<template>
    <div>
        <button type="button" class="btn btn--alpha" @click="handleUploadClick">
            {{ $t('DOCUMENT_WIZARD.UPLOAD_NEW_VERSION') }}
        </button>
        <input
            ref="filesRef"
            type="file"
            :accept="accept"
            class="hidden-dms"
            @change="handleChange"
        />
    </div>
</template>

<script>
import { first } from 'lodash-es'
import { mapActions, mapGetters } from 'vuex'
import {
    STEP_DOCUMENT_UPLOAD_OPTION,
    UPLOAD_NEW_VERSION_STATUSES,
    TOAST_TYPES
} from '@/constants'
import { toasts } from '@/mixins'
import { parseApiErrorMessage } from '@/services/utils/functions'
import { EventBus } from '@/EventBus'

export default {
    mixins: [toasts],

    computed: {
        ...mapGetters('company', ['company', 'regulationData'])
    },

    methods: {
        ...mapActions('document', ['uploadDocument']),
        ...mapActions('regulation', ['updateRegulationInputs']),

        handleUploadClick () {
            if (this.$refs.filesRef) {
                this.$refs.filesRef.click()
            }
        },

        async handleChange (event) {
            const document = first(event.target.files)

            document && (await this.handleUpload(document))
        },

        async handleUpload (document) {
            try {
                EventBus.$emit(
                    'update-document-url',
                    UPLOAD_NEW_VERSION_STATUSES.RESET
                )

                const file = await this.uploadDocument({
                    formData: this.getUploadFormData(document),
                    companyId: this.company.id
                })

                if (this.stepId) {
                    await this.updateInputsForDocumentUpload(file)
                }

                EventBus.$emit(
                    'update-document-url',
                    UPLOAD_NEW_VERSION_STATUSES.SET_NEW
                )
            } catch (error) {
                this.showToast(
                    parseApiErrorMessage(error, 'document'),
                    TOAST_TYPES.ERROR
                )

                throw error
            }
        },

        async updateInputsForDocumentUpload (file) {
            let form = {}

            if (this.stepInputsNumber) {
                form[`step_document_upload_${this.stepInputsNumber}`] = { file }
                form[`step_own_document_${this.stepInputsNumber}`] =
                    STEP_DOCUMENT_UPLOAD_OPTION.USER_UPLOADED_DOCUMENT
            } else {
                form['step_document_upload_custom_step'] = { file }
            }

            const data = {
                payload: form,
                regulationId: this.regulationData.id,
                stepId: this.stepId
            }

            await this.updateRegulationInputs(data)
        },

        getUploadFormData (document) {
            const formData = new FormData()
            formData.append('document', document)
            formData.append('document_id', this.documentId)
            formData.append('regulation_id', this.regulationData.id)

            return formData
        }
    },

    props: {
        documentId: {
            type: Number,
            required: true
        },

        accept: {
            type: String,
            required: false,
            default: ''
        },

        stepId: {
            required: false
        },

        stepInputsNumber: {
            required: false
        }
    }
}
</script>

<style>
.hidden-dms {
    display: none;
}
</style>
