<template>
    <iframe
        ref="video"
        :src="src"
        :width="width"
        :height="height"
        frameborder="0"
        allowfullscreen
    />
</template>

<script>
import Player from '@vimeo/player'

export default {
    props: {
        src: {
            type: String,
            required: true
        },

        width: {
            type: Number,
            required: false,
            default: null
        },

        height: {
            type: Number,
            required: false,
            default: 360
        }
    },

    data () {
        return {
            player: null
        }
    },

    mounted () {
        this.player = new Player(this.$refs.video)

        this.player.on('play', () => {
            this.$emit('play')
        })

        this.player.ready().then(() => {
            this.$emit('ready')
        })
    }
}
</script>
