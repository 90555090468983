<template>
    <button
        type="button"
        class="btn btn--beta"
        v-b-tooltip.top="$t('DOCUMENT_WIZARD.PRINT_OPTION.PRINT_BTN_TOOLTIP')"
        :class="[
            'btn',
            'btn--beta',
            { 'cf-c-btn-disabled': !canPrintDocument }
        ]"
        :disabled="!canPrintDocument"
        @click="printDocument"
    >
        <app-icon size="sm" icon="print-icon" />
        {{ $t('DOCUMENT_WIZARD.PRINT_OPTION.PRINT_BTN_TEXT') }}
    </button>
</template>

<script>
import { mapGetters } from 'vuex'
import { DOCUMENT_TYPES } from '@/constants/documents'
import { printDocumentFromCanvas } from '@/services/utils/dom'

export default {
    name: 'DocumentPrint',

    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        }
    },

    computed: {
        ...mapGetters('company', ['pricingPlanLimits', 'company']),

        canPrintDocument () {
            return true
            // return this.pricingPlanLimits.is_allowed_print_document_preview && // will be enabled when early access will be removed
        }
    },

    methods: {
        printDocument () {
            if (
                this.document.version.documentable_type ===
                DOCUMENT_TYPES.USER_UPLOAD_DOCUMENT
            ) {
                printDocumentFromCanvas(
                    document.querySelectorAll('.vue-pdf-embed canvas'),
                    this.document.version.documentable.name
                )

                return
            }

            const template = this.prepareTemplate()

            const mywindow = window.open(
                '',
                new Date().getTime(),
                'height=400,width=600,left=0,top=1000'
            )

            mywindow.document.write(template)
        },

        prepareTemplate () {
            // get all inputs in template and replace placeholders with values
            // since values is not displayed
            document
                .querySelectorAll('[type="text"], textarea')
                .forEach(function (input) {
                    input.placeholder = input.value
                })

            // use all styles for template
            const styleElements = [
                ...document.querySelectorAll('head style')
            ].map(function (style) {
                return style.outerHTML
            })

            const linkElements = [
                ...document.querySelectorAll('head link')
            ].map(function (style) {
                return style.outerHTML
            })

            let script = window.document.createElement('script')
            script.setAttribute('type', 'text/javascript')

            // set timeout to wait for links be loaded, since onload event is not triggered
            // @todo fix this in future

            script.appendChild(
                window.document.createTextNode(
                    'setTimeout(function() {\n' +
                        '    print(); close();\n' +
                        '}, 4000);'
                )
            )

            return (
                '<html><head><title>' +
                document.title +
                '</title>' +
                styleElements.join('') +
                linkElements.join('') +
                '</head><body id="body"><div id="document-content">' +
                document.getElementById('document').innerHTML +
                '</div>' +
                script.outerHTML +
                '</body></html>'
            )
        }
    }
}
</script>

<style scoped></style>
