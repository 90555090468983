<template>
    <div class="cf-c-checkbox-list">
        <div
            v-if="headerAlways || (header && list.length)"
            class="cf-c-checkbox-list__header"
        >
            {{ header }}
        </div>
        <div
            v-if="
                list.length || ($slots['add-new'] && $slots['add-new'].length)
            "
            :class="[
                'cf-u-display-flex cf-u-flex-direction-column',
                { 'cf-c-labeled-checkbox--border': border }
            ]"
        >
            <labeled-checkbox
                v-for="item in list"
                :id="`checkbox-item-${item[trackBy]}`"
                :key="item[trackBy]"
                class="cf-c-checkbox-list__item"
                :value="isChecked(item)"
                :text-justified="hasExtra"
                :disabled="item.disabled"
                truncated
                small
                :big="big"
                @input="checked => handleCheck(item[trackBy], checked)"
            >
                <span class="cf-c-chechbox-list__text-label">
                    {{ item[label] }}
                </span>
                <span v-if="hasExtra">
                    <slot name="extra" :item="item"></slot>
                </span>
                <b-tooltip
                    v-if="item.info"
                    :target="`checkbox-item-${item[trackBy]}`"
                    placement="bottom"
                    boundary="window"
                >
                    {{ item.info }}
                </b-tooltip>
            </labeled-checkbox>
            <slot name="add-new"></slot>
        </div>
        <div v-if="shouldShowEmptyState" class="cf-c-checkbox-list__empty-list">
            {{ $t(emptyListMessage) }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        },

        header: {
            type: String,
            required: false,
            default: ''
        },

        headerAlways: {
            type: Boolean,
            default: false
        },

        trackBy: {
            type: String,
            default: 'id'
        },

        value: {
            type: String,
            default: 'value'
        },

        label: {
            type: String,
            default: 'label'
        },

        emptyListMessage: {
            type: String,
            default: 'COMMON.CHECKBOX_SEARCH_EMPTY_LIST'
        },

        hasExtra: {
            type: Boolean,
            required: false,
            default: false
        },

        shouldReinitialize: {
            type: Boolean,
            required: false,
            default: false
        },

        border: {
            type: Boolean,
            required: false,
            default: false
        },

        big: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        checked: {},
        isInitialized: false
    }),

    computed: {
        shouldShowEmptyState () {
            return (
                !this.list.length &&
                this.emptyListMessage &&
                (!this.$slots['add-new'] ||
                    (this.$slots['add-new'] && !this.$slots['add-new'].length))
            )
        }
    },

    watch: {
        list (newValue, oldValue) {
            // This is due to checked items changing from outside
            this.shouldReinitialize && this.setSelected()

            if (
                !oldValue.length &&
                newValue.length &&
                !this.isInitialized &&
                !this.shouldReinitialize
            ) {
                this.setSelected()
                this.notify()
            }
        }
    },

    created () {
        this.setSelected()
        this.notify(true)
    },

    methods: {
        reset () {
            this.checked = {}
            this.list.forEach(item => {
                item[this.value] = false
            })
            this.setSelected()
        },

        setSelected () {
            if (this.list.length) {
                this.isInitialized = true
            }

            this.list.forEach(item => {
                this.$set(
                    this.checked,
                    item[this.trackBy],
                    item[this.value] === undefined ? false : item[this.value]
                )
            })
        },

        isChecked (item) {
            return this.checked[item[this.trackBy]]
        },

        handleCheck (itemId, checked) {
            this.checked[itemId] = checked
            this.notify()
        },

        notify (isInitialDataEmit = false) {
            this.$emit('checked', this.checked, isInitialDataEmit)
        }
    }
}
</script>
