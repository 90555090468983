import { REGISTER_WIZARD_ROUTES } from '@/constants'

const INTERNAL_AUDITS_REGISTER_SIDEBAR_ROUTES = [
    {
        route: REGISTER_WIZARD_ROUTES.INTERNAL_AUDITS.WIZARD,
        label: 'COMMON.WIZARD'
    },
    {
        route: REGISTER_WIZARD_ROUTES.INTERNAL_AUDITS.PROPERTIES,
        label: 'COMMON.PROPERTIES'
    },
    {
        route: REGISTER_WIZARD_ROUTES.INTERNAL_AUDITS.DISCUSSIONS,
        label: 'COMMON.DISCUSSIONS'
    }
]

const STATUSES = {
    PLANNED: 'planned',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    CANCELLED: 'cancelled'
}

const STATUS_COLORS = {
    PLANNED: 'cf-u-color-primary',
    IN_PROGRESS: 'cf-u-color-amber',
    COMPLETED: 'cf-u-color-secondary-light',
    CANCELLED: 'cf-u-color-gray-canceled'
}

const STATUS_GUIDES = [
    {
        label: 'REGISTERS.INTERNAL_AUDITS.STATUSES.PLANNED',
        color: STATUS_COLORS.PLANNED
    },
    {
        label: 'REGISTERS.INTERNAL_AUDITS.STATUSES.IN_PROGRESS',
        color: STATUS_COLORS.IN_PROGRESS
    },
    {
        label: 'REGISTERS.INTERNAL_AUDITS.STATUSES.COMPLETED',
        color: STATUS_COLORS.COMPLETED
    },
    {
        label: 'REGISTERS.INTERNAL_AUDITS.STATUSES.CANCELLED',
        color: STATUS_COLORS.CANCELLED
    }
]

const INPUT_GUIDES = {
    title: {
        label: 'REGISTERS.INTERNAL_AUDITS.FIELDS.TITLE.LABEL',
        placeholder: 'REGISTERS.INTERNAL_AUDITS.FIELDS.TITLE.PLACEHOLDER',
        description: 'REGISTERS.INTERNAL_AUDITS.FIELDS.TITLE.DESCRIPTION',
        index: 0
    },
    scope: {
        label: 'REGISTERS.INTERNAL_AUDITS.FIELDS.SCOPE.LABEL',
        placeholder: 'REGISTERS.INTERNAL_AUDITS.FIELDS.SCOPE.PLACEHOLDER',
        description: 'REGISTERS.INTERNAL_AUDITS.FIELDS.SCOPE.DESCRIPTION',
        index: 1
    },
    criteria: {
        label: 'REGISTERS.INTERNAL_AUDITS.FIELDS.CRITERIA.LABEL',
        placeholder: 'REGISTERS.INTERNAL_AUDITS.FIELDS.CRITERIA.PLACEHOLDER',
        description: 'REGISTERS.INTERNAL_AUDITS.FIELDS.CRITERIA.DESCRIPTION',
        index: 2
    },
    assigned_to: {
        label: 'REGISTERS.INTERNAL_AUDITS.FIELDS.ASSIGNED_TO.LABEL',
        placeholder: 'REGISTERS.INTERNAL_AUDITS.FIELDS.ASSIGNED_TO.PLACEHOLDER',
        description: 'REGISTERS.INTERNAL_AUDITS.FIELDS.ASSIGNED_TO.DESCRIPTION',
        index: 3
    },
    start_date: {
        label: 'REGISTERS.INTERNAL_AUDITS.FIELDS.START_DATE.LABEL',
        placeholder: 'REGISTERS.INTERNAL_AUDITS.FIELDS.START_DATE.PLACEHOLDER',
        description: 'REGISTERS.INTERNAL_AUDITS.FIELDS.START_DATE.DESCRIPTION',
        index: 4
    },
    end_date: {
        label: 'REGISTERS.INTERNAL_AUDITS.FIELDS.END_DATE.LABEL',
        placeholder: 'REGISTERS.INTERNAL_AUDITS.FIELDS.END_DATE.PLACEHOLDER',
        description: 'REGISTERS.INTERNAL_AUDITS.FIELDS.END_DATE.DESCRIPTION',
        index: 5
    },
    checklist: {
        label: 'REGISTERS.INTERNAL_AUDITS.FIELDS.CHECKLIST.LABEL',
        placeholder: 'REGISTERS.INTERNAL_AUDITS.FIELDS.CHECKLIST.PLACEHOLDER',
        description: 'REGISTERS.INTERNAL_AUDITS.FIELDS.CHECKLIST.DESCRIPTION',
        index: 6
    },
    methods: {
        label: 'REGISTERS.INTERNAL_AUDITS.FIELDS.METHODS.LABEL',
        placeholder: 'REGISTERS.INTERNAL_AUDITS.FIELDS.METHODS.PLACEHOLDER',
        description: 'REGISTERS.INTERNAL_AUDITS.FIELDS.METHODS.DESCRIPTION',
        index: 7
    },
    notificants: {
        label: 'REGISTERS.INTERNAL_AUDITS.FIELDS.NOTIFICANTS.LABEL',
        placeholder: 'REGISTERS.INTERNAL_AUDITS.FIELDS.NOTIFICANTS.PLACEHOLDER',
        description: 'REGISTERS.INTERNAL_AUDITS.FIELDS.NOTIFICANTS.DESCRIPTION',
        index: 8
    }
}

const SORT_VALUES = {
    TITLE_ASC: 'title_asc',
    TITLE_DESC: 'title_desc',
    START_DATE_ASC: 'start_date_asc',
    START_DATE_DESC: 'start_date_desc',
    END_DATE_ASC: 'end_date_asc',
    END_DATE_DESC: 'end_date_desc',
    STATUS_ASC: 'status_asc',
    STATUS_DESC: 'status_desc'
}

const SORT_ACTIONS = {
    [SORT_VALUES.TITLE_ASC]: (a, b) => (a.title > b.title ? 1 : -1),
    [SORT_VALUES.TITLE_DESC]: (a, b) => (a.title < b.title ? 1 : -1),
    [SORT_VALUES.START_DATE_ASC]: (a, b) =>
        a.start_date > b.start_date ? 1 : -1,
    [SORT_VALUES.START_DATE_DESC]: (a, b) =>
        a.start_date < b.start_date ? 1 : -1,
    [SORT_VALUES.END_DATE_ASC]: (a, b) => (a.end_date > b.end_date ? 1 : -1),
    [SORT_VALUES.END_DATE_DESC]: (a, b) => (a.end_date < b.end_date ? 1 : -1),
    [SORT_VALUES.STATUS_ASC]: (a, b) => (a.state.id > b.state.id ? 1 : -1),
    [SORT_VALUES.STATUS_DESC]: (a, b) => (a.state.id < b.state.id ? 1 : -1)
}

const INTERNAL_AUDIT_PARAMS = [
    'title',
    'scope',
    'state',
    'criteria',
    'checklist_id',
    'methods',
    'start_date',
    'end_date',
    'assignee_id',
    'notificants',
    'audit_team',
    'conclusion_and_comments',
    'observations'
]

const DOCUMENTATION_REVIEW_SIDEBAR_ROUTES = [
    {
        route: REGISTER_WIZARD_ROUTES.DOCUMENTATION_REVIEW.WIZARD,
        label: 'COMMON.WIZARD'
    },
    {
        route: REGISTER_WIZARD_ROUTES.DOCUMENTATION_REVIEW.PROPERTIES,
        label: 'COMMON.PROPERTIES'
    },
    {
        route: REGISTER_WIZARD_ROUTES.DOCUMENTATION_REVIEW.DISCUSSIONS,
        label: 'COMMON.DISCUSSIONS'
    }
]

const DOCUMENTATION_REVIEW_ITEM_TYPES = {
    DOCUMENT: 'document',
    CLAUSE: 'clause',
    REQUIREMENT: 'requirement'
}

const DOCUMENTATION_REVIEW_PARAMS = [
    'id',
    'title',
    'is_relevant',
    'is_reviewed'
]

const CHECKLIST_ADAPTATION_SIDEBAR_ROUTES = [
    {
        route: REGISTER_WIZARD_ROUTES.CHECKLIST_ADAPTATION.WIZARD,
        label: 'COMMON.WIZARD'
    },
    {
        route: REGISTER_WIZARD_ROUTES.CHECKLIST_ADAPTATION.PROPERTIES,
        label: 'COMMON.PROPERTIES'
    },
    {
        route: REGISTER_WIZARD_ROUTES.CHECKLIST_ADAPTATION.DISCUSSIONS,
        label: 'COMMON.DISCUSSIONS'
    }
]

const CHECKLIST_ADAPTATION_INPUT_GUIDES = {
    section: {
        label:
            'REGISTERS.INTERNAL_AUDITS.CHECKLIST_ADAPTATION.FIELDS.SECTION.LABEL',
        placeholder:
            'REGISTERS.INTERNAL_AUDITS.CHECKLIST_ADAPTATION.FIELDS.SECTION.PLACEHOLDER',
        index: 0
    },
    title: {
        label:
            'REGISTERS.INTERNAL_AUDITS.CHECKLIST_ADAPTATION.FIELDS.TITLE.LABEL',
        placeholder:
            'REGISTERS.INTERNAL_AUDITS.CHECKLIST_ADAPTATION.FIELDS.TITLE.PLACEHOLDER',
        description:
            'REGISTERS.INTERNAL_AUDITS.CHECKLIST_ADAPTATION.FIELDS.TITLE.DESCRIPTION',
        index: 1
    },
    reference_to: {
        label:
            'REGISTERS.INTERNAL_AUDITS.CHECKLIST_ADAPTATION.FIELDS.REFERENCE_TO.LABEL',
        placeholder:
            'REGISTERS.INTERNAL_AUDITS.CHECKLIST_ADAPTATION.FIELDS.REFERENCE_TO.PLACEHOLDER',
        description:
            'REGISTERS.INTERNAL_AUDITS.CHECKLIST_ADAPTATION.FIELDS.REFERENCE_TO.DESCRIPTION',
        index: 2
    }
}

const CHECKLIST_ADAPTATION_PARAMS = [
    'id',
    'section',
    'checklist_section_id',
    'previous_item_id',
    'title',
    'nonconformities_ids',
    'observations',
    'reference_to'
]

const PERFORMING_SIDEBAR_ROUTES = [
    {
        route: REGISTER_WIZARD_ROUTES.INTERNAL_AUDIT_PERFORMING.WIZARD,
        label: 'COMMON.WIZARD'
    },
    {
        route: REGISTER_WIZARD_ROUTES.INTERNAL_AUDIT_PERFORMING.PROPERTIES,
        label: 'COMMON.PROPERTIES'
    },
    {
        route: REGISTER_WIZARD_ROUTES.INTERNAL_AUDIT_PERFORMING.DISCUSSIONS,
        label: 'COMMON.DISCUSSIONS'
    }
]

const AUDIT_REPORT_ROUTES = [
    {
        route: REGISTER_WIZARD_ROUTES.AUDIT_REPORT.WIZARD,
        label: 'COMMON.WIZARD'
    },
    {
        route: REGISTER_WIZARD_ROUTES.AUDIT_REPORT.PROPERTIES,
        label: 'COMMON.PROPERTIES'
    },
    {
        route: REGISTER_WIZARD_ROUTES.AUDIT_REPORT.DISCUSSIONS,
        label: 'COMMON.DISCUSSIONS'
    }
]

const PERFORMING_PARAMS = [
    'id',
    'item_id',
    'compliant',
    'evidence',
    'checklist_section_id',
    'reference_to'
]

const INTENTIONS = {
    CREATE: 'create',
    EDIT: 'edit',
    CANCEL: 'cancel'
}

const ITEM_UPDATE_DEBOUNCE_INTERVAL = 1000

const STEPS = {
    DOCUMENTATION_REVIEW: 'documentation review',
    ADAPTING_THE_CHECKLIST: 'adapting the checklist',
    PERFORMING_THE_AUDIT: 'performing the audit',
    AUDIT_REPORT: 'audit report'
}

const INTERNAL_AUDIT_STEPS = [
    {
        label: 'REGISTERS.INTERNAL_AUDITS.STEPS.DOCUMENTATION_REVIEWT.LABEL',
        title: 'REGISTERS.INTERNAL_AUDITS.STEPS.DOCUMENTATION_REVIEW.TITLE',
        value: STEPS.DOCUMENTATION_REVIEW,
        description:
            'REGISTERS.INTERNAL_AUDITS.STEPS.DOCUMENTATION_REVIEW.DESCRIPTION'
    },
    {
        label: 'REGISTERS.INTERNAL_AUDITS.STEPS.ADAPTING_THE_CHECKLIST.LABEL',
        title: 'REGISTERS.INTERNAL_AUDITS.STEPS.ADAPTING_THE_CHECKLIST.TITLE',
        value: STEPS.ADAPTING_THE_CHECKLIST,
        description:
            'REGISTERS.INTERNAL_AUDITS.STEPS.ADAPTING_THE_CHECKLIST.DESCRIPTION'
    },
    {
        label: 'REGISTERS.INTERNAL_AUDITS.STEPS.PERFORMING_THE_AUDIT.LABEL',
        title: 'REGISTERS.INTERNAL_AUDITS.STEPS.PERFORMING_THE_AUDIT.TITLE',
        value: STEPS.PERFORMING_THE_AUDIT,
        description:
            'REGISTERS.INTERNAL_AUDITS.STEPS.PERFORMING_THE_AUDIT.DESCRIPTION'
    },
    {
        label: 'REGISTERS.INTERNAL_AUDITS.STEPS.AUDIT_REPORT.LABEL',
        title: 'REGISTERS.INTERNAL_AUDITS.STEPS.AUDIT_REPORT.TITLE',
        value: STEPS.AUDIT_REPORT,
        description: 'REGISTERS.INTERNAL_AUDITS.STEPS.AUDIT_REPORT.DESCRIPTION'
    }
]

const REGISTER_OF_INTERNAL_AUDITS = {
    INTERNAL_AUDITS_REGISTER_SIDEBAR_ROUTES,
    STATUSES,
    STATUS_COLORS,
    STATUS_GUIDES,
    INPUT_GUIDES,
    SORT_VALUES,
    SORT_ACTIONS,
    INTERNAL_AUDIT_PARAMS,
    DOCUMENTATION_REVIEW_SIDEBAR_ROUTES,
    DOCUMENTATION_REVIEW_ITEM_TYPES,
    DOCUMENTATION_REVIEW_PARAMS,
    CHECKLIST_ADAPTATION_SIDEBAR_ROUTES,
    CHECKLIST_ADAPTATION_INPUT_GUIDES,
    CHECKLIST_ADAPTATION_PARAMS,
    PERFORMING_SIDEBAR_ROUTES,
    PERFORMING_PARAMS,
    AUDIT_REPORT_ROUTES,
    INTENTIONS,
    ITEM_UPDATE_DEBOUNCE_INTERVAL,
    INTERNAL_AUDIT_STEPS,
    STEPS
}

export { REGISTER_OF_INTERNAL_AUDITS }
