<template>
    <label class="cf-c-radio-label" :class="{ 'cf-is-checked': isChecked }">
        <input
            :disabled="disabled"
            class="cf-c-radio-label__input"
            type="radio"
            :value="radioValue"
            :name="name"
            :checked="isChecked"
            @change="$emit('input', $event.target.value)"
        />
        <div class="cf-c-radio-label__dot-wrapper">
            <span class="cf-c-radio-dot"></span>
        </div>
        <span
            :class="[
                'cf-c-radio-label__text',
                { 'cf-c-radio-label__text--disabled ': disabled }
            ]"
        >
            <slot>{{ label }}</slot>
        </span>
    </label>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },

        label: {
            type: String,
            required: false,
            default: ''
        },

        radioValue: {
            type: [String, Boolean, Number],
            required: false,
            default: null
        },

        value: {
            type: [String, Boolean, Number],
            required: false,
            default: null
        },

        checked: {
            type: Boolean,
            required: false,
            default: false
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        isChecked () {
            return this.checked || this.value === this.radioValue
        }
    }
}
</script>
