import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {
    getLocalStorageItem,
    setLocalStorageItem
} from '@/services/localStorage'
import { EN } from './locale-en'
import { HR } from './locale-hr'
import { DE } from './locale-de'
import { ES } from './locale-es'
import moment from 'moment'
import dayjs from 'dayjs'
const locales = {
    de: () => import('dayjs/locale/de'),
    en: () => import('dayjs/locale/en'),
    es: () => import('dayjs/locale/es')
}

const DEFAULT_LOCALE = 'en'
const defaultLocale =
    getLocalStorageItem('locale') !== 'undefined' &&
    getLocalStorageItem('locale')
        ? getLocalStorageItem('locale')
        : DEFAULT_LOCALE

moment.locale(defaultLocale)

function loadLocale (language) {
    locales[language]().then(() => dayjs.locale(language))
}
loadLocale(defaultLocale)

const languages = {
    en: EN,
    hr: HR,
    de: DE,
    es: ES
}

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: defaultLocale,
    messages: Object.assign(languages),
    silentTranslationWarn: true
})

const changeLocale = locale => {
    setLocalStorageItem('locale', locale)
    moment.locale(locale)

    i18n.locale = locale
}

export { i18n, changeLocale, DEFAULT_LOCALE }
