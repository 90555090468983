import { securityObjectivesService } from './api'
import { STATUSES } from './constants'

const store = {
    namespaced: true,

    state: {
        isLoading: false,
        isSecObjectivesInitiallyLoaded: false,
        isAnyObjectiveUpdated: false,
        securityObjectives: []
    },

    actions: {
        async getSecurityObjectives ({ commit, rootGetters, dispatch }) {
            commit('setIsLoading', true)

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                const response = await securityObjectivesService.getSecurityObjectives(
                    companyId,
                    regulationId
                )

                const securityObjectives = response.map(objective => {
                    return {
                        id: objective.id,
                        name: objective.title,
                        type: objective.type,
                        responsibleUser:
                            objective.responsible_for_achieving_company_member ??
                            null,
                        measurement: objective.measure_period,
                        status: objective.status
                    }
                })

                commit('setSecurityObjectives', securityObjectives)
            } catch (err) {
                dispatch('errors/handleError', err, { root: true })
                throw err
            } finally {
                commit('setIsLoading', false)
                commit('setIsSecObjectivesInitiallyLoaded', true)
            }
        },

        async getSecurityObjectiveDetails (
            { commit, rootGetters, dispatch },
            objectiveId
        ) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                const response = await securityObjectivesService.getSecurityObjectiveDetails(
                    companyId,
                    regulationId,
                    objectiveId
                )

                return {
                    id: response.id,
                    is_system_objective: response.is_system_objective,
                    locked: response.locked,
                    title_key: response.is_system_objective
                        ? response.title
                        : response.key,
                    security_objective_type: response.type,
                    status: response.is_accepted
                        ? STATUSES.ACCEPTED
                        : STATUSES.DRAFT,
                    target_type: response.target_type,
                    target: response.target,
                    achieving_member_id:
                        response.responsible_for_achieving_company_member.id,
                    measure_period: response.measure_period,
                    first_due_date: response.first_due_date,
                    measurement_member_id:
                        response.responsible_for_measurement_company_member.id,
                    analyzing_member_id:
                        response
                            .responsible_for_analyzing_and_evaluating_company_member
                            .id,
                    data_source: response.data_source
                }
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },

        async createNewObjective ({ commit, rootGetters, dispatch }, payload) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                const response = await securityObjectivesService.createObjective(
                    companyId,
                    regulationId,
                    payload
                )

                const securityObjective = {
                    id: response.id,
                    name: response.title,
                    type: response.type,
                    responsibleUser:
                        response.responsible_for_achieving_company_member ??
                        null,
                    measurement: response.measure_period,
                    status: response.status
                }

                commit('updateSecurityObjectives', securityObjective)
                commit('setIsAnyObjectiveUpdated', true)
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
                throw error
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },

        async updateObjective ({ commit, rootGetters, dispatch }, payload) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                const response = await securityObjectivesService.updateObjective(
                    companyId,
                    regulationId,
                    payload.id,
                    payload
                )

                const securityObjective = {
                    id: response.id,
                    name: response.title,
                    type: response.type,
                    responsibleUser:
                        response.responsible_for_achieving_company_member ??
                        null,
                    measurement: response.measure_period,
                    status: response.status
                }

                commit('updateSecurityObjectives', securityObjective)
                commit('setIsAnyObjectiveUpdated', true)
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
                throw error
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },

        async deleteObjective ({ commit, rootGetters }, objectiveId) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                await securityObjectivesService.deleteObjective(
                    companyId,
                    regulationId,
                    objectiveId
                )

                commit('removeSecurityObjective', objectiveId)
                commit('setIsAnyObjectiveUpdated', true)
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
                throw error
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },

        async confirmObjectives ({ commit, rootGetters, dispatch }) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                await securityObjectivesService.confirmObjectives(
                    companyId,
                    regulationId
                )

                commit('setIsAnyObjectiveUpdated', false)
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
                throw error
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        }
    },

    mutations: {
        setIsLoading (state, isLoading) {
            state.isLoading = isLoading
        },

        setIsSecObjectivesInitiallyLoaded (state, value) {
            state.isSecObjectivesInitiallyLoaded = value
        },

        setIsAnyObjectiveUpdated (state, value) {
            state.isAnyObjectiveUpdated = value
        },

        setSecurityObjectives (state, securityObjectives) {
            state.securityObjectives = securityObjectives
        },

        updateSecurityObjectives (state, securityObjective) {
            const exist = state.securityObjectives.find(
                obj => obj.id === securityObjective.id
            )

            if (exist) {
                state.securityObjectives = state.securityObjectives.map(obj => {
                    if (obj.id === securityObjective.id) {
                        return securityObjective
                    }
                    return obj
                })
            } else {
                state.securityObjectives = [
                    securityObjective,
                    ...state.securityObjectives
                ]
            }
        },

        removeSecurityObjective (state, objectiveId) {
            state.securityObjectives = state.securityObjectives.filter(
                obj => obj.id !== objectiveId
            )
        }
    }
}

export { store }
