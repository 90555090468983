import store from '@src/store'

const hasPermission = permission => {
    const myPermissions = store.state.account.myPermissions

    if (!permission || !myPermissions?.permissions?.length) {
        return false
    }

    return Boolean(
        myPermissions.permissions.find(per => per.name === permission)
    )
}

const isRegistersOwner = register => {
    const registersOwnership = store.state.account.myPermissions

    if (!register || !registersOwnership?.registers_owner?.length) {
        return false
    }

    return Boolean(
        registersOwnership.registers_owner.find(reg => reg === register)
    )
}

const isStepOwner = stepId => {
    const stepsOwnership = store.state.account.myPermissions

    if (!stepId || !stepsOwnership?.steps_owner?.length) {
        return false
    }

    return Boolean(
        stepsOwnership.steps_owner.find(step => Number(step) === Number(stepId))
    )
}

export { hasPermission, isRegistersOwner, isStepOwner }
