import dayjs from 'dayjs'
import { trainingsService } from './api'

const store = {
    namespaced: true,

    state: {
        isLoading: false,
        isTrainingsInitiallyLoaded: false,
        isAnyTrainingUpdated: false,
        trainings: [],
        stepTrainings: []
    },

    actions: {
        async getTrainings ({ commit, rootGetters, dispatch }) {
            commit('setIsLoading', true)

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                const response = await trainingsService.getTrainings(
                    companyId,
                    regulationId
                )

                const trainings = response.map(training => {
                    return {
                        id: training.id,
                        trainees: training.trainees.map(
                            trainee => trainee.label
                        ),
                        requiredTraining: training.required_training,
                        date: training.training_date
                            ? dayjs(training.training_date).format(
                                  'D MMM, YYYY'
                              )
                            : '-',
                        status: training.status.name
                    }
                })

                commit('setTrainings', trainings)
            } catch (err) {
                dispatch('errors/handleError', err, { root: true })
                throw err
            } finally {
                commit('setIsLoading', false)
                commit('setIsTrainingsInitiallyLoaded', true)
            }
        },
        async getTrainingDetails (
            { commit, rootGetters, dispatch },
            trainingId
        ) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                const response = await trainingsService.getTrainingDetails(
                    companyId,
                    regulationId,
                    trainingId
                )

                return {
                    id: response.id,
                    status: response.status.name,
                    required_knowledge: response.required_knowledge,
                    note: response.note,
                    training_date: response.training_date,
                    required_training: response.required_training,
                    step: response.step,
                    trainees: response.trainees.map(trainee => {
                        return {
                            value: trainee.id,
                            label: trainee.label
                        }
                    })
                }
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },
        async addTraining ({ commit, rootGetters, dispatch }, payload) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                const response = await trainingsService.addTraining(
                    companyId,
                    regulationId,
                    payload
                )

                const training = {
                    id: response.id,
                    trainees: response.trainees.map(trainee => trainee.label),
                    requiredTraining: response.required_training,
                    date: response.training_date
                        ? dayjs(response.training_date).format('D MMM, YYYY')
                        : '-',
                    status: response.status.name
                }
                commit('updateTrainings', training)
                commit('setIsAnyTrainingUpdated', true)
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
                throw error
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },
        async editTraining ({ commit, rootGetters, dispatch }, payload) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                const response = await trainingsService.editTraining(
                    companyId,
                    regulationId,
                    payload.id,
                    payload
                )

                const training = {
                    id: response.id,
                    trainees: response.trainees.map(trainee => trainee.label),
                    requiredTraining: response.required_training,
                    date: response.training_date
                        ? dayjs(response.training_date).format('D MMM, YYYY')
                        : '-',
                    status: response.status.name
                }
                commit('updateTrainings', training)
                commit('setIsAnyTrainingUpdated', true)
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
                throw error
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },
        async deleteTraining ({ commit, rootGetters }, trainingId) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                await trainingsService.deleteTraining(
                    companyId,
                    regulationId,
                    trainingId
                )

                commit('removeTraining', trainingId)
                commit('setIsAnyTrainingUpdated', true)
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
                throw error
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },
        async getStepTrainings ({ commit, rootGetters, dispatch }, stepId) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                const trainings = await trainingsService.getStepTrainings(
                    companyId,
                    regulationId,
                    stepId
                )

                commit('setStepTrainings', trainings)
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
                throw error
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },
        async bulkSaveTrainings (
            { commit, rootGetters, dispatch, getters },
            { stepId, payload }
        ) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                const trainings = await trainingsService.bulkSaveTrainings(
                    companyId,
                    regulationId,
                    stepId,
                    payload
                )

                commit('setStepTrainings', trainings)
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
                throw error
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },
        async deleteStepTraining ({ commit, rootGetters, dispatch }, data) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const { stepId, trainingId } = data
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                await trainingsService.deleteStepTraining(
                    companyId,
                    regulationId,
                    stepId,
                    trainingId
                )

                commit('removeStepTraining', trainingId)
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
                throw error
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },
        async generateReport ({ commit, rootGetters, dispatch, getters }) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                await trainingsService.generateReport(companyId, regulationId)

                commit('setIsAnyTrainingUpdated', false)
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
                throw error
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        }
    },

    mutations: {
        setIsLoading (state, isLoading) {
            state.isLoading = isLoading
        },

        setIsTrainingsInitiallyLoaded (state, value) {
            state.isTrainingsInitiallyLoaded = value
        },

        setIsAnyTrainingUpdated (state, value) {
            state.isAnyTrainingUpdated = value
        },

        setTrainings (state, trainings) {
            state.trainings = trainings
        },

        updateTrainings (state, training) {
            const exist = state.trainings.find(obj => obj.id === training.id)

            if (exist) {
                state.trainings = state.trainings.map(obj => {
                    if (obj.id === training.id) {
                        return training
                    }
                    return obj
                })
            } else {
                state.trainings = [training, ...state.trainings]
            }
        },

        removeTraining (state, trainingId) {
            state.trainings = state.trainings.filter(
                training => training.id !== trainingId
            )
        },

        setStepTrainings (state, trainings) {
            state.stepTrainings = trainings
        },

        removeStepTraining (state, trainingId) {
            state.stepTrainings = state.stepTrainings.filter(
                training => training.id !== trainingId
            )
        }
    }
}

export { store }
