<template>
    <div :class="wrapperClasses">
        <div class="cf-c-collapsible__header" :class="headerClasses">
            <div class="cf-c-collapsible__icon-wrap" @click="toggleCollapse">
                <div class="cf-c-collapsible__icon"></div>
            </div>
            <div class="cf-c-collapsible__header-ctn">
                <slot name="target"></slot>
            </div>
        </div>
        <div
            v-if="visible"
            class="cf-c-collapsible__content"
            :class="contentClasses"
        >
            <slot name="content"></slot>
        </div>
        <div
            v-if="!visible && collapsedMessage"
            class="cf-c-collapsible__content"
            :class="contentClasses"
        >
            <div class="cf-c-collapsible__empty-ctn">
                {{ collapsedMessage }}
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/EventBus'
export default {
    props: {
        highlighted: {
            type: Boolean,
            required: false,
            default: false
        },

        horizontal: {
            type: Boolean,
            required: false,
            default: false
        },

        toggleFullHeight: {
            type: Boolean,
            required: false,
            default: false
        },

        collapsedMessage: {
            type: String,
            required: false,
            default: ''
        },

        headerClasses: {
            type: Array,
            required: false,
            default: () => []
        },

        contentClasses: {
            type: Array,
            required: false,
            default: () => []
        },

        isVisible: {
            type: Boolean,
            required: false,
            default: true
        },

        shadow: {
            type: Boolean,
            required: false,
            default: false
        },

        fullHeaderClickable: {
            type: Boolean,
            required: false,
            default: false
        },

        componentId: {
            type: [String, Number],
            required: false,
            default: null
        }
    },

    data () {
        return {
            visible: this.isVisible
        }
    },

    computed: {
        wrapperClasses () {
            let classes = 'cf-c-collapsible'

            if (this.highlighted) {
                classes += ' cf-c-collapsible--highlighted'
            }

            if (this.horizontal) {
                classes += ' cf-c-collapsible--horizontal'
            }

            if (this.toggleFullHeight) {
                classes += ' cf-c-collapsible--toggle-full-height'
            }

            if (this.visible) {
                classes += ' cf-is-open'
            }

            if (this.shadow) {
                classes += ' cf-c-collapsible--shadow'
            }

            return classes
        }
    },

    watch: {
        isVisible (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.visible = this.isVisible
            }
        }
    },

    created () {
        EventBus.$on('collapsible-header-clicked', this.triggerCollapse)
    },

    beforeDestroy () {
        EventBus.$off('collapsible-header-clicked', this.triggerCollapse)
    },

    methods: {
        toggleCollapse () {
            this.visible = !this.visible
            this.$emit('visibility-changed', this.visible)
        },

        triggerCollapse (id) {
            this.componentId === id &&
                this.fullHeaderClickable &&
                this.toggleCollapse()
        }
    }
}
</script>
