<template>
    <div class="cf-c-checkbox-multiselect" @blur.capture="handleBlur">
        <v-multiselect
            ref="multiselect"
            v-model="selected"
            :tabindex="tabindex"
            open-direction="bottom"
            :multiple="true"
            :close-on-select="closeOnSelect"
            :clear-on-select="false"
            :class="wrapClasses"
            :options="options"
            :label="label"
            :track-by="trackBy"
            :disabled="disabled"
            :placeholder="placeholder"
            :searchable="searchable"
            @input="handleInput"
            @tag="$emit('tag', $event)"
            @select="$emit('select', $event)"
            @close="
                $emit(
                    'close',
                    $event && $event.target ? $event.target.value : ''
                )
            "
            @remove="$emit('remove', $event)"
        >
            <template slot="option" slot-scope="props">
                <app-checkbox
                    class="cf-c-checkbox-wrapper cf-u-mr-md cf-u-font-size-stack cf-u-color-gray-02 cf-u-flex-align-center"
                    checkbox-input-classes="cf-c-checkbox-input cf-u-cursor-pointer cf-u-mr-sm cf-u-flex-align-center"
                    :value="isChecked(props.option)"
                    :title="optionTitle(props.option)"
                >
                    <template slot="title">
                        <span class="cf-c-checkbox-multiselect__label">{{
                            props.option[label]
                        }}</span>
                    </template>
                </app-checkbox>
            </template>
        </v-multiselect>
        <template slot="singleLabel" slot-scope="props">
            {{ props }}
        </template>
        <span v-if="!!errorMessage" class="cf-c-input__error">
            <span v-if="showErrorMessage">{{ errorMessage }}</span>
        </span>
    </div>
</template>

<script>
import { isUndefined, isNil } from 'lodash-es'

export default {
    props: {
        options: {
            type: Array,
            required: false,
            default: () => []
        },

        label: {
            type: String,
            required: false,
            default: 'label'
        },

        trackBy: {
            type: String,
            required: false,
            default: 'value'
        },

        value: {
            type: [Array, Number, String],
            required: false,
            default: () => []
        },

        initialValue: {
            type: [Array, Number, String],
            required: false,
            default: () => []
        },

        errorMessage: {
            type: String,
            required: false,
            default: ''
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        disabledCustom: {
            type: Boolean,
            required: false,
            default: false
        },

        tabindex: {
            type: Number,
            required: false,
            default: 0
        },

        noBorderRadius: {
            type: Boolean,
            required: false,
            default: false
        },

        placeholder: {
            type: String,
            required: false,
            default: ''
        },

        taggable: {
            type: Boolean,
            default: false
        },

        noMinWidth: {
            type: Boolean,
            required: false,
            default: false
        },

        showErrorMessage: {
            type: Boolean,
            required: false,
            default: true
        },

        searchable: {
            type: Boolean,
            required: false,
            default: true
        },
        closeOnSelect: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data () {
        return {
            selected: null
        }
    },

    computed: {
        hasValue () {
            return !isNil(this.value)
        },

        wrapClasses () {
            return `
        ${this.errorMessage ? 'multiselect__tags--red-border' : ''}
        ${
            this.noBorderRadius
                ? 'cf-c-multiselect cf-c-multiselect--no-border-radius'
                : ''
        }
        ${
            this.value.length > 0
                ? 'cf-c-multiselect cf-c-multiselect--has-text'
                : ''
        }
        ${
            this.disabledCustom
                ? 'cf-c-multiselect cf-c-multiselect--disabled-custom'
                : ''
        }
      ${this.noMinWidth ? 'cf-c-multiselect--no-min-width' : ''}
      `
        }
    },

    watch: {
        value (newValue) {
            if (!isNil(newValue)) {
                this.selected = [...newValue]
            }
        }
    },

    mounted () {
        if (!isUndefined(this.value)) {
            this.selected = [...this.value]
        }

        if (this.initialValue.length) {
            this.selected = [...this.initialValue]
        }
    },

    methods: {
        handleInput (selected) {
            this.$emit(
                'input',
                selected.map(item => ({ ...item, value: true }))
            )
        },

        isChecked (option) {
            return (
                !!this.selected &&
                this.selected.some(
                    selected => selected[this.trackBy] === option[this.trackBy]
                )
            )
        },

        handleBlur () {
            if (this.$refs.multiselect) {
                this.$refs.multiselect.isOpen = false
            }
        },

        optionTitle (option) {
            return option.tooltip ?? ''
        }
    }
}
</script>
