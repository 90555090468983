import VueScrollTo from 'vue-scrollto'
import { includes } from 'lodash-es'
// import {document} from "@/store/modules/document";

export function scrollTo (
    elementIdentifier,
    container,
    offset = -150,
    delay = 500,
    easing = 'ease-in'
) {
    VueScrollTo.scrollTo(elementIdentifier, delay, {
        container,
        offset,
        easing
    })
}

export function isModalOpen () {
    return includes(document.body.classList, 'v--modal-block-scroll')
}

export function calculateTopOffsetOfElementInDomTree (
    element,
    nestedCount = null
) {
    let offsetTop = 0
    let currentNested = 0

    while (element) {
        offsetTop += element.offsetTop
        element = element.offsetParent
        currentNested++

        if (nestedCount && nestedCount <= currentNested) {
            break
        }
    }

    return offsetTop
}

export function loadScript (attributes) {
    let scriptTag = document.createElement('script')

    Object.keys(attributes).forEach(key =>
        scriptTag.setAttribute(key, attributes[key])
    )

    document.head.appendChild(scriptTag)
}

export function printDocumentFromCanvas (selector, title = '') {
    let windowContent =
        '<!DOCTYPE html><html><head><title>' + title + '</title></head><body>'
    selector.forEach(canvas => {
        windowContent +=
            '<img style="height:100%; width:100%" src="' +
            canvas.toDataURL() +
            '">'
    })
    windowContent += '</body></html>'

    const printerContent = document.createElement('iframe')
    printerContent.style.display = 'none'
    document.body.appendChild(printerContent)

    printerContent.contentWindow.document.open()
    printerContent.contentWindow.document.write(windowContent)
    printerContent.contentWindow.document.close()

    printerContent.contentWindow.addEventListener('load', () => {
        printerContent.contentWindow.print()
    })

    printerContent.contentWindow.addEventListener('onafterprint', () => {
        closePrintWindow()
    })

    printerContent.contentWindow.addEventListener('onbeforeunload', () => {
        closePrintWindow()
    })

    const closePrintWindow = () => {
        document.body.removeChild(printerContent)
    }
}
