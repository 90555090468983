<template>
    <div
        class="cf-c-checkbox-list"
        :class="[{ 'cf-c-checkbox-list--small': small }]"
    >
        <div
            v-if="
                list.length || ($slots['add-new'] && $slots['add-new'].length)
            "
            class="cf-u-display-flex cf-u-flex-direction-column"
        >
            <template v-if="byCategory">
                <app-collapsible
                    v-for="(item, index) in list"
                    :key="index"
                    highlighted
                    :horizontal="false"
                >
                    <template slot="target">
                        <slot name="target">
                            {{ item[label] }}
                        </slot>
                    </template>

                    <template slot="content">
                        <div
                            v-for="(asset, index) in item.assets"
                            :key="index"
                            :class="[
                                'cf-c-checkbox-list__item',
                                {
                                    'cf-c-checkbox-list__selected': isItemSelected(
                                        asset
                                    )
                                }
                            ]"
                            @click="handleClick(asset)"
                        >
                            {{ asset[label] }}
                        </div>
                    </template>
                </app-collapsible>
            </template>
            <template v-else>
                <div
                    v-for="item in list"
                    :key="item[trackBy]"
                    :class="[
                        'cf-c-checkbox-list__item',
                        { 'cf-c-checkbox-list__selected': isItemSelected(item) }
                    ]"
                    @click="handleClick(item)"
                >
                    {{ item[label] }}
                </div>
            </template>
        </div>
        <div v-if="shouldShowEmptyState" class="cf-c-checkbox-list__empty-list">
            {{ $t(emptyListMessage) }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        },

        trackBy: {
            type: String,
            required: false,
            default: 'id'
        },

        emptyListMessage: {
            type: String,
            default: 'COMMON.CHECKBOX_SEARCH_EMPTY_LIST'
        },

        byCategory: {
            type: Boolean,
            required: false,
            default: false
        },

        label: {
            type: String,
            required: false,
            default: 'name'
        },

        small: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data () {
        return {
            selectedItem: null
        }
    },

    computed: {
        shouldShowEmptyState () {
            return (
                !this.list.length &&
                this.emptyListMessage &&
                (!this.$slots['add-new'] ||
                    (this.$slots['add-new'] && !this.$slots['add-new'].length))
            )
        }
    },

    methods: {
        handleClick (item) {
            this.selectedItem = item
            this.$emit('click', item)
        },

        isItemSelected (item) {
            return this.selectedItem
                ? this.selectedItem[this.trackBy] === item[this.trackBy]
                : false
        }
    }
}
</script>
