<template>
    <div
        class="cf-c-links-list"
        :class="[{ 'cf-c-links-list--separated': separated }]"
    >
        <div
            v-for="(item, index) in list"
            :key="index"
            class="cf-c-links-list__item"
        >
            <div class="cf-c-links-list-item">
                <span class="cf-c-links-list-item__label cf-u-color-gray-03">
                    {{ item[label] }}
                </span>
                <router-link
                    v-if="hasLinks"
                    class="cf-c-links-list-item__link"
                    :to="item.to"
                    target="_blank"
                >
                    <app-icon icon="link-attachment-icon" />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            required: true
        },

        label: {
            type: String,
            required: false,
            default: 'label'
        },

        hasLinks: {
            type: Boolean,
            required: false,
            default: true
        },

        separated: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>
