import { ROUTES_COMPANY_SETTINGS } from '@src/modules/company-settings/constants'

const MAIN_EXECUTIVE = 'main_executive'
const LEGAL_ADVISOR = 'legal_advisor'
const COMPLIANCE_OFFICER = 'compliance_officer'
const HEAD_OF_IT = 'head_of_it'
const SECURITY_OFFICER = 'security_officer'
const HR_OFFICER = 'hr_officer'
const OFFICE_MANAGER = 'office_manager'
const INTERNAL_AUDITOR = 'internal_auditor'
const PURCHASING_MANAGER = 'purchasing_manager'
const MARKETING_OFFICER = 'marketing_officer'
const FINANCIAL_OFFICER = 'financial_officer'
const RESOURCE_APPROVER = 'resource_approver'
const DOCUMENT_UPLOAD_PERMISSION = 'disabled_file_upload'
const DOCUMENT_DOWNLOAD_PERMISSION = 'disabled_document_download'
const DOCUMENT_ACCESS_TO_APPROVED_DOCS = 'disabled_access_to_approved_docs'
const CANCELED_PLAN = 'canceled_plan'
const FREE_PLAN = 'free_plan'
const FREEZED_PLAN = 'freezed_plan'
const PAID_PLAN = 'paid_plan'

const COMPANY_SETTINGS_ROUTES = {
    INDEX: { name: 'company-settings' },
    BASIC_INFORMATION: { name: 'company-settings.basic-information' },
    PROJECT_SETTINGS: { name: 'company-settings.project-settings' },
    INTEGRATIONS: { name: 'company-settings.integrations' },
    SECURITY: {
        INDEX: { name: 'company-settings.security' }
    },
    SUBSCRIPTION: {
        INDEX: { name: 'company-settings.subscription' }
    }
}

const COMPANY_SETTINGS_MY_PERMISSIONS = {
    USER_MANAGEMENT_ACCESS: 'conformio_user_management_access',
    SUBSCRIPTIONS_ACCESS: 'conformio_subscriptions_access',
    BASIC_INFORMATION_ACCESS: 'conformio_basic_information_access',
    SECURITY_ACCESS: 'conformio_security_access',
    PROJECT_SETTINGS_ACCESS: 'conformio_project_settings_access',
    INTEGRATIONS_ACCESS: 'conformio_integrations_access'
}

const COMPANY_SETTINGS_OPTIONS = [
    {
        route: ROUTES_COMPANY_SETTINGS.USER_MANAGEMENT,
        title: 'COMPANY_SETTINGS.USER_MANAGEMENT.TITLE',
        permission: COMPANY_SETTINGS_MY_PERMISSIONS.USER_MANAGEMENT_ACCESS
    },
    {
        title: 'COMPANY_SETTINGS.SUBSCRIPTION.TITLE',
        route: COMPANY_SETTINGS_ROUTES.SUBSCRIPTION.INDEX,
        permission: COMPANY_SETTINGS_MY_PERMISSIONS.SUBSCRIPTIONS_ACCESS
    },
    {
        title: 'COMPANY_SETTINGS.BASIC_INFORMATION.TITLE',
        route: COMPANY_SETTINGS_ROUTES.BASIC_INFORMATION,
        permission: COMPANY_SETTINGS_MY_PERMISSIONS.BASIC_INFORMATION_ACCESS
    },
    {
        title: 'COMPANY_SETTINGS.SECURITY.TITLE',
        route: COMPANY_SETTINGS_ROUTES.SECURITY.INDEX,
        permission: COMPANY_SETTINGS_MY_PERMISSIONS.SECURITY_ACCESS
    },
    {
        title: 'COMPANY_SETTINGS.PROJECT_SETTINGS.TITLE',
        route: COMPANY_SETTINGS_ROUTES.PROJECT_SETTINGS,
        permission: COMPANY_SETTINGS_MY_PERMISSIONS.PROJECT_SETTINGS_ACCESS
    },
    {
        title: 'COMPANY_SETTINGS.INTEGRATIONS.TITLE',
        subtitle: 'COMPANY_SETTINGS.INTEGRATIONS.SECTION',
        description: 'COMPANY_SETTINGS.INTEGRATIONS.DESCRIPTION',
        route: COMPANY_SETTINGS_ROUTES.INTEGRATIONS,
        permission: COMPANY_SETTINGS_MY_PERMISSIONS.INTEGRATIONS_ACCESS
    }
]

const TIERS = {
    BASIC: 'basic',
    ADVANCED: 'advanced',
    PREMIUM: 'premium',
    CONFORMIO_STARTER: 'conformio-starter',
    CONFORMIO_PROFESSIONAL: 'conformio-professional',
    CONFORMIO_ADVANCED: 'conformio-advanced'
}

const SUBSCRIPTIONS_OPTIONS = {
    ANNUAL: 'annual',
    MONTHLY: 'monthly'
}

const TIERS_MAX_USERS = {
    CONFORMIO_STARTER: 3,
    CONFORMIO_PROFESSIONAL: 5,
    CONFORMIO_ADVANCED: null
}

const TIERS_EMPLOYEES_RECOMENDATIONS = {
    BASIC: 10,
    ADVANCED: 50,
    PREMIUM: 200
}

const PAYMENT_TIERS_OPTIONS = [
    {
        title: 'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.USERS_INCLUDED.TITLE',
        tip: false,
        tiers: [
            {
                name: TIERS.BASIC,
                value:
                    'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.USERS_INCLUDED.BASIC_VALUE',
                tip: false
            },
            {
                name: TIERS.ADVANCED,
                value:
                    'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.USERS_INCLUDED.ADVANCED_VALUE',
                tip: false
            },
            {
                name: TIERS.PREMIUM,
                value:
                    'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.USERS_INCLUDED.PREMIUM_VALUE',
                tip: false
            }
        ]
    },
    {
        title: 'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.MODULE_ACCESS.TITLE',
        tip: false,
        tiers: [
            {
                name: TIERS.BASIC,
                value: true,
                tip: false
            },
            {
                name: TIERS.ADVANCED,
                value: true,
                tip: false
            },
            {
                name: TIERS.PREMIUM,
                value: true,
                tip: false
            }
        ]
    },
    {
        title:
            'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.MANDATORY_DOCUMENTS_ENABLING.TITLE',
        tip: false,
        tiers: [
            {
                name: TIERS.BASIC,
                value: true,
                tip: false
            },
            {
                name: TIERS.ADVANCED,
                value: true,
                tip: false
            },
            {
                name: TIERS.PREMIUM,
                value: true,
                tip: false
            }
        ]
    },
    {
        title:
            'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.OPTIONAL_DOCUMENTS.TITLE',
        tip: 'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.OPTIONAL_DOCUMENTS.TIP',
        tiers: [
            {
                name: TIERS.BASIC,
                value: false,
                tip: false
            },
            {
                name: TIERS.ADVANCED,
                value: true,
                tip: false
            },
            {
                name: TIERS.PREMIUM,
                value: true,
                tip: false
            }
        ]
    },
    {
        title: 'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.RISK_REGISTER.TITLE',
        tip: false,
        tiers: [
            {
                name: TIERS.BASIC,
                value:
                    'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.RISK_REGISTER.BASIC_VALUE',
                tip: false
            },
            {
                name: TIERS.ADVANCED,
                value:
                    'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.RISK_REGISTER.ADVANCED_VALUE',
                tip: false
            },
            {
                name: TIERS.PREMIUM,
                value:
                    'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.RISK_REGISTER.PREMIUM_VALUE',
                tip: false
            }
        ]
    },
    {
        title:
            'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.TASKS_AND_REMINDERS.TITLE',
        tip: false,
        tiers: [
            {
                name: TIERS.BASIC,
                value: true,
                tip: false
            },
            {
                name: TIERS.ADVANCED,
                value: true,
                tip: false
            },
            {
                name: TIERS.PREMIUM,
                value: true,
                tip: false
            }
        ]
    },
    {
        title:
            'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.DOWNLOADING_ALL_DOCUMENTS.TITLE',
        tip: false,
        tiers: [
            {
                name: TIERS.BASIC,
                value: true,
                tip: false
            },
            {
                name: TIERS.ADVANCED,
                value: true,
                tip: false
            },
            {
                name: TIERS.PREMIUM,
                value: true,
                tip: false
            }
        ]
    },
    {
        title:
            'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.SLACK_DROPBOX_INTEGRATION.TITLE',
        tip: false,
        tiers: [
            {
                name: TIERS.BASIC,
                value: false,
                tip: false
            },
            {
                name: TIERS.ADVANCED,
                value: true,
                tip: false
            },
            {
                name: TIERS.PREMIUM,
                value: true,
                tip: false
            }
        ]
    },
    {
        title:
            'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.SECURITY_AWARENESS.TITLE',
        tip: 'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.SECURITY_AWARENESS.TIP',
        tiers: [
            {
                name: TIERS.BASIC,
                value: false,
                tip: false
            },
            {
                name: TIERS.ADVANCED,
                value:
                    'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.SECURITY_AWARENESS.ADVANCED_VALUE',
                tip: false
            },
            {
                name: TIERS.PREMIUM,
                value:
                    'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.SECURITY_AWARENESS.PREMIUM_VALUE',
                tip: false
            }
        ]
    },
    {
        title: 'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.CONSULTATIONS.TITLE',
        tip: false,
        tiers: [
            {
                name: TIERS.BASIC,
                value: false,
                tip: false
            },
            {
                name: TIERS.ADVANCED,
                value:
                    'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.CONSULTATIONS.ADVANCED_VALUE',
                tip: false
            },
            {
                name: TIERS.PREMIUM,
                value:
                    'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.CONSULTATIONS.PREMIUM_VALUE',
                tip: false
            }
        ]
    },
    {
        title:
            'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.REVIEW_OF_DOCUMENTS.TITLE',
        tip: false,
        tiers: [
            {
                name: TIERS.BASIC,
                value: false,
                tip: false
            },
            {
                name: TIERS.ADVANCED,
                value:
                    'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.REVIEW_OF_DOCUMENTS.ADVANCED_VALUE',
                tip: false
            },
            {
                name: TIERS.PREMIUM,
                value:
                    'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.REVIEW_OF_DOCUMENTS.PREMIUM_VALUE',
                tip: false
            }
        ]
    },
    {
        title: 'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.EXPERT_CHECKS.TITLE',
        tip: false,
        tiers: [
            {
                name: TIERS.BASIC,
                value: false,
                tip: false
            },
            {
                name: TIERS.ADVANCED,
                value: false,
                tip: false
            },
            {
                name: TIERS.PREMIUM,
                value:
                    'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.EXPERT_CHECKS.PREMIUM_VALUE',
                tip:
                    'COMPANY_SETTINGS.PAYMENT_TIERS_OPTIONS.EXPERT_CHECKS.TIER_TIP'
            }
        ]
    }
]

const ADD_ONS = {
    [TIERS.BASIC]: {
        [SUBSCRIPTIONS_OPTIONS.MONTHLY]: [
            'COMPANY_SETTINGS.ADD_ONS.BASIC.CONSULTATIONS',
            'COMPANY_SETTINGS.ADD_ONS.BASIC.DOCUMENTS_REVIEW'
        ],
        [SUBSCRIPTIONS_OPTIONS.ANNUAL]: [
            'COMPANY_SETTINGS.ADD_ONS.BASIC.FOUNDATION_COURSE'
        ]
    },
    [TIERS.ADVANCED]: {
        [SUBSCRIPTIONS_OPTIONS.MONTHLY]: [
            'COMPANY_SETTINGS.ADD_ONS.ADVANCED.SECURITY_TRAINING',
            'COMPANY_SETTINGS.ADD_ONS.ADVANCED.CONSULTATIONS',
            'COMPANY_SETTINGS.ADD_ONS.ADVANCED.DOCUMENTS_REVIEW'
        ],
        [SUBSCRIPTIONS_OPTIONS.ANNUAL]: [
            'COMPANY_SETTINGS.ADD_ONS.ADVANCED.FOUNDATION_COURSE',
            'COMPANY_SETTINGS.ADD_ONS.ADVANCED.INTERNAL_AUDITOR_COURSE'
        ]
    },
    [TIERS.PREMIUM]: {
        [SUBSCRIPTIONS_OPTIONS.MONTHLY]: [
            'COMPANY_SETTINGS.ADD_ONS.PREMIUM.SECURITY_TRAINING',
            'COMPANY_SETTINGS.ADD_ONS.PREMIUM.CONSULTATIONS',
            'COMPANY_SETTINGS.ADD_ONS.PREMIUM.DOCUMENTS_REVIEW',
            'COMPANY_SETTINGS.ADD_ONS.PREMIUM.PRE_AUDIT_CHECK'
        ],
        [SUBSCRIPTIONS_OPTIONS.ANNUAL]: [
            'COMPANY_SETTINGS.ADD_ONS.PREMIUM.FOUNDATION_COURSE',
            'COMPANY_SETTINGS.ADD_ONS.PREMIUM.INTERNAL_AUDITOR_COURSE'
        ]
    }
}

const ROLES = {
    MAIN_EXECUTIVE,
    LEGAL_ADVISOR,
    COMPLIANCE_OFFICER,
    HEAD_OF_IT,
    SECURITY_OFFICER,
    HR_OFFICER,
    OFFICE_MANAGER,
    INTERNAL_AUDITOR,
    PURCHASING_MANAGER,
    MARKETING_OFFICER,
    FINANCIAL_OFFICER,
    RESOURCE_APPROVER
}

const COMPANY_ROLES = [
    MAIN_EXECUTIVE,
    LEGAL_ADVISOR,
    COMPLIANCE_OFFICER,
    HEAD_OF_IT,
    SECURITY_OFFICER,
    HR_OFFICER,
    OFFICE_MANAGER,
    INTERNAL_AUDITOR,
    PURCHASING_MANAGER,
    MARKETING_OFFICER,
    FINANCIAL_OFFICER,
    RESOURCE_APPROVER
]

const PLAN_PERMISSIONS = {
    DOCUMENT_DOWNLOAD_PERMISSION,
    DOCUMENT_UPLOAD_PERMISSION,
    DOCUMENT_ACCESS_TO_APPROVED_DOCS
}

const PROJECT_ROLES = ['sponsor', 'project_manager']

const MANUAL_PROVIDER = 'manual'

const COMPANY = {
    ROLES,
    COMPANY_ROLES,
    PROJECT_ROLES,
    DEPARTMENT_HEADS: 'department_heads',
    PLAN_PERMISSIONS,
    CANCELED_PLAN,
    FREE_PLAN,
    FREEZED_PLAN,
    PAID_PLAN,
    PAYMENT_TIERS_OPTIONS,
    SUBSCRIPTIONS_OPTIONS,
    TIERS_EMPLOYEES_RECOMENDATIONS,
    TIERS,
    TIERS_MAX_USERS,
    ADD_ONS,
    MANUAL_PROVIDER,
    COMPANY_SETTINGS_OPTIONS
}

export { COMPANY, COMPANY_SETTINGS_ROUTES, COMPANY_SETTINGS_MY_PERMISSIONS }
