<template>
    <div class="cf-c-multiple-checklist">
        <section-item
            v-for="(item, index) in items"
            :key="index"
            :item="item"
            :label="item[label]"
            :disabled="item.disabled"
            :is-limit-exceeded="isLimitExceeded"
            @input="handleInput($event, item)"
        />
        <add-item :label="addLabel" @add="$emit('add', $event)" />
    </div>
</template>

<script>
import SectionItem from './MultipleChecklist/SectionItem'
import AddItem from './MultipleChecklist/AddItem'

export default {
    components: {
        SectionItem,
        AddItem
    },

    props: {
        trackBy: {
            type: String,
            required: true
        },

        label: {
            type: String,
            required: true
        },

        addLabel: {
            type: String,
            required: true
        },

        items: {
            type: Array,
            required: true
        },

        isLimitExceeded: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    watch: {
        items: {
            handler (items) {
                this.attachCheckedProperty(items)
            },
            deep: true,
            immediate: true
        }
    },

    created () {
        this.attachCheckedProperty(this.items)
    },

    methods: {
        handleInput (value, item) {
            this.$emit('input', { value, item })
        },

        attachCheckedProperty (items) {
            items.forEach(item => {
                this.$set(item, 'checked', !!item.checked)
            })
        }
    }
}
</script>
