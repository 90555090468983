import axios from 'axios'

const ENDPOINTS = {
    CORRECTIVE_ACTIONS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/corrective-actions`,
    CORRECTIVE_ACTION: (companyId, regulationId, correctiveActionId) =>
        `companies/${companyId}/regulations/${regulationId}/corrective-actions/${correctiveActionId}`,
    CORRECTIONABLE: (companyId, regulationId, correctionablePivotId) =>
        `companies/${companyId}/regulations/${regulationId}/correctionables/${correctionablePivotId}`,
    CORRECTIONABLES: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/correctionables`
}

export default class CorrectiveActionService {
    /**
     * Get corrective actions for a regulation
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getCorrectiveActions (companyId, regulationId) {
        try {
            const { data } = await axios.get(
                ENDPOINTS.CORRECTIVE_ACTIONS(companyId, regulationId)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Crete a corrective action for an incident
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Object} payload
     *
     * @returns Promise
     */
    async addCorrectiveAction (companyId, regulationId, payload) {
        try {
            const { data } = await axios.post(
                ENDPOINTS.CORRECTIVE_ACTIONS(companyId, regulationId),
                payload
            )
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update corrective action
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} id
     * @param {Object} payload
     *
     * @returns Promise
     */
    async updateCorrectiveAction (companyId, regulationId, id, payload) {
        try {
            const route = ENDPOINTS.CORRECTIVE_ACTION(
                companyId,
                regulationId,
                id
            )
            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Create correctionable
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Array} correctionableData
     *
     * @returns Promise
     */
    async createCorrectionable (companyId, regulationId, correctionableData) {
        try {
            const { data } = await axios.post(
                ENDPOINTS.CORRECTIONABLES(companyId, regulationId),
                correctionableData
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Detach correctionable from corrective action
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} correctionablePivotId
     *
     * @returns Promise
     */
    async detachCorrectionable (
        companyId,
        regulationId,
        correctionablePivotId
    ) {
        try {
            const { data } = await axios.delete(
                ENDPOINTS.CORRECTIONABLE(
                    companyId,
                    regulationId,
                    correctionablePivotId
                )
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Delete corrective action
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} id
     *
     * @returns Promise
     */
    async deleteCorrectiveAction (companyId, regulationId, id) {
        try {
            const { data } = await axios.delete(
                ENDPOINTS.CORRECTIVE_ACTION(companyId, regulationId, id)
            )

            return data
        } catch (error) {
            throw error
        }
    }
}

export const correctiveActionService = new CorrectiveActionService()
