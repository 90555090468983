var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('textarea',{directives:[{name:"char-counter",rawName:"v-char-counter",value:({ charLimit: _vm.charLimit, emitInput: false }),expression:"{ charLimit: charLimit, emitInput: false }"}],ref:"textarea",class:[
            'cf-c-textarea',
            { 'cf-has-text': _vm.value },
            { 'cf-c-textarea--full-width': _vm.fullWidth },
            { 'cf-c-textarea--min-height-full': _vm.minHeightFull },
            { 'cf-c-textarea--min-height-sm-double': _vm.minHeightSmDouble },
            { 'cf-c-textarea--resize-arrow-disabled': _vm.resizeArrowDisabled },
            { 'cf-c-textarea--has-error': _vm.errorMessage },
            { 'cf-c-textarea--min-height-15': _vm.minHeight15 },
            { 'cf-c-textarea--background-custom': _vm.backgroundCustom }
        ],attrs:{"id":_vm.id,"disabled":_vm.disabled,"tabindex":_vm.tabindex,"name":_vm.name,"rows":"1","spellcheck":"false","placeholder":_vm.$t(_vm.placeholder)},domProps:{"value":_vm.value},on:{"input":_vm.handleInput,"blur":function($event){return _vm.$emit('blur', $event.target.value)},"focus":function($event){return _vm.$emit('focus')}}}),(!!_vm.errorMessage)?_c('span',{staticClass:"cf-c-input__error"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }