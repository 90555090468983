const API_ROUTE = '/api/'

const CONFORMIO_API_URL = process.env.VUE_APP_BASE_API
const CONFORMIO_APP_URL = process.env.VUE_APP_BASE_APP

const DROPBOX_CLIENT_ID = process.env.VUE_APP_DROPBOX_CLIENT_ID
const SHAREPOINT_CLIENT_ID = process.env.VUE_APP_SHAREPOINT_CLIENT_ID
const SHAREPOINT_SCOPE = process.env.VUE_APP_SHAREPOINT_SCOPE
const SHAREPOINT_CODE_CHALLENGE = process.env.VUE_APP_SHAREPOINT_CODE_CHALLENGE

const VUE_APP_GTM_TRACKING_ID = process.env.VUE_APP_GTM_TRACKING_ID
const VUE_APP_MIXPANEL_TOKEN = process.env.VUE_APP_MIXPANEL_TOKEN

const RISKS_LIMITS_ASSETS = process.env.VUE_APP_RISKS_LIMITS_ASSETS
const RISKS_LIMITS_VULNERABILITIES =
    process.env.VUE_APP_RISKS_LIMITS_VULNERABILITIES
const RISKS_LIMITS_THREATS = process.env.VUE_APP_RISKS_LIMITS_THREATS

const SENTRY_KEY = process.env.VUE_APP_SENTRY_KEY
const SENTRY_ENV = process.env.VUE_APP_SENTRY_ENV

// HotJar
const HOTJAR_SITE_ID = process.env.VUE_APP_HOTJAR_SITE_ID

const PER_PAGE = Number(String(process.env.VUE_APP_PER_PAGE))

const STATUS_CODES = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    SHAREABLE_DOCUMENT_LINK_EXPIRED: 406,
    INVITATION_ALREADY_ACCEPTED: 409,
    TOKEN_EXPIRED: 410,
    VALIDATION_FAILED: 422,
    INTERNAL_SERVER_ERROR: 500,
    PRECONDITION_FAILED: 412,
    CANCELLED: 0
}

const RESPONSE_TYPE_ARRAY_BUFFER = 'arraybuffer'

const LOCALES = {
    en: 'ENGLISH',
    hr: 'CROATIAN'
}

const LOCALE_TAGS = {
    EN: 'en'
}

const DEFAULT_LOCALE = 'en'

const MFA_METHODS = {
    OTP: 'otp',
    OOB: 'oob'
}

const TOAST_TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info'
}

const TOAST_DURATIONS = {
    ERROR: 5000
}

const BLUESNAP_DOMAIN_PATHS = {
    sandbox: 'https://sandbox.bluesnap.com/source/web-sdk/bluesnap.js',
    production: 'https://ws.bluesnap.com/source/web-sdk/bluesnap.js'
}

const VUEX_MODULES = {
    COMPANY: 'company',
    ACCOUNT: 'account',
    REGULATION: 'regulation'
}

const MAIN_DROPDOWN_ITEMS = {
    LOGOUT: 'logout',
    SETTINGS: 'settings'
}

const PROGRESS_VARIATIONS = {
    WARNING: 'warning',
    SECONDARY: 'secondary',
    DANGER: 'danger'
}

const COMMON_PAGES = {
    FORBIDDEN: { name: 'forbidden' },
    PAYMENT_REQUIRED: { name: 'paymentRequired' },
    NOT_FOUND: { name: 'notFound' },
    SHAREABLE_DOCUMENT_LINK_EXPIRED: { name: 'shareableDocumentLinkExpired' },
    INVITATION_ALREADY_ACCEPTED: { name: 'invitationAlreadyAccepted' },
    TOKEN_EXPIRED: { name: 'tokenExpired' },
    USER_ACCOUT_DEACTIVATED: { name: 'userAccountDeactivated' }
}

const YES_NO_OPTIONS = [
    { value: true, label: 'COMMON.YES' },
    { value: false, label: 'COMMON.NO' }
]

const TOP_COUNTRY_CODES = ['US', 'CA', 'GB', 'AU', 'HR']

const INPUT_IDENTIFIERS =
    'input:not([type="search"]), select, .v-select, .vs__selected, textarea'
const FOCUSABLE_INPUTS = 'input, select, textarea'

const FILENAME_REGEX = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/

const ROUTES = {
    HOME: { name: 'home' },
    MFA: {
        VERIFY: { name: 'verify-mfa' },
        SETUP: { name: 'setup-mfa' }
    },
    RESPONSIBILITY_MATRIX: {
        INDEX: { name: 'responsibility-matrix' }
    },
    MY_WORK: {
        INDEX: { name: 'my-work' },
        OVERVIEW: { name: 'my-work.overview' },
        TASKS: {
            INDEX: { name: 'my-work.tasks' },
            MY_TASKS: { name: 'my-work.tasks.my-tasks' },
            TASKS_FOR_OTHERS: { name: 'my-work.tasks.tasks-for-others' },
            SINGLE_TASK: { name: 'my-work.tasks.single-task' }
        },
        DISCUSSIONS: {
            name: 'my-work.my-discussions'
        },
        STEPS: { name: 'my-work.steps' },
        DOCUMENTS: { name: 'my-work.documents' },
        REGISTERS: { name: 'my-work.registers' }
    },
    REGISTERS_AND_MODULES: {
        name: 'registers-and-modules',
        title: 'REGISTERS_AND_MODULES.TITLE'
    },
    ADVISERA_CONFORMIO: 'https://advisera.com/conformio/'
}

const FILTER_CLASSES = {
    APPLIED: 'cf-u-color-white cf-u-bg-primary',
    NOT_APPLIED: 'cf-u-color-primary cf-u-bg-white'
}

const CHART_TYPES = {
    DOUGHNUT: 'doughnut'
}

const SUPPORTED_IMAGE_FORMATS = '.jpeg,.jpg,.png'

const MENTION_SYMBOL = '@'
const MENTION_ENTRY_SYMBOL = '@['
const SPACE_SYMBOL = ' '

const LOCAL_STORAGE_ITEMS = {
    LOGIN_REDIRECT_PATH: 'loginRedirectPath'
}

const SSE_EVENTS = {
    STEP_UPDATED: 'App\\Events\\StepUpdated'
}

const JOB_TITLES_OPTION_LENGTH = 30

export {
    CHART_TYPES,
    STATUS_CODES,
    LOCALES,
    LOCALE_TAGS,
    DEFAULT_LOCALE,
    MFA_METHODS,
    TOAST_TYPES,
    TOAST_DURATIONS,
    BLUESNAP_DOMAIN_PATHS,
    API_ROUTE,
    CONFORMIO_API_URL,
    CONFORMIO_APP_URL,
    DROPBOX_CLIENT_ID,
    SHAREPOINT_CLIENT_ID,
    SHAREPOINT_SCOPE,
    SHAREPOINT_CODE_CHALLENGE,
    VUEX_MODULES,
    MAIN_DROPDOWN_ITEMS,
    PROGRESS_VARIATIONS,
    COMMON_PAGES,
    YES_NO_OPTIONS,
    TOP_COUNTRY_CODES,
    INPUT_IDENTIFIERS,
    FOCUSABLE_INPUTS,
    FILENAME_REGEX,
    ROUTES,
    FILTER_CLASSES,
    MENTION_SYMBOL,
    MENTION_ENTRY_SYMBOL,
    SPACE_SYMBOL,
    SUPPORTED_IMAGE_FORMATS,
    RESPONSE_TYPE_ARRAY_BUFFER,
    LOCAL_STORAGE_ITEMS,
    VUE_APP_GTM_TRACKING_ID,
    VUE_APP_MIXPANEL_TOKEN,
    RISKS_LIMITS_ASSETS,
    RISKS_LIMITS_THREATS,
    RISKS_LIMITS_VULNERABILITIES,
    SENTRY_KEY,
    SENTRY_ENV,
    SSE_EVENTS,
    JOB_TITLES_OPTION_LENGTH,
    PER_PAGE,
    HOTJAR_SITE_ID
}
