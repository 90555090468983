<template>
    <div class="cf-c-radio-group">
        <div class="cf-c-radio-group__ctn">
            <template v-for="(input, index) in inputs">
                <app-radio-input
                    v-show="!input.hidden"
                    :key="index"
                    class="cf-c-radio-group__item"
                    :disabled="disabled"
                    :name="name"
                    :label="input.label"
                    :radio-value="input.value"
                    :value="value"
                    :checked="isOptionChecked(input.value, index)"
                    @input="handleRadioInput($event, index)"
                />
            </template>
            <div class="cf-c-radio-group__item cf-c-radio-group__addition">
                <app-input
                    :value="textValue"
                    :disabled="disabled || !isOtherOptionSelected"
                    :error-message="errorMessage"
                    @input="handleInput"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { uniqueId, initial } from 'lodash-es'

export default {
    props: {
        inputs: {
            type: Array,
            required: true
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        value: {
            type: [String, Boolean],
            required: false,
            default: ''
        },

        errorMessage: {
            type: String,
            required: false,
            default: ''
        }
    },
    data () {
        return {
            selectedValue: '',
            textValue: '',
            isOtherOptionSelected: false,
            name: uniqueId()
        }
    },

    computed: {
        isPredefinedValueSelected () {
            return initial(this.inputs).some(
                input => input.value === this.value
            )
        }
    },

    watch: {
        value () {
            this.init()
        }
    },

    created () {
        this.init()
    },

    methods: {
        init () {
            this.selectedValue = this.value

            if (!this.isPredefinedValueSelected) {
                this.textValue = this.value
                this.isOtherOptionSelected = true
            }
        },

        isLastInput (index) {
            return index === this.inputs.length - 1
        },

        isOptionChecked (value, index) {
            return this.isLastInput(index) && this.isOtherOptionSelected
                ? true
                : this.selectedValue === value
        },

        handleRadioInput (value, index) {
            this.isOtherOptionSelected = this.isLastInput(index)
            this.selectedValue = this.inputs[index].value
            this.isOtherOptionSelected && this.handleInput(this.textValue)

            this.handleChange()
        },

        handleInput (value) {
            this.textValue = value
            this.selectedValue = this.textValue
            this.handleChange()
        },

        handleChange () {
            this.$emit('input', this.selectedValue)
        }
    }
}
</script>
