import { REGISTER_WIZARD_ROUTES } from '@/constants'
import { calculateRiskLevel, getRiskOwnerName } from '@/services/utils/risks'

const UNCOMPLETED_RISK_REGISTER_SIDEBAR_ROUTES = [
    {
        route: REGISTER_WIZARD_ROUTES.RISKS.WIZARD,
        label: 'COMMON.WIZARD'
    },
    {
        route: REGISTER_WIZARD_ROUTES.RISKS.PROPERTIES,
        label: 'COMMON.PROPERTIES'
    },
    {
        route: REGISTER_WIZARD_ROUTES.RISKS.DISCUSSIONS,
        label: 'COMMON.DISCUSSIONS'
    }
]

const COMPLETED_RISK_REGISTER_SIDEBAR_ROUTES = [
    {
        route: REGISTER_WIZARD_ROUTES.RISKS.COMPLETED_WIZARD,
        label: 'COMMON.WIZARD'
    },
    {
        route: REGISTER_WIZARD_ROUTES.RISKS.COMPLETED_PROPERTIES,
        label: 'COMMON.PROPERTIES'
    },
    {
        route: REGISTER_WIZARD_ROUTES.RISKS.COMPLETED_DISCUSSIONS,
        label: 'COMMON.DISCUSSIONS'
    }
]

const MODES = {
    SIMPLE_MODE: 'simple-mode',
    ADVANCED_MODE: 'advanced-mode'
}

const STEPS = {
    ASSESSMENT: 'assessment',
    REVIEW: 'review',
    TREATMENT: 'treatment',
    ACCEPTANCE: 'acceptance',
    ASSETS: 'assets',
    VULNERABILITIES: 'vulnerabilities',
    THREATS: 'threats',
    EVALUATION: 'evaluation'
}

const CONFIRMATION_ACTION_TYPES = {
    ACTION_TYPE_CONFIRM: 'confirm_risk',
    ACTION_TYPE_REJECT: 'reject_risk'
}

const ADVANCED_MODE_STEPS = [
    {
        label: 'REGISTERS.RISKS.STEPS.ASSESSMENT.LABEL',
        title: 'REGISTERS.RISKS.STEPS.ASSESSMENT.TITLE',
        value: STEPS.ASSESSMENT,
        description: 'REGISTERS.RISKS.STEPS.ASSESSMENT.DESCRIPTION'
    },
    {
        label: 'REGISTERS.RISKS.STEPS.REVIEW.LABEL',
        title: 'REGISTERS.RISKS.STEPS.REVIEW.TITLE',
        value: STEPS.REVIEW,
        description: 'REGISTERS.RISKS.STEPS.REVIEW.DESCRIPTION'
    },
    {
        label: 'REGISTERS.RISKS.STEPS.TREATMENT.LABEL',
        title: 'REGISTERS.RISKS.STEPS.TREATMENT.TITLE',
        value: STEPS.TREATMENT,
        description: 'REGISTERS.RISKS.STEPS.TREATMENT.DESCRIPTION'
    },
    {
        label: 'REGISTERS.RISKS.STEPS.ACCEPTANCE.LABEL',
        title: 'REGISTERS.RISKS.STEPS.ACCEPTANCE.TITLE',
        value: STEPS.ACCEPTANCE,
        description: 'REGISTERS.RISKS.STEPS.ACCEPTANCE.DESCRIPTION'
    }
]

const SIMPLE_MODE_STEPS = [
    {
        label: 'REGISTERS.RISKS.STEPS.ASSETS.LABEL',
        title: 'REGISTERS.RISKS.STEPS.ASSETS.TITLE',
        value: STEPS.ASSETS,
        description: 'REGISTERS.RISKS.STEPS.ASSETS.DESCRIPTION',
        pre_description: 'REGISTERS.RISKS.STEPS.ASSETS.PRE_DESCRIPTION'
    },
    {
        label: 'REGISTERS.RISKS.STEPS.VULNERABILITIES.LABEL',
        title: 'REGISTERS.RISKS.STEPS.VULNERABILITIES.TITLE',
        value: STEPS.VULNERABILITIES,
        description: 'REGISTERS.RISKS.STEPS.VULNERABILITIES.DESCRIPTION'
    },
    {
        label: 'REGISTERS.RISKS.STEPS.THREATS.LABEL',
        title: 'REGISTERS.RISKS.STEPS.THREATS.TITLE',
        value: STEPS.THREATS,
        description: 'REGISTERS.RISKS.STEPS.THREATS.DESCRIPTION'
    },
    {
        label: 'REGISTERS.RISKS.STEPS.EVALUATION.LABEL',
        title: 'REGISTERS.RISKS.STEPS.EVALUATION.TITLE',
        value: STEPS.EVALUATION,
        description: 'REGISTERS.RISKS.STEPS.EVALUATION.DESCRIPTION'
    },
    {
        label: 'REGISTERS.RISKS.STEPS.REVIEW.LABEL',
        title: 'REGISTERS.RISKS.STEPS.REVIEW.TITLE',
        value: STEPS.REVIEW,
        description: 'REGISTERS.RISKS.STEPS.REVIEW.DESCRIPTION'
    },
    {
        label: 'REGISTERS.RISKS.STEPS.TREATMENT.LABEL',
        title: 'REGISTERS.RISKS.STEPS.TREATMENT.TITLE',
        value: STEPS.TREATMENT,
        description: 'REGISTERS.RISKS.STEPS.TREATMENT.DESCRIPTION'
    },
    {
        label: 'REGISTERS.RISKS.STEPS.ACCEPTANCE.LABEL',
        title: 'REGISTERS.RISKS.STEPS.ACCEPTANCE.TITLE',
        value: STEPS.ACCEPTANCE,
        description: 'REGISTERS.RISKS.STEPS.ACCEPTANCE.DESCRIPTION'
    }
]

const RISK_SORT_KEYS = {
    ASSET: 'ASSET',
    VULNERABILITY: 'VULNERABILITY',
    THREAT: 'THREAT',
    IMPACT: 'IMPACT',
    LIKELIHOOD: 'LIKELIHOOD',
    LEVEL: 'LEVEL',
    OWNER: 'OWNER'
}

const RISK_SORT_VALUES = {
    ASSET_ASC: 'ASSET_ASC',
    ASSET_DESC: 'ASSET_DESC',
    VULNERABILITY_ASC: 'VULNERABILITY_ASC',
    VULNERABILITY_DESC: 'VULNERABILITY_DESC',
    THREAT_ASC: 'THREAT_ASC',
    THREAT_DESC: 'THREAT_DESC',
    IMPACT_ASC: 'IMPACT_ASC',
    IMPACT_DESC: 'IMPACT_DESC',
    LIKELIHOOD_ASC: 'LIKELIHOOD_ASC',
    LIKELIHOOD_DESC: 'LIKELIHOOD_DESC',
    LEVEL_ASC: 'LEVEL_ASC',
    LEVEL_DESC: 'LEVEL_DESC',
    OWNER_ASC: 'OWNER_ASC',
    OWNER_DESC: 'OWNER_DESC'
}

const RISK_SORT_ACTIONS = {
    [RISK_SORT_VALUES.ASSET_ASC]: (a, b) => {
        if (!a?.asset?.name || !b?.asset?.name) {
            return 0
        }

        return a.asset.name > b.asset.name ? 1 : -1
    },
    [RISK_SORT_VALUES.ASSET_DESC]: (a, b) => {
        if (!a?.asset?.name || !b?.asset?.name) {
            return 0
        }

        return a.asset.name < b.asset.name ? 1 : -1
    },
    [RISK_SORT_VALUES.VULNERABILITY_ASC]: (a, b) => {
        if (!a?.vulnerability?.name || !b?.vulnerability?.name) {
            return 0
        }

        return a.vulnerability.name > b.vulnerability.name ? 1 : -1
    },
    [RISK_SORT_VALUES.VULNERABILITY_DESC]: (a, b) => {
        if (!a?.vulnerability?.name || !b?.vulnerability?.name) {
            return 0
        }

        return a.vulnerability.name < b.vulnerability.name ? 1 : -1
    },
    [RISK_SORT_VALUES.THREAT_ASC]: (a, b) => {
        if (!a?.threat?.name || !b?.threat?.name) {
            return 0
        }

        return a.threat.name > b.threat.name ? 1 : -1
    },
    [RISK_SORT_VALUES.THREAT_DESC]: (a, b) => {
        if (!a?.threat?.name || !b?.threat?.name) {
            return 0
        }

        return a.threat.name < b.threat.name ? 1 : -1
    },
    [RISK_SORT_VALUES.IMPACT_ASC]: (a, b) => (a.impact > b.impact ? 1 : -1),
    [RISK_SORT_VALUES.IMPACT_DESC]: (a, b) => (a.impact < b.impact ? 1 : -1),
    [RISK_SORT_VALUES.LIKELIHOOD_ASC]: (a, b) =>
        a.likelihood > b.likelihood ? 1 : -1,
    [RISK_SORT_VALUES.LIKELIHOOD_DESC]: (a, b) =>
        a.likelihood < b.likelihood ? 1 : -1,
    [RISK_SORT_VALUES.LEVEL_ASC]: (a, b) =>
        calculateRiskLevel(a.likelihood, a.impact) >
        calculateRiskLevel(b.likelihood, b.impact)
            ? 1
            : -1,
    [RISK_SORT_VALUES.LEVEL_DESC]: (a, b) =>
        calculateRiskLevel(a.likelihood, a.impact) <
        calculateRiskLevel(b.likelihood, b.impact)
            ? 1
            : -1,
    [RISK_SORT_VALUES.OWNER_ASC]: (a, b) => {
        const firstRiskOwnerName = getRiskOwnerName(a)
        const seccondRiskOwnerName = getRiskOwnerName(b)

        if (!firstRiskOwnerName || !seccondRiskOwnerName) {
            return 0
        }

        return firstRiskOwnerName > seccondRiskOwnerName ? 1 : -1
    },
    [RISK_SORT_VALUES.OWNER_DESC]: (a, b) => {
        const firstRiskOwnerName = getRiskOwnerName(a)
        const seccondRiskOwnerName = getRiskOwnerName(b)

        if (!firstRiskOwnerName || !seccondRiskOwnerName) {
            return 0
        }

        return firstRiskOwnerName < seccondRiskOwnerName ? 1 : -1
    }
}

const FORM_MODES = {
    EDIT: 'edit',
    REVIEW: 'review',
    VIEW: 'view',
    EVALUATION: 'evaluation',
    TREATMENT: 'treatment',
    ACCEPTANCE: 'acceptance'
}

const STATUSES = {
    NEW: 'new',
    UNCHANGED: 'unchanged',
    EDITED: 'edited'
}

const STATUS_COLORS = {
    NEW: 'cf-u-color-primary',
    UNCHANGED: 'cf-u-color-secondary-light',
    EDITED: 'cf-u-color-amber'
}

const TREATMENT_STATUSES = {
    NEW: 'new',
    CHANGED: 'changed',
    PENDING: 'pending',
    REJECTED: 'rejected',
    CONFIRMED: 'confirmed',
    APPROVED: 'approved'
}

const RISK_CONFIRMATION_STATUSES = [
    TREATMENT_STATUSES.REJECTED,
    TREATMENT_STATUSES.APPROVED,
    TREATMENT_STATUSES.CONFIRMED
]

const STATUS_GUIDES = [
    {
        label: 'REGISTERS.RISKS.STATUSES.UNCHANGED',
        color: STATUS_COLORS.UNCHANGED,
        value: STATUSES.UNCHANGED
    },
    {
        label: 'REGISTERS.RISKS.STATUSES.EDITED',
        color: STATUS_COLORS.EDITED,
        value: STATUSES.EDITED
    },
    {
        label: 'REGISTERS.RISKS.STATUSES.NEW',
        color: STATUS_COLORS.NEW,
        value: STATUSES.NEW
    }
]

const FILTER_CATEGORIES = {
    ASSETS: 'assets',
    ASSET_CATEGORIES: 'asset_categories',
    VULNERABILITIES: 'vulnerabilities',
    THREATS: 'threats',
    THREAT_CATEGORIES: 'threat_categories'
}

const FILTER_ACTIONS = {
    [FILTER_CATEGORIES.ASSETS]: (item, filter) => filter[item.asset_id],
    [FILTER_CATEGORIES.VULNERABILITIES]: (item, filter) =>
        filter[item.vulnerability_id],
    [FILTER_CATEGORIES.THREATS]: (item, filter) => filter[item.threat_id],
    [FILTER_CATEGORIES.THREAT_CATEGORIES]: (item, filter) =>
        filter[item.threat.threat_category_id],
    [FILTER_CATEGORIES.ASSET_CATEGORIES]: (item, filter) =>
        filter[item.asset.asset_category_id]
}

const FILTER_OPTIONS = {
    DRAFT: 'draft',
    UNREVIEWED: 'unreviewed',
    UNCONFIRMED: 'unconfirmed'
}

const AVAILABLE_FILTERS = [
    {
        name: FILTER_OPTIONS.DRAFT,
        label: 'REGISTERS.RISKS.RISK_FILTERS.DRAFT'
    },
    {
        name: FILTER_OPTIONS.UNREVIEWED,
        label: 'REGISTERS.RISKS.RISK_FILTERS.UNREVIEWED'
    },
    {
        name: FILTER_OPTIONS.UNCONFIRMED,
        label: 'REGISTERS.RISKS.RISK_FILTERS.UNCONFIRMED'
    }
]

const RISK_TREATMENT_OPTIONS = {
    AVOID: 'avoid_risk',
    ACCEPT: 'accept_risk',
    TRANSFER: 'transfer_risk',
    DECREASE: 'use_safeguards'
}

const TASKS = {
    CONFIRM_RISK_DEADLINE: 2,
    CONFIRM_RISK_MESSAGE: 'REGISTERS.RISKS.TASKS.CONFIRM_RISK_TREATMENT_MESSAGE'
}

const SUBMIT_RISKS_CACHE_INTERVAL = 3000

const DEPARTMENT_COMPARISON_FIELDS = ['id']

const CONTROL_COMPARISON_FIELDS = ['id']

const HELP_STATUSES = [
    {
        label: 'REGISTERS.RISKS.STATUSES.UNCHANGED',
        color: STATUS_COLORS.UNCHANGED,
        value: STATUSES.UNCHANGED
    },
    {
        label: 'REGISTERS.RISKS.STATUSES.EDITED',
        color: STATUS_COLORS.EDITED,
        value: STATUSES.EDITED
    },
    {
        label: 'REGISTERS.RISKS.STATUSES.NEW',
        color: STATUS_COLORS.NEW,
        value: STATUSES.NEW
    }
]

const HELP_ICONS = [
    {
        name: 'warning-icon',
        label:
            'REGISTERS.RISKS.COMPLETED_RISKS_REGISTER.WIZARD.HELP_ICONS_LEGEND.RISK_HAS_INCIDENTS_NONCONFORMITIES'
    },
    {
        name: 'semaphore-icon',
        label:
            'REGISTERS.RISKS.COMPLETED_RISKS_REGISTER.WIZARD.HELP_ICONS_LEGEND.RISK_HAS_BEEN_REVIEW'
    },
    {
        name: 'ok-icon',
        label:
            'REGISTERS.RISKS.COMPLETED_RISKS_REGISTER.WIZARD.HELP_ICONS_LEGEND.RISK_HAS_BEEN_EVALUATED'
    },
    {
        name: 'remove-icon',
        label:
            'REGISTERS.RISKS.COMPLETED_RISKS_REGISTER.WIZARD.HELP_ICONS_LEGEND.REMOVE_RISK_PERMANENTLY'
    }
]

const AUTOSAVE_RISK_DEBOUNCE_TIME = 1500
const SCROLL_TO_NEW_RISK_DEBOUNCE_TIME = 500
const AUTOSAVE_RISK_SHOW_SAVE_TIME = 4000
const OPTION_BUTTON_CLICKED_TIMEOUT = 1000
const OPTION_BUTTON_ID = -1

const ACCEPTABLE_THRESHOLD = 2

const IMPACT_OPTIONS = {
    LOW: 0,
    MEDIUM: 1,
    HIGH: 2
}

const LIKELIHOOD_OPTIONS = {
    LOW: 0,
    MEDIUM: 1,
    HIGH: 2
}

const REGISTER_OF_RISKS = {
    SUBMIT_RISKS_CACHE_INTERVAL,
    UNCOMPLETED_RISK_REGISTER_SIDEBAR_ROUTES,
    COMPLETED_RISK_REGISTER_SIDEBAR_ROUTES,
    ADVANCED_MODE_STEPS,
    SIMPLE_MODE_STEPS,
    MODES,
    STEPS,
    FORM_MODES,
    STATUSES,
    STATUS_COLORS,
    STATUS_GUIDES,
    FILTER_CATEGORIES,
    FILTER_ACTIONS,
    RISK_SORT_KEYS,
    RISK_SORT_VALUES,
    RISK_SORT_ACTIONS,
    RISK_TREATMENT_OPTIONS,
    TREATMENT_STATUSES,
    TASKS,
    DEPARTMENT_COMPARISON_FIELDS,
    CONTROL_COMPARISON_FIELDS,
    CONFIRMATION_ACTION_TYPES,
    RISK_CONFIRMATION_STATUSES,
    HELP_STATUSES,
    HELP_ICONS,
    AUTOSAVE_RISK_DEBOUNCE_TIME,
    AUTOSAVE_RISK_SHOW_SAVE_TIME,
    ACCEPTABLE_THRESHOLD,
    IMPACT_OPTIONS,
    LIKELIHOOD_OPTIONS,
    OPTION_BUTTON_CLICKED_TIMEOUT,
    OPTION_BUTTON_ID,
    SCROLL_TO_NEW_RISK_DEBOUNCE_TIME,
    FILTER_OPTIONS,
    AVAILABLE_FILTERS
}

export { REGISTER_OF_RISKS }
