import { MY_PERMISSIONS } from '@src/constants'
import { myPermissionsGuard } from '@src/router/guards'
import { ROUTES_DOCUMENT_WIZARD } from './constants'

export default {
    path: 'document-wizard',
    name: ROUTES_DOCUMENT_WIZARD.BASE.name,
    component: () => import('./ModuleDocumentWizard'),
    props: route => ({
        ...route.params
    }),
    redirect: to => ({
        name: ROUTES_DOCUMENT_WIZARD.WIZARD.name,
        params: { ...to.params }
    }),
    children: [
        {
            path: 'document/:documentId',
            name: ROUTES_DOCUMENT_WIZARD.WIZARD.name,
            component: () => import('./DocumentWizard'),
            props: route => ({
                ...route.params
            }),
            meta: {
                pageTitle: 'DOCUMENT_WIZARD.TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.DOCUMENT_WIZARD_ACCESS]
            }
        },
        {
            path: 'document/:documentId/create-template',
            name: ROUTES_DOCUMENT_WIZARD.TEMPLATE.name,
            component: () => import('./create-template/CreateTemplate'),
            meta: {
                pageTitle: 'DOCUMENT_WIZARD.TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.DOCUMENT_WIZARD_ACCESS]
            }
        }
    ]
}
