<template>
    <div class="dw-editor__document">
        <pdf-viewer
            v-if="!hasErrorOccured"
            :file="url"
            @error="handleError"
            @fully-loaded="handleLoaded"
        />
        <div v-if="!loaded">
            <p>{{ $t('COMMON.LOADING') }}...</p>
        </div>
        <div v-if="hasErrorOccured">
            <p>
                {{ $t('DOCUMENT_MANAGEMENT_SYSTEM.UNABLE_TO_OPEN_DOCUMENT') }}
            </p>
            <app-button :disabled="isCompanyExpired" @click="handleDownload">
                {{ $t('COMMON.DOWNLOAD') }}
            </app-button>
        </div>
    </div>
</template>

<script>
import { fileDownload } from '@/services/utils/functions'
import { permissionControl } from '@/mixins'

export default {
    mixins: [permissionControl],
    props: {
        url: {
            type: String,
            required: false,
            default: ''
        },

        fileName: {
            type: String,
            required: false,
            default: ''
        }
    },

    data () {
        return {
            hasErrorOccured: false,
            loaded: false
        }
    },

    methods: {
        handleError (error) {
            this.hasErrorOccured = true
            this.loaded = true
            this.$emit('error', error)
        },

        handleLoaded () {
            this.loaded = true
            this.$emit('fully-loaded')
        },

        async handleDownload () {
            this.loaded = false
            try {
                await fileDownload(this.url, this.fileName)
            } finally {
                this.loaded = true
            }
        }
    }
}
</script>
