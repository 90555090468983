<template>
    <div
        class="cf-c-status-card cf-u-mr-sm-except-last"
        :class="[
            { 'cf-c-status-card--long-title': title.length > 30 },
            { 'cf-c-status-card--single-task': task },
            { 'is-active': isActive }
        ]"
        @click="$emit('click')"
    >
        <div class="cf-u-min-height-2">
            <h4 class="cf-c-status-card__title">
                {{ title }}
            </h4>
        </div>

        <slot name="content"></slot>

        <slot name="footer">
            <span
                class="cf-c-status-card_percentage-info cf-u-font-weight-bold"
            ></span>
        </slot>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },

        task: {
            type: Boolean,
            required: false,
            default: false
        },

        isActive: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style scoped>
.is-active {
    border: 2px solid #4788c6;
}
</style>
