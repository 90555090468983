import {
    REGISTER_WIZARD_ROUTES,
    REGISTER_TYPES,
    MY_PERMISSIONS,
    REGISTERS_PERMISSIONS
} from '@/constants'
import { myPermissionsGuard } from '@src/router/guards'

export default {
    name: REGISTER_WIZARD_ROUTES.RISK_TREATMENT_PLAN.INDEX.name,
    component: () => import('@/containers/RegisterWizard/RiskTreatmentPlan'),
    path: 'soa/risk-treatment-plan',
    props: true,
    redirect: to => ({
        name: REGISTER_WIZARD_ROUTES.RISK_TREATMENT_PLAN.WIZARD.name,
        params: { ...to.params }
    }),
    meta: {
        requiresAuth: true,
        register: REGISTER_TYPES.SOA
    },
    children: [
        {
            path: 'wizard',
            name: REGISTER_WIZARD_ROUTES.RISK_TREATMENT_PLAN.WIZARD.name,
            component: () =>
                import(
                    '@/components/registers/soa/risk-treatment-plan/WizardTab'
                ),
            props: true,
            meta: {
                pageTitle: 'REGISTERS.SOA.RISK_TREATMENT_PLAN.TITLE',
                withScaling: true,
                requiresAuth: true,
                register: REGISTER_TYPES.SOA,
                shouldSkipRouteInHistory: true,
                guards: [myPermissionsGuard],
                permissions: [
                    MY_PERMISSIONS.SOA_ACCESS,
                    REGISTERS_PERMISSIONS.STATEMENT_OF_APPLICABILITY
                ]
            }
        },
        {
            path: 'properties',
            name: REGISTER_WIZARD_ROUTES.RISK_TREATMENT_PLAN.PROPERTIES.name,
            component: () => import('@/components/registers/PropertiesTab'),
            props: true,
            meta: {
                pageTitle: 'REGISTERS.SOA.RISK_TREATMENT_PLAN.TITLE',
                withScaling: true,
                requiresAuth: true,
                register: REGISTER_TYPES.SOA,
                shouldSkipRouteInHistory: true,
                guards: [myPermissionsGuard],
                permissions: [
                    MY_PERMISSIONS.SOA_ACCESS,
                    REGISTERS_PERMISSIONS.STATEMENT_OF_APPLICABILITY
                ]
            }
        },
        {
            path: 'discussions',
            name: REGISTER_WIZARD_ROUTES.RISK_TREATMENT_PLAN.DISCUSSIONS.name,
            component: () => import('@/components/registers/Discussions'),
            props: true,
            meta: {
                pageTitle: 'REGISTERS.SOA.RISK_TREATMENT_PLAN.TITLE',
                withScaling: true,
                requiresAuth: true,
                register: REGISTER_TYPES.SOA,
                shouldSkipRouteInHistory: true,
                guards: [myPermissionsGuard],
                permissions: [
                    MY_PERMISSIONS.SOA_ACCESS,
                    REGISTERS_PERMISSIONS.STATEMENT_OF_APPLICABILITY
                ]
            }
        }
    ]
}
