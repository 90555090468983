import axios from 'axios'

const ENDPOINTS = {
    GET_REQUIREMENTS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/requirements`,
    GET_REQUIREMENT_DETAILS: (companyId, regulationId, requirementId) =>
        `companies/${companyId}/regulations/${regulationId}/requirements/${requirementId}`,
    ADD_REQUIREMENT: (companyId, regulationId, stepId) =>
        `companies/${companyId}/regulations/${regulationId}/steps/${stepId}/requirements`,
    EDIT_REQUIREMENT: (companyId, regulationId, stepId, requirementId) =>
        `companies/${companyId}/regulations/${regulationId}/steps/${stepId}/requirements/${requirementId}`,
    DELETE_REQUIREMENT: (companyId, regulationId, requirementId) =>
        `companies/${companyId}/regulations/${regulationId}/requirements/${requirementId}`,
    CONFIRM_INITIAL_REQUIREMENTS: (companyId, regulationId, stepId) =>
        `companies/${companyId}/regulations/${regulationId}/steps/${stepId}/requirements/confirm`,
    EXPORT_PDF: (companyId, regulationId, stepId) =>
        `companies/${companyId}/regulations/${regulationId}/steps/${stepId}/requirements/export`
}

export default class RiskOfRequirementsService {
    /**
     * Get registers of requirements for a regulation
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getRequirements (companyId, regulationId) {
        try {
            const route = ENDPOINTS.GET_REQUIREMENTS(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get step requirements
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} requirementId
     *
     * @returns Promise
     */
    async getRequirementDetails (companyId, regulationId, requirementId) {
        try {
            const route = ENDPOINTS.GET_REQUIREMENT_DETAILS(
                companyId,
                regulationId,
                requirementId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Create new requirement
     *
     * @param companyId
     * @param regulationId
     * @param payload
     * @returns {Promise<any>}
     */
    async createRequirement (companyId, regulationId, payload) {
        try {
            const route = ENDPOINTS.ADD_REQUIREMENT(
                companyId,
                regulationId,
                payload.stepId
            )
            const { data } = await axios.post(route, payload.payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update requirement
     *
     * @param companyId
     * @param regulationId
     * @param requirementId
     * @param payload
     * @returns {Promise<any>}
     */
    async updateRequirement (
        companyId,
        regulationId,
        stepId,
        requirementId,
        payload
    ) {
        try {
            const route = ENDPOINTS.EDIT_REQUIREMENT(
                companyId,
                regulationId,
                stepId,
                requirementId
            )
            const { data } = await axios.put(route, payload.payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Remove requirement
     *
     * @param companyId
     * @param regulationId
     * @param requirementId
     * @returns {Promise<any>}
     */
    async deleteRequirement (companyId, regulationId, requirementId) {
        try {
            const route = ENDPOINTS.DELETE_REQUIREMENT(
                companyId,
                regulationId,
                requirementId
            )
            const { data } = await axios.delete(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Confirm initial requirements
     *
     * @param companyId
     * @param regulationId
     * @param stepId
     * @returns {Promise<any>}
     */
    async confirmInitialRequirements (companyId, regulationId, stepId) {
        try {
            const route = ENDPOINTS.CONFIRM_INITIAL_REQUIREMENTS(
                companyId,
                regulationId,
                stepId
            )
            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Export requirements as PDF
     *
     * @param companyId
     * @param regulationId
     * @param stepId
     * @returns {Promise<any>}
     */
    async exportPdf (companyId, regulationId, stepId) {
        try {
            const route = ENDPOINTS.EXPORT_PDF(companyId, regulationId, stepId)
            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const riskOfRequirementsService = new RiskOfRequirementsService()
