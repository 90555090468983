import { findIndex, find, some, isUndefined } from 'lodash-es'
import { nonconformityService } from '@/services/api/nonconformity'
import { CORRECTIVE_ACTIONS, REGISTER_OF_NONCONFORMITIES } from '@/constants'

function getInitialState () {
    return {
        nonconformities: []
    }
}

const state = getInitialState()

const actions = {
    async getNonconformities ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const nonconformities = await nonconformityService.getNonconformities(
                companyId,
                regulationId
            )

            commit('SET_NONCONFORMITIES', nonconformities)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async addAttachments (
        { commit, state },
        { id, files, companyId, regulationId, filesIds }
    ) {
        try {
            const attachments = await nonconformityService.addAttachments(
                companyId,
                regulationId,
                id,
                files,
                filesIds
            )

            const nonconformity = find(state.nonconformities, { id })

            commit('UPDATE_NONCONFORMITY', {
                ...nonconformity,
                files: attachments
            })
        } catch (error) {
            throw error
        }
    },

    async addNonconformity ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const files = data.files.filter(file => !file.id)

            const nonconformity = await nonconformityService.addNonconformity(
                companyId,
                regulationId,
                data,
                files
            )

            commit('ADD_NONCONFORMITY', nonconformity)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async createNonconformityTask (
        { commit, rootGetters, dispatch },
        { regulationId, companyId, nonconformityId, data }
    ) {
        if (isUndefined(regulationId)) {
            const currentRegulation =
                rootGetters['regulation/currentRegulation']
            regulationId = currentRegulation ? currentRegulation.id : null
        }

        if (isUndefined(companyId)) {
            const company = rootGetters['company/company']
            companyId = company ? company.id : null
        }

        commit('SET_APP_LOADING', true, { root: true })
        try {
            const task = await nonconformityService.createNonconformityTask(
                companyId,
                regulationId,
                nonconformityId,
                data
            )
            commit('ADD_TASK', { id: nonconformityId, task })

            return task
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateNonconformityTask (
        { commit, rootGetters, dispatch },
        { regulationId, companyId, nonconformityId, data }
    ) {
        if (isUndefined(regulationId)) {
            const currentRegulation =
                rootGetters['regulation/currentRegulation']
            regulationId = currentRegulation ? currentRegulation.id : null
        }

        if (isUndefined(companyId)) {
            const company = rootGetters['company/company']
            companyId = company ? company.id : null
        }

        commit('SET_APP_LOADING', true, { root: true })
        try {
            const task = await nonconformityService.updateNonconformityTask(
                companyId,
                regulationId,
                nonconformityId,
                data
            )
            commit('UPDATE_TASK', {
                id: nonconformityId,
                taskId: task.id,
                data: task
            })

            return task
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateNonconformity ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const files = data.files.filter(file => !file.id)
            const filesIds = data.files
                .filter(file => file.id)
                .map(file => file.id)
            let payload = { ...data, state: data.state.name }

            const nonconformity = await nonconformityService.updateNonconformity(
                companyId,
                regulationId,
                data.id,
                {
                    ...payload,
                    notificants: data.notificants.map(
                        notificant => notificant.id
                    )
                }
            )
            commit('UPDATE_NONCONFORMITY', nonconformity)

            await dispatch('addAttachments', {
                id: nonconformity.id,
                files,
                companyId,
                regulationId,
                filesIds
            })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateResponsibilities ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const {
                id,
                assignee_id, // eslint-disable-line
                requirement_description, // eslint-disable-line
                notificants,
                state
            } = data

            const nonconformity = await nonconformityService.updateResponsibilities(
                companyId,
                regulationId,
                data.id,
                {
                    id,
                    assignee_id,
                    requirement_description,
                    notificants: notificants.map(notificant => notificant.id),
                    state: state.name
                }
            )
            commit('UPDATE_NONCONFORMITY', nonconformity)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async wrapUp ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const { id, state } = data

            const nonconformity = await nonconformityService.wrapUp(
                companyId,
                regulationId,
                data.id,
                { id, state: state.name }
            )
            commit('UPDATE_NONCONFORMITY', nonconformity)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async deleteNonconformity ({ commit, rootGetters }, nonconformityId) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await nonconformityService.deleteNonconformity(
                companyId,
                regulationId,
                nonconformityId
            )

            commit('REMOVE_NONCONFORMITY', nonconformityId)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}

const mutations = {
    SET_NONCONFORMITIES (state, nonconformities) {
        state.nonconformities = nonconformities.map(nonconformity => {
            const { name } = nonconformity.state

            return {
                ...nonconformity,
                isResolved:
                    name === REGISTER_OF_NONCONFORMITIES.STATUSES.RESOLVED,
                isAssigned:
                    name === REGISTER_OF_NONCONFORMITIES.STATUSES.ASSIGNED
            }
        })
    },

    ADD_NONCONFORMITY (state, nonconformity) {
        state.nonconformities = [...state.nonconformities, { ...nonconformity }]
    },

    ADD_CORRECTIVE_ACTION (state, { id, data }) {
        const index = findIndex(state.nonconformities, { id })
        const nonconformity = state.nonconformities[index]
        const { nonconformity: nonconformityState, correctiveAction } = data
        const correctionable = find(correctiveAction.correctionables, {
            corrective_action_id: correctiveAction.id
        })

        state.nonconformities.splice(index, 1, {
            ...nonconformity,
            state: { ...nonconformityState.state },
            corrective_actions: [
                ...nonconformity.corrective_actions,
                {
                    corrective_action: correctiveAction,
                    correctionable_id: nonconformity.id,
                    correctionable_type:
                        CORRECTIVE_ACTIONS.CORRECTIONABLE_TYPES.NONCONFORMITY,
                    corrective_action_id: correctiveAction.id,
                    id: correctionable.id
                }
            ]
        })
    },

    DELETE_CORRECTIVE_ACTION (state, { id, correctionablePivotId }) {
        const nonconformity = find(state.nonconformities, { id })
        const index = findIndex(nonconformity.corrective_actions, {
            id: correctionablePivotId
        })

        nonconformity.corrective_actions.splice(index, 1)
    },

    ADD_TASK (state, { id, task }) {
        const nonconformity = find(state.nonconformities, { id })

        if (!nonconformity) {
            return
        }

        const { nonconformity: nonconformityState, ...rest } = task

        nonconformity.tasks.push({ ...rest, can_be_deleted: true })
        nonconformity.state = nonconformityState.state
    },

    DELETE_TASK (state, { id, taskId }) {
        const nonconformityIndex = findIndex(state.nonconformities, ['id', id])
        const nonconformity = state.nonconformities[nonconformityIndex]
        const taskIndex = findIndex(nonconformity.tasks, ['id', taskId])

        nonconformity.tasks.splice(taskIndex, 1)
    },

    UPDATE_TASK (state, { id, taskId, data }) {
        const nonconformityIndex = findIndex(state.nonconformities, ['id', id])
        const nonconformity = state.nonconformities[nonconformityIndex]
        const taskIndex = findIndex(nonconformity.tasks, ['id', taskId])

        nonconformity.tasks.splice(taskIndex, 1, {
            ...data,
            can_be_deleted: true
        })
    },

    UPDATE_CORRECTIVE_ACTION (state, { id, data }) {
        const nonconformity = find(state.nonconformities, { id })
        const index = findIndex(nonconformity.corrective_actions, {
            corrective_action_id: data.id
        })

        nonconformity.corrective_actions.splice(index, 1, {
            ...nonconformity.corrective_actions[index],
            corrective_action: data
        })
    },

    UPDATE_NONCONFORMITY (state, nonconformity) {
        const index = findIndex(state.nonconformities, { id: nonconformity.id })

        state.nonconformities.splice(index, 1, {
            ...nonconformity,
            isResolved:
                nonconformity.state.name ===
                REGISTER_OF_NONCONFORMITIES.STATUSES.RESOLVED,
            isAssigned:
                nonconformity.state.name ===
                REGISTER_OF_NONCONFORMITIES.STATUSES.ASSIGNED
        })
    },

    REMOVE_NONCONFORMITY (state, nonconformityId) {
        state.nonconformities = state.nonconformities.filter(
            nonconformity => nonconformity.id !== nonconformityId
        )
    }
}

const getters = {
    nonconformities: state => state.nonconformities,
    areAllActionsCompleted: () => nonconformity => {
        if (!nonconformity.corrective_actions.length) {
            return true
        }

        return some(
            nonconformity.corrective_actions,
            action =>
                action.corrective_action.status.name ===
                CORRECTIVE_ACTIONS.STATUSES.COMPLETED
        )
    }
}

export const nonconformity = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
