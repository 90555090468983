import { get, each, find, first } from 'lodash-es'
import { mapActions, mapGetters } from 'vuex'
import {
    DOCUMENT_STATUSES,
    PROGRESS_VARIATIONS,
    META_FIELD_TYPES
} from '@/constants'
import { isUserUploadedDocument } from '@/services/utils/dms'
import { fromJson } from '@/services/utils/functions'
import { DOCUMENT_WIZARD_MODES } from '../constants/documents'
import { isConformioDocument } from '../services/utils/dms'
import { COMPANY_ROLES } from '@src/modules/company-settings/constants'

export default {
    computed: {
        ...mapGetters('company', ['isAdvancedTier', 'isProfessionalTier']),
        ...mapGetters('document', ['document', 'documentMeta']),
        ...mapGetters('account', ['user', 'companyMember']),

        documentCanBeSentToDraftFromCurrentStatus () {
            if (!this.document) {
                return false
            }

            const statuses = [
                DOCUMENT_STATUSES.COMPLETED,
                DOCUMENT_STATUSES.CORRECTIONS_NEEDED,
                DOCUMENT_STATUSES.WITHDRAWN
            ]

            return statuses.includes(this.document.status)
        },

        documentExists () {
            return this.document && this.document.status
        },

        documentIsEditable () {
            if (!this.documentExists) {
                return false
            }

            return (
                this.userCanEditDocument &&
                this.document.status === DOCUMENT_STATUSES.IN_PROGRESS
            )
        },

        documentReviewIsStoppable () {
            if (!this.documentExists) {
                return false
            }

            return (
                this.userCanEditDocument &&
                this.document.status === DOCUMENT_STATUSES.IN_REVIEW
            )
        },

        documentApprovalIsStoppable () {
            if (!this.documentExists) {
                return false
            }

            return (
                this.userCanEditDocument &&
                this.document.status === DOCUMENT_STATUSES.IN_APPROVAL
            )
        },

        documentIsCommentable () {
            if (!this.documentExists) {
                return false
            }

            return (
                this.userHasTaskActions &&
                (this.document.status === DOCUMENT_STATUSES.IN_REVIEW ||
                    this.document.status === DOCUMENT_STATUSES.IN_APPROVAL)
            )
        },

        userCanEditDocument () {
            if (!this.documentExists) {
                return false
            }

            const {
                author,
                owner_company_member_id: ownerCompanyMemberId
            } = this.document

            const companyMemberId = this.companyMember
                ? this.companyMember.id
                : this.$route.query.company_member_id

            return (
                this.companyMember?.role?.name ===
                    COMPANY_ROLES.PROJECT_MANAGER ||
                companyMemberId === author.company_member_id ||
                companyMemberId === ownerCompanyMemberId
            )
        },

        userHasTaskActions () {
            return !!this.taskActions.length
        },

        taskActions () {
            const taskActions = []

            if (!this.document || !this.document.version) {
                return []
            }

            each(this.document.version.conditions, condition => {
                taskActions.push({
                    name: condition.action_type.name,
                    action_token: condition.action_token
                })
            })

            each(this.document.conditions, condition => {
                taskActions.push({
                    name: condition.action_type.name,
                    action_token: condition.action_token
                })
            })

            return taskActions
        },

        hasReviewersAssigned () {
            const reviewerMetaProperty = find(this.documentMeta, {
                name: META_FIELD_TYPES.REVIEWERS
            })

            return fromJson(reviewerMetaProperty?.value)?.length
        },

        hasApproverAssigned () {
            const approverMetaProperty = find(this.documentMeta, {
                name: META_FIELD_TYPES.APPROVER
            })

            return approverMetaProperty?.value
        },

        isCustomModeEnabled () {
            return (
                this.$route?.query?.mode === DOCUMENT_WIZARD_MODES.CUSTOM ??
                false
            )
        },

        isCustomModeAvailable () {
            return (
                isConformioDocument(this.document) &&
                (this.isAdvancedTier || this.isProfessionalTier)
            )
        },

        isCustomModeActive () {
            return this.isCustomModeAvailable && this.isCustomModeEnabled
        }
    },

    methods: {
        ...mapActions('regulation', ['getTemplate']),

        canLoggedInUserOpenDocumentWizard (currentStep, currentDocument) {
            let document = this.document || currentDocument

            return (
                this.isStepAssigneeLoggedInUser(currentStep) ||
                this.companyMember?.role?.name ===
                    COMPANY_ROLES.PROJECT_MANAGER ||
                this.isDocumentAuthorLoggedInUser(document) ||
                this.isDocumentOwnerLoggedInUser(document)
            )
        },

        isStepAssigneeLoggedInUser (step) {
            const { assignee } = step

            if (!assignee) return false

            return assignee.id === this.companyMember.id
        },

        isDocumentAuthorLoggedInUser (document) {
            const { author } = document

            if (author) {
                return author.company_member_id === this.companyMember.id
            }

            return false
        },

        isDocumentOwnerLoggedInUser (document) {
            const { owner_company_member_id: ownerCompanyMemberId } = document

            return (
                ownerCompanyMemberId &&
                ownerCompanyMemberId === this.companyMember.id
            )
        },

        getDocumentStatusColor (status) {
            const DOCUMENT_STATUS_COLORS = {
                [DOCUMENT_STATUSES.COMPLETED]: PROGRESS_VARIATIONS.SECONDARY,
                [DOCUMENT_STATUSES.IN_PROGRESS]: PROGRESS_VARIATIONS.WARNING
            }

            if (status) {
                const color = DOCUMENT_STATUS_COLORS[status]
                return color || PROGRESS_VARIATIONS.WARNING
            }

            return ''
        },

        getHighlightableElement (id, inputs) {
            const input = find(inputs, { name: id })

            return document.getElementById(
                input && input.parent ? input.parent : id
            )
        },

        getHighlightContainer () {
            return document.getElementById('document-wrapper')
        },

        getCommentableElements () {
            return document.querySelectorAll('[data-commentable]')
        },

        attachClassesToElement (element, classes) {
            if (element) {
                element.classList.add(...classes)
            }
        },

        removeClassesFromElement (element, classes) {
            if (element) {
                element.classList.remove(...classes)
            }
        },

        async getDocumentTemplate () {
            if (
                (this.documentStep && !this.documentStep.templates.length) ||
                isUserUploadedDocument(this.document)
            ) {
                return
            }

            const { access_token: accessToken } = this.$route.query

            await this.getTemplate({
                regulationId: get(
                    this,
                    'currentRegulation.id',
                    this.$route.query.regulation_id
                ),
                companyId:
                    get(this, 'company.id', null) ||
                    this.$route.query.company_id,
                stepId: this.documentStep.id,
                templateId: first(this.documentStep.templates).id,
                accessToken: accessToken
            })
        }
    }
}
