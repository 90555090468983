<template>
    <div
        class="cf-c-adv-collapsible-subsection"
        :class="{ 'cf-c-adv-collapsible-subsection--inline': inline }"
    >
        <div class="cf-c-adv-collapsible-subsection__title">
            <h5 class="cf-c-adv-collapsible-subsection__title-txt">
                <slot name="title"></slot>
            </h5>
        </div>
        <div class="cf-c-adv-collapsible-subsection__ctn">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        inline: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>
