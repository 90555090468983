<template>
    <app-modal-embedded
        :approval="true"
        name="want-to-learn-more"
        :title="title"
        max-width27
        hide-border
        :hide-footer="true"
        :click-to-close="false"
        :scrollable="false"
        @ok="handleClose"
    >
        <template slot="header-actions">
            <app-icon
                icon="close-icon"
                class="close"
                size="md"
                @click="handleClose"
            />
        </template>
        <template slot="body">
            <div
                v-for="(section, index) in content"
                :key="index"
                class="cf-u-mb-md"
            >
                <p
                    v-if="section.title"
                    class="cf-u-color-gray-03 cf-u-line-height-sm cf-u-mb-none"
                >
                    {{ section.title }}
                </p>
                <a
                    v-if="section.link"
                    class="cf-u-color-primary cf-u-text-bold cf-u-line-height-sm"
                    :href="section.link && section.link"
                    target="_blank"
                >
                    {{ section.description || section.link }}
                </a>
                <p v-else-if="section.description">
                    {{ section.description }}
                </p>
            </div>
        </template>
    </app-modal-embedded>
</template>

<script>
import { DOCUMENT_WIZARD_WTLM_TYPES } from '@/constants'

export default {
    data: () => ({
        content: [],
        type: 'want_to_learn_more'
    }),

    computed: {
        title () {
            return this.$t(`COMMON.${this.type.toUpperCase()}`)
        }
    },
    async mounted () {
        this.handleWtlmTags()
    },

    methods: {
        handleOpen (data, type) {
            this.content = data
            this.type = type || DOCUMENT_WIZARD_WTLM_TYPES.WANT_TO_LEARN_MORE
            this.$modal.show('want-to-learn-more')
        },

        handleClose () {
            this.$modal.hide('want-to-learn-more')
        },

        handleWtlmTags () {
            const that = this
            const elementLinks = document.getElementsByClassName('wtlm')

            if (elementLinks.length) {
                for (var i = 0; i < elementLinks.length; i++) {
                    elementLinks[i].addEventListener('click', function (e) {
                        try {
                            let data = JSON.parse(
                                this.getAttribute('data-content')
                            )
                            let type = this.getAttribute('data-type')
                            that.handleOpen(data, type)
                        } catch (error) {
                            throw error
                        }
                        e.stopPropagation()
                    })
                }

                document.addEventListener('click', function () {
                    const buttons = document.querySelectorAll(
                        '.cf-c-dw-doc__heading button'
                    )
                    for (var k = 0; k < buttons.length; k++) {
                        buttons[k].addEventListener('click', function () {
                            that.handleClose()
                        })
                    }
                })
            }
        }
    }
}
</script>
