<template>
    <div :class="wrapperClasses">
        <div class="cf-c-collapsible-right__header">
            <div class="cf-c-collapsible-right__header-ctn">
                <div
                    class="cf-u-flex-2 cf-u-pl-sm cf-u-mr-xl cf-u-font-size-stack cf-u-text-bold"
                >
                    <span>{{ title }}</span>
                </div>

                <div class="cf-u-flex-2 cf-u-font-size-stack cf-u-text-bold">
                    <span>{{ $t('COMMON.REFERENCE') }}</span>
                </div>
            </div>

            <div
                class="cf-c-collapsible-right__icon-wrap"
                @click="toggleCollapse"
            >
                <div class="cf-c-collapsible-right__icon"></div>
            </div>
        </div>

        <div v-show="visible" class="cf-c-collapsible-right__content">
            <div
                v-for="(item, index) in items"
                :key="index"
                class="cf-u-display-flex cf-u-ml-sm cf-u-pb-sm cf-u-pt-default"
                :class="[{ 'cf-u-border-bottom': index < items.length - 1 }]"
            >
                <div
                    class="cf-u-flex-2 cf-u-font-size-stack cf-u-mr-xl cf-u-flex-align-center "
                >
                    {{ item.value }}
                </div>
                <div
                    class="cf-u-flex-2 cf-u-color-primary cf-u-text-bold cf-u-flex-align-center"
                >
                    {{ item.reference_to || $t('COMMON.NOT_AVAILABLE') }}
                </div>
                <div
                    v-if="approvalDropdown"
                    class="cf-u-flex-1 cf-u-justify-end"
                >
                    <resource-approval-select
                        class="cf-u-width-min-width-10 cf-u-flex-align-center"
                        :placeholder="$t('RESOURCE.CHOOSE_ACTION')"
                        full-width
                        @input="handleChange($event, item)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/EventBus'

export default {
    props: {
        title: {
            type: String,
            required: true
        },

        items: {
            type: Array,
            required: false,
            default: () => []
        },

        approvalDropdown: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data () {
        return {
            visible: true,
            required: false
        }
    },

    computed: {
        wrapperClasses () {
            let classes = 'cf-c-collapsible-right'

            if (this.visible) {
                classes += ' cf-is-open'
            }

            return classes
        }
    },

    methods: {
        toggleCollapse () {
            this.visible = !this.visible
        },

        handleChange (select, item) {
            EventBus.$emit('resource-action-select', {
                id: item.id,
                action: select && select.value
            })
        }
    }
}
</script>
