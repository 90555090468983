const INPUTS = {
    CHECKBOX_GROUP_INPUT_LIST: 'checkbox_group_input_list',
    COMPANY_PRIOR_DOCUMENTS_CONDITIONAL_TEXT:
        'company_prior_documents_conditional_text',
    DOCUMENT_CLASSIFICATION: 'document_classification',
    DOCUMENT_WIZARD: 'document_wizard',
    DYNAMIC_MULTISELECT: 'dynamic_multiselect',
    DYNAMIC_SELECT_WITH_PREDEFINED_OPTION:
        'dynamic_select_with_predefined_option',
    GENERIC_TEXT: 'generic_text',
    MULTI_VALUE_REMOTE_PARTICIPANTS_TEXT:
        'multi_value_remote_participants_text',
    NUMBER_INPUT: 'number_input',
    RADIO_GROUP_INPUT_LIST: 'radio_group_input_list',
    RADIO_INPUT: 'radio_input',
    REGISTER_WIZARD: 'register_wizard',
    SEQUENCE_INPUT: 'sequence_input',
    STEP_AWARENESS: 'step_awareness',
    STEP_CLAUSE: 'step_clause',
    STEP_FINANCE: 'step_finance',
    STEP_HUMAN_RESOURCES: 'step_human_resources',
    STEP_SYNC: 'step_sync',
    STEP_TECHNOLOGY: 'step_technology',
    STEP_TRAINING: 'step_training',
    TEMPLATE_DOCUMENT: 'template_document',
    TABLE_INPUT: 'table_input',
    TEXT_INPUT: 'text_input',
    UPLOAD_DOCUMENT: 'document_upload',
    VIDEO_TEXT: 'video_text'
}

const DATETIME_FORMATS = {
    DATE_PICKER: 'M j, Y',
    TIME_PICKER: 'h:i K'
}

const DATETIME_PICKER_TYPE = {
    TIME: 'time',
    DATE: 'date'
}

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
const MAX_PREDEFINED_OPTIONS = 3

const CONDITION_DICTIONARY = {
    READ_FROM: 'read_from',
    SHOW: 'show'
}

const DISPLAY = {
    NONE: 'cf-u-display-none',
    BLOCK: 'cf-u-display-block'
}

const UPLOAD_DOCUMENT_TOGGLE_STEP = 'step_own_document'
const STEP_PROCEED = 'step_proceed'
const FILE_INPUT = 'file_input'
const UPLOAD_MAX_FILES_SIZE = 50 // MB

const HUMAN_RESOURCES = 'human_resources'
const FINANCIAL_RESOURCES = 'financial_resources'
const TECHNOLOGY_RESOURCES = 'technology_resources'

const RESOURCES_TYPES = {
    HUMAN_RESOURCES,
    FINANCIAL_RESOURCES,
    TECHNOLOGY_RESOURCES
}

const STEP_RESOURCES_TYPES = {
    HUMAN_RESOURCES: 'step_human_resources',
    FINANCIAL_RESOURCES: 'step_finance',
    TECHNOLOGY_RESOURCES: 'step_technology'
}

const STEP_DOCUMENT_UPLOAD_OPTION = {
    CONFORMIO_DOCUMENT: '1',
    USER_UPLOADED_DOCUMENT: '0'
}

const GLOBAL_VARIABLE_DEPENDS = [
    'controls_applicable',
    'document_adopted_in_soa',
    'one_of_controls_applicable'
]

const INPUT_AUTOSAVE_DEBOUNCE_TIME = 1500

export {
    INPUTS,
    CONDITION_DICTIONARY,
    DISPLAY,
    UPLOAD_DOCUMENT_TOGGLE_STEP,
    UPLOAD_MAX_FILES_SIZE,
    FILE_INPUT,
    STEP_PROCEED,
    DATETIME_FORMATS,
    EMAIL_REGEX,
    MAX_PREDEFINED_OPTIONS,
    RESOURCES_TYPES,
    STEP_RESOURCES_TYPES,
    STEP_DOCUMENT_UPLOAD_OPTION,
    GLOBAL_VARIABLE_DEPENDS,
    DATETIME_PICKER_TYPE,
    INPUT_AUTOSAVE_DEBOUNCE_TIME
}
