import { mapActions, mapGetters } from 'vuex'
import { focusableInputs } from '@/mixins'
import { mapUsersByIdentifier } from '@/services/utils/functions'
import { isAfter } from '@/services/utils/date'

export default {
    mixins: [focusableInputs],

    props: {
        requirement: {
            type: Object,
            required: false,
            default: () => {}
        },

        index: {
            type: Number,
            required: false,
            default: () => -1
        }
    },

    data () {
        return {
            form: {}
        }
    },

    created () {
        this.form = this.getFormData()
    },

    computed: {
        ...mapGetters('company', ['users', 'activeUsers']),

        canSaveRequirement () {
            if (!this.isExistingRequirement) {
                return true
            }

            if (!this.form.is_compliant && this.requirement.is_compliant) {
                const { deadline } = this.form

                return deadline ? isAfter(deadline) : true
            }

            return true
        },

        isExistingRequirement () {
            return this.index !== -1
        },

        mappedUsers () {
            return mapUsersByIdentifier(this.activeUsers)
        }
    },

    methods: {
        ...mapActions('errors', ['clearAllErrors']),

        performSave () {
            this.$emit('save', this.form, this.index)
        },

        performCancel () {
            this.$emit('cancel')
        },

        performDelete () {
            this.$emit('delete', this.index)
            this.$modal.hide(this.modalName)
        },

        confirmDelete () {
            this.$modal.show(this.modalName)
        },

        cancelDelete () {
            this.$modal.hide(this.modalName)
        }
    },

    watch: {
        'form.type' () {
            this.clearAllErrors()
            this.$emit('type-change', this.form.type)
        }
    }
}
