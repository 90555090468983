import { REGISTER_WIZARD_ROUTES } from '@/constants'
import RISKS from './risks'
import SOA from './soa'
import RISK_TREATMENT_PLAN from './risk-treatment-plan'
import INCIDENTS from './incidents'
import PERIODIC_REVIEW from './periodic-review'
import NONCONFORMITIES from './nonconformities'
import INTERNAL_AUDITS from './internal-audits'
import INTERNAL_AUDIT_PERFORMING from './internal-audit-performing'
import DOCUMENTATION_REVIEW from './documentation-review'
import CHECKLIST_ADAPTATION from './checklist-adaptation'
import AUDIT_REPORT from './audit-report'
import MANAGEMENT_REVIEW from './management-review'
import SETTING_UP_MANAGEMENT_REVIEW from './setting-up-management-review'
import RoutesTrainings from '@src/modules/registers/trainings/router'
import RoutesSecurityObjectives from '@src/modules/registers/security-objectives/router'
import RoutesRegisterOfRequirements from '@src/modules/registers/requirements/router'

export default {
    name: REGISTER_WIZARD_ROUTES.BASE_REGISTER_WIZARD,
    path: 'register-wizard',
    component: () => import('@/containers/RegisterWizard/BaseRegisterWizard'),
    props: true,
    children: [
        RISKS,
        SOA,
        INCIDENTS,
        PERIODIC_REVIEW,
        NONCONFORMITIES,
        RISK_TREATMENT_PLAN,
        INTERNAL_AUDITS,
        INTERNAL_AUDIT_PERFORMING,
        DOCUMENTATION_REVIEW,
        CHECKLIST_ADAPTATION,
        AUDIT_REPORT,
        MANAGEMENT_REVIEW,
        SETTING_UP_MANAGEMENT_REVIEW,
        RoutesTrainings,
        RoutesSecurityObjectives,
        RoutesRegisterOfRequirements
    ]
}
