<template>
    <draggable
        class="list-group"
        element="ul"
        :list="elements"
        :options="dragOptions"
        :move="onMove"
    >
        <transition-group type="transition" name="flip-list">
            <li
                v-for="element in elements"
                :key="element.order"
                class="list-group-item"
            >
                {{ element.name }}
            </li>
        </transition-group>
    </draggable>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    components: {
        draggable
    },

    props: {
        list: {
            type: Array,
            required: false,
            default: () => []
        },

        initialList: {
            type: Array,
            required: false,
            default: () => []
        },

        name: {
            type: String,
            required: true
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data () {
        return {
            elements: []
        }
    },

    computed: {
        dragOptions () {
            return {
                animation: 20,
                group: this.name,
                ghostClass: 'ghost',
                easing: 'cubic-bezier(1, 0, 0, 1)',
                disabled: this.disabled
            }
        }
    },

    watch: {
        list (value) {
            this.elements = [...value]
        },

        elements (value) {
            this.$emit(
                'input',
                value.map(item => ({
                    value: item.value,
                    label: item.label
                }))
            )
        }
    },

    created () {
        this.elements = [...this.initialList]
    },

    methods: {
        onMove ({ relatedContext, draggedContext }) {
            const relatedElement = relatedContext.element
            const draggedElement = draggedContext.element

            return (
                (!relatedElement || !relatedElement.fixed) &&
                !draggedElement.fixed
            )
        },

        orderList () {
            this.elements = this.elements.sort((a, b) => a.order - b.order)
        }
    }
}
</script>

<style scoped>
ul {
    list-style: none;
}

li {
    padding: 5px;
    margin: 5px;
    border: 1px solid black;
}

.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.list-group {
    min-height: 50px;
    display: block;
}

.list-group-item {
    cursor: move;
}
</style>
