import axios from 'axios'
import { last } from 'lodash-es'
import { forceFileDownload } from '@/services/utils/functions'
import { RESPONSE_TYPE_ARRAY_BUFFER } from '@/constants'

const ENDPOINTS = {
    FILE_DOWNLOAD: companyId => `companies/${companyId}/file`
}

class FileService {
    async downloadFile (companyId, path) {
        try {
            const file = await axios.get(ENDPOINTS.FILE_DOWNLOAD(companyId), {
                params: { path },
                responseType: RESPONSE_TYPE_ARRAY_BUFFER
            })

            forceFileDownload(file, last(path.split('/')))
        } catch (error) {
            throw error
        }
    }
}

export const fileService = new FileService()
