const ROUTES_COMPLIANCE = {
    BASE: {
        name: 'compliance'
    },
    PREPARATION: {
        name: 'compliance.preparation'
    },
    IMPLEMENTATION: {
        name: 'compliance.implementation'
    },
    AUDIT_AND_EVIDENCE: {
        name: 'compliance.audit-and-evidence'
    },
    MAINTENANCE: {
        name: 'compliance.maintenance'
    },
    STEPS: {
        name: 'compliance.implementation-steps'
    },
    STEP_OVERVIEW: {
        name: 'step-overview'
    }
}

const EVIDENCE_TYPES = {
    CLAUSE: 'clause',
    CONTROL: 'control'
}

const NOTE_MAX_CHARACTERS = 250

const DOCUMENTS_STATUS_COLORS = {
    COMPLETED: '#70D787',
    IN_PROGRESS: '#4789C6',
    IN_REVIEW: '#F19E1A',
    IN_APPROVAL: '#FFC700',
    CORRECTIONS_NEEDED: '#E4444E',
    WITHDRAWN: '#8792A2'
}

const CLAUSES_CONTROLS_STATUS_COLORS = {
    AUDIT_READY: '#70D787',
    AUDIT_NOT_READY: '#E4444E',
    NOT_APPLICABLE: '#8792A2'
}

const MAINTENANCE_DATES_CALCULATIONS = {
    NEXT_INTERNAL_AUDIT: 11,
    NEXT_SURVEILLANCE_AUDIT: 12,
    NEXT_RECERTIFICATION: 36
}

const MAINTENANCE_ACTIVITY = {
    ACTIVITY_TYPE_CONTINUOUS: 'continuous',
    ACTIVITY_TYPE_RECURRING: 'recurring',
    EXPLANATION_CHAR_LIMIT: 270,
    SCHEDULE_ONGOING: 'ongoing',
    SCHEDULE_AUTO: 'auto'
}

const MAINTENANCE_LEARN_MORE_LINK =
    'https://help.advisera.com/hc/en-us/articles/10139050766364-Maintenance-Module'

const IMPLEMENTATION_STEPS_LEARN_MORE_LINK =
    'https://help.advisera.com/hc/en-us/articles/10139060632732-Implementation-Conformio-Implementation-Steps'
const SECURITY_POLICY_STEP_NAME = 'ISO_27001_it_security_policy'

export {
    ROUTES_COMPLIANCE,
    EVIDENCE_TYPES,
    NOTE_MAX_CHARACTERS,
    DOCUMENTS_STATUS_COLORS,
    CLAUSES_CONTROLS_STATUS_COLORS,
    MAINTENANCE_DATES_CALCULATIONS,
    MAINTENANCE_ACTIVITY,
    MAINTENANCE_LEARN_MORE_LINK,
    IMPLEMENTATION_STEPS_LEARN_MORE_LINK,
    SECURITY_POLICY_STEP_NAME
}
