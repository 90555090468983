<template>
    <div class="cf-c-checkbox-grid-list">
        <div
            v-for="item in checkedControls"
            :key="item[trackBy]"
            class="cf-c-checkbox-grid-list__item"
        >
            <labeled-checkbox
                :key="item.id"
                class="cf-c-labeled-checkbox--inside-collapsible"
                :value="isChecked(item)"
                :label="item[label]"
                :disabled="disabled"
                @input="handleInput(item[trackBy], $event)"
            />
        </div>
    </div>
</template>

<script>
import { find, keys } from 'lodash-es'

export default {
    props: {
        items: {
            type: Array,
            required: true
        },

        label: {
            type: String,
            required: false,
            default: 'label'
        },

        trackBy: {
            type: String,
            required: false,
            default: 'value'
        },

        value: {
            type: Array,
            required: false,
            default: () => []
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data () {
        return {
            isInitialized: false,
            checked: {},
            checkedControls: {}
        }
    },

    watch: {
        items (newValue, oldValue) {
            if (!oldValue.length && newValue.length && !this.isInitialized) {
                this.setSelected()
                this.notify(true)
            }
        },

        value () {
            this.setSelected()
        }
    },

    created () {
        this.setSelected()
        this.notify(true)
    },

    methods: {
        setSelected () {
            if (this.items.length) {
                this.isInitialized = true
            }

            this.items.forEach(item => {
                const selectedItem = find(this.value, {
                    [this.trackBy]: item[this.trackBy]
                })

                this.$set(
                    this.checkedControls,
                    item[this.trackBy],
                    selectedItem
                )
                this.$set(this.checked, item[this.trackBy], !!selectedItem)
            })
        },

        isChecked (item) {
            return !!this.checked[item[this.trackBy]]
        },

        handleInput (itemId, checked) {
            this.checked[itemId] = checked
            this.notify()
        },

        notify (isInitialSetup = false) {
            let items = []
            keys(this.checked).forEach(key => {
                if (this.checked[key]) {
                    const item = find(
                        this.checkedControls,
                        item => item[this.trackBy] == key // eslint-disable-line
                    )
                    items.push(item)
                }
            })
            this.$emit('input', { controls: items, isInitialSetup })
        }
    }
}
</script>
