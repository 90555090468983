import {
    REGISTER_WIZARD_ROUTES,
    REGISTER_TYPES,
    MY_PERMISSIONS,
    REGISTERS_PERMISSIONS
} from '@/constants'
import { myPermissionsGuard } from '@src/router/guards'

const { MANAGEMENT_REVIEW } = REGISTER_WIZARD_ROUTES.REPORTING

export default {
    name: MANAGEMENT_REVIEW.SETTING_UP.INDEX.name,
    path: 'setting-up-management-review',
    component: () =>
        import('@/containers/ReportingDashboard/ManagementReviewSettings'),
    redirect: to => ({
        name: MANAGEMENT_REVIEW.SETTING_UP.WIZARD.name,
        params: { ...to.params }
    }),
    meta: {
        requiresAuth: true,
        register: REGISTER_TYPES.SETTING_UP_MANAGEMENT_REVIEW
    },
    children: [
        {
            path: 'wizard',
            name: MANAGEMENT_REVIEW.SETTING_UP.WIZARD.name,
            component: () =>
                import(
                    '@/components/reporting/management-review/settings/WizardTab'
                ),
            props: true,
            meta: {
                pageTitle: 'REPORTING.MANAGEMENT_REVIEW.SETTINGS.TITLE',
                withScaling: true,
                requiresAuth: true,
                register: REGISTER_TYPES.MANAGEMENT_REVIEW,
                shouldSkipRouteInHistory: true,
                guards: [myPermissionsGuard],
                permissions: [
                    MY_PERMISSIONS.MANAGEMENT_REVIEW_ACCESS,
                    REGISTERS_PERMISSIONS.MANAGEMENT_REVIEWS
                ]
            }
        },
        {
            path: 'discussions',
            name: MANAGEMENT_REVIEW.SETTING_UP.DISCUSSIONS.name,
            component: () => import('@/components/registers/Discussions'),
            props: true,
            meta: {
                pageTitle: 'REPORTING.MANAGEMENT_REVIEW.SETTINGS.TITLE',
                withScaling: true,
                requiresAuth: true,
                register: REGISTER_TYPES.MANAGEMENT_REVIEW,
                shouldSkipRouteInHistory: true,
                guards: [myPermissionsGuard],
                permissions: [
                    MY_PERMISSIONS.MANAGEMENT_REVIEW_ACCESS,
                    REGISTERS_PERMISSIONS.MANAGEMENT_REVIEWS
                ]
            }
        }
    ]
}
