<template>
    <div class="dw-editor__heading">
        <div class="dw-editor__heading-left">
            <!-- Custom paragraph switch button -->
            <app-form-switch
                v-if="isConformioDocument && documentIsEditable"
                :value="isCustomModeEnabled"
                size="lg"
                icon="paragraph-icon"
                inactive-color="gray-beta"
                active-color="primary-epsilon"
                @input="handleModeChange"
            >
                <template slot="first-label">
                    {{ $t('DOCUMENT_WIZARD.CUSTOM_PARAGRAPH.TEXT_LABEL') }}
                </template>
            </app-form-switch>

            <!-- Print button -->
            <document-print v-if="shouldSeePrintButton" :document="document" />
        </div>

        <div class="dw-editor__heading-right">
            <!-- Stop review -->
            <div
                v-if="documentReviewIsStoppable"
                class="dw-editor__heading-btn"
            >
                <button
                    type="button"
                    class="btn btn--gamma"
                    permission-control
                    @click="handleStopReview"
                >
                    {{ $t('DOCUMENT_WIZARD.STOP_REVIEW') }}
                </button>
            </div>

            <!-- Stop approval  -->
            <div
                v-if="documentApprovalIsStoppable"
                class="dw-editor__heading-btn"
            >
                <button
                    type="button"
                    class="btn btn--gamma"
                    v-b-tooltip.top="
                        $t('DOCUMENT_WIZARD.STOP_APPROVAL_TOOLTIP')
                    "
                    :disabled="isCompanyExpired"
                    @click="handleStopApproval"
                >
                    {{ $t('DOCUMENT_WIZARD.STOP_APPROVAL') }}
                </button>
            </div>

            <div
                v-if="shouldUpploadNewVersionBeVisible"
                class="dw-editor__heading-btn"
            >
                <upload-new-version
                    v-if="
                        (document && documentStep) ||
                            isUserUploadedDocument(document)
                    "
                    :document-id="document.id"
                    :step-id="documentStep ? documentStep.id : null"
                    :step-inputs-number="stepInputsNumber"
                    @update-document-url="$emit('upload-new-version', $event)"
                />
            </div>

            <template v-if="documentIsEditable">
                <!-- Submit for review -->
                <div class="dw-editor__heading-btn" id="submit-for-review">
                    <button
                        type="button"
                        class="btn btn--alpha"
                        :disabled="isCompanyExpired || !hasReviewersAssigned"
                        @click="handleOpenReviewDeadlineModal"
                    >
                        {{ $t('DOCUMENT_WIZARD.SUBMIT_FOR_REVIEW') }}
                    </button>
                </div>
                <b-tooltip
                    :disabled="!!hasReviewersAssigned"
                    target="submit-for-review"
                >
                    {{ $t('DOCUMENT_WIZARD.MUST_ASSIGN_REVIEWERS_FIRST') }}
                </b-tooltip>

                <!-- Submit for approval -->
                <div
                    v-if="isSubmittable"
                    class="dw-editor__heading-btn"
                    id="submit-for-approval"
                >
                    <button
                        type="button"
                        class="btn btn--alpha"
                        :disabled="isCompanyExpired || !hasApproverAssigned"
                        @click="handleOpenSubmitForApprovalModal"
                    >
                        {{ $t('DOCUMENT_WIZARD.SUBMIT_FOR_APPROVAL') }}
                    </button>
                </div>
                <b-tooltip
                    :disabled="!!hasApproverAssigned"
                    target="submit-for-approval"
                >
                    {{ $t('DOCUMENT_WIZARD.MUST_ASSIGN_APPROVER_FIRST') }}
                </b-tooltip>
            </template>

            <div
                v-if="
                    documentCanBeSentToDraftFromCurrentStatus &&
                        !taskActions.length
                "
                class="dw-editor__heading-btn"
            >
                <button
                    type="button"
                    class="btn btn--alpha"
                    :disabled="isCompanyExpired"
                    @click="handleEditDocument"
                >
                    {{ $t('DOCUMENT_WIZARD.EDIT_DOCUMENT') }}
                </button>
            </div>

            <div
                v-if="taskActions.length"
                v-for="(action, index) in taskActions"
                :key="`${action.name}-${index}`"
                class="dw-editor__heading-btn"
            >
                <button
                    type="button"
                    class="btn btn--alpha"
                    @click="handleTaskActionButtonClick(action)"
                >
                    {{ $t(`DOCUMENT_WIZARD.${action.name.toUpperCase()}`) }}
                </button>
            </div>
        </div>

        <review-deadline @submit="handleSubmitForReview" />
        <submit-for-approval @submit="handleSubmitForApproval" />

        <app-confirm-modal
            title="DOCUMENT_WIZARD.EDIT_DOCUMENT_WARNING_MODAL.TITLE"
            text="DOCUMENT_WIZARD.EDIT_DOCUMENT_WARNING_MODAL.DESCRIPTION"
            modal-name="edit-document-warning"
            should-close-on-cancel
            ok-title="COMMON.CONTINUE"
            @confirm="sendDocumentToEditMode"
        />

        <app-modal
            name="suggested-responsiblity-tasks-modal"
            body="DOCUMENT_WIZARD.SUGGESTED_RESPONSIBILITY_WARNING_MODAL.DESCRIPTION"
            title="DOCUMENT_WIZARD.SUGGESTED_RESPONSIBILITY_WARNING_MODAL.TITLE"
            ok-title="DOCUMENT_WIZARD.SUGGESTED_RESPONSIBILITY_WARNING_MODAL.OK_TITLE"
            cancel-title="DOCUMENT_WIZARD.SUGGESTED_RESPONSIBILITY_WARNING_MODAL.CANCEL_TITLE"
            :hide-footer="false"
            @ok="handleSuggestedResponsiblityModalOkClick"
            @before-open="handleSuggestedResponsiblityModalBeforeOpen"
        />

        <action-notification-modal
            @action-notification-modal-closed="handleRedirectionAfterAction"
        />
    </div>
</template>

<script>
import { get, includes } from 'lodash-es'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ReviewDeadline from '@/components/modals/ReviewDeadline'
import SubmitForApproval from '@/components/modals/SubmitForApproval'
import ActionNotificationModal from '@/components/modals/wizards/ActionNotificationModal'
import { documents, toasts, permissionControl } from '@/mixins'
import {
    DOCUMENT_STATUSES,
    TASKS,
    DOCUMENT_WIZARD_ROUTES,
    DOCUMENT_TYPES,
    RESOURCES,
    DMS_ROUTES,
    ROUTES,
    IN_PROGRESS_DOCUMENT_STATUSES
} from '@/constants'
import { EventBus } from '@/EventBus'
import { FORMAT_OPTIONS } from '@/services/utils/comment'
import {
    isUserUploadedDocument,
    isConformioDocument
} from '@/services/utils/dms'
import AppFormSwitch from '../shared/AppFormSwitch.vue'
import DocumentPrint from './DocumentPrint.vue'

export default {
    components: {
        ReviewDeadline,
        SubmitForApproval,
        ActionNotificationModal,
        AppFormSwitch,
        DocumentPrint
    },

    mixins: [documents, toasts, permissionControl],

    props: {
        isSubmittable: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    data () {
        return {
            taskActionToken: null
        }
    },

    created () {
        EventBus.$on('document-withdrawn', this.refreshDocument)
    },

    beforeDestroy () {
        EventBus.$off('document-withdrawn', this.refreshDocument)
    },

    computed: {
        ...mapGetters('company', [
            'company',
            'usersById',
            'companyHasEarlyAccess'
        ]),
        ...mapGetters('regulation', ['documentStep', 'currentRegulation']),
        ...mapGetters('document', [
            'document',
            'lastDocumentVersion',
            'suggestedResponsibilities'
        ]),

        stepInputsNumber () {
            if (!this.documentStep) {
                return
            }

            if (this.documentStep.company_id) {
                return ''
            }

            const splitStep = this.documentStep.title.split('STEP_')
            const nameSplit = splitStep[1].split('_')
            return nameSplit[0]
        },

        shouldUpploadNewVersionBeVisible () {
            return (
                this.documentIsEditable &&
                this.lastDocumentVersion.documentable_type ===
                    DOCUMENT_TYPES.USER_UPLOAD_DOCUMENT
            )
        },

        areAllSuggestedResponsiblityTasksChecked () {
            return this.suggestedResponsibilities.every(
                suggestedResponsiblityTask => suggestedResponsiblityTask.checked
            )
        },

        showChangesAutomaticallySavedDisclaimer () {
            return IN_PROGRESS_DOCUMENT_STATUSES.includes(this.document.status)
        },

        shouldSeePrintButton () {
            return this.companyHasEarlyAccess
        },

        isConformioDocument () {
            return isConformioDocument(this.document)
        }
    },

    methods: {
        ...mapActions('task', ['submitAction', 'submitTasks']),
        ...mapActions('document', [
            'updateDocument',
            'stopReview',
            'stopApproval',
            'getDocument',
            'getDocumentMeta'
        ]),

        isUserUploadedDocument,

        ...mapMutations('document', {
            setDocument: 'SET_DOCUMENT'
        }),

        async handleEditDocument () {
            if (this.document.status === DOCUMENT_STATUSES.COMPLETED) {
                this.showEditDocumentWarningModal()
                return
            }

            await this.sendDocumentToEditMode()
        },

        showEditDocumentWarningModal () {
            this.$modal.show('edit-document-warning')
        },

        handleOpenReviewDeadlineModal () {
            this.$modal.show('submit-for-review')
        },

        handleOpenSubmitForApprovalModal () {
            this.$modal.show('submit-for-approval')
        },

        async handleStopReview () {
            if (!this.lastDocumentVersion) {
                return
            }

            const data = {
                documentId: this.document.id,
                versionId: this.lastDocumentVersion.id
            }

            await this.stopReview(data)
            this.handleModalAndDocument(TASKS.ACTION_TYPES.STOP_REVIEW)
        },

        async handleStopApproval () {
            if (!this.lastDocumentVersion) {
                return
            }

            const data = {
                documentId: this.document.id,
                versionId: this.lastDocumentVersion.id
            }

            await this.stopApproval(data)
            this.handleModalAndDocument(TASKS.ACTION_TYPES.STOP_APPROVAL)
        },

        async sendDocumentToEditMode () {
            this.$modal.hide('edit-document-warning')
            await this.updateDocument({
                ...this.document,
                status: DOCUMENT_STATUSES.IN_PROGRESS
            })

            await this.getDocumentTemplate()
        },

        async handleSubmitForReview ({
            expires_in_days: expiresInDays,
            message
        }) {
            if (!this.lastDocumentVersion) {
                this.$modal.hide('submit-for-review')
                return
            }

            try {
                await this.submitTasks({
                    regulationId: get(this, 'currentRegulation.id', null), // TODO - This has to be fixed
                    data: {
                        ...this.getTaskPayload(),
                        task_type: TASKS.TASK_TYPES.REVIEW,
                        expires_in_days: expiresInDays,
                        message
                    }
                })

                this.updateDocumentStatus(
                    DOCUMENT_STATUSES.IN_REVIEW,
                    FORMAT_OPTIONS.FOR_SEND_COMMENT
                )

                this.$modal.hide('submit-for-review')
                this.navigateToDocumentProperties()
                this.refreshDocument()
            } catch (error) {
                console.error(error)
            }
        },

        async handleSubmitForApproval ({ changeReason: message }) {
            if (!this.lastDocumentVersion) {
                return
            }

            try {
                await this.submitTasks({
                    regulationId: this.document.regulation_id,
                    data: {
                        ...this.getTaskPayload(),
                        task_type: TASKS.TASK_TYPES.APPROVE,
                        message
                    }
                })

                this.updateDocumentStatus(DOCUMENT_STATUSES.IN_APPROVAL)

                this.$modal.hide('submit-for-approval')
                if (
                    this.document.step_id === null ||
                    this.$route.name.includes(DMS_ROUTES.PROPERTIES.name)
                ) {
                    this.navigateToFilePreview()
                } else {
                    this.navigateToDocumentProperties()
                }
                this.refreshDocument()
            } catch (error) {
                console.error(error)
            }
        },

        async handleSubmitAction () {
            const {
                company_id: companyId,
                access_token: accessToken
            } = this.$route.query

            try {
                await this.submitAction({
                    companyId: companyId || this.company.id,
                    actionToken: this.taskActionToken,
                    accessToken: accessToken || ''
                })
                this.showToast(this.$t('COMMON.SUCCESS'))
            } catch (error) {
                console.error(error)
            }
        },

        getTaskPayload () {
            return {
                target_id: this.lastDocumentVersion.id,
                step_id: get(this, 'documentStep.id', null)
            }
        },

        updateDocumentStatus (status, formatOption = null) {
            this.setDocument({
                document: { discussion: [], ...this.document, status },
                users: this.usersById,
                formatOption
            })
        },

        navigateToDocumentProperties () {
            this.$router.push(DOCUMENT_WIZARD_ROUTES.PROPERTIES).catch(() => {})
        },

        async handleTaskActionButtonClick (action) {
            if (
                action.name === RESOURCES.APPROVAL_ACTIONS.ACTION_APPROVE &&
                !this.areAllSuggestedResponsiblityTasksChecked
            ) {
                this.$modal.show('suggested-responsiblity-tasks-modal', {
                    actionToken: action.action_token
                })

                return
            }

            this.taskActionToken = action.action_token
            await this.handleSubmitAction()

            this.handleModalAndDocument(action.name)
        },

        handleSuggestedResponsiblityModalBeforeOpen (event) {
            this.taskActionToken = event.params.actionToken
        },

        async handleSuggestedResponsiblityModalOkClick () {
            this.$modal.hide('suggested-responsiblity-tasks-modal')
            await this.handleSubmitAction()
            this.handleRedirectionAfterAction()
        },

        navigateToFilePreview () {
            this.$router.push(DMS_ROUTES.PROPERTIES).catch(() => {})
        },

        handleModalAndDocument (actionName) {
            actionName = this.user?.id
                ? actionName
                : TASKS.ADDITIONAL_WIZARD_TASK_MODAL_ACTIONS.GUEST_USER_REVIEW

            if (
                includes(
                    TASKS.DOCUMENT_WIZARD_ACTION_MODAL_ACTION_TYPES,
                    actionName
                )
            ) {
                return this.$modal.show('action-notification-modal', {
                    actionName
                })
            }

            this.handleRedirectionAfterAction()
        },

        async handleRedirectionAfterAction () {
            if (!this.companyMember?.id) {
                window.location = ROUTES.ADVISERA_CONFORMIO
                return
            }

            if (!this.userCanEditDocument) {
                return this.$router
                    .push(ROUTES.MY_WORK.OVERVIEW)
                    .catch(() => {})
            }

            this.refreshDocument()
        },

        refreshDocument () {
            const { company_id: companyId } = this.$route.query

            const params = {
                companyId: companyId || this.company.id,
                documentId: this.document.id
            }

            if (Object.keys(this.usersById).length) {
                this.getDocument(params)
                this.getDocumentTemplate()
            }
        },

        async getConditionalDocumentMeta (document) {
            if (document?.step_id && document?.id) {
                await this.getDocumentMeta({
                    documentId: document.id
                })
            }
        },

        handleModeChange (value) {
            EventBus.$emit('mode-changed', value)
        }
    },

    watch: {
        document (value) {
            this.getConditionalDocumentMeta(value)
        }
    }
}
</script>
