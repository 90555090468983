<template>
    <div class="cf-c-add-file">
        <label class="cf-c-add-file__ctn">
            <input
                ref="filesRef"
                type="file"
                class="cf-c-add-file__input"
                :accept="accept"
                @change="handleChange"
            />
            <div v-if="!value" class="cf-c-add-file__placeholder"></div>
            <div v-else class="cf-c-add-file__img">
                <img class="cf-u-height-full object-fit-contain" :src="value" />
            </div>
            <span class="cf-c-add-file__overlay">
                {{ $t('COMMON.CHANGE_FILE') }}
            </span>
        </label>
        <div v-if="value" class="cf-c-add-file__footer">
            <span v-if="fileUploaded" class="cf-c-add-file__name">
                {{ getFileName() }}
            </span>
            <div class="cf-c-add-file__delete-icon">
                <app-button variation="clear" @click="handleDelete">
                    <app-icon icon="delete-icon" />
                </app-button>
            </div>
        </div>
    </div>
</template>

<script>
import { first } from 'lodash-es'

export default {
    props: {
        hasErrors: {
            type: Boolean,
            required: false,
            default: false
        },

        hasLabel: {
            type: Boolean,
            required: false,
            default: true
        },

        accept: {
            type: String,
            required: true
        },

        value: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            fileUploaded: false
        }
    },

    methods: {
        handleChange () {
            this.fileUploaded = true
            this.$emit('file-uploaded', first(this.$refs.filesRef.files))
        },

        handleDelete () {
            this.$emit('file-deleted')
            this.$refs.filesRef.value = ''
        },

        getFileName () {
            return first(this.$refs.filesRef.files).name
        }
    }
}
</script>
