<template>
    <app-modal
        name="action-notification-modal"
        min-width42
        hide-border
        ok-only
        :ok-title="
            $t('DOCUMENT_WIZARD.ACTION_NOTIFICATION_MODAL.CONFIRM_TITLE')
        "
        :approval="true"
        :hide-footer="false"
        :click-to-close="true"
        @ok="handleClose"
        @before-open="handleBeforeOpen"
    >
        <template slot="body">
            <p>
                {{
                    actionName
                        ? $t(
                              `DOCUMENT_WIZARD.ACTION_NOTIFICATION_MODAL.${actionName.toUpperCase()}`
                          )
                        : ''
                }}
            </p>
        </template>
    </app-modal>
</template>

<script>
import { get } from 'lodash-es'

export default {
    data () {
        return {
            actionName: ''
        }
    },

    methods: {
        async handleClose () {
            this.$modal.hide('action-notification-modal')
            this.$emit('action-notification-modal-closed')
        },

        handleBeforeOpen (event) {
            this.actionName = get(event, 'params.actionName', '')
        }
    }
}
</script>
