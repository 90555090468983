import { VUE_APP_GTM_TRACKING_ID } from '../constants/index'

const vueGTMConfig = () => {
    return {
        id: VUE_APP_GTM_TRACKING_ID,
        defer: false,
        enabled: true,
        debug: true,
        loadScript: true,
        trackOnNextTick: false
    }
}

export default vueGTMConfig
