<template>
    <div ref="map" class="cf-c-map">
        <div class="cf-c-map__track"></div>
        <div
            v-for="(point, index) in points"
            :key="index"
            v-b-tooltip.hover.bottom="point.shortcode"
            class="cf-c-map__dot"
            :style="getPointStyle(point)"
            @click="handlePointClick(point.index)"
        ></div>
    </div>
</template>

<script>
import { calculateTopOffsetOfElementInDomTree } from '@/services/utils/dom'
import { filterDependingInputs } from '@/services/utils/functions'

export default {
    props: {
        form: {
            type: Object,
            required: true
        },

        inputs: {
            type: Array,
            required: true
        },

        question: {
            type: [String, Number],
            required: false,
            default: 0
        },

        isActive: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    data () {
        return {
            points: [],
            previousPointPosition: 0
        }
    },

    watch: {
        form () {
            this.$nextTick(this.calculatePoints)
        },

        question () {
            this.calculatePoints()
        }
    },

    mounted () {
        window.addEventListener('resize', this.calculatePoints)
        this.calculatePoints()
    },

    beforeDestroy () {
        window.removeEventListener('resize', this.calculatePoints)
    },

    methods: {
        calculatePoints () {
            this.points = []
            const container = document.getElementById('document-content')
            if (container) {
                const proportion = this.getContainerAndMapProprotion(container)
                this.mapPoints(
                    calculateTopOffsetOfElementInDomTree(container, 2),
                    proportion
                )
            }
        },

        getPointStyle (point) {
            const pointName = point.name
            const formPoint = this.form[pointName]
            const isOption = pointName.indexOf('OPTION') !== -1
            const isVisible = !!formPoint && !isOption

            return {
                top: `${point.position}px`,
                backgroundColor:
                    isVisible && this.isActive ? '#4788c6' : 'white',
                border: '1px solid white',
                zIndex: isVisible ? '2' : '1',
                display: isOption ? 'none' : null
            }
        },

        handlePointClick (index) {
            this.$router
                .push({
                    query: { question: index }
                })
                .catch(() => {})
        },

        increaseContainerHeight (container, percentage) {
            return (container.scrollHeight * percentage) / 100
        },

        getContainerAndMapProprotion (container) {
            return (
                this.increaseContainerHeight(container, 120) /
                this.$refs.map.offsetHeight
            )
        },

        positionCalculate (containerOffsetTop, offsetTop, proportion) {
            let position = (containerOffsetTop + offsetTop) / proportion
            const diffBetweenCurAndPrevMark =
                position - this.previousPointPosition

            if (
                diffBetweenCurAndPrevMark <= 10 &&
                diffBetweenCurAndPrevMark > 0
            ) {
                position += diffBetweenCurAndPrevMark
            }
            this.previousPointPosition = position

            return position
        },

        mapPoints (containerOffsetTop, proportion) {
            const correctInputs = filterDependingInputs(this.inputs, this.form)
            // We're not mapping in case we have more inputs than actual element
            // Human error while making the config
            correctInputs.forEach((input, index) => {
                const element = document.getElementById(
                    input.parent ? input.parent : input.name
                )

                if (element) {
                    const offsetTop = calculateTopOffsetOfElementInDomTree(
                        element
                    )

                    this.points = [
                        ...this.points,
                        {
                            position: this.positionCalculate(
                                containerOffsetTop,
                                offsetTop,
                                proportion
                            ),
                            shortcode: input.shortcode,
                            index,
                            name: input.name
                        }
                    ]
                }
            })
        }
    }
}
</script>
