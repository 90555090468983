const REGISTER_TYPE = 'security_objectives'

const ROUTES_SECURITY_OBJECTIVES = {
    BASE: {
        name: 'security-objectives'
    }
}

const NOT_EDITABLE_FIELDS = ['is_system_objective', 'locked', 'data_source']

const STATUSES = {
    DRAFT: 'draft',
    ACCEPTED: 'accepted'
}

const STATUSES_MAPPED = {
    [STATUSES.DRAFT]: {
        colorClass: 'color-gray-beta',
        label: 'SECURITY_OBJECTIVES.STATUSES.DRAFT'
    },
    [STATUSES.ACCEPTED]: {
        colorClass: 'color-success-alpha',
        label: 'SECURITY_OBJECTIVES.STATUSES.ACCEPTED'
    }
}

const TYPES = {
    TOP_LEVEL: 'top_level',
    OPERATIONAL: 'operational'
}

const TYPES_MAPPED = {
    [TYPES.TOP_LEVEL]: 'SECURITY_OBJECTIVES.TYPES.TOP_LEVEL',
    [TYPES.OPERATIONAL]: 'SECURITY_OBJECTIVES.TYPES.OPERATIONAL'
}

const FREQUENCIES = {
    EVERY_WEEK: 'every_week',
    EVERY_MONTH: 'every_month',
    EVERY_QUARTER: 'every_quarter',
    EVERY_6_MONTHS: 'every_6_months',
    EVERY_YEAR: 'every_year',
    EVERY_3_YEARS: 'every_3_years'
}

const FREQUENCIES_MAPPED = {
    [FREQUENCIES.EVERY_WEEK]: 'SECURITY_OBJECTIVES.FREQUENCIES.EVERY_WEEK',
    [FREQUENCIES.EVERY_MONTH]: 'SECURITY_OBJECTIVES.FREQUENCIES.EVERY_MONTH',
    [FREQUENCIES.EVERY_QUARTER]:
        'SECURITY_OBJECTIVES.FREQUENCIES.EVERY_QUARTER',
    [FREQUENCIES.EVERY_6_MONTHS]:
        'SECURITY_OBJECTIVES.FREQUENCIES.EVERY_SIX_MONTHS',
    [FREQUENCIES.EVERY_YEAR]: 'SECURITY_OBJECTIVES.FREQUENCIES.EVERY_YEAR',
    [FREQUENCIES.EVERY_3_YEARS]:
        'SECURITY_OBJECTIVES.FREQUENCIES.EVERY_THREE_YEARS'
}

const TARGET_TYPES = {
    PERCENTAGE: 'percentage',
    VALUE: 'value'
}

const TARGET_TYPES_MAPPED = {
    [TARGET_TYPES.PERCENTAGE]: 'SECURITY_OBJECTIVES.TARGET_TYPES.PERCENTAGE',
    [TARGET_TYPES.VALUE]: 'SECURITY_OBJECTIVES.TARGET_TYPES.VALUE'
}

export {
    REGISTER_TYPE,
    ROUTES_SECURITY_OBJECTIVES,
    NOT_EDITABLE_FIELDS,
    STATUSES,
    STATUSES_MAPPED,
    TYPES,
    TYPES_MAPPED,
    FREQUENCIES,
    FREQUENCIES_MAPPED,
    TARGET_TYPES,
    TARGET_TYPES_MAPPED
}
