import axios from 'axios'
import { CONFORMIO_APP_URL } from '@/constants'

const ENDPOINTS = {
    COUNTRIES: 'countries',
    AREAS: 'areas',
    LAWS: 'companies/:companyId/regulations/:regulationId/laws'
}

export default class GeneralService {
    /**
     * Get all countries
     *
     * @return Array
     */
    async getCountries () {
        try {
            const { data } = await axios.get(ENDPOINTS.COUNTRIES)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get all areas
     *
     * @return Array
     */
    async getAreas () {
        try {
            const { data } = await axios.get(ENDPOINTS.AREAS)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get all laws
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     *
     * @return Array
     */
    async getLaws (companyId, regulationId) {
        try {
            const route = ENDPOINTS.LAWS.replace(
                ':companyId',
                companyId
            ).replace(':regulationId', regulationId)

            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     *  General get method for local calls
     * @param uri
     * @returns {Promise<any>}
     */
    async get (uri) {
        try {
            const { data } = await axios.get(uri, {
                baseURL: CONFORMIO_APP_URL
            })
            return data
        } catch (error) {
            throw error
        }
    }
}

export const generalService = new GeneralService()
