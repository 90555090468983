import axios from 'axios'

const ENDPOINTS = {
    PAYMENT_SUPPORT: '/support/company/payment-support',
    SCHEDULE_EXPERT_CALL: companyId =>
        `/support/company/${companyId}/schedule-expert-call`
}

export default class SupportService {
    /**
     * Send payment support message
     *
     * @param {String} subject
     * @param {String} message
     *
     * @returns Promise
     */
    async sendPaymentSupport (subject, message, companyId) {
        try {
            const response = await axios.post(ENDPOINTS.PAYMENT_SUPPORT, {
                subject: subject,
                message: message,
                company_id: companyId
            })
            return response.data
        } catch (error) {
            throw error
        }
    }

    async scheduleExpertCall (companyId) {
        try {
            const { data } = await axios.get(
                ENDPOINTS.SCHEDULE_EXPERT_CALL(companyId)
            )

            return data
        } catch (error) {
            throw error
        }
    }
}

export const supportService = new SupportService()
