import { ACCOUNT_ROUTES } from '@/constants'

export default {
    path: 'account',
    name: ACCOUNT_ROUTES.INDEX.name,
    component: () => import('@/containers/Account/Account'),
    props: true,
    children: [
        {
            name: ACCOUNT_ROUTES.SETTINGS.name,
            path: 'settings',
            component: () => import('@/containers/Account/Settings'),
            meta: {
                pageTitle: 'ACCOUNT.SETTINGS',
                requiresAuth: true
            }
        },
        {
            name: ACCOUNT_ROUTES.MY_PROFILE.name,
            path: 'my-profile',
            component: () => import('@/containers/Account/MyProfile'),
            meta: {
                pageTitle: 'ACCOUNT.MY_PROFILE',
                requiresAuth: true
            }
        }
    ]
}
