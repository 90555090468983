<template>
    <app-modal
        name="contact-administrator"
        min-width42
        hide-border
        ok-only
        :approval="true"
        :hide-footer="false"
        :ok-title="$t('COMMON.OK')"
        :click-to-close="false"
        @ok="handleClose"
    >
        <template slot="body">
            <h4 class="cf-modal-black-center-txt">
                {{ $t('COMMON.PAYMENT_USER_MODAL_MESSAGE') }}
            </h4>
        </template>
    </app-modal>
</template>

<script>
export default {
    methods: {
        handleClose () {
            this.$modal.hide('contact-administrator')
        }
    }
}
</script>
