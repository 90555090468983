import axios from 'axios'
import {
    union,
    filter,
    keys,
    isEmpty,
    first,
    isArray,
    includes,
    trim,
    pickBy,
    uniq,
    get
} from 'lodash-es'
import { i18n } from '@/i18n'
import { FILENAME_REGEX, USER_MANAGEMENT } from '@/constants'
import { checkIfDependingOnIsFulfilled } from '@/services/utils/documents'

export function changedKeys (object1, object2) {
    const objectKeys = union(keys(object1), keys(object2))

    return filter(objectKeys, key => object1[key] !== object2[key])
}

export function firstChangedKey (object1, object2) {
    const differentKeys = changedKeys(object1, object2)

    return !isEmpty(differentKeys) ? first(differentKeys) : ''
}

export function getFullNameAndEmail (user) {
    user = user?.user ||
        user?.temp_data ||
        user || { first_name: '', last_name: '', email: '', full_name: '' }

    let fullName = user.full_name
        ? user.full_name
        : trim((user.first_name || '') + ' ' + (user.last_name || ''))

    return [fullName, user.email]
}

export function extractFullNameOrEmailUniversalReverseStructure (
    companyMember
) {
    if (!companyMember) {
        return ''
    }

    if (isEmpty(companyMember.user)) {
        return companyMember.email
    }

    const fullName = extractFullName(
        companyMember.user,
        'first_name',
        'last_name'
    )

    return fullName || companyMember.email
}

function extractFullName (user, firstNameAttribute, lastNameAttribute) {
    let fullName = ''
    if (!isEmpty(user[firstNameAttribute])) {
        fullName = user[firstNameAttribute]
    }
    if (!isEmpty(user[lastNameAttribute])) {
        fullName += ' ' + user[lastNameAttribute]
    }

    return trim(fullName)
}

export function extractFullNameOrEmailUniversal (
    user,
    firstNameAttribute = 'firstName',
    lastNameAttribute = 'lastName'
) {
    if (!user) {
        return ''
    }

    let fullName = extractFullName(user, firstNameAttribute, lastNameAttribute)

    return fullName || user.email
}

export function getFullNameOrEmail (user) {
    const [fullName, email] = getFullNameAndEmail(user)

    return fullName || email
}

export function forceFileDownload (response, fileName) {
    const url = window.URL.createObjectURL(
        new Blob([response.data], {
            type: response.headers['content-type']
        })
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    link.setAttribute('class', 'link-download')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export function convertArrayOfObjectsToString (arr, key = 'label') {
    return arr && isArray(arr) && arr.length
        ? arr.map(val => val[key]).join(', ')
        : ''
}

export function objectToQueryParams (params) {
    return params && Object.keys(params).length
        ? Object.keys(params)
              .map(key => key + '=' + params[key])
              .join('&')
        : ''
}

export function objectToFormData (obj, formData, namespace) {
    if (!formData) {
        formData = new FormData()
    }

    if (!(obj instanceof Object)) {
        return formData
    }

    Object.keys(obj).forEach(key => {
        const value = obj[key]

        if (value instanceof Object && !Array.isArray(value)) {
            return this.objectToFormData(value, formData, key)
        }

        if (namespace) {
            key = `${namespace}[${key}]`
        }

        if (Array.isArray(value)) {
            value.forEach(val => {
                formData.append(`${key}[]`, val)
            })
        } else {
            formData.append(key, value)
        }
    })

    return formData
}

export function dropEmptyValues (object) {
    return Object.fromEntries(
        Object.entries(object).filter(([_, v]) => v != null)
    )
}

export function generateArrayOfNumbers (from, to) {
    return Array(to)
        .fill(from)
        .map((e, i) => i + 1)
}

export function objectKeyIntersection (obj, arrayOfKeys) {
    keys(obj).forEach(key => {
        if (!includes(arrayOfKeys, key)) {
            delete obj[key]
        }
    })

    return obj
}

export function mapUsersByIdentifierUniversal (
    users,
    labelKey = 'label',
    firstNameAttribute = 'firstName',
    lastNameAttribute = 'lastName'
) {
    return users.map(user => {
        return {
            ...user,
            [labelKey]: extractFullNameOrEmailUniversalReverseStructure(
                user,
                labelKey,
                firstNameAttribute,
                lastNameAttribute
            )
        }
    })
}

export function mapUsersByIdentifier (users, labelKey = 'label') {
    return !isEmpty(users)
        ? users.map(user => {
              return {
                  ...user,
                  [labelKey]: getFullNameOrEmail(user)
              }
          })
        : []
}

export function mapManagedUsersByIdentifier (users, labelKey = 'label') {
    return users.map(user => {
        return {
            ...user,
            user_id: user.user ? user.user.id : user.id,
            [labelKey]: getFullNameOrEmail(user),
            disabled: !(
                user.status === USER_MANAGEMENT.STATUSES.ACTIVE && user.user
            ),
            tooltip:
                user.status === USER_MANAGEMENT.STATUSES.ACTIVE &&
                user.user &&
                user.user.id
                    ? ''
                    : i18n.t('REGISTERS.RISKS.DISABLED_OPTIONS.TOOLTIP')
        }
    })
}

export function includesSearchTerm (item = '', searchTerm = '') {
    return item.toLowerCase().indexOf(trim(searchTerm.toLowerCase())) > -1
}

function getFileNameFromHeader (response) {
    let fileName = ''
    const disposition = response.headers['content-disposition']

    if (disposition && disposition.indexOf('attachment') !== -1) {
        const matches = FILENAME_REGEX.exec(disposition)
        if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '')
        }
    }

    return fileName
}

export async function fileDownload (
    url,
    fileName = '',
    previewDirectly = false
) {
    try {
        const formattedUrl = previewDirectly ? `${url}?preview-directly` : url

        const response = await axios.get(formattedUrl, {
            responseType: 'arraybuffer'
        })

        fileName = fileName || getFileNameFromHeader(response)

        forceFileDownload(response, fileName)
    } catch (err) {
        throw err
    }
}

/**
 * Returns a sorting value by field name and define order by the current one.
 *
 * @param {String} fieldName
 * @param {String} currentValue
 *
 * @returns String
 */
export function getSortValue (fieldName, currentValue) {
    const currentOrder = currentValue.replace(`${fieldName}_`, '')
    let order =
        currentValue.startsWith(fieldName) && currentOrder === 'asc'
            ? 'desc'
            : 'asc'

    return `${fieldName}_${order}`
}

export function compareAssigneesByFullNameOrEmail (a, b, ascending = true) {
    if (!a) {
        return 1
    }

    if (!b) {
        return -1
    }
    return a.full_name && b.full_name
        ? a.full_name > b.full_name
            ? ascending
                ? 1
                : -1
            : ascending
            ? -1
            : 1
        : a.email > b.email
        ? ascending
            ? 1
            : -1
        : ascending
        ? -1
        : 1
}

export function compareMembersByFullNameOrEmail (a, b, ascending = true) {
    if (!a) {
        return 1
    }

    if (!b) {
        return -1
    }

    return getFullNameOrEmail(a.user ? a.user : a) >
        getFullNameOrEmail(b.user ? b.user : b)
        ? ascending
            ? 1
            : -1
        : ascending
        ? -1
        : 1
}

export function openRouteInNewTab (route, params) {
    let url = route.href

    if (params) {
        url = route.href + '?' + objectToQueryParams(params)
    }

    window.open(url, '_blank')
}

export function checkIfSubFieldIsFilled (object, field, subField) {
    return !!object[field] && !!object[field][subField]
}

export function fromJson (valueToBeParsed) {
    let value
    try {
        value = valueToBeParsed && JSON.parse(valueToBeParsed)
    } catch (error) {
        value = valueToBeParsed
    }

    return value
}

export function filterDependingInputs (inputs, form) {
    return inputs.filter(wizardInput => {
        const dependingOn = wizardInput.depending_on
            ? fromJson(wizardInput.depending_on)
            : wizardInput.depending_on

        return dependingOn
            ? checkIfDependingOnIsFulfilled(dependingOn, form)
            : true
    })
}

export const delay = (action, duration, params) =>
    new Promise((resolve, reject) => {
        setTimeout(() => {
            try {
                resolve(action(params))
            } catch (e) {
                reject(e)
            }
        }, duration)
    })

/**
 * Changes case of first letter of a string
 *
 * @param {String} string
 * @param {Boolean} isUpperCase
 *
 * @returns String
 */
export function changeFirstLetterCase (string, isUpperCase) {
    if (!string || typeof string !== 'string') {
        return ''
    }

    if (isUpperCase) {
        return string[0].toUpperCase() + string.slice(1)
    }

    return string[0].toLowerCase() + string.slice(1)
}

/**
 * Parse API error message by field key name
 *
 * @param {Object} error
 * @param {String} field
 *
 * @returns {String}
 */
export const parseApiErrorMessage = (error, field) => {
    const fieldErrors = get(error.response.data.errors, field, [])

    if (!fieldErrors.length) {
        return ''
    }

    return first(fieldErrors)
}

/**
 * Check if value is false or a positive decimal number
 *
 * @param {Boolean|Number} value
 *
 * @returns {Boolean}
 */
export const falseOrPositiveDecimal = value =>
    value === false || (!!parseFloat(value) && value >= 0)

export const random = (length = 8) => {
    // Declare all characters
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    // Pick characers randomly
    let str = ''
    for (let i = 0; i < length; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length))
    }

    return str
}

export const replaceSpecialCharactersWith = (input, replaceWith = '_') =>
    input.replace(/[^a-zA-Z0-9]/g, replaceWith)
