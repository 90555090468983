import { REGISTER_OF_RISKS } from '@/constants'
import { find, findIndex, pick } from 'lodash-es'

export function belongsToCategory (item, categoryId) {
    if (item.asset_category_id) {
        return item.asset_category_id === categoryId
    }
    if (
        typeof item.asset_categories !== 'undefined' &&
        item.asset_categories.length > 0
    ) {
        return item.asset_categories.some(
            itemAssetCategory => itemAssetCategory.id === parseInt(categoryId)
        )
    }
    return false
}

export function hasCategory (item, categoryId) {
    return item.asset_categories.some(
        itemCategory => itemCategory.id === parseInt(categoryId)
    )
}

export function calculateRiskLevel (likelihood, impact) {
    return parseInt(likelihood || 0, 10) + parseInt(impact || 0, 10)
}

export function hasVulnerability (item, vulnerabilityId) {
    return item.vulnerabilities.some(
        itemVulnerability => itemVulnerability.id === parseInt(vulnerabilityId)
    )
}

export function hasThreat (threat, asset, vulnerability) {
    return (
        hasVulnerability(threat, vulnerability.id) &&
        (hasAsset(threat, asset.id) ||
            hasCategory(threat, asset.asset_category_id))
    )
}

export function hasAsset (item, assetId) {
    return item.assets.some(itemAsset => itemAsset.id === parseInt(assetId))
}

export function isRiskAcceptable (level) {
    return level <= REGISTER_OF_RISKS.ACCEPTABLE_THRESHOLD
}

export function calculateResidualRiskBasedOnControls (
    numberOfSelectedControls,
    numberOfCoveredCategories,
    numberOfCoveredGroups,
    riskLevel
) {
    let residualRisk = riskLevel

    if (
        numberOfSelectedControls >= 5 &&
        numberOfCoveredCategories >= 4 &&
        numberOfCoveredGroups === 3
    ) {
        residualRisk = riskLevel - 3
    } else if (
        numberOfSelectedControls >= 5 &&
        numberOfCoveredCategories === 3 &&
        numberOfCoveredGroups === 3
    ) {
        residualRisk = riskLevel - 2
    } else if (
        [3, 4].includes(numberOfSelectedControls) &&
        numberOfCoveredCategories === 4 &&
        numberOfCoveredGroups === 3
    ) {
        residualRisk = riskLevel - 2
    } else if (
        [3, 4].includes(numberOfSelectedControls) &&
        numberOfCoveredCategories === 3 &&
        numberOfCoveredGroups === 3
    ) {
        residualRisk = riskLevel - 1
    } else if (
        [3, 4].includes(numberOfSelectedControls) &&
        [4, 5, 6].includes(numberOfCoveredCategories) &&
        numberOfCoveredGroups === 3
    ) {
        residualRisk = riskLevel - 2
    }

    return residualRisk < 0 ? 0 : residualRisk
}

export function getDefaultRiskData () {
    return {
        is_reviewed: false,
        internal_is_reviewed: false,
        impact: null,
        likelihood: null,
        level: 0,
        asset_owner_id: null,
        risk_owner_id: null,
        comment: null,
        residual_risk: 0,
        risk_treatment_description: null,
        is_acceptable: false,
        departments: [],
        risk_treatment_options: [],
        controls: [],
        asset_id: null,
        vulnerability_id: null,
        threat_id: null,
        asset: {},
        vulnerability: {},
        threat: {},
        risk_state: {
            name: REGISTER_OF_RISKS.STATUSES.NEW
        }
    }
}

export function addNameCodeFieldToControl (control) {
    return {
        ...control,
        nameCode: `${control.code} - ${control.name}`
    }
}

export function prepareRiskFromResponse (risk) {
    const riskIncidents = risk.incidents
        ? risk.incidents.map(incident => ({
              ...incident,
              label: incident.title,
              description: incident.description
          }))
        : []

    return {
        ...risk,
        is_reviewed: !!risk.is_reviewed,
        internal_is_reviewed: !risk.is_reviewed,
        incidents: riskIncidents,
        risk_treatment_options: mapRiskTreatmentOptions(
            risk.risk_treatment_options
        )
    }
}

function mapRiskTreatmentOptions (options) {
    if (!Array.isArray(options)) {
        return options
    }

    return options.reduce((currReducer, option) => {
        currReducer[option.name] = true

        return currReducer
    }, {})
}

export function mapOwnersToRisks (risks, users) {
    return [...risks].map(risk => ({
        ...risk,
        owner: users.find(user => user.id === risk.risk_owner_id)
    }))
}

export function getRiskOwnerName (risk) {
    if (!risk || !risk.owner) {
        return null
    }

    return risk.owner.first_name ? risk.owner.first_name : risk.owner.email
}

export function reduceCache (cache) {
    if (cache.length > 0) {
        cache.forEach(asset => {
            delete asset.name
            delete asset.transfer_description
            delete asset.asset_category
            delete asset.category
            delete asset.created_at
            delete asset.updated_at
            delete asset.created_by
            delete asset.checked
            delete asset.disabled

            // processing vulnerabilities
            if (asset.vulnerabilities && asset.vulnerabilities.length > 0) {
                asset.vulnerabilities.forEach(vulnerability => {
                    // processing vulnerabilities
                    delete vulnerability.name
                    delete vulnerability.controls
                    delete vulnerability.asset_categories
                    delete vulnerability.created_at
                    delete vulnerability.updated_at
                    delete vulnerability.created_by
                    delete vulnerability.checked
                    delete vulnerability.disabled
                    delete vulnerability.isLimitExceeded

                    // removing pivot from vulnerability.controls
                    if (
                        vulnerability.controls &&
                        vulnerability.controls.length > 0
                    ) {
                        vulnerability.controls.forEach(vulnerabilityControl => {
                            delete vulnerabilityControl.pivot
                        })
                    }

                    // removing pivot from vulnerability.asset_categories
                    if (
                        vulnerability.asset_categories &&
                        vulnerability.asset_categories.length > 0
                    ) {
                        vulnerability.asset_categories.forEach(
                            vulnerabilityAssetCategory => {
                                delete vulnerabilityAssetCategory.pivot
                            }
                        )
                    }

                    // working with asset.vulnerability.threats
                    if (
                        vulnerability.threats &&
                        vulnerability.threats.length > 0
                    ) {
                        vulnerability.threats.forEach(threat => {
                            delete threat.name
                            delete threat.threat_category_id
                            delete threat.asset_category_id
                            delete threat.threat_category
                            delete threat.created_at
                            delete threat.updated_at
                            delete threat.created_by
                            delete threat.assets
                            delete threat.vulnerabilities
                            delete threat.controls
                            delete threat.isLimitExceeded

                            // removing pivot from threat.controls
                            if (threat.controls && threat.controls.length > 0) {
                                threat.controls.forEach(threatControl => {
                                    delete threatControl.pivot
                                })
                            }
                        })
                    }
                })
            }
        })
    }
    return cache
}

export function getSelectedAssets (cache) {
    return cache.length
}

export function getSelectedVulnerabilities (cache) {
    let counter = 0

    cache.forEach(function (asset) {
        counter += asset.vulnerabilities.length
    })

    return counter
}

export function getSelectedThreats (cache) {
    let counter = 0

    cache.forEach(function (asset) {
        asset.vulnerabilities.forEach(function (vulnerability) {
            counter += vulnerability.threats.length
        })
    })

    return counter
}

export function mapRiskForSubmit (risk) {
    const { ...rest } = risk

    return {
        ...rest,
        departments: risk.departments
            ? risk.departments.map(department => department.id)
            : [],
        controls: risk.controls ? risk.controls.map(control => control.id) : []
    }
}

export function mapCategories (items, field) {
    return items.reduce((categories, asset) => {
        const { [field]: category } = asset

        if (findIndex(categories, { id: category ? category.id : -1 }) === -1) {
            categories.push(category)
        }

        return categories
    }, [])
}

export function getVulnerabilityFromCache (
    risksCache,
    assetId,
    vulnerabilityId
) {
    const assetData = getAssetFromCache(risksCache, assetId)

    if (assetData) {
        return find(assetData.vulnerabilities, {
            id: vulnerabilityId
        })
    }

    return null
}

export function getAssetFromCache (risksCache, assetId) {
    return find(risksCache, { id: assetId })
}

export function formatRisksCacheEntry (data, entryType = 'asset') {
    switch (entryType) {
        case 'asset':
            return pick(data, ['id', 'asset_category_id', 'vulnerabilities'])

        case 'vulnerability':
            return pick(data, ['id', 'threats'])

        case 'threat':
            return pick(data, ['id'])
    }
}

export function newAssetWithData (asset, vulnerabilities, threats) {
    const vulnerabilitiesForAsset = vulnerabilities.filter(vulnerability =>
        hasCategory(vulnerability, asset.asset_category_id)
    )

    return {
        ...asset,
        vulnerabilities: vulnerabilitiesForAsset.map(vulnerability => ({
            ...vulnerability,
            threats: threats.filter(threat =>
                hasThreat(threat, asset, vulnerability)
            )
        }))
    }
}
