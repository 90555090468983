<template>
    <div
        :class="[
            'custom-paragraph',
            { [highlightClasses]: isHighlighted },
            { 'inline-button': isAddButtonVisible }
        ]"
        :style="[isAddButtonVisible ? { display: 'inline-block' } : '']"
    >
        <template v-if="isPreview">
            <div v-if="isParagraphCreated" class="custom-paragraph__paragraph">
                <p
                    :class="[
                        'custom-paragraph__paragraph-text',
                        { 'add-background': showTextBackground }
                    ]"
                    v-b-tooltip.html="editTooltip"
                >
                    {{ value }}
                </p>
                <div
                    v-if="isCustomModeAvailable && isDocumentEditable"
                    class="custom-paragraph__action-block flex-start"
                >
                    <button
                        type="button"
                        class="btn btn--alpha"
                        @click="activateCustomModeAndEdit"
                    >
                        {{ $t('COMMON.EDIT') }}
                    </button>
                </div>
            </div>
            <button
                v-else-if="!isParagraphCreated && isCustomModeActive"
                type="button"
                class="btn btn--beta"
                @click="changeAction(CUSTOM_PARAGRAPH_ACTIONS.CREATE)"
            >
                <app-icon size="sm" icon="paragraph-icon" />
                {{ $t('COMMON.TABLES.ADD_PARAGRAPH') }}
            </button>
        </template>

        <div
            v-else-if="isCustomModeActive && isCreate"
            class="custom-paragraph__box"
        >
            <label class="custom-paragraph__text-label">
                {{ $t('DOCUMENT_WIZARD.CUSTOM_PARAGRAPH.TEXT_LABEL') }}:
            </label>
            <autosize-textarea
                v-model="value"
                :char-limit="CUSTOM_PARAGRAPH_CHAR_LIMIT"
                @input="updateParagraphState"
                class="custom-paragraph__textarea"
                :placeholder="
                    $t('DOCUMENT_WIZARD.CUSTOM_PARAGRAPH.TEXTAREA_PLACEHOLDER')
                "
                :autofocus="true"
            />
            <div class="custom-paragraph__action-block">
                <div class="custom-paragraph__btn-group">
                    <button
                        type="button"
                        class="btn btn--beta"
                        @click="cancelChanges"
                    >
                        {{ $t('COMMON.CANCEL') }}
                    </button>
                    <button
                        type="button"
                        class="btn btn--alpha"
                        :disabled="!value"
                        @click="saveChanges"
                    >
                        {{ $t('COMMON.SAVE') }}
                    </button>
                </div>
            </div>
        </div>

        <div
            v-else-if="isCustomModeActive && isEdit"
            class="custom-paragraph__box"
        >
            <label class="custom-paragraph__text-label">
                {{ $t('DOCUMENT_WIZARD.CUSTOM_PARAGRAPH.TEXT_LABEL') }}:
            </label>
            <autosize-textarea
                v-model="value"
                :char-limit="CUSTOM_PARAGRAPH_CHAR_LIMIT"
                @input="updateParagraphState"
                class="custom-paragraph__textarea"
                :placeholder="
                    $t('DOCUMENT_WIZARD.CUSTOM_PARAGRAPH.TEXTAREA_PLACEHOLDER')
                "
                :autofocus="true"
            />
            <div class="custom-paragraph__action-block space-between">
                <div class="custom-paragraph__btn-group">
                    <button
                        type="button"
                        class="btn btn--danger"
                        @click="showDeleteConfirmation"
                    >
                        {{
                            $t(
                                'DOCUMENT_WIZARD.CUSTOM_PARAGRAPH.DELETE_BUTTON_LABEL'
                            )
                        }}
                    </button>
                </div>
                <div class="custom-paragraph__btn-group">
                    <button
                        type="button"
                        class="btn btn--beta"
                        @click="cancelChanges"
                    >
                        {{ $t('COMMON.CANCEL') }}
                    </button>
                    <button
                        type="button"
                        class="btn btn--alpha"
                        :disabled="!value || value === initialValue"
                        @click="saveChanges"
                    >
                        {{ $t('COMMON.UPDATE') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/EventBus'
import { isEmpty } from 'lodash-es'
import {
    CUSTOM_PARAGRAPH_ACTIONS,
    BASE_CLASSES,
    CUSTOM_PARAGRAPH_HIGHLIGHT_CLASSES,
    CUSTOM_PARAGRAPH_CHAR_LIMIT,
    DOCUMENT_STATUSES
} from '@/constants'
import { mapActions } from 'vuex'

export default {
    props: {
        inputName: {
            type: String,
            required: true
        },
        encodedInitialValue: {
            type: String,
            required: false,
            default: ''
        },
        encdodedUnsavedValue: {
            type: String,
            required: false,
            default: ''
        },
        initialAction: {
            type: String,
            required: false
        },
        isCustomModeAvailable: {
            type: Boolean,
            required: true,
            default: false
        },
        isCustomModeActive: {
            type: Boolean,
            required: true,
            default: false
        },
        isDocumentEditable: {
            type: Boolean,
            required: true,
            default: false
        },
        documentStatus: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            CUSTOM_PARAGRAPH_ACTIONS,
            CUSTOM_PARAGRAPH_CHAR_LIMIT,
            isCustomParagraph: true, // Needed in AppDocument
            value: '',
            action: CUSTOM_PARAGRAPH_ACTIONS.PREVIEW
        }
    },

    created () {
        // Set custom paragraph value
        this.value = this.unsavedValue ? this.unsavedValue : this.initialValue

        if (this.initialAction) {
            this.action = this.initialAction
        }
    },

    computed: {
        initialValue () {
            return this.fromBase64(this.encodedInitialValue)
        },

        unsavedValue () {
            return this.fromBase64(this.encdodedUnsavedValue)
        },

        highlightClasses () {
            return [
                ...BASE_CLASSES,
                ...CUSTOM_PARAGRAPH_HIGHLIGHT_CLASSES
            ].join(' ')
        },

        isParagraphCreated () {
            return !isEmpty(this.value)
        },

        isAddButtonVisible () {
            return (
                this.isPreview &&
                this.isCustomModeActive &&
                !this.isParagraphCreated
            )
        },

        isPreview () {
            return this.action === CUSTOM_PARAGRAPH_ACTIONS.PREVIEW
        },

        isHighlighted () {
            return (
                (this.isPreview && this.isCustomModeActive) || !this.isPreview
            )
        },

        isCreate () {
            return this.action === CUSTOM_PARAGRAPH_ACTIONS.CREATE
        },

        isEdit () {
            return this.action === CUSTOM_PARAGRAPH_ACTIONS.EDIT
        },

        isUnsaved () {
            return this.initialValue.trim() !== this.value.trim()
        },

        editTooltip () {
            return this.documentStatus === DOCUMENT_STATUSES.IN_REVIEW ||
                this.documentStatus === DOCUMENT_STATUSES.IN_APPROVAL ||
                this.documentStatus === DOCUMENT_STATUSES.CORRECTIONS_NEEDED
                ? this.$t('DOCUMENT_WIZARD.CUSTOM_PARAGRAPH.REVIEW_TOOLTIP')
                : ''
        },

        showTextBackground () {
            return this.documentStatus !== DOCUMENT_STATUSES.COMPLETED
        }
    },

    methods: {
        ...mapActions('confirmModal', [
            'openConfirmModal',
            'closeConfirmModal'
        ]),
        ...mapActions('document', ['saveCustomInput']),

        isEmpty,

        fromBase64 (string) {
            return decodeURIComponent(escape(atob(string)))
        },

        changeAction (mode) {
            this.action = mode

            this.updateParagraphState()
        },

        activateCustomModeAndEdit () {
            this.changeAction(CUSTOM_PARAGRAPH_ACTIONS.EDIT)

            if (!this.isCustomModeActive) {
                EventBus.$emit('mode-changed', true)
            }
        },

        cancelChanges () {
            this.value = this.initialValue

            this.changeAction(CUSTOM_PARAGRAPH_ACTIONS.PREVIEW)
        },

        async saveChanges () {
            this.changeAction(CUSTOM_PARAGRAPH_ACTIONS.PREVIEW)

            try {
                await this.saveCustomInput({
                    name: this.inputName,
                    value: this.value
                })
            } catch (error) {
                // Reset value
                this.value = this.initialValue
            }
        },

        showDeleteConfirmation () {
            this.openConfirmModal({
                title: this.$t(
                    'DOCUMENT_WIZARD.CUSTOM_PARAGRAPH.DELETE_MODAL.TITLE'
                ),
                content: this.$t(
                    'DOCUMENT_WIZARD.CUSTOM_PARAGRAPH.DELETE_MODAL.DESCRIPTION'
                ),
                confirmActionLabel: this.$t('COMMON.DELETE'),
                confirmActionVariation: 'danger',
                confirmHandler: this.handleDeleteParagraph
            })
        },

        handleDeleteParagraph () {
            this.value = ''

            this.saveChanges()

            this.closeConfirmModal()
        },

        updateParagraphState () {
            EventBus.$emit('custom-paragraph-state', this.inputName, {
                unsavedValue: this.value,
                initialAction: this.action
            })
        }
    }
}
</script>
