<template>
    <app-modal
        name="submit-for-approval"
        ok-title="DOCUMENT_WIZARD.SUBMIT_FOR_APPROVAL"
        title="DOCUMENT_WIZARD.APPROVE_MODAL.TITLE"
        @ok="handleSubmit"
        @cancel="setInitialForm"
    >
        <template slot="body">
            <h5 class="cf-c-modal__subheading">
                {{ $t('DOCUMENT_WIZARD.REVIEW_CHANGES') }}
            </h5>
            <app-input
                v-model="form.changeReason"
                class="pb-4"
                :max-length="100"
                :error-message="firstError('change_reason')"
                min-value="0"
            />
        </template>
    </app-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data () {
        return {
            form: {
                changeReason: ''
            }
        }
    },

    computed: {
        ...mapGetters('errors', ['firstError'])
    },

    methods: {
        ...mapActions('errors', ['clearErrors']),

        handleSubmit () {
            this.$emit('submit', this.form)
            this.setInitialForm()
        },

        setInitialForm () {
            this.form = {
                changeReason: ''
            }
        }
    },

    watch: {
        'form.changeReason' () {
            this.clearErrors('change_reason')
        }
    }
}
</script>
