import { MY_PERMISSIONS } from '@src/constants'
import { myPermissionsGuard } from '@src/router/guards'
import { ROUTES_COMPANY_SETTINGS } from './constants'

export default {
    path: 'company-settings',
    name: ROUTES_COMPANY_SETTINGS.BASE.name,
    component: () => import('./ModuleCompanySettings'),
    props: true,
    redirect: to => ({
        name: ROUTES_COMPANY_SETTINGS.USER_MANAGEMENT.name,
        params: { ...to.params }
    }),
    children: [
        {
            path: 'user-management',
            name: ROUTES_COMPANY_SETTINGS.USER_MANAGEMENT.name,
            component: () => import('./user-management/UserManagement'),
            props: true,
            meta: {
                pageTitle: 'USER_MANAGEMENT.PAGE_TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.USER_MANAGEMENT_ACCESS]
            }
        }
    ]
}
