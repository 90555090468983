<template>
    <popper-js
        :disabled="disabled"
        :trigger="trigger"
        :options="{
            placement,
            modifiers: { offset: { offset: `${offset}px` } }
        }"
        @created="init"
    >
        <div class="popper">
            <slot v-if="isContentVisible" name="content"></slot>
        </div>

        <span slot="reference">
            <slot name="trigger"></slot>
        </span>
    </popper-js>
</template>

<script>
import PopperJs from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

export default {
    components: {
        PopperJs
    },

    props: {
        placement: {
            type: String,
            required: false,
            default: 'bottom'
        },

        trigger: {
            type: String,
            required: false,
            default: 'click'
        },

        offset: {
            type: Number,
            required: false,
            default: 0
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data () {
        return {
            instance: null,
            isContentVisible: true
        }
    },

    methods: {
        init (popper) {
            this.instance = popper
            this.isContentVisible = true
        },

        hide () {
            this.isContentVisible = false
        }
    }
}
</script>

<style type="scss" scoped>
.popper {
    box-shadow: none;
    padding: 0;
    font-size: inherit;
    border: none;
    text-align: start;
}
</style>
