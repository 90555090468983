import {
    API_ROUTE,
    CONFORMIO_APP_URL,
    CONFORMIO_API_URL,
    SLACK_CLIENT_ID
} from '@/constants'

let config = {
    getApiUrl () {
        return CONFORMIO_API_URL + API_ROUTE
    },

    getAppUrl () {
        return CONFORMIO_APP_URL
    },

    getHostName () {
        return CONFORMIO_API_URL
    },

    isProductionEnv () {
        return process.env.NODE_ENV === 'production'
    },

    getSlackClientId () {
        return SLACK_CLIENT_ID
    }
}

export { config }
