<template>
    <searchable-list>
        <template slot="input">
            <app-input
                v-model="searchTerm"
                :placeholder="inputPlaceholder"
                :ignore-localization="itemSelected"
                type="text"
                bg-gray
                small
            />
        </template>

        <select-list
            :list="filteredList"
            :track-by="trackBy"
            :label="label"
            :empty-list-message="emptyListMessage"
            :by-category="byCategory"
            small
            @click="$emit('select', $event)"
        />
        <slot name="add-new"></slot>
    </searchable-list>
</template>

<script>
import { isEmpty } from 'lodash-es'
import { includesSearchTerm } from '@/services/utils/functions'

export default {
    props: {
        list: {
            type: Array,
            required: true,
            default: () => []
        },

        trackBy: {
            type: String,
            required: false,
            default: 'id'
        },

        label: {
            type: String,
            required: false,
            default: 'name'
        },

        emptyListMessage: {
            type: String,
            required: false,
            default: 'COMMON.CHECKBOX_SEARCH_EMPTY_LIST'
        },

        byCategory: {
            type: Boolean,
            required: false,
            default: false
        },

        placeholder: {
            type: String,
            required: false,
            default: 'COMMON.SEARCH'
        },

        small: {
            type: Boolean,
            required: false,
            default: false
        },

        selectedItem: {
            type: Object,
            required: false
        }
    },

    data: () => ({
        searchTerm: ''
    }),

    computed: {
        filteredList () {
            return this.list.filter(item =>
                includesSearchTerm(item[this.label], this.searchTerm)
            )
        },

        itemSelected () {
            return !isEmpty(this.selectedItem)
        },

        inputPlaceholder () {
            return this.itemSelected ? this.selectedItem.name : this.placeholder
        }
    }
}
</script>
