import axios from 'axios'

const ENDPOINTS = {
    MY_COMMENTS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/my-discussions`,
    COMMENT: (companyId, regulationId, commentId) =>
        `companies/${companyId}/regulations/${regulationId}/comments/${commentId}`,
    COMMENTS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/comments`
}

class CommentService {
    async resolveComment (companyId, regulationId, comment) {
        try {
            const route = ENDPOINTS.COMMENT(companyId, regulationId, comment.id)

            return await axios.put(route, comment)
        } catch (error) {
            throw error
        }
    }

    async addComment (companyId, regulationId, payload) {
        try {
            const route = ENDPOINTS.COMMENTS(companyId, regulationId)

            const { data } = await axios.post(route, payload)
            return data
        } catch (error) {
            throw error
        }
    }

    async deleteComment (companyId, regulationId, id, accessToken) {
        try {
            let route = ENDPOINTS.COMMENT(companyId, regulationId, id)

            if (accessToken) {
                route = `${route}?access_token=${accessToken}`
            }

            const { data } = await axios.delete(route)
            return data
        } catch (error) {
            throw error
        }
    }

    async getMyDiscussions (companyId, regulationId) {
        try {
            const route = ENDPOINTS.MY_COMMENTS(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    async updateComment (companyId, regulationId, commentId, commentData) {
        try {
            const route = ENDPOINTS.COMMENT(companyId, regulationId, commentId)

            const { data } = await axios.put(route, commentData)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const commentService = new CommentService()
