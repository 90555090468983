import { authService } from '@/services/auth'
import { getLocalStorageItem } from '@/services/localStorage'
import { SUBDOMAIN, ISO_27001_ROUTE_PARAM } from '@/constants'

export default class RoutingService {
    // Generates full route (including subdomain - company slug)
    generateRoute (to) {
        return {
            name: to.name,
            params: {
                subdomain: getLocalStorageItem(SUBDOMAIN) || '',
                ...to.params
            },
            query: {
                ...to.query
            },
            hash: to.hash ? to.hash : ''
        }
    }

    generateStandardBasedRoute (name) {
        return this.generateRoute({
            name,
            params: { standard: ISO_27001_ROUTE_PARAM }
        })
    }

    attachSubdomainToRoute (to, next) {
        const subdomain = getLocalStorageItem(SUBDOMAIN) || ''

        if (
            authService.isLoggedIn() &&
            subdomain &&
            (!to.params.subdomain || to.params.subdomain !== subdomain)
        ) {
            return next({
                name: to.name,
                params: { ...to.params, subdomain },
                query: { ...to.query },
                hash: to.hash ? to.hash : ''
            })
        }

        next()
    }
}

export const routingService = new RoutingService()
