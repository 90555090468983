<template>
    <searchable-list
        v-click-outside="hide"
        :shadowed="shadowed"
        :flying-list="flyingList"
    >
        <template slot="input">
            <div class="cf-u-position-relative">
                <app-input
                    v-model="searchTerm"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    v-bind="heightOption"
                    @focus="show"
                />
                <app-icon
                    v-if="icon"
                    class="cf-u-color-primary cf-u-position-absolute cf-u-icon-postion"
                    icon="search-icon"
                />
            </div>
        </template>
        <div v-show="isVisible">
            <div v-for="listItem in list" :key="listItem[trackBy]">
                <checkbox-list
                    empty-list-message=""
                    :header="$t(listItem.label)"
                    :list="filteredCheckboxes(listItem.list)"
                    :header-always="true"
                    :should-reinitialize="shouldReinitialize"
                    @checked="handleChecked(listItem.key, $event)"
                />
            </div>
            <slot name="after-content"></slot>
        </div>
        <div
            v-if="showAdd"
            class="cf-u-display-flex cf-u-justify-end cf-u-p-default cf-u-mt-sm"
            :class="[{ 'cf-u-display-none': !isVisible }, buttonWrapClass]"
        >
            <app-button @click="handleAdding">
                {{ $t('COMMON.ADD') }}
            </app-button>
        </div>
    </searchable-list>
</template>

<script>
import { isArray, isString } from 'lodash-es'
import { includesSearchTerm } from '@/services/utils/functions'
import ClickOutside from '@/directives/ClickOutside'

export default {
    directives: {
        'click-outside': ClickOutside
    },

    props: {
        list: {
            type: Array,
            validator: list => {
                for (let listItem of list) {
                    if (!isArray(listItem.list)) {
                        return false
                    }
                    if (!isString(listItem.label)) {
                        return false
                    }
                    if (!isString(listItem.key)) {
                        return false
                    }
                }

                return true
            },
            required: true
        },

        trackBy: {
            type: String,
            default: 'id'
        },

        label: {
            type: String,
            default: 'label'
        },

        showAdd: {
            type: Boolean,
            default: false
        },

        heightSize: {
            type: String,
            default: ''
        },

        shadowed: {
            type: Boolean,
            required: false,
            default: true
        },

        flyingList: {
            type: Boolean,
            required: false,
            default: false
        },

        isInitiallyVisible: {
            type: Boolean,
            required: false,
            default: true
        },

        placeholder: {
            type: String,
            required: false,
            default: 'COMMON.SEARCH'
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        shouldReinitialize: {
            type: Boolean,
            required: false,
            default: false
        },

        buttonWrapClass: {
            type: String,
            required: false,
            default: ''
        },

        icon: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data: () => ({
        searchTerm: '',
        checked: {},
        isVisible: true
    }),

    computed: {
        heightOption () {
            return this.heightSize
                ? { [`height-${this.heightSize}`]: true }
                : null
        }
    },

    created () {
        this.isVisible = this.isInitiallyVisible
    },

    methods: {
        show () {
            this.isVisible = true
        },

        hide () {
            this.isVisible = false
        },

        uncheckItem (key, identifier) {
            this.checked[key][identifier] = false
        },

        filteredCheckboxes (list) {
            return list.filter(item =>
                includesSearchTerm(item[this.label], this.searchTerm)
            )
        },

        handleChecked (listItemKey, data) {
            this.$set(this.checked, listItemKey, data)
            this.$emit('checked', this.checked)
        },

        handleAdding () {
            this.searchTerm = ''
            this.$emit('add-items')
            this.hide()
        }
    }
}
</script>
