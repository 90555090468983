import { mapGetters } from 'vuex'
import { isFreeTrialPlan } from '@/services/utils/company'
import { COMPANY, COMPANY_SETTINGS_ROUTES } from '@/constants'
import { routingService } from '@/services/routing'
import { hasPermission } from '@src/services/permissions/my-permissions'
import { COMPANY_ROLES } from '@src/modules/company-settings/constants'

const userManagementPermissions = {
    canViewSubscriptions () {
        return (
            !this.company.isUnderConsultant &&
            this.companyMember?.role?.name === COMPANY_ROLES.PROJECT_MANAGER
        )
    }
}

const internalAuditPermissions = {
    canPrintChecklist () {
        return !this.isCompanyInFreeTrial
    }
}

export default {
    methods: {
        isNotPermited (key) {
            const result = this.getCompanyPermissionsKeys

            return result && result.includes(key) ? key : false
        }
    },

    computed: {
        ...mapGetters('company', [
            'company',
            'getCompanyPermissionsKeys',
            'isCompanyExpired',
            'pricingPlan'
        ]),
        ...mapGetters('account', ['companyMember']),

        isCompanyInFreeTrial () {
            return isFreeTrialPlan(this.pricingPlan?.plan.name)
        },

        companySettingsNavigation () {
            return COMPANY.COMPANY_SETTINGS_OPTIONS.map(item => {
                return {
                    url: routingService.generateStandardBasedRoute(
                        item.route.name
                    ),
                    label: this.$t(item.title),
                    isVisible:
                        item.route ===
                        COMPANY_SETTINGS_ROUTES.SUBSCRIPTION.INDEX
                            ? hasPermission(item.permission) &&
                              !this.company.isUnderConsultant
                            : hasPermission(item.permission)
                }
            }).filter(item => {
                return item.isVisible
            })
        },

        ...userManagementPermissions,
        ...internalAuditPermissions
    }
}
