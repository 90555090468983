const ISO_27001 = process.env.VUE_APP_STANDARD_ISO_27001
const ISO_27001_ROUTE_PARAM = process.env.VUE_APP_STANDARD_ISO_27001_ROUTE_PARAM

const REGULATION_ROUTES = {
    [ISO_27001_ROUTE_PARAM]: ISO_27001
}

const REGULATION_PARAMS_BY_NAME = {
    [ISO_27001]: ISO_27001_ROUTE_PARAM
}

const REGULATION_ROLES = {
    SPONSOR: 'sponsor',
    PROJECT_MANAGER: 'project_manager',
    IMP_3_1_1: 'imp_3_1_1',
    IMP_3_5_1: 'imp_3_5_1',
    OBJECTIVE_APPROVER: 'objective_approver',
    PCA_3_1_1: 'pca_3_1_1',
    INTERNAL_AUDIT_OWNER: 'internal_audit_owner'
}

const AVAILABLE_REGULATIONS = {
    ISO_27001
}

const COMPANY_SIZES = {
    FROM_ZERO_TO_TWENTY_EMPLOYEES: 'zero_to_twenty_employees',
    FROM_TWENTY_ONE_TO_FIFTY_EMPLOYEES: 'twenty_to_fifty_employees',
    FROM_FIFTY_ONE_TO_TWO_HUNDRED_EMPLOYEES: 'fifty_to_two_hundred_employees',
    FROM_TWO_HUNDRED_ONE_TO_FIVE_HUNDRED_EMPLOYEES:
        'one_hundred_to_five_hundred_employees',
    FROM_FIVE_HUNDRED_ONE_TO_TWO_THOUSAND_EMPLOYEES:
        'five_hundred_to_two_thousand_employees',
    TWO_THOUSAND_ONE_OR_MORE_EMPLOYEES: 'two_thousand_or_more_employees'
}

const STEP_AWARENESS = {
    DOCUMENTS: 'DOCUMENTS',
    ROLES: 'ROLES',
    MATERIALS: 'MATERIALS',
    ENABLED: 'enabled',
    ASSIGNEES: 'assignees'
}

const PLACEHOLDER = '{0}'

const STANDARD_TRANSLATION_KEYS = {
    [ISO_27001_ROUTE_PARAM]: 'ISO_27001'
}

const MODULES = {
    COMPANY: {
        NAME: 'company',
        PATH: 'company/company'
    },
    ROLES: {
        NAME: 'roles',
        PATH: 'onboarding/roles'
    }
}

const INPUT_FIELD_TYPES = {
    PARTICIPANTS: 'step_participants',
    WORKLOAD: 'step_workload',
    TOTAL_TIME: 'step_total_time',
    REASON: 'step_reason',
    DEADLINE: 'step_deadline',
    CLAUSE: 'step_clause',
    PROCEED: 'step_proceed',
    DOCUMENT_UPLOAD: 'step_document_upload',
    OTHER_WIZARD: 'step_other_wizard',
    STEP_FINANCE: 'step_finance',
    STEP_TECHNOLOGY: 'step_technology',
    STEP_HUMAN_RESOURCES: 'step_human_resources',
    STEP_TRAINING: 'step_training'
}

const INPUTS_REGEX = /\*\*name=((.+?)\|.+?)\*\*/g
const SHORTCODE_REGEX = /name=(.*?)?(?:\|.*?\('(.*?)'\))(?:\|.*?\('(.*?)'\))?/g
const CUSTOM_PARAGRAPHS_REGEX = /\*\*custom_paragraph=(.+?)\*\*/g
const DOCUMENT_HISTORY_REGEX = /\*\*document-history=(.+?)\*\*/g

// TODO - Change this when backend changes
const META_FIELD_TYPES = {
    REVIEWERS: 'reviewers',
    APPROVER: 'approvers',
    UPDATE_INTERVAL: 'update_interval',
    CONFIDENTIALITY: 'confidentiality',
    OWNER: 'owner',
    READERS: 'readers'
}
const ISO_27001_HOW_TO_SUCCEED_VIDEO_LINK =
    'https://player.vimeo.com/video/432421913'
const ISO_27001_HOW_WIZARD_WORKS_VIDEO_LINK =
    'https://player.vimeo.com/video/235215203'
const ISO_27001_HOW_WIZARD_WORKS_VIDEO_LINK_2 =
    'https://player.vimeo.com/video/235215203'

const ISO_27001_AWARENESS_VIDEO_LINK =
    'https://player.vimeo.com/video/298544650'

const ISO_27001_AWARENESS_REDIRECT_LINK =
    'https://training.advisera.com/awareness-session/security-awareness-training/'

const ADVISERA_COMMUNITY_LINKS = {
    [ISO_27001]: 'https://community.advisera.com/discussion/iso-27001-iso-22301'
}

const ISO_27001_SIMPLE_STEPS = {
    INTRODUCTION: 'ISO_27001_introduction',
    INFORMATION_SECURITY_AWARENESS: 'ISO_27001_information_security_awareness',
    SPECIFICATION_OF_INFORMATION_SYSTEM_REQUIREMENTS:
        'ISO_27001_specification_of_information_system_requirements',
    SECURITY_CLAUSES_FOR_SUPPLIERS_AND_PARTNERS:
        'ISO_27001_security_clauses_for_suppliers_and_partners',
    CONFIDENTIALITY_STATEMENT: 'ISO_27001_confidentiality_statement'
}

const STEP_NAME_KEYS = {
    ISO_27001_RISK_REGISTER: 'ISO_27001_risk_register',
    ISO_27001_REGISTER_OF_REQUIREMENTS: 'ISO_27001_register_of_requirements',
    ISO_27001_SECURITY_OBJECTIVES: 'ISO_27001_setting_up_security_objectives'
}

const STEP_TYPES = {
    REGULAR: 'type_regular',
    SIMPLE: 'type_simple'
}

const ISO_27001_2022_REVISION_NAME = 'ISO_27001_2022'

const COMPANY_REGULATION_MAINTENANCE_DATES = [
    'certification_date',
    'next_internal_audit_date',
    'next_surveillance_audit_date',
    'next_recertification_date'
]

export {
    REGULATION_ROLES,
    AVAILABLE_REGULATIONS,
    REGULATION_ROUTES,
    ISO_27001,
    COMPANY_SIZES,
    PLACEHOLDER,
    STEP_AWARENESS,
    STANDARD_TRANSLATION_KEYS,
    MODULES,
    INPUT_FIELD_TYPES,
    INPUTS_REGEX,
    SHORTCODE_REGEX,
    META_FIELD_TYPES,
    ISO_27001_HOW_TO_SUCCEED_VIDEO_LINK,
    ISO_27001_HOW_WIZARD_WORKS_VIDEO_LINK,
    ISO_27001_HOW_WIZARD_WORKS_VIDEO_LINK_2,
    ISO_27001_AWARENESS_VIDEO_LINK,
    ISO_27001_AWARENESS_REDIRECT_LINK,
    ISO_27001_ROUTE_PARAM,
    REGULATION_PARAMS_BY_NAME,
    ADVISERA_COMMUNITY_LINKS,
    STEP_TYPES,
    ISO_27001_SIMPLE_STEPS,
    STEP_NAME_KEYS,
    ISO_27001_2022_REVISION_NAME,
    CUSTOM_PARAGRAPHS_REGEX,
    DOCUMENT_HISTORY_REGEX,
    COMPANY_REGULATION_MAINTENANCE_DATES
}
