import { keys, each, findIndex, first } from 'lodash-es'
import { taskService } from '@/services/api/task'
import { regulationService } from '@/services/api/regulation'
import { registerService } from '@/services/api/register'
import { documentService } from '@/services/api/document'
import { mapStepForDisplay } from '@/services/utils/steps'
import { TASKS } from '@/constants'
import { commentService } from '@/services/api/comment'
import { formatComment, FORMAT_OPTIONS } from '@/services/utils/comment'

function getInitialState () {
    return {
        assignedTasks: [],
        createdTasks: [],
        createdTasksOverview: [],
        assignedTasksOverview: [],
        tasksFetched: false,
        discussions: {
            active: [],
            archived: []
        },
        areDiscussionsFetched: false,
        usersSteps: [],
        usersDocuments: {},
        usersRegisters: {},
        registersItemsStatuses: {},
        newTaskAssignees: {}
    }
}

const state = getInitialState()

const actions = {
    /**
     * Fetch all tasks created by specific user
     * @param commit
     * @param rootGetters
     * @param dispatch
     * @param params
     * @param shouldLoad
     * @returns {Promise<void>}
     */
    async getTasksCreatedByMe (
        { commit, rootGetters, dispatch },
        params,
        shouldLoad = true
    ) {
        shouldLoad && commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const myTasks = await taskService.getCreatedTasks({
                companyId: companyId,
                regulationId: regulationId,
                queryParams: params
            })
            commit('SET_CREATED_TASKS', myTasks)
            commit('SET_TASKS_FETCHED', true)
        } catch (err) {
            dispatch('errors/handleError', err, { root: true })
            throw err
        } finally {
            shouldLoad && commit('SET_APP_LOADING', false, { root: true })
        }
    },

    /**
     *
     * @param commit
     * @param rootGetters
     * @param dispatch
     * @param params
     * @param shouldLoad
     * @returns {Promise<void>}
     */
    async getTasksCreatedByMeOverview (
        { commit, rootGetters, dispatch },
        params,
        shouldLoad = true
    ) {
        shouldLoad && commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const myTasks = await taskService.getCreatedTasksOverview({
                companyId: companyId,
                regulationId: regulationId,
                queryParams: params
            })
            commit('SET_CREATED_TASKS_OVERVIEW', myTasks)
            commit('SET_TASKS_FETCHED', true)
        } catch (err) {
            dispatch('errors/handleError', err, { root: true })
            throw err
        } finally {
            shouldLoad && commit('SET_APP_LOADING', false, { root: true })
        }
    },

    /**
     *  Fetch all tasks assigned to specific user
     * @param commit
     * @param rootGetters
     * @param dispatch
     * @param params
     * @param shouldLoad
     * @returns {Promise<void>}
     */
    async getTasksAssignedToMe (
        { commit, rootGetters, dispatch },
        params,
        shouldLoad = true
    ) {
        shouldLoad && commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const myTasks = await taskService.getAssignedTasks({
                companyId: companyId,
                regulationId: regulationId,
                queryParams: params
            })
            commit('SET_ASSIGNED_TASKS', myTasks)
            commit('SET_TASKS_FETCHED', true)
        } catch (err) {
            dispatch('errors/handleError', err, { root: true })
            throw err
        } finally {
            shouldLoad && commit('SET_APP_LOADING', false, { root: true })
        }
    },

    /**
     *  Fetch all tasks assigned to specific user
     * @param commit
     * @param rootGetters
     * @param dispatch
     * @param params
     * @param shouldLoad
     * @returns {Promise<void>}
     */
    async getTasksAssignedToMeOverview (
        { commit, rootGetters, dispatch },
        params,
        shouldLoad = true
    ) {
        shouldLoad && commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const myTasks = await taskService.getAssignedTasksOverview({
                companyId: companyId,
                regulationId: regulationId,
                queryParams: params
            })
            commit('SET_ASSIGNED_TASKS_OVERVIEW', myTasks)
            commit('SET_TASKS_FETCHED', true)
        } catch (err) {
            dispatch('errors/handleError', err, { root: true })
            throw err
        } finally {
            shouldLoad && commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getUsersSteps ({ commit, rootGetters, dispatch }, shouldLoad = true) {
        shouldLoad && commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const steps = await regulationService.getUsersSteps(
                companyId,
                regulationId
            )
            commit('SET_USERS_STEPS', steps)
        } catch (err) {
            dispatch('errors/handleError', err, { root: true })
            throw err
        } finally {
            shouldLoad && commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getUsersDocuments (
        { commit, rootGetters, dispatch },
        shouldLoad = true
    ) {
        shouldLoad && commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const documents = await documentService.getUsersDocuments(
                companyId,
                regulationId
            )
            commit('SET_USERS_DOCUMENTS', documents)
        } catch (err) {
            dispatch('errors/handleError', err, { root: true })
            throw err
        } finally {
            shouldLoad && commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getNewTaskAssignees (
        { commit, rootGetters, dispatch },
        shouldLoad = true
    ) {
        shouldLoad && commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id

            const assignees = await taskService.getNewTaskAssignees(companyId)

            commit('SET_NEW_TASK_ASSIGNEES', assignees)
        } catch (err) {
            dispatch('errors/handleError', err, { root: true })
            throw err
        } finally {
            shouldLoad && commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getDiscussions (
        { commit, rootGetters, dispatch },
        shouldLoad = true
    ) {
        shouldLoad && commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const discussions = await commentService.getMyDiscussions(
                companyId,
                regulationId
            )

            const users = rootGetters['company/usersById']

            commit('SET_DISCUSSIONS', { discussions, users })
            commit('SET_DISCUSSIONS_FETCHED', true)
        } catch (err) {
            dispatch('errors/handleError', err, { root: true })
            throw err
        } finally {
            shouldLoad && commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async addTask ({ commit, rootGetters }, task) {
        const assignee = first(task.assignees)
        assignee.id === rootGetters['account/companyMember'].id &&
            commit(
                'ADD_TASK_TO_ASSIGNED_TASKS',
                mapTaskWithCategoryAndSource(task)
            )

        commit('ADD_TASK_TO_CREATED_TASKS', mapTaskWithCategoryAndSource(task))
    },

    async getMyRegisters ({ commit, rootGetters, dispatch }, shouldLoad) {
        shouldLoad && commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const data = await registerService.getMyRegisters(
                companyId,
                regulationId
            )

            commit('SET_MY_REGISTERS', data)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            shouldLoad && commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getRegistersItemsStatuses (
        { commit, rootGetters, dispatch },
        shouldLoad
    ) {
        shouldLoad && commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const data = await registerService.getMyRegistersPerStatus(
                companyId,
                regulationId
            )

            commit('SET_REGISTERS_ITEMS_STATUSES', data)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            shouldLoad && commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async archiveDiscussion ({ commit, rootGetters, dispatch }, id) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await commentService.updateComment(companyId, regulationId, id, {
                is_resolved: true
            })

            commit('ADD_DISCUSSION_FROM_ACTIVE_TO_ARCHIVE', id)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}

const mutations = {
    ADD_TASK_TO_ASSIGNED_TASKS (state, task) {
        state.assignedTasks = [...state.assignedTasks, { ...task }]
    },

    ADD_TASK_TO_CREATED_TASKS (state, task) {
        state.createdTasks = [...state.createdTasks, { ...task }]
    },

    MARK_TASK_AS_DONE (state, task) {
        const index = findIndex(state.assignedTasks, { id: task.id })
        state.assignedTasks.splice(index, 1, {
            ...state.assignedTasks[index],
            status: TASKS.STATUSES.COMPLETED
        })
    },

    SET_USERS_DOCUMENTS (state, documents) {
        state.usersDocuments = documents
    },

    SET_USERS_STEPS (state, steps) {
        state.usersSteps = steps.map(mapStepForDisplay).map(step => {
            const companyRegulationStep = first(step.company_regulation_step)
            const { tasks_info: taskInfo } = companyRegulationStep

            return {
                ...step,
                numberOfTasks: taskInfo.created,
                numberOfLateTasks: taskInfo.late
            }
        })
    },

    SET_ASSIGNED_TASKS (state, tasks) {
        state.assignedTasks = tasks.map(mapTaskWithCategoryAndSource)
    },

    SET_CREATED_TASKS (state, tasks) {
        state.createdTasks = tasks.map(mapTaskWithCategoryAndSource)
    },

    SET_NEW_TASK_ASSIGNEES (state, assignees) {
        state.newTaskAssignees = assignees
    },

    SET_CREATED_TASKS_OVERVIEW (state, tasks) {
        state.createdTasksOverview = tasks
    },

    SET_ASSIGNED_TASKS_OVERVIEW (state, tasks) {
        state.assignedTasksOverview = tasks
    },

    SET_TASKS_FETCHED (state, tasksFetched) {
        state.tasksFetched = tasksFetched
    },

    SET_DISCUSSIONS (state, { discussions, users }) {
        const active = discussions.active.map(discussion => {
            const parsedReplies = discussion.replies.map(reply => ({
                ...reply,
                content: formatComment(
                    reply.content,
                    users,
                    FORMAT_OPTIONS.FOR_VIEW_OF_COMMENT
                )
            }))

            return {
                ...discussion,
                content: formatComment(
                    discussion.content,
                    users,
                    FORMAT_OPTIONS.FOR_VIEW_OF_COMMENT
                ),
                replies: parsedReplies
            }
        })

        const archived = discussions.archived.map(discussion => {
            const parsedReplies = discussion.replies.map(reply => ({
                ...reply,
                content: formatComment(
                    reply.content,
                    users,
                    FORMAT_OPTIONS.FOR_VIEW_OF_COMMENT
                )
            }))

            return {
                ...discussion,
                content: formatComment(
                    discussion.content,
                    users,
                    FORMAT_OPTIONS.FOR_VIEW_OF_COMMENT
                ),
                replies: parsedReplies
            }
        })

        state.discussions = {
            active,
            archived
        }
    },

    SET_DISCUSSIONS_FETCHED (state, isFetched) {
        state.areDiscussionsFetched = isFetched
    },

    RESET_MY_WORK (state) {
        const s = getInitialState()
        each(keys(state), key => {
            state[key] = s[key]
        })
    },

    SET_MY_REGISTERS (state, registers) {
        state.usersRegisters = registers
    },

    SET_REGISTERS_ITEMS_STATUSES (state, registers) {
        state.registersItemsStatuses = registers
    },

    ADD_DISCUSSION_FROM_ACTIVE_TO_ARCHIVE (state, activeDiscussionId) {
        const discussionIndex = state.discussions.active.findIndex(
            val => val.id === activeDiscussionId
        )

        let removedDiscussions = state.discussions.active.splice(
            discussionIndex,
            1
        )

        const discussion = removedDiscussions[0]

        state.discussions.archived.push({ ...discussion, is_resolved: true })
    }
}

const getters = {
    assignedTasks: state => state.assignedTasks,
    createdTasks: state => state.createdTasks,
    createdTasksOverview: state => state.createdTasksOverview,
    assignedTasksOverview: state => state.assignedTasksOverview,
    tasksFetched: state => state.tasksFetched,
    discussions: state => state.discussions,
    areDiscussionsFetched: state => state.areDiscussionsFetched,
    usersSteps: state => state.usersSteps,
    usersDocuments: state => state.usersDocuments,
    usersRegisters: state => state.usersRegisters,
    registersItemsStatuses: state => state.registersItemsStatuses,
    newTaskAssignees: state => state.newTaskAssignees
}

function mapTaskWithCategoryAndSource (task) {
    return {
        ...task,
        source: TASKS.TYPE_SOURCES[task.softType],
        category: TASKS.TYPE_CATEGORIES[task.softType]
    }
}

export const myWork = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
