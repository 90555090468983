import { render, staticRenderFns } from "./AppPopper.vue?vue&type=template&id=a63f60e8&scoped=true"
import script from "./AppPopper.vue?vue&type=script&lang=js"
export * from "./AppPopper.vue?vue&type=script&lang=js"
import style0 from "./AppPopper.vue?vue&type=style&index=0&id=a63f60e8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a63f60e8",
  null
  
)

export default component.exports