import { correctiveActionService } from '@/services/api/corrective-action'
import { findIndex } from 'lodash-es'

function getInitialState () {
    return {
        correctiveActions: []
    }
}

const state = getInitialState()

const actions = {
    async getCorrectiveActions ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const correctiveActions = await correctiveActionService.getCorrectiveActions(
                companyId,
                regulationId
            )
            commit('SET_CORRECTIVE_ACTIONS', correctiveActions)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async addCorrectiveAction ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const correctiveAction = await correctiveActionService.addCorrectiveAction(
                companyId,
                regulationId,
                { ...data }
            )
            commit('ADD_CORRECTIVE_ACTION', correctiveAction)

            return correctiveAction
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateCorrectiveAction ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const correctiveAction = await correctiveActionService.updateCorrectiveAction(
                companyId,
                regulationId,
                data.id,
                { ...data }
            )
            commit('UPDATE_CORRECTIVE_ACTION', correctiveAction)
            dispatch('getCorrectiveActions')

            return correctiveAction
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async createCorrectionable ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            return await correctiveActionService.createCorrectionable(
                companyId,
                regulationId,
                { correctionables: data }
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async detachCorrectionable (
        { commit, rootGetters, dispatch },
        correctionablePivotId
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await correctiveActionService.detachCorrectionable(
                companyId,
                regulationId,
                correctionablePivotId
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async deleteCorrectiveAction (
        { commit, rootGetters, dispatch },
        correctiveActionId
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await correctiveActionService.deleteCorrectiveAction(
                companyId,
                regulationId,
                correctiveActionId
            )

            commit('DELETE_CORRECTIVE_ACTION', correctiveActionId)
            dispatch('getCorrectiveActions')
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}
const mutations = {
    SET_CORRECTIVE_ACTIONS (state, correctiveActions) {
        state.correctiveActions = correctiveActions
    },

    ADD_CORRECTIVE_ACTION (state, correctiveAction) {
        state.correctiveActions.push({ ...correctiveAction })
    },

    UPDATE_CORRECTIVE_ACTION (state, correctiveAction) {
        const index = findIndex(state.correctiveActions, {
            id: correctiveAction.id
        })

        state.correctiveActions.splice(index, 1, { ...correctiveAction })
    },

    DELETE_CORRECTIVE_ACTION (state, id) {
        const index = findIndex(state.correctiveActions, { id })

        state.correctiveActions.splice(index, 1)
    }
}

const getters = {
    correctiveActions: state => state.correctiveActions
}

export const correctiveAction = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
