import axios from 'axios'

const ENDPOINTS = {
    SETUP_DATA: '/mfa/setup-data',
    VERIFY_SETUP: '/mfa/verify-setup',
    PROCESS_SETUP: '/mfa/process-setup',
    VERIFY_CODE: '/mfa/verify-code'
}

class MfaService {
    /**
     * Sets Authorization header in order to make authenticated requests
     */
    getAuthConfig (mfaToken) {
        return {
            headers: {
                Authorization: `Bearer ${mfaToken}`
            }
        }
    }

    /**
     * Get MFA setup data
     * @param {String} mfaToken
     *
     * @returns {Promise}
     */
    async getSetupData (mfaToken) {
        try {
            const { data } = await axios.get(
                ENDPOINTS.SETUP_DATA,
                this.getAuthConfig(mfaToken)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Verify MFA setup data
     * @param {Object} body
     *
     * @returns {Promise}
     */
    async verifySetup (body, mfaToken) {
        try {
            const { data } = await axios.post(
                ENDPOINTS.VERIFY_SETUP,
                body,
                this.getAuthConfig(mfaToken)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Process MFA setup data
     * @param {Object} body
     *
     * @returns {Promise}
     */
    async processSetup (body, mfaToken) {
        try {
            const { data } = await axios.post(
                ENDPOINTS.PROCESS_SETUP,
                body,
                this.getAuthConfig(mfaToken)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Verify MFA code
     * @param {Object} code
     *
     * @returns {Promise}
     */
    async verifyCode (code, mfaToken) {
        try {
            const { data } = await axios.post(
                ENDPOINTS.VERIFY_CODE,
                code,
                this.getAuthConfig(mfaToken)
            )

            return data
        } catch (error) {
            throw error
        }
    }
}

export default new MfaService()
