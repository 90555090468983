import {
    REGISTER_WIZARD_ROUTES,
    REGISTER_TYPES,
    MY_PERMISSIONS,
    REGISTERS_PERMISSIONS
} from '@/constants'
import { myPermissionsGuard } from '@src/router/guards'

export default {
    name: REGISTER_WIZARD_ROUTES.SOA.INDEX.name,
    component: () =>
        import('@/containers/RegisterWizard/StatementOfApplicability'),
    path: 'soa',
    props: true,
    redirect: to => ({
        name: REGISTER_WIZARD_ROUTES.SOA.WIZARD.name,
        params: { ...to.params }
    }),
    meta: {
        requiresAuth: true,
        register: REGISTER_TYPES.SOA
    },
    children: [
        {
            path: 'wizard',
            name: REGISTER_WIZARD_ROUTES.SOA.WIZARD.name,
            component: () => import('@/components/registers/soa/WizardTab'),
            props: true,
            meta: {
                pageTitle: 'REGISTERS.SOA.TITLE',
                title: 'REGISTERS.SOA.WIZARD_TITLE',
                withScaling: true,
                requiresAuth: true,
                register: REGISTER_TYPES.SOA,
                shouldSkipRouteInHistory: true,
                guards: [myPermissionsGuard],
                permissions: [
                    MY_PERMISSIONS.SOA_ACCESS,
                    REGISTERS_PERMISSIONS.STATEMENT_OF_APPLICABILITY
                ]
            }
        },
        {
            path: 'properties',
            name: REGISTER_WIZARD_ROUTES.SOA.PROPERTIES.name,
            component: () => import('@/components/registers/PropertiesTab'),
            props: true,
            meta: {
                pageTitle: 'REGISTERS.SOA.TITLE',
                withScaling: true,
                requiresAuth: true,
                register: REGISTER_TYPES.SOA,
                shouldSkipRouteInHistory: true,
                guards: [myPermissionsGuard],
                permissions: [
                    MY_PERMISSIONS.SOA_ACCESS,
                    REGISTERS_PERMISSIONS.STATEMENT_OF_APPLICABILITY
                ]
            }
        },
        {
            path: 'discussions',
            name: REGISTER_WIZARD_ROUTES.SOA.DISCUSSIONS.name,
            component: () => import('@/components/registers/Discussions'),
            props: true,
            meta: {
                pageTitle: 'REGISTERS.SOA.TITLE',
                withScaling: true,
                requiresAuth: true,
                register: REGISTER_TYPES.SOA,
                shouldSkipRouteInHistory: true,
                guards: [myPermissionsGuard],
                permissions: [
                    MY_PERMISSIONS.SOA_ACCESS,
                    REGISTERS_PERMISSIONS.STATEMENT_OF_APPLICABILITY
                ]
            }
        }
    ]
}
