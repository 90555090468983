<template>
    <div class="cf-c-adaptation-section">
        <div class="cf-c-adaptation-section__header">
            <strong class="cf-c-adaptation-section__header-title">
                {{ title }}
            </strong>
            <div class="cf-c-adaptation-section__action">
                <app-button
                    variation="clear"
                    :disabled="disabled"
                    @click="$emit('delete')"
                >
                    <app-icon icon="delete-icon" />
                </app-button>
            </div>
        </div>
        <div class="cf-c-adaptation-section__ctn">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>
