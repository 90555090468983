const REGISTER_TYPE = 'requirements'

const ROUTES_REGISTER_OF_REQUIREMENTS = {
    BASE: {
        name: 'requirements'
    }
}

const NEED_HELP_URL =
    'https://help.advisera.com/hc/en-us/articles/10139086339100-Register-of-Requirements'

const CONTRACTUAL_NOT_MANDATORY_FIELDS = ['date_of_the_document', 'deadline']
const LEGAL_NOT_MANDATORY_FIELDS = ['deadline', 'state_id', 'link', 'lawId']

const STATUSES = {
    COMPLIANT: 'compliant',
    NON_COMPLIANT: 'non_compliant'
}

const STATUSES_MAPPED = {
    [STATUSES.COMPLIANT]: {
        colorClass: 'color-success-alpha',
        label: 'REQUIREMENTS.STATUSES.COMPLIANT'
    },
    [STATUSES.NON_COMPLIANT]: {
        colorClass: 'color-danger-alpha',
        label: 'REQUIREMENTS.STATUSES.NON_COMPLIANT'
    }
}

const TYPES = {
    CONTRACTUAL_REQUIREMENT: 'contractual',
    LEGAL_REGULATORY_REQUIREMENTS: 'legal-regulatory',
    OTHER_REQUIREMENTS: 'other-requirements'
}

const TYPES_MAPPED = {
    [TYPES.CONTRACTUAL_REQUIREMENT]:
        'REQUIREMENTS.TYPES.CONTRACTUAL_REQUIREMENT',
    [TYPES.LEGAL_REGULATORY_REQUIREMENTS]:
        'REQUIREMENTS.TYPES.LEGAL_REGULATORY_REQUIREMENT',
    [TYPES.OTHER_REQUIREMENTS]: 'REQUIREMENTS.TYPES.OTHER_REQUIREMENT'
}

export {
    REGISTER_TYPE,
    ROUTES_REGISTER_OF_REQUIREMENTS,
    NEED_HELP_URL,
    STATUSES,
    STATUSES_MAPPED,
    TYPES,
    TYPES_MAPPED,
    CONTRACTUAL_NOT_MANDATORY_FIELDS,
    LEGAL_NOT_MANDATORY_FIELDS
}
