import axios from 'axios'

const ENDPOINTS = {
    RESOURCES: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/resources`,

    RESOURCES_BULK_APPROVAL: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/resources/bulk-approval`,

    RESOURCE_DELETE: (companyId, regulationId, recourceId) =>
        `companies/${companyId}/regulations/${regulationId}/resources/${recourceId}`
}

export default class ResourcesService {
    /**
     * Save a resource
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Object} resource
     *
     * @returns Promise
     */
    async save (companyId, regulationId, resource) {
        try {
            const route = ENDPOINTS.RESOURCES(companyId, regulationId)
            const { data } = await axios.post(route, resource)

            return data
        } catch (error) {
            throw error
        }
    }

    async bulkApproval (companyId, regulationId, approvalData) {
        try {
            const route = ENDPOINTS.RESOURCES_BULK_APPROVAL(
                companyId,
                regulationId
            )
            const { data } = await axios.post(route, approvalData)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Delete RTP Recource
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} resourceId
     *
     * @returns Promise
     */
    async deleteRtpResource (companyId, regulationId, resourceId) {
        try {
            const route = ENDPOINTS.RESOURCE_DELETE(
                companyId,
                regulationId,
                resourceId
            )
            const { data } = await axios.delete(route)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const resourcesService = new ResourcesService()
