import { mapGetters, mapActions } from 'vuex'
import { get, isEmpty, first } from 'lodash-es'
import { TASKS } from '@/constants'

export default {
    created () {
        this.completePeriodicStepTask()
    },

    computed: {
        ...mapGetters('register', ['currentCompanyRegulationRegister']),
        ...mapGetters('company', ['company']),

        periodicStepCondition () {
            return get(
                this.currentCompanyRegulationRegister,
                'conditions',
                []
            ).find(condition =>
                TASKS.PERIODIC_STEPS_CONDITIONS.includes(
                    condition.action_type.name
                )
            )
        },

        accessToken () {
            return get(
                first(
                    get(
                        this.periodicStepCondition,
                        'task.assignees_with_access_token',
                        []
                    )
                ),
                'pivot.token',
                ''
            )
        }
    },

    methods: {
        ...mapActions('task', ['submitAction']),

        completePeriodicStepTask () {
            if (
                !isEmpty(this.currentCompanyRegulationRegister) &&
                !!this.periodicStepCondition
            ) {
                this.submitAction({
                    companyId: this.company.id,
                    actionToken: this.periodicStepCondition.action_token,
                    accessToken: this.accessToken
                })
            }
        }
    },

    watch: {
        currentCompanyRegulationRegister () {
            this.completePeriodicStepTask()
        }
    }
}
