<template>
    <div
        :class="['cf-c-icon-wrap cf-c-icon', className, wrapperClasses]"
        @click="$emit('click', $event)"
    >
        <img
            v-if="isPng"
            :class="['cf-c-icon', className]"
            :src="require(`@/assets/icons/temporary-icon-folder/${icon}`)"
        />
        <component v-else :is="iconName" :class="[className]" />
    </div>
</template>

<script>
import { startCase } from 'lodash-es'

export default {
    props: {
        icon: {
            type: String,
            required: true
        },

        size: {
            type: String,
            required: false,
            default: ''
        },

        isInlineWrapper: {
            type: Boolean,
            required: false,
            default: false
        },

        cursorPointer: {
            type: Boolean,
            required: false,
            default: false
        },

        disabled: {
            required: false,
            default: false
        }
    },

    computed: {
        className () {
            return this.size ? `cf-c-icon--${this.size}` : 'cf-c-icon'
        },

        iconName () {
            return startCase(this.icon)
                .split(' ')
                .join('')
        },

        isPng () {
            return this.icon.includes('.png')
        },

        wrapperClasses () {
            let classes = `cf-c-${this.icon}`

            if (this.isInlineWrapper) {
                classes += ' cf-c-icon-wrap-inline'
            }

            if (this.cursorPointer && !this.disabled) {
                classes += ' cf-c-icon-cursor-pointer'
            }

            if (this.disabled) {
                classes += ' cf-c-icon-disabled'
            }

            return classes
        }
    }
}
</script>
