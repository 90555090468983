import { expertaService } from '@/services/api/experta'
import { EXPERTA_MESSAGE_TYPE, EXPERTA_MESSAGE_SENDER } from '@/constants'

function getInitialState () {
    return {
        messages: [],
        isThinking: false
    }
}

const state = getInitialState()

const actions = {
    clearHistory ({ commit }) {
        commit('DELETE_ALL_MESSAGES')
    },

    addUserMessage ({ commit }, content) {
        commit('ADD_MESSAGE', {
            content,
            sender: EXPERTA_MESSAGE_SENDER.USER
        })
    },

    addBotMessage ({ commit }, content) {
        commit('ADD_MESSAGE', {
            content,
            sender: EXPERTA_MESSAGE_SENDER.BOT
        })
    },

    async askQuestion ({ commit, dispatch }, { question, email }) {
        try {
            commit('START_THINKING')

            const { data } = await expertaService.askQuestion(question, email)

            commit('STOP_THINKING')

            return data ?? null
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        }
    },

    async getRelatedQuestions ({ commit, dispatch }, context) {
        try {
            commit('START_THINKING')

            const { data } = await expertaService.getRelatedQuestions(context)

            if (data && data.length) {
                data.forEach(question => {
                    commit('ADD_MESSAGE', {
                        content: question,
                        sender: EXPERTA_MESSAGE_SENDER.BOT,
                        type: EXPERTA_MESSAGE_TYPE.QUESTION
                    })
                })
            }

            commit('STOP_THINKING')
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        }
    }
}

const mutations = {
    ADD_MESSAGE (state, { content, sender, type = EXPERTA_MESSAGE_TYPE.TEXT }) {
        state.messages.push({
            content,
            sender,
            type
        })
    },

    DELETE_ALL_MESSAGES (state, message) {
        state.messages = []
    },

    START_THINKING (state) {
        state.isThinking = true
    },

    STOP_THINKING (state) {
        state.isThinking = false
    }
}

const getters = {
    messages: state => state.messages,
    isThinking: state => state.isThinking
}

export const experta = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
