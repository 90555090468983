import { MY_PERMISSIONS } from '@src/constants'
import { myPermissionsGuard } from '@src/router/guards'
import { ROUTES_TRAINING } from './constants'

export default {
    name: ROUTES_TRAINING.BASE.name,
    path: 'standard/:standard/training-programs',
    component: () => import('./ModuleTraining'),
    props: true,
    meta: {
        pageTitle: 'TRAINING_PROGRAM.PAGE_TITLE',
        requiresAuth: true,
        guards: [myPermissionsGuard],
        permissions: [MY_PERMISSIONS.TRAINING_ACCESS]
    }
}
