import { TASKS } from '@/constants'
import { formatDate } from '@/services/utils/date'
import { getFullNameOrEmail } from '@/services/utils/functions'
import { getAssignee, isTaskLate } from '@/services/utils/tasks'

const { STATUS_COLORS, STATUS_GUIDES, SOURCES, CATEGORIES } = TASKS

export default {
    computed: {
        source () {
            return this.task.source ? SOURCES[this.task.source] : ''
        },

        sourceLabel () {
            return this.source
                ? this.$t(this.source.LABEL)
                : this.$t('COMMON.NOT_AVAILABLE')
        },

        category () {
            return this.task.category ? CATEGORIES[this.task.category] : ''
        },

        categoryLabel () {
            return this.category
                ? this.$t(this.category.LABEL)
                : this.$t('COMMON.NOT_AVAILABLE')
        },

        recurrenceLabel () {
            return this.isRecurring
                ? `${this.$t('COMMON.EVERY')} ${this.recurrence}`
                : this.$t('COMMON.NOT_AVAILABLE')
        },

        status () {
            return this.task.status
        },

        isLate () {
            return isTaskLate(this.task)
        },

        statusColor () {
            return STATUS_COLORS[this.status]
        },

        statusLabel () {
            const status = STATUS_GUIDES.find(s => s.value === this.status)
            return status.label
        },

        isRecurring () {
            return this.task.recurrence_interval && this.task.recurrence_unit
        },

        recurrence () {
            return this.$tc(
                `COMMON.${this.task.recurrence_unit.toUpperCase()}`,
                this.task.recurrence_interval,
                { count: this.task.recurrence_interval }
            )
        },

        assignee () {
            return getAssignee(this.task)
        },

        assigneeLabel () {
            return this.assignee
                ? getFullNameOrEmail(
                      this.assignee.user ? this.assignee.user : this.assignee
                  )
                : ''
        }
    },

    methods: {
        formatDate
    }
}
