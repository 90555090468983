function CharCounter () {
    // General settings
    let _settings

    /**
     * Directive initialization
     */
    let init = (vnode, settings = {}) => {
        setSettings(settings)

        let el = vnode.elm

        if (getSettings('charLimit')) {
            createCounter(el, vnode)

            return
        }

        if (getSettings('emitInput')) {
            el.addEventListener('input', e => emitInput(vnode, e.target.value))
        }
    }

    /**
     * Just emits input event
     */
    let emitInput = (vnode, value) => {
        vnode.context.$emit('input', value)
    }

    /**
     * Creates and inits counter
     */
    let createCounter = (el, vnode) => {
        let wrapper = createCounterWrapper(el)

        el.addEventListener('input', e => inputAction(e, vnode, wrapper))
    }

    /**
     * Checks input limits and shortens content if is needed
     */
    let inputAction = (e, vnode, wrapper) => {
        let charLimit = getSettings('charLimit')

        let value = e.target.value

        if (value) {
            let charLength = value.length

            if (charLength >= charLimit) {
                value = value.substring(0, charLimit)

                charLength = value.length

                e.target.value = value
            }
        }

        setCounterContent(value, wrapper)

        if (getSettings('emitInput')) {
            emitInput(vnode, value)
        }
    }

    /**
     * Creates and styles Counter wrapper
     */
    let createCounterWrapper = el => {
        let wrapper = document.createElement('div')

        wrapper.className = 'char-counter'

        el.after(wrapper)

        setCounterContent(el.value, wrapper)

        return wrapper
    }

    /**
     * Adds or removes warning class (when is char limit reached)
     */
    let toggleWarningClass = (warning = false, wrapper) => {
        let className = 'warning'

        warning
            ? wrapper.classList.add(className)
            : wrapper.classList.remove(className)
    }

    /**
     * Sets and updates Counter numbers (character length and limit)
     */
    let setCounterContent = (value, wrapper) => {
        let charLimit = getSettings('charLimit')

        let charLength = value ? value.length : 0

        let html = charLength + '/' + charLimit

        wrapper.innerHTML = html

        charLength >= charLimit
            ? toggleWarningClass(true, wrapper)
            : toggleWarningClass(false, wrapper)
    }

    /**
     * Sets general settings
     */
    let setSettings = settings => {
        _settings = {
            charLimit: settings.charLimit ?? 0,
            emitInput: settings.emitInput ?? false
        }
    }

    /**
     * Returns settings option value
     */
    let getSettings = option => {
        return _settings[option] ?? null
    }

    /**
     * Public methods
     */
    return {
        init: init
    }
}

export default {
    inserted: (el, binding, vnode) => {
        new CharCounter().init(vnode, binding.value)
    }
}
