<template>
    <router-link class="cf-c-tabs__item" active-class="cf-is-active" :to="to">
        {{ label }}
    </router-link>
</template>

<script>
export default {
    props: {
        to: {
            type: Object,
            required: true
        },

        label: {
            type: String,
            required: true
        }
    }
}
</script>
