const STEP_STATUSES = {
    COMPLETED: 'completed',
    DOCUMENT_FULLY_FILLED_OUT: 'document_fully_filled_out',
    DOCUMENT_REVIEWED_AND_APPROVED: 'document_reviewed_and_approved',
    NOT_STARTED: 'not_started',
    DOCUMENT_PARTIALLY_FILLED_OUT: 'document_partially_filled_out'
}

const STEP_STATUS_TEXT_COLOR_CLASSES = {
    [STEP_STATUSES.COMPLETED]: 'cf-u-color-primary',
    [STEP_STATUSES.DOCUMENT_FULLY_FILLED_OUT]: 'cf-u-color-primary-light',
    [STEP_STATUSES.DOCUMENT_REVIEWED_AND_APPROVED]: 'cf-u-color-sky-blue',
    [STEP_STATUSES.NOT_STARTED]: 'cf-u-color-gray-02',
    [STEP_STATUSES.DOCUMENT_PARTIALLY_FILLED_OUT]: 'cf-u-color-gray-blue'
}

const STEP_STATUS_TEXT = {
    [STEP_STATUSES.COMPLETED]:
        'REPORTING.PROJECT_STATUS.STEP_STATUSES.COMPLETED',
    [STEP_STATUSES.DOCUMENT_FULLY_FILLED_OUT]:
        'REPORTING.PROJECT_STATUS.STEP_STATUSES.DOCUMENT_FULLY_FILLED_OUT',
    [STEP_STATUSES.DOCUMENT_REVIEWED_AND_APPROVED]:
        'REPORTING.PROJECT_STATUS.STEP_STATUSES.DOCUMENT_REVIEWED_AND_APPROVED',
    [STEP_STATUSES.NOT_STARTED]:
        'REPORTING.PROJECT_STATUS.STEP_STATUSES.NOT_STARTED',
    [STEP_STATUSES.DOCUMENT_PARTIALLY_FILLED_OUT]:
        'REPORTING.PROJECT_STATUS.STEP_STATUSES.DOCUMENT_PARTIALLY_FILLED_OUT'
}

const PERFORMANCE_ACTIVITIES_INTERVAL_SELECT_LABELS = {
    LAST_30_DAYS: 'REPORTING.PERFORMANCE.INTERVALS.LAST_30_DAYS',
    LAST_3_MONTHS: 'REPORTING.PERFORMANCE.INTERVALS.LAST_3_MONTHS',
    THIS_YEAR: 'REPORTING.PERFORMANCE.INTERVALS.THIS_YEAR',
    LAST_YEAR: 'REPORTING.PERFORMANCE.INTERVALS.LAST_YEAR'
}

const PERFORMANCE_ACTIVITIES_INTERVAL_SELECT_VALUES = {
    LAST_30_DAYS: 'last_thirty_days',
    LAST_3_MONTHS: 'last_three_months',
    THIS_YEAR: 'this_year',
    LAST_YEAR: 'last_year'
}

const PERFORMANCE_ACTIVITIES_DEFAULT_VALUE =
    PERFORMANCE_ACTIVITIES_INTERVAL_SELECT_VALUES.LAST_30_DAYS

const PERFORMANCE_ACTIVITIES_INTERVAL_SELECT_OPTIONS = Object.keys(
    PERFORMANCE_ACTIVITIES_INTERVAL_SELECT_LABELS
).map(key => ({
    label: PERFORMANCE_ACTIVITIES_INTERVAL_SELECT_LABELS[key],
    value: PERFORMANCE_ACTIVITIES_INTERVAL_SELECT_VALUES[key]
}))

const SECURITY_OBJECTIVE_TYPES = {
    FINISH_ISO_27001: 'finish_iso_27001',
    SECURITY_OBJECTIVE_PROJECT_PLAN: 'security_objective_project_plan'
}

const DONUT_COLORS = {
    TASKS: '#f82f47',
    STEPS: '#4788c6',
    DEADLINE: '#f5a623'
}

const COMPLIANCE_POSSIBLE_DOCUMENT_TYPES = {
    REPORT: 'report',
    DOCUMENT: 'document'
}

const COMPLIANCE_DOCUMENT_TYPES = [
    COMPLIANCE_POSSIBLE_DOCUMENT_TYPES.REPORT,
    COMPLIANCE_POSSIBLE_DOCUMENT_TYPES.DOCUMENT
]

const READINESS_FOR_CERTIFICATION_TIPS_LINK =
    'https://advisera.com/27001academy/knowledgebase/how-to-choose-a-certification-body/'

export const REPORTING = {
    STEP_STATUSES,
    STEP_STATUS_TEXT_COLOR_CLASSES,
    STEP_STATUS_TEXT,
    PERFORMANCE_ACTIVITIES_DEFAULT_VALUE,
    PERFORMANCE_ACTIVITIES_INTERVAL_SELECT_OPTIONS,
    SECURITY_OBJECTIVE_TYPES,
    DONUT_COLORS,
    COMPLIANCE_POSSIBLE_DOCUMENT_TYPES,
    COMPLIANCE_DOCUMENT_TYPES,
    READINESS_FOR_CERTIFICATION_TIPS_LINK
}
