<template>
    <div class="cf-c-multiple-checklist__item">
        <div class="cf-c-multiple-checklist__add-new-wrapper">
            <app-button
                variation="clear"
                class="cf-u-pr-default cf-u-font-size-sm"
                @click="$emit('add')"
            >
                <div class="cf-c-multiple-checklist__add-new-icon-wrapper">
                    <app-icon icon="plus-icon" />
                </div>
                {{ $t(label) }}
            </app-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        }
    }
}
</script>
