import { formatDate } from '@/services/utils/date'
import {
    getFullNameOrEmail,
    openRouteInNewTab
} from '@/services/utils/functions'
import { MANAGEMENT_REVIEW, DMS_ROUTES } from '@/constants'

const { DATA_SOURCES } = MANAGEMENT_REVIEW

export default {
    methods: {
        formatDate,

        getFullNameOrEmail,

        getUsersNames (users) {
            return users.map(user => ({ ...user.user })).map(getFullNameOrEmail)
        },

        getRouteForRegister (source) {
            return this.$router.resolve(source.route)
        },

        getRouteForReport (documentId) {
            return documentId
                ? this.$router.resolve({
                      name: DMS_ROUTES.FILE_PREVIEW.name,
                      params: { documentId }
                  })
                : null
        },

        navigateToSource () {
            const source = DATA_SOURCES[this.activity.data_source.type]
            const route =
                source.register || source.directLink
                    ? this.getRouteForRegister(source)
                    : this.getRouteForReport(this.activity.data_source.data)

            route && openRouteInNewTab(route)
        }
    },

    computed: {
        hasSourceLink () {
            const source = DATA_SOURCES[this.activity.data_source.type]
            if (source.register || source.directLink) {
                return true
            }

            return !!this.getRouteForReport(this.activity.data_source.data)
        },

        frequencyLabel () {
            return this.$t(
                `REPORTING.MANAGEMENT_REVIEW.FREQUENCIES.${this.activity.activity_frequency.toUpperCase()}`
            )
        },

        sourceLabel () {
            return this.$t(DATA_SOURCES[this.activity.data_source.type].label)
        }
    }
}
