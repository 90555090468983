import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('register', ['currentCompanyRegulationRegister']),
        ...mapGetters('internalAudit', ['isIntenalAuditInProgress']),

        isRTPLocked () {
            return this.currentCompanyRegulationRegister.is_rtp_locked ?? false
        },

        isInternalAuditInProgress () {
            return this.isIntenalAuditInProgress
        }
    }
}
