import { isUndefined } from 'lodash-es'
import { taskService } from '@/services/api/task'
import { REGISTER_TYPES, TASKS } from '@/constants'

const state = {
    tasks: [],
    singleTask: null
}

const actions = {
    async submitAction ({ commit, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        let task = null
        try {
            task = await taskService.submitAction(data)
            commit('UPDATE_TASK', task)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }

        return task
    },

    async submitTasks (
        { commit, dispatch, rootGetters },
        { regulationId, companyId, data }
    ) {
        if (isUndefined(regulationId)) {
            const currentRegulation =
                rootGetters['regulation/currentRegulation']
            regulationId = currentRegulation ? currentRegulation.id : null
        }

        if (isUndefined(companyId)) {
            const company = rootGetters['company/company']
            companyId = company ? company.id : null
        }

        commit('SET_APP_LOADING', true, { root: true })
        try {
            return await taskService.tasksCreateAction({
                companyId: companyId,
                regulationId: regulationId,
                formData: data
            })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async deleteTask ({ commit, rootGetters, dispatch }, { taskId, id, type }) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await taskService.deleteTask({
                taskId,
                companyId,
                regulationId
            })

            const module =
                type === REGISTER_TYPES.INCIDENTS ? 'incident' : 'nonconformity'

            commit(`${module}/DELETE_TASK`, { id, taskId }, { root: true })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getTasks ({ commit, rootGetters, dispatch }, data = {}) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            let { companyId, regulationId, queryParams } = data
            companyId = companyId || rootGetters['company/company'].id
            regulationId =
                regulationId || rootGetters['regulation/currentRegulation'].id

            const tasks = await taskService.getTasks({
                companyId,
                regulationId,
                queryParams
            })
            commit('SET_TASKS', tasks)
        } catch (err) {
            dispatch('errors/handleError', err, { root: true })
            throw err
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateTask ({ commit, rootGetters }, { taskId, id, type, data }) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await taskService.updateTask({
                taskId,
                companyId,
                regulationId,
                data
            })

            const module =
                type === REGISTER_TYPES.INCIDENTS ? 'incident' : 'nonconformity'
            commit(
                `${module}/UPDATE_TASK`,
                { taskId, id, data },
                { root: true }
            )
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getTask ({ commit, rootGetters, dispatch }, taskId) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const task = await taskService.getTask({
                companyId,
                regulationId,
                taskId
            })

            commit('SET_SINGLE_TASK', task)
        } catch (err) {
            dispatch('errors/handleError', err, { root: true })
            throw err
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async markTaskAsDone ({ commit, dispatch, rootGetters }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const task = await taskService.submitAction({
                ...data,
                companyId: rootGetters['company/company'].id
            })

            commit('myWork/MARK_TASK_AS_DONE', task, { root: true })

            commit('SET_SINGLE_TASK', task)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}

const mutations = {
    SET_TASKS (state, tasks) {
        state.tasks = tasks.map(task => {
            const { soft_type: softType } = task

            return {
                ...task,
                source: TASKS.TYPE_SOURCES[softType.name],
                category: TASKS.TYPE_CATEGORIES[softType.name]
            }
        })
    },

    UPDATE_TASK (state, task) {
        state.tasks = state.tasks.map(stateTask => {
            if (stateTask.id === task.id) {
                return task
            }

            return stateTask
        })
    },

    SET_SINGLE_TASK (state, task) {
        state.singleTask = task
    }
}

const getters = {
    tasks: state => state.tasks,
    singleTask: state => state.singleTask
}

export const task = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
