<template>
    <img class="w-100" :src="url" />
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            required: true
        }
    }
}
</script>
