<template>
    <div>
        <label :class="wrapperClasses" @mouseup="removeFocus">
            <slot name="first-label"></slot>
            <input
                ref="switch"
                :tabindex="tabindex"
                type="checkbox"
                class="cf-c-switch__input"
                :checked="value"
                :disabled="disabled"
                @change="$emit('input', $event.target.checked)"
            />
            <span class="cf-c-switch__toggler">
                <div class="cf-c-switch__toggler-button">
                    <p
                        class="cf-c-switch__toggler-button-text"
                        :class="{
                            'cf-c-switch__toggler-button-text-left': !value
                        }"
                    >
                        {{
                            $t(
                                'COMPANY_SETTINGS.MOUNTH_YEARLY_SWITCHER.MONTHLY'
                            )
                        }}
                    </p>
                    <p
                        class="cf-c-switch__toggler-button-text cf-c-switch__toggler-button-text-format"
                        :class="{
                            'cf-c-switch__toggler-button-text-right': value
                        }"
                    >
                        {{
                            $t('COMPANY_SETTINGS.MOUNTH_YEARLY_SWITCHER.YEARLY')
                        }}
                        <span v-if="!value">{{
                            $t(
                                'COMPANY_SETTINGS.MOUNTH_YEARLY_SWITCHER.ADDITIONAL'
                            )
                        }}</span>
                    </p>
                </div>
            </span>
        </label>
    </div>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        value: {
            type: Boolean,
            required: true
        },

        tabindex: {
            type: Number,
            required: false,
            default: 0
        }
    },

    computed: {
        wrapperClasses () {
            let classes = 'cf-c-switch'

            if (this.disabled) {
                classes += ' cf-is-disabled'
            }

            return classes
        }
    },

    methods: {
        removeFocus () {
            this.$refs.switch.blur()
        }
    }
}
</script>
