import { transform } from 'lodash-es'
import { CORRECTIVE_ACTIONS } from '@/constants'

const {
    CORRECTIONABLE_TYPES,
    STATUSES_BY_TYPE,
    ROUTE_MAP,
    UNLINK_FORBIDDEN_TYPES_WHEN_CORRECTIVE_ACTION_COMPLETED
} = CORRECTIVE_ACTIONS

export default {
    props: {
        item: {
            type: Object,
            required: true
        },

        type: {
            type: String,
            required: true
        },

        isCorrectiveActionCompleted: {
            type: Boolean,
            required: false,
            default: false
        },

        showDelete: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    created () {
        this.statusesByType = STATUSES_BY_TYPE
        this.routeMap = ROUTE_MAP
    },

    computed: {
        routeToItem () {
            return {
                name: this.routeMap[this.type].name,
                query: { id: this.item.id }
            }
        },

        status () {
            const statusMap = {
                [CORRECTIONABLE_TYPES.INCIDENT]: item => item.state.name,
                [CORRECTIONABLE_TYPES.NONCONFORMITY]: item => item.state.name,
                [CORRECTIONABLE_TYPES.COMPANY_REGULATION_MANAGEMENT_REVIEW]: item =>
                    item.status,
                [CORRECTIONABLE_TYPES.CORRECTIVE_ACTION]: item =>
                    item.status.name
            }

            return statusMap[this.type](this.item)
        },

        isIncident () {
            return this.type === CORRECTIONABLE_TYPES.INCIDENT
        },

        statusColorClass () {
            const statuses = transform(
                this.statusesByType[this.type],
                (result, value, key) => {
                    if (key) {
                        result[key.toUpperCase()] = value
                    }
                }
            )

            return statuses[this.status]
                ? statuses[this.status.toUpperCase()]
                : ''
        },

        canRemove () {
            if (!this.isCorrectiveActionCompleted) {
                return true
            }

            return !UNLINK_FORBIDDEN_TYPES_WHEN_CORRECTIVE_ACTION_COMPLETED.includes(
                this.type
            )
        }
    }
}
