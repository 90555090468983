<template>
    <div class="cf-c-tabs" :class="{ 'cf-c-tabs--full-height': fullHeight }">
        <div class="cf-c-tabs__items">
            <slot name="tabs"></slot>
        </div>
        <div id="tab-content" class="cf-c-tabs__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fullHeight: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>
