import { ISO_27001 } from './regulations'

const REGULATIONS = [{ value: ISO_27001, label: ISO_27001 }]

const ZERO_TO_TWENTY_EMPLOYEES = 'zero_to_twenty_employees'
const TWENTY_TO_FIFTY_EMPLOYEES = 'twenty_to_fifty_employees'
const FIFTY_TO_TWO_HUNDRED_EMPLOYEES = 'fifty_to_two_hundred_employees'
const ONE_HUNDRED_TO_FIVE_HUNDRED_EMPLOYEES =
    'one_hundred_to_five_hundred_employees'
const FIVE_HUNDRED_TO_TWO_THOUSAND_EMPLOYEES =
    'five_hundred_to_two_thousand_employees'
const TWO_THOUSAND_OR_MORE_EMPLOYEES = 'two_thousand_or_more_employees'

const NUMBER_OF_EMPLOYEES = [
    {
        value: ZERO_TO_TWENTY_EMPLOYEES,
        label: 'COMPANY_SETTINGS.ONE_TO_TWENTY_EMPLOYEES'
    },
    {
        value: TWENTY_TO_FIFTY_EMPLOYEES,
        label: 'COMPANY_SETTINGS.TWENTY_TO_FIFTY_EMPLOYEES'
    },
    {
        value: FIFTY_TO_TWO_HUNDRED_EMPLOYEES,
        label: 'COMPANY_SETTINGS.FIFTY_TO_TWO_HUNDRED_EMPLOYEES'
    },
    {
        value: ONE_HUNDRED_TO_FIVE_HUNDRED_EMPLOYEES,
        label: 'COMPANY_SETTINGS.ONE_HUNDRED_TO_FIVE_HUNDRED_EMPLOYEES'
    },
    {
        value: FIVE_HUNDRED_TO_TWO_THOUSAND_EMPLOYEES,
        label: 'COMPANY_SETTINGS.FIVE_HUNDRED_TO_TWO_THOUSAND_EMPLOYEES'
    },
    {
        value: TWO_THOUSAND_OR_MORE_EMPLOYEES,
        label: 'COMPANY_SETTINGS.TWO_THOUSAND_OR_MORE_EMPLOYEES'
    }
]

const CONFORMIO_ONLY = 'conformio'
const DROPBOX = 'dropbox'
const SHAREPOINT = 'sharepoint'
const SLACK = 'slack'

const FILE_SYSTEMS = [
    { value: CONFORMIO_ONLY, label: 'COMPANY_SETTINGS.FILES_CONFORMIO_ONLY' },
    { value: DROPBOX, label: 'COMPANY_SETTINGS.FILES_DROPBOX' },
    { value: SHAREPOINT, label: 'COMPANY_SETTINGS.FILES_SHAREPOINT' }
]

const COMMUNICATION_SYSTEMS = [
    {
        value: CONFORMIO_ONLY,
        label: 'COMPANY_SETTINGS.COMMUNICATION_CONFORMIO_ONLY'
    },
    { value: SLACK, label: 'COMPANY_SETTINGS.COMMUNICATION_SLACK' }
]

const ROLES = {
    MAIN_EXECUTIVE: 'main_executive',
    LEGAL_ADVISOR: 'legal_advisor',
    COMPLIANCE_OFFICER: 'compliance_officer',
    HEAD_OF_IT: 'head_of_it',
    SECURITY_OFFICER: 'security_officer',
    HR_OFFICER: 'hr_officer',
    OFFICE_MANAGER: 'office_manager',
    INTERNAL_AUDITOR: 'internal_auditor',
    PURCHASING_MANAGER: 'purchasing_manager',
    SPONSOR: 'sponsor',
    PROJECT_MANAGER: 'project_manager',
    MARKETING_OFFICER: 'marketing_officer',
    FINANCIAL_OFFICER: 'financial_officer',
    RESOURCE_APPROVER: 'resource_approver'
}

const ROLE_CONFORMIO_USER = 'conformio_user'

const CONFORMIO_ROLES = [
    ROLE_CONFORMIO_USER,
    ROLES.SPONSOR,
    ROLES.PROJECT_MANAGER
]

const SECURITY_OBJECTIVE_FINISH_ISO_27001 = 'finish_iso_27001'

export {
    REGULATIONS,
    NUMBER_OF_EMPLOYEES,
    FILE_SYSTEMS,
    COMMUNICATION_SYSTEMS,
    ROLES,
    SLACK,
    CONFORMIO_ONLY,
    DROPBOX,
    SHAREPOINT,
    SECURITY_OBJECTIVE_FINISH_ISO_27001,
    CONFORMIO_ROLES
}
