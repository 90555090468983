<template>
    <div :class="styles">
        <button class="cf-c-draggable-item__drag-area" tabindex="-1">
            <app-icon icon="drag-icon" />
        </button>
        <div class="cf-c-draggable-item__ctn">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        lg: {
            type: Boolean,
            required: false,
            default: false
        },

        shadowed: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        styles () {
            let classes = 'cf-c-draggable-item'

            if (this.lg) {
                classes += ' cf-c-draggable-item--lg'
            }

            if (this.shadowed) {
                classes += ' cf-c-draggable-item--shadowed'
            }

            return classes
        }
    }
}
</script>
