import axios from 'axios'

const ENDPOINTS = {
    GET_SECURITY_OBJECTIVES: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/security-objectives`,
    GET_SECURITY_OBJECTIVE_DETAILS: (companyId, regulationId, objectiveId) =>
        `companies/${companyId}/regulations/${regulationId}/security-objectives/${objectiveId}`,
    ADD_SECURITY_OBJECTIVE: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/security-objectives`,
    EDIT_SECURITY_OBJECTIVE: (companyId, regulationId, objectiveId) =>
        `companies/${companyId}/regulations/${regulationId}/security-objectives/${objectiveId}`,
    DELETE_SECURITY_OBJECTIVE: (companyId, regulationId, objectiveId) =>
        `companies/${companyId}/regulations/${regulationId}/security-objectives/${objectiveId}`,
    CONFIRM_SECURITY_OBJECTIVES: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/security-objectives/confirm`
}

export default class SecurityObjectivesService {
    /**
     * Get all security objectives
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getSecurityObjectives (companyId, regulationId) {
        try {
            const route = ENDPOINTS.GET_SECURITY_OBJECTIVES(
                companyId,
                regulationId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get security objective details
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} objectiveId
     *
     * @returns Promise
     */
    async getSecurityObjectiveDetails (companyId, regulationId, objectiveId) {
        try {
            const route = ENDPOINTS.GET_SECURITY_OBJECTIVE_DETAILS(
                companyId,
                regulationId,
                objectiveId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Add new objective
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Object} payload
     *
     * @returns Promise
     */
    async createObjective (companyId, regulationId, payload) {
        try {
            const route = ENDPOINTS.ADD_SECURITY_OBJECTIVE(
                companyId,
                regulationId
            )
            const { data } = await axios.post(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update objective
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} objectiveId
     * @param {Object} payload
     *
     * @returns Promise
     */
    async updateObjective (companyId, regulationId, objectiveId, payload) {
        try {
            const route = ENDPOINTS.EDIT_SECURITY_OBJECTIVE(
                companyId,
                regulationId,
                objectiveId
            )
            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Delete objective
     *
     * @param {Number|String} companyId
     * @param {Number|String} regulationId
     * @param {Number|String} objectiveId
     *
     * @returns Promise
     */
    async deleteObjective (companyId, regulationId, objectiveId) {
        try {
            const route = ENDPOINTS.DELETE_SECURITY_OBJECTIVE(
                companyId,
                regulationId,
                objectiveId
            )
            const { data } = await axios.delete(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Send objectives to be confirmed
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async confirmObjectives (companyId, regulationId) {
        try {
            const route = ENDPOINTS.CONFIRM_SECURITY_OBJECTIVES(
                companyId,
                regulationId
            )
            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const securityObjectivesService = new SecurityObjectivesService()
