<template>
    <div
        v-if="shouldShowNotification"
        class="preonboarding-notification__wrapper"
    >
        <div class="preonboarding-notification__content">
            <app-icon
                class="preonboarding-notification__icon"
                icon="icon-phone-message"
                size="xs"
            />

            {{ $t('PREONBOARDING.NOTIFICATION.BODY') }}

            <button
                type="button"
                class="preonboarding-notification__action"
                @click="scheduleCall"
            >
                {{ $t('PREONBOARDING.NOTIFICATION.ACTION') }}
            </button>
        </div>
        <button
            type="button"
            class="preonboarding-notification__dismiss"
            @click="dismissNotification()"
        >
            <app-icon
                class="preonboarding-notification__dismiss-icon"
                icon="icon-cancel"
                size="xs"
            />
        </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
    getLocalStorageItem,
    setLocalStorageItem
} from '@/services/localStorage'
import { COMPANY } from '@src/constants'

const NOTIFICATION_LOCAL_STORAGE_KEY = 'notification'

export default {
    data: () => ({
        id: 'schedule-call',
        show: true
    }),

    computed: {
        ...mapGetters('account', ['user']),
        ...mapGetters('company', ['pricingPlan']),

        shouldShowNotification () {
            const currentPlanName = this.pricingPlan?.plan.name

            return (
                this.show &&
                !this.isNotificationDismissed(this.id) &&
                (currentPlanName === COMPANY.FREE_PLAN ||
                    currentPlanName === COMPANY.FREEZED_PLAN ||
                    currentPlanName === COMPANY.CANCELED_PLAN)
            )
        }
    },

    methods: {
        scheduleCall () {
            this.$modal.show('modal-onboarding-meeting')
        },

        /** Dissmisses the actual notification */
        dismissNotification () {
            this.setStorageItem(this.id, { dismiss: true })

            this.show = false
        },

        /** Checks whether is notification dismissed */
        isNotificationDismissed (notificationId) {
            let options = this.getStorageItem(notificationId)

            return options.dismiss !== undefined && options.dismiss
        },

        /** Key of a notification entry in local storage */
        storageItemKey (notificationId) {
            return (
                NOTIFICATION_LOCAL_STORAGE_KEY +
                '_' +
                this.user.id +
                '_' +
                notificationId
            )
        },

        /** Saves notification settings to local storage (or cookie) */
        setStorageItem (notificationId, newOptions = {}) {
            if (notificationId && newOptions) {
                let options = this.getStorageItem(notificationId)

                Object.keys(newOptions).forEach(option => {
                    options[option] = newOptions[option]
                })

                setLocalStorageItem(
                    this.storageItemKey(notificationId),
                    JSON.stringify(options)
                )
            }
        },

        /** Reads notification settings value from local storage */
        getStorageItem (notificationId) {
            return (
                JSON.parse(
                    getLocalStorageItem(this.storageItemKey(notificationId))
                ) ?? {}
            )
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@style/sfc.scss';

.preonboarding-notification {
    &__wrapper {
        position: relative;
        padding: rem(8) rem(20);
        background-color: $color-primary-gamma;
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: rem(12);
        line-height: 1;
        color: $color-primary-beta;
    }

    &__icon {
        margin-right: rem(8);
    }

    &__action {
        margin: 0 0 0 rem(2);
        padding: 0;
        border: 0;
        font-size: rem(12);
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        text-decoration: underline;
        background-color: transparent;
        color: $color-primary-beta;
        transition: color 0.25s ease;

        &:hover {
            color: $color-primary-epsilon;
        }
    }

    &__dismiss {
        position: absolute;
        top: rem(8);
        right: rem(20);
        margin: 0;
        padding: 0;
        border: 0;
        background-color: transparent;
        color: $color-primary-beta;
        transition: color 0.25s ease;

        &:hover {
            color: $color-primary-epsilon;
        }
    }
}
</style>
