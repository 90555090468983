import { notificationSettingsService } from '@/services/api/notification-settings'

const actions = {
    async getSettingsAction ({ commit, rootGetters }) {
        commit('SET_APP_LOADING', true, { root: true })
        const companyId = rootGetters['company/company'].id

        try {
            return await notificationSettingsService.getNotificationSettings(
                companyId
            )
        } catch (error) {
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async saveProvidersAction ({ commit, rootGetters }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            await notificationSettingsService.saveProvidersSettings(
                rootGetters['company/company'].id,
                data
            )
        } catch (error) {
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async saveNotificationGroupsAction ({ commit, rootGetters }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            await notificationSettingsService.saveNotificationGroups(
                rootGetters['company/company'].id,
                data
            )
        } catch (error) {
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async saveNotificationSettingsAction ({ commit, rootGetters }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            await notificationSettingsService.saveNotificationSettings(
                rootGetters['company/company'].id,
                data
            )
        } catch (error) {
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}

const mutations = {}

const getters = {}

export const notification = {
    namespaced: true,
    actions,
    mutations,
    getters
}
