<template>
    <div :class="['cf-c-cube-tag', wrapperClasses]">
        <span class="cf-c-cube-tag__label">
            <slot name="label"></slot>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        wrapperClasses: {
            type: [Object, Array, String],
            required: false
        }
    }
}
</script>
