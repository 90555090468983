<template>
    <div :class="getStyles">
        <div
            v-if="!isLabelHidden"
            class="cf-c-reg-section-form-group__title-wrap"
        >
            <strong class="cf-c-reg-section-form-group__title-text">
                <div>
                    <slot name="label">
                        {{ label }}
                    </slot>
                    <span
                        v-if="required"
                        class="cf-c-reg-section-form-group__asterisk"
                    ></span>
                </div>
                <slot name="info"></slot>
            </strong>
        </div>
        <div
            v-if="$slots.default"
            class="cf-c-reg-section-form-group__input-wrap"
            @focus.capture="handleFocus"
            @blur.capture="handleBlur"
        >
            <slot></slot>
        </div>
        <span
            v-if="prequired"
            class="cf-c-reg-section-form-group__asterisk"
        ></span>
    </div>
</template>

<script>
import { EventBus } from '@/EventBus'
import { isModalOpen } from '@/services/utils/dom'

export default {
    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },

        isLabelHidden: {
            type: Boolean,
            required: false,
            default: false
        },

        name: {
            type: String,
            required: false,
            default: ''
        },

        description: {
            type: String,
            required: false,
            default: ''
        },

        titleNarrow: {
            type: Boolean,
            required: false,
            default: false
        },

        titleNarrowXs: {
            type: Boolean,
            required: false,
            default: false
        },

        equalColumns: {
            type: Boolean,
            required: false,
            default: false
        },

        contentShrink: {
            type: Boolean,
            required: false,
            default: false
        },

        marginMd: {
            type: Boolean,
            required: false,
            default: false
        },

        required: {
            type: Boolean,
            required: false,
            default: false
        },

        prequired: {
            type: Boolean,
            required: false,
            default: false
        },

        noPadding: {
            type: Boolean,
            required: false,
            default: false
        },

        titleAutoHeight: {
            type: Boolean,
            required: false,
            default: false
        },

        groupColumn: {
            type: Boolean,
            required: false,
            default: false
        },

        labelCenter: {
            type: Boolean,
            required: false,
            default: false
        },

        alignLabelCenter: {
            type: Boolean,
            required: false,
            default: false
        },
        noMargin: {
            type: Boolean,
            required: false,
            default: false
        },
        className: {
            type: String,
            required: false,
            default: ''
        },
        helpText: {
            type: String,
            required: false,
            default: ''
        }
    },

    computed: {
        getStyles () {
            let styles = 'cf-c-reg-section-form-group'
            if (this.titleNarrow) {
                styles += ' cf-c-reg-section-form-group--title-narrow'
            }
            if (this.titleNarrowXs) {
                styles += ' cf-c-reg-section-form-group--title-narrow-xs'
            }
            if (this.equalColumns) {
                styles += ' cf-c-reg-section-form-group--equal-columns'
            }
            if (this.contentShrink) {
                styles += ' cf-c-reg-section-form-group--content-shrink'
            }
            if (this.marginMd) {
                styles += ' cf-c-reg-section-form-group--margin-md'
            }
            if (this.required) {
                styles += ' cf-is-required'
            }
            if (this.noPadding) {
                styles += ' cf-c-reg-section-form-group--no-padding'
            }
            if (this.noMargin) {
                styles += ' cf-c-reg-section-form-group--no-margin'
            }
            if (this.titleAutoHeight) {
                styles += ' cf-c-reg-section-form-group--title-hight-auto'
            }
            if (this.groupColumn) {
                styles += ' cf-c-reg-section-form-group--group-column'
            }

            if (this.labelCenter) {
                styles += ' cf-c-reg-section-form-group--label-center'
            }

            if (this.alignLabelCenter) {
                styles += ' cf-c-reg-section-form-group--label-align-center'
            }

            if (this.className) {
                styles += ` ${this.className}`
            }
            return styles
        }
    },

    methods: {
        handleFocus () {
            if (isModalOpen()) {
                return
            }

            const { name, description, label, helpText } = this

            EventBus.$emit('input-focused', {
                description,
                label,
                name,
                helpText
            })
        },

        handleBlur () {
            !isModalOpen() && EventBus.$emit('input-blurred')
        }
    }
}
</script>
