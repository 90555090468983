<template>
    <searchable-list show5-items>
        <template slot="input">
            <app-input
                v-model="searchTerm"
                :placeholder="placeholder"
                type="text"
                :bg-gray="hasExtra"
                small
            />
        </template>
        <checkbox-list
            :list="filteredCheckboxes"
            :empty-list-message="emptyListMessage"
            :label="label"
            :track-by="trackBy"
            :value="value"
            has-extra
            :should-reinitialize="shouldReinitialize"
            small
            @checked="
                ($event, isInitialDataEmit) =>
                    $emit('checked', $event, isInitialDataEmit)
            "
        >
            <template slot="extra" slot-scope="props">
                <slot name="extra" :item="props.item"></slot>
            </template>
            <template slot="add-new">
                <slot name="add-new"></slot>
            </template>
        </checkbox-list>
    </searchable-list>
</template>

<script>
import { includesSearchTerm } from '@/services/utils/functions'

export default {
    props: {
        list: {
            type: Array,
            default: () => []
        },

        trackBy: {
            type: String,
            default: 'id'
        },

        value: {
            type: String,
            default: 'value'
        },

        label: {
            type: String,
            default: 'label'
        },

        emptyListMessage: {
            type: String,
            default: 'COMMON.CHECKBOX_SEARCH_EMPTY_LIST'
        },

        placeholder: {
            type: String,
            required: false,
            default: 'COMMON.SEARCH'
        },

        hasExtra: {
            type: Boolean,
            required: false,
            default: false
        },

        shouldReinitialize: {
            type: Boolean,
            required: false,
            default: false
        },

        show5Items: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data: () => ({
        searchTerm: ''
    }),
    computed: {
        filteredCheckboxes () {
            return this.list.filter(item =>
                includesSearchTerm(item[this.label], this.searchTerm)
            )
        }
    }
}
</script>
