import Vue from 'vue'
import { first } from 'lodash-es'
import { dmsService } from '@/services/api/dms'
import { TOAST_TYPES, STATUS_CODES } from '@/constants'

function getInitialState () {
    return {}
}

const state = getInitialState()

const actions = {
    async getFilesAndFoldersAtPath (
        { commit, rootGetters, dispatch },
        { path }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            return await dmsService.getFilesAndFoldersAtPath(companyId, path)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async deleteFileDms (
        { commit, rootGetters },
        { documentId, documentType }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            return await dmsService.deleteFileFromDms(
                rootGetters['company/company'].id,
                documentId,
                documentType
            )
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async deleteFolderDms (
        { commit, rootGetters, dispatch },
        { folderId, path }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            return await dmsService.deleteFolderFromDms(
                rootGetters['company/company'].id,
                folderId,
                path
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            if (error.response.status === STATUS_CODES.VALIDATION_FAILED) {
                Vue.toasted.show(first(error.response.data.errors), {
                    type: TOAST_TYPES.ERROR
                })
                dispatch('errors/clearAllErrors', null, { root: true })
            }
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async setPermissions ({ commit, rootGetters }, { folderId, data }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            return await dmsService.setPermissions(
                rootGetters['company/company'].id,
                folderId,
                data
            )
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async createFolderDms ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            return await dmsService.createFolderToDms(
                rootGetters['company/company'].id,
                data
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async renameFile ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            return await dmsService.renameFile(
                rootGetters['company/company'].id,
                data
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async renameFolder ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            return await dmsService.renameFolder(
                rootGetters['company/company'].id,
                data
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async moveFile ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            return await dmsService.moveFile(
                rootGetters['company/company'].id,
                data
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async moveFolder ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            return await dmsService.moveFolder(
                rootGetters['company/company'].id,
                data
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async shareFile ({ commit, rootGetters, dispatch }, data) {
        try {
            return await dmsService.shareFile(
                rootGetters['company/company'].id,
                data
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async copyFile ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            return await dmsService.copyFile(
                rootGetters['company/company'].id,
                data
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async copyFolder ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            return await dmsService.copyFolder(
                rootGetters['company/company'].id,
                data
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}

export const dms = {
    namespaced: true,
    state,
    actions
}
