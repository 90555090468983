<template>
    <div class="cf-c-reg-section-form cf-c-reg-section-form--border-shadow">
        <div
            class="cf-c-reg-section-form__item cf-c-reg-section-form__item--full cf-u-mb-sm"
        >
            <app-form-group
                :ref="inputs['section'].index"
                required
                title-narrow
                name="section"
                :label="$t(inputs['section'].label)"
            >
                <app-select-input
                    v-model="form.checklist_section_id"
                    ignore-localization
                    name="section"
                    full-width
                    sm
                    font-size="sm"
                    :tabindex="1"
                    label="title"
                    :placeholder="inputs['section'].placeholder"
                    :options="mappedSections"
                />
            </app-form-group>
        </div>
        <div
            class="cf-c-reg-section-form__item cf-c-reg-section-form__item--full cf-u-mb-sm"
        >
            <app-form-group
                :ref="inputs['title'].index"
                required
                title-narrow
                name="title"
                :label="$t(inputs['title'].label)"
                :description="$t(inputs['title'].description)"
            >
                <div class="cf-u-min-height-sm-double utility">
                    <autosize-textarea
                        v-model="form.title"
                        min-height-sm-double
                        :tabindex="2"
                        :placeholder="inputs['title'].placeholder"
                        :error-message="firstError('title')"
                        @input="clearErrors('title')"
                    />
                </div>
            </app-form-group>
        </div>
        <div
            class="cf-c-reg-section-form__item cf-c-reg-section-form__item--full cf-u-mb-sm"
        >
            <app-form-group
                :ref="inputs['reference_to'].index"
                required
                title-narrow
                name="reference_to"
                :label="$t(inputs['reference_to'].label)"
                :description="$t(inputs['reference_to'].description)"
            >
                <div class="cf-u-min-height-sm-double utility">
                    <autosize-textarea
                        v-model="form.reference_to"
                        min-height-sm-double
                        :tabindex="2"
                        :placeholder="inputs['reference_to'].placeholder"
                        :error-message="firstError('reference_to')"
                        @input="clearErrors('reference_to')"
                    />
                </div>
            </app-form-group>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { focusableInputs } from '@/mixins'
import { REGISTER_OF_INTERNAL_AUDITS } from '@/constants'

export default {
    mixins: [focusableInputs],

    props: {
        sections: {
            type: Array,
            required: true
        },

        form: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data () {
        return {
            isInEditMode: true,
            inputs:
                REGISTER_OF_INTERNAL_AUDITS.CHECKLIST_ADAPTATION_INPUT_GUIDES
        }
    },

    async created () {
        this.clearAllErrors()
    },

    computed: {
        ...mapGetters('regulation', ['currentRegulation']),
        ...mapGetters('errors', ['firstError']),

        mappedSections () {
            return this.sections.map(section => ({
                ...section,
                value: section.id
            }))
        }
    },

    methods: {
        ...mapActions('errors', ['clearErrors', 'clearAllErrors'])
    }
}
</script>
