<template>
    <div class="cf-c-donut-progress">
        <h4 v-if="!!title" class="cf-c-donut__title cf-u-mb-default">
            {{ title }}
        </h4>

        <div class="cf-c-donut__item cf-u-mb-default">
            <app-donut :dataset="dataset" :background-colors="[stat.color]" />
        </div>

        <div class="cf-c-donut__description">
            <div v-if="stat && !deadline">
                <app-completed-status-text
                    :current-step="stat.current"
                    :total-steps="stat.total"
                />
            </div>

            <template v-if="deadline">
                <span class="cf-u-font-size-xxl cf-u-font-weight-bold">
                    {{ deadline }}
                </span>
            </template>
        </div>
    </div>
</template>

<script>
import { getPercentage } from '@/services/utils/numbers'

export default {
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },

        deadline: {
            type: String,
            required: false,
            default: ''
        },

        stat: {
            type: Object,
            required: false,
            default: () => ({
                current: 0,
                total: 0,
                color: ''
            })
        }
    },

    computed: {
        dataset () {
            const percentage = getPercentage(this.stat.total, this.stat.current)

            return [percentage, 100 - percentage]
        }
    },

    methods: {
        getPercentage
    }
}
</script>
