<template>
    <label
        :class="[
            'cf-c-labeled-checkbox',
            { 'cf-c-labeled-checkbox--pure-checkbox': pureCheckbox },
            { 'cf-c-labeled-checkbox--truncated': truncated },
            { 'cf-c-labeled-checkbox--text-justified': textJustified },
            { 'cf-c-labeled-checkbox--small': small },
            { 'cf-c-labeled-checkbox--big': big },
            clickableArea && `cf-c-labeled-checkbox--clickable-${clickableArea}`
        ]"
    >
        <div class="cf-c-labeled-checkbox__ctn">
            <input
                v-model="checked"
                class="cf-c-labeled-checkbox__input"
                type="checkbox"
                :disabled="disabled"
                :value="value"
                :checked="value"
                @change="$emit('input', $event.target.checked)"
            />
            <div
                class="cf-c-labeled-checkbox__icon-wrapper"
                :class="[
                    { 'cf-is-checked': checked },
                    { 'cf-is-disabled': disabled }
                ]"
            >
                <app-icon
                    :size="iconSize"
                    :icon="checked ? 'check-on-icon' : 'check-off-icon'"
                />
            </div>
            <span v-if="!pureCheckbox" class="cf-c-labeled-checkbox__text">
                <slot>{{ label }}</slot>
            </span>
        </div>
    </label>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        value: {
            type: Boolean,
            required: false,
            default: false
        },

        label: {
            type: String,
            required: false,
            default: ''
        },

        pureCheckbox: {
            type: Boolean,
            required: false,
            default: false
        },

        truncated: {
            type: Boolean,
            required: false,
            default: false
        },

        clickableArea: {
            type: String,
            required: false,
            default: ''
        },

        iconSize: {
            type: String,
            required: false,
            default: 'sm'
        },

        textJustified: {
            type: Boolean,
            required: false,
            default: false
        },

        small: {
            type: Boolean,
            required: false,
            default: false
        },

        big: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data () {
        return {
            checked: false
        }
    },

    watch: {
        value (newValue) {
            this.checked = newValue
        }
    },

    created () {
        this.checked = this.value
    }
}
</script>
