<template>
    <div>
        <template>
            {{ displayText }}

            <span
                v-if="limitExceeed"
                @click="toggleReadMore"
                class="read-more__link"
                v-html="toggleLabel"
            >
            </span>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        },
        charLimit: {
            type: Number,
            required: true
        },
        readMoreLabel: {
            type: String,
            default: null
        },
        readLessLabel: {
            type: String,
            default: null
        }
    },

    data () {
        return {
            readMoreActivated: false
        }
    },

    computed: {
        limitExceeed () {
            if (!this.charLimit) {
                return false
            }

            // Tolarete if text is longer for 10%
            return this.text.length > 1.1 * this.charLimit
        },

        toggleLabel () {
            return this.readMoreActivated
                ? this.readLessLabel ?? this.$t('COMMON.SHOW_LESS')
                : this.readMoreLabel ?? this.$t('COMMON.SHOW_MORE')
        },

        displayText () {
            if (!this.limitExceeed || this.readMoreActivated) {
                return this.text
            }

            // Reduce text
            const slicedText = this.text.slice(0, this.charLimit)

            return this.sliceSpecificChars(slicedText) + '...'
        }
    },

    methods: {
        toggleReadMore () {
            this.readMoreActivated = !this.readMoreActivated
        },

        sliceSpecificChars (text) {
            const lastChar = text[text.length - 1]

            if (lastChar.match(/^[ '.,:!?]/)) {
                const slicedText = text.slice(0, -1)

                return this.sliceSpecificChars(slicedText)
            }

            return text
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@style/sfc.scss';

.read-more__link {
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
        color: $color-primary-epsilon;
    }
}
</style>
