import { REGISTER_WIZARD_ROUTES } from '@/constants'
import { getFullNameOrEmail } from '@/services/utils/functions'

const INCIDENTS_REGISTER_SIDEBAR_ROUTES = [
    {
        route: REGISTER_WIZARD_ROUTES.INCIDENTS.WIZARD,
        label: 'COMMON.WIZARD'
    },
    {
        route: REGISTER_WIZARD_ROUTES.INCIDENTS.PROPERTIES,
        label: 'COMMON.PROPERTIES'
    },
    {
        route: REGISTER_WIZARD_ROUTES.INCIDENTS.DISCUSSIONS,
        label: 'COMMON.DISCUSSIONS'
    }
]

const PERIODIC_REVIEW_SIDEBAR_ROUTES = [
    {
        route: REGISTER_WIZARD_ROUTES.PERIODIC_REVIEW.WIZARD,
        label: 'COMMON.WIZARD'
    },
    {
        route: REGISTER_WIZARD_ROUTES.PERIODIC_REVIEW.PROPERTIES,
        label: 'COMMON.PROPERTIES'
    },
    {
        route: REGISTER_WIZARD_ROUTES.PERIODIC_REVIEW.DISCUSSIONS,
        label: 'COMMON.DISCUSSIONS'
    }
]

const SORT_VALUES = {
    DATE_OF_EVENT_ASC: 'created_at_asc',
    DATE_OF_EVENT_DESC: 'created_at_desc',
    RESPONSIBLE_PERSON_ASC: 'responsible_person_asc',
    RESPONSIBLE_PERSON_DESC: 'responsible_person_desc',
    STATUS_ASC: 'status_asc',
    STATUS_DESC: 'status_desc'
}

const SORT_OPTIONS = [
    {
        value: SORT_VALUES.DATE_OF_EVENT_ASC,
        label: 'REGISTERS.INCIDENTS.SORT_VALUES.DATE_OF_EVENT_ASC'
    },
    {
        value: SORT_VALUES.DATE_OF_EVENT_DESC,
        label: 'REGISTERS.INCIDENTS.SORT_VALUES.DATE_OF_EVENT_DESC'
    },
    {
        value: SORT_VALUES.RESPONSIBLE_PERSON_ASC,
        label: 'REGISTERS.INCIDENTS.SORT_VALUES.RESPONSIBLE_PERSON_ASC'
    },
    {
        value: SORT_VALUES.RESPONSIBLE_PERSON_DESC,
        label: 'REGISTERS.INCIDENTS.SORT_VALUES.RESPONSIBLE_PERSON_DESC'
    },
    {
        value: SORT_VALUES.STATUS_ASC,
        label: 'REGISTERS.INCIDENTS.SORT_VALUES.STATUS_ASC'
    },
    {
        value: SORT_VALUES.STATUS_DESC,
        label: 'REGISTERS.INCIDENTS.SORT_VALUES.STATUS_DESC'
    }
]

const SORT_ACTIONS = {
    [SORT_VALUES.DATE_OF_EVENT_ASC]: (a, b) => (a.date > b.date ? 1 : -1),
    [SORT_VALUES.DATE_OF_EVENT_DESC]: (a, b) => (a.date < b.date ? 1 : -1),
    [SORT_VALUES.RESPONSIBLE_PERSON_ASC]: (a, b) =>
        getFullNameOrEmail(a.assignee) > getFullNameOrEmail(b.assignee)
            ? 1
            : -1,
    [SORT_VALUES.RESPONSIBLE_PERSON_DESC]: (a, b) =>
        getFullNameOrEmail(a.assignee) < getFullNameOrEmail(b.assignee)
            ? 1
            : -1,
    [SORT_VALUES.STATUS_ASC]: (a, b) => (a.state.id > b.state.id ? 1 : -1),
    [SORT_VALUES.STATUS_DESC]: (a, b) => (a.state.id < b.state.id ? 1 : -1)
}

const STATUSES = {
    UNASSIGNED: 'unassigned',
    ASSIGNED: 'assigned',
    ACTIONS_DEFINED: 'actions_defined',
    RESOLVED: 'resolved'
}

const STATUS_COLORS = {
    UNASSIGNED: 'cf-u-color-unassigned',
    ASSIGNED: 'cf-u-color-primary',
    ACTIONS_DEFINED: 'cf-u-color-amber',
    RESOLVED: 'cf-u-color-secondary-light'
}

const REVIEW_STATUS_COLORS = {
    REVIEWED: 'cf-u-color-secondary-light',
    NOT_REVIEWED: 'cf-u-color-gray-02'
}

const STATUS_GUIDES = [
    {
        label: 'REGISTERS.INCIDENTS.STATUSES.UNASSIGNED',
        color: STATUS_COLORS.UNASSIGNED
    },
    {
        label: 'REGISTERS.INCIDENTS.STATUSES.ASSIGNED',
        color: STATUS_COLORS.ASSIGNED
    },
    {
        label: 'REGISTERS.INCIDENTS.STATUSES.ACTIONS_DEFINED',
        color: STATUS_COLORS.ACTIONS_DEFINED
    },
    {
        label: 'REGISTERS.INCIDENTS.STATUSES.RESOLVED',
        color: STATUS_COLORS.RESOLVED
    }
]

const INPUT_GUIDES = {
    title: {
        label: 'REGISTERS.INCIDENTS.FIELDS.TITLE.LABEL',
        description: 'REGISTERS.INCIDENTS.FIELDS.TITLE.DESCRIPTION',
        index: 0
    },
    description: {
        label: 'REGISTERS.INCIDENTS.FIELDS.DESCRIPTION.LABEL',
        description: 'REGISTERS.INCIDENTS.FIELDS.DESCRIPTION.DESCRIPTION',
        index: 1
    },
    reporter_id: {
        label: 'REGISTERS.INCIDENTS.FIELDS.REPORTED_BY.LABEL',
        index: 2
    },
    date: {
        label: 'REGISTERS.INCIDENTS.FIELDS.DATE_OF_THE_EVENT.LABEL',
        index: 3
    },
    time: {
        label: 'REGISTERS.INCIDENTS.FIELDS.TIME.LABEL',
        index: 4
    },
    type: {
        label: 'REGISTERS.INCIDENTS.FIELDS.TYPE.LABEL',
        description: 'REGISTERS.INCIDENTS.FIELDS.TYPE.DESCRIPTION',
        index: 5
    },
    cost: {
        label: 'REGISTERS.INCIDENTS.FIELDS.COST.LABEL',
        description: 'REGISTERS.INCIDENTS.FIELDS.COST.DESCRIPTION',
        index: 6
    },
    related_risks: {
        label: 'REGISTERS.INCIDENTS.FIELDS.RELATED_RISKS.LABEL',
        description: 'REGISTERS.INCIDENTS.FIELDS.RELATED_RISKS.DESCRIPTION',
        index: 7
    },
    files: {
        label: 'COMMON.ATTACH_A_FILE',
        description: 'REGISTERS.INCIDENTS.FIELDS.ATTACHED_FILES.DESCRIPTION',
        index: 8
    }
}

const INCIDENT_PARAMS = [
    'title',
    'description',
    'reporter_id',
    'date',
    'time',
    'type_id',
    'cost',
    'state',
    'risks',
    'notificants',
    'assignee_id',
    'classification',
    'ready_for_resolving'
]

const NO_INCIDENT = 'no_incident'
const MINOR_INCIDENT = 'minor_incident'
const MAJOR_INCIDENT = 'major_incident'

const CLASSIFICATIONS_WITH_WRAP_UP = [MAJOR_INCIDENT, MINOR_INCIDENT]

const CLASSIFICATIONS = [
    {
        label: 'REGISTERS.INCIDENTS.CLASSIFICATIONS.NO_INCIDENT',
        value: NO_INCIDENT
    },
    {
        label: 'REGISTERS.INCIDENTS.CLASSIFICATIONS.MINOR_INCIDENT',
        value: MINOR_INCIDENT
    },
    {
        label: 'REGISTERS.INCIDENTS.CLASSIFICATIONS.MAJOR_INCIDENT',
        value: MAJOR_INCIDENT
    }
]

const RESPONSIBILITIES_INPUT_GUIDES = {
    classification: {
        label:
            'REGISTERS.INCIDENTS.RESPONSIBILITIES.FIELDS.CLASSIFICATION.LABEL',
        description:
            'REGISTERS.INCIDENTS.RESPONSIBILITIES.FIELDS.CLASSIFICATION.DESCRIPTION',
        index: 0
    },
    notificants: {
        label: 'REGISTERS.INCIDENTS.RESPONSIBILITIES.FIELDS.NOTIFY.LABEL',
        description:
            'REGISTERS.INCIDENTS.RESPONSIBILITIES.FIELDS.NOTIFY.DESCRIPTION',
        index: 1
    },
    assignee_id: {
        label: 'REGISTERS.INCIDENTS.RESPONSIBILITIES.FIELDS.ASSIGN_TO.LABEL',
        description:
            'REGISTERS.INCIDENTS.RESPONSIBILITIES.FIELDS.ASSIGN_TO.DESCRIPTION',
        index: 2
    }
}

const MODES = {
    STANDARD: 'standard',
    REVIEW: 'review'
}

const INTENTIONS = {
    CREATE: 'create',
    READ: 'read',
    EDIT: 'edit'
}

const REGISTER_OF_INCIDENTS = {
    INCIDENTS_REGISTER_SIDEBAR_ROUTES,
    PERIODIC_REVIEW_SIDEBAR_ROUTES,
    SORT_VALUES,
    SORT_OPTIONS,
    SORT_ACTIONS,
    STATUSES,
    STATUS_COLORS,
    STATUS_GUIDES,
    INPUT_GUIDES,
    INCIDENT_PARAMS,
    CLASSIFICATIONS,
    NO_INCIDENT,
    MINOR_INCIDENT,
    MAJOR_INCIDENT,
    RESPONSIBILITIES_INPUT_GUIDES,
    MODES,
    INTENTIONS,
    REVIEW_STATUS_COLORS,
    CLASSIFICATIONS_WITH_WRAP_UP
}

export { REGISTER_OF_INCIDENTS }
