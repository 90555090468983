import axios from 'axios'
import { assign } from 'lodash-es'
import router from '@/router'
import store from '@/store'
import {
    COMMON_PAGES,
    DEFAULT_LOCALE,
    LOCAL_STORAGE_ITEMS,
    STATUS_CODES,
    AUTH_COMPANY_ID,
    SUBDOMAIN,
    ROUTES
} from '@/constants'
import { ROUTES_AUTH } from '@src/modules/auth/constants'
import {
    getLocalStorageItem,
    removeLocalStorageItem,
    setLocalStorageItem
} from '@/services/localStorage'
import { getCookie, removeCookie, setCookie } from '../localStorage'
import { ROUTES_MAINTENANCE } from '@src/modules/maintenance/constants'

const ENDPOINTS = {
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    REGISTER: '/auth/register',
    VERIFY_TOKEN: '/auth/token/verify',
    REFRESH_TOKEN: '/auth/token/refresh',
    VERIFY_ACCOUNT: '/auth/account/verify',
    LOGGED_IN_USER: '/auth/me',
    PASSWORD_RESET_REQEUST: '/auth/password/email',
    PASSWORD_RESET: '/auth/password/reset',
    PASSWORD_CHANGE: '/auth/password/change',
    SUBMIT_OTP: '/auth/submit_otp',
    USER_METADATA: '/auth/metadata',
    IS_EMAIL_VERIFIED: '/auth/email-verified'
}

const regex = '/undefined'

class AuthService {
    constructor () {
        this.setAuthHeader()
        this.setLanguageHeader()
        this.setLanguageHeader()
        this.setAuthResponseInterceptors()
    }

    /**
     * Sets auth data in localStorage in order to remember the users token
     * @param {String} token
     * @param {String} refreshToken
     * @param {Boolean} remember
     */
    setLocalAuthData (token, refreshToken, remember = false) {
        // Set cookies
        setCookie('token', token, remember)
        setCookie('refreshToken', refreshToken, remember)

        // Set auth header
        this.setAuthHeader()
    }

    setLocalStorageCompanyData (id, subdomain) {
        // Set auth company id
        setLocalStorageItem(AUTH_COMPANY_ID, id)

        // Set auth company subdomain
        setLocalStorageItem(SUBDOMAIN, subdomain)
    }

    async tryLogin (loginData) {
        const loginResponse = await store.dispatch(
            'account/attemptLogin',
            loginData,
            { root: true }
        )

        // Login is successfull, login action can be finalized
        if (loginResponse.status === 'success') {
            await this.finalizeLogin({
                ...loginResponse,
                remember: loginData?.remember ?? false
            })

            return true
        }

        // Multi-Factor Authentication is needed to finish login
        if (loginResponse.status === 'mfa_required') {
            const { mfa_token: mfaToken, mfa_action: mfaAction } = loginResponse

            router.push({
                name:
                    mfaAction === 'setup'
                        ? ROUTES.MFA.SETUP.name
                        : ROUTES.MFA.VERIFY.name,
                params: { mfaToken }
            })

            return false
        }
    }

    async finalizeLogin ({
        access_token: accessToken,
        refresh_token: refreshToken,
        remember = false
    }) {
        // Fetch user details
        await store.dispatch(
            'account/doLogin',
            {
                token: accessToken,
                refreshToken: refreshToken,
                remember,
                withLoader: true
            },
            { root: true }
        )

        // Fetch company details
        await store.dispatch('company/getCompany', null, { root: true })
    }

    /**
     * Clear localStorage from auth data
     */
    clearLocalAuthData () {
        removeCookie('token')
        removeCookie('refreshToken')

        removeLocalStorageItem(SUBDOMAIN)
        removeLocalStorageItem(AUTH_COMPANY_ID)
    }

    /**
     * Checks if user is logged in by checking localStorage
     *
     * @returns {Boolean}
     */
    isLoggedIn () {
        return !!getCookie('token')
    }

    /**
     * Sets Authorization header in order to make authenticated requests
     */
    setAuthHeader () {
        this.setHeader('Authorization', `Bearer ${getCookie('token')}`)
    }

    /**
     * Removes axios default authorization header
     */
    removeAuthHeader () {
        delete axios.defaults.headers['Authorization']
    }

    /**
     * Sets Accept-Language header to appropriate user selected language
     */
    setLanguageHeader () {
        const locale = getLocalStorageItem('locale') || DEFAULT_LOCALE
        this.setHeader('Accept-Language', locale)
    }

    /**
     * Gets the currently logged in user
     *
     * @returns {Promise}
     */
    async getLoggedInUser () {
        try {
            const response = await axios.post(ENDPOINTS.LOGGED_IN_USER)

            return response.data
        } catch (error) {
            throw error
        }
    }

    /**
     * Attempt login
     * @param {Object} data
     */
    async attemptLogin (credentials) {
        try {
            const { data } = await axios.post(ENDPOINTS.LOGIN, credentials)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Log out currently logged in user
     */
    async logout () {
        try {
            this.clearLocalAuthData()

            router.push(ROUTES_AUTH.LOGIN).catch(() => {})

            store.dispatch('clearAll')

            this.removeAuthHeader()

            if (store.hasModule('consultantDashboard')) {
                store.commit('consultantDashboard/resetState')
            }
        } catch (error) {
            throw error
        }
    }

    async clearCompanyData (options = {}) {
        try {
            removeLocalStorageItem(SUBDOMAIN)

            removeLocalStorageItem(AUTH_COMPANY_ID)

            store.dispatch('clearAll', options)
        } catch (error) {
            throw error
        }
    }

    /**
     * Registers the user
     * @param {Object} data
     *
     * @returns {Promise}
     */
    async register (data) {
        try {
            return await axios.post(ENDPOINTS.REGISTER, data)
        } catch (error) {
            throw error
        }
    }

    /**
     * Verify the existence of the token
     * @param {Object} data
     *
     * @returns {Promise}
     */
    async verifyToken (data) {
        try {
            return await axios.post(ENDPOINTS.VERIFY_TOKEN, data)
        } catch (error) {
            throw error
        }
    }

    /**
     * Verify the existence of the token
     * @param {Object} data
     *
     * @returns {Promise}
     */
    async refreshToken (data) {
        try {
            return await axios.post(ENDPOINTS.REFRESH_TOKEN, data)
        } catch (error) {
            throw error
        }
    }

    /**
     * Registers the user
     * @param {Object} data
     *
     * @returns {Promise}
     */
    async verifyAccount (data) {
        try {
            return await axios.post(ENDPOINTS.VERIFY_ACCOUNT, data)
        } catch (error) {
            throw error
        }
    }

    /**
     * Sends a request to reset users password
     * @param {String} email
     *
     * @return {Promise}
     */
    async sendResetPasswordRequest (email) {
        try {
            return await axios.post(ENDPOINTS.PASSWORD_RESET_REQEUST, {
                email
            })
        } catch (error) {
            throw error
        }
    }

    /**
     * Resets password
     * @param {Object} data
     */
    async resetPassword (data) {
        try {
            return await axios.post(ENDPOINTS.PASSWORD_RESET, data)
        } catch (error) {
            throw error
        }
    }

    async changePassword (data) {
        try {
            const response = await axios.patch(ENDPOINTS.PASSWORD_CHANGE, data)

            return response.data
        } catch (error) {
            throw error
        }
    }

    /**
     * Gets users metadata (use_mfa, mfa_method, phone_number...)
     * @param {String} email
     */
    async getUserMetadata (email) {
        try {
            const response = await axios.get(
                `${ENDPOINTS.USER_METADATA}?email=${email}`
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    /**
     * Sets users metadata
     * @param {Object} data
     */
    async setUserMetadata (data) {
        try {
            const response = await axios.patch(ENDPOINTS.USER_METADATA, data)
            return response.data
        } catch (error) {
            throw error
        }
    }

    /**
     * Set a default axios header
     * @param {String} header
     * @param {String} value
     */
    setHeader (header, value) {
        assign(axios.defaults.headers, {
            [header]: value
        })
    }

    saveLoginRedirectPath (route) {
        if (!this.isLoggedIn()) {
            setLocalStorageItem(
                LOCAL_STORAGE_ITEMS.LOGIN_REDIRECT_PATH,
                route.path
            )
        }
    }

    redirectToForbidden (route = false) {
        if (route) {
            this.saveLoginRedirectPath(route)
        }

        router.replace(COMMON_PAGES.FORBIDDEN).catch(() => {})
    }

    setAuthResponseInterceptors () {
        axios.interceptors.response.use(
            response => {
                return response
            },
            error => {
                if (axios.isCancel(error)) {
                    return Promise.reject(STATUS_CODES.CANCELLED)
                } else if (
                    (error.response &&
                        [502, 503, 504].includes(error.response.status)) ||
                    typeof error.response === 'undefined'
                ) {
                    router.push(ROUTES_MAINTENANCE.BASE).catch(() => {})
                } else {
                    if (
                        error.response &&
                        error.response.config.url.includes('/risks')
                    ) {
                        return Promise.reject(error)
                    }

                    const { status } = error.response

                    switch (status) {
                        case STATUS_CODES.FORBIDDEN:
                            this.redirectToForbidden()
                            return Promise.reject(error)

                        case STATUS_CODES.NOT_FOUND:
                            router
                                .replace(
                                    this.getRedirectUrlOnNotFound(
                                        error.response.config?.url
                                    )
                                )
                                .catch(() => {})
                            return Promise.reject(error)

                        case STATUS_CODES.UNAUTHORIZED:
                            store.dispatch('account/logout')
                            router.replace(ROUTES_AUTH.LOGIN).catch(() => {})
                            return Promise.reject(error)

                        case STATUS_CODES.PAYMENT_REQUIRED:
                            router
                                .replace(COMMON_PAGES.PAYMENT_REQUIRED)
                                .catch(() => {})
                            return Promise.reject(error)

                        case STATUS_CODES.SHAREABLE_DOCUMENT_LINK_EXPIRED:
                            router
                                .replace(
                                    COMMON_PAGES.SHAREABLE_DOCUMENT_LINK_EXPIRED
                                )
                                .catch(() => {})
                            return Promise.reject(error)

                        case STATUS_CODES.INVITATION_ALREADY_ACCEPTED:
                            router
                                .replace(
                                    COMMON_PAGES.INVITATION_ALREADY_ACCEPTED
                                )
                                .catch(() => {})
                            return Promise.reject(error)

                        case STATUS_CODES.TOKEN_EXPIRED:
                            router
                                .replace(COMMON_PAGES.TOKEN_EXPIRED)
                                .catch(() => {})
                            return Promise.reject(error)

                        case STATUS_CODES.PRECONDITION_FAILED:
                            store.dispatch('account/logout')
                            router
                                .replace(this.getRedirectUrlOnGone())
                                .catch(() => {})
                            return Promise.reject(error)

                        default:
                            return Promise.reject(error)
                    }
                }
            }
        )
    }

    getRedirectUrlOnNotFound (url) {
        if (url && url.includes(regex)) {
            return {
                name: 'login',
                params: {
                    from: STATUS_CODES.NOT_FOUND
                }
            }
        }

        return COMMON_PAGES.NOT_FOUND
    }

    /**
     * Get has the user verified his email
     */
    async isUserEmailVerified () {
        try {
            const {
                data: { email_verified: isEmailVerified }
            } = await axios.get(ENDPOINTS.IS_EMAIL_VERIFIED)

            return isEmailVerified
        } catch (error) {
            throw error
        }
    }
}

export default new AuthService()
