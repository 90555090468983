const INTENTIONS = {
    CREATE: 'create',
    READ: 'read',
    EDIT: 'edit',
    DELETE: 'delete'
}

const REGISTER_OWNER = 'register_owner'
const REQUIREMENT_ASIGNEE = 'requirement_asignee'
const ROLE_ANY = '*'

const PERMISSION_MAP = {
    [INTENTIONS.READ]: [ROLE_ANY],
    [INTENTIONS.EDIT]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
    [INTENTIONS.CREATE]: [REGISTER_OWNER],
    [INTENTIONS.DELETE]: [REGISTER_OWNER]
}

const FIELD_PERMISSION_MAP = {
    type: {
        [INTENTIONS.READ]: [ROLE_ANY],
        [INTENTIONS.EDIT]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.CREATE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.DELETE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE]
    },
    valid_from: {
        [INTENTIONS.READ]: [ROLE_ANY],
        [INTENTIONS.EDIT]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.CREATE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.DELETE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE]
    },
    country_id: {
        [INTENTIONS.READ]: [ROLE_ANY],
        [INTENTIONS.EDIT]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.CREATE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.DELETE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE]
    },
    deadline: {
        [INTENTIONS.READ]: [ROLE_ANY],
        [INTENTIONS.EDIT]: [REGISTER_OWNER],
        [INTENTIONS.CREATE]: [REGISTER_OWNER],
        [INTENTIONS.DELETE]: [REGISTER_OWNER]
    },
    state_id: {
        [INTENTIONS.READ]: [ROLE_ANY],
        [INTENTIONS.EDIT]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.CREATE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.DELETE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE]
    },
    area_id: {
        [INTENTIONS.READ]: [ROLE_ANY],
        [INTENTIONS.EDIT]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.CREATE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.DELETE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE]
    },
    name: {
        [INTENTIONS.READ]: [ROLE_ANY],
        [INTENTIONS.EDIT]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.CREATE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.DELETE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE]
    },
    responsible_person_id: {
        [INTENTIONS.READ]: [ROLE_ANY],
        [INTENTIONS.EDIT]: [REGISTER_OWNER],
        [INTENTIONS.CREATE]: [REGISTER_OWNER],
        [INTENTIONS.DELETE]: [REGISTER_OWNER]
    },
    interested_party: {
        [INTENTIONS.READ]: [ROLE_ANY],
        [INTENTIONS.EDIT]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.CREATE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.DELETE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE]
    },
    link: {
        [INTENTIONS.READ]: [ROLE_ANY],
        [INTENTIONS.EDIT]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.CREATE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.DELETE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE]
    },
    description: {
        [INTENTIONS.READ]: [ROLE_ANY],
        [INTENTIONS.EDIT]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.CREATE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.DELETE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE]
    },
    is_compliant: {
        [INTENTIONS.READ]: [ROLE_ANY],
        [INTENTIONS.EDIT]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.CREATE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.DELETE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE]
    },
    date_of_the_document: {
        [INTENTIONS.READ]: [ROLE_ANY],
        [INTENTIONS.EDIT]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.CREATE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.DELETE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE]
    },
    prescribing_document: {
        [INTENTIONS.READ]: [ROLE_ANY],
        [INTENTIONS.EDIT]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.CREATE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE],
        [INTENTIONS.DELETE]: [REGISTER_OWNER, REQUIREMENT_ASIGNEE]
    }
}

export const REQUIREMENTS = {
    INTENTIONS,
    PERMISSION_MAP,
    FIELD_PERMISSION_MAP,
    REGISTER_OWNER,
    REQUIREMENT_ASIGNEE,
    ROLE_ANY
}
