<template>
    <div class="dw-editor">
        <document-heading :is-submittable="allInputsFilled" />
        <want-to-learn-more-modal :key="wtlmKey" />
        <div class="dw-editor__document-wrapper" id="document-wrapper">
            <document-mini-map
                v-if="miniMapIsVisible"
                :form="form"
                :inputs="inputs"
                :question="question"
                :is-active="!isCustomModeEnabled"
            />

            <div class="dw-editor__document" id="document">
                <component
                    v-if="keys(form).length"
                    ref="document"
                    id="document-content"
                    class="dw-editor__document-content"
                    :is="parser"
                    v-bind="form"
                    :inputs="inputs"
                    :document="document"
                    :statuses="statuses"
                    :form="form"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { keys, each, find } from 'lodash-es'
import DocumentMiniMap from '@/components/document-wizard/DocumentMiniMap'
import DocumentHeading from '@/components/wizards/DocumentHeading'
import ParsedInput from '@/components/regulation/inputs/template/ParsedInput'
import ParsedCustomParagraph from '@/components/regulation/inputs/template/ParsedCustomParagraph'
import AppDocumentHistoryItem from '@/components/document-wizard/AppDocumentHistoryItem'
import { documents } from '@/mixins'
import { shortcode } from '@/filters'
import { EventBus } from '@/EventBus'
import {
    PARAGRAPH_HOVER_CLASSES,
    BASE_CLASSES,
    DOCUMENT_STATUSES,
    DOCUMENT_WIZARD_ROUTES,
    COMMENT_HIGHLIGHT_CLASSES,
    INPUTS_REGEX,
    CUSTOM_PARAGRAPHS_REGEX,
    DOCUMENT_HISTORY_REGEX
} from '@/constants'
import {
    checkIfDependingOnIsFulfilled,
    getDependingOnIfExist
} from '@/services/utils/documents'
import WantToLearnMoreModal from '@/components/modals/wizards/WantToLearnMoreModal'
import { v4 as uuidv4 } from 'uuid'
import { DOCUMENT_WIZARD_MODES } from '@/constants/documents'
import { BASE_HIGHLIGHT_CLASS } from '../../constants/documents'
import { mapActions } from 'vuex'

export default {
    mixins: [documents],

    components: {
        DocumentMiniMap,
        DocumentHeading,
        WantToLearnMoreModal,
        AppDocumentHistoryItem
    },

    created () {
        this.statuses = DOCUMENT_STATUSES

        // Events
        EventBus.$on('mode-changed', this.handleModeChange)
        EventBus.$on('custom-paragraph-state', this.updateCustomParagraphState)
    },

    beforeDestroy () {
        each(keys(this.form), key => {
            const element = this.getHighlightableElement(key, this.inputs)

            if (element && element.removeEventListner) {
                element.removeEventListner(
                    'mouseleave',
                    this.removeHoverableClass
                )
                element.removeEventListner('mouseenter', this.addHoverableClass)
                element.removeEventListner('click', this.focusClickedElement)
            }
        })

        this.getCommentableElements().forEach(element => {
            element.removeEventListner('mouseenter', this.addHoverableClass)
            element.removeEventListner('mouseleave', this.removeHoverableClass)
        })

        this.unhighlightAllComments()

        // Events
        EventBus.$off('mode-changed', this.handleModeChange)
        EventBus.$off('custom-paragraph-state', this.updateCustomParagraphState)
    },

    data () {
        return {
            commentableTooltips: [],
            customParagraphDetails: [],
            wtlmKey: ''
        }
    },

    beforeUpdate () {
        this.wtlmKey = uuidv4()
    },

    mounted () {
        this.setUpListeners()
    },

    props: {
        form: {
            type: Object,
            required: false,
            default: () => ({})
        },

        template: {
            type: String,
            required: false,
            default: ''
        },

        inputs: {
            type: Array,
            required: false,
            default: () => []
        },

        visibleInputs: {
            type: Array,
            required: false,
            default: () => []
        },

        discussions: {
            type: Array,
            required: false,
            default: () => []
        },

        question: {
            type: [String, Number],
            required: false,
            default: 0
        },

        customInputs: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    computed: {
        miniMapIsVisible () {
            return this.$route.name === DOCUMENT_WIZARD_ROUTES.WIZARD.name
        },

        parser () {
            let documentTemplate = '<div>' + this.template + '</div>'

            documentTemplate = this.replaceInputs(documentTemplate)
            documentTemplate = this.replaceCustomParagraphs(documentTemplate)
            documentTemplate = this.replaceDocumentHistory(documentTemplate)

            const topElementDetails = this.findDocumentTopElementDetails()

            return {
                template: documentTemplate,
                props: [
                    ...this.inputs.map(input => input.name),
                    ...keys(this.form),
                    'inputs',
                    'document',
                    'statuses',
                    'form',
                    'current'
                ],
                filters: { shortcode, parent: value => value },
                components: {
                    ParsedInput,
                    ParsedCustomParagraph,
                    AppDocumentHistoryItem
                },
                mounted: () => {
                    this.toggleHiglightContainer()

                    // Keep document scrolling position after expanding document content with custom paragraphs
                    topElementDetails &&
                        this.scrollToDocumentTopElement(
                            topElementDetails.index,
                            topElementDetails.top
                        )
                }
            }
        },

        allInputsFilled () {
            return keys(this.form).reduce((accumulator, current) => {
                const input = this.inputs.find(i => i.name === current)
                if (input && !input.required) {
                    return true
                }
                const dependingOn = getDependingOnIfExist(this.inputs, current)
                if (dependingOn) {
                    return checkIfDependingOnIsFulfilled(
                        dependingOn,
                        this.form
                    ) && !this.form[current]
                        ? false
                        : accumulator
                }

                return !this.form[current] ? false : accumulator
            }, true)
        }
    },

    methods: {
        keys,

        ...mapActions('confirmModal', [
            'openConfirmModal',
            'closeConfirmModal'
        ]),

        addComment (key) {
            this.$emit('trigger-comment-add', key)
        },

        setUpListeners () {
            this.$nextTick(() => {
                this.setUpBaseHighlightableClasses()

                if (this.documentIsCommentable) {
                    this.setUpCommentableListeners()
                    this.setUpOnHoverListeners()
                    this.setUpOnHoverLeaveListeners()
                    this.setUpCommentableTooltips()
                }

                if (this.documentIsEditable) {
                    this.setUpOnClickListeners()
                }

                this.$route.name === DOCUMENT_WIZARD_ROUTES.DISCUSSIONS.name &&
                    this.highlightAllComments()
            })
        },

        setUpBaseHighlightableClasses () {
            each(keys(this.form), key => {
                const element = this.getHighlightableElement(key, this.inputs)
                this.attachClassesToElement(element, BASE_CLASSES)
            })
        },

        setUpCommentableTooltips () {
            let commentableIds = []

            this.getCommentableElements().forEach(element => {
                commentableIds = [...commentableIds, element.id]
            })

            this.commentableTooltips = [...keys(this.form), ...commentableIds]
        },

        highlightAllComments () {
            this.discussions.forEach(comment => {
                const element = this.getHighlightableElement(
                    comment.reference_id,
                    this.inputs
                )
                this.attachClassesToElement(element, BASE_CLASSES)
                this.attachClassesToElement(element, COMMENT_HIGHLIGHT_CLASSES)
            })
        },

        unhighlightAllComments () {
            this.discussions.forEach(comment => {
                const element = this.getHighlightableElement(
                    comment.reference_id,
                    this.inputs
                )
                this.removeClassesFromElement(
                    element,
                    COMMENT_HIGHLIGHT_CLASSES
                )
            })
        },

        setUpEventListeners (eventName, callback, ignoreParents = false) {
            each(keys(this.form), key => {
                const element = this.getHighlightableElement(
                    key,
                    ignoreParents ? [] : this.inputs
                )
                element && element.addEventListener(eventName, callback)
            })
        },

        setUpOnHoverListeners (event) {
            this.setUpEventListeners('mouseenter', this.addHoverableClass)
        },

        setUpOnHoverLeaveListeners (event) {
            this.setUpEventListeners('mouseleave', this.removeHoverableClass)
        },

        setUpCommentableListeners () {
            this.setUpCommentableHoverListeners()
            this.setUpCommentableHoverLeaveListeners()
        },

        setUpCommentableHoverListeners () {
            this.getCommentableElements().forEach(commentable => {
                this.attachClassesToElement(commentable, BASE_CLASSES)
                commentable.addEventListener(
                    'mouseenter',
                    this.addHoverableClass
                )
            })
        },

        setUpCommentableHoverLeaveListeners () {
            this.getCommentableElements().forEach(commentable => {
                commentable.addEventListener(
                    'mouseleave',
                    this.removeHoverableClass
                )
            })
        },

        addHoverableClass (event) {
            this.attachClassesToElement(event.target, PARAGRAPH_HOVER_CLASSES)
        },

        removeHoverableClass (event) {
            this.removeClassesFromElement(event.target, PARAGRAPH_HOVER_CLASSES)
        },

        setUpOnClickListeners (event) {
            this.setUpEventListeners('click', this.focusClickedElement, true)
        },

        focusClickedElement (event) {
            this.$emit('input-focused', event.currentTarget.id)
        },

        shouldShowDependingInput (dependingInputName, dependingInputValue) {
            return this.form[dependingInputName] == dependingInputValue // eslint-disable-line
        },

        setListenerToCommentableParagraph () {
            const that = this
            this.commentableTooltips.forEach(item => {
                const commentableItem = document.getElementById(item)
                if (commentableItem) {
                    commentableItem.addEventListener('click', function () {
                        that.addComment(item)
                    })
                }
            })
        },

        replaceInputs (template) {
            return template.replace(
                INPUTS_REGEX,
                `<parsed-input
                    :expression="$1"
                    :input-value="$2"
                    input-name="$2"
                    :inputs="inputs"
                    :disabled="document.status !== statuses.IN_PROGRESS"
                    :approved="document.status === statuses.COMPLETED"
                    :form="this.form"
                />`
            )
        },

        toBase64 (string) {
            return btoa(unescape(encodeURIComponent(string)))
        },

        replaceCustomParagraphs (template) {
            const replaced = template.replace(
                CUSTOM_PARAGRAPHS_REGEX,
                (match, inputName) => {
                    // Find custom input by its name
                    const customInput = this.getCustomInput(inputName) ?? {}

                    // Set input value
                    const value = this.toBase64(customInput?.value ?? '')

                    // Check if unsaved value exists
                    const customParagraph =
                        this.getCustomParagraphDetails(inputName) ?? {}

                    const unsavedValue = this.toBase64(
                        customParagraph?.unsavedValue ?? ''
                    )

                    const initialAction = customParagraph?.initialAction ?? ''

                    return `<parsed-custom-paragraph
                        input-name="${inputName}"
                        encoded-initial-value="${value}"
                        encoded-unsaved-value="${unsavedValue}"
                        initial-action="${initialAction}"
                        :is-custom-mode-available="${this.isCustomModeAvailable}"
                        :is-custom-mode-active="${this.isCustomModeActive}"
                        :is-document-editable="${this.documentIsEditable}"
                        :document-status="document.status"
                    />`
                }
            )

            return replaced
        },

        getCustomInput (name) {
            return find(this.customInputs, {
                name: name
            })
        },

        replaceDocumentHistory (template) {
            if (this.document.status !== this.statuses.IN_PROGRESS) {
                return template.replace(DOCUMENT_HISTORY_REGEX, '')
            }
            return template.replace(
                DOCUMENT_HISTORY_REGEX,
                `<AppDocumentHistoryItem :history-id="$1"></AppDocumentHistoryItem>`
            )
        },

        /**
         * Check if there is at least one paragraph whose changes aren't saved
         */
        findUnsavedCustomParagraphs () {
            return this.$refs?.document?.$children.find(
                component => component.isCustomParagraph && component.isUnsaved
            )
        },

        renderedCustomParagraphs () {
            return this.$refs?.document?.$children.filter(
                component => component.isCustomParagraph
            )
        },

        /**
         * Change between custom and default mode
         */
        handleModeChange (value) {
            // If exiting from custom mode check if there is any unsaved custom paragraph
            if (!value && this.findUnsavedCustomParagraphs()) {
                return this.openConfirmModal({
                    title: this.$t(
                        'DOCUMENT_WIZARD.CUSTOM_PARAGRAPH.UNSAVED_CHANGES_MODAL.TITLE'
                    ),
                    content: this.$t(
                        'DOCUMENT_WIZARD.CUSTOM_PARAGRAPH.UNSAVED_CHANGES_MODAL.DESCRIPTION'
                    ),
                    confirmActionLabel: this.$t('COMMON.DISCARD_CHANGES'),
                    confirmActionVariation: 'danger',
                    confirmHandler: this.handleDiscardChanges
                })
            }

            // Execute mode change
            this.changeCustomMode(value)
        },

        /**
         * Enable or disable custom mode
         */
        changeCustomMode (value) {
            // Clear custom mode state (details about unsaved changes and action)
            if (!value) {
                this.clearCustomParagraphDetails()
            }

            // Change route
            this.$router
                .push({
                    ...(value && DOCUMENT_WIZARD_ROUTES.WIZARD),
                    query: {
                        ...this.$route.query,
                        mode: value ? DOCUMENT_WIZARD_MODES.CUSTOM : undefined
                    }
                })
                .catch(() => {})

            // When custom mode isn't available, document won't be re-rendered,
            // so we need to manually display highlight container
            if (!this.isCustomModeAvailable) {
                this.toggleHiglightContainer()
            }
        },

        /**
         *
         */
        handleDiscardChanges () {
            // Close modal
            this.closeConfirmModal()

            // Exit from custom mode
            this.changeCustomMode(false)
        },

        toggleHiglightContainer () {
            if (this.isCustomModeEnabled) {
                this.$nextTick(() => {
                    this.attachClassesToElement(this.getHighlightContainer(), [
                        BASE_HIGHLIGHT_CLASS
                    ])
                })
            } else {
                this.removeClassesFromElement(this.getHighlightContainer(), [
                    BASE_HIGHLIGHT_CLASS
                ])
            }
        },

        /**
         * Returns list (HTMLCollection) of all document subelements (headings, paragraphs...)
         */
        getDocumentSubelements () {
            return document.querySelector('#document-content .document-content')
                .children
        },

        /**
         * Find out which of document's subelement is at top of the document view
         */
        findDocumentTopElementDetails () {
            const documentWrapper = document.getElementById('document-wrapper')

            if (!documentWrapper) {
                return
            }

            // Find out document's wrapper top position (starting point of the document)
            const documentWrapperTop = documentWrapper.getBoundingClientRect()
                .top

            // Gell document's subelements
            const subelements = this.getDocumentSubelements()

            if (subelements.length) {
                // Go through all subelements (headings, chapters...)
                // and find which element is at the top of document view
                for (var i = 0; i < subelements.length; i++) {
                    const element = subelements[i]

                    // Find out element's height and top position
                    const elementDOMRect = element.getBoundingClientRect()

                    // Also consider top margin
                    const topMargin = parseFloat(
                        getComputedStyle(element).marginTop
                    )

                    // Check if element is at the top of the document view
                    if (
                        elementDOMRect.top +
                            topMargin +
                            elementDOMRect.height >=
                        documentWrapperTop
                    ) {
                        return {
                            index: i,
                            top: elementDOMRect.top
                        }
                    }
                }
            }
        },

        /**
         * Scroll to element which was at the top of the document before changing modes
         */
        scrollToDocumentTopElement (index, exactTopPosition) {
            // Get all subelements
            const subelements = this.getDocumentSubelements()

            // Determine top element by given index
            const element = subelements[index]

            // Calculate difference between previous and current top position
            const topDifference = Math.round(
                exactTopPosition - element.getBoundingClientRect().top
            )

            // Scroll by calculated top difference
            element &&
                document.getElementById('document').scrollBy(0, -topDifference)
        },

        updateCustomParagraphState (inputName, details) {
            this.customParagraphDetails[inputName] = details
        },

        getCustomParagraphDetails (inputName) {
            return this.customParagraphDetails[inputName] ?? undefined
        },

        clearCustomParagraphDetails () {
            this.customParagraphDetails = []
        }
    },

    watch: {
        $route (route) {
            this.setUpListeners()

            route.name !== DOCUMENT_WIZARD_ROUTES.DISCUSSIONS.name
                ? this.unhighlightAllComments()
                : this.highlightAllComments()
        },

        question: {
            handler (newValue, oldValue) {
                const currentInput = this.inputs[newValue]

                const currentInputElements = [
                    ...document.getElementsByClassName(
                        `input-${currentInput.shortcode}`
                    )
                ]

                currentInputElements.forEach((element, index, array) => {
                    this.attachClassesToElement(element, [
                        'cf-u-bg-primary-light'
                    ])
                })

                const previousInput = this.inputs[oldValue]
                const previousInputElements = [
                    ...document.getElementsByClassName(
                        `input-${previousInput.shortcode}`
                    )
                ]

                previousInputElements.forEach(element => {
                    this.removeClassesFromElement(element, [
                        'cf-u-bg-primary-light'
                    ])
                })
            }
        },

        commentableTooltips (commentableParagraps) {
            if (commentableParagraps && this.documentIsCommentable) {
                this.setListenerToCommentableParagraph()
            }
        }
    }
}
</script>
