const ROUTES_DOCUMENT_WIZARD = {
    BASE: {
        name: 'doc-wizard'
    },
    WIZARD: {
        name: 'doc-wizard.wizard'
    },
    TEMPLATE: {
        name: 'doc-wizard.create-template'
    }
}

const NEED_HELP_URL =
    'https://help.advisera.com/hc/en-us/sections/10119033434140-Conformio-Support-Articles'

const DOCUMENT_STATUSES = {
    IN_PROGRESS: 'in_progress',
    IN_REVIEW: 'in_review',
    IN_APPROVAL: 'in_approval',
    COMPLETED: 'completed',
    CORRECTIONS_NEEDED: 'corrections_needed',
    WITHDRAWN: 'withdrawn'
}

const APPROVERS_NAME = 'approvers'
const REVIEWERS_NAME = 'reviewers'
const READERS_NAME = 'readers'
const OWNER_NAME = 'owner'
const INTERVAL_NAME = 'update_interval'

const PROPERTIES_SORT = {
    [OWNER_NAME]: 1,
    [INTERVAL_NAME]: 2,
    [REVIEWERS_NAME]: 3,
    [APPROVERS_NAME]: 4,
    [READERS_NAME]: 5
}

const DOCUMENT_STATUSES_MAPPED = {
    [DOCUMENT_STATUSES.IN_PROGRESS]: {
        backgroundColorClass: 'background-primary-epsilon',
        label: 'REGULATION.IN_PROGRESS',
        icon: 'icon-refresh-circle',
        isOptional: false,
        showInTimeline: true
    },
    [DOCUMENT_STATUSES.IN_REVIEW]: {
        backgroundColorClass: 'background-warning-alpha',
        label: 'REGULATION.IN_REVIEW',
        icon: 'icon-point-circle',
        isOptional: true,
        showInTimeline: true
    },
    [DOCUMENT_STATUSES.IN_APPROVAL]: {
        backgroundColorClass: 'background-warning-gamma',
        label: 'REGULATION.IN_APPROVAL',
        icon: 'icon-approve-circle',
        isOptional: false,
        showInTimeline: true
    },
    [DOCUMENT_STATUSES.CORRECTIONS_NEEDED]: {
        backgroundColorClass: 'background-danger-alpha',
        label: 'REGULATION.CORRECTIONS_NEEDED',
        icon: 'icon-exclamation-circle',
        isOptional: true,
        showInTimeline: true
    },
    [DOCUMENT_STATUSES.COMPLETED]: {
        backgroundColorClass: 'background-success-alpha',
        label: 'REGULATION.APPROVED',
        icon: 'icon-check-circle',
        isOptional: false,
        showInTimeline: true
    },
    [DOCUMENT_STATUSES.WITHDRAWN]: {
        backgroundColorClass: 'background-gray-beta',
        label: 'REGULATION.WITHDRAWN',
        icon: 'icon-arrow-down-circle',
        isOptional: false,
        showInTimeline: false
    }
}

const DOWNLOAD_TYPES = {
    PDF: 'pdf',
    WORD: 'word'
}

const DOCUMENTABLE_TYPES = {
    PDF: 'application/pdf'
}

export {
    ROUTES_DOCUMENT_WIZARD,
    NEED_HELP_URL,
    DOCUMENT_STATUSES,
    DOCUMENT_STATUSES_MAPPED,
    APPROVERS_NAME,
    REVIEWERS_NAME,
    READERS_NAME,
    OWNER_NAME,
    INTERVAL_NAME,
    PROPERTIES_SORT,
    DOWNLOAD_TYPES,
    DOCUMENTABLE_TYPES
}
