<template>
    <div :class="getStyles">
        <div class="cf-c-sortable-th__ctn" @click="$emit('sort-toggled')">
            <div class="cf-c-sortable-th__label">
                {{ label }}
            </div>
            <!--  TODO: implement icon behavior -->
            <app-icon
                v-if="hasIcon"
                class="cf-c-sortable-th__icon"
                icon="sort-arrow-icon"
                size="xs"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },

        hasIcon: {
            type: Boolean,
            required: false,
            default: false
        },

        textAlign: {
            type: String,
            required: false,
            default: ''
        }
    },

    computed: {
        getStyles () {
            let styles = 'cf-c-sortable-th'
            if (this.textAlign === 'center') {
                styles += ' cf-c-sortable-th--text-align-center'
            }
            if (this.hasIcon) {
                styles += ' cf-c-sortable-th--has-icon'
            }
            return styles
        }
    }
}
</script>
