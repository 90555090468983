import { ROUTES_MAINTENANCE } from './constants'

export default {
    path: '/maintenance',
    name: ROUTES_MAINTENANCE.BASE.name,
    component: () => import('./ModuleMaintenance'),
    meta: {
        pageTitle: 'ERRORS.MAINTENANCE_TITLE'
    }
}
