import {
    REGISTER_OF_INTERNAL_AUDITS,
    REGISTER_OF_REQUIREMENTS
} from '@/constants'
const { DOCUMENTATION_REVIEW_ITEM_TYPES } = REGISTER_OF_INTERNAL_AUDITS

export function getRequirementTitle (item) {
    return item.type === REGISTER_OF_REQUIREMENTS.CONTRACTUAL_TYPE
        ? item.prescribing_document
        : item.law.name
}

export function getItemTitle (item) {
    switch (item.referenceable_type) {
        case DOCUMENTATION_REVIEW_ITEM_TYPES.DOCUMENT:
            return item.referenceable.name

        case DOCUMENTATION_REVIEW_ITEM_TYPES.CLAUSE:
            return item.referenceable.title

        case DOCUMENTATION_REVIEW_ITEM_TYPES.REQUIREMENT:
            return getRequirementTitle(item.referenceable)

        default:
            return ''
    }
}
