const MY_PERMISSIONS = {
    IMPLEMENTATION_STEPS_ACCESS: 'conformio_implementation_steps_access',
    STEP_DETAILS_ACCESS: 'conformio_step_details_access',
    DOCUMENT_WIZARD_ACCESS: 'conformio_document_wizard_access',
    AUDIT_AND_EVIDENCE_ACCESS: 'conformio_audit_and_evidence_access',
    AUDIT_AND_EVIDENCE_VIEW_CLAUSES_STATUS:
        'conformio_audit_and_evidence_view_clauses_status',
    AUDIT_AND_EVIDENCE_GO_TO_ACTIONS:
        'conformio_audit_and_evidence_go_to_actions',
    AUDIT_AND_EVIDENCE_DELETE_EVIDENCES:
        'conformio_audit_and_evidence_delete_evidence',
    MAINTENANCE_ACCESS: 'conformio_maintenance_access',
    DOCUMENT_EXPLORER_ACCESS: 'conformio_document_explorer_access',
    SECURITY_OBJECTIVES_ACCESS: 'conformio_security_objectives_access',
    SECURITY_OBJECTIVES_EDIT: 'conformio_security_objectives_edit',
    REGISTER_OF_REQUIREMENTS_ACCESS:
        'conformio_register_of_requirements_access',
    REGISTER_OF_REQUIREMENTS_EDIT: 'conformio_register_of_requirements_edit',
    RISK_REGISTER_ACCESS: 'conformio_risk_register_access',
    RISK_REGISTER_EDIT: 'conformio_risk_register_edit',
    SOA_ACCESS: 'conformio_soa_access',
    SOA_EDIT: 'conformio_soa_edit',
    TRAINING_MODULE_ACCESS: 'conformio_training_module_access',
    TRAINING_MODULE_EDIT: 'conformio_training_module_edit',
    INCIDENT_REGISTER_ACCESS: 'conformio_incident_register_access',
    INCIDENT_REGISTER_EDIT: 'conformio_incident_register_edit',
    NONCONFORMITY_REGISTER_ACCESS: 'conformio_nonconformity_register_access',
    NONCONFORMITY_REGISTER_EDIT: 'conformio_nonconformity_register_edit',
    INTERNAL_AUDIT_ACCESS: 'conformio_internal_audit_access',
    INTERNAL_AUDIT_EDIT: 'conformio_internal_audit_edit',
    MANAGEMENT_REVIEW_ACCESS: 'conformio_management_review_access',
    REPORTING_DASHBOARD_ACCESS: 'conformio_reporting_dashboard_access',
    REPORTING_DASHBOARD_EDIT: 'conformio_reporting_dashboard_edit',
    PROJECT_STATUS_ACCESS: 'conformio_project_status_access',
    COMPLIANCE_DASHBOARD_ACCESS: 'conformio_compliance_dashboard_access',
    PERFORMANCE_DASHBOARD_ACCESS: 'conformio_performance_dashboard_access',
    RESPONSIBILITY_MATRIX_ACCESS: 'conformio_responsibility_matrix_access',
    MY_WORK_ACCESS: 'conformio_my_work_access',
    TRAINING_ACCESS: 'conformio_training_access',
    USER_MANAGEMENT_ACCESS: 'conformio_user_management_access',
    SUBSCRIPTIONS_ACCESS: 'conformio_subscriptions_access',
    BASIC_INFORMATION_ACCESS: 'conformio_basic_information_access',
    SECURITY_ACCESS: 'conformio_security_access',
    PROJECT_SETTINGS_ACCESS: 'conformio_project_settings_access',
    INTEGRATIONS_ACCESS: 'conformio_integrations_access',
    REGISTER_AND_MODULES_ACCESS: 'conformio_register_and_modules_access'
}

const REGISTERS_PERMISSIONS = {
    REGISTER_OF_REQUIREMENTS: 'requirements',
    RISK_REGISTER: 'risks',
    STATEMENT_OF_APPLICABILITY: 'statement_of_applicability',
    TRAININGS: 'trainings',
    SECURITY_OBJECTIVES: 'security_objectives',
    SETUP_MANAGEMENT_REVIEW: 'setup_management_review',
    AUDIT_REGISTER: 'audits',
    NONCONFORMITY_REGISTER: 'nonconformities',
    INCIDENT_REGISTER: 'incidents',
    MANAGEMENT_REVIEWS: 'management_reviews'
}

export { MY_PERMISSIONS, REGISTERS_PERMISSIONS }
