const ROUTES_AUTH = {
    LOGIN: {
        name: 'login'
    },
    PASSWORD_RESET: {
        name: 'password-reset'
    },
    PASSWORD_CHANGE: {
        name: 'change-password'
    }
}

export { ROUTES_AUTH }
