<template>
    <div>
        <div class="cf-c-step-bar">
            <div
                v-for="(step, index) in steps"
                :key="index"
                :class="[
                    'cf-c-step-bar__item',
                    { 'cf-is-active': activeStep === index }
                ]"
            >
                <div class="cf-c-step-bar__progress">
                    <div class="cf-c-step-bar__line"></div>
                    <div class="cf-c-step-bar__dot"></div>
                </div>
            </div>
        </div>
        <div class="cf-u-text-center cf-u-mb-md cf-u-mt-sm">
            <strong
                >{{
                    `${$t('REGULATION.STEP')} ${activeStep + 1}: ${$t(
                        steps[activeStep].label
                    )}`
                }}
            </strong>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        steps: {
            type: Array,
            required: false,
            default: () => []
        },

        activeStep: {
            type: Number,
            required: false,
            default: 0
        },

        onlyActiveLabel: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        shouldShowLabel (index) {
            return !this.onlyActiveLabel || this.activeStep === index
        }
    }
}
</script>
