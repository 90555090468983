import axios from 'axios'
import { isArray } from 'lodash-es'

const ENDPOINTS = {
    EXPLORER: 'dms/companies/:companyId/explore',
    DMS_FILE_DELETE: 'companies/:companyId/:documentType/:documentId',
    DMS_FOLDER_DELETE: 'dms/companies/:companyId/folders/:folderId?path=:path',
    SET_FOLDER_PERMISSIONS:
        'dms/companies/:companyId/folder/:folderId/permissions',
    DMS_FOLDER_CREATE: 'dms/companies/:companyId/folders',
    DMS_FILE_MOVE: 'dms/companies/:companyId/move-file',
    DMS_FILE_SHARE: 'dms/companies/:companyId/share-file',
    DMS_FOLDER_MOVE: 'dms/companies/:companyId/move-folder',
    DMS_FILE_COPY: 'dms/companies/:companyId/copy-file',
    DMS_FOLDER_COPY: 'dms/companies/:companyId/copy-folder',
    DMS_FILE_RENAME: 'dms/companies/:companyId/rename-file',
    DMS_FOLDER_RENAME: 'dms/companies/:companyId/rename-folder'
}

class DmsService {
    async getFilesAndFoldersAtPath (companyId, path = null) {
        const structuredPath = this.getStructuredPath(path)
        try {
            const { data } = await axios.get(
                this.getDmsExplorerRoute(companyId, structuredPath)
            )

            return data
        } catch (err) {
            throw err
        }
    }

    getStructuredPath (path) {
        return isArray(path) ? path.join(',') : null
    }

    getDmsExplorerRoute (companyId, path) {
        const basePath = ENDPOINTS.EXPLORER.replace(':companyId', companyId)

        return !path ? basePath : basePath + '?path=' + encodeURIComponent(path)
    }

    async deleteFileFromDms (companyId, documentId, documentType) {
        try {
            const { data } = await axios.delete(
                this.getDeleteFileRoute(companyId, documentId, documentType)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    async deleteFolderFromDms (companyId, folderId, path) {
        try {
            const { data } = await axios.delete(
                this.getDeleteFolderRoute(companyId, folderId, path)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    getDeleteFileRoute (companyId, documentId, documentType) {
        return ENDPOINTS.DMS_FILE_DELETE.replace(':companyId', companyId)
            .replace(':documentType', documentType)
            .replace(':documentId', documentId)
    }

    getDeleteFolderRoute (companyId, folderId, path) {
        return ENDPOINTS.DMS_FOLDER_DELETE.replace(':companyId', companyId)
            .replace(':folderId', folderId)
            .replace(':path', encodeURIComponent(path))
    }

    async setPermissions (companyId, folderId, payload) {
        try {
            const route = ENDPOINTS.SET_FOLDER_PERMISSIONS.replace(
                ':companyId',
                companyId
            ).replace(':folderId', folderId)

            const { data } = await axios.post(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    async createFolderToDms (companyId, payload) {
        try {
            const route = ENDPOINTS.DMS_FOLDER_CREATE.replace(
                ':companyId',
                companyId
            )
            const { data } = await axios.post(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    async moveFile (companyId, payload) {
        try {
            const route = ENDPOINTS.DMS_FILE_MOVE.replace(
                ':companyId',
                companyId
            )
            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    async moveFolder (companyId, payload) {
        try {
            const route = ENDPOINTS.DMS_FOLDER_MOVE.replace(
                ':companyId',
                companyId
            )
            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    async copyFile (companyId, payload) {
        try {
            const route = ENDPOINTS.DMS_FILE_COPY.replace(
                ':companyId',
                companyId
            )
            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    async copyFolder (companyId, payload) {
        try {
            const route = ENDPOINTS.DMS_FOLDER_COPY.replace(
                ':companyId',
                companyId
            )
            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    async renameFile (companyId, payload) {
        try {
            const route = ENDPOINTS.DMS_FILE_RENAME.replace(
                ':companyId',
                companyId
            )
            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    async shareFile (companyId, payload) {
        try {
            const route = ENDPOINTS.DMS_FILE_SHARE.replace(
                ':companyId',
                companyId
            )
            const { data } = await axios.post(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    async renameFolder (companyId, payload) {
        try {
            const route = ENDPOINTS.DMS_FOLDER_RENAME.replace(
                ':companyId',
                companyId
            )
            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const dmsService = new DmsService()
