<template>
    <app-modal
        min-width
        :name="modalName"
        :title="title"
        :ok-title="okTitle"
        :cancel-title="cancelTitle"
        :click-to-close="false"
        :custom-cancel-callback="cancelCallback"
        :error-message="firstError('name')"
        class-name="cf-c-app-modal--stepper"
        @ok="handleOkClick"
        @before-open="$emit('before-open', $event)"
        @closed="$emit('closed')"
    >
        <template slot="body">
            <step-bar
                :steps="steps"
                :active-step="activeStepIndex"
                only-active-label
            />
            <slot name="content"></slot>
        </template>
    </app-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StepBar from '@/components/shared/StepperModal/StepBar'

export default {
    components: {
        StepBar
    },

    props: {
        title: {
            type: String,
            required: true
        },

        steps: {
            type: Array,
            required: true
        },

        modalName: {
            type: String,
            required: true
        },

        activeStepIndex: {
            type: Number,
            required: true
        },

        submitButtonText: {
            type: String,
            required: true
        }
    },

    computed: {
        ...mapGetters('errors', ['firstError']),

        okTitle () {
            return this.isLastStep ? this.submitButtonText : 'COMMON.NEXT'
        },

        cancelTitle () {
            return this.isLastStep ? 'COMMON.GO_BACK' : 'COMMON.CANCEL'
        },

        isLastStep () {
            return this.activeStepIndex === this.steps.length - 1
        }
    },

    methods: {
        ...mapActions('errors', ['clearErrors']),

        handleSubmit () {
            this.$emit('ok')
        },

        handleNext () {
            this.$emit('next-clicked')
        },

        handleBack () {
            this.$emit('update:activeStepIndex', this.activeStepIndex - 1)
        },

        cancelCallback () {
            if (this.activeStepIndex === 0) {
                this.$emit('cancel')
                return this.$modal.hide(this.modalName)
            }
            this.handleBack()
        },

        handleOkClick () {
            this.isLastStep ? this.handleSubmit() : this.handleNext()
        }
    }
}
</script>
