import { dayDifference } from '@/services/utils/date'

export function subscribtionDaysLeftCounter (daysLeft, pricingPlanExpires) {
    let startDate = new Date().getDate()
    let endDate = new Date(pricingPlanExpires).getDate()

    if (startDate !== endDate) {
        return daysLeft + 1
    } else {
        return daysLeft
    }
}

export function expireDays (pricingPlan) {
    if (!pricingPlan) {
        return '-'
    }

    return dayDifference(new Date(), pricingPlan.expires)
}
