<template>
    <app-button variation="clear" @click="removeItem" size="sm">
        <app-icon class="cf-u-color-danger" icon="delete-icon" />
    </app-button>
</template>

<script>
import { documentService } from '@/services/api/document'
import { mapGetters } from 'vuex'

export default {
    props: {
        historyId: {
            type: [Number],
            required: true,
            default: ''
        }
    },

    computed: {
        ...mapGetters('company', ['company']),
        ...mapGetters('regulation', ['currentRegulation'])
    },

    methods: {
        async removeItem () {
            await documentService.hideDocumentHistory({
                companyId: this.company.id,
                regulationId: this.currentRegulation.id,
                historyId: this.historyId
            })
            this.hide()
        },

        hide () {
            document
                .getElementById('document-history-row-' + this.historyId)
                .setAttribute('style', 'display: none;')
        }
    }
}
</script>
