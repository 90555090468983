import {
    REGISTER_WIZARD_ROUTES,
    REGISTER_TYPES,
    MY_PERMISSIONS,
    REGISTERS_PERMISSIONS
} from '@/constants'
import { myPermissionsGuard } from '@src/router/guards'

export default {
    name: REGISTER_WIZARD_ROUTES.CHECKLIST_ADAPTATION.INDEX.name,
    component: () =>
        import(
            '@/containers/RegisterWizard/InternalAudits/ChecklistAdaptation'
        ),
    path: 'internal-audits/:auditId/checklist-adaptation',
    props: true,
    redirect: to => ({
        name: REGISTER_WIZARD_ROUTES.CHECKLIST_ADAPTATION.WIZARD.name,
        params: { ...to.params }
    }),
    meta: {
        requiresAuth: true,
        register: REGISTER_TYPES.INTERNAL_AUDITS
    },
    children: [
        {
            path: 'wizard',
            name: REGISTER_WIZARD_ROUTES.CHECKLIST_ADAPTATION.WIZARD.name,
            component: () =>
                import(
                    '@/components/registers/internal-audits/checklist-adaptation/WizardTab'
                ),
            props: true,
            meta: {
                pageTitle:
                    'REGISTERS.INTERNAL_AUDITS.CHECKLIST_ADAPTATION.HEADING_TITLE',
                withScaling: true,
                requiresAuth: true,
                register: REGISTER_TYPES.INTERNAL_AUDITS,
                shouldSkipRouteInHistory: true,
                guards: [myPermissionsGuard],
                permissions: [
                    MY_PERMISSIONS.INTERNAL_AUDIT_ACCESS,
                    REGISTERS_PERMISSIONS.AUDIT_REGISTER
                ]
            }
        },
        {
            path: 'properties',
            name: REGISTER_WIZARD_ROUTES.CHECKLIST_ADAPTATION.PROPERTIES.name,
            component: () => import('@/components/registers/PropertiesTab'),
            props: true,
            meta: {
                pageTitle:
                    'REGISTERS.INTERNAL_AUDITS.CHECKLIST_ADAPTATION.HEADING_TITLE',
                withScaling: true,
                requiresAuth: true,
                register: REGISTER_TYPES.INTERNAL_AUDITS,
                shouldSkipRouteInHistory: true,
                guards: [myPermissionsGuard],
                permissions: [
                    MY_PERMISSIONS.INTERNAL_AUDIT_ACCESS,
                    REGISTERS_PERMISSIONS.AUDIT_REGISTER
                ]
            }
        },
        {
            path: 'discussions',
            name: REGISTER_WIZARD_ROUTES.CHECKLIST_ADAPTATION.DISCUSSIONS.name,
            component: () => import('@/components/registers/Discussions'),
            props: true,
            meta: {
                pageTitle:
                    'REGISTERS.INTERNAL_AUDITS.CHECKLIST_ADAPTATION.HEADING_TITLE',
                withScaling: true,
                requiresAuth: true,
                register: REGISTER_TYPES.INTERNAL_AUDITS,
                shouldSkipRouteInHistory: true,
                guards: [myPermissionsGuard],
                permissions: [
                    MY_PERMISSIONS.INTERNAL_AUDIT_ACCESS,
                    REGISTERS_PERMISSIONS.AUDIT_REGISTER
                ]
            }
        }
    ]
}
