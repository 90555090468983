<template>
    <div :class="wrapperClasses">
        <div class="cf-c-input__ctn cf-u-flex-align-center">
            <input
                ref="input"
                class="cf-c-input-enable__field cf-u-width-full"
                :disabled="disableInput || disabled"
                :value="value"
                @input="$emit('input', $event.target.value)"
                @blur="handleBlur($event.target.value)"
                @focus="$emit('focus', $event.target.value)"
            />
            <app-button
                v-if="showEditButton"
                variation="clear"
                :disabled="disabled"
                @click="handleEdit"
            >
                <app-icon class="cf-u-color-primary" icon="pencil-icon" />
            </app-button>
            <span class="cf-c-input__required">*</span>
        </div>
        <span v-if="!!errorMessage" class="cf-c-input__error">{{
            errorMessage
        }}</span>
    </div>
</template>

<script>
import AppInput from './AppInput'

export default {
    extends: AppInput,

    props: {
        type: {
            type: String,
            required: false,
            default: ''
        },
        showEditButton: {
            type: Boolean,
            required: false,
            default: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data () {
        return {
            disableInput: true
        }
    },

    computed: {
        wrapperClasses () {
            return `
        cf-c-input-enable cf-u-width-full
        ${this.type ? `cf-c-input-enable--type-${this.type}` : ''}
        `
        }
    },

    methods: {
        handleEdit () {
            this.disableInput = !this.disableInput
            this.$nextTick(() => {
                this.$refs.input.focus()
            })
        },

        handleBlur (value) {
            this.disableInput = true
            this.$emit('blur', value)
        }
    }
}
</script>
