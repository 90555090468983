import { first } from 'lodash-es'
import { isAfter } from '@/services/utils/date'
import { compareAssigneesByFullNameOrEmail } from '@/services/utils/functions'
import { REGISTER_WIZARD_ROUTES, REGISTER_TYPES } from '@/constants/registers'
import { DOCUMENT_WIZARD_ROUTES } from '@/constants/documents'
import {
    REGISTER_TYPE as REGISTER_TYPE_SECURITY_OBJECTIVES,
    ROUTES_SECURITY_OBJECTIVES
} from '@src/modules/registers/security-objectives/constants'
import {
    REGISTER_TYPE as REGISTER_TYPE_TRAININGS,
    ROUTES_TRAININGS
} from '@src/modules/registers/trainings/constants'
import {
    REGISTER_TYPE as REGISTER_TYPE_REQUIREMENTS,
    ROUTES_REGISTER_OF_REQUIREMENTS
} from '@src/modules/registers/requirements/constants'

const TASKS_ROUTES = {
    MY_WORK: {
        INDEX: { name: 'my-work' }
    }
}

const TASK_TYPES = {
    APPROVE_RESOURCES: 'approve_resources_type',
    CONTROL_TASK: 'Control task',
    CONFIRM_RISK: 'Confirm risk',
    CUSTOM: 'Custom',
    REVIEW: 'Review',
    APPROVE: 'Approve',
    RISK_TREATMENT_PLAN_TASK: 'Risk treatment plan task'
}

const CONDITION_ACTION_TYPES = {
    PERIODIC_STEP_SECURITY_TRAININGS:
        'complete_periodic_step_security_trainings',
    PERIODIC_STEP_INCIDENT_REVIEW:
        'complete_periodic_step_incident_review_task',
    PERIODIC_STEP_RISK_REVIEW: 'regular_risk_review_performed',
    PERIODIC_SECURITY_OBJECTIVES_REVIEW: 'periodic_security_objectives_review'
}

const PERIODIC_STEPS_CONDITIONS = [
    CONDITION_ACTION_TYPES.PERIODIC_STEP_SECURITY_TRAININGS,
    CONDITION_ACTION_TYPES.PERIODIC_STEP_INCIDENT_REVIEW,
    CONDITION_ACTION_TYPES.PERIODIC_STEP_RISK_REVIEW,
    CONDITION_ACTION_TYPES.PERIODIC_SECURITY_OBJECTIVES_REVIEW
]

const TASK_SOFT_TYPES = {
    REVIEW: 'Review',
    APPROVE: 'Approve',
    READ: 'Read',
    CONFIRM_RISK: 'ConfirmRisk',
    INCIDENT_TASK: 'IncidentTask',
    NONCONFORMITY_TASK: 'NonconformityTask',
    CUSTOM: 'CustomTask',
    SECURITY_OBJECTIVE_MANUAL_INPUT:
        'InsertManualInputForSecurityObjectiveTask',
    RISK_TREATMENT_PLAN_TASK: 'RiskTreatmentPlanTask'
}

const ADDITIONAL_WIZARD_TASK_MODAL_ACTIONS = {
    GUEST_USER_REVIEW: 'GUEST_USER_REVIEW'
}

const ACTION_TYPES = {
    REVIEW: 'review',
    APPROVE: 'approve',
    REJECT: 'reject',
    STOP_REVIEW: 'stop_review',
    STOP_APPROVAL: 'stop_approval',
    APPROVAL_RISK_TREATMENT_PLAN: 'approve_risk_treatment_plan'
}

const DOCUMENT_WIZARD_ACTION_MODAL_ACTION_TYPES = [
    ACTION_TYPES.REVIEW,
    ACTION_TYPES.APPROVE,
    ACTION_TYPES.REJECT,
    ADDITIONAL_WIZARD_TASK_MODAL_ACTIONS.GUEST_USER_REVIEW
]

const CUSTOM_SOFT_TYPES = [TASK_SOFT_TYPES.SECURITY_OBJECTIVE_MANUAL_INPUT]

const STATUSES = {
    COMPLETED: 'completed',
    DELETED: 'deleted',
    LATE: 'late',
    DUE_DATE_IN_THE_FUTURE: 'due_date_in_the_future'
}

const STATUS_COLORS = {
    [STATUSES.COMPLETED]: 'cf-u-color-secondary-light',
    [STATUSES.DELETED]: 'cf-u-color-gray-canceled',
    [STATUSES.LATE]: 'cf-u-color-danger',
    [STATUSES.DUE_DATE_IN_THE_FUTURE]: 'cf-u-color-amber'
}

const STATUS_GUIDES = [
    {
        value: STATUSES.LATE,
        label: 'TASKS.STATUSES.LATE',
        color: STATUS_COLORS[STATUSES.LATE]
    },
    {
        value: STATUSES.DUE_DATE_IN_THE_FUTURE,
        label: 'TASKS.STATUSES.DUE_DATE_IN_THE_FUTURE',
        color: STATUS_COLORS[STATUSES.DUE_DATE_IN_THE_FUTURE]
    },
    {
        value: STATUSES.COMPLETED,
        label: 'TASKS.STATUSES.COMPLETED',
        color: STATUS_COLORS[STATUSES.COMPLETED]
    },
    {
        value: STATUSES.DELETED,
        label: 'TASKS.STATUSES.DELETED',
        color: STATUS_COLORS[STATUSES.DELETED]
    }
]

const DEFAULT_STATUS_FILTERS = [STATUSES.LATE, STATUSES.DUE_DATE_IN_THE_FUTURE]

const CATEGORY_TYPES = {
    MAJOR_STEP: 'major_step',
    RECURRING_TASKS: 'recurring_tasks',
    CONTINUOUS_RESPONSIBILITIES: 'continuous_responsibilities',
    ONE_TIME_TASKS: 'one_time_tasks',
    COMMUNICATION: 'communication',
    TASKS_OF_SMALLER_IMPORTANCE: 'tasks_of_smaller_importance',
    COMPLY_TASKS: 'comply_tasks'
}

const CATEGORIES = {
    [CATEGORY_TYPES.MAJOR_STEP]: { LABEL: 'TASKS.CATEGORIES.MAJOR_STEP' },
    [CATEGORY_TYPES.RECURRING_TASKS]: {
        LABEL: 'TASKS.CATEGORIES.RECURRING_TASKS'
    },
    [CATEGORY_TYPES.ONE_TIME_TASKS]: {
        LABEL: 'TASKS.CATEGORIES.ONE_TIME_TASKS'
    },
    [CATEGORY_TYPES.COMMUNICATION]: { LABEL: 'TASKS.CATEGORIES.COMMUNICATION' },
    [CATEGORY_TYPES.CONTINUOUS_RESPONSIBILITIES]: {
        LABEL: 'TASKS.CATEGORIES.CONTINUOUS_RESPONSIBILITIES'
    },
    [CATEGORY_TYPES.TASKS_OF_SMALLER_IMPORTANCE]: {
        LABEL: 'TASKS.CATEGORIES.TASKS_OF_SMALLER_IMPORTANCE'
    },
    [CATEGORY_TYPES.COMPLY_TASKS]: { LABEL: 'TASKS.CATEGORIES.COMPLY_TASKS' }
}

// BE correspondent: src/config/regulation/ISO_27001/task_categories.php
const TYPE_CATEGORIES = {
    Approve: CATEGORY_TYPES.ONE_TIME_TASKS,
    ApproveResourceTask: CATEGORY_TYPES.ONE_TIME_TASKS,
    ClassifyIncidentTask: CATEGORY_TYPES.ONE_TIME_TASKS,
    Comply: CATEGORY_TYPES.COMPLY_TASKS,
    ConfirmRisk: CATEGORY_TYPES.ONE_TIME_TASKS,
    ControlTask: CATEGORY_TYPES.ONE_TIME_TASKS,
    CorrectiveActionTask: CATEGORY_TYPES.ONE_TIME_TASKS, // TODO - This isn't confirmed yet
    IncidentsPeriodicReview: CATEGORY_TYPES.RECURRING_TASKS, // TODO - This isn't confirmed yet
    InternalAuditTask: CATEGORY_TYPES.ONE_TIME_TASKS,
    IncidentTask: CATEGORY_TYPES.ONE_TIME_TASKS,
    NonconformityResponsibilityTask: CATEGORY_TYPES.ONE_TIME_TASKS, // TODO - This isn't confirmed yet
    NonconformityTask: CATEGORY_TYPES.ONE_TIME_TASKS,
    Read: CATEGORY_TYPES.ONE_TIME_TASKS, // TODO - This isn't confirmed yet
    Review: CATEGORY_TYPES.ONE_TIME_TASKS,
    ReviewControls: CATEGORY_TYPES.ONE_TIME_TASKS,
    RiskTreatmentPlanTask: CATEGORY_TYPES.ONE_TIME_TASKS,
    AcceptSecurityObjectiveTask: CATEGORY_TYPES.ONE_TIME_TASKS, // TODO - This isn't confirmed yet
    ReviewManagementReviewTask: CATEGORY_TYPES.ONE_TIME_TASKS,
    CustomTask: CATEGORY_TYPES.ONE_TIME_TASKS,
    InsertManualInputForSecurityObjectiveTask: CATEGORY_TYPES.RECURRING_TASKS, // TODO - This isn't confirmed yet
    TrainingCreated: CATEGORY_TYPES.ONE_TIME_TASKS, // TODO - This isn't confirmed yet
    TrainingUpdated: CATEGORY_TYPES.ONE_TIME_TASKS, // TODO - This isn't confirmed yet,
    RecurringTask: CATEGORY_TYPES.RECURRING_TASKS,
    ContinuousTask: CATEGORY_TYPES.CONTINUOUS_RESPONSIBILITIES,
    DocumentUpdatePeriodic: CATEGORY_TYPES.RECURRING_TASKS,
    DisciplinaryProcessTask: CATEGORY_TYPES.ONE_TIME_TASKS,
    OneTimeTask: CATEGORY_TYPES.ONE_TIME_TASKS,
    CommunicationTask: CATEGORY_TYPES.COMMUNICATION
}

const SOURCE_TYPES = {
    STEP_BY_STEP_WIZARD: 'step_by_step_wizard',
    DOCUMENT_WIZARD: 'document_wizard',
    REQUIREMENTS_REGISTER: 'requirements_register',
    RISK_REGISTER: 'risk_register',
    SOA: 'statement_of_applicability',
    MY_WORK: 'my_work',
    INCIDENT_REGISTER: 'incident_register',
    NONCONFORMITY_REGISTER: 'nonconformity_register',
    INTERNAL_AUDIT: 'internal_audit',
    TRAINING_MODULE: 'training_module',
    SECURITY_OBJECTIVES: 'security_objectives',
    MANAGEMENT_REVIEW: 'management_review',
    FILL_MISSING_DATA: 'fill_missing_data'
}

const SOURCES = {
    [SOURCE_TYPES.STEP_BY_STEP_WIZARD]: {
        LABEL: 'TASKS.SOURCES.STEP_BY_STEP_WIZARD',
        ROUTE: DOCUMENT_WIZARD_ROUTES.SINGLE_STEP
        // TODO -> When we implement a task that has
        // step by step wizard as source
        // implement navigating to specific step here
    },
    [SOURCE_TYPES.DOCUMENT_WIZARD]: {
        LABEL: 'TASKS.SOURCES.DOCUMENT_WIZARD',
        ROUTE: DOCUMENT_WIZARD_ROUTES.DOCUMENT_WIZARD
    },
    [SOURCE_TYPES.MANAGEMENT_REVIEW]: {
        LABEL: 'TASKS.SOURCES.MANAGEMENT_REVIEW',
        ROUTE: REGISTER_WIZARD_ROUTES.REPORTING.MANAGEMENT_REVIEW
    },
    [SOURCE_TYPES.SECURITY_OBJECTIVES]: {
        LABEL: 'TASKS.SOURCES.SECURITY_OBJECTIVES',
        ROUTE: ROUTES_SECURITY_OBJECTIVES.BASE,
        REGISTER: REGISTER_TYPE_SECURITY_OBJECTIVES
    },
    [SOURCE_TYPES.REQUIREMENTS_REGISTER]: {
        LABEL: 'TASKS.SOURCES.REQUIREMENTS_REGISTER',
        ROUTE: ROUTES_REGISTER_OF_REQUIREMENTS.BASE,
        REGISTER: REGISTER_TYPE_REQUIREMENTS
    },
    [SOURCE_TYPES.RISK_REGISTER]: {
        LABEL: 'TASKS.SOURCES.RISK_REGISTER',
        ROUTE: REGISTER_WIZARD_ROUTES.RISKS.WIZARD,
        REGISTER: REGISTER_TYPES.RISKS
    },
    [SOURCE_TYPES.SOA]: {
        LABEL: 'TASKS.SOURCES.SOA',
        ROUTE: REGISTER_WIZARD_ROUTES.SOA.INDEX,
        REGISTER: REGISTER_TYPES.SOA
    },
    [SOURCE_TYPES.MY_WORK]: {
        LABEL: 'TASKS.SOURCES.MY_WORK',
        ROUTE: TASKS_ROUTES.MY_WORK.INDEX
    },
    [SOURCE_TYPES.INCIDENT_REGISTER]: {
        LABEL: 'TASKS.SOURCES.INCIDENT_REGISTER',
        ROUTE: REGISTER_WIZARD_ROUTES.INCIDENTS.INDEX,
        REGISTER: REGISTER_TYPES.INCIDENTS
    },
    [SOURCE_TYPES.NONCONFORMITY_REGISTER]: {
        LABEL: 'TASKS.SOURCES.NONCONFORMITY_REGISTER',
        ROUTE: REGISTER_WIZARD_ROUTES.NONCONFORMITIES.WIZARD,
        REGISTER: REGISTER_TYPES.NONCONFORMITIES
    },
    [SOURCE_TYPES.INTERNAL_AUDIT]: {
        LABEL: 'TASKS.SOURCES.INTERNAL_AUDIT',
        ROUTE: REGISTER_WIZARD_ROUTES.INTERNAL_AUDITS.INDEX,
        REGISTER: REGISTER_TYPES.INTERNAL_AUDITS
    },
    [SOURCE_TYPES.TRAINING_MODULE]: {
        LABEL: 'TASKS.SOURCES.TRAINING_MODULE',
        ROUTE: ROUTES_TRAININGS.BASE,
        REGISTER: REGISTER_TYPE_TRAININGS
    },
    [SOURCE_TYPES.FILL_MISSING_DATA]: {
        LABEL: 'TASKS.SOURCES.FILL_MISSING_DATA'
    }
}

const TYPE_SOURCES = {
    Approve: SOURCE_TYPES.DOCUMENT_WIZARD,
    Comply: SOURCE_TYPES.REQUIREMENTS_REGISTER,
    ConfirmRisk: SOURCE_TYPES.RISK_REGISTER,
    Review: SOURCE_TYPES.DOCUMENT_WIZARD,
    ReviewControls: SOURCE_TYPES.SOA,
    ClassifyIncidentTask: SOURCE_TYPES.INCIDENT_REGISTER,
    ControlTask: SOURCE_TYPES.SOA,
    CorrectiveActionTask: SOURCE_TYPES.INCIDENT_REGISTER, // TODO - This isn't confirmed yet
    DisciplinaryProcessTask: SOURCE_TYPES.INCIDENT_REGISTER,
    IncidentsPeriodicReview: SOURCE_TYPES.INCIDENT_REGISTER, // TODO - This isn't confirmed yet
    InternalAuditTask: SOURCE_TYPES.INTERNAL_AUDIT,
    IncidentTask: SOURCE_TYPES.INCIDENT_REGISTER,
    NonconformityResponsibilityTask: SOURCE_TYPES.NONCONFORMITY_REGISTER, // TODO - This isn't confirmed yet
    NonconformityTask: SOURCE_TYPES.NONCONFORMITY_REGISTER,
    ApproveResourceTask: SOURCE_TYPES.SOA,
    RiskTreatmentPlanTask: SOURCE_TYPES.SOA,
    AcceptSecurityObjectiveTask: SOURCE_TYPES.SECURITY_OBJECTIVES,
    ReviewManagementReviewTask: SOURCE_TYPES.MANAGEMENT_REVIEW,
    Read: SOURCE_TYPES.DOCUMENT_WIZARD, // TODO - This isn't confirmed yet,
    InsertManualInputForSecurityObjectiveTask: SOURCE_TYPES.SECURITY_OBJECTIVES,
    CustomTask: SOURCE_TYPES.MY_WORK,
    TrainingCreated: SOURCE_TYPES.TRAINING_MODULE, // TODO - This isn't confirmed yet
    TrainingUpdated: SOURCE_TYPES.TRAINING_MODULE, // TODO - This isn't confirmed yet
    FillMissingOnboardingData: SOURCE_TYPES.FILL_MISSING_DATA
}

const SORT_VALUES = {
    CREATED_IN_ASC: 'created_in_asc',
    CREATED_IN_DESC: 'created_in_desc',
    DUE_DATE_ASC: 'due_date_asc',
    DUE_DATE_DESC: 'due_date_desc',
    CATEGORY_ASC: 'category_asc',
    CATEGORY_DESC: 'category_desc',
    RESPONSIBLE_PERSON_ASC: 'responsible_person_asc',
    RESPONSIBLE_PERSON_DESC: 'responsible_person_desc'
}

const SORT_OPTIONS = [
    {
        value: SORT_VALUES.CREATED_IN_ASC,
        label: 'TASKS.SORT_VALUES.CREATED_IN_ASC'
    },
    {
        value: SORT_VALUES.CREATED_IN_DESC,
        label: 'TASKS.SORT_VALUES.CREATED_IN_DESC'
    },
    {
        value: SORT_VALUES.DUE_DATE_ASC,
        label: 'TASKS.SORT_VALUES.DUE_DATE_ASC'
    },
    {
        value: SORT_VALUES.DUE_DATE_DESC,
        label: 'TASKS.SORT_VALUES.DUE_DATE_DESC'
    },
    {
        value: SORT_VALUES.CATEGORY_ASC,
        label: 'TASKS.SORT_VALUES.CATEGORY_ASC'
    },
    {
        value: SORT_VALUES.CATEGORY_DESC,
        label: 'TASKS.SORT_VALUES.CATEGORY_DESC'
    },
    {
        value: SORT_VALUES.RESPONSIBLE_PERSON_ASC,
        label: 'TASKS.SORT_VALUES.RESPONSIBLE_PERSON_ASC'
    },
    {
        value: SORT_VALUES.RESPONSIBLE_PERSON_DESC,
        label: 'TASKS.SORT_VALUES.RESPONSIBLE_PERSON_DESC'
    }
]

const SORT_ACTIONS = {
    [SORT_VALUES.CREATED_IN_ASC]: (a, b) => {
        return a.source > b.source ? 1 : -1
    },
    [SORT_VALUES.CREATED_IN_DESC]: (a, b) => (a.source < b.source ? 1 : -1),
    [SORT_VALUES.CATEGORY_ASC]: (a, b) => (a.category > b.category ? 1 : -1),
    [SORT_VALUES.CATEGORY_DESC]: (a, b) => (a.category < b.category ? 1 : -1),
    [SORT_VALUES.DUE_DATE_ASC]: (a, b) => {
        if (a.due_date === b.due_date) {
            return a.order > b.order ? 1 : -1
        }

        return isAfter(a.due_date, b.due_date) ? 1 : -1
    },

    [SORT_VALUES.DUE_DATE_DESC]: (a, b) => {
        if (a.due_date === b.due_date) {
            return a.order < b.order ? 1 : -1
        }

        return isAfter(a.due_date, b.due_date) ? -1 : 1
    },

    [SORT_VALUES.RESPONSIBLE_PERSON_ASC]: (a, b) =>
        compareAssigneesByFullNameOrEmail(
            first(a.assignees),
            first(b.assignees)
        ),
    [SORT_VALUES.RESPONSIBLE_PERSON_DESC]: (a, b) =>
        compareAssigneesByFullNameOrEmail(
            first(a.assignees),
            first(b.assignees),
            false
        )
}

const HEX_STATUS_COLORS = {
    LATE: '#F82F47',
    DUE_DATE_IN_THE_FUTURE: '#F5A623',
    COMPLETED: '#70D787',
    DELETED: '#818181'
}

const TARGETABLE_TYPES = {
    DOCUMENT: 'App\\Models\\Document\\TargetableVersion'
}

export const TASKS = {
    TASK_SOFT_TYPES,
    TASK_TYPES,
    ACTION_TYPES,
    STATUSES,
    STATUS_COLORS,
    STATUS_GUIDES,
    CATEGORIES,
    SOURCES,
    SORT_VALUES,
    SORT_ACTIONS,
    SORT_OPTIONS,
    TYPE_CATEGORIES,
    TYPE_SOURCES,
    HEX_STATUS_COLORS,
    TARGETABLE_TYPES,
    CUSTOM_SOFT_TYPES,
    SOURCE_TYPES,
    CATEGORY_TYPES,
    PERIODIC_STEPS_CONDITIONS,
    ADDITIONAL_WIZARD_TASK_MODAL_ACTIONS,
    DOCUMENT_WIZARD_ACTION_MODAL_ACTION_TYPES,
    DEFAULT_STATUS_FILTERS,
    TASKS_ROUTES
}
