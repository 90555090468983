import { ROUTES, MY_PERMISSIONS } from '@src/constants'
import { myPermissionsGuard } from './guards'

const { MY_WORK } = ROUTES

export default {
    name: MY_WORK.INDEX.name,
    path: 'my-work',
    component: () => import('@/containers/MyWork/MyWork'),
    meta: {
        requiresAuth: true
    },
    redirect: to => ({
        name: MY_WORK.OVERVIEW.name,
        params: { ...to.params }
    }),
    children: [
        {
            name: MY_WORK.OVERVIEW.name,
            path: 'overview',
            component: () => import('@/containers/MyWork/Overview'),
            meta: {
                pageTitle: 'MY_WORK.TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.MY_WORK_ACCESS]
            }
        },
        {
            name: MY_WORK.TASKS.INDEX.name,
            path: 'tasks',
            component: () => import('@/containers/MyWork/Tasks'),
            meta: {
                requiresAuth: true
            },
            redirect: to => ({
                name: MY_WORK.TASKS.MY_TASKS.name,
                params: { ...to.params }
            }),
            children: [
                {
                    name: MY_WORK.TASKS.MY_TASKS.name,
                    path: 'my-tasks',
                    component: () => import('@/containers/MyWork/MyTasks'),
                    meta: {
                        pageTitle: 'MY_WORK.TASKS.MY_TASKS.TITLE',
                        requiresAuth: true,
                        guards: [myPermissionsGuard],
                        permissions: [MY_PERMISSIONS.MY_WORK_ACCESS]
                    }
                },
                {
                    name: MY_WORK.TASKS.TASKS_FOR_OTHERS.name,
                    path: 'tasks-for-others',
                    component: () =>
                        import('@/containers/MyWork/TasksForOthers'),
                    meta: {
                        pageTitle: 'MY_WORK.TASKS.TASKS_FOR_OTHERS.TITLE',
                        requiresAuth: true,
                        guards: [myPermissionsGuard],
                        permissions: [MY_PERMISSIONS.MY_WORK_ACCESS]
                    }
                }
            ]
        },
        {
            path: 'tasks/:taskId',
            name: MY_WORK.TASKS.SINGLE_TASK.name,
            component: () => import('@/containers/MyWork/Task'),
            meta: {
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.MY_WORK_ACCESS]
            }
        },
        {
            name: MY_WORK.DISCUSSIONS.name,
            path: 'my-discussions',
            component: () => import('@/containers/MyWork/MyDiscussions'),
            meta: {
                pageTitle: 'MY_WORK.DISCUSSIONS.TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.MY_WORK_ACCESS]
            }
        },
        {
            name: MY_WORK.STEPS.name,
            path: 'steps',
            component: () => import('@/containers/MyWork/MySteps'),
            meta: {
                pageTitle: 'MY_WORK.STEPS.TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.MY_WORK_ACCESS]
            }
        },
        {
            name: MY_WORK.DOCUMENTS.name,
            path: 'documents',
            component: () => import('@/containers/MyWork/MyDocuments'),
            meta: {
                pageTitle: 'MY_WORK.DOCUMENTS.TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.MY_WORK_ACCESS]
            }
        },
        {
            name: MY_WORK.REGISTERS.name,
            path: 'registers',
            component: () => import('@/containers/MyWork/MyRegisters'),
            meta: {
                pageTitle: 'MY_WORK.REGISTERS.TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.MY_WORK_ACCESS]
            }
        }
    ]
}
