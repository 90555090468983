import { kebabCase, transform } from 'lodash-es'
import { CORRECTIVE_ACTIONS } from '@/constants'

export default {
    created () {
        this.types = CORRECTIVE_ACTIONS.CORRECTIONABLE_TYPES
    },

    props: {
        items: {
            type: Object,
            required: false,
            default: () => {}
        },

        isCorrectiveActionCompleted: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        visibleTypes () {
            return transform(
                this.types,
                (result, value, key) => {
                    if (this.getNumberOfItemsByType(key)) {
                        result[key] = value
                    }
                },
                {}
            )
        }
    },

    methods: {
        getItemsByType (type) {
            return this.items[this.getType(type)] || []
        },

        getNumberOfItemsByType (type) {
            return this.getItemsByType(type).length
        },

        getType (type) {
            return kebabCase(type)
        }
    }
}
