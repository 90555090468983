import { mapGetters, mapActions } from 'vuex'
import { COMPANY_SIZES, STANDARD_TRANSLATION_KEYS } from '@/constants'

export default {
    props: {
        step: {
            type: [String, Number],
            required: false,
            default: ''
        },

        standard: {
            type: String,
            required: false,
            default: ''
        }
    },

    async created () {
        this.setCurrentStepId(this.step)
    },

    computed: {
        ...mapGetters('regulation', ['currentStep', 'currentRegulation']),
        ...mapGetters('company', { companyRegulations: 'regulations' }),
        ...mapGetters('company', ['companySize']),

        stepId () {
            return parseInt(this.step, 10)
        },

        standardName () {
            return this.standard
        }
    },

    watch: {
        step (value) {
            this.setCurrentStepId(value)
        },

        async standard (value) {
            await this.loadRegulationData(value)
        }
    },

    methods: {
        ...mapActions('regulation', ['setCurrentStepId', 'loadRegulationData']),

        getCompanyConditionalText (input) {
            const {
                FROM_ZERO_TO_TWENTY_EMPLOYEES,
                FROM_TWENTY_ONE_TO_FIFTY_EMPLOYEES,
                FROM_FIFTY_ONE_TO_TWO_HUNDRED_EMPLOYEES,
                FROM_TWO_HUNDRED_ONE_TO_FIVE_HUNDRED_EMPLOYEES,
                FROM_FIVE_HUNDRED_ONE_TO_TWO_THOUSAND_EMPLOYEES,
                TWO_THOUSAND_ONE_OR_MORE_EMPLOYEES
            } = COMPANY_SIZES

            const name = input.name.toUpperCase()
            const standard = STANDARD_TRANSLATION_KEYS[this.standardName]

            const companyConditionalText = {
                [FROM_ZERO_TO_TWENTY_EMPLOYEES]: {
                    companySizeConditionalText: this.$t(
                        `REGULATION.${standard}_${name}_1`
                    )
                },
                [FROM_TWENTY_ONE_TO_FIFTY_EMPLOYEES]: {
                    companySizeConditionalText: this.$t(
                        `REGULATION.${standard}_${name}_1`
                    )
                },
                [FROM_FIFTY_ONE_TO_TWO_HUNDRED_EMPLOYEES]: {
                    companySizeConditionalText: this.$t(
                        `REGULATION.${standard}_${name}_2`
                    )
                },
                [FROM_TWO_HUNDRED_ONE_TO_FIVE_HUNDRED_EMPLOYEES]: {
                    companySizeConditionalText: this.$t(
                        `REGULATION.${standard}_${name}_2`
                    )
                },
                [FROM_FIVE_HUNDRED_ONE_TO_TWO_THOUSAND_EMPLOYEES]: {
                    companySizeConditionalText: this.$t(
                        `REGULATION.${standard}_${name}_3`
                    )
                },
                [TWO_THOUSAND_ONE_OR_MORE_EMPLOYEES]: {
                    companySizeConditionalText: this.$t(
                        `REGULATION.${standard}_${name}_3`
                    )
                }
            }

            return (
                companyConditionalText[this.companySize] || {
                    companySizeConditionalText: ''
                }
            )
        }
    }
}
