const CAN_ARCHIVE_DISCUSSIONS = ['active']

const CREATED_BY_FILTER_VALUES = {
    LAST_30_DAYS: 'Last 30 days',
    LAST_3_MONTHS: 'Last 3 months',
    THIS_YEAR: 'This year',
    LAST_YEAR: 'Last year'
}

const CREATED_AT_FILTER_OPTIONS = [
    {
        value: CREATED_BY_FILTER_VALUES.LAST_30_DAYS,
        label: 'MY_WORK.DISCUSSIONS.FILTER_VALUES.LAST_30_DAYS'
    },
    {
        value: CREATED_BY_FILTER_VALUES.LAST_3_MONTHS,
        label: 'MY_WORK.DISCUSSIONS.FILTER_VALUES.LAST_3_MONTHS'
    },
    {
        value: CREATED_BY_FILTER_VALUES.THIS_YEAR,
        label: 'MY_WORK.DISCUSSIONS.FILTER_VALUES.THIS_YEAR'
    },
    {
        value: CREATED_BY_FILTER_VALUES.LAST_YEAR,
        label: 'MY_WORK.DISCUSSIONS.FILTER_VALUES.LAST_YEAR'
    }
]

const FILTER_VALUES = {
    COMPLETED: 'completed',
    DOCUMENT_FULLY_FILLED_OUT: 'document_fully_filled_out',
    DOCUMENT_REVIEWED_AND_APPROVED: 'document_reviewed_and_approved',
    NOT_STARTED: 'not_started',
    DOCUMENT_PARTIALLY_FILLED_OUT: 'document_partially_filled_out',
    ALL: 'all'
}

const FILTER_OPTIONS = [
    {
        value: FILTER_VALUES.COMPLETED,
        label: 'MY_WORK.STEPS.FILTER_VALUES.COMPLETED'
    },
    {
        value: FILTER_VALUES.DOCUMENT_FULLY_FILLED_OUT,
        label: 'MY_WORK.STEPS.FILTER_VALUES.DOCUMENT_FULLY_FILLED_OUT'
    },
    {
        value: FILTER_VALUES.DOCUMENT_REVIEWED_AND_APPROVED,
        label: 'MY_WORK.STEPS.FILTER_VALUES.DOCUMENT_REVIEWED_AND_APPROVED'
    },
    {
        value: FILTER_VALUES.NOT_STARTED,
        label: 'MY_WORK.STEPS.FILTER_VALUES.NOT_STARTED'
    },
    {
        value: FILTER_VALUES.DOCUMENT_PARTIALLY_FILLED_OUT,
        label: 'MY_WORK.STEPS.FILTER_VALUES.DOCUMENT_PARTIALLY_FILLED_OUT'
    },
    {
        value: FILTER_VALUES.ALL,
        label: 'MY_WORK.STEPS.FILTER_VALUES.ALL'
    }
]

const REGISTER_STATUSES = {
    UNASSIGNED: 'unassigned',
    ASSIGNED: 'assigned',
    CORRECTIONS_AND_CORRECTIVE_ACTIONS_DEFINED: 'actions_defined',
    RESOLVED: 'resolved',
    NOT_RELEVANT: 'not_relevant',
    PLANNED: 'planned',
    NOT_ENABLED: 'not_enabled',
    ENABLED_NOT_ACCEPTED: 'enabled_not_accepted',
    ACCEPTED: 'accepted',
    IN_PROGRESS: 'in_progress',
    SCHEDULED: 'scheduled',
    COMPLETED: 'completed',
    CANCELLED: 'cancelled',
    LATE: 'late',
    DUE_DATE_IN_THE_FUTURE: 'due_date_in_the_future',
    DELETED: 'deleted',
    APPROVED: 'approved',
    IN_APPROVAL: 'in_approval',
    NOT_APPROVED: 'not_approved',
    UNCHANGED: 'unchanged',
    EDITED: 'edited',
    NEW: 'new',
    COMPLIANT: 'compliant',
    NON_COMPLIANT: 'non_compliant',
    UNDER_WAY: 'under_way',
    IMPLEMENTED: 'implemented',
    PERFORMED: 'performed',
    SUGGESTED: 'suggested',
    CHANGED_REQUIREMENTS: 'changed_requirements',
    CANCELED: 'canceled'
}

const COLORS_CLASSES = {
    YELLOW: 'cf-c-cube-tag--is-amber',
    LIGHT_GRAY: 'cf-c-cube-tag--is-gray-02',
    BLUE: '',
    GREEN: 'cf-c-cube-tag--is-secondary-light cf-c-cube-tag-has-tooltip--long',
    GRAY: 'cf-c-cube-tag--is-gray-03',
    RED: 'cf-c-cube-tag--is-danger',
    PINK: 'cf-c-cube-tag--is-rose'
}

const MY_REGISTERS_STATUS_COLORS_CLASSES = {
    SECURITY_OBJECTIVES: {
        [REGISTER_STATUSES.NOT_ENABLED]: COLORS_CLASSES.LIGHT_GRAY,
        [REGISTER_STATUSES.ENABLED_NOT_ACCEPTED]: COLORS_CLASSES.YELLOW,
        [REGISTER_STATUSES.ACCEPTED]: COLORS_CLASSES.GREEN
    },
    INCIDENTS: {
        [REGISTER_STATUSES.UNASSIGNED]: COLORS_CLASSES.LIGHT_GRAY,
        [REGISTER_STATUSES.ASSIGNED]: COLORS_CLASSES.BLUE,
        [REGISTER_STATUSES.CORRECTIONS_AND_CORRECTIVE_ACTIONS_DEFINED]:
            COLORS_CLASSES.YELLOW,
        [REGISTER_STATUSES.RESOLVED]: COLORS_CLASSES.GREEN
    },
    NONCONFORMITIES: {
        [REGISTER_STATUSES.UNASSIGNED]: COLORS_CLASSES.LIGHT_GRAY,
        [REGISTER_STATUSES.ASSIGNED]: COLORS_CLASSES.BLUE,
        [REGISTER_STATUSES.CORRECTIONS_AND_CORRECTIVE_ACTIONS_DEFINED]:
            COLORS_CLASSES.YELLOW,
        [REGISTER_STATUSES.RESOLVED]: COLORS_CLASSES.GREEN,
        [REGISTER_STATUSES.NOT_RELEVANT]: COLORS_CLASSES.GRAY
    },
    AUDITS: {
        [REGISTER_STATUSES.PLANNED]: COLORS_CLASSES.BLUE,
        [REGISTER_STATUSES.IN_PROGRESS]: COLORS_CLASSES.YELLOW,
        [REGISTER_STATUSES.COMPLETED]: COLORS_CLASSES.GREEN,
        [REGISTER_STATUSES.CANCELLED]: COLORS_CLASSES.GRAY
    },
    MANAGEMENT_REVIEW: {
        [REGISTER_STATUSES.LATE]: COLORS_CLASSES.RED,
        [REGISTER_STATUSES.PLANNED]: COLORS_CLASSES.BLUE,
        [REGISTER_STATUSES.COMPLETED]: COLORS_CLASSES.GREEN
    },
    RESPONSIBILITY_MATRIX: {
        [REGISTER_STATUSES.LATE]: COLORS_CLASSES.RED,
        [REGISTER_STATUSES.DUE_DATE_IN_THE_FUTURE]: COLORS_CLASSES.YELLOW,
        [REGISTER_STATUSES.COMPLETED]: COLORS_CLASSES.GREEN,
        [REGISTER_STATUSES.DELETED]: COLORS_CLASSES.GRAY
    },
    STATEMENT_OF_APPLICABILITY: {
        [REGISTER_STATUSES.PLANNED]: COLORS_CLASSES.BLUE,
        [REGISTER_STATUSES.UNDER_WAY]: COLORS_CLASSES.YELLOW,
        [REGISTER_STATUSES.IMPLEMENTED]: COLORS_CLASSES.GREEN
    },
    MY_WORK: {
        [REGISTER_STATUSES.LATE]: COLORS_CLASSES.RED,
        [REGISTER_STATUSES.DUE_DATE_IN_THE_FUTURE]: COLORS_CLASSES.YELLOW,
        [REGISTER_STATUSES.COMPLETED]: COLORS_CLASSES.GREEN,
        [REGISTER_STATUSES.DELETED]: COLORS_CLASSES.GRAY
    },
    RISKS: {
        [REGISTER_STATUSES.UNCHANGED]: COLORS_CLASSES.GREEN,
        [REGISTER_STATUSES.EDITED]: COLORS_CLASSES.YELLOW,
        [REGISTER_STATUSES.NEW]: COLORS_CLASSES.BLUE
    },
    REQUIREMENTS: {
        [REGISTER_STATUSES.COMPLIANT]: COLORS_CLASSES.BLUE,
        [REGISTER_STATUSES.NON_COMPLIANT]: COLORS_CLASSES.YELLOW
    },
    TRAININGS: {
        [REGISTER_STATUSES.SUGGESTED]: COLORS_CLASSES.LIGHT_GRAY,
        [REGISTER_STATUSES.APPROVED]: COLORS_CLASSES.BLUE,
        [REGISTER_STATUSES.SCHEDULED]: COLORS_CLASSES.YELLOW,
        [REGISTER_STATUSES.PERFORMED]: COLORS_CLASSES.GREEN,
        [REGISTER_STATUSES.CHANGED_REQUIREMENTS]: COLORS_CLASSES.PINK,
        [REGISTER_STATUSES.CANCELED]: COLORS_CLASSES.GRAY
    },
    MANAGEMENT_REVIEWS: {
        [REGISTER_STATUSES.PLANNED]: COLORS_CLASSES.BLUE,
        [REGISTER_STATUSES.LATE]: COLORS_CLASSES.PINK,
        [REGISTER_STATUSES.COMPLETED]: COLORS_CLASSES.GREEN
    }
}

const ROUTE_TYPE = {
    REGISTER: 'company-regulation-register',
    DOCUMENT: 'document'
}

export const MY_WORK = {
    FILTER_OPTIONS,
    FILTER_VALUES,
    CAN_ARCHIVE_DISCUSSIONS,
    MY_REGISTERS_STATUS_COLORS_CLASSES,
    CREATED_AT_FILTER_OPTIONS,
    CREATED_BY_FILTER_VALUES,
    ROUTE_TYPE
}
