<template>
    <div :class="wrapperClasses">
        <div class="cf-c-input__ctn">
            <input
                ref="name"
                :tabindex="tabindex"
                :name="name"
                :class="'cf-c-input__field ' + inputClasses"
                :type="type"
                :placeholder="
                    widthXs
                        ? ''
                        : ignoreLocalization
                        ? placeholder
                        : $t(placeholder)
                "
                :required="required"
                :autofocus="autofocus"
                :disabled="disabled"
                :value="value"
                :min="minValue"
                :max="maxValue"
                :maxlength="maxLength"
                :autocomplete="autocomplete"
                @input="handleInput"
                @blur="blurInput"
                @focus="$emit('focus', $event.target.value)"
            />
            <span class="cf-c-input__required">*</span>
        </div>
        <slot name="errorMessage" :default="errorMessage">
            <span v-if="!!errorMessage" class="cf-c-input__error">
                {{ errorMessage }}
            </span>
        </slot>
    </div>
</template>

<script>
import { INPUT_TYPES } from '../constants'

export default {
    props: {
        type: {
            type: String,
            required: false,
            default: 'text',
            validator (value) {
                return INPUT_TYPES.indexOf(value) !== -1
            }
        },

        name: {
            type: String,
            required: false,
            default: ''
        },

        ignoreLocalization: {
            type: Boolean,
            required: false,
            default: false
        },

        autocomplete: {
            type: String,
            required: false,
            default: 'off'
        },

        placeholder: {
            type: String,
            required: false,
            default: 'COMMON.PLEASE_SPECIFY'
        },

        required: {
            type: Boolean,
            required: false,
            default: false
        },

        autofocus: {
            type: Boolean,
            required: false,
            default: false
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        value: {
            type: [String, Number],
            required: false,
            default: ''
        },

        errorMessage: {
            type: String,
            required: false,
            default: ''
        },

        tabindex: {
            type: Number,
            required: false,
            default: 0
        },

        hasAbsolutePositionError: {
            type: Boolean,
            required: false,
            default: false
        },

        isWithinText: {
            type: Boolean,
            required: false,
            default: false
        },

        heightMd: {
            type: Boolean,
            required: false,
            default: false
        },

        heightSm: {
            type: Boolean,
            required: false,
            default: false
        },

        heightXs: {
            type: Boolean,
            required: false,
            default: false
        },

        heightAuto: {
            type: Boolean,
            required: false,
            default: false
        },

        widthXs: {
            type: Boolean,
            required: false,
            default: false
        },

        widthSm: {
            type: Boolean,
            required: false,
            default: false
        },

        width22: {
            type: Boolean,
            required: false,
            default: false
        },

        width30: {
            type: Boolean,
            required: false,
            default: false
        },

        width60: {
            type: Boolean,
            required: false,
            default: false
        },

        fullWidth: {
            type: Boolean,
            required: false,
            default: false
        },

        noBorders: {
            type: Boolean,
            required: false,
            default: false
        },

        inputClasses: {
            type: String,
            required: false,
            default: ''
        },

        minValue: {
            type: [String, Number],
            required: false,
            default: ''
        },

        maxValue: {
            type: [String, Number],
            required: false,
            default: ''
        },

        insideCollapsible: {
            type: Boolean,
            required: false,
            default: false
        },

        borderRadius: {
            type: Boolean,
            required: false,
            default: false
        },

        bgGray: {
            type: Boolean,
            required: false,
            default: false
        },

        textCenter: {
            type: Boolean,
            required: false,
            default: false
        },

        small: {
            type: Boolean,
            required: false,
            default: false
        },

        displayContents: {
            type: Boolean,
            required: false,
            default: false
        },

        single: {
            type: Boolean,
            required: false,
            default: false
        },

        integerInput: {
            type: Boolean,
            required: false,
            default: false
        },

        className: {
            type: String,
            required: false,
            default: ''
        },

        hasMarginBottom: {
            type: Boolean,
            required: false,
            default: false
        },

        maxLength: {
            type: [String, Number],
            required: false,
            default: ''
        }
    },
    computed: {
        wrapperClasses () {
            let classes = 'cf-c-input'

            if (this.errorMessage) {
                classes += ' cf-has-error'
            }

            if (this.hasAbsolutePositionError) {
                classes += ' cf-has-absolute-errors'
            }

            if (this.errorMessage && this.hasMarginBottom) {
                classes += ' cf-u-mb-md'
            }

            if (this.value) {
                classes += ' cf-has-text'
            }

            if (this.isWithinText) {
                classes += ' cf-c-input--within-text'
            }

            if (this.heightMd) {
                classes += ' cf-c-input--h-md'
            }

            if (this.heightSm) {
                classes += ' cf-c-input--h-sm'
            }

            if (this.heightXs) {
                classes += ' cf-c-input--h-xs'
            }

            if (this.heightAuto) {
                classes += ' cf-c-input--h-auto'
            }

            if (this.widthXs) {
                classes += ' cf-c-input--w-xs'
            }

            if (this.widthSm) {
                classes += ' cf-c-input--w-sm'
            }

            if (this.width22) {
                classes += ' cf-c-input--w-22'
            }

            if (this.width30) {
                classes += ' cf-c-input--w-30'
            }

            if (this.width60) {
                classes += ' cf-c-input--w-60'
            }

            if (this.fullWidth) {
                classes += ' cf-c-input--full-width'
            }

            if (this.noBorders) {
                classes += ' cf-c-input--no-borders'
            }

            if (this.borderRadius) {
                classes += ' cf-c-input--border-radius'
            }

            if (this.insideCollapsible) {
                classes += ' cf-c-input--inside-collapsible'
            }

            if (this.bgGray) {
                classes += ' cf-c-input--bg-gray'
            }

            if (this.textCenter) {
                classes += ' cf-c-input--text-center'
            }

            if (this.small) {
                classes += ' cf-c-input--small'
            }

            if (this.displayContents) {
                classes += ' cf-u-contents'
            }
            if (this.single) {
                classes += ' cf-c-input--single'
            }

            if (this.integerInput) {
                classes += ' cf-c-input--integer'
            }

            if (this.className) {
                classes += ` ${this.className}`
            }

            return classes
        }
    },

    methods: {
        handleInput (event) {
            const inputValue = event.target.value
            this.$emit('input', inputValue.trim())
        },

        blurInput (event) {
            const inputValue = event.target.value

            if (!inputValue.trim().length) {
                this.$refs.name.value = ''
            }

            this.$emit('blur', inputValue.trim())
        }
    }
}
</script>
