import RegisterWizard from './register-wizard'
import DocumentWizard from './document-wizard'
import ResponsibilityMatrix from './responsibility-matrix'
import MyWork from './my-work'
import { ROUTES_COMPLIANCE } from '@src/modules/compliance/constants'
import { DOCUMENT_WIZARD_ROUTES, MY_PERMISSIONS } from '@src/constants'
import { myPermissionsGuard } from './guards'
import RoutesDocumentWizard from '@src/modules/document-wizard/router'

export default {
    path: 'standard/:standard',
    component: () => import('@/containers/Regulation/Regulation'),
    props: true,
    children: [
        RegisterWizard,
        {
            name: ROUTES_COMPLIANCE.STEP_OVERVIEW.name,
            path: 'step/:step',
            component: () => import('@/containers/Regulation/RegulationStep'),
            props: true,
            meta: {
                requiresAuth: true
            },
            redirect: to => ({
                name: DOCUMENT_WIZARD_ROUTES.SINGLE_STEP.name,
                params: { ...to.params }
            }),
            children: [
                {
                    name: DOCUMENT_WIZARD_ROUTES.SINGLE_STEP.name,
                    path: 'details',
                    component: () =>
                        import('@/containers/Regulation/SingleStep'),
                    props: true,
                    meta: {
                        pageTitle: 'REGULATION.STEP_DETAILS',
                        title: 'REGULATION.STEP_DETAILS',
                        requiresAuth: true,
                        guards: [myPermissionsGuard],
                        permissions: [MY_PERMISSIONS.STEP_DETAILS_ACCESS]
                    }
                }
            ]
        },
        {
            name: 'registers-and-modules',
            path: 'registers-and-modules',
            component: () => import('@/containers/RegistersAndModules'),
            meta: {
                pageTitle: 'REGISTERS_AND_MODULES.TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.REGISTER_AND_MODULES_ACCESS]
            }
        },
        DocumentWizard,
        RoutesDocumentWizard,
        ResponsibilityMatrix,
        MyWork
    ]
}
