<template>
    <div
        class="cf-c-progress-bar"
        :class="[
            cssClasses,
            { 'cf-c-progress-bar--is-micro': micro },
            { 'cf-c-progress-bar--is-thick': thick }
        ]"
    >
        <div class="cf-c-progress-bar__progress" :style="`width: ${value}%`">
            <span v-if="showProgress">{{ value }}%</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            required: false,
            default: 0
        },

        max: {
            type: Number,
            required: false,
            default: 100
        },

        showProgress: {
            type: Boolean,
            required: false,
            default: false
        },

        cssClasses: {
            type: [Object, Array, String],
            required: false
        },

        micro: {
            type: Boolean,
            required: false,
            default: false
        },

        thick: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>
