import { supportService } from '@/services/api/support'

const actions = {
    async sendPaymentSupport ({ dispatch, rootGetters }, dataObject) {
        try {
            const companyId = rootGetters['company/company'].id

            await supportService.sendPaymentSupport(
                dataObject.subject,
                dataObject.message,
                companyId
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async scheduleExpertCall ({ commit, rootGetters, dispatch }) {
        const companyId = rootGetters['company/company'].id

        commit('SET_APP_LOADING', true, { root: true })
        try {
            return await supportService.scheduleExpertCall(companyId)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}

export const support = {
    namespaced: true,
    actions
}
