import Cookies from 'js-cookie'
import { COOKIE_LOCALSTORAGE } from '@/constants/storage'
import { config } from '../config'
import { LOGIN_REMEMBER_EXPIRATION } from '../constants/account'

function isBrowser () {
    return typeof window !== 'undefined'
}

export function parseVuex () {
    let vuex
    if (COOKIE_LOCALSTORAGE) {
        vuex = Cookies.get('vuex')
    } else if (isBrowser()) {
        vuex = window.localStorage.getItem('vuex')
    }
    return vuex ? JSON.parse(vuex) : null
}

export function getVuexModule (module) {
    const vuex = parseVuex()

    return vuex ? vuex[module] : vuex
}

export function getLocalStorageItem (key) {
    if (COOKIE_LOCALSTORAGE) {
        return Cookies.get(key) || null
    } else if (isBrowser()) {
        return window.localStorage.getItem(key)
    }
    return null
}

export function setLocalStorageItem (key, value) {
    if (COOKIE_LOCALSTORAGE) {
        Cookies.set(key, value)
    } else if (isBrowser()) {
        window.localStorage.setItem(key, value)
    }
}

export function removeLocalStorageItem (key) {
    if (COOKIE_LOCALSTORAGE) {
        Cookies.remove(key)
    } else if (isBrowser()) {
        window.localStorage.removeItem(key)
    }
}

export function doesLocalStorageItemExist (key) {
    if (isBrowser()) {
        return window.localStorage.getItem(key) !== null
    }
    return null
}

export function getCookie (key) {
    return Cookies.get(key) || null
}

export function setCookie (key, value, remember = false) {
    Cookies.set(key, value, {
        secure: config.isProductionEnv(),
        expires: remember ? LOGIN_REMEMBER_EXPIRATION : undefined
    })
}

export function removeCookie (key) {
    Cookies.remove(key)
}
