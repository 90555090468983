<template>
    <app-modal
        name="submit-for-review"
        ok-title="DOCUMENT_WIZARD.SUBMIT_FOR_REVIEW"
        title="DOCUMENT_WIZARD.REVIEW_MODAL.TITLE"
        @ok="handleSubmit"
        @cancel="handleCancel"
    >
        <template slot="body">
            <h5 class="cf-c-modal__subheading">
                {{ $t('DOCUMENT_WIZARD.REVIEW_MODAL.LABEL') }}
            </h5>
            <p class="cf-c-modal__text">
                {{ $t('DOCUMENT_WIZARD.REVIEW_MODAL.TEXT') }}
            </p>
            <div class="cf-u-display-inline-block cf-u-mb-default">
                <app-input
                    v-model="form.expires_in_days"
                    is-within-text
                    height-sm
                    width-xs
                    class="cf-u-mb-default"
                    type="number"
                    min-value="1"
                    :error-message="firstError('expires_in_days')"
                    has-absolute-position-error
                />
                {{ $t('COMMON.DAYS') }}
            </div>
            <h5 class="cf-c-modal__subheading">
                {{ $t('DOCUMENT_WIZARD.REVIEW_CHANGES') }}
            </h5>
            <app-input
                v-model="form.message"
                class="cf-u-mb-md"
                :max-length="100"
                :error-message="firstError('message')"
            />
        </template>
    </app-modal>
</template>

<script>
import { forEach } from 'lodash-es'
import { mapActions, mapGetters } from 'vuex'

export default {
    data () {
        return {
            form: {
                expires_in_days: '',
                message: ''
            }
        }
    },

    computed: {
        ...mapGetters('errors', ['firstError'])
    },

    methods: {
        ...mapActions('errors', ['clearErrors']),

        handleSubmit () {
            this.resetErrors()

            this.$emit('submit', this.form)
        },

        setInitialForm () {
            this.form = {
                expires_in_days: '',
                message: ''
            }
        },

        resetErrors () {
            forEach(this.form, (fieldValue, fieldKey) =>
                this.clearErrors(fieldKey)
            )
        },

        handleCancel () {
            this.resetErrors()
            this.setInitialForm()
        }
    }
}
</script>
