import { MENTION_SYMBOL, MENTION_ENTRY_SYMBOL } from '@/constants'
import { getFullNameOrEmail } from '@/services/utils/functions'

export const FORMAT_OPTIONS = {
    FOR_SEND_COMMENT: 'for_send_comment',
    FOR_VIEW_OF_COMMENT: 'for_view_of_comment'
}

const FORMAT_FUNCTIONS = {
    [FORMAT_OPTIONS.FOR_SEND_COMMENT]: formatSendComment,

    [FORMAT_OPTIONS.FOR_VIEW_OF_COMMENT]: formatViewComment
}

export function commentIsResolvable (...args) {
    return (
        commentableAuthorIsLoggedInUser(...args) ||
        commentCreatorIsLoggedInUser(...args) ||
        commentableOwnerIsLoggedInUser(...args)
    )
}

export function commentCreatorIsLoggedInUser (comment, companyMember) {
    const { creator } = comment

    return companyMember && creator ? creator.id === companyMember.id : false
}

export function formatComment (comment, users, formatOption) {
    let tagsIndices = getAllPositionsOfTagMention(comment)

    let formatedComment = []
    let currentIndex = 0

    while (tagsIndices.length > 0) {
        const tagIndex = tagsIndices.shift()

        formatedComment.push(comment.substring(currentIndex, tagIndex))

        let endOfMentionIndex = comment.indexOf(' ', tagIndex)

        endOfMentionIndex =
            endOfMentionIndex === -1 ? comment.length : endOfMentionIndex

        currentIndex = endOfMentionIndex

        const insertText = FORMAT_FUNCTIONS[formatOption](
            comment,
            users,
            tagIndex,
            endOfMentionIndex
        )

        formatedComment.push(`${MENTION_SYMBOL}${insertText}`)
    }

    formatedComment.push(comment.substring(currentIndex))

    return formatedComment.join('')
}

function getAllPositionsOfTagMention (comment) {
    let indicesOfTagMentions = []

    let currentIndex = 0

    do {
        const indexOfTag = comment.indexOf(MENTION_ENTRY_SYMBOL, currentIndex)

        if (
            indexOfTag !== -1 &&
            (indexOfTag === 0 || comment.charAt(indexOfTag - 1) === ' ')
        ) {
            indicesOfTagMentions.push(indexOfTag)
        }

        currentIndex = indexOfTag >= 0 ? indexOfTag + 1 : indexOfTag
    } while (currentIndex < comment.length && currentIndex !== -1)

    return indicesOfTagMentions
}

function commentableAuthorIsLoggedInUser (comment, companyMember) {
    const { commentable } = comment

    return companyMember && commentable?.author
        ? commentable.author.company_member_id === companyMember.id
        : false
}

function commentableOwnerIsLoggedInUser (comment, companyMember) {
    const { commentable } = comment

    if (!companyMember || !commentable) {
        return false
    }

    if (commentable.owner_user) {
        return commentable.owner_user.company_member_id === companyMember.id
    }

    return commentable.owner_company_member_id === companyMember.id
}

function formatSendComment (comment, users, tagIndex, endOfMentionIndex) {
    const parsedComment = comment.substring(tagIndex + 1, endOfMentionIndex)

    return users[parsedComment] ? `[id:${users[parsedComment]}]` : parsedComment
}

function formatViewComment (comment, users, tagIndex, endOfMentionIndex) {
    const splitPartsOfComment = comment
        .substring(tagIndex + 1, endOfMentionIndex)
        .split(':')

    const parsedComment = parseInt(
        splitPartsOfComment[1].substring(0, splitPartsOfComment[1].length - 1)
    )

    return users[parsedComment]
        ? getFullNameOrEmail(users[parsedComment])
        : parsedComment
}
