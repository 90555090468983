<template>
    <div :class="wrapperClasses">
        <div class="cf-f-vf-collapsible__marker-wrap">
            <div
                :class="[
                    'cf-f-vf-collapsible__marker',
                    {
                        'cf-f-vf-collapsible__marker--should-show':
                            shouldShow && !visible,
                        'cf-f-vf-collapsible__marker--should-not-show': !shouldShow,
                        'cf-f-vf-collapsible__marker--visible': visible
                    }
                ]"
            ></div>
            <div
                v-if="lastMarkerLine"
                :class="[
                    'cf-f-vf-collapsible__marker-line',
                    {
                        'cf-f-vf-collapsible__marker-line--should-show': shouldShow,
                        'cf-f-vf-collapsible__marker-line--should-not-show': !shouldShow
                    }
                ]"
            ></div>
        </div>
        <div class="cf-c-vf-collapsible__content-wrap">
            <div class="cf-c-vf-collapsible__header" :class="headerClasses">
                <div class="cf-c-vf-collapsible__header-ctn"></div>
                <div
                    :class="[
                        'cf-c-vf-collapsible__icon-wrap',
                        {
                            'cf-c-vf-collapsible__icon-wrap--no-cursor':
                                !shouldShow || !collapsible
                        }
                    ]"
                    @click="shouldShow && collapsible && toggleCollapse()"
                >
                    <div
                        :class="[
                            'cf-c-vf-collapsible__header-ctn',
                            {
                                'cf-c-vf-collapsible__header-ctn--should-show':
                                    shouldShow && !visible,
                                'cf-c-vf-collapsible__header-ctn--should-not-show':
                                    !shouldShow || !collapsible,
                                'cf-c-vf-collapsible__header-ctn--visible': visible
                            }
                        ]"
                    >
                        <slot name="header"></slot>
                    </div>
                    <div
                        :class="[
                            'cf-c-vf-collapsible__icon',
                            {
                                'cf-c-vf-collapsible__icon--should-show':
                                    shouldShow && !visible,
                                'cf-c-vf-collapsible__icon--should-not-show':
                                    !shouldShow || !collapsible,
                                'cf-c-vf-collapsible__icon--visible': visible
                            }
                        ]"
                    ></div>
                </div>
            </div>
            <div
                v-show="visible"
                class="cf-c-vf-collapsible__content"
                :class="contentClasses"
            >
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        lastMarkerLine: {
            type: Boolean,
            required: false,
            default: true
        },

        shouldShow: {
            type: Boolean,
            required: false,
            default: true
        },

        headerClasses: {
            type: Array,
            required: false,
            default: () => []
        },

        contentClasses: {
            type: Array,
            required: false,
            default: () => []
        },

        isVisible: {
            type: Boolean,
            required: false,
            default: true
        },

        collapsible: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    data () {
        return {
            visible: this.isVisible
        }
    },

    computed: {
        wrapperClasses () {
            let classes = 'cf-c-vf-collapsible'

            if (this.visible) {
                classes += ' cf-is-open'
            }

            return classes
        }
    },

    watch: {
        isVisible (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.visible = this.isVisible
            }
        }
    },

    methods: {
        toggleCollapse () {
            this.visible = !this.visible
            this.$emit('visibility-changed', this.visible)
        }
    }
}
</script>
