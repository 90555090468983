<template>
    <div class="cf-c-attach-file-wrapper">
        <div class="cf-u-display-flex cf-u-flex-wrap cf-u-mb-sm">
            <file-item
                v-for="(file, index) in files"
                :key="index"
                :name="file.name"
                :path="file.path"
                class="cf-u-mx-xs cf-u-mb-sm"
                :removable="!disabled"
                @file-deleted="handleDelete(index)"
            />
        </div>

        <label v-if="!disabled" class="cf-c-attach-file">
            <input
                ref="filesRef"
                type="file"
                multiple
                class="cf-c-add-file__input"
                :accept="accept"
                @change="handleChange"
            />
            <div class="cf-c-attach-file__ctn">
                <app-icon icon="attachment-icon" class="cf-u-mr-xs" />
                <div
                    class="cf-u-width-min-width-5 cf-u-text-center cf-u-font-size-xs"
                >
                    {{ $t('COMMON.ATTACH_A_FILE') }}
                </div>
            </div>
        </label>
    </div>
</template>

<script>
import { findIndex, forEach } from 'lodash-es'
import { toasts } from '@/mixins'
import { UPLOAD_MAX_FILES_SIZE, TOAST_TYPES } from '@/constants'

export default {
    mixins: [toasts],

    props: {
        accept: {
            type: String,
            required: false,
            default: ''
        },

        files: {
            type: Array,
            required: false,
            default: () => []
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        uploadSize () {
            return this.files.reduce((size, file) => size + file.size, 0)
        },

        maxUploadSize () {
            return UPLOAD_MAX_FILES_SIZE * 1000000
        }
    },

    methods: {
        handleChange () {
            forEach(this.$refs.filesRef.files, fileRef => {
                const { name, size } = fileRef
                const index = findIndex(this.files, { name, size })

                if (index !== -1) {
                    return
                }

                if (this.uploadSize + fileRef.size > this.maxUploadSize) {
                    this.showToast(
                        this.$t('COMMON.MAX_UPLOAD_SIZE_EXCEEDED'),
                        TOAST_TYPES.ERROR
                    )
                    return
                }

                this.files.push(fileRef)
            })

            this.$emit('file-uploaded', this.files)
        },

        handleDelete (index) {
            this.files.splice(index, 1)
            this.$emit('file-uploaded', this.files)
        }
    }
}
</script>
