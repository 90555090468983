import axios from 'axios'
import { SOA } from '@/constants'
import { objectKeyIntersection } from '@/services/utils/functions'

const { COMPANY_CONTROL_TASK_PARAMS } = SOA

const ENDPOINTS = {
    CONTROLS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/controls`,
    CONTROLS_PER_SECTIONS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/controls/sections`,
    COMPANY_CONTROL: (companyId, regulationId, companyControlId) =>
        `companies/${companyId}/regulations/${regulationId}/company-controls/${companyControlId}`,
    MARK_AS_SEEN: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/company-controls/seen`,
    COMPANY_CONTROLS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/company-controls`,
    IMPLEMENTATION_METHODS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/risk-treatment-plan`,
    SEND_TO_APPROVAL: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/resources/start-approval`,
    COMPANY_CONTROL_TASKS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/company-control-tasks`,
    COMPANY_CONTROL_TASK: (companyId, regulationId, companyControlTaskId) =>
        `companies/${companyId}/regulations/${regulationId}/company-control-tasks/${companyControlTaskId}`,
    COMPANY_CONTROL_STEPS: (companyId, regulationId, companyControlId) =>
        `companies/${companyId}/regulations/${regulationId}/company-controls/${companyControlId}/steps`,
    COMPANY_CONTROL_STEP: (companyId, regulationId, companyControlId, stepId) =>
        `companies/${companyId}/regulations/${regulationId}/company-controls/${companyControlId}/steps/${stepId}`,
    DOCUMENT_REMOVAL_INFO: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/company-controls/document-removal-info`,
    SYNC_STEPS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/sync-steps`,
    COMPLETE_FIRST_USAGE_OF_RTP: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/risk-treatment-plan/first-usage/complete`,
    UPDATE_DOCUMENTS_FOR_CONTROL: (companyId, regulationId, controlId) =>
        `companies/${companyId}/regulations/${regulationId}/controls/${controlId}/update-documents`,
    APPROVE_STATEMENT_OF_APPLICABILITY: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/statement-of-applicability-approved`
}

export default class SoaService {
    /**
     * Get controls
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     *
     * @returns Promise
     */
    async getControlsPerSections (companyId, regulationId) {
        try {
            const route = ENDPOINTS.CONTROLS_PER_SECTIONS(
                companyId,
                regulationId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get controls
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     *
     * @returns Promise
     */
    async getControls (companyId, regulationId) {
        try {
            const route = ENDPOINTS.CONTROLS(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update control
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} companyControlId
     * @param {Object} payload
     *
     * @returns Promise
     */
    async update (companyId, regulationId, companyControlId, payload) {
        try {
            const route = ENDPOINTS.COMPANY_CONTROL(
                companyId,
                regulationId,
                companyControlId
            )

            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Bulk Update controls
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Object} payload
     *
     * @returns void
     */
    async bulkUpdate (companyId, regulationId, payload) {
        try {
            const route = ENDPOINTS.COMPANY_CONTROLS(companyId, regulationId)

            await axios.put(route, payload)
        } catch (error) {
            throw error
        }
    }

    /**
     * Mark company controls as seen
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Object} payload
     *
     * @returns void
     */
    async markControlsAsSeen (companyId, regulationId, payload) {
        try {
            const route = ENDPOINTS.MARK_AS_SEEN(companyId, regulationId)

            await axios.put(route, payload)
        } catch (error) {
            throw error
        }
    }

    /**
     * Prepare company control task payload
     *
     * @param {Object} data
     *
     * @returns {Object}
     */
    prepareControlTaskPayload (data) {
        const payload = {
            ...data,
            assignee_id: data.assigned_to,
            company_control_id: data.target_id
        }

        return objectKeyIntersection(payload, COMPANY_CONTROL_TASK_PARAMS)
    }

    /**
     * Create a control task
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Object} companyControlTask
     *
     * @returns Promise
     */
    async addControlTask (companyId, regulationId, companyControlTask) {
        try {
            const route = ENDPOINTS.COMPANY_CONTROL_TASKS(
                companyId,
                regulationId
            )
            const { data } = await axios.post(
                route,
                this.prepareControlTaskPayload(companyControlTask)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update a control task
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} companyControlTaskId
     * @param {Object} companyControlTask
     *
     * @returns Promise
     */
    async updateControlTask (
        companyId,
        regulationId,
        companyControlTaskId,
        companyControlTask
    ) {
        try {
            const route = ENDPOINTS.COMPANY_CONTROL_TASK(
                companyId,
                regulationId,
                companyControlTaskId
            )
            const { data } = await axios.put(
                route,
                this.prepareControlTaskPayload(companyControlTask)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Delete a control task
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} companyControlTaskId
     *
     * @returns Promise
     */
    async removeControlTask (companyId, regulationId, companyControlTaskId) {
        try {
            const route = ENDPOINTS.COMPANY_CONTROL_TASK(
                companyId,
                regulationId,
                companyControlTaskId
            )

            const { data } = await axios.delete(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Add a control document
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} companyControlId
     * @param {Object} payload
     *
     * @returns void
     */
    async addControlDocument (
        companyId,
        regulationId,
        companyControlId,
        payload
    ) {
        try {
            const route = ENDPOINTS.COMPANY_CONTROL_STEPS(
                companyId,
                regulationId,
                companyControlId
            )
            const { data } = await axios.post(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Delete a control document
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} companyControlId
     * @param {Number} stepId
     *
     * @returns void
     */
    async removeControlDocument (
        companyId,
        regulationId,
        companyControlId,
        stepId
    ) {
        try {
            const route = ENDPOINTS.COMPANY_CONTROL_STEP(
                companyId,
                regulationId,
                companyControlId,
                stepId
            )

            const { data } = await axios.delete(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get implementation methods
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getImplementationMethods (companyId, regulationId) {
        try {
            const route = ENDPOINTS.IMPLEMENTATION_METHODS(
                companyId,
                regulationId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Send to risk owners
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Array} resourceIds
     *
     * @returns Promise
     */
    async sendToApproval (companyId, regulationId, payload) {
        try {
            const route = ENDPOINTS.SEND_TO_APPROVAL(companyId, regulationId)
            const { data } = await axios.post(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get unnecessary steps info
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getUnnecessarySteps (companyId, regulationId) {
        try {
            const route = ENDPOINTS.DOCUMENT_REMOVAL_INFO(
                companyId,
                regulationId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Synchronize steps after soa changes
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async syncSteps (companyId, regulationId) {
        try {
            const route = ENDPOINTS.SYNC_STEPS(companyId, regulationId)
            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }
    /**
     * Update documents for control
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulation
     * @param {String|Number} controlId
     * @param {Object} data
     *
     * @returns Promise
     */
    async updateDocumentsForControl (
        companyId,
        regulation,
        controlId,
        postData
    ) {
        try {
            const route = ENDPOINTS.UPDATE_DOCUMENTS_FOR_CONTROL(
                companyId,
                regulation,
                controlId
            )

            const { data } = await axios.post(route, postData)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update documents for control
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulation
     *
     * @returns Promise
     */
    async approveStatementOfApplicability (companyId, regulation) {
        try {
            const route = ENDPOINTS.APPROVE_STATEMENT_OF_APPLICABILITY(
                companyId,
                regulation
            )

            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const soaService = new SoaService()
