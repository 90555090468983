<template>
    <div class="cf-c-data-card-body__row">
        <div class="cf-c-data-card-body__label">
            {{ title }}
        </div>
        <div class="cf-c-data-card-body__value" :class="textColorStyles">
            {{ content }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },

        content: {
            type: [String, Number, Array],
            required: true
        },

        textColor: {
            type: String,
            required: false,
            default: ''
        }
    },

    computed: {
        textColorStyles () {
            if (this.textColor.length) {
                return 'cf-u-color-' + this.textColor
            }

            return ''
        }
    }
}
</script>
