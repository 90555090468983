import { MY_PERMISSIONS } from '@src/constants'
import { myPermissionsGuard } from '@src/router/guards'
import { ROUTES_COMPLIANCE } from './constants'

export default {
    name: ROUTES_COMPLIANCE.BASE.name,
    component: () => import('./ModuleCompliance'),
    path: 'standard/:standard/compliance',
    props: true,
    redirect: to => ({
        name: ROUTES_COMPLIANCE.AUDIT_AND_EVIDENCE.name,
        params: { ...to.params }
    }),
    meta: {
        requiresAuth: true
    },
    children: [
        {
            path: 'implementation-steps',
            name: ROUTES_COMPLIANCE.STEPS.name,
            component: () =>
                import('./implementation-steps/ImplementationSteps'),
            props: true,
            meta: {
                pageTitle: 'COMPLIANCE.PAGE_TITLES.IMPLEMENTATION_STEPS',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.IMPLEMENTATION_STEPS_ACCESS]
            }
        },
        {
            path: 'preparation',
            name: ROUTES_COMPLIANCE.PREPARATION.name,
            component: () => import('./audit-and-evidence/AuditEvidence'),
            props: true,
            meta: {
                pageTitle: 'COMPLIANCE.PAGE_TITLES.PREPARATION',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.AUDIT_AND_EVIDENCE_ACCESS]
            }
        },
        {
            path: 'implementation',
            name: ROUTES_COMPLIANCE.IMPLEMENTATION.name,
            component: () => import('./audit-and-evidence/AuditEvidence'),
            props: true,
            meta: {
                pageTitle: 'COMPLIANCE.PAGE_TITLES.IMPLEMENTATION',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.AUDIT_AND_EVIDENCE_ACCESS]
            }
        },
        {
            path: 'audit-and-evidence',
            name: ROUTES_COMPLIANCE.AUDIT_AND_EVIDENCE.name,
            component: () => import('./audit-and-evidence/AuditEvidence'),
            props: true,
            meta: {
                pageTitle: 'COMPLIANCE.PAGE_TITLES.AUDIT_AND_EVIDENCE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.AUDIT_AND_EVIDENCE_ACCESS]
            }
        },
        {
            path: 'maintenance',
            name: ROUTES_COMPLIANCE.MAINTENANCE.name,
            component: () => import('./maintenance/Maintenance'),
            props: true,
            meta: {
                pageTitle: 'COMPLIANCE.PAGE_TITLES.MAINTENANCE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.MAINTENANCE_ACCESS]
            }
        }
    ]
}
