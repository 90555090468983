import { ROUTES_AUTH } from './constants'
import { COMMON_PAGES, ROUTES } from '@src/constants'
import { mfaGuard } from '@src/router/guards'

export default {
    path: '/',
    name: 'auth',
    component: () => import('@src/modules/auth/ModuleAuth'),
    redirect: () => {
        window.location.href = ROUTES.ADVISERA_CONFORMIO
    },
    children: [
        {
            path: 'login',
            name: ROUTES_AUTH.LOGIN.name,
            component: () => import('@src/modules/auth/AppLogin'),
            meta: {
                pageTitle: 'ACCOUNT.LOGINTITLE',
                redirectsAuthenticated: true
            }
        },
        {
            path: 'verify-mfa',
            name: ROUTES.MFA.VERIFY.name,
            component: () => import('@src/containers/Auth/VerifyMfa'),
            meta: {
                pageTitle: 'ACCOUNT.MFA_TITLE',
                redirectsAuthenticated: true,
                guards: [mfaGuard]
            }
        },
        {
            path: 'setup-mfa',
            name: ROUTES.MFA.SETUP.name,
            component: () => import('@src/containers/Auth/SetupMfa'),
            meta: {
                pageTitle: 'ACCOUNT.MFA_SETUP.TITLE',
                redirectsAuthenticated: true,
                guards: [mfaGuard]
            }
        },
        {
            path: 'register',
            name: 'register',
            component: () => import('@src/containers/Auth/AppRegister'),
            meta: {
                pageTitle: 'ACCOUNT.JOIN_AN_ACCOUNT_TITLE',
                redirectsAuthenticated: false
            }
        },
        {
            path: ROUTES_AUTH.PASSWORD_RESET.name,
            name: 'password-reset',
            component: () => import('@src/modules/auth/AppForgotPassword'),
            meta: {
                pageTitle: 'ACCOUNT.FORGOT_PASSWORD',
                redirectsAuthenticated: true
            }
        },
        {
            path: ROUTES_AUTH.PASSWORD_CHANGE.name,
            name: 'change-password',
            component: () => import('@src/modules/auth/AppResetPassword'),
            meta: {
                pageTitle: 'ACCOUNT.RESET_PASSWORD_TITLE',
                redirectsAuthenticated: true
            }
        },
        {
            path: 'pricing',
            name: 'pricing',
            component: () => import('@src/containers/Pricing/PricingPlans'),
            meta: {
                pageTitle: 'PRICING.CHOOSE_A_PLAN',
                requiresAuth: true
            }
        },
        {
            path: 'forbidden',
            name: COMMON_PAGES.FORBIDDEN.name,
            component: () => import('@src/containers/Errors/403')
        },
        {
            path: 'not-found',
            name: COMMON_PAGES.NOT_FOUND.name,
            component: () => import('@src/containers/Errors/404')
        },
        {
            path: 'payment-required',
            name: COMMON_PAGES.PAYMENT_REQUIRED.name,
            component: () => import('@src/containers/Errors/402')
        },
        {
            path: 'document-link-expired',
            name: COMMON_PAGES.SHAREABLE_DOCUMENT_LINK_EXPIRED.name,
            component: () => import('@src/containers/Errors/406')
        },
        {
            path: 'invitation-already-accepted',
            name: COMMON_PAGES.INVITATION_ALREADY_ACCEPTED.name,
            component: () => import('@src/containers/Errors/409')
        },
        {
            path: 'token-expired',
            name: COMMON_PAGES.TOKEN_EXPIRED.name,
            component: () =>
                import('@src/containers/Errors/Specific/InvitationTokenExpired')
        },
        {
            path: 'user-deactivated',
            name: COMMON_PAGES.USER_ACCOUT_DEACTIVATED.name,
            component: () =>
                import('@src/containers/Errors/Specific/UserAccountDeactivated')
        }
    ]
}
