import axios from 'axios'

const ENDPOINTS = {
    REVIEWS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/management-reviews`,
    ACTIVITIES: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/management-reviews-activities`,
    META_ACTIVITIES: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/meta-management-reviews`,
    SESSION: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/management-reviews-session`
}

export default class ManagementReviewService {
    /**
     *
     * @param companyId
     * @param regulationId
     * @returns Promise
     */
    async getSessionData (companyId, regulationId) {
        try {
            const route = ENDPOINTS.SESSION(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     *
     * @param companyId
     * @param regulationId
     * @returns Promise
     */
    async finishReviewSession (companyId, regulationId) {
        try {
            const route = `${ENDPOINTS.SESSION(companyId, regulationId)}/finish`
            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     *
     * @param companyId
     * @param regulationId
     * @returns Promise
     */
    async startSession (companyId, regulationId) {
        try {
            const route = ENDPOINTS.SESSION(companyId, regulationId)
            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get management review activities
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getActivities (companyId, regulationId) {
        try {
            const route = ENDPOINTS.ACTIVITIES(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get management reviews
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getManagementReviews (companyId, regulationId) {
        try {
            const route = ENDPOINTS.REVIEWS(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get management review activities
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getMetaActivities (companyId, regulationId) {
        try {
            const route = ENDPOINTS.META_ACTIVITIES(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update management review settings
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Array} payload
     *
     * @returns Promise
     */
    async updateSettings (companyId, regulationId, payload) {
        try {
            const route = ENDPOINTS.META_ACTIVITIES(companyId, regulationId)
            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const managementReviewService = new ManagementReviewService()
