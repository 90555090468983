import { each, keys, isEmpty, first, last, get } from 'lodash-es'
import { documentService } from '@/services/api/document'
import { DOCUMENT_TYPES } from '@/constants'
import { formatComment, FORMAT_OPTIONS } from '@/services/utils/comment'

function getInitialState () {
    return {
        document: null,
        commentAdded: false,
        documentHistory: [],
        documentMeta: [],
        documents: [],
        suggestedResponsibilities: [],
        tasks: {
            fetched: false,
            all: []
        },
        customInputs: []
    }
}

const state = getInitialState()

const actions = {
    async getConformioDocuments ({ commit, rootGetters }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const documents = await documentService.getConformioDocuments({
                companyId,
                regulationId
            })

            commit('SET_DOCUMENTS', documents)
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getDocuments ({ commit }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const documents = await documentService.getDocuments()

            commit('SET_DOCUMENTS', documents)
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async addDocument ({ commit }, data) {
        const document = { ...data, id: Math.random() }
        commit('ADD_DOCUMENT', document)
        return document
    },

    async getDocument ({ commit, dispatch, rootGetters }, data) {
        if (!data.hideLoader) {
            commit('SET_APP_LOADING', true, { root: true })
        }
        try {
            if (data.hideLoader) {
                delete data.hideLoader
            }
            const document = await documentService.getDocument(data)

            const users = rootGetters['company/usersById']

            commit('SET_DOCUMENT', { document, users })

            commit('regulation/SET_DOCUMENT_STEP', document.step, {
                root: true
            })
            commit(
                'regulation/SET_USEFUL_LINKS',
                get(document.step, 'useful_links', ''),
                {
                    root: true
                }
            )
            commit(
                'comment/SET_CURRENT_DISCUSSIONS',
                { discussions: document.discussions, users },
                {
                    root: true
                }
            )
            commit(
                'comment/SET_CURRENT_COMMENT_TARGETABLE_TYPE',
                DOCUMENT_TYPES.DOCUMENT,
                { root: true }
            )
            commit('comment/SET_CURRENT_COMMENT_TARGETABLE_ID', document.id, {
                root: true
            })

            dispatch('getDocumentHistory', document.id)
        } finally {
            if (!data.hideLoader) {
                commit('SET_APP_LOADING', false, { root: true })
            }
        }
    },

    /**
     *
     * @param commit
     * @param dispatch
     * @param rootGetters
     * @param data
     * @returns {Promise<void>}
     */
    async getDocumentHistory ({ commit, dispatch, rootGetters }, documentId) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation']?.id

            if (!companyId || !regulationId) {
                return
            }

            const history = await documentService.getDocumentHistory({
                companyId,
                regulationId,
                documentId
            })

            commit('SET_DOCUMENT_HISTORY', history)
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getCustomDocument ({ commit, rootGetters }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        const { companyId, documentId, token, custom } = data
        try {
            const document = await documentService.getDocumentById(
                companyId,
                documentId,
                token,
                custom
            )

            const users = rootGetters['company/usersById']

            commit('SET_DOCUMENT', { document, users })
            commit(
                'comment/SET_CURRENT_DISCUSSIONS',
                { discussions: document.discussions, users },
                {
                    root: true
                }
            )
            commit(
                'comment/SET_CURRENT_COMMENT_TARGETABLE_TYPE',
                DOCUMENT_TYPES.DOCUMENT,
                { root: true }
            )
            commit('comment/SET_CURRENT_COMMENT_TARGETABLE_ID', document.id, {
                root: true
            })
        } catch (err) {
            throw err
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async uploadDocument ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        let document = null
        try {
            document = await documentService.uploadDocument(data)

            const users = rootGetters['company/usersById']

            commit('SET_DOCUMENT', { document, users })
        } catch (err) {
            const error = err

            if (err.response?.data?.errors?.document?.length) {
                error.response.data.message =
                    err.response.data.errors.document[0]
            }

            dispatch('errors/handleError', error, { root: true })

            throw error.response.data.message
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }

        return document
    },

    async uploadCustomDocument ({ commit, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        let document = null
        try {
            document = await documentService.uploadDmsDocument(data)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })

            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
        return document
    },

    async deleteDocument ({ commit }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            await documentService.deleteDocument(data)
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async createConformioDocument ({ commit, rootGetters }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const document = await documentService.createConformioDocument(data)

            const users = rootGetters['company/usersById']

            commit('SET_DOCUMENT', { document, users })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async stopReview ({ commit, dispatch, rootGetters }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const { documentId, versionId } = data

            const document = await documentService.stopReview(
                companyId,
                documentId,
                versionId
            )

            const users = rootGetters['company/usersById']

            commit('SET_DOCUMENT', { document, users })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async stopApproval ({ commit, dispatch, rootGetters }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const { documentId, versionId } = data

            const document = await documentService.stopApproval(
                companyId,
                documentId,
                versionId
            )

            const users = rootGetters['company/usersById']

            commit('SET_DOCUMENT', { document, users })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async withdrawDocument ({ commit, dispatch, rootGetters }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const { documentId, versionId } = data

            const document = await documentService.withdrawDocument(
                companyId,
                documentId,
                versionId
            )

            const users = rootGetters['company/usersById']

            commit('SET_DOCUMENT', { document, users })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async submitDocumentInputs ({ getters, rootGetters, dispatch }, data) {
        try {
            const { form } = data
            const companyId = rootGetters['company/company'].id
            const { document } = getters
            if (!document || !companyId) {
                return
            }

            await documentService.submitForm(
                companyId,
                document.id,
                document.version.id,
                first(document.step.templates).id,
                form
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async getDocumentMeta ({ commit, rootGetters, dispatch }, { documentId }) {
        const companyId = rootGetters['company/company'].id
        const regulationId = rootGetters['regulation/currentRegulation'].id

        try {
            const meta = await documentService.getDocumentMeta(
                companyId,
                regulationId,
                documentId
            )

            commit('SET_DOCUMENT_META', meta)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async saveCustomInput ({ getters, rootGetters, dispatch, commit }, data) {
        try {
            const companyId = rootGetters['company/company'].id

            const { document } = getters

            if (!document || !companyId) {
                return
            }

            const customInput = await documentService.saveCustomInput(
                companyId,
                document.id,
                data
            )

            commit('UPDATE_CUSTOM_INPUT', customInput)

            return customInput
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async updateDocument ({ commit, rootGetters }, document) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const updatedDocument = await documentService.updateDocument(
                document
            )

            const users = rootGetters['company/usersById']

            commit('SET_DOCUMENT', { document: updatedDocument, users })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getCustomDocumentMetaData (
        { commit, rootGetters },
        { documentId, companyId, regulationId }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const meta = await documentService.getCustomDocumentMeta(
                companyId,
                regulationId,
                documentId
            )

            commit('SET_DOCUMENT_META', meta)
            return meta
        } catch (err) {
            throw err
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getSuggestedResponsibilities (
        { rootGetters, dispatch, commit },
        { stepId }
    ) {
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const suggestedResponsibilities = await documentService.getSuggestedResponsibilities(
                companyId,
                regulationId,
                stepId
            )

            commit('SET_SUGGESTED_RESPONSIBILITIES', suggestedResponsibilities)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        }
    },

    async changeSuggestedResponsiblityActiveStatus (
        { rootGetters, dispatch, commit },
        changedSuggestedResponsibilities
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await documentService.changeSuggestedResponsiblityActiveStatus(
                companyId,
                regulationId,
                changedSuggestedResponsibilities
            )

            commit(
                'CHANGE_SUGGESTED_RESPONSIBILITY_ACTIVE_STATUS',
                changedSuggestedResponsibilities
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getDocumentTasks ({ rootGetters, dispatch, commit }, documentId) {
        let tasks = []

        try {
            const companyId =
                rootGetters['company/company'] &&
                rootGetters['company/company'].id
            const regulationId =
                rootGetters['regulation/currentRegulation'] &&
                rootGetters['regulation/currentRegulation'].id

            if (companyId >= 0 && regulationId >= 0 && documentId >= 0) {
                tasks = await documentService.getDocumentTasks(
                    companyId,
                    regulationId,
                    documentId
                )
            }
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_DOCUMENT_TASKS', { all: tasks, fetched: true })
        }
    },

    async getCustomInputs ({ commit }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const customInputs = await documentService.getCustomDocumentInputs(
                data
            )

            commit('SET_DOCUMENT_CUSTOM_INPUTS', customInputs)

            return customInputs
        } catch (err) {
            throw err
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}

const mutations = {
    ADD_DOCUMENT (state, document) {
        state.documents.push(document)
    },

    SET_DOCUMENT (
        state,
        {
            document = null,
            users = null,
            formatOption = FORMAT_OPTIONS.FOR_VIEW_OF_COMMENT
        }
    ) {
        const version = !isEmpty(document.targetable_versions)
            ? last(document.targetable_versions)
            : null
        const discussions = document.discussions
            ? document.discussions.map(discussion => ({
                  ...discussion,
                  content: users
                      ? formatComment(discussion.content, users, formatOption)
                      : discussion.content
              }))
            : []

        state.document = {
            ...document,
            discussions,
            status: document.status || document.current_status,
            versions: document.targetable_versions && [
                ...document.targetable_versions
            ],
            version: version ?? null,
            last_approval_action: document.last_approval_action ?? null
        }
    },

    RESET_DOCUMENT (state) {
        const s = getInitialState()
        each(keys(state), key => {
            state[key] = s[key]
        })
    },

    SET_DOCUMENT_HISTORY (state, history) {
        state.documentHistory = history
    },

    SET_DOCUMENT_META (state, meta) {
        state.documentMeta = meta
    },

    SET_DOCUMENTS (state, documents) {
        state.documents = documents
    },

    SET_SUGGESTED_RESPONSIBILITIES (state, suggestedResponsibilities) {
        state.suggestedResponsibilities = suggestedResponsibilities
    },

    CHANGE_SUGGESTED_RESPONSIBILITY_ACTIVE_STATUS (
        state,
        suggestedResponsibilities
    ) {
        state.suggestedResponsibilities = state.suggestedResponsibilities.map(
            suggestedResponsibility => {
                const changedSuggestedResponsibility = suggestedResponsibilities.find(
                    changedSuggestedResponsibility =>
                        changedSuggestedResponsibility.id ===
                        suggestedResponsibility.id
                )

                return changedSuggestedResponsibility
                    ? {
                          ...suggestedResponsibility,
                          checked: changedSuggestedResponsibility.checked
                      }
                    : suggestedResponsibility
            }
        )
    },

    SET_DOCUMENT_TASKS (state, tasksData) {
        state.tasks = {
            ...state.tasks,
            ...tasksData
        }
    },

    SET_DOCUMENT_CUSTOM_INPUTS (state, customInputs) {
        state.customInputs = customInputs
    },

    UPDATE_CUSTOM_INPUT (state, customInput) {
        const stateCustomInput = state.customInputs.find(
            element => element.name === customInput.name
        )

        if (stateCustomInput) {
            // Update custom input value
            stateCustomInput.value = customInput.value
        } else {
            // Insert custom input value
            state.customInputs.push(customInput)
        }
    }
}

const getters = {
    document: state => state.document,
    documents: state => state.documents,
    commentsLoading: state => state.commentsLoading,
    lastDocumentVersion: state =>
        !isEmpty(state.document) && !isEmpty(state.document.targetable_versions)
            ? first(state.document.targetable_versions)
            : null,
    commentAdded: state => state.commentAdded,
    documentHistory: state => state.documentHistory,
    documentMeta: state => state.documentMeta,
    suggestedResponsibilities: state => state.suggestedResponsibilities,
    tasks: state => state.tasks,
    customInputs: state => state.customInputs
}

export const document = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
