var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cf-c-checkbox-list",class:[{ 'cf-c-checkbox-list--small': _vm.small }]},[(
            _vm.list.length || (_vm.$slots['add-new'] && _vm.$slots['add-new'].length)
        )?_c('div',{staticClass:"cf-u-display-flex cf-u-flex-direction-column"},[(_vm.byCategory)?_vm._l((_vm.list),function(item,index){return _c('app-collapsible',{key:index,attrs:{"highlighted":"","horizontal":false}},[_c('template',{slot:"target"},[_vm._t("target",function(){return [_vm._v(" "+_vm._s(item[_vm.label])+" ")]})],2),_c('template',{slot:"content"},_vm._l((item.assets),function(asset,index){return _c('div',{key:index,class:[
                            'cf-c-checkbox-list__item',
                            {
                                'cf-c-checkbox-list__selected': _vm.isItemSelected(
                                    asset
                                )
                            }
                        ],on:{"click":function($event){return _vm.handleClick(asset)}}},[_vm._v(" "+_vm._s(asset[_vm.label])+" ")])}),0)],2)}):_vm._l((_vm.list),function(item){return _c('div',{key:item[_vm.trackBy],class:[
                    'cf-c-checkbox-list__item',
                    { 'cf-c-checkbox-list__selected': _vm.isItemSelected(item) }
                ],on:{"click":function($event){return _vm.handleClick(item)}}},[_vm._v(" "+_vm._s(item[_vm.label])+" ")])})],2):_vm._e(),(_vm.shouldShowEmptyState)?_c('div',{staticClass:"cf-c-checkbox-list__empty-list"},[_vm._v(" "+_vm._s(_vm.$t(_vm.emptyListMessage))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }