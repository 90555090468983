<template>
    <span>
        <span
            v-if="shouldShowShortcode"
            :class="{ 'cf-c-shortcode': !approved }"
        >
            {{ expression }}
        </span>
        <component
            :is="inputComponent"
            v-if="inputComponent"
            :value="value"
            :input="input"
            :disabled="isDisabled"
            :approved="approved"
            :form="form"
        />
    </span>
</template>

<script>
import { find, get, kebabCase, isNil } from 'lodash-es'
import { inputFactory } from '@/mixins'
import { filterDependingInputs } from '@/services/utils/functions'

export default {
    components: {
        'parsed-text-input': () =>
            import('@/components/regulation/inputs/template/ParsedTextInput'),
        'parsed-multiline-input': () =>
            import(
                '@/components/regulation/inputs/template/ParsedMultilineInput'
            ),
        'parsed-table-input': () =>
            import('@/components/regulation/inputs/template/ParsedTableInput'),
        'parsed-one-per-line-input': () =>
            import(
                '@/components/regulation/inputs/template/ParsedOnePerLineInput'
            ),
        'parsed-radio-input': () =>
            import('@/components/regulation/inputs/template/ParsedRadioInput'),
        'parsed-company-member-radio-input': () =>
            import(
                '@/components/regulation/inputs/template/ParsedCompanyMemberRadioInput'
            ),
        'parsed-company-member-radio-input-picker': () =>
            import(
                '@/components/regulation/inputs/template/ParsedCompanyMemberRadioInputPicker'
            ),
        'parsed-checkbox-input': () =>
            import(
                '@/components/regulation/inputs/template/ParsedCheckboxInput'
            ),
        'parsed-radio-input-with-text-entry': () =>
            import(
                '@/components/regulation/inputs/template/ParsedRadioInputWithTextEntry'
            ),
        'parsed-user-defined-checkbox-input': () =>
            import(
                '@/components/regulation/inputs/template/ParsedUserDefinedCheckboxInput'
            ),
        'parsed-single-job-title-input': () =>
            import(
                '@/components/regulation/inputs/template/ParsedSingleJobTitleInput'
            )
    },
    mixins: [inputFactory],

    props: {
        expression: {
            type: String,
            required: false,
            default: ''
        },

        inputValue: {
            type: [String, Array, Number],
            required: false,
            default: ''
        },

        inputName: {
            type: String,
            required: false,
            default: ''
        },

        inputs: {
            type: Array,
            required: false,
            default: () => []
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        approved: {
            type: Boolean,
            required: false,
            default: false
        },

        form: {
            type: Object,
            required: true
        }
    },

    computed: {
        currentQuestionName () {
            const questionIndex = this.$route.query.question ?? 0

            const correctInputs = filterDependingInputs(this.inputs, this.form)

            if (
                (!questionIndex && questionIndex !== 0) ||
                !correctInputs[questionIndex]
            ) {
                return null
            }

            return correctInputs[questionIndex].name
        },

        isDisabled () {
            return this.disabled || this.currentQuestionName !== this.inputName
        },

        input () {
            return find(this.inputs, { name: this.inputName })
        },

        inputType () {
            return kebabCase(get(this, 'input.descriptor.field_type', ''))
        },

        inputComponent () {
            return this.inputType ? `parsed-${this.inputType}` : null
        },

        isInputValueFalsy () {
            return (
                isNil(this.value) || this.value === false || this.value === ''
            )
        },

        shouldShowShortcode () {
            return Array.isArray(this.value)
                ? !this.value.length
                : this.isInputValueFalsy
        },

        value () {
            try {
                return Array.isArray(this.inputValue)
                    ? this.inputValue
                    : JSON.parse(this.inputValue)
            } catch (error) {
                return this.injectFieldValues(this.inputValue)
            }
        }
    }
}
</script>
