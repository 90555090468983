var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{class:[
        'cf-c-labeled-checkbox',
        { 'cf-c-labeled-checkbox--pure-checkbox': _vm.pureCheckbox },
        { 'cf-c-labeled-checkbox--truncated': _vm.truncated },
        { 'cf-c-labeled-checkbox--text-justified': _vm.textJustified },
        { 'cf-c-labeled-checkbox--small': _vm.small },
        { 'cf-c-labeled-checkbox--big': _vm.big },
        _vm.clickableArea && `cf-c-labeled-checkbox--clickable-${_vm.clickableArea}`
    ]},[_c('div',{staticClass:"cf-c-labeled-checkbox__ctn"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checked),expression:"checked"}],staticClass:"cf-c-labeled-checkbox__input",attrs:{"type":"checkbox","disabled":_vm.disabled},domProps:{"value":_vm.value,"checked":_vm.value,"checked":Array.isArray(_vm.checked)?_vm._i(_vm.checked,_vm.value)>-1:(_vm.checked)},on:{"change":[function($event){var $$a=_vm.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checked=$$c}},function($event){return _vm.$emit('input', $event.target.checked)}]}}),_c('div',{staticClass:"cf-c-labeled-checkbox__icon-wrapper",class:[
                { 'cf-is-checked': _vm.checked },
                { 'cf-is-disabled': _vm.disabled }
            ]},[_c('app-icon',{attrs:{"size":_vm.iconSize,"icon":_vm.checked ? 'check-on-icon' : 'check-off-icon'}})],1),(!_vm.pureCheckbox)?_c('span',{staticClass:"cf-c-labeled-checkbox__text"},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]})],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }