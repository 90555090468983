import { MY_PERMISSIONS, REGISTERS_PERMISSIONS } from '@src/constants'
import { myPermissionsGuard } from '@src/router/guards'
import { REGISTER_TYPE, ROUTES_REGISTER_OF_REQUIREMENTS } from './constants'

export default {
    name: ROUTES_REGISTER_OF_REQUIREMENTS.BASE.name,
    path: 'requirements',
    component: () => import('./Requirements'),
    meta: {
        pageTitle: 'REQUIREMENTS.PAGE_TITLE',
        requiresAuth: true,
        register: REGISTER_TYPE,
        guards: [myPermissionsGuard],
        permissions: [
            MY_PERMISSIONS.REGISTER_OF_REQUIREMENTS_ACCESS,
            REGISTERS_PERMISSIONS.REGISTER_OF_REQUIREMENTS
        ]
    }
}
