<template>
    <div class="cf-c-radio-group">
        <template v-for="(input, index) in inputs">
            <div
                v-if="!input.hidden"
                :key="index"
                class="cf-c-radio-group__ctn"
            >
                <div class="cf-c-radio-group__item">
                    <app-radio-input
                        :disabled="disabled"
                        :name="name"
                        :label="input.label"
                        :radio-value="input.value"
                        :value="selectedValue"
                        :checked="input.value === selectedValue"
                        @input="handleInput"
                    />
                    <div
                        v-if="input.isEditable"
                        class="cf-c-radio-group__addition"
                    >
                        <app-input
                            :value="input.value"
                            :disabled="
                                input.value !== selectedValue || disabled
                            "
                            :error-message="errorMessage"
                            :type="type"
                            @input="handleInput"
                        />
                    </div>
                </div>
            </div>
        </template>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        inputs: {
            type: Array,
            required: true
        },

        name: {
            type: String,
            required: true
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        selectedValue: null,

        errorMessage: {
            type: String,
            required: false,
            default: ''
        },

        type: {
            type: String,
            required: false,
            default: 'text'
        }
    },

    methods: {
        handleInput (event) {
            let value = event.target ? event.target.value : event

            if (value === 'true') value = true
            if (value === 'false') value = false

            this.$emit('change', value)
        }
    }
}
</script>
