import axios from 'axios'

const ENDPOINTS = {
    USER: () => 'user',
    INVITE: companyId => `company/${companyId}/invite`,
    UPLOAD_AVATAR: () => '/user/avatar',
    USER_DOCUMENT_WIZARD: () => '/user/document-wizard-seen',
    USER_IS_ASSIGNEE: (companyId, regulationId) =>
        `/company/${companyId}/regulation/${regulationId}/is-user-assignee`,
    MY_PERMISSIONS: (companyId, regulationId) =>
        `auth/company/${companyId}/regulation/${regulationId}/my-permissions`
}

export default class UserService {
    /**
     * Updates a user
     * @param {String|Number} id
     * @param {Object} userData
     *
     * @returns Promise
     */
    async update (userData) {
        try {
            const response = await axios.patch(ENDPOINTS.USER(), userData)
            return response.data
        } catch (error) {
            throw error
        }
    }

    /**
     * Invite a user
     * @param {String|Number} companyId
     * @param {Object} userData
     *
     * @returns Promise
     */
    async inviteUser (companyId, userData) {
        try {
            const { data } = await axios.post(
                ENDPOINTS.INVITE(companyId),
                userData
            )
            return data
        } catch (error) {
            throw error
        }
    }

    /*
     * Upload profile image
     *
     * @param {File} file
     *
     * @returns Promise
     */
    async uploadProfileImage (file) {
        try {
            const formData = new FormData()

            formData.append('avatar', file)

            const { data } = await axios.post(
                ENDPOINTS.UPLOAD_AVATAR(),
                formData
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /*
     * Get is document wizard seen for current user
     *
     * @returns Promise
     */
    async getIsDocumentWizardSeen () {
        try {
            const { data } = await axios.get(ENDPOINTS.USER_DOCUMENT_WIZARD())

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Set is document wizard seen for current user
     *
     * @returns Promise
     */
    async markIsDocumentWizardSeen () {
        try {
            const { data } = await axios.post(ENDPOINTS.USER_DOCUMENT_WIZARD())

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * GET is user asiggned to step
     */
    async getIsAssignee (companyId, regulationId) {
        try {
            const { data } = await axios.get(
                ENDPOINTS.USER_IS_ASSIGNEE(companyId, regulationId)
            )
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get my permissions
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getMyPermissions (companyId, regulationId) {
        try {
            const route = ENDPOINTS.MY_PERMISSIONS(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const userService = new UserService()
