import {
    REGISTER_WIZARD_ROUTES,
    ROUTES,
    DOCUMENT_WIZARD_ROUTES
} from '@/constants'
import Vue from 'vue'

const state = {
    previousRoute: {},
    oldHistory: {}
}

const mutations = {
    SET_PREVIOUS_ROUTE (state, route) {
        if (!route.meta.shouldSkipRouteInHistory && route.name) {
            state.previousRoute = {
                name: route.name,
                meta: route.meta,
                query: route.query,
                params: route.params,
                hash: route.hash
            }
        }
    },

    ADD_OLD_HISTORY (state, to) {
        if (
            to.meta.shouldKeepPreviousRoute &&
            to.name !== state.previousRoute.name
        ) {
            state.oldHistory[to.name] = { ...state.previousRoute }
        }
    }
}

const getters = {
    previousRoute: state => state.previousRoute,
    oldHistory: state => key => {
        return state.oldHistory[key] || null
    },
    untitledRoutes: () => {
        return [
            REGISTER_WIZARD_ROUTES.REPORTING.INDEX.name,
            ROUTES.REGISTERS_AND_MODULES.name,
            DOCUMENT_WIZARD_ROUTES.SINGLE_STEP.name,
            ROUTES.MY_WORK.TASKS.MY_TASKS.name,
            ROUTES.MY_WORK.OVERVIEW.name,
            REGISTER_WIZARD_ROUTES.INTERNAL_AUDITS.WIZARD.name,
            ROUTES.MY_WORK.REGISTERS.name
        ]
    }
}

const actions = {
    setPreviousRoute ({ commit }, from) {
        Vue.nextTick(() => {
            commit('SET_PREVIOUS_ROUTE', from)
        })
    },
    setOldHistory ({ commit }, to) {
        Vue.nextTick(() => {
            commit('ADD_OLD_HISTORY', to)
        })
    }
}

export const router = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
