import { find, findIndex, cloneDeep, last } from 'lodash-es'
import { internalAuditService } from '@/services/api/internal-audit'
import { REGISTER_OF_INTERNAL_AUDITS } from '@/constants'

const { INTENTIONS } = REGISTER_OF_INTERNAL_AUDITS

function getInitialState () {
    return {
        audits: [],
        audit: null,
        isIntenalAuditInProgress: false,
        checklists: [],
        clauses: []
    }
}

const state = getInitialState()

const actions = {
    async getInternalAudits ({ commit, rootGetters, dispatch }, regulationId) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id

            const audits = await internalAuditService.getAudits(
                companyId,
                regulationId
            )

            commit('SET_INTERNAL_AUDITS', audits)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async isAnyAuditInProgress (
        { commit, rootGetters, dispatch },
        regulationId
    ) {
        try {
            const companyId = rootGetters['company/company'].id

            const isInProgress = await internalAuditService.isAnyInProgress(
                companyId,
                regulationId
            )

            commit('SET_IS_INTERNAL_AUDIT_IN_PROGRESS', isInProgress)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async getInternalAudit ({ commit, rootGetters, dispatch }, { auditId }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const audit = await internalAuditService.getAudit(
                companyId,
                regulationId,
                auditId
            )

            commit('SET_INTERNAL_AUDIT', audit)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async addAttachments (
        { commit, state, rootGetters, dispatch },
        { id, files, filesIds }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const attachments = await internalAuditService.addAttachments(
                companyId,
                regulationId,
                id,
                files,
                filesIds
            )

            const audit = find(state.audits, { id })

            commit('UPDATE_ATTACHMENTS', {
                ...audit,
                files: attachments,
                filesIds
            })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async addInternalAudit ({ commit, rootGetters, dispatch }, { data }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const audit = await internalAuditService.addAudit(
                companyId,
                regulationId,
                data
            )

            commit('ADD_INTERNAL_AUDIT', audit)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateInternalAudit ({ commit, rootGetters, dispatch }, { data }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            let payload = { ...data, state: data.state.name }

            const audit = await internalAuditService.updateAudit(
                companyId,
                regulationId,
                data.id,
                payload
            )
            commit('UPDATE_INTERNAL_AUDIT', audit)
            return audit
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getChecklists ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const checklists = await internalAuditService.getChecklists(
                regulationId,
                companyId
            )

            commit('SET_CHECKLISTS', checklists)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async addChecklistItem (
        { state, commit, rootGetters, dispatch },
        { companyId, regulationId, auditId, checklistId, sectionId, data }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            companyId = companyId || rootGetters['company/company'].id
            sectionId = sectionId || data.checklist_section_id
            regulationId =
                regulationId || rootGetters['regulation/currentRegulation'].id
            checklistId = checklistId || state.audit.checklist.id
            auditId = auditId || state.audit.id

            const item = await internalAuditService.addChecklistItem(
                companyId,
                regulationId,
                auditId,
                checklistId,
                sectionId,
                data
            )

            commit('ADD_CHECKLIST_ITEM', item)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async addNonconformities ({ commit, dispatch, rootGetters }, data) {
        try {
            await dispatch('updateChecklistItem', data)
            commit('ADD_NONCONFORMITY', {
                sectionId: data.sectionId,
                itemId: data.id,
                nonconformities: rootGetters[
                    'nonconformity/nonconformities'
                ].filter(
                    nonconformity =>
                        !!find(data.nonconformities_ids, nonconformity.id)
                )
            })
        } catch (error) {
            throw error
        }
    },

    async deleteNonconformity ({ commit, dispatch, rootGetters }, data) {
        try {
            await dispatch('updateChecklistItem', data)
            commit('ADD_NONCONFORMITY', {
                sectionId: data.sectionId,
                itemId: data.id,
                nonconformities: rootGetters[
                    'nonconformity/nonconformities'
                ].filter(
                    nonconformity =>
                        !!find(data.nonconformities_ids, nonconformity.id)
                )
            })
        } catch (error) {
            throw error
        }
    },

    async updateChecklist (
        { state, commit, rootGetters, dispatch },
        { companyId, regulationId, auditId, checklistId, data }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            companyId = companyId || rootGetters['company/company'].id
            regulationId =
                regulationId || rootGetters['regulation/currentRegulation'].id
            checklistId = checklistId || state.audit.checklist.id
            auditId = auditId || state.audit.id

            const checklist = await internalAuditService.updateChecklist(
                companyId,
                regulationId,
                auditId,
                checklistId,
                data
            )

            commit('ADD_CHECKLIST', {
                ...state.audit.checklist,
                title: checklist.title
            })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateChecklistItem (
        { state, commit, rootGetters, dispatch },
        { companyId, regulationId, auditId, checklistId, sectionId, data }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            companyId = companyId || rootGetters['company/company'].id
            sectionId = sectionId || data.checklist_section_id
            regulationId =
                regulationId || rootGetters['regulation/currentRegulation'].id
            checklistId = checklistId || state.audit.checklist.id
            auditId = auditId || state.audit.id

            const audit = await internalAuditService.updateChecklistItem(
                companyId,
                regulationId,
                auditId,
                checklistId,
                sectionId,
                data.id,
                data
            )

            commit('SET_SECTIONS', audit.checklist.sections)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async saveChecklist ({ commit, state, rootGetters, dispatch }, title) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await internalAuditService.saveChecklist(
                companyId,
                regulationId,
                state.audit.id,
                {
                    title,
                    checklist_id: state.audit.checklist.id
                }
            )

            commit('ADD_CHECKLIST', { ...state.audit.checklist, title })
            dispatch('getChecklists')
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async overwriteChecklistTemplate ({
        commit,
        state,
        rootGetters,
        dispatch
    }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const checklistId = state.audit.checklist.id

            await internalAuditService.overwriteChecklistTemplate(
                companyId,
                regulationId,
                state.audit.id,
                checklistId
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async deleteChecklist ({ commit, rootGetters, dispatch }, id) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await internalAuditService.deleteChecklist(
                companyId,
                regulationId,
                id
            )
            commit('DELETE_CHECKLIST', id)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async deleteChecklistItem (
        { commit, rootGetters, dispatch },
        { auditId, checklistId, sectionId, item }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await internalAuditService.deleteChecklistItem(
                companyId,
                regulationId,
                auditId,
                checklistId,
                sectionId,
                item.id
            )
            commit('DELETE_CHECKLIST_ITEM', item)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async reorderChecklist (
        { state, commit, rootGetters, dispatch },
        { oldIndex, newIndex, sectionId, item }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        let oldChecklist
        try {
            oldIndex =
                oldIndex !== undefined
                    ? oldIndex
                    : state.audit.checklist.sections
                          .find(
                              section =>
                                  section.id === item.checklist_section_id
                          )
                          .items.findIndex(
                              sectionItem => sectionItem.id === item.id
                          )
            oldChecklist = cloneDeep(state.audit.checklist)
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const companyId = rootGetters['company/company'].id
            const itemId = item.id
            const auditId = state.audit.id

            const newSectionId = sectionId || item.checklist_section_id
            const sectionIndex = findIndex(state.audit.checklist.sections, [
                'id',
                newSectionId
            ])
            const realNewIndex = oldIndex < newIndex ? newIndex : newIndex - 1

            const previousItemId =
                realNewIndex > -1
                    ? state.audit.checklist.sections[sectionIndex].items[
                          realNewIndex
                      ].id
                    : null

            commit('REORDER_CHECKLIST_ITEM', {
                item,
                oldIndex,
                newIndex,
                oldSectionId: item.checklist_section_id,
                newSectionId: newSectionId
            })

            const {
                checklist
            } = await internalAuditService.updateChecklistItem(
                companyId,
                regulationId,
                auditId,
                state.audit.checklist.id,
                item.checklist_section_id,
                itemId,
                {
                    checklist_section_id: newSectionId,
                    previous_item_id: previousItemId,
                    title: item.title,
                    reference_to: item.reference_to
                }
            )

            commit('SET_SECTIONS', checklist.sections)
        } catch (error) {
            commit('SET_SECTIONS', oldChecklist.sections)
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateInternalAuditPerforming (
        { state, commit, rootGetters, dispatch },
        { companyId, regulationId, auditId, checklistId, sectionId, data }
    ) {
        try {
            commit('SET_APP_LOADING', true, { root: true })
            companyId = companyId || rootGetters['company/company'].id
            sectionId = sectionId || data.checklist_section_id
            regulationId =
                regulationId || rootGetters['regulation/currentRegulation'].id
            checklistId = checklistId || state.audit.checklist.id
            auditId = auditId || state.audit.id

            const audit = await internalAuditService.updateAuditPerforming(
                companyId,
                regulationId,
                auditId,
                checklistId,
                sectionId,
                { ...data }
            )
            commit('UPDATE_CHECKLIST', audit.checklist)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getClauses ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const clauses = await internalAuditService.getClauses(
                companyId,
                regulationId
            )

            commit('SET_CLAUSES', clauses)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateDocumentationReview (
        { commit, rootGetters, dispatch },
        { auditId, data }
    ) {
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const reviewItem = await internalAuditService.updateDocumentationReview(
                companyId,
                regulationId,
                auditId,
                data
            )

            commit('UPDATE_REVIEW_ITEM', reviewItem)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async addSection ({ state, commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const companyId = rootGetters['company/company'].id
            const auditId = state.audit.id
            const checklistId = state.audit.checklist.id

            const section = await internalAuditService.addSection(
                companyId,
                regulationId,
                auditId,
                checklistId,
                data
            )
            commit('ADD_SECTION', section)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async deleteSection ({ state, commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const companyId = rootGetters['company/company'].id
            const auditId = state.audit.id
            const checklistId = state.audit.checklist.id
            const sectionId = data.id

            await internalAuditService.deleteSection(
                companyId,
                regulationId,
                auditId,
                checklistId,
                sectionId
            )
            commit('DELETE_SECTION', sectionId)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async printChecklist (
        { state, rootGetters, dispatch, commit },
        checklistId
    ) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const companyId = rootGetters['company/company'].id
            const auditId = state.audit.id

            await internalAuditService.printChecklist(
                companyId,
                regulationId,
                auditId,
                checklistId
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async saveChecklistDocument (
        { state, rootGetters, dispatch, commit },
        checklistId
    ) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const companyId = rootGetters['company/company'].id
            const auditId = state.audit.id

            await internalAuditService.saveChecklistDocument(
                companyId,
                regulationId,
                auditId,
                checklistId
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async printReport ({ state, rootGetters, dispatch, commit }) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const companyId = rootGetters['company/company'].id
            const auditId = state.audit.id

            await internalAuditService.printAuditReport(
                companyId,
                regulationId,
                auditId
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async generateAuditReport ({ state, commit, dispatch, rootGetters }) {
        try {
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const companyId = rootGetters['company/company'].id
            const auditId = last(state.audits).id

            const report = await internalAuditService.generateAuditReport(
                companyId,
                regulationId,
                auditId
            )
            commit('register/SET_ADDITIONAL_RELATED_DOCUMENT', [report], {
                root: true
            })

            dispatch('register/getCurrentStepRelatedDocuments', null, {
                root: true
            })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async cancelAudit ({ commit, dispatch, rootGetters }, audit) {
        try {
            commit('SET_APP_LOADING', true, { root: true })

            const regulationId = rootGetters['regulation/currentRegulation'].id
            const companyId = rootGetters['company/company'].id

            const canceledAudit = await internalAuditService.cancelAudit(
                companyId,
                regulationId,
                audit.id
            )

            commit('UPDATE_INTERNAL_AUDIT', canceledAudit)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async deleteAudit ({ commit, rootGetters }, auditId) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await internalAuditService.deleteAudit(
                companyId,
                regulationId,
                auditId
            )

            commit('REMOVE_AUDIT', auditId)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}

const mutations = {
    SET_CLAUSES (state, clauses) {
        state.clauses = clauses
    },

    ADD_NONCONFORMITY (state, { sectionId, itemId, nonconformities }) {
        const section = find(state.audit.checklist.sections, { id: sectionId })

        if (section) {
            const index = findIndex(section.items, { id: itemId })

            if (index !== -1) {
                section.items.splice(index, 1, {
                    ...section.items[index],
                    nonconformities
                })
            }
        }
    },

    ADD_SECTION (state, section) {
        state.audit.checklist.sections.push(section)
    },

    DELETE_SECTION (state, sectionId) {
        const sectionIndex = findIndex(state.audit.checklist.sections, [
            'id',
            sectionId
        ])

        state.audit.checklist.sections.splice(sectionIndex, 1)
    },

    SET_SECTIONS (state, sections) {
        state.audit.checklist = { ...state.audit.checklist, sections }
    },

    SET_INTERNAL_AUDITS (state, audits) {
        state.audits = audits
    },

    SET_IS_INTERNAL_AUDIT_IN_PROGRESS (state, isInProgress) {
        state.isIntenalAuditInProgress = isInProgress
    },

    SET_INTERNAL_AUDIT (state, audit) {
        state.audit = audit
    },

    ADD_INTERNAL_AUDIT (state, audit) {
        state.audits = [...state.audits, audit]
    },

    UPDATE_ATTACHMENTS (state, { files, filesIds }) {
        state.audit = {
            ...state.audit,
            files,
            files_ids: filesIds
        }
    },

    UPDATE_CHECKLIST (state, checklist) {
        state.audit.checklist = { ...state.audit.checklist, ...checklist }
    },

    UPDATE_INTERNAL_AUDIT (state, audit) {
        const index = findIndex(state.audits, { id: audit.id })

        state.audits.splice(index, 1, audit)

        if (state.audit && audit.id === state.audit.id) {
            const { files } = state.audit

            state.audit = { ...audit, files }
        }
    },

    SET_CHECKLISTS (state, checklists) {
        state.checklists = checklists
    },

    ADD_CHECKLIST_ITEM (state, item) {
        const sectionIndex = findIndex(state.audit.checklist.sections, [
            'id',
            Number(item.checklist_section_id)
        ])

        state.audit.checklist.sections[sectionIndex].items.push(item)
    },

    UPDATE_CHECKLIST_ITEM (state, item) {
        const sectionIndex = findIndex(state.audit.checklist.sections, [
            'id',
            Number(item.checklist_section_id)
        ])

        const items = state.audit.checklist.sections[sectionIndex].items
        const index = findIndex(items, { id: item.id })

        items.splice(index, 1, item)
    },

    DELETE_CHECKLIST (state, id) {
        const index = findIndex(state.checklists, { id })

        state.checklists.splice(index, 1)
    },

    ADD_CHECKLIST (state, checklist) {
        state.checklists.push({ ...checklist })
    },

    DELETE_CHECKLIST_ITEM (state, item) {
        const sectionIndex = findIndex(state.audit.checklist.sections, {
            id: Number(item.checklist_section_id)
        })
        const itemIndex = findIndex(
            state.audit.checklist.sections[sectionIndex].items,
            { id: item.id }
        )

        state.audit.checklist.sections[sectionIndex].items.splice(itemIndex, 1)
    },

    UPDATE_REVIEW_ITEM (state, reviewItem) {
        const reviewItemIndex = findIndex(state.audit.review_items, {
            id: reviewItem.id
        })

        const latestReviewItemIndex = findIndex(
            state.audit.latest_review_items,
            {
                id: reviewItem.id
            }
        )

        state.audit.review_items.splice(reviewItemIndex, 1, reviewItem)
        state.audit.latest_review_items.splice(
            latestReviewItemIndex,
            1,
            reviewItem
        )
    },

    REORDER_CHECKLIST_ITEM (
        state,
        { item, oldIndex, newIndex, oldSectionId, newSectionId }
    ) {
        const oldSection = state.audit.checklist.sections.find(
            x => x.id === oldSectionId
        )
        const newSection = state.audit.checklist.sections.find(
            x => x.id === newSectionId
        )
        let newItemsOrder = [...oldSection.items]

        if (newSectionId === oldSectionId) {
            oldIndex = oldIndex > newIndex ? oldIndex + 1 : oldIndex
            newIndex = oldIndex < newIndex ? newIndex + 1 : newIndex

            newItemsOrder.splice(newIndex, 0, item)
            newItemsOrder.splice(oldIndex, 1)
            oldSection.items = newItemsOrder
            return
        }
        newSection.items.splice(newIndex, 0, item)
        oldSection.items.splice(oldIndex, 1)
    },

    REMOVE_AUDIT (state, auditId) {
        state.audits = state.audits.filter(audit => audit.id !== auditId)
    }
}

const getters = {
    internalAudits: state => state.audits,
    isIntenalAuditInProgress: state => state.isIntenalAuditInProgress,
    internalAudit: state => state.audit,
    checklists: state => state.checklists,
    clauses: state => state.clauses
}

export const internalAudit = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
