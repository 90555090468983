<template>
    <app-modal
        name="save-adapting-checklist-item"
        :title="title"
        :ok-title="'COMMON.SAVE'"
        max-width53
        :error-message="firstError('name')"
        :ok-disabled="!canSubmit"
        @ok="handleSubmit"
        @cancel="handleCancel"
    >
        <template slot="body">
            <item-form :sections="sections" :form="form" />
        </template>
    </app-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ItemForm from '@/components/registers/internal-audits/checklist-adaptation/ItemForm'

export default {
    components: {
        ItemForm
    },

    props: {
        sections: {
            type: Array,
            required: true
        },

        item: {
            type: Object,
            required: false,
            default: null
        }
    },

    data () {
        return {
            form: this.getDefaults()
        }
    },

    computed: {
        ...mapGetters('errors', ['firstError']),

        isEditing () {
            return this.item && this.item.id
        },

        title () {
            return this.isEditing
                ? 'REGISTERS.INTERNAL_AUDITS.CHECKLIST_ADAPTATION.MODAL.ITEM.EDIT_TITLE'
                : 'REGISTERS.INTERNAL_AUDITS.CHECKLIST_ADAPTATION.MODAL.ITEM.ADD_TITLE'
        },

        canSubmit () {
            return this.form.title && this.form.checklist_section_id
        }
    },

    methods: {
        ...mapActions('errors', ['clearErrors']),

        getDefaults () {
            if (this.isEditing) {
                return {
                    ...this.item
                }
            }

            return {
                checklist_section_id: '',
                title: '',
                reference_to: ''
            }
        },

        init () {
            this.form = this.getDefaults()
        },

        handleSubmit () {
            this.$emit(
                'ok',
                {
                    title: this.form.title,
                    checklist_section_id: this.form.checklist_section_id,
                    reference_to: this.form.reference_to
                },
                this.init
            )
        },

        handleCancel () {
            this.$emit('cancel')
            this.form = this.getDefaults()
        }
    },

    watch: {
        item () {
            this.form = this.getDefaults()
        }
    }
}
</script>
