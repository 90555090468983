const HUMAN_RESOURCES = 'human_resources'
const FINANCIAL_RESOURCES = 'financial_resources'
const TECHNOLOGY_RESOURCES = 'technology_resources'

const TYPES = {
    HUMAN_RESOURCES,
    FINANCIAL_RESOURCES,
    TECHNOLOGY_RESOURCES
}

const NOT_APPROVED = 'not_approved'
const APPROVED = 'approved'
const IN_APPROVAL = 'in_approval'
const CHANGED = 'changed'
const NO_STATUS = 'no_status'

const STATUSES = {
    NOT_APPROVED,
    APPROVED,
    IN_APPROVAL,
    CHANGED,
    NO_STATUS
}

const COMPANY_CONTROL_TASK = 'company-control-task'
const COMPANY_REGULATION_STEP = 'company-regulation-step'

const TARGET_TYPES = {
    COMPANY_CONTROL_TASK,
    COMPANY_REGULATION_STEP
}

const ACTION_APPROVE = 'approve'
const ACTION_REJECT = 'reject'

const APPROVAL_ACTIONS = {
    ACTION_APPROVE,
    ACTION_REJECT
}

export const RESOURCES = {
    TYPES,
    STATUSES,
    TARGET_TYPES,
    APPROVAL_ACTIONS
}
