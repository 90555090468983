<template>
    <div class="cf-c-radio-group">
        <div
            v-for="(option, index) in options"
            :key="index"
            class="cf-c-radio-group__item"
        >
            <app-radio-input
                v-b-tooltip.top="option.tooltip"
                :disabled="disabled || option.disabled"
                :value="selectedOption"
                :name="name"
                :label="getLabel(option)"
                :radio-value="option.value"
                @input="handleInput"
            />
        </div>
        <span v-if="!!errorMessage" class="cf-c-input__error">
            {{ errorMessage }}
        </span>
    </div>
</template>

<script>
import { isNil, find } from 'lodash-es'

export default {
    props: {
        options: {
            type: Array,
            required: true
        },

        name: {
            type: String,
            required: true
        },

        value: {
            type: [String, Number, Boolean],
            required: false
        },

        errorMessage: {
            type: String,
            required: false,
            default: ''
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        formValue: {
            type: [String, Number],
            required: false,
            default: ''
        },

        ignoreLocalization: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data () {
        return {
            selectedOption: ''
        }
    },

    watch: {
        value (value) {
            this.setSelectedOption(value)
        },

        formValue (value) {
            this.setSelectedOption(value)
        }
    },

    created () {
        this.setSelectedOption(this.value)
    },

    methods: {
        setSelectedOption (value) {
            if (!isNil(value)) {
                this.selectedOption =
                    typeof value === 'boolean' ? value : value.toString()
            }

            const defaultSelected = find(
                this.options,
                option => option.initial_selected
            )

            if (isNil(value) && defaultSelected) {
                this.selectedOption = defaultSelected.value
                this.handleInput(this.selectedOption)
            }
        },

        handleInput (value) {
            value = value === 'true' ? true : value === 'false' ? false : value

            this.$emit('input', value)
        },

        getLabel (option) {
            if (this.ignoreLocalization) {
                return option.label
            }

            return this.$te(option.label) ? this.$t(option.label) : option.label
        }
    }
}
</script>
