import { GLOBAL_VARIABLE_DEPENDS } from '@/constants'
import { fromJson } from '@/services/utils/functions'

export function checkIfDependingOnIsFulfilled (dependingOn, form) {
    return dependingOn.every(dependentElement => {
        if (GLOBAL_VARIABLE_DEPENDS.includes(dependentElement.name)) {
            return dependentElement.value
        }

        return parseInt(form[dependentElement.name]) === dependentElement.value
    })
}

export function getDependingOnIfExist (inputs, inputName) {
    const input = inputs.find(input => input.name === inputName)

    return fromJson(input?.depending_on)
}
