const SORT_VALUES = {
    FIRST_NAME_ASC: 'first_name_asc',
    FIRST_NAME_DESC: 'first_name_desc',
    LAST_NAME_ASC: 'last_name_asc',
    LAST_NAME_DESC: 'last_name_desc',
    ROLE_ASC: 'role_asc',
    ROLE_DESC: 'role_desc',
    JOB_TITLE_ASC: 'job_title_asc',
    JOB_TITLE_DESC: 'job_title_desc',
    STATUS_ASC: 'status_asc',
    STATUS_DESC: 'status_desc',
    LAST_ACTIVE_ASC: 'last_active_asc',
    LAST_ACTIVE_DESC: 'last_active_desc'
}

const SORT_OPTIONS = [
    {
        value: SORT_VALUES.FIRST_NAME_ASC,
        label: 'USER_MANAGEMENT.SORT_OPTIONS.FIRST_NAME_ASC'
    },
    {
        value: SORT_VALUES.FIRST_NAME_DESC,
        label: 'USER_MANAGEMENT.SORT_OPTIONS.FIRST_NAME_DESC'
    },
    {
        value: SORT_VALUES.LAST_NAME_ASC,
        label: 'USER_MANAGEMENT.SORT_OPTIONS.LAST_NAME_ASC'
    },
    {
        value: SORT_VALUES.LAST_NAME_DESC,
        label: 'USER_MANAGEMENT.SORT_OPTIONS.LAST_NAME_DESC'
    },
    {
        value: SORT_VALUES.ROLE_ASC,
        label: 'USER_MANAGEMENT.SORT_OPTIONS.ROLE_ASC'
    },
    {
        value: SORT_VALUES.ROLE_DESC,
        label: 'USER_MANAGEMENT.SORT_OPTIONS.ROLE_DESC'
    },
    {
        value: SORT_VALUES.JOB_TITLE_ASC,
        label: 'USER_MANAGEMENT.SORT_OPTIONS.JOB_TITLE_ASC'
    },
    {
        value: SORT_VALUES.JOB_TITLE_DESC,
        label: 'USER_MANAGEMENT.SORT_OPTIONS.JOB_TITLE_DESC'
    },
    {
        value: SORT_VALUES.STATUS_ASC,
        label: 'USER_MANAGEMENT.SORT_OPTIONS.STATUS_ASC'
    },
    {
        value: SORT_VALUES.STATUS_DESC,
        label: 'USER_MANAGEMENT.SORT_OPTIONS.STATUS_DESC'
    },
    {
        value: SORT_VALUES.LAST_ACTIVE_ASC,
        label: 'USER_MANAGEMENT.SORT_OPTIONS.LAST_ACTIVE_ASC'
    },
    {
        value: SORT_VALUES.LAST_ACTIVE_DESC,
        label: 'USER_MANAGEMENT.SORT_OPTIONS.LAST_ACTIVE_DESC'
    }
]

const SORT_ACTIONS = {
    [SORT_VALUES.FIRST_NAME_ASC]: (a, b) => SORT_BY_FIELD(a, b, 'firstName'),
    [SORT_VALUES.FIRST_NAME_DESC]: (a, b) =>
        SORT_BY_FIELD(a, b, 'firstName', false),
    [SORT_VALUES.LAST_NAME_ASC]: (a, b) => SORT_BY_FIELD(a, b, 'lastName'),
    [SORT_VALUES.LAST_NAME_DESC]: (a, b) =>
        SORT_BY_FIELD(a, b, 'lastName', false),
    [SORT_VALUES.ROLE_ASC]: (a, b) => SORT_BY_FIELD(a, b, 'role'),
    [SORT_VALUES.ROLE_DESC]: (a, b) => SORT_BY_FIELD(a, b, 'role', false),
    [SORT_VALUES.JOB_TITLE_ASC]: (a, b) => SORT_BY_FIELD(a, b, 'job_title'),
    [SORT_VALUES.JOB_TITLE_DESC]: (a, b) =>
        SORT_BY_FIELD(a, b, 'job_title', false),
    [SORT_VALUES.STATUS_ASC]: (a, b) => SORT_BY_FIELD(a, b, 'status'),
    [SORT_VALUES.STATUS_DESC]: (a, b) => SORT_BY_FIELD(a, b, 'status', false),
    [SORT_VALUES.LAST_ACTIVE_ASC]: (a, b) => SORT_BY_FIELD(a, b, 'lastActive'),
    [SORT_VALUES.LAST_ACTIVE_DESC]: (a, b) =>
        SORT_BY_FIELD(a, b, 'lastActive', false)
}

const STATUSES = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    INVITED: 'invited'
}

const FILTER_OPTIONS = [
    {
        label: 'USER_MANAGEMENT.STATUSES.ACTIVE',
        key: STATUSES.ACTIVE
    },
    {
        label: 'USER_MANAGEMENT.STATUSES.INACTIVE',
        key: STATUSES.INACTIVE
    },
    {
        label: 'USER_MANAGEMENT.STATUSES.INVITED',
        key: STATUSES.INVITED
    }
]

const SORT_BY_FIELD = (a, b, field, asc = true) => {
    if (!a[field] && !b[field]) {
        return 0
    }
    if (!a[field]) {
        return 1
    }
    if (!b[field]) {
        return -1
    }

    return asc ? (a[field] > b[field] ? 1 : -1) : a[field] < b[field] ? 1 : -1
}

export const USER_MANAGEMENT = {
    SORT_VALUES,
    SORT_OPTIONS,
    SORT_ACTIONS,
    FILTER_OPTIONS,
    STATUSES
}
