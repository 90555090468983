const SECTION_COMPARISON_FIELDS = ['title']

const ITEM_COMPARISON_FIELDS = [
    'compliant',
    'evidence',
    'reference_to',
    'title'
]

export { SECTION_COMPARISON_FIELDS, ITEM_COMPARISON_FIELDS }
