import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REGISTER_OF_RISKS } from '@/constants'
import { isEqual, pickBy } from 'lodash-es'
import { EventBus } from '@/EventBus'

export default {
    data () {
        return {
            availableFilters: REGISTER_OF_RISKS.AVAILABLE_FILTERS,
            filters: {},
            pagination: {
                page: 1,
                total: 0,
                perPage: 100 // PER_PAGE
            }
        }
    },

    created () {
        EventBus.$on('sort-risks', this.sortRisks)
    },

    beforeDestroy () {
        EventBus.$off('sort-risks', this.sortRisks)

        this.emptyRisks()

        this.setRiskSortCriteria(null)

        // Reset filters
        this.filters = {}
    },

    computed: {
        ...mapGetters('loading', ['risksLoading']),

        isDataLoading () {
            return this.risksLoading
        },

        isDataLoaded () {
            return !this.risksLoading && this.risks.length
        },

        isDataEmpty () {
            return !this.risksLoading && !this.risks.length
        },

        currentPage: {
            get () {
                return this.pagination.page
            },
            set (page) {
                this.pagination.page = page
            }
        },

        perPage () {
            return this.pagination.perPage
        },

        numOfPages () {
            return this.pagination.total
                ? Math.ceil(this.pagination.total / this.perPage)
                : 1
        },

        isDraftFilterActive () {
            return this.filters[REGISTER_OF_RISKS.FILTER_OPTIONS.DRAFT] ?? false
        },

        activeFilters () {
            return {
                ...this.defaultFilters,
                ...this.filters
            }
        },

        isBottomFilterBarVisible () {
            return this.risks && this.risks.length > 1
        }
    },

    methods: {
        ...mapActions('risks', ['getRisks', 'setRiskSortCriteria']),
        ...mapMutations('risks', { emptyRisks: 'EMPTY_RISKS' }),

        sortRisks (criteria) {
            this.setRiskSortCriteria(criteria)
        },

        async applyFilters (filters) {
            // Apply only filters which are set to true (activated)
            const newFilters = pickBy(filters, value => value)

            // Check if current page was changed
            let pageChanged = false

            // Check if are filters changed
            if (!isEqual(this.filters, newFilters)) {
                // Update current filters
                this.filters = newFilters

                pageChanged = await this.setPage(1)
            }

            // If page wasn't changed (date reloaded), reload it now
            if (!pageChanged) {
                await this.loadData()
            }
        },

        /**
         * Change page and reload data if is page changed
         *
         * @returns boolean Is it page changed (or is it data reloaded)
         */
        async setPage (newPage = null) {
            // Cannot change page if is new value same (supports doubled pagination)
            if (!newPage || newPage === this.currentPage) {
                return false
            }

            // Update real currentPage
            this.currentPage = newPage

            // Update fake models (needed for displaying double pagination)
            this.$refs.topFilterBar.setPage(newPage)
            this.$refs.bottomFilterBar.setPage(newPage)

            // Load risks data
            await this.loadData()

            return true
        },

        /**
         * Default loadData function
         * Used in Evaluation, Review and Treatment step
         */
        async loadData () {
            // Clear risks state, so SingleRisk component would be regenerated
            this.emptyRisks()

            // Load new risks from the backend
            const { meta } = await this.getRisks({
                fromCache: this.fromCache ?? undefined,
                pagination: this.pagination,
                filters: this.activeFilters
            })

            if (meta) {
                // E.g. when risks are filtered and then reviewed but page stays same
                if (meta.current_page > meta.last_page) {
                    this.setPage(meta.last_page)
                }

                this.pagination.total = meta.total
            }
        },

        /**
         * Default updatePage function
         * Used in Evaluation, Review Treatment step
         */
        async updatePage () {
            if (this.currentPage > this.numOfPages) {
                // Check if current page needs to be changed
                this.setPage(this.numOfPages)
            } else {
                // Reload existing page
                await this.loadData()
            }
        },

        /**
         * Handle delete risk action
         * Used in Evaluation and Review step
         */
        async onDeleteRisk (risk, riskIndex) {
            // Delete risk
            await this.deleteRisk({ risk, riskIndex, removeFromCache: true })

            // Decrease num of total risks (needed or auto changing page)
            this.pagination.total--

            this.updatePage()
        }
    }
}
