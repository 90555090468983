import { REGISTER_WIZARD_ROUTES, REGISTER_TYPES } from '@/constants/registers'
import {
    REGISTER_TYPE as REGISTER_TYPE_REQUIREMENTS,
    ROUTES_REGISTER_OF_REQUIREMENTS
} from '@src/modules/registers/requirements/constants'

const STATUSES = {
    PLANNED: 'planned',
    COMPLETED: 'completed',
    LATE: 'late'
}

const STATUS_COLORS = {
    [STATUSES.COMPLETED]: 'cf-u-color-secondary-light',
    [STATUSES.PLANNED]: 'cf-u-color-primary',
    [STATUSES.LATE]: 'cf-u-color-danger'
}

const STATUS_GUIDES = [
    {
        label: 'REPORTING.MANAGEMENT_REVIEW.STATUSES.LATE',
        color: STATUS_COLORS[STATUSES.LATE]
    },
    {
        label: 'REPORTING.MANAGEMENT_REVIEW.STATUSES.PLANNED',
        color: STATUS_COLORS[STATUSES.PLANNED]
    },
    {
        label: 'REPORTING.MANAGEMENT_REVIEW.STATUSES.COMPLETED',
        color: STATUS_COLORS[STATUSES.COMPLETED]
    }
]

const SORT_VALUES = {
    DUE_DATE_ASC: 'due_date_asc',
    DUE_DATE_DESC: 'due_date_desc',
    REVIEWER_ASC: 'reviewer_asc',
    REVIEWER_DESC: 'reviewer_desc',
    ACTIVITY_FREQUENCY_ASC: 'activity_frequency_asc',
    ACTIVITY_FREQUENCY_DESC: 'activity_frequency_desc',
    STATUS_ASC: 'status_asc',
    STATUS_DESC: 'status_desc'
}

const SORT_ACTIONS = {
    [SORT_VALUES.ACTIVITY_FREQUENCY_ASC]: (a, b) =>
        a.activity_frequency > b.activity_frequency ? 1 : -1,
    [SORT_VALUES.ACTIVITY_FREQUENCY_DESC]: (a, b) =>
        a.activity_frequency < b.activity_frequency ? 1 : -1,
    [SORT_VALUES.DUE_DATE_ASC]: (a, b) => (a.due_date > b.due_date ? 1 : -1),
    [SORT_VALUES.DUE_DATE_DESC]: (a, b) => (a.due_date < b.due_date ? 1 : -1),
    [SORT_VALUES.STATUS_ASC]: (a, b) => (a.status.id > b.status.id ? 1 : -1),
    [SORT_VALUES.STATUS_DESC]: (a, b) => (a.status.id < b.status.id ? 1 : -1)
}

const ONCE_A_WEEK = 'once_a_week'
const ONCE_A_MONTH = 'once_a_month'
const ONCE_EVERY_THREE_MONTHS = 'once_every_three_months'
const ONCE_EVERY_SIX_MONTHS = 'once_every_six_months'
const ONCE_A_YEAR = 'once_a_year'

const INTERNAL_AUDIT_COMPLETED = 'internal_audit_completed'
const RISK_REVIEW_PERFORMED = 'risk_review_performed'
const OBJECTIVES_MEASUREMENT = 'objectives_measurement'

const FREQUENCIES = [
    {
        value: ONCE_A_WEEK,
        label: 'REPORTING.MANAGEMENT_REVIEW.FREQUENCIES.ONCE_A_WEEK'
    },
    {
        value: ONCE_A_MONTH,
        label: 'REPORTING.MANAGEMENT_REVIEW.FREQUENCIES.ONCE_A_MONTH'
    },
    {
        value: ONCE_EVERY_THREE_MONTHS,
        label: 'REPORTING.MANAGEMENT_REVIEW.FREQUENCIES.ONCE_EVERY_THREE_MONTHS'
    },
    {
        value: ONCE_EVERY_SIX_MONTHS,
        label: 'REPORTING.MANAGEMENT_REVIEW.FREQUENCIES.ONCE_EVERY_SIX_MONTHS'
    },
    {
        value: ONCE_A_YEAR,
        label: 'REPORTING.MANAGEMENT_REVIEW.FREQUENCIES.ONCE_A_YEAR'
    }
]

const UNEDITABLE_FREQUENCIES = [
    {
        value: INTERNAL_AUDIT_COMPLETED,
        label:
            'REPORTING.MANAGEMENT_REVIEW.FREQUENCIES.INTERNAL_AUDIT_COMPLETED'
    },
    {
        value: RISK_REVIEW_PERFORMED,
        label: 'REPORTING.MANAGEMENT_REVIEW.FREQUENCIES.RISK_REVIEW_PERFORMED'
    },
    {
        value: OBJECTIVES_MEASUREMENT,
        label: 'REPORTING.MANAGEMENT_REVIEW.FREQUENCIES.OBJECTIVES_MEASUREMENT'
    }
]

const POSSIBLE_FREQUENCIES = [...FREQUENCIES, ...UNEDITABLE_FREQUENCIES]

const INTENTIONS = {
    EDIT: 'edit'
}

const IMPLEMENTATION_COMPLETED = 'implementation_completed'

const END_ACTIVITIES = {
    [IMPLEMENTATION_COMPLETED]:
        'REPORTING.MANAGEMENT_REVIEW.END_ACTIVITIES.IMPLEMENTATION_COMPLETED'
}

const NONCONFORMITIES_REGISTER =
    'nonconformities_and_corrective_action_register'
const REQUIREMENT_REGISTER = 'requirement_register'
const INCIDENT_REGISTER = 'incident_register'
const QUARTERLY_SUMMARY_OF_CORRECTIVE_ACTIONS_AND_NONCONFORMITIES_REPORT =
    'quarterly_summary_of_corrective_actions_and_nonconformities_pdf'
const COMPLIANCE_DASHBOARD = 'compliance_dashboard'
const PERFORMANCE_DASHBOARD = 'performance_dashboard'
const PROJECT_STATUS_DASHBOARD = 'project_status_dashboard'
const RISK_TREATMENT_PLAN = 'risk_treatment_plan'

const INTERNAL_AUDIT_REPORT = 'internal_audit_report'
const OBJECTIVES_FULFILLMENT_REPORT = 'objectives_fulfillment_report'
const REPORTING_MODULE_INTERESTED_PARTY_FEEDBACK_REPORT =
    'reporting_module_interested_party_feedback_report'
const RISK_ASSESSMENT_AND_TREATMENT_REPORT =
    'risk_assessment_and_risk_treatment_report'
const REPORTING_MODULE_OPPORTUNITIES_FOR_IMPROVEMENT_REPORT =
    'reporting_module_opportunities_for_improvement_report'
const LIST_OF_SECURITY_OBJECTIVES_PDF = 'list_of_security_objectives_pdf'
const RTP_REPORT = 'rtp_report'

const DATA_SOURCES = {
    [RTP_REPORT]: {
        label: 'REPORTING.MANAGEMENT_REVIEW.DATA_SOURCES.RTP_REPORT'
    },
    [NONCONFORMITIES_REGISTER]: {
        label:
            'REPORTING.MANAGEMENT_REVIEW.DATA_SOURCES.NONCONFORMITIES_REGISTER',
        route: REGISTER_WIZARD_ROUTES.NONCONFORMITIES.CORRECTIVE_ACTIONS.WIZARD,
        register: REGISTER_TYPES.NONCONFORMITIES
    },
    [REQUIREMENT_REGISTER]: {
        label: 'REPORTING.MANAGEMENT_REVIEW.DATA_SOURCES.REQUIREMENT_REGISTER',
        route: ROUTES_REGISTER_OF_REQUIREMENTS.BASE,
        register: REGISTER_TYPE_REQUIREMENTS
    },
    [INCIDENT_REGISTER]: {
        label: 'REPORTING.MANAGEMENT_REVIEW.DATA_SOURCES.INCIDENT_REGISTER',
        route: REGISTER_WIZARD_ROUTES.INCIDENTS.WIZARD,
        register: REGISTER_TYPES.INCIDENTS
    },
    [COMPLIANCE_DASHBOARD]: {
        label: 'REPORTING.MANAGEMENT_REVIEW.DATA_SOURCES.COMPLIANCE_DASHBOARD',
        route: REGISTER_WIZARD_ROUTES.REPORTING.COMPLIANCE_DASHBOARD,
        directLink: true
    },
    [PROJECT_STATUS_DASHBOARD]: {
        label:
            'REPORTING.MANAGEMENT_REVIEW.DATA_SOURCES.PROJECT_STATUS_DASHBOARD',
        route: REGISTER_WIZARD_ROUTES.REPORTING.PROJECT_STATUS_DASHBOARD,
        directLink: true
    },
    [PERFORMANCE_DASHBOARD]: {
        label: 'REPORTING.MANAGEMENT_REVIEW.DATA_SOURCES.PERFORMANCE_DASHBOARD',
        route: REGISTER_WIZARD_ROUTES.REPORTING.PERFORMANCE_DASHBOARD,
        directLink: true
    },
    [RISK_TREATMENT_PLAN]: {
        label: 'REPORTING.MANAGEMENT_REVIEW.DATA_SOURCES.RISK_TREATMENT_PLAN',
        route: REGISTER_WIZARD_ROUTES.RISK_TREATMENT_PLAN.WIZARD,
        register: REGISTER_TYPES.SOA
    },

    [REPORTING_MODULE_OPPORTUNITIES_FOR_IMPROVEMENT_REPORT]: {
        label:
            'REPORTING.MANAGEMENT_REVIEW.DATA_SOURCES.REPORTING_MODULE_OPPORTUNITIES_FOR_IMPROVEMENT_REPORT'
    },
    [RISK_ASSESSMENT_AND_TREATMENT_REPORT]: {
        label:
            'REPORTING.MANAGEMENT_REVIEW.DATA_SOURCES.RISK_ASSESSMENT_AND_TREATMENT_REPORT'
    },
    [REPORTING_MODULE_INTERESTED_PARTY_FEEDBACK_REPORT]: {
        label:
            'REPORTING.MANAGEMENT_REVIEW.DATA_SOURCES.REPORTING_MODULE_INTERESTED_PARTY_FEEDBACK_REPORT'
    },
    [INTERNAL_AUDIT_REPORT]: {
        label: 'REPORTING.MANAGEMENT_REVIEW.DATA_SOURCES.INTERNAL_AUDIT_REPORT'
    },
    [QUARTERLY_SUMMARY_OF_CORRECTIVE_ACTIONS_AND_NONCONFORMITIES_REPORT]: {
        label:
            'REPORTING.MANAGEMENT_REVIEW.DATA_SOURCES.QUARTERLY_SUMMARY_OF_CORRECTIVE_ACTIONS_AND_NONCONFORMITIES_REPORT'
    },
    [OBJECTIVES_FULFILLMENT_REPORT]: {
        label:
            'REPORTING.MANAGEMENT_REVIEW.DATA_SOURCES.OBJECTIVES_FULFILLMENT_REPORT'
    },
    [LIST_OF_SECURITY_OBJECTIVES_PDF]: {
        label:
            'REPORTING.MANAGEMENT_REVIEW.DATA_SOURCES.LIST_OF_SECURITY_OBJECTIVES_PDF'
    }
}

const FIRST_MANAGEMENT_REVIEW = 'first_official_management_review'
const ONE_MONTH_AFTER_IMPLEMENTATION_START = 'one_month_after_start'

const FIRST_OCCURANCES = {
    [FIRST_MANAGEMENT_REVIEW]: {
        label:
            'REPORTING.MANAGEMENT_REVIEW.FIRST_OCCURANCES.FIRST_MANAGEMENT_REVIEW'
    },
    [ONE_MONTH_AFTER_IMPLEMENTATION_START]: {
        label:
            'REPORTING.MANAGEMENT_REVIEW.FIRST_OCCURANCES.ONE_MONTH_AFTER_IMPLEMENTATION_START'
    }
}

const AS_SPECIFIED_IN_CORRECTIVE_ACTION = 'as_specified_in_corrective_action'
const NEW_LIST_OF_OBJECTIVES = 'new_list_of_objectives'

const INFORMERS = {
    [AS_SPECIFIED_IN_CORRECTIVE_ACTION]: {
        label:
            'REPORTING.MANAGEMENT_REVIEW.INFORMERS.AS_SPECIFIED_IN_CORRECTIVE_ACTION'
    },
    [NEW_LIST_OF_OBJECTIVES]: {
        label: 'REPORTING.MANAGEMENT_REVIEW.INFORMERS.NEW_LIST_OF_OBJECTIVES'
    }
}

const OUTPUT_TYPES = {
    MAKE_CORRECTIVE_ACTIONS_IF_NEEDED: 'make_corrective_actions_if_needed',
    MAKE_CHANGES_IF_NEEDED: 'make_changes_if_needed',
    RECEIVE_INFORMATION: 'receive_information'
}

export const MANAGEMENT_REVIEW = {
    STATUS_COLORS,
    STATUS_GUIDES,
    SORT_VALUES,
    SORT_ACTIONS,
    FREQUENCIES,
    STATUSES,
    INTENTIONS,
    END_ACTIVITIES,
    DATA_SOURCES,
    FIRST_OCCURANCES,
    POSSIBLE_FREQUENCIES,
    UNEDITABLE_FREQUENCIES,
    INFORMERS,
    OUTPUT_TYPES
}
