import { DMS_ROUTES, MY_PERMISSIONS } from '@src/constants'
import { myPermissionsGuard } from './guards'

export default {
    path: 'dms',
    name: DMS_ROUTES.INDEX.name,
    component: () =>
        import(
            '@/containers/DocumentManagementSystem/DocumentManagementSystem'
        ),
    props: true,
    redirect: to => ({ name: 'dms.explorer', params: { ...to.params } }),
    children: [
        {
            path: 'explorer',
            name: DMS_ROUTES.FILE_EXPLORER.name,
            component: () =>
                import('@/components/dms/FileAndDirectoryExplorer'),
            props: true,
            meta: {
                pageTitle: 'DOCUMENT_MANAGEMENT_SYSTEM.EXPLORER',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.DOCUMENT_EXPLORER_ACCESS]
            }
        },
        {
            path: 'file-preview/document/:documentId',
            name: DMS_ROUTES.FILE_PREVIEW.name,
            component: () => import('@/components/dms/FilePreview'),
            props: true,
            redirect: to => ({
                name: DMS_ROUTES.DISCUSSIONS.name,
                params: { ...to.params }
            }),
            meta: {
                pageTitle: 'DOCUMENT_MANAGEMENT_SYSTEM.FILES.PREVIEW'
            },
            children: [
                {
                    path: 'properties',
                    name: DMS_ROUTES.PROPERTIES.name,
                    component: () =>
                        import('@/components/document-wizard/PropertiesTab'),
                    props: true,
                    meta: {
                        pageTitle: 'DOCUMENT_MANAGEMENT_SYSTEM.FILES.PREVIEW'
                    }
                },
                {
                    path: 'discussions',
                    name: DMS_ROUTES.DISCUSSIONS.name,
                    component: () =>
                        import('@/components/document-wizard/Discussions'),
                    props: true,
                    meta: {
                        pageTitle: 'DOCUMENT_MANAGEMENT_SYSTEM.FILES.PREVIEW'
                    }
                }
            ]
        }
    ]
}
