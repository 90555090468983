import { ROUTES_CONSULTANT } from './constants'

export default {
    path: '/consultant',
    name: ROUTES_CONSULTANT.BASE.name,
    component: () => import('./ModuleConsultant'),
    props: false,
    redirect: to => ({
        name: ROUTES_CONSULTANT.COMPANIES.name
    }),
    meta: {
        requiresAuth: true
    },
    children: [
        {
            path: 'companies',
            name: ROUTES_CONSULTANT.COMPANIES.name,
            component: () => import('./dashboard/ConsultantDashboard'),
            props: true,
            meta: {
                pageTitle: 'APP.SIDEBAR_ROUTES.CONSULTANT.DASHBOARD',
                requiresAuth: true
            }
        }
    ]
}
