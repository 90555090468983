import { mapActions } from 'vuex'
import { TASKS } from '@/constants'

export default {
    methods: {
        ...mapActions('task', ['submitTasks']),
        ...mapActions('myWork', ['addTask']),

        openCreateTaskModal () {
            this.$modal.show('create-task')
        },

        async handleCreateTask (task, callback) {
            try {
                const [createdTask] = await this.submitTasks({
                    data: {
                        ...task,
                        assignees: [task.assigned_to],
                        assigned_to: task.assigned_to ? task.assigned_to : '',
                        task_type: TASKS.TASK_TYPES.CUSTOM
                    }
                })
                this.addTask(createdTask)
                this.$modal.hide('create-task')
                callback()
            } catch (error) {
                console.error(error)
            }
        }
    }
}
