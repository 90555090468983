import { keys, each } from 'lodash-es'

function getInitialState () {
    return {
        approvedDocumentsAndGeneratedReportsFetched: false,
        areasFetched: false,
        assetsFetched: false,
        clausesFetched: false,
        companyFetched: false,
        companyRegulationRegistersFetched: false,
        companyRegulationRegistersLoading: true,
        registersFetched: false,
        complianceFetched: false,
        complianceStepsFetched: false,
        correctiveActionsFetched: false,
        correctiveActionsLoading: true,
        countriesFetched: false,
        documentLoading: true,
        incidentsFetched: false,
        lawsFetched: false,
        activitiesFetched: false,
        activitiesLoading: true,
        nonconformitiesFetched: false,
        performanceFetched: false,
        projectStatusFetched: false,
        projectStatusLoading: false,
        requirementsFetched: false,
        resourcesFetched: false,
        risksLoading: false,
        risksFetched: false,
        registerChangeHistoryLoading: false,
        relatedDocumentsLoading: false,
        riskDataFetched: false,
        stepInputsLoading: true,
        stepLoading: false,
        stepsFetched: false,
        stepRisksLoading: true,
        stepRequirementsLoading: true,
        tasksFetched: false,
        templateLoading: false,
        threatsFetched: false,
        vulnerabilitiesFetched: false,
        usersStepsFetched: false,
        usersDocumentsFetched: false,
        myRegistersFetched: false,
        registersItemsStatusesFetched: false,
        trainingsFetched: false,
        registersAndModulesFetched: false,
        documentFetched: false,
        documentMetaFetched: false,
        stepResourcesFetched: false,
        companyRolesFetched: false,
        managementReviewSessionFetched: false,
        currentCompanyRegulationRegisterLoading: false,
        currentCompanyRegulationRegisterFetched: false,
        dropboxIntegrationLoading: false,
        controlsLoading: false,
        controlsFetched: false
    }
}

const state = getInitialState()

const mutations = {
    SET_RESOURCE_LOADING (state, payload) {
        const { type, status } = payload
        state[type] = status
    },

    SET_RESOURCE_FETCHED (state, payload) {
        const { type, status } = payload

        state[type] = status
    },

    RESET_RESOURCE_FETCHED (state, field) {
        state[field] = false
    },

    RESET_LOADING (state) {
        const s = getInitialState()
        each(keys(state), key => {
            state[key] = s[key]
        })
    }
}

const getters = {
    approvedDocumentsAndGeneratedReportsFetched: state =>
        state.approvedDocumentsAndGeneratedReportsFetched,
    areasFetched: state => state.areasFetched,
    assetsFetched: state => state.assetsFetched,
    clausesFetched: state => state.clausesFetched,
    companyFetched: state => state.companyFetched,
    companyRegulationRegistersFetched: state =>
        state.companyRegulationRegistersFetched,
    companyRegulationRegistersLoading: state =>
        state.companyRegulationRegistersLoading,
    registersFetched: state => state.registersFetched,
    complianceFetched: state => state.complianceFetched,
    complianceStepsFetched: state => state.complianceStepsFetched,
    correctiveActionsFetched: state => state.correctiveActionsFetched,
    correctiveActionsLoading: state => state.correctiveActionsLoading,
    countriesFetched: state => state.countriesFetched,
    documentLoading: state => state.documentLoading,
    incidentsFetched: state => state.incidentsFetched,
    lawsFetched: state => state.lawsFetched,
    activitiesFetched: state => state.activitiesFetched,
    activitiesLoading: state => state.activitiesLoading,
    nonconformitiesFetched: state => state.nonconformitiesFetched,
    performanceFetched: state => state.performanceFetched,
    managementReviewSessionFetched: state =>
        state.managementReviewSessionFetched,
    projectStatusFetched: state => state.projectStatusFetched,
    projectStatusLoading: state => state.projectStatusLoading,
    requirementsFetched: state => state.requirementsFetched,
    resourcesFetched: state => state.resourcesFetched,
    risksLoading: state => state.risksLoading,
    risksFetched: state => state.risksFetched,
    registerChangeHistoryLoading: state => state.registerChangeHistoryLoading,
    relatedDocumentsLoading: state => state.relatedDocumentsLoading,
    riskDataFetched: state => state.riskDataFetched,
    stepInputsLoading: state => state.stepInputsLoading,
    stepLoading: state => state.stepLoading,
    stepRisksLoading: state => state.stepRisksLoading,
    stepRequirementsLoading: state => state.stepRequirementsLoading,
    stepsFetched: state => state.stepsFetched,
    tasksFetched: state => state.tasksFetched,
    templateLoading: state => state.templateLoading,
    threatsFetched: state => state.threatsFetched,
    vulnerabilitiesFetched: state => state.vulnerabilitiesFetched,
    usersStepsFetched: state => state.usersStepsFetched,
    usersDocumentsFetched: state => state.usersDocumentsFetched,
    myRegistersFetched: state => state.myRegistersFetched,
    registersItemsStatusesFetched: state => state.registersItemsStatusesFetched,
    trainingsFetched: state => state.trainingsFetched,
    registersAndModulesFetched: state => state.registersAndModulesFetched,
    documentFetched: state => state.documentFetched,
    documentMetaFetched: state => state.documentMetaFetched,
    stepResourcesFetched: state => state.stepResourcesFetched,
    companyRolesFetched: state => state.companyRolesFetched,
    currentCompanyRegulationRegisterLoading: state =>
        state.currentCompanyRegulationRegisterLoading,
    currentCompanyRegulationRegisterFetched: state =>
        state.currentCompanyRegulationRegisterFetched,
    dropboxIntegrationLoading: state => state.dropboxIntegrationLoading,
    controlsLoading: state => state.controlsLoading,
    controlsFetched: state => state.controlsFetched
}

export const loading = {
    namespaced: true,
    state,
    getters,
    mutations
}
