<template>
    <div v-if="show" class="cf-c-navbar__ctn-inner-plan">
        <p>
            {{ planText }}
            <span v-if="planTextLink" @click="handleLink">{{
                planTextLink
            }}</span>
        </p>
        <contact-administrator-modal />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
    COMPANY,
    COMPANY_SETTINGS_ROUTES,
    CANCELED_PLAN_WARNING_PERIOD
} from '@/constants'
import { ROUTES_CONSULTANT } from '@src/modules/consultant/constants'
import { isNull } from 'lodash-es'
import ContactAdministratorModal from '@/components/modals/ContactAdministrator'
import {
    subscribtionDaysLeftCounter,
    expireDays
} from '@/services/utils/subscriptions'
import { COMPANY_ROLES } from '@src/modules/company-settings/constants'

export default {
    components: {
        ContactAdministratorModal
    },

    data: () => ({
        planText: '',
        planTextLink: false,
        show: false
    }),
    watch: {
        $route (to, from) {
            if (to.path !== from.path) {
                this.showInfoMessage(to)
            }
        }
    },
    async created () {
        this.showInfoMessage(this.$route)
    },

    methods: {
        displayPlanMessage () {
            const currentPlanName = this.pricingPlan?.plan.name

            const subscriptionId = this.pricingPlan?.subscription_id

            // Free trial
            if (isNull(subscriptionId)) {
                // Actual free trial plan
                if (currentPlanName === COMPANY.FREE_PLAN) {
                    return this.messageForFreeTrial(currentPlanName)
                }

                // Expired free trial plan
                if (currentPlanName === COMPANY.FREEZED_PLAN) {
                    return this.messageForExpiredFreeTrial(currentPlanName)
                }
            }

            // Manual or paid plan
            if (!isNull(subscriptionId)) {
                // Expired and freezed paid plan
                if (currentPlanName === COMPANY.FREEZED_PLAN) {
                    return this.messageForExpiredPaidPlan()
                }

                // Canceled plan
                if (currentPlanName === COMPANY.CANCELED_PLAN) {
                    return this.messageForCanceledPaidPlan()
                }
            }
        },

        messageForFreeTrial () {
            if (this.company.isUnderConsultant) {
                this.showInfoBarAndExentLayout(
                    this.$t('COMMON.CONSULTANT_FREE_TRIAL_PLAN_HEADER')
                )
            } else {
                this.showInfoBarAndExentLayout(
                    this.$t('COMMON.DEFAULT_FREE_TRIAL_PLAN_HEADER'),
                    this.$t('COMMON.SUBSCRIBE_HERE')
                )
            }
        },

        messageForExpiredFreeTrial () {
            this.showInfoBarAndExentLayout(
                this.$t('COMMON.FREE_TRIAL_FREEZE_PLAN_HEADER'),
                this.$t('COMMON.RENEW_HERE')
            )
        },

        messageForExpiredPaidPlan () {
            this.showInfoBarAndExentLayout(
                this.$t('COMMON.SUBSCRIPTION_FREEZE_PLAN_HEADER'),
                this.$t('COMMON.RENEW_HERE')
            )
        },

        messageForCanceledPaidPlan () {
            const daysLeft = subscribtionDaysLeftCounter(
                expireDays(this.pricingPlan),
                this.pricingPlan.expires
            )

            let planText = this.$t(
                daysLeft > CANCELED_PLAN_WARNING_PERIOD
                    ? 'COMMON.SUBSCRIPTION_CANCEL_PLAN_HEADER.BEFORE_WARNING_PERIOD'
                    : 'COMMON.SUBSCRIPTION_CANCEL_PLAN_HEADER.AFTER_WARNING_PERIOD'
            ).replace('#', daysLeft)

            const daysText =
                daysLeft === 1
                    ? this.$t('COMPANY_SETTINGS.SUBSCRIPTION.DAY')
                    : this.$t('COMPANY_SETTINGS.SUBSCRIPTION.DAYS')

            planText = planText.replace('%', daysText)

            const planTextLink = this.$t(
                daysLeft > CANCELED_PLAN_WARNING_PERIOD
                    ? 'COMMON.CLICK_HERE'
                    : 'COMMON.RENEW_HERE'
            )

            this.showInfoBarAndExentLayout(planText, planTextLink)
        },

        showInfoBarAndExentLayout (planText, planTextLink) {
            this.planText = planText
            this.planTextLink = planTextLink

            this.show = true

            this.$emit('update-spacing')
        },

        handleLink () {
            if (
                this.companyMember?.role?.name ===
                    COMPANY_ROLES.PROJECT_MANAGER ||
                this.companyMember?.role?.name === COMPANY_ROLES.BILLER
            ) {
                const daysLeft = subscribtionDaysLeftCounter(
                    expireDays(this.pricingPlan),
                    this.pricingPlan.expires
                )
                this.$router
                    .push({
                        name: COMPANY_SETTINGS_ROUTES.SUBSCRIPTION.INDEX.name,
                        params: {
                            subdomain: this.$route.params.subdomain,
                            renewSubscription: !(
                                daysLeft > CANCELED_PLAN_WARNING_PERIOD
                            )
                        }
                    })
                    .catch(() => {})
            } else {
                this.$modal.show('contact-administrator')
            }
        },

        showInfoMessage (route) {
            if (
                !route.name ||
                route.name.includes('login') ||
                route.name.includes(
                    COMPANY_SETTINGS_ROUTES.SUBSCRIPTION.INDEX.name
                ) ||
                route.name.includes(ROUTES_CONSULTANT.COMPANIES.name)
            ) {
                this.show = false
                this.$emit('update-spacing')
            } else {
                if (!isNull(this.company.id)) {
                    this.COMPANY = COMPANY
                    this.displayPlanMessage()
                }
            }
        }
    },

    computed: {
        ...mapGetters('company', ['pricingPlan', 'company']),
        ...mapGetters('account', ['companyMember'])
    }
}
</script>

<style lang="scss" scoped>
@import '@style/sfc.scss';

.cf-c-navbar__ctn-inner-plan {
    padding: rem(8);
    text-align: center;
    background-color: $cf-color-primary-plan;
    color: $cf-color-white;

    p {
        margin: 0;

        span {
            font-weight: bold;
            text-decoration: underline;
            color: $cf-color-white;
            cursor: pointer;
        }
    }
}
</style>
