var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cf-c-step-bar"},_vm._l((_vm.steps),function(step,index){return _c('div',{key:index,class:[
            'cf-c-step-bar__item',
            { 'cf-is-active': _vm.activeStep === index },
            { 'cf-is-passed': step.isPassed },
            { 'cf-c-step-bar__item--inactive': _vm.activeStep === -1 }
        ]},[_vm._m(0,true),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShowLabel(index)),expression:"shouldShowLabel(index)"}],class:[
                'cf-c-step-bar__label',
                'cf-u-uppercased',
                { 'cf-c-step-bar__label--active': _vm.activeStep === index },
                { 'cf-c-step-bar__label--relative': step.icon }
            ]},[_c('p',{staticClass:"cf-c-step-bar__label-text"},[_vm._v(" "+_vm._s(_vm.$t(step.label))+" ")]),(step.icon)?_c('app-icon',{staticClass:"cf-c-step-bar__icon",attrs:{"id":step.label,"icon":_vm.showIcon(step),"size":"md"}}):_vm._e(),(step.subLabel)?_c('div',{staticClass:"cf-c-step-bar__sub-label"},[_vm._v(" "+_vm._s(step.subLabel)+" ")]):_vm._e(),(_vm.shouldShowTooltip(step))?_c('b-tooltip',{attrs:{"target":step.label}},[_vm._v(" "+_vm._s(_vm.$t(step.icon.tooltipText))+" ")]):_vm._e()],1)])}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cf-c-step-bar__progress"},[_c('div',{staticClass:"cf-c-step-bar__line"}),_c('div',{staticClass:"cf-c-step-bar__dot"})])
}]

export { render, staticRenderFns }