import { findIndex, find, isUndefined } from 'lodash-es'
import { incidentService } from '@/services/api/incident'
import { REGISTER_OF_INCIDENTS, CORRECTIVE_ACTIONS } from '@/constants'

function getInitialState () {
    return {
        incidents: []
    }
}

const state = getInitialState()

const actions = {
    async getIncidents ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const incidents = await incidentService.getIncidents(
                companyId,
                regulationId
            )
            commit('SET_INCIDENTS', incidents)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async addAttachments (
        { commit, state },
        { id, files, companyId, regulationId, filesIds }
    ) {
        try {
            const attachments = await incidentService.addAttachments(
                companyId,
                regulationId,
                id,
                files,
                filesIds
            )

            const incident = find(state.incidents, { id })

            commit('UPDATE_INCIDENT', {
                ...incident,
                files: attachments
            })
        } catch (error) {
            throw error
        }
    },

    async addIncident ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const incidentData = {
                ...data,
                risks: data.risks.map(risk => risk.id),
                notificants: data.notificants.map(notificant => notificant.id),
                incidentFiles: data.files.filter(file => !file.id)
            }
            const incident = await incidentService.addIncident(
                companyId,
                regulationId,
                incidentData
            )
            commit('ADD_INCIDENT', { ...incident })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateIncident ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const files = data.files.filter(file => !file.id)
            const filesIds = data.files
                .filter(file => file.id)
                .map(file => file.id)
            let payload = { ...data, state: data.state.name }

            const incident = await incidentService.updateIncident(
                companyId,
                regulationId,
                data.id,
                {
                    ...payload,
                    risks: data.risks.map(risk => risk.id),
                    notificants: data.notificants.map(
                        notificant => notificant.id
                    )
                }
            )
            commit('UPDATE_INCIDENT', incident)

            await dispatch('addAttachments', {
                id: incident.id,
                files,
                companyId,
                regulationId,
                filesIds
            })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async wrapUp ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const { id, type_id, cost, risks, state } = data // eslint-disable-line

            const incident = await incidentService.wrapUp(
                companyId,
                regulationId,
                data.id,
                {
                    id,
                    type_id,
                    cost,
                    state,
                    risks: risks.map(risk => risk.id)
                }
            )
            commit('UPDATE_INCIDENT', incident)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateResponsibilities ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            /* eslint-disable */
            const {
                id,
                assignee_id,
                classification,
                notificants,
                state,
                notificants_ids
            } = data
            /* eslint-enable */

            const incident = await incidentService.updateResponsibilities(
                companyId,
                regulationId,
                data.id,
                {
                    id,
                    assignee_id,
                    classification,
                    notificants_ids,
                    notificants: notificants.map(notificant => notificant.id),
                    state
                }
            )
            commit('UPDATE_INCIDENT', incident)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async markAsReviewed ({ commit, rootGetters, dispatch }, id) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await incidentService.markAsReviewed(companyId, regulationId, id)

            commit('MARK_INCIDENT_AS_REVIEWED', id)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async createIncidentTask (
        { commit, rootGetters, dispatch },
        { regulationId, companyId, incidentId, data }
    ) {
        if (isUndefined(regulationId)) {
            const currentRegulation =
                rootGetters['regulation/currentRegulation']
            regulationId = currentRegulation ? currentRegulation.id : null
        }

        if (isUndefined(companyId)) {
            const company = rootGetters['company/company']
            companyId = company ? company.id : null
        }

        commit('SET_APP_LOADING', true, { root: true })
        try {
            const task = await incidentService.createIncidentTask(
                companyId,
                regulationId,
                incidentId,
                data
            )
            commit('ADD_TASK', { id: incidentId, task: task })

            return task
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateIncidentTask (
        { commit, rootGetters, dispatch },
        { regulationId, companyId, incidentId, data }
    ) {
        if (isUndefined(regulationId)) {
            const currentRegulation =
                rootGetters['regulation/currentRegulation']
            regulationId = currentRegulation ? currentRegulation.id : null
        }

        if (isUndefined(companyId)) {
            const company = rootGetters['company/company']
            companyId = company ? company.id : null
        }

        commit('SET_APP_LOADING', true, { root: true })
        try {
            const task = await incidentService.updateIncidentTask(
                companyId,
                regulationId,
                incidentId,
                data
            )
            commit('UPDATE_TASK', {
                id: incidentId,
                taskId: task.id,
                data: { ...task, can_be_deleted: true }
            })

            return task
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async deleteIncident ({ commit, rootGetters }, incidentId) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await incidentService.deleteIncident(
                companyId,
                regulationId,
                incidentId
            )

            commit('REMOVE_INCIDENT', incidentId)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}

const mutations = {
    ADD_TASK (state, { id, task }) {
        const incident = find(state.incidents, { id })

        if (!incident) {
            return
        }

        const { incident: incidentState, ...rest } = task

        incident.tasks.push({ ...rest, can_be_deleted: true })
        incident.state = incidentState.state
    },

    DELETE_TASK (state, { id, taskId }) {
        const incidentIndex = findIndex(state.incidents, ['id', id])
        const incident = state.incidents[incidentIndex]
        const taskIndex = findIndex(incident.tasks, ['id', taskId])

        incident.tasks.splice(taskIndex, 1)
    },

    UPDATE_TASK (state, { id, taskId, data }) {
        const incidentIndex = findIndex(state.incidents, ['id', id])
        const incident = state.incidents[incidentIndex]
        const taskIndex = findIndex(incident.tasks, ['id', taskId])

        incident.tasks.splice(taskIndex, 1, { ...data })
    },

    SET_INCIDENTS (state, incidents) {
        state.incidents = incidents.map(incident => {
            const { name } = incident.state

            return {
                ...incident,
                isResolved: name === REGISTER_OF_INCIDENTS.STATUSES.RESOLVED,
                isAssigned: name === REGISTER_OF_INCIDENTS.STATUSES.ASSIGNED
            }
        })
    },

    ADD_INCIDENT (state, incident) {
        state.incidents = [...state.incidents, { ...incident }]
    },

    UPDATE_INCIDENT (state, incident) {
        const index = findIndex(state.incidents, { id: incident.id })
        incident.isResolved =
            incident.state.name === REGISTER_OF_INCIDENTS.STATUSES.RESOLVED
        incident.isAssigned =
            incident.state.name === REGISTER_OF_INCIDENTS.STATUSES.ASSIGNED

        state.incidents.splice(index, 1, { ...incident })
    },

    ADD_CORRECTIVE_ACTION (state, { id, correctiveAction }) {
        const index = findIndex(state.incidents, { id })
        const incident = state.incidents[index]
        const correctionable = find(correctiveAction.correctionables, {
            corrective_action_id: correctiveAction.id,
            correctionable_id: id,
            correctionable_type:
                CORRECTIVE_ACTIONS.CORRECTIONABLE_TYPES.INCIDENT
        })

        state.incidents.splice(index, 1, {
            ...incident,
            state: { ...correctionable.correctionable.state },
            corrective_actions: [
                ...incident.corrective_actions,
                {
                    corrective_action: correctiveAction,
                    correctionable_id: incident.id,
                    correctionable_type:
                        CORRECTIVE_ACTIONS.CORRECTIONABLE_TYPES.INCIDENT,
                    corrective_action_id: correctiveAction.id,
                    id: correctionable.id
                }
            ]
        })
    },

    DELETE_CORRECTIVE_ACTION (state, { id, correctionablePivotId }) {
        const incident = find(state.incidents, { id })
        const index = findIndex(incident.corrective_actions, {
            id: correctionablePivotId
        })

        incident.corrective_actions.splice(index, 1)
    },

    UPDATE_CORRECTIVE_ACTION (state, { id, data }) {
        const incident = find(state.incidents, { id })
        const index = findIndex(incident.corrective_actions, {
            corrective_action_id: data.id
        })

        incident.corrective_actions.splice(index, 1, {
            ...incident.corrective_actions[index],
            corrective_action: data
        })
    },

    MARK_INCIDENT_AS_REVIEWED (state, id) {
        const index = findIndex(state.incidents, { id })

        state.incidents.splice(index, 1, {
            ...state.incidents[index],
            is_reviewed: true
        })
    },

    REMOVE_INCIDENT (state, incidentId) {
        state.incidents = state.incidents.filter(
            incident => incident.id !== incidentId
        )
    }
}

const getters = {
    incidents: state => state.incidents
}

export const incident = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
