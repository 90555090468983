const ROUTES_PREONBOARDING = {
    BASE: {
        name: 'preonboarding'
    },
    ACCOUNT_DETAILS: {
        name: 'preonboarding.account-details'
    },
    EMAIL_VERIFICATION: {
        name: 'preonboarding.email-verfication'
    },
    COMPANY_SETUP: {
        name: 'preonboarding.company-setup'
    }
}

const CONTACT_SUPPORT_URL = 'https://advisera.com/contact/'
const TERMS_PRIVACY_URL = 'https://advisera.com/terms'
const PRIVACY_NOTICE_URL =
    'https://advisera.com/privacy-notice-conformio-users/'

const LANGUAGES = [
    {
        value: 'en',
        label: 'LANGUAGES.ENGLISH',
        disabled: false
    },
    {
        value: 'de',
        label: 'LANGUAGES.GERMAN',
        disabled: false
    },
    {
        value: 'es',
        label: 'LANGUAGES.SPANISH',
        disabled: true
    },
    {
        value: 'nl',
        label: 'LANGUAGES.DUTCH',
        disabled: true
    },
    {
        value: 'fr',
        label: 'LANGUAGES.FRENCH',
        disabled: true
    }
]

const PERSONA_ISO_27001_IN_COMPANY = 'ISO_27001_IN_COMPANY'
const PERSONA_ISO_27001_FOR_CLIENT = 'ISO_27001_FOR_CLIENT'
const PERSONA_IS0_27001_LEARN_MORE = 'IS0_27001_LEARN_MORE'
const PERSONA_OTHER = 'OTHER'

const PERSONAS = [
    {
        value: PERSONA_ISO_27001_IN_COMPANY,
        label: 'PREONBOARDING.COMPANY_SETUP.PERSONA_ISO_27001_IN_COMPANY'
    },
    {
        value: PERSONA_ISO_27001_FOR_CLIENT,
        label: 'PREONBOARDING.COMPANY_SETUP.PERSONA_ISO_27001_FOR_CLIENT'
    },
    {
        value: PERSONA_IS0_27001_LEARN_MORE,
        label: 'PREONBOARDING.COMPANY_SETUP.PERSONA_IS0_27001_LEARN_MORE'
    },
    {
        value: PERSONA_OTHER,
        label: 'PREONBOARDING.COMPANY_SETUP.PERSONA_OTHER'
    }
]

const ZERO_TO_TWENTY_EMPLOYEES = 'zero_to_twenty_employees'
const TWENTY_TO_FIFTY_EMPLOYEES = 'twenty_to_fifty_employees'
const FIFTY_TO_TWO_HUNDRED_EMPLOYEES = 'fifty_to_two_hundred_employees'
const ONE_HUNDRED_TO_FIVE_HUNDRED_EMPLOYEES =
    'one_hundred_to_five_hundred_employees'
const FIVE_HUNDRED_TO_TWO_THOUSAND_EMPLOYEES =
    'five_hundred_to_two_thousand_employees'
const TWO_THOUSAND_OR_MORE_EMPLOYEES = 'two_thousand_or_more_employees'

const NUMBER_OF_EMPLOYEES = [
    {
        value: ZERO_TO_TWENTY_EMPLOYEES,
        label: 'COMPANY_SETTINGS.ONE_TO_TWENTY_EMPLOYEES'
    },
    {
        value: TWENTY_TO_FIFTY_EMPLOYEES,
        label: 'COMPANY_SETTINGS.TWENTY_TO_FIFTY_EMPLOYEES'
    },
    {
        value: FIFTY_TO_TWO_HUNDRED_EMPLOYEES,
        label: 'COMPANY_SETTINGS.FIFTY_TO_TWO_HUNDRED_EMPLOYEES'
    },
    {
        value: ONE_HUNDRED_TO_FIVE_HUNDRED_EMPLOYEES,
        label: 'COMPANY_SETTINGS.ONE_HUNDRED_TO_FIVE_HUNDRED_EMPLOYEES'
    },
    {
        value: FIVE_HUNDRED_TO_TWO_THOUSAND_EMPLOYEES,
        label: 'COMPANY_SETTINGS.FIVE_HUNDRED_TO_TWO_THOUSAND_EMPLOYEES'
    },
    {
        value: TWO_THOUSAND_OR_MORE_EMPLOYEES,
        label: 'COMPANY_SETTINGS.TWO_THOUSAND_OR_MORE_EMPLOYEES'
    }
]

const COMPANY_SETUP_NOT_MANDATORY_FIELDS = [
    'label',
    'schedule_a_call',
    'issued_by',
    'revision_id'
]

export {
    ROUTES_PREONBOARDING,
    TERMS_PRIVACY_URL,
    PRIVACY_NOTICE_URL,
    CONTACT_SUPPORT_URL,
    LANGUAGES,
    PERSONAS,
    PERSONA_OTHER,
    NUMBER_OF_EMPLOYEES,
    COMPANY_SETUP_NOT_MANDATORY_FIELDS
}
