import axios from 'axios'

import { REGISTER_OF_NONCONFORMITIES } from '@/constants'
import {
    objectKeyIntersection,
    objectToFormData
} from '@/services/utils/functions'

const { NONCONFORMITY_PARAMS } = REGISTER_OF_NONCONFORMITIES

const ENDPOINTS = {
    NONCONFORMITIES:
        'companies/:companyId/regulations/:regulationId/nonconformities',
    NONCONFORMITY:
        'companies/:companyId/regulations/:regulationId/nonconformities/:nonconformityId',
    NONCONFORMITY_TASKS:
        'companies/:companyId/regulations/:regulationId/nonconformities/:noncoformityId/tasks',
    DELETE_NONCONFORMITY: (companyId, regulationId, nonconformityId) =>
        `companies/${companyId}/regulations/${regulationId}/nonconformities/${nonconformityId}`
}

export default class NonconformityService {
    /**
     * Get nonconformities for a regulation
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getNonconformities (companyId, regulationId) {
        try {
            const { data } = await axios.get(
                this.getNonconformitiesRoute(companyId, regulationId)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Add nonconformity attachments
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} nonconformityId
     * @param {Array} files
     * @param {Array} filesIds
     *
     * @returns Promise
     */
    async addAttachments (
        companyId,
        regulationId,
        nonconformityId,
        files = [],
        filesIds = []
    ) {
        try {
            const formData = new FormData()
            const route = this.getNonconformityRoute(
                companyId,
                regulationId,
                nonconformityId
            )

            files.forEach(file => {
                formData.append('files[]', file)
            })

            filesIds.forEach(id => {
                formData.append('files_ids[]', id)
            })

            const { data } = await axios.post(`${route}/attachments`, formData)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Create a nonconformity
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Object} nonconformity
     * @param {Array} files
     * @param {Array} filesIds
     *
     * @returns Promise
     */
    async addNonconformity (
        companyId,
        regulationId,
        nonconformity,
        files = []
    ) {
        try {
            const nonconformityData = objectKeyIntersection(
                nonconformity,
                NONCONFORMITY_PARAMS
            )

            const formData = objectToFormData(nonconformityData)

            files.forEach(file => {
                formData.append('files[]', file)
            })

            const { data } = await axios.post(
                this.getNonconformitiesRoute(companyId, regulationId),
                formData
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update a nonconformity
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} nonconformityId
     * @param {Object} nonconformity
     *
     * @returns Promise
     */
    async updateNonconformity (
        companyId,
        regulationId,
        nonconformityId,
        nonconformity
    ) {
        try {
            const { data } = await axios.put(
                this.getNonconformityRoute(
                    companyId,
                    regulationId,
                    nonconformityId
                ),
                objectKeyIntersection(nonconformity, NONCONFORMITY_PARAMS)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Wrap up the nonconformity
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} nonconformityId
     * @param {Object}
     *
     * @returns Promise
     */
    async wrapUp (companyId, regulationId, nonconformityId, nonconformity) {
        try {
            const { data } = await axios.put(
                `${this.getNonconformityRoute(
                    companyId,
                    regulationId,
                    nonconformityId
                )}/wrap-up`,
                objectKeyIntersection(nonconformity, NONCONFORMITY_PARAMS)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update responsibilities of a nonconformity
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} nonconformityId
     * @param {Object}
     *
     * @returns Promise
     */
    async updateResponsibilities (
        companyId,
        regulationId,
        nonconformityId,
        nonconformity
    ) {
        try {
            const { data } = await axios.put(
                `${this.getNonconformityRoute(
                    companyId,
                    regulationId,
                    nonconformityId
                )}/responsibilities`,
                objectKeyIntersection(nonconformity, NONCONFORMITY_PARAMS)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    async createNonconformityTask (
        companyId,
        regulationId,
        nonconformityId,
        taskData
    ) {
        try {
            const { data } = await axios.post(
                `${this.getNonconformityRoute(
                    companyId,
                    regulationId,
                    nonconformityId
                )}/tasks`,
                taskData
            )

            return data
        } catch (error) {
            throw error
        }
    }

    async updateNonconformityTask (
        companyId,
        regulationId,
        nonconformityId,
        taskData
    ) {
        try {
            const { data } = await axios.put(
                `${this.getNonconformityRoute(
                    companyId,
                    regulationId,
                    nonconformityId
                )}/tasks/${taskData.id}`,
                taskData
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get nonconformities route
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     *
     * @returns {String}
     */
    getNonconformitiesRoute (companyId, regulationId) {
        return ENDPOINTS.NONCONFORMITIES.replace(
            ':companyId',
            companyId
        ).replace(':regulationId', regulationId)
    }

    /**
     * Get nonconformity route
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} nonconformityId
     *
     * @returns {String}
     */
    getNonconformityRoute (companyId, regulationId, nonconformityId) {
        return ENDPOINTS.NONCONFORMITY.replace(':companyId', companyId)
            .replace(':regulationId', regulationId)
            .replace(':nonconformityId', nonconformityId)
    }

    /**
     * Delete nonconformity
     *
     * @param {Number|String} companyId
     * @param {Number|String} regulationId
     * @param {Number|String} nonconformityId
     *
     * @returns Promise
     */
    async deleteNonconformity (companyId, regulationId, nonconformityId) {
        try {
            const route = ENDPOINTS.DELETE_NONCONFORMITY(
                companyId,
                regulationId,
                nonconformityId
            )
            const { data } = await axios.delete(route)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const nonconformityService = new NonconformityService()
