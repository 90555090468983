<template>
    <tr>
        <th class="cf-c-data-card-table__th">
            {{ title }}
        </th>
        <td class="cf-c-data-card-table__td" :class="textColorStyles">
            {{ content }}
        </td>
    </tr>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },

        content: {
            type: [String, Number],
            required: true
        },

        textColor: {
            type: String,
            required: false,
            default: ''
        }
    },

    computed: {
        textColorStyles () {
            if (this.textColor.length) {
                return 'cf-u-color-' + this.textColor
            }

            return ''
        }
    }
}
</script>
