<template>
    <div class="cf-c-video-responsive cf-c-video-responsive--16by9">
        <video controls>
            <source :src="src" type="video/mp4" />
        </video>
    </div>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            required: false,
            default: '../../assets/placeholder.mp4'
        }
    }
}
</script>
