<template>
    <app-modal
        :name="modalName"
        :title="title"
        :ok-title="okTitle"
        :cancel-title="cancelTitle"
        max-width38
        :click-to-close="clickToClose"
        @ok="handleConfirm"
        @cancel="handleCancel"
    >
        <template slot="body">
            {{ $t(text) }}
        </template>
    </app-modal>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        },

        modalName: {
            type: String,
            required: true
        },

        title: {
            type: String,
            required: true
        },

        okTitle: {
            type: String,
            required: false,
            default: 'COMMON.CONFIRM'
        },

        cancelTitle: {
            type: String,
            required: false,
            default: 'COMMON.CANCEL'
        },

        shouldHideOnCancel: {
            type: Boolean,
            required: false,
            default: false
        },

        clickToClose: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    methods: {
        handleCancel () {
            this.$emit('cancel')
            this.shouldHideOnCancel && this.$modal.hide(this.modalName)
        },

        handleConfirm () {
            this.$emit('confirm')
        }
    }
}
</script>
