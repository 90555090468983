<template>
    <v-modal
        ref="modal"
        :name="name"
        :adaptive="true"
        :scrollable="scrollable"
        :classes="getModalStyles"
        height="auto"
        width="100%"
        :click-to-close="clickToClose"
        :class="getContainerStyles"
        @closed="$emit('closed')"
        @before-open="$emit('before-open', $event)"
    >
        <div class="cf-c-app-modal">
            <div class="cf-c-app-modal__header">
                <h4>
                    {{ $t(title) }}
                </h4>
                <slot name="header-actions"></slot>
            </div>
            <div class="cf-c-app-modal__body">
                <slot name="body">
                    <span class="cf-c-modal__text">
                        {{ $t(body) }}
                    </span>
                </slot>
            </div>
            <slot name="footer">
                <div v-if="!hideFooter" class="cf-c-app-modal__footer">
                    <slot name="extra"></slot>
                    <slot v-if="!okOnly" name="cancel">
                        <div class="cf-c-app-modal__footer-item">
                            <app-button
                                variation="danger-light"
                                size="full"
                                @click="handleCancel"
                            >
                                {{ $t(cancelTitle) }}
                            </app-button>
                        </div>
                    </slot>
                    <slot name="ok">
                        <div class="cf-c-app-modal__footer-item">
                            <app-button
                                variation="primary"
                                size="full"
                                :disabled="okDisabled"
                                @click="$emit('ok')"
                            >
                                {{ $t(okTitle) }}
                            </app-button>
                        </div>
                    </slot>
                </div>
            </slot>
        </div>
    </v-modal>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },

        clickToClose: {
            type: Boolean,
            required: false,
            default: true
        },

        body: {
            type: String,
            required: false,
            default: ''
        },

        hideFooter: {
            type: Boolean,
            required: false,
            default: false
        },

        okOnly: {
            type: Boolean,
            required: false,
            default: false
        },

        okTitle: {
            type: String,
            required: false,
            default: 'COMMON.PROCEED'
        },

        okDisabled: {
            type: Boolean,
            required: false,
            default: false
        },

        cancelTitle: {
            type: String,
            required: false,
            default: 'COMMON.CANCEL'
        },

        name: {
            type: String,
            required: false,
            default: 'modal'
        },

        minWidth: {
            type: Boolean,
            required: false,
            default: false
        },

        maxWidth53: {
            type: Boolean,
            required: false,
            default: false
        },

        maxWidth63: {
            type: Boolean,
            required: false,
            default: false
        },

        maxWidth38: {
            type: Boolean,
            required: false,
            default: false
        },

        maxWidth25: {
            type: Boolean,
            required: false,
            default: false
        },

        maxWidth27: {
            type: Boolean,
            required: false,
            default: false
        },

        minWidth44: {
            type: Boolean,
            required: false,
            default: false
        },

        minWidth42: {
            type: Boolean,
            required: false,
            default: false
        },

        modalExplorer: {
            type: Boolean,
            required: false,
            default: false
        },

        customCancelCallback: {
            type: Function,
            required: false
        },

        scrollable: {
            type: Boolean,
            required: false,
            default: true
        },

        scrollableBody: {
            type: Boolean,
            required: false,
            default: false
        },

        scrollableBodyMd: {
            type: Boolean,
            required: false,
            default: false
        },

        flat: {
            type: Boolean,
            required: false,
            default: false
        },

        approval: {
            type: Boolean,
            required: false,
            default: false
        },

        hideBorder: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        getModalStyles () {
            let classes = 'cf-c-app-modal-wrapper '

            if (this.minWidth) {
                classes += 'cf-c-app-modal-wrapper--min-width-23 '
            }

            if (this.maxWidth25) {
                classes += 'cf-c-app-modal-wrapper--max-width-25 '
            }

            if (this.maxWidth27) {
                classes += 'cf-c-app-modal-wrapper--max-width-27 '
            }

            if (this.maxWidth38) {
                classes += 'cf-c-app-modal-wrapper--max-width-38 '
            }

            if (this.minWidth44) {
                classes += 'cf-c-app-modal-wrapper--min-width-44 '
            }

            if (this.minWidth42) {
                classes += 'cf-c-app-modal-wrapper--min-width-42 '
            }

            if (this.modalExplorer) {
                classes += 'cf-c-app-modal-wrapper--modal-explorer '
            }

            if (this.maxWidth53) {
                classes += 'cf-c-app-modal-wrapper--max-width-53 '
            }

            if (this.approval) {
                classes += 'cf-c-app-modal-wrapper--approval '
            }

            if (this.minWidth50) {
                classes += 'cf-c-app-modal-wrapper--min-width-50 '
            }

            if (this.maxWidth63) {
                classes += 'cf-c-app-modal-wrapper--max-width-63 '
            }

            if (this.scrollableBody) {
                classes += 'cf-c-app-modal-wrapper--scrollable-body '
            }

            if (this.scrollableBodyMd) {
                classes += 'cf-c-app-modal-wrapper--scrollable-body-md '
            }

            if (this.flat) {
                classes += 'cf-c-app-modal--flat '
            }

            if (this.hideBorder) {
                classes += 'cf-c-app-modal--no-border '
            }

            return classes.trim()
        },

        getContainerStyles () {
            let classes = 'cf-v-modal-embedded '
            if (!this.scrollable) {
                classes += 'cf-c-app-modal--no-scrolling-overlay '
            }

            return classes.trim()
        }
    },

    methods: {
        handleCancel () {
            this.$emit('cancel')

            if (this.customCancelCallback) {
                return this.customCancelCallback()
            }

            this.$modal.hide(this.name)
        }
    }
}
</script>
