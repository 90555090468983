<template>
    <div class="cf-c-multiple-checklist__item">
        <labeled-checkbox
            :id="`checkbox-${id}`"
            :value="item.checked"
            :disabled="disabled || isAutoDisabled"
            :label="label"
            :class="[
                'cf-c-labeled-checkbox--inside-collapsible',
                classLimitExceeded
            ]"
            @input="$emit('input', $event)"
        />
        <b-tooltip v-if="disabled || isAutoDisabled" :target="`checkbox-${id}`">
            {{ tooltipMessage }}
        </b-tooltip>
    </div>
</template>

<script>
export default {
    inject: ['tooltipMessages'],

    props: {
        item: {
            type: Object,
            required: true
        },

        label: {
            type: String,
            required: true
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        isLimitExceeded: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data () {
        return {
            id: null
        }
    },

    computed: {
        classLimitExceeded () {
            return this.isAutoDisabled ? 'cf-u-limit-default' : ''
        },

        isAutoDisabled () {
            // Autodisable item if is not checked and limit is exceeded
            return this.isLimitExceeded && !this.item.checked
        },

        tooltipMessage () {
            return this.isAutoDisabled
                ? this.tooltipMessages.limitExceededMessage
                : this.tooltipMessages.message
        }
    },

    mounted () {
        this.id = this._uid
    }
}
</script>
