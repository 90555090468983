import { isObject, isString, find, isNil, last, first } from 'lodash-es'
import {
    DOCUMENT_TYPES,
    SYSTEM_TYPES,
    FILE_FORMAT,
    SYSTEM_FOLDERS
} from '@/constants'
import { config } from '@/config'
import store from '@src/store'
import { COMPANY_ROLES } from '@src/modules/company-settings/constants'

export function isPublic (item) {
    return (
        isObject(item) &&
        Object.prototype.hasOwnProperty.call(item, 'has_permissions') &&
        !item.has_permissions
    )
}

export function isFile (item) {
    return isObject(item) && !isNil(item.current_status)
}

export function isFolder (item) {
    return isObject(item) && isNil(item.current_status)
}

export function prefixWithType (item) {
    if (!isFile(item)) {
        return isPublic(item)
            ? SYSTEM_TYPES.PUBLIC_FOLDER
            : SYSTEM_TYPES.PRIVATE_FOLDER
    } else {
        switch (getFileFormat(item.name)) {
            case FILE_FORMAT.PDF:
                return SYSTEM_TYPES.FILE_PDF
            case FILE_FORMAT.DOCX:
                return SYSTEM_TYPES.FILE_DOCX
            case FILE_FORMAT.XLS:
                return SYSTEM_TYPES.FILE_XLS
            case FILE_FORMAT.ODC:
                return SYSTEM_TYPES.FILE_ODC
            case FILE_FORMAT.XLSM:
                return SYSTEM_TYPES.FILE_XLSM
            case FILE_FORMAT.PPT:
                return SYSTEM_TYPES.FILE_PPT
            case FILE_FORMAT.ODS:
                return SYSTEM_TYPES.FILE_ODS
            case FILE_FORMAT.PPTM:
                return SYSTEM_TYPES.FILE_PPTM
            case FILE_FORMAT.ODT:
                return SYSTEM_TYPES.FILE_ODT
            case FILE_FORMAT.ODP:
                return SYSTEM_TYPES.FILE_ODP
            case FILE_FORMAT.MPP:
                return SYSTEM_TYPES.FILE_MPP
            case FILE_FORMAT.PPTX:
                return SYSTEM_TYPES.FILE_PPTX
            case FILE_FORMAT.DOCM:
                return SYSTEM_TYPES.FILE_DOCM
            case FILE_FORMAT.PPSX:
                return SYSTEM_TYPES.FILE_PPSX
            case FILE_FORMAT.JPEG:
                return SYSTEM_TYPES.FILE_JPEG
            case FILE_FORMAT.JPG:
                return SYSTEM_TYPES.FILE_JPEG
            case FILE_FORMAT.XLSX:
                return SYSTEM_TYPES.FILE_XLSX
            case FILE_FORMAT.PNG:
                return SYSTEM_TYPES.FILE_PNG
            default:
                return SYSTEM_TYPES.FILE_DEFAULT
        }
    }
}

function getFileFormat (fileName) {
    return fileName.split('.').pop()
}

export function userHasAccess (user, item, parentFolder, type = 'write') {
    if (user.isProjectManager) {
        return true
    }

    return isFile(item) || !item
        ? userHasAccessToFile(user, parentFolder, type)
        : userHasAccessToFolder(user, item, type)
}

export function userHasAccessToFile (user, parentFolder, type) {
    return userHasAccessToFolder(user, parentFolder, type)
}

export function userHasAccessToFolder (user, item, type) {
    if (isPublic(item) || folderBelongsToUser(user, item)) {
        return true
    }

    if (
        isObject(item) &&
        Object.prototype.hasOwnProperty.call(item, 'folder_access')
    ) {
        const access = find(item.folder_access, { id: user.id })
        return access ? access.pivot[type] : false
    }

    return false
}

export function folderBelongsToUser (user, folder) {
    return (
        isObject(folder) &&
        Object.prototype.hasOwnProperty.call(folder, 'folder_owner') &&
        (folder.folder_owner.id === user.id || user.isProjectManager)
    )
}

export function getItemType (item) {
    return isFile(item) ? 'FILES' : 'FOLDERS'
}

export function isCloudDocument (item) {
    return item && item.cloud_provider
}

export function isCloudFolder (item) {
    return item && item.cloud_provider && item.type === SYSTEM_TYPES.FOLDER
}

export function isPlainDocument (item) {
    return item && item.targetable_versions && item.targetable_versions.length
        ? last(item.targetable_versions).documentable_type ===
              SYSTEM_TYPES.PLAIN_DOCUMENT
        : false
}

export function isUserUploadedDocument (item) {
    return item && item.targetable_versions && item.targetable_versions.length
        ? last(item.targetable_versions).documentable_type ===
              DOCUMENT_TYPES.USER_UPLOAD_DOCUMENT
        : false
}

export function isConformioDocument (item) {
    return item && item.targetable_versions && item.targetable_versions.length
        ? last(item.targetable_versions).documentable_type ===
              DOCUMENT_TYPES.CONFORMIO
        : false
}

function getDmsFilePreviewRoute (companyId, documentId) {
    return `dms/companies/${companyId}/preview/document/${documentId}`
}

function getDmsFileVersionPreviewRoute (companyId, documentId, version) {
    return `dms/companies/${companyId}/preview/document/${documentId}/version/${version}`
}

export function getFilePreviewUrl (companyId, documentId) {
    return config.getApiUrl() + getDmsFilePreviewRoute(companyId, documentId)
}

export function getFileVersionPreviewUrl (companyId, documentId, version) {
    return (
        config.getApiUrl() +
        getDmsFileVersionPreviewRoute(companyId, documentId, version)
    )
}

export function getCloudFileDownloadUrl (companyId, cloudProvider, fullPath) {
    return (
        config.getApiUrl() +
        `dms/companies/${companyId}/cloud-file/download?provider=${cloudProvider}&path=${fullPath}`
    )
}

export function isPolicyAndProceduresFolder (item) {
    return (
        isFolder(item) && item.alias === SYSTEM_FOLDERS.POLICIES_AND_PROCEDURES
    )
}

export function isListsReportsStatementsAndPlansFolder (item) {
    return isFolder(item) && item.alias === SYSTEM_FOLDERS.LRSP
}

export function isTemplatesForManualEditingFolder (item) {
    return (
        isFolder(item) &&
        item.alias === SYSTEM_FOLDERS.TEMPLATES_FOR_MANUAL_EDIT
    )
}

export function isEmptyPath (path) {
    return (
        (isString(path) && !path) ||
        (isObject(path) && path.length === 1 && !first(path))
    )
}

export function isISO27001Folder (path) {
    return (
        isObject(path) &&
        path.length === 1 &&
        first(path) === SYSTEM_FOLDERS.ISO_27001
    )
}

export function extractDocumentStoredPath (document) {
    const path = last(document.targetable_versions)?.documentable.path

    if (path) {
        const pathPartials = path.split('/')

        pathPartials.splice(0, 2)
        pathPartials.pop()

        return pathPartials.toString()
    }

    return path
}
