import { commentService } from '@/services/api/comment'
import { findIndex } from 'lodash-es'
import { formatComment, FORMAT_OPTIONS } from '@/services/utils/comment'

function getInitialState () {
    return {
        currentDiscussions: [],
        currentCommentReferenceId: '',
        currentCommentTargetableType: '',
        currentCommentTargetableId: null,
        currentCommentCommentableVersionId: null,
        commentAdded: false
    }
}

const state = getInitialState()

const actions = {
    async resolveComment (
        { commit, dispatch, rootGetters },
        { comment, companyId = '', regulationId = '' }
    ) {
        try {
            companyId = companyId || rootGetters['company/company'].id
            regulationId =
                regulationId || rootGetters['regulation/currentRegulation'].id

            commit('SET_COMMENT_RESOLVE_STATUS', {
                comment,
                status: true
            })
            await commentService.resolveComment(
                companyId,
                regulationId,
                comment
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            commit('SET_COMMENT_RESOLVE_STATUS', {
                comment,
                status: false
            })
            throw error
        }
    },

    async deleteComment (
        { commit, rootGetters },
        { companyId, regulationId, comment, accessToken }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            companyId = companyId || rootGetters['company/company'].id
            regulationId =
                regulationId || rootGetters['regulation/currentRegulation'].id

            await commentService.deleteComment(
                companyId,
                regulationId,
                comment.id,
                accessToken
            )
            comment.parent_id
                ? commit('DELETE_REPLY', comment)
                : commit('DELETE_COMMENT', comment)
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    setCurrentCommentReferenceId ({ commit }, referenceId) {
        commit('SET_CURRENT_COMMENT_REFERENCE_ID', referenceId)
    },

    async addComment (
        { commit, getters, rootGetters, dispatch },
        { companyId, regulationId, ...data }
    ) {
        commit('SET_COMMENT_ADDED', false)
        commit('SET_APP_LOADING', true, { root: true })
        try {
            companyId = companyId || rootGetters['company/company'].id
            regulationId =
                regulationId || rootGetters['regulation/currentRegulation'].id

            const {
                content,
                parent_id: parentId = null,
                creatorId,
                external_creator_email: email
            } = data

            let payload = {
                external_creator_email: email,
                content,
                creator_id: creatorId,
                parent_id: parentId,
                reference_id: getters.currentCommentReferenceId,
                targetable_type: getters.currentCommentTargetableType,
                targetable_id: getters.currentCommentTargetableId
            }
            if (getters.currentCommentCommentableVersionId) {
                payload.commentable_version_id =
                    getters.currentCommentCommentableVersionId
            }
            const comment = await commentService.addComment(
                companyId,
                regulationId,
                payload
            )

            const users = rootGetters['company/usersById']

            comment.parent_id
                ? commit('ADD_REPLY', { comment, users })
                : commit('ADD_COMMENT', { comment, users })
            commit('SET_CURRENT_COMMENT_REFERENCE_ID', '')
            commit('SET_COMMENT_ADDED', true)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            commit('SET_COMMENT_ADDED', false)
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}

const mutations = {
    SET_COMMENT_RESOLVE_STATUS (state, payload) {
        state.currentDiscussions.find(
            discussion => discussion.id === payload.comment.id
        ).is_resolved = payload.status
    },

    SET_CURRENT_DISCUSSIONS (state, { discussions, users }) {
        const parsedDiscussions = discussions.map(discussion => {
            const parsedReplies = discussion.replies.map(reply => ({
                ...reply,
                content: formatComment(
                    reply.content,
                    users,
                    FORMAT_OPTIONS.FOR_VIEW_OF_COMMENT
                )
            }))

            return {
                ...discussion,
                content: formatComment(
                    discussion.content,
                    users,
                    FORMAT_OPTIONS.FOR_VIEW_OF_COMMENT
                ),
                replies: parsedReplies
            }
        })

        state.currentDiscussions = parsedDiscussions
    },

    SET_CURRENT_COMMENT_REFERENCE_ID (state, referenceId) {
        state.currentCommentReferenceId = referenceId
    },

    SET_CURRENT_COMMENT_TARGETABLE_TYPE (state, targetableType) {
        state.currentCommentTargetableType = targetableType
    },

    SET_CURRENT_COMMENT_TARGETABLE_ID (state, targetableId) {
        state.currentCommentTargetableId = targetableId
    },

    SET_CURRENT_COMMENT_COMMENTABLE_VERSION_ID (
        state,
        currentCommentCommentableVersionId
    ) {
        state.currentCommentCommentableVersionId = currentCommentCommentableVersionId
    },

    ADD_REPLY (state, { comment, users }) {
        const parentCommentIndex = findIndex(state.currentDiscussions, {
            id: comment.parent_id
        })

        const parentComment = state.currentDiscussions[parentCommentIndex]

        state.currentDiscussions.splice(parentCommentIndex, 1, {
            ...parentComment,
            replies: [
                ...parentComment.replies,
                {
                    ...comment,
                    content: formatComment(
                        comment.content,
                        users,
                        FORMAT_OPTIONS.FOR_VIEW_OF_COMMENT
                    ),
                    is_resolved: false
                }
            ]
        })
    },

    ADD_COMMENT (state, { comment, users }) {
        state.currentDiscussions = [
            ...state.currentDiscussions,
            {
                ...comment,
                content: formatComment(
                    comment.content,
                    users,
                    FORMAT_OPTIONS.FOR_VIEW_OF_COMMENT
                ),
                is_resolved: false,
                replies: []
            }
        ]
    },

    SET_COMMENT_ADDED (state, status) {
        state.commentAdded = status
    },

    DELETE_COMMENT (state, comment) {
        const commentIndex = findIndex(state.currentDiscussions, {
            id: comment.id
        })

        state.currentDiscussions.splice(commentIndex, 1)
    },

    DELETE_REPLY (state, comment) {
        const parentCommentIndex = findIndex(state.currentDiscussions, {
            id: comment.parent_id
        })

        const parentComment = {
            ...state.currentDiscussions[parentCommentIndex]
        }
        parentComment.replies.splice(
            findIndex(parentComment.replies, { id: comment.id }),
            1
        )

        state.currentDiscussions.splice(parentCommentIndex, 1, parentComment)
    }
}

const getters = {
    currentDiscussions: state => state.currentDiscussions,
    currentCommentReferenceId: state => state.currentCommentReferenceId,
    currentCommentTargetableType: state => state.currentCommentTargetableType,
    currentCommentTargetableId: state => state.currentCommentTargetableId,
    currentCommentCommentableVersionId: state =>
        state.currentCommentCommentableVersionId,
    commentAdded: state => state.commentAdded
}

export const comment = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
