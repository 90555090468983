var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.wrapperClasses},[_c('div',{staticClass:"cf-f-vf-collapsible__marker-wrap"},[_c('div',{class:[
                'cf-f-vf-collapsible__marker',
                {
                    'cf-f-vf-collapsible__marker--should-show':
                        _vm.shouldShow && !_vm.visible,
                    'cf-f-vf-collapsible__marker--should-not-show': !_vm.shouldShow,
                    'cf-f-vf-collapsible__marker--visible': _vm.visible
                }
            ]}),(_vm.lastMarkerLine)?_c('div',{class:[
                'cf-f-vf-collapsible__marker-line',
                {
                    'cf-f-vf-collapsible__marker-line--should-show': _vm.shouldShow,
                    'cf-f-vf-collapsible__marker-line--should-not-show': !_vm.shouldShow
                }
            ]}):_vm._e()]),_c('div',{staticClass:"cf-c-vf-collapsible__content-wrap"},[_c('div',{staticClass:"cf-c-vf-collapsible__header",class:_vm.headerClasses},[_c('div',{staticClass:"cf-c-vf-collapsible__header-ctn"}),_c('div',{class:[
                    'cf-c-vf-collapsible__icon-wrap',
                    {
                        'cf-c-vf-collapsible__icon-wrap--no-cursor':
                            !_vm.shouldShow || !_vm.collapsible
                    }
                ],on:{"click":function($event){_vm.shouldShow && _vm.collapsible && _vm.toggleCollapse()}}},[_c('div',{class:[
                        'cf-c-vf-collapsible__header-ctn',
                        {
                            'cf-c-vf-collapsible__header-ctn--should-show':
                                _vm.shouldShow && !_vm.visible,
                            'cf-c-vf-collapsible__header-ctn--should-not-show':
                                !_vm.shouldShow || !_vm.collapsible,
                            'cf-c-vf-collapsible__header-ctn--visible': _vm.visible
                        }
                    ]},[_vm._t("header")],2),_c('div',{class:[
                        'cf-c-vf-collapsible__icon',
                        {
                            'cf-c-vf-collapsible__icon--should-show':
                                _vm.shouldShow && !_vm.visible,
                            'cf-c-vf-collapsible__icon--should-not-show':
                                !_vm.shouldShow || !_vm.collapsible,
                            'cf-c-vf-collapsible__icon--visible': _vm.visible
                        }
                    ]})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"cf-c-vf-collapsible__content",class:_vm.contentClasses},[_vm._t("content")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }