import moment from 'moment'

export const DATE_FORMATS = {
    API: 'YYYY-MM-DD',
    DISPLAY: 'MMM DD, YYYY'
}

export const TIME_FORMATS = {
    API: 'HH:mm:ss',
    DISPLAY: 'hh:mm A'
}

export function formatDate (date, format = DATE_FORMATS.DISPLAY) {
    return date && moment(date).isValid()
        ? moment(date, [DATE_FORMATS.API]).format(format)
        : null
}

export function formatTime (time, format = TIME_FORMATS.API) {
    return time && moment(time, [TIME_FORMATS.API]).isValid
        ? moment(time, [TIME_FORMATS.API]).format(format)
        : null
}

export function isAfter (date, dateToCompare = moment()) {
    return date && dateToCompare
        ? moment(date).isAfter(moment(dateToCompare))
        : !!date
}

export function isBefore (date, dateToCompare = moment()) {
    return date && dateToCompare
        ? moment(date).isBefore(moment(dateToCompare))
        : !!date
}

export function monthDifference (startDate, endDate) {
    return Math.round(moment(endDate).diff(moment(startDate), 'months', true))
}

export function dayDifference (startDate, endDate) {
    return moment(endDate).diff(moment(startDate), 'days')
}

export function beforeNow (compareDate) {
    const utcDate = moment.utc(compareDate, 'YYYYMMDD HH:mm:ss')

    return moment(utcDate).fromNow()
}

export function subtractInDays (from, days) {
    return moment(from).subtract(days, 'days')
}

export function subtractInMonths (from, months) {
    return moment(from).subtract(months, 'months')
}
