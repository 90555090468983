import { integrationService } from '@/services/api/integration'

const state = {}

const actions = {
    async linkWithThirdPartyStorage ({ commit, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        commit(
            'loading/SET_RESOURCE_LOADING',
            {
                type: 'dropboxIntegrationLoading',
                status: true
            },
            { root: true }
        )
        try {
            await integrationService.linkWithThirdPartyStorage(
                data.provider,
                data.formData
            )

            commit('company/SET_COMPANY_FILE_SYSTEM', data.provider, {
                root: true
            })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
            commit(
                'loading/SET_RESOURCE_LOADING',
                {
                    type: 'dropboxIntegrationLoading',
                    status: false
                },
                { root: true }
            )
        }
    }
}

export const integration = {
    namespaced: true,
    state,
    actions
}
