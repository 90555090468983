<template>
    <div class="cf-c-radio-card-list-inline">
        <div
            v-for="option in options"
            :key="option.value"
            class="cf-c-radio-card-list-inline__item"
        >
            <label
                class="cf-c-radio-card"
                :class="{
                    'cf-c-radio-card--descriptive':
                        option.icon || option.description,
                    'cf-c-radio-card--disabled': disabled
                }"
            >
                <input
                    v-model="selected"
                    :disabled="disabled"
                    class="cf-c-radio-card__field"
                    type="radio"
                    :name="name"
                    :value="option.value"
                />
                <div class="cf-c-radio-card__appearance"></div>
                <div class="cf-c-radio-card__ctn">
                    <div v-if="option.icon" class="cf-c-radio-card__icon-wrap">
                        <div class="cf-c-radio-card__icon">
                            <app-icon :icon="option.icon" size="full" />
                        </div>
                    </div>
                    <div class="cf-c-radio-card__label">
                        <div class="cf-c-radio-card__dot-wrapper">
                            <span class="cf-c-radio-card__dot"></span>
                        </div>
                        <span class="cf-c-radio-card__label-text">{{
                            $t(option.label)
                        }}</span>
                    </div>
                    <div
                        v-if="option.description"
                        class="cf-c-radio-card__desc"
                    >
                        {{ $t(option.description) }}
                    </div>
                </div>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: 'options'
        },

        options: {
            type: Array,
            required: true
        },

        value: {
            type: [String, Number],
            required: true
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            selected: this.value
        }
    },

    watch: {
        selected (value) {
            this.$emit('input', value)
        },

        value (value) {
            this.selected = value
        }
    }
}
</script>
