import { findIndex } from 'lodash-es'
import { slackService } from '@/services/api/slack'

function getInitialState () {
    return {
        slackConfigurations: [],
        slackChannels: []
    }
}

const state = getInitialState()

const actions = {
    async getSlackConfigurations ({ commit, dispatch, rootGetters }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const slackConfigurations = await slackService.getSlackConfigurations(
                companyId
            )

            commit('SET_SLACK_CONFIGURATIONS', slackConfigurations)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getSlackChannels ({ commit, dispatch, rootGetters }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const slackChannels = await slackService.getSlackChannels(companyId)

            commit('SET_SLACK_CHANNELS', slackChannels)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateSlackConfiguration (
        { commit, dispatch, rootGetters },
        payload
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const updatedConfiguration = await slackService.updateSlackConfiguration(
                companyId,
                payload
            )

            commit('UPDATE_SLACK_CONFIGURATION', updatedConfiguration)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getSlackIntegration ({ commit, dispatch, rootGetters }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const integration = await slackService.getSlackIntegration(
                companyId
            )

            return integration
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}

const mutations = {
    SET_SLACK_CONFIGURATIONS (state, slackConfigurations) {
        state.slackConfigurations = slackConfigurations
    },

    SET_SLACK_CHANNELS (state, slackChannels) {
        state.slackChannels = slackChannels
    },

    UPDATE_SLACK_CONFIGURATION (state, updatedConfiguration) {
        const index = findIndex(state.slackConfigurations, {
            id: updatedConfiguration.id
        })

        state.slackConfigurations.splice(index, 1, { ...updatedConfiguration })
    }
}

const getters = {
    slackConfigurations: state => state.slackConfigurations,
    slackChannels: state => state.slackChannels
}

export const slack = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
