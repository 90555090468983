const ACCOUNT_ROUTES = {
    INDEX: { name: 'account' },
    SETTINGS: { name: 'account.settings' },
    MY_PROFILE: { name: 'account.my_profile' }
}

const ABOUT_LINK = 'https://advisera.com/about'

const TERMS_LINK = 'http://advisera.com/terms'

const HELP_CENTER_LINK =
    'https://help.advisera.com/hc/en-us/sections/10119033434140-Conformio-Support-Articles'

const HELP_VIDEOS_LINK = 'https://vimeo.com/showcase/6734609'

const STEP_BY_STEP_WIZARD_VIDEO_LINK =
    'https://player.vimeo.com/video/473340135'

const MY_WORK_VIDEO_LINK = 'https://player.vimeo.com/video/472256129'

const INTRO_VIDEO_LINK = 'https://player.vimeo.com/video/472256222'

const MAINTENANCE_VIDEO_LINK = 'https://player.vimeo.com/video/508420736'

const CONTACT_US_LINK = 'https://advisera.com/support/email-us/'

const CONFORMIO_SERVICE_REQUEST_FORM =
    'https://info.advisera.com/support/conformio-service-request-form'

const CONFORMIO_PRICING_LINK = 'https://advisera.com/conformio/#pricing'

const LOGIN_REMEMBER_EXPIRATION = 30
const ACCESS_TOKEN_EXPIRATION = 30
const REFRESH_TOKEN_EXPIRATION = 365

export {
    ACCOUNT_ROUTES,
    ABOUT_LINK,
    TERMS_LINK,
    HELP_CENTER_LINK,
    HELP_VIDEOS_LINK,
    STEP_BY_STEP_WIZARD_VIDEO_LINK,
    MY_WORK_VIDEO_LINK,
    INTRO_VIDEO_LINK,
    CONTACT_US_LINK,
    MAINTENANCE_VIDEO_LINK,
    CONFORMIO_SERVICE_REQUEST_FORM,
    CONFORMIO_PRICING_LINK,
    LOGIN_REMEMBER_EXPIRATION,
    ACCESS_TOKEN_EXPIRATION,
    REFRESH_TOKEN_EXPIRATION
}
