import { find, uniq } from 'lodash-es'
import { COMPANY, ROLES } from '@/constants'

const { PROJECT_ROLES, COMPANY_ROLES } = COMPANY

function findSponsor (members, currentRegulation) {
    const sponsor = find(members, member => {
        return !!find(member.sponsored_projects, { id: currentRegulation.id })
    })

    return sponsor ? sponsor.id : null
}

function findProjectManager (members, currentRegulation) {
    const projectManager = find(
        members,
        member => !!find(member.managed_projects, { id: currentRegulation.id })
    )

    return projectManager ? projectManager.id : null
}

function findMemberByProjectRole (role, members, currentRegulation) {
    if (role === ROLES.PROJECT_MANAGER) {
        return findProjectManager(members, currentRegulation)
    }
    if (role === ROLES.SPONSOR) {
        return findSponsor(members, currentRegulation)
    }

    return null
}

function findMemberByCompanyRole (role, members) {
    const member = find(
        members,
        member => !!find(member.company_roles, { name: role })
    )

    return member ? member.id : null
}

export function findMemberIdByRole (role, members, currentRegulation) {
    if (PROJECT_ROLES.includes(role)) {
        return findMemberByProjectRole(role, members, currentRegulation)
    }
    if (COMPANY_ROLES.includes(role)) {
        return findMemberByCompanyRole(role, members)
    }

    return null
}

function findDepartmentHeads (members) {
    return members
        .filter(member => member.departments.length)
        .map(member => member.id)
}

export function findMemberIdsByRoles (roles, members, currentRegulation) {
    let ids = []

    roles.forEach(role => {
        if (PROJECT_ROLES.includes(role)) {
            ids.push(findMemberByProjectRole(role, members, currentRegulation))
        }
        if (COMPANY_ROLES.includes(role)) {
            ids.push(findMemberByCompanyRole(role, members))
        }
        if (role === COMPANY.DEPARTMENT_HEADS) {
            ids = [...ids, ...findDepartmentHeads(members)]
        }
    })

    return uniq(ids.filter(id => id))
}

export function isFreeTrialPlan (pricingPlanName) {
    return !!pricingPlanName && pricingPlanName === COMPANY.FREE_PLAN
}

export function isFreezedPlan (pricingPlanName) {
    return !!pricingPlanName && pricingPlanName === COMPANY.FREEZED_PLAN
}
