import { mapActions, mapGetters } from 'vuex'
import { cloneDeep, isEqual, map, pick, trim, find, get } from 'lodash-es'
import AdaptationSection from '@/components/registers/internal-audits/adaptation/Section'
import AdaptationContent from '@/components/registers/internal-audits/adaptation/Content'
import NewAdaptationSection from '@/components/registers/internal-audits/adaptation/AddNewSection'
import ItemModal from '@/components/modals/internal-audits/checklist-adaptation/ItemModal'
import { ITEM_COMPARISON_FIELDS, SECTION_COMPARISON_FIELDS } from '@/constants'

export default {
    components: {
        AdaptationSection,
        AdaptationContent,
        NewAdaptationSection,
        ItemModal
    },

    data () {
        return {
            editingItem: null,
            deletingItem: null,
            deletingSection: null
        }
    },

    computed: {
        ...mapGetters('regulation', ['currentRegulation']),
        ...mapGetters('internalAudit', ['internalAudit', 'checklists']),

        auditSubtitle () {
            return get(
                this.internalAudit,
                'title',
                this.$t(
                    'REGISTERS.INTERNAL_AUDITS.CHECKLIST_ADAPTATION.HEADING_INFO'
                )
            )
        }
    },

    methods: {
        ...mapActions('internalAudit', [
            'addChecklistItem',
            'updateChecklistItem',
            'updateChecklist',
            'deleteChecklistItem',
            'reorderChecklist',
            'addSection',
            'deleteSection',
            'getChecklists',
            'saveChecklist'
        ]),

        mapChecklistForComparison (oldChecklist) {
            return {
                sections: map(oldChecklist.sections, oldSection => {
                    return {
                        ...pick(oldSection, SECTION_COMPARISON_FIELDS),
                        items: map(oldSection.items, oldItem => {
                            return pick(oldItem, ITEM_COMPARISON_FIELDS)
                        })
                    }
                })
            }
        },

        isChecklistModified (newChecklist) {
            if (!newChecklist.template_checklist_id) {
                return false
            }
            const oldChecklist = find(this.checklists, {
                id: newChecklist.template_checklist_id
            })

            const oldChecklistMapped = this.mapChecklistForComparison(
                oldChecklist
            )
            const newChecklistMapped = this.mapChecklistForComparison(
                newChecklist
            )
            const isModified = !isEqual(oldChecklistMapped, newChecklistMapped)

            return isModified
        },

        async handleAddSection (data, callback) {
            try {
                await this.addSection(data)
                callback && callback()
            } catch (error) {
                console.error(error)
            }
        },

        handleSectionDelete (section) {
            this.deletingSection = section
            this.$modal.show('confirm-delete-section')
        },

        async confirmDeleteSection () {
            try {
                await this.deleteSection(this.deletingSection)
                this.deletingSection = null
                this.$modal.hide('confirm-delete-section')
            } catch (error) {
                console.error(error)
            }
        },

        async newItem () {
            this.$modal.show('save-adapting-checklist-item')
        },

        async editItem (item) {
            this.editingItem = cloneDeep(item)
            this.$modal.show('save-adapting-checklist-item')
        },

        async confirmDeleteItem () {
            try {
                await this.deleteChecklistItem({
                    regulationId: this.currentRegulation.id,
                    auditId: this.$route.params.auditId,
                    checklistId: this.internalAudit.checklist.id,
                    sectionId: this.deletingItem.checklist_section_id,
                    item: this.deletingItem
                })
                this.deletingItem = null
                this.$modal.hide('confirm-delete-item')
            } catch (error) {
                console.error(error)
            }
        },

        async deleteItem (item) {
            this.deletingItem = item
            this.$modal.show('confirm-delete-item')
        },

        async handleSave (item, callback) {
            try {
                if (this.editingItem) {
                    await this.updateChecklistItem({
                        data: { ...item, id: this.editingItem.id }
                    })
                } else {
                    await this.addChecklistItem({
                        data: item
                    })
                }
                this.editingItem = null

                callback && callback()
                this.$modal.hide('save-adapting-checklist-item')
            } catch (error) {
                console.error(error)
            }
        },

        handleCancel () {
            this.editingItem = null
        },

        handleItemSectionChange (data) {
            this.reorderChecklist({
                sectionId: data.sectionId,
                item: data.item,
                newIndex: data.newIndex
            })
        },

        handleItemReorder (data) {
            this.reorderChecklist({
                sectionId: null,
                item: data.item,
                newIndex: data.newIndex,
                oldIndex: data.oldIndex
            })
        },

        handleChecklistTitleUpdate (title) {
            if (!trim(title)) {
                return
            }

            this.internalAudit.checklist.title = title
            this.updateChecklist({ data: { title } })
        }
    }
}
