<template>
    <div class="cf-c-v-switch-options">
        <div class="cf-c-v-switch-options__items">
            <div
                v-for="(option, index) in options"
                :key="index"
                :class="[
                    'cf-c-v-switch-options__item',
                    internalValue !== option.value ? 'cf-is-selected' : ''
                ]"
                @click="handleInput(!option.value)"
            >
                {{ option.label }}
            </div>
        </div>
        <app-form-switch
            v-model="internalValue"
            vertical
            :active-color="activeColor"
            :inactive-color="inactiveColor"
            :disabled="disabled"
            :tabindex="tabindex"
            :size="size"
            @input="handleInput"
        />
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        value: {
            type: Boolean,
            required: true
        },

        tabindex: {
            type: Number,
            required: false,
            default: 0
        },

        size: {
            type: String,
            required: false,
            default: ''
        },

        activeColor: {
            type: String,
            required: false,
            default: 'grey'
        },

        inactiveColor: {
            type: String,
            required: false,
            default: ''
        }
    },

    data () {
        return {
            internalValue: false
        }
    },

    watch: {
        value () {
            this.internalValue = this.value
        }
    },

    created () {
        this.internalValue = this.value
    },

    methods: {
        handleInput (value) {
            if (this.disabled) {
                return
            }

            this.internalValue = value
            this.$emit('input', value)
        }
    }
}
</script>
