import {
    each,
    every,
    findIndex,
    includes,
    intersectionWith,
    keys,
    pick,
    some
} from 'lodash-es'
import { riskService } from '@/services/api/risks'
import {
    belongsToCategory,
    hasCategory,
    hasThreat,
    mapOwnersToRisks,
    prepareRiskFromResponse,
    mapRiskForSubmit,
    mapCategories,
    getAssetFromCache,
    getVulnerabilityFromCache,
    reduceCache
} from '@/services/utils/risks'
import {
    REGISTER_OF_RISKS,
    RISKS_LIMITS_THREATS,
    RISKS_LIMITS_VULNERABILITIES
} from '@/constants'
import { REGISTER_STATUSES } from '../../constants/registers'
import {
    formatRisksCacheEntry,
    newAssetWithData
} from '../../services/utils/risks'

function getInitialState () {
    return {
        categories: [],
        threatCategories: [],
        riskData: [],
        risks: [],
        assets: [],
        vulnerabilities: [],
        threats: [],
        risksCache: [],
        riskTreatmentOptions: [],
        stepRisks: [],
        riskSortCriteria: null
    }
}

const state = getInitialState()

const actions = {
    async submitRisksCache ({ commit, state, dispatch, rootGetters }) {
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            // Fallback for unreduced risks caches which may be already in process (reduceCache can be completely removed after some time)
            if (
                state.risksCache.length &&
                state.risksCache[0].transfer_description
            ) {
                reduceCache(state.risksCache)
            }

            const cache = await riskService.submitRisksCache(
                companyId,
                regulationId,
                state.risksCache
            )

            // Update cache
            commit('SET_RISKS_CACHE', cache)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async getRisksCache ({ commit, rootGetters, dispatch }) {
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const cache = await riskService.getRisksCache(
                companyId,
                regulationId
            )

            commit('SET_RISKS_CACHE', cache)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async getRisks ({ commit, dispatch, rootGetters }, options = null) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const { fromCache, dataset, filters, pagination } = options ?? {}

            // Request's URL params
            const params = {
                dataset,
                ...filters,
                ...pick(pagination, ['page', 'perPage'])
            }

            const risks = fromCache
                ? await riskService.getRisksFromCache(
                      companyId,
                      regulationId,
                      params
                  )
                : await riskService.getRisks(companyId, regulationId, params)

            commit('SET_RISKS', risks.data)

            return risks
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getStepRisks ({ commit, rootGetters, dispatch }, stepId) {
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const risks = await riskService.getStepRisks(
                companyId,
                regulationId,
                stepId
            )

            commit('SET_STEP_RISKS', risks)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        }
    },

    async getRiskTreatmentOptions ({ commit }) {
        try {
            const riskTreatmentOptions = await riskService.getRiskTreatmentOptions()
            commit('SET_RISK_TREATMENT_OPTIONS', riskTreatmentOptions)
        } catch {
            commit('SET_RISK_TREATMENT_OPTIONS', [])
        }
    },

    async reviewRisks ({ commit, rootGetters }, risks) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await riskService.reviewRisks(companyId, regulationId, {
                risks: risks.map(risk => ({
                    id: risk.id,
                    impact: risk.impact,
                    likelihood: risk.likelihood,
                    is_reviewed: risk.internal_is_reviewed
                }))
            })

            return true
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async confirmRisk ({ commit, rootGetters }, riskId) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await riskService.confirmRisk(companyId, regulationId, riskId)
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async confirmAllRisks ({ commit, dispatch, rootGetters }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await riskService.confirmAllRisks(companyId, regulationId)
            await dispatch('getRisks')
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async completeStep ({ commit, dispatch, rootGetters }, step) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await riskService.completeStep(companyId, regulationId, step)

            return true
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })

            return false
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getRiskData ({ commit, dispatch, rootGetters }, options = {}) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const riskDataFetched = rootGetters['loading/riskDataFetched']

            let [data] = await Promise.all([
                !riskDataFetched &&
                    riskService.getRiskData(companyId, regulationId),
                options.withCache && dispatch('getRisksCache'),
                options.withRisks &&
                    dispatch('getRisks', { dataset: 'combination' })
            ])

            if (data) {
                commit('SET_ASSETS', data.assets)
                commit('SET_VULNERABILITIES', data.vulnerabilities)
                commit('SET_THREATS', data.threats)

                commit('SET_CATEGORIES')
                commit(
                    'SET_THREAT_CATEGORIES',
                    mapCategories(data.threats, 'threat_category')
                )

                commit('SET_RISK_DATA')
            }
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async addAnAsset ({ commit, dispatch, rootGetters }, payload) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const asset = await riskService.addAnAsset(
                companyId,
                regulationId,
                payload
            )
            commit('ADD_AN_ASSET', asset)
            commit('ADD_ASSET_TO_RISK_DATA', asset)

            return asset
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getThreatsCategories ({ commit, dispatch, rootGetters }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const threatCategories = await riskService.getThreatsCategories(
                companyId,
                regulationId
            )

            commit('SET_THREAT_CATEGORIES', threatCategories)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async addVulnerabilityInCategory (
        { commit, dispatch, rootGetters },
        payload
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const {
                vulnerability: payloadVulnerability,
                ...restPayload
            } = payload

            const vulnerability = await riskService.addVulnerability(
                companyId,
                regulationId,
                payloadVulnerability.id,
                restPayload
            )

            commit('ADD_VULNERABILITY', vulnerability)
            commit('ADD_VULNERABILITY_TO_RISK_DATA', {
                vulnerability
            })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async createVulnerabilityForCategory (
        { commit, dispatch, rootGetters },
        payload
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const vulnerability = await riskService.createNewVulnerability(
                companyId,
                regulationId,
                payload
            )
            commit('ADD_VULNERABILITY', vulnerability)
            commit('ADD_VULNERABILITY_TO_RISK_DATA', {
                vulnerability
            })

            return vulnerability
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async createNewThreat ({ commit, dispatch, rootGetters }, payload) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const threat = await riskService.createNewThreat(
                companyId,
                regulationId,
                payload
            )
            commit('ADD_THREAT', threat)
            commit('ADD_THREAT_TO_RISK_DATA', threat)

            return threat
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async addExistingThreatToOtherVulnerability (
        { commit, dispatch, rootGetters },
        payload
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const { threat: threatPayload, ...restPayload } = payload

            const threat = await riskService.addThreat(
                companyId,
                regulationId,
                threatPayload.id,
                restPayload
            )
            commit('ADD_THREAT', threat)
            commit('ADD_THREAT_TO_RISK_DATA', threat)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async validateDraftRisk ({ dispatch, rootGetters }, payload) {
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            return await riskService.validateDraftRisk(
                companyId,
                regulationId,
                payload
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async autosaveRisk (
        { commit, dispatch, rootGetters },
        { riskId, riskData }
    ) {
        commit('SET_IS_AUTOSAVING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const mappedRisk = mapRiskForSubmit(riskData)

            const response = riskId
                ? await riskService.updateRisk(
                      companyId,
                      regulationId,
                      riskId,
                      mappedRisk
                  )
                : await riskService.createRisk(
                      companyId,
                      regulationId,
                      mappedRisk
                  )

            const risk = prepareRiskFromResponse(response)

            commit(
                'register/UPDATE_CURRENT_COMPANY_REGULATION_REGISTER_STATUS',
                REGISTER_STATUSES.CHANGED,
                { root: true }
            )

            return risk
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_IS_AUTOSAVING', false, { root: true })
        }
    },

    async updateRisk ({ commit, dispatch, rootGetters }, { riskId, riskData }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const mappedRisk = mapRiskForSubmit(riskData)

            const response = await riskService.updateRisk(
                companyId,
                regulationId,
                riskId,
                mappedRisk
            )

            const risk = prepareRiskFromResponse(response)

            // Update risk data
            commit('UPDATE_RISK', risk)

            commit(
                'register/UPDATE_CURRENT_COMPANY_REGULATION_REGISTER_STATUS',
                REGISTER_STATUSES.CHANGED,
                { root: true }
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async deleteRisk (
        { commit, dispatch, rootGetters },
        { risk, riskIndex, removeFromCache = false }
    ) {
        try {
            commit('SET_APP_LOADING', true, { root: true })

            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            // If risk already exists, delete it and also remove from the cache if defined
            if (risk.id) {
                await riskService.deleteRisk(companyId, regulationId, risk.id, {
                    removeFromCache
                })
            } else if (removeFromCache) {
                // Otherwise, just remove risk from cache
                await riskService.removeRiskFromCache(
                    companyId,
                    regulationId,
                    pick(risk, ['asset_id', 'vulnerability_id', 'threat_id'])
                )
            }

            // Finally, remove risk from current state
            riskIndex
                ? commit('REMOVE_RISK_BY_INDEX', riskIndex)
                : commit('REMOVE_RISK_BY_ID', risk.id)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getRiskById ({ commit, dispatch, rootGetters }, riskId) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const response = await riskService.getRisk(
                companyId,
                regulationId,
                riskId
            )

            const risk = prepareRiskFromResponse(response)

            commit('UPDATE_RISK', risk)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    setRiskSortCriteria ({ commit }, criteria) {
        commit('SET_RISK_SORT_CRITERIA', criteria)
    }
}

const mutations = {
    SET_RISKS_CACHE (state, cache) {
        state.risksCache = cache
    },

    SET_RISK_TREATMENT_OPTIONS (state, riskTreatmentOptions) {
        state.riskTreatmentOptions = riskTreatmentOptions
    },

    MARK_RISKS_AS_REVIEWED (state, { risks, isReviewed }) {
        risks.forEach(risk => {
            risk.internal_is_reviewed = isReviewed
        })
    },

    SET_RISK_DATA (state) {
        state.riskData = state.assets.map(asset => {
            return newAssetWithData(asset, state.vulnerabilities, state.threats)
        })
    },

    SET_CATEGORIES (state) {
        state.categories = mapCategories(state.assets, 'asset_category')
    },

    SET_THREAT_CATEGORIES (state, threatsCategories) {
        state.threatCategories = threatsCategories
    },

    ADD_ASSET_TO_RISK_DATA (state, asset) {
        state.riskData.push(
            newAssetWithData(asset, state.vulnerabilities, state.threats)
        )
    },

    ADD_VULNERABILITY_TO_RISK_DATA (state, { vulnerability }) {
        state.riskData.forEach(asset => {
            if (hasCategory(vulnerability, asset.asset_category.id)) {
                const threats = state.threats.filter(threat =>
                    hasThreat(threat, asset, vulnerability)
                )

                const vulnerabilityIndex = findIndex(asset.vulnerabilities, {
                    id: vulnerability.id
                })

                if (vulnerabilityIndex !== -1) {
                    asset.vulnerabilities.splice(vulnerabilityIndex, 1, {
                        ...vulnerability,
                        threats: [...threats]
                    })
                    return
                }

                asset.vulnerabilities.push({
                    ...vulnerability,
                    threats: [...threats]
                })
            }
        })
    },

    ADD_THREAT_TO_RISK_DATA (state, threat) {
        state.riskData.forEach(asset => {
            asset.vulnerabilities.forEach(vulnerability => {
                if (hasThreat(threat, asset, vulnerability)) {
                    const threatIndex = findIndex(vulnerability.threats, {
                        id: threat.id
                    })

                    if (threatIndex !== -1) {
                        const oldThreat = vulnerability.threats[threatIndex]

                        vulnerability.threats.splice(threatIndex, 1, {
                            ...oldThreat,
                            ...threat
                        })
                        return
                    }

                    vulnerability.threats.push(threat)
                }
            })
        })
    },

    UPDATE_ASSET_IN_RISKS_CACHE (state, { asset }) {
        const index = findIndex(state.risksCache, { id: asset.id })

        index !== -1
            ? state.risksCache.splice(index, 1)
            : state.risksCache.push({
                  ...formatRisksCacheEntry(asset),
                  vulnerabilities: []
              })
    },

    UPDATE_VULNERABILITY_IN_RISKS_CACHE (state, { asset, vulnerability }) {
        const assetData = getAssetFromCache(state.risksCache, asset.id)

        const index = findIndex(assetData.vulnerabilities, {
            id: vulnerability.id
        })

        index !== -1
            ? assetData.vulnerabilities.splice(index, 1)
            : assetData.vulnerabilities.push({
                  ...formatRisksCacheEntry(vulnerability, 'vulnerability'),
                  threats: []
              })
    },

    UPDATE_THREAT_IN_RISKS_CACHE (state, { asset, vulnerability, threat }) {
        const assetVulnerability = getVulnerabilityFromCache(
            state.risksCache,
            asset.id,
            vulnerability.id
        )

        const index = findIndex(assetVulnerability.threats, { id: threat.id })

        index !== -1
            ? assetVulnerability.threats.splice(index, 1)
            : assetVulnerability.threats.push({
                  ...formatRisksCacheEntry(threat, 'threat')
              })
    },

    SET_RISKS (state, risks) {
        state.risks = risks
            ? risks.map(risk => prepareRiskFromResponse(risk))
            : []
    },

    UPDATE_RISK (state, risk) {
        state.risks = state.risks.map(stateRisk => {
            if (stateRisk.id === risk.id) {
                return risk
            }

            return stateRisk
        })
    },

    REMOVE_RISK_BY_ID (state, riskId) {
        const riskIndex = state.risks.findIndex(
            stateRisk => stateRisk.id === riskId
        )

        if (riskIndex !== -1) {
            riskIndex !== -1 && state.risks.splice(riskIndex, 1)
        }
    },

    REMOVE_RISK_BY_INDEX (state, riskIndex) {
        riskIndex !== -1 && state.risks.splice(riskIndex, 1)
    },

    SET_ASSETS (state, assets) {
        state.assets = assets
    },

    SET_VULNERABILITIES (state, vulnerabilities) {
        state.vulnerabilities = vulnerabilities
    },

    SET_THREATS (state, threats) {
        state.threats = threats
    },

    ADD_AN_ASSET (state, asset) {
        state.assets.push(asset)
    },

    ADD_VULNERABILITY (state, vulnerability) {
        const indexOfVulnerability = findIndex(state.vulnerabilities, {
            id: vulnerability.id
        })

        if (indexOfVulnerability !== -1) {
            state.vulnerabilities.splice(indexOfVulnerability, 1, vulnerability)
            return
        }

        state.vulnerabilities.push(vulnerability)
    },

    ADD_THREAT (state, threat) {
        const indexOfThreat = findIndex(state.threats, {
            id: threat.id
        })

        if (indexOfThreat !== -1) {
            state.threats.splice(indexOfThreat, 1, threat)
            return
        }

        state.threats.push(threat)
    },

    SET_STEP_RISKS (state, risks) {
        state.stepRisks = risks
    },

    SET_RISK_SORT_CRITERIA (state, criteria) {
        state.riskSortCriteria = criteria
    },

    RESET_RISKS (state) {
        const s = getInitialState()
        each(keys(state), key => {
            state[key] = s[key]
        })
    },

    EMPTY_RISKS (state) {
        state.risks = []
    }
}

const getters = {
    riskTreatmentOptions: state => state.riskTreatmentOptions,
    risksCache: state => state.risksCache,
    riskData: state => state.riskData,
    categories: state => state.categories,
    threatCategories: state => state.threatCategories,
    risks: (state, getters, rootState) => {
        if (!state.riskSortCriteria) {
            return state.risks
        }

        return mapOwnersToRisks(state.risks, rootState.company.users).sort(
            REGISTER_OF_RISKS.RISK_SORT_ACTIONS[state.riskSortCriteria]
        )
    },
    riskSortCriteria: state => state.riskSortCriteria,
    assets: state => state.assets,
    vulnerabilities: state => state.vulnerabilities,
    threats: state => state.threats,
    stepRisks: state => state.stepRisks,
    areAllRisksConfirmed: state =>
        every(state.risks, risk => {
            return (
                includes(
                    REGISTER_OF_RISKS.RISK_CONFIRMATION_STATUSES,
                    risk.status
                ) || risk.is_acceptable
            )
        }) &&
        some(
            state.risks,
            risk =>
                risk.status === REGISTER_OF_RISKS.TREATMENT_STATUSES.CONFIRMED
        ),
    assetsByCategory: state =>
        state.categories.map(category => ({
            label: category.name,
            list: state.assets.filter(asset =>
                belongsToCategory(asset, category.id)
            )
        })),

    filledCategories: state => {
        return intersectionWith(
            state.categories,
            state.risksCache,
            (category, risk) => {
                return category.id === risk.asset_category_id
            }
        )
    },

    eachCategoryHasAsset: (state, getters) => {
        return getters.filledCategories.length === state.categories.length
    },

    eachVulnerabilityHasThreats (state) {
        return state.risksCache.every(risk =>
            risk.vulnerabilities.every(
                vulnerability => vulnerability.threats.length
            )
        )
    },

    eachAssetHasVulnerabilities (state) {
        return state.risksCache.every(risk => risk.vulnerabilities.length)
    },

    subscriptionLimit: (state, getters, rootState) => {
        return (
            rootState.company.company.pricing_plan.subscription.limits
                .risks_limit ?? null
        )
    },

    risksLimit: (state, getters) =>
        getters.subscriptionLimit ?? RISKS_LIMITS_THREATS,

    vulnerabilitiesLimit: () => RISKS_LIMITS_VULNERABILITIES
}

export const risks = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
