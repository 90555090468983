import axios from 'axios'

import { REGISTER_OF_REQUIREMENTS } from '@/constants'
import { objectKeyIntersection } from '@/services/utils/functions'

const { LEGAL_REGULATORY_TYPE } = REGISTER_OF_REQUIREMENTS

const ENDPOINTS = {
    STEP_REQUIREMENTS: (companyId, regulationId, stepId) =>
        `companies/${companyId}/regulations/${regulationId}/steps/${stepId}/requirements`,
    COMPANY_REGULATION_REGISTERS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/registers`,
    COMPANY_REGULATION_REGISTER: (companyId, regulationId, registerId) =>
        `companies/${companyId}/regulations/${regulationId}/registers/${registerId}`,
    DETAILS: (companyId, regulationId, registerId) =>
        `companies/${companyId}/regulations/${regulationId}/registers/${registerId}/details`,
    MY_REGISTERS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/my-registers`,
    MY_REGISTERS_PER_STATUSES: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/my-registers/statuses`,
    META: (companyId, regulationId, registerId) =>
        `companies/${companyId}/regulations/${regulationId}/registers/${registerId}/meta`,
    REGISTERS_AND_MODULES: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/registers-and-modules-info`,
    REGISTER_VIDEO: (companyId, regulationId, registerId, videoId) =>
        `companies/${companyId}/regulations/${regulationId}/registers/${registerId}/videos/${videoId}/seen`,
    REGISTER_CHANGE_HISTORY: (companyId, regulationId, registerId) =>
        `companies/${companyId}/regulations/${regulationId}/registers/${registerId}/history`,
    RELATED_DOCUMENTS: (companyId, regulationId, registerId) =>
        `companies/${companyId}/regulations/${regulationId}/registers/${registerId}/related-documents`
}

export default class RegisterService {
    /**
     * Get step requirements
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} stepId
     *
     * @returns Promise
     */
    async getStepRequirements (companyId, regulationId, stepId) {
        try {
            const route = ENDPOINTS.STEP_REQUIREMENTS(
                companyId,
                regulationId,
                stepId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update company regulation register
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} registerId
     * @param {Object} payload
     *
     * @return Promise
     */
    async updateCompanyRegulationRegister (
        companyId,
        regulationId,
        registerId,
        payload
    ) {
        try {
            const route = ENDPOINTS.COMPANY_REGULATION_REGISTER(
                companyId,
                regulationId,
                registerId
            )
            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get company regulation registers by company regulation
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     *
     * @return Promise
     */
    async getCompanyRegulationRegisters (companyId, regulationId) {
        try {
            const route = ENDPOINTS.COMPANY_REGULATION_REGISTERS(
                companyId,
                regulationId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get company regulation register by company regulation and register
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} registerId
     *
     * @return Promise
     */
    async getCompanyRegulationRegister (companyId, regulationId, registerId) {
        try {
            const route = ENDPOINTS.COMPANY_REGULATION_REGISTER(
                companyId,
                regulationId,
                registerId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get register change history
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} registerId
     */
    async getRegisterChangeHistory (companyId, regulationId, registerId) {
        try {
            const route = ENDPOINTS.REGISTER_CHANGE_HISTORY(
                companyId,
                regulationId,
                registerId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get register details
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} registerId
     *
     * @return Promise
     */
    async getRegisterDetails (companyId, regulationId, registerId) {
        try {
            const route = ENDPOINTS.DETAILS(companyId, regulationId, registerId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update register properties
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} registerId
     * @param {Object} payload
     *
     * @return Promise
     */
    async updateRegisterProperties (
        companyId,
        regulationId,
        registerId,
        payload
    ) {
        try {
            const route = ENDPOINTS.META(companyId, regulationId, registerId)
            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get registers where i am the owner
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} stepId
     *
     * @returns {Object}
     */
    async getMyRegisters (companyId, regulationId) {
        try {
            const route = ENDPOINTS.MY_REGISTERS(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    async getMyRegistersPerStatus (companyId, regulationId) {
        try {
            const route = ENDPOINTS.MY_REGISTERS_PER_STATUSES(
                companyId,
                regulationId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get registers and modules info
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     *
     * @returns {Object}
     */
    async getDataForRegistersAndModulesPage (companyId, regulationId) {
        try {
            const route = ENDPOINTS.REGISTERS_AND_MODULES(
                companyId,
                regulationId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Mark video as seen
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} registerId
     * @param {String|Number} videoId
     *
     * @returns Promise
     */
    async markVideoAsSeen (companyId, regulationId, registerId, videoId) {
        try {
            const route = ENDPOINTS.REGISTER_VIDEO(
                companyId,
                regulationId,
                registerId,
                videoId
            )
            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }

    async getRelatedDocuments (companyId, regulationId, registerId) {
        try {
            const route = ENDPOINTS.RELATED_DOCUMENTS(
                companyId,
                regulationId,
                registerId
            )

            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const registerService = new RegisterService()
