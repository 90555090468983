const DOCUMENT_TYPES = {
    DOCUMENT: 'document',
    USER_UPLOAD_DOCUMENT: 'user-uploaded-document',
    PLAIN_DOCUMENT: 'plain-document',
    CONFORMIO: 'conformio-document',
    COMPANY_REGULATION_REGISTER: 'company-regulation-register'
}

const BASE_HIGHLIGHT_CLASS = 'cf-is-highlighted'

const BASE_CLASSES = [BASE_HIGHLIGHT_CLASS, 'dw-editor__document-highlight']

const INPUT_HIGHLIGHT_CLASSES = ['highlight--type-1']
const HOVERED_COMMENT_CLASSES = ['highlight--type-2']
const PARAGRAPH_HOVER_CLASSES = ['highlight--type-3']
const COMMENT_HIGHLIGHT_CLASSES = ['highlight--type-4']
const CUSTOM_PARAGRAPH_HIGHLIGHT_CLASSES = ['highlight--type-5']

const DISCUSSIONS_FILTER_VALUES = {
    ACTIVE: 0,
    ALL: 1
}

const DOCUMENT_STATUSES = {
    IN_PROGRESS: 'in_progress',
    IN_REVIEW: 'in_review',
    IN_APPROVAL: 'in_approval',
    COMPLETED: 'completed',
    CORRECTIONS_NEEDED: 'corrections_needed',
    WITHDRAWN: 'withdrawn'
}

const OUTPUT_TYPES = {
    DOCUMENT: 'document'
}

const IN_PROGRESS_DOCUMENT_STATUSES = [
    DOCUMENT_STATUSES.IN_PROGRESS,
    DOCUMENT_STATUSES.IN_REVIEW,
    DOCUMENT_STATUSES.IN_APPROVAL,
    DOCUMENT_STATUSES.CORRECTIONS_NEEDED
]

const DOCUMENT_WIZARD_ROUTES = {
    DOCUMENT_WIZARD: { name: 'document-wizard' },
    DOCUMENT: { name: 'document-wizard.document' },
    WIZARD: { name: 'document-wizard.wizard' },
    PROPERTIES: { name: 'document-wizard.properties' },
    DISCUSSIONS: { name: 'document-wizard.discussions' },
    SINGLE_STEP: { name: 'single-step' }
}

const DOCUMENT_WIZARD_MODES = {
    CUSTOM: 'custom'
}

const CUSTOM_PARAGRAPH_ACTIONS = {
    PREVIEW: 'preview',
    CREATE: 'create',
    EDIT: 'edit'
}

const CUSTOM_PARAGRAPH_CHAR_LIMIT = 2500

const DOCUMENT_FILE_PREVIEW_ROUTES = {
    PROPERTIES: 'properties'
}

const DOCUMENT_STATUS_REDIRECT_MAP = {
    [DOCUMENT_STATUSES.IN_PROGRESS]: DOCUMENT_WIZARD_ROUTES.WIZARD.name,
    [DOCUMENT_STATUSES.IN_REVIEW]: DOCUMENT_WIZARD_ROUTES.DISCUSSIONS.name,
    [DOCUMENT_STATUSES.IN_APPROVAL]: DOCUMENT_WIZARD_ROUTES.PROPERTIES.name,
    [DOCUMENT_STATUSES.CORRECTIONS_NEEDED]:
        DOCUMENT_WIZARD_ROUTES.DISCUSSIONS.name,
    [DOCUMENT_STATUSES.COMPLETED]: DOCUMENT_WIZARD_ROUTES.PROPERTIES.name,
    [DOCUMENT_STATUSES.WITHDRAWN]: DOCUMENT_WIZARD_ROUTES.PROPERTIES.name
}

const UPLOAD_NEW_VERSION_STATUSES = {
    SET_NEW: 'set_new',
    RESET: 'reset'
}

const DOCUMENT_WIZARD_INTRO_VIDEO_URL =
    'https://player.vimeo.com/video/508420839'

const DOCUMENT_WIZARD_WTLM_TYPES = {
    WANT_TO_LEARN_MORE: 'want_to_learn_more',
    MORE_INFO: 'more_info'
}

export {
    DOCUMENT_STATUSES,
    DOCUMENT_STATUS_REDIRECT_MAP,
    DOCUMENT_TYPES,
    INPUT_HIGHLIGHT_CLASSES,
    PARAGRAPH_HOVER_CLASSES,
    HOVERED_COMMENT_CLASSES,
    COMMENT_HIGHLIGHT_CLASSES,
    BASE_HIGHLIGHT_CLASS,
    BASE_CLASSES,
    DISCUSSIONS_FILTER_VALUES,
    DOCUMENT_WIZARD_ROUTES,
    IN_PROGRESS_DOCUMENT_STATUSES,
    UPLOAD_NEW_VERSION_STATUSES,
    DOCUMENT_WIZARD_INTRO_VIDEO_URL,
    OUTPUT_TYPES,
    DOCUMENT_WIZARD_WTLM_TYPES,
    DOCUMENT_FILE_PREVIEW_ROUTES,
    DOCUMENT_WIZARD_MODES,
    CUSTOM_PARAGRAPH_HIGHLIGHT_CLASSES,
    CUSTOM_PARAGRAPH_ACTIONS,
    CUSTOM_PARAGRAPH_CHAR_LIMIT
}
