<template>
    <div
        class="cf-c-pill-tabs"
        :class="{ 'cf-c-pill-tabs--full-height': fullHeight }"
    >
        <div class="cf-c-pill-tabs__items cf-u-m-default">
            <slot name="tabs"></slot>
        </div>
        <div class="cf-c-pill-tabs__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fullHeight: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>
