import { isEmpty } from 'lodash-es'
import { managementReviewService } from '@/services/api/management-review'

function getInitialState () {
    return {
        sessionData: {}
    }
}

const state = getInitialState()

const actions = {
    async getManagementReviewSession ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const sessionData = await managementReviewService.getSessionData(
                companyId,
                regulationId
            )

            commit('SET_SESSION_DATA', sessionData)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async finishReviewSession ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            await managementReviewService.finishReviewSession(
                companyId,
                regulationId
            )
            await dispatch('getManagementReviewSession')
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async startManagementReviewSession ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const sessionData = await managementReviewService.startSession(
                companyId,
                regulationId
            )

            commit('SET_SESSION_DATA', sessionData)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}

const mutations = {
    SET_SESSION_DATA (state, session) {
        state.sessionData = session
    }
}

const getters = {
    session: state => (state.sessionData ? state.sessionData.session : {}),
    canCreateSession: state => Boolean(state.sessionData.canCreateSession),
    sessionItems: state => {
        if (isEmpty(state.sessionData.session)) {
            return []
        }

        return state.sessionData.session.session_items.map(managementReview => {
            return { ...managementReview }
        })
    },
    hasReviewedSession: state => Boolean(state.sessionData.hasReviewedSession),
    sessionExists: state => !isEmpty(state.sessionData.session)
}

export const managementReviewSession = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
