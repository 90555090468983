import { each, keys } from 'lodash-es'

function getInitialState () {
    return {
        isVisible: false,
        title: '',
        content: '',
        confirmActionLabel: undefined,
        confirmActionVariation: 'alpha',
        confirmHandler: () => {}
    }
}

const state = getInitialState()

const actions = {
    openConfirmModal ({ commit }, data) {
        commit('OPEN_MODAL')

        commit('SET_MODAL_DATA', data)
    },

    closeConfirmModal ({ commit }) {
        commit('CLOSE_MODAL')

        commit('RESET_MODAL_DATA')
    }
}

const mutations = {
    OPEN_MODAL (state) {
        state.isVisible = true
    },

    CLOSE_MODAL (state) {
        state.isVisible = false
    },

    SET_MODAL_DATA (
        state,
        {
            title,
            content,
            confirmActionLabel,
            confirmActionVariation,
            confirmHandler
        }
    ) {
        state.title = title

        state.content = content

        confirmActionLabel && (state.confirmActionLabel = confirmActionLabel)

        confirmActionVariation &&
            (state.confirmActionVariation = confirmActionVariation)

        confirmHandler && (state.confirmHandler = confirmHandler)
    },

    RESET_MODAL_DATA (state) {
        const inititalState = getInitialState()

        each(keys(state), key => {
            state[key] = inititalState[key]
        })
    }
}

const getters = {
    isConfirmModalVisible: state => state.isVisible,
    confirmModal: state => state
}

export const confirmModal = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
