import axios from 'axios'
import { REGISTER_OF_INTERNAL_AUDITS } from '@/constants'
import { objectKeyIntersection } from '@/services/utils/functions'
import { fileDownload } from '../utils/functions'

const {
    INTERNAL_AUDIT_PARAMS,
    CHECKLIST_ADAPTATION_PARAMS,
    DOCUMENTATION_REVIEW_PARAMS,
    PERFORMING_PARAMS
} = REGISTER_OF_INTERNAL_AUDITS

const ENDPOINTS = {
    AUDITS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/audits`,
    IS_ANY_AUDIT_IN_PROGRESS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/audits/is-any-in-progress`,
    AUDIT: (companyId, regulationId, auditId) =>
        `companies/${companyId}/regulations/${regulationId}/audits/${auditId}`,
    CHECKLISTS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/checklists`,
    CHECKLIST: (companyId, regulationId, auditId) =>
        `companies/${companyId}/regulations/${regulationId}/audits/${auditId}/checklists`,
    CHECKLIST_UPDATE: (companyId, regulationId, auditId, checklistId) =>
        `companies/${companyId}/regulations/${regulationId}/audits/${auditId}/checklists/${checklistId}/update`,
    SECTIONS: (companyId, regulationId, auditId, checklistId) =>
        `companies/${companyId}/regulations/${regulationId}/audits/${auditId}/checklists/${checklistId}/sections`,
    CHECKLIST_ITEM: (
        companyId,
        regulationId,
        auditId,
        checklistId,
        sectionId
    ) =>
        `companies/${companyId}/regulations/${regulationId}/audits/${auditId}/checklists/${checklistId}/sections/${sectionId}/items`,
    DOCUMENTATION: (companyId, regulationId, auditId, itemId) =>
        `companies/${companyId}/regulations/${regulationId}/audits/${auditId}/items/${itemId}`,
    CLAUSES: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/clauses`,
    PRINT_CHECKLIST: (companyId, regulationId, auditId, checklistId) =>
        `companies/${companyId}/regulations/${regulationId}/audits/${auditId}/checklists/${checklistId}/print`,
    SAVE_CHECKLIST_DOCUMENT: (companyId, regulationId, auditId, checklistId) =>
        `companies/${companyId}/regulations/${regulationId}/audits/${auditId}/checklists/${checklistId}/save-document`,
    PRINT_AUDIT_REPORT: (companyId, regulationId, auditId) =>
        `companies/${companyId}/regulations/${regulationId}/audits/${auditId}/print`,
    GENERATE_AUDIT_REPORT: (companyId, regulationId, auditId) =>
        `companies/${companyId}/regulations/${regulationId}/audits/${auditId}/generate-report`,
    CANCEL_AUDIT: (companyId, regulationId, auditId) =>
        `companies/${companyId}/regulations/${regulationId}/audits/${auditId}/cancel`,
    DELETE_AUDIT: (companyId, regulationId, auditId) =>
        `companies/${companyId}/regulations/${regulationId}/audits/${auditId}`
}

export default class InternalAuditService {
    /**
     * Get audits for a regulation
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     *
     * @returns Promise
     */
    async getAudits (companyId, regulationId) {
        try {
            const { data } = await axios.get(
                ENDPOINTS.AUDITS(companyId, regulationId)
            )
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Check if is any audit in progress
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     *
     * @returns Promise
     */
    async isAnyInProgress (companyId, regulationId) {
        try {
            const { data } = await axios.get(
                ENDPOINTS.IS_ANY_AUDIT_IN_PROGRESS(companyId, regulationId)
            )
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get audit for a regulation
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} auditId
     *
     * @returns Promise
     */
    async getAudit (companyId, regulationId, auditId) {
        try {
            const { data } = await axios.get(
                ENDPOINTS.AUDIT(companyId, regulationId, auditId)
            )
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Add audit attachments
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} incidentId
     * @param {Array} files
     *
     * @returns Promise
     */
    async addAttachments (
        companyId,
        regulationId,
        incidentId,
        files = [],
        filesIds = []
    ) {
        try {
            const formData = new FormData()
            const route = ENDPOINTS.AUDIT(companyId, regulationId, incidentId)

            files.forEach(file => {
                formData.append('files[]', file)
            })

            filesIds.forEach(id => {
                formData.append('files_ids[]', id)
            })

            const { data } = await axios.post(`${route}/attachments`, formData)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Create an audit
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Object} audit
     *
     * @returns Promise
     */
    async addAudit (companyId, regulationId, audit) {
        try {
            const { data } = await axios.post(
                ENDPOINTS.AUDITS(companyId, regulationId),
                this.prepareFormData(
                    objectKeyIntersection(audit, INTERNAL_AUDIT_PARAMS)
                )
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update an audit
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} auditId
     * @param {Object} audit
     *
     * @returns Promise
     */
    async updateAudit (companyId, regulationId, auditId, audit) {
        try {
            const { data } = await axios.put(
                ENDPOINTS.AUDIT(companyId, regulationId, auditId),
                this.prepareFormData(
                    objectKeyIntersection(audit, INTERNAL_AUDIT_PARAMS)
                )
            )

            return data
        } catch (error) {
            throw error
        }
    }

    prepareFormData (payload) {
        const { ...data } = payload

        if (data.state && data.state instanceof Object) {
            data.state = data.state.name
        }

        return { ...data }
    }

    /**
     * Get checklists for a regulation
     *
     * @param {String|Number} regulationId
     * @param {String|Number} companyId
     *
     * @returns Promise
     */
    async getChecklists (regulationId, companyId) {
        try {
            const { data } = await axios.get(
                ENDPOINTS.CHECKLISTS(companyId, regulationId)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Add a new checklist item
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Object} item
     *
     * @returns Promise
     */
    async addChecklistItem (
        companyId,
        regulationId,
        auditId,
        checklistId,
        sectionId,
        item
    ) {
        try {
            const { data } = await axios.post(
                ENDPOINTS.CHECKLIST_ITEM(
                    companyId,
                    regulationId,
                    auditId,
                    checklistId,
                    sectionId
                ),
                this.prepareChecklistItemData(item)
            )

            return data
        } catch (error) {
            throw error
        }
    }
    /**
     * Add a new checklist item
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Object} item
     *
     * @returns Promise
     */
    async updateChecklist (
        companyId,
        regulationId,
        auditId,
        checklistId,
        checklistData
    ) {
        try {
            const { data } = await axios.post(
                ENDPOINTS.CHECKLIST_UPDATE(
                    companyId,
                    regulationId,
                    auditId,
                    checklistId
                ),
                { ...checklistData }
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update a checklist item
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} itemId
     * @param {Object} item
     *
     * @returns Promise
     */
    async updateChecklistItem (
        companyId,
        regulationId,
        auditId,
        checklistId,
        sectionId,
        itemId,
        item
    ) {
        try {
            const { data } = await axios.put(
                `${ENDPOINTS.CHECKLIST_ITEM(
                    companyId,
                    regulationId,
                    auditId,
                    checklistId,
                    sectionId
                )}/${itemId}`,
                this.prepareChecklistItemData(item)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Save a checklist
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} auditId
     * @param {String|Number} checklistId
     *
     * @returns Promise
     */
    async overwriteChecklistTemplate (
        companyId,
        regulationId,
        auditId,
        checklistId
    ) {
        try {
            const route = ENDPOINTS.CHECKLIST(companyId, regulationId, auditId)
            const { data } = await axios.put(`${route}/${checklistId}`)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Save a checklist
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} auditId
     * @param {Object} payload
     *
     * @returns Promise
     */
    async saveChecklist (companyId, regulationId, auditId, payload) {
        try {
            const route = ENDPOINTS.CHECKLIST(companyId, regulationId, auditId)
            const { data } = await axios.post(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Delete a checklist
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} id
     *
     * @returns Promise
     */
    async deleteChecklist (companyId, regulationId, id) {
        try {
            const route = ENDPOINTS.CHECKLISTS(companyId, regulationId)

            await axios.delete(`${route}/${id}`)
        } catch (error) {
            throw error
        }
    }

    /**
     * Delete a checklist item
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} itemId
     *
     * @returns Promise
     */
    async deleteChecklistItem (
        companyId,
        regulationId,
        auditId,
        checklistId,
        sectionId,
        itemId
    ) {
        try {
            const { data } = await axios.delete(
                `${ENDPOINTS.CHECKLIST_ITEM(
                    companyId,
                    regulationId,
                    auditId,
                    checklistId,
                    sectionId
                )}/${itemId}`
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Create section
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} auditId
     * @param {Number} checklistId
     * @param {Object} section
     *
     * @returns Promise
     */
    async addSection (companyId, regulationId, auditId, checklistId, section) {
        try {
            const { data } = await axios.post(
                ENDPOINTS.SECTIONS(
                    companyId,
                    regulationId,
                    auditId,
                    checklistId
                ),
                section
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Delete section
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} auditId
     * @param {Number} checklistId
     * @param {Object} sectionId
     *
     * @returns Promise
     */
    async deleteSection (
        companyId,
        regulationId,
        auditId,
        checklistId,
        sectionId
    ) {
        try {
            const { data } = await axios.delete(
                `${ENDPOINTS.SECTIONS(
                    companyId,
                    regulationId,
                    auditId,
                    checklistId
                )}/${sectionId}`
            )

            return data
        } catch (error) {
            throw error
        }
    }

    prepareChecklistItemData (payload, params = CHECKLIST_ADAPTATION_PARAMS) {
        const data = objectKeyIntersection(payload, params)

        return { ...data }
    }

    /**
     * Update a checklist item
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} auditId
     * @param {Number} checklistId
     * @param {Number} sectionId
     * @param {Object} item
     *
     * @returns Promise
     */
    async updateAuditPerforming (
        companyId,
        regulationId,
        auditId,
        checklistId,
        sectionId,
        item
    ) {
        try {
            const route = `${ENDPOINTS.CHECKLIST_ITEM(
                companyId,
                regulationId,
                auditId,
                checklistId,
                sectionId
            )}/${item.id}`
            const { data } = await axios.put(
                route,
                this.prepareChecklistItemData(item, PERFORMING_PARAMS)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get clauses for a regulation
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getClauses (companyId, regulationId) {
        try {
            const route = ENDPOINTS.CLAUSES(companyId, regulationId)

            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update documentation review for an audit
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} auditId
     * @param {Object} payload
     *
     * @returns Promise
     */
    async updateDocumentationReview (
        companyId,
        regulationId,
        auditId,
        payload
    ) {
        try {
            const { data } = await axios.patch(
                ENDPOINTS.DOCUMENTATION(
                    companyId,
                    regulationId,
                    auditId,
                    payload.id
                ),
                this.prepareReviewData(payload)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    async printChecklist (companyId, regulationId, auditId, checklistId) {
        try {
            const route = ENDPOINTS.PRINT_CHECKLIST(
                companyId,
                regulationId,
                auditId,
                checklistId
            )

            await fileDownload(route)
        } catch (error) {
            throw error
        }
    }

    async saveChecklistDocument (
        companyId,
        regulationId,
        auditId,
        checklistId
    ) {
        try {
            const { data } = axios.post(
                ENDPOINTS.SAVE_CHECKLIST_DOCUMENT(
                    companyId,
                    regulationId,
                    auditId,
                    checklistId
                )
            )

            return data
        } catch (error) {
            throw error
        }
    }

    async printAuditReport (companyId, regulationId, auditId) {
        try {
            const route = ENDPOINTS.PRINT_AUDIT_REPORT(
                companyId,
                regulationId,
                auditId
            )

            await fileDownload(route)
        } catch (error) {
            throw error
        }
    }

    async generateAuditReport (companyId, regulationId, auditId) {
        try {
            const { data } = await axios.get(
                ENDPOINTS.GENERATE_AUDIT_REPORT(
                    companyId,
                    regulationId,
                    auditId
                )
            )

            return data
        } catch (error) {
            throw error
        }
    }

    prepareReviewData (payload) {
        return objectKeyIntersection(payload, DOCUMENTATION_REVIEW_PARAMS)
    }

    async cancelAudit (companyId, regulationId, auditId) {
        try {
            const { data } = await axios.put(
                ENDPOINTS.CANCEL_AUDIT(companyId, regulationId, auditId)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Delete audit
     *
     * @param {Number|String} companyId
     * @param {Number|String} regulationId
     * @param {Number|String} auditId
     *
     * @returns Promise
     */
    async deleteAudit (companyId, regulationId, auditId) {
        try {
            const route = ENDPOINTS.DELETE_AUDIT(
                companyId,
                regulationId,
                auditId
            )
            const { data } = await axios.delete(route)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const internalAuditService = new InternalAuditService()
