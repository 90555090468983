<template>
    <div v-if="shouldShowNotification" class="notification-wrapper">
        <app-icon
            v-if="icon"
            class="notification-icon"
            :icon="'notification-' + icon + '-icon'"
        />
        <div class="notification-content" v-html="content"></div>
        <app-icon
            v-if="canClose"
            class="notification-dismiss"
            icon="notification-close-icon"
            @click="dismissNotification()"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
    getLocalStorageItem,
    setLocalStorageItem
} from '@/services/localStorage'

const NOTIFICATION_LOCAL_STORAGE_KEY = 'notification'

export default {
    props: {
        id: {
            type: [Number, String],
            required: false,
            default: 1
        },
        icon: {
            type: String,
            required: false,
            default: ''
        },
        content: {
            type: String,
            required: false,
            default: ''
        },
        canClose: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data: () => ({
        show: true
    }),

    computed: {
        ...mapGetters('account', ['user', 'companyMember']),

        shouldShowNotification () {
            return this.show && !this.isNotificationDismissed(this.id)
        }
    },

    methods: {
        /** Dissmisses the actual notification */
        dismissNotification () {
            this.setStorageItem(this.id, { dismiss: true })

            this.show = false
        },

        /** Checks whether is notification dismissed */
        isNotificationDismissed (notificationId) {
            let options = this.getStorageItem(notificationId)

            return options.dismiss !== undefined && options.dismiss
        },

        /** Key of a notification entry in local storage */
        storageItemKey (notificationId) {
            return (
                NOTIFICATION_LOCAL_STORAGE_KEY +
                '_' +
                this.user.id +
                '_' +
                notificationId
            )
        },

        /** Saves notification settings to local storage (or cookie) */
        setStorageItem (notificationId, newOptions = {}) {
            if (notificationId && newOptions) {
                let options = this.getStorageItem(notificationId)

                Object.keys(newOptions).forEach(option => {
                    options[option] = newOptions[option]
                })

                setLocalStorageItem(
                    this.storageItemKey(notificationId),
                    JSON.stringify(options)
                )
            }
        },

        /** Reads notification settings value from local storage */
        getStorageItem (notificationId) {
            return (
                JSON.parse(
                    getLocalStorageItem(this.storageItemKey(notificationId))
                ) ?? {}
            )
        }
    }
}
</script>
