<template>
    <div :class="wrapperClasses">
        <div class="cf-c-underlined-collapsible__header">
            <h5 class="cf-c-underlined-collapsible__header-text">
                <slot name="target"></slot>
            </h5>
            <button
                ref="button"
                class="cf-c-underlined-collapsible__header-action"
                @mouseup="handleMouseUp"
                @keyup.enter="toggleCollapse"
                @keyup.space="toggleCollapse"
            >
                <span :class="{ 'cf-u-uppercased': isUppercased }">
                    {{ visible ? $t('COMMON.COLLAPSE') : $t('COMMON.EXPAND') }}
                </span>
            </button>
        </div>
        <div v-show="visible" class="cf-c-underlined-collapsible__ctn">
            <slot name="content"></slot>
        </div>
        <div
            v-if="$slots.emptyState && visible"
            class="cf-c-underlined-collapsible__empty-state"
        >
            <slot name="emptyState"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isVisible: {
            type: Boolean,
            required: false,
            default: true
        },

        isUppercased: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data () {
        return {
            visible: this.isVisible
        }
    },

    computed: {
        wrapperClasses () {
            let classes = 'cf-c-underlined-collapsible'

            if (this.visible) {
                classes += ' cf-is-open'
            }

            return classes
        }
    },

    watch: {
        isVisible (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.visible = this.isVisible
            }
        }
    },

    methods: {
        toggleCollapse () {
            this.visible = !this.visible
        },

        handleMouseUp () {
            this.toggleCollapse()
            this.$refs.button.blur()
        }
    }
}
</script>
