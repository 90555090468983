<template>
    <div class="experta" @click="focusQuestionInput">
        <div class="experta__heading">
            <h4>{{ $t('EXPERTA.TITLE') }}</h4>
            <span>{{ $t('EXPERTA.SUBTITLE') }}</span>
        </div>

        <div class="experta__info">
            {{ getInfoPanel() }}
            <button
                v-if="helpTopicsContext"
                class="btn-link btn-link--alpha"
                type="button"
                @click="suggestHelpTopics()"
            >
                <app-icon icon="icon-link" size="sm" />
                {{ $t('EXPERTA.HELP_TOPICS') }}
            </button>
        </div>

        <div class="experta__chat">
            <div class="experta__chat-content" ref="expertaChatContent">
                <div class="experta__chat-messages">
                    <experta-chat-message
                        v-for="(message, i) in messages"
                        @ask-experta="askExperta"
                        :key="i"
                        :message="message"
                    />
                </div>
                <div v-if="isThinking" class="experta__chat-spinner">
                    {{ $t('EXPERTA.THINKING') }}
                </div>
            </div>

            <div class="experta__chat-form">
                <textarea
                    v-model="question"
                    @keypress.enter="askOnEnterPress"
                    ref="questionInput"
                    class="form-control"
                    autocomplete="off"
                    rows="3"
                    :placeholder="$t('EXPERTA.TYPE_MESSAGE')"
                ></textarea>
                <button
                    type="button"
                    class="btn btn--alpha"
                    @click="askExperta(question)"
                    :disabled="isThinking || !this.question"
                >
                    {{ $t('COMMON.SEND') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ExpertaChatMessage from './ExpertaChatMessage'
import { EXPERTA_ROUTE_INFO_MAP } from '@/constants'
import { DOCUMENT_WIZARD_ROUTES } from '../../constants/documents'

export default {
    components: {
        ExpertaChatMessage
    },

    data () {
        return {
            question: '',
            helpTopicsContext: ''
        }
    },

    created () {
        this.clearHistory()

        this.newBotMessage(this.$t('EXPERTA.WELCOME_MESSAGE'))
    },

    computed: {
        ...mapGetters('experta', ['messages', 'isThinking']),
        ...mapGetters('account', ['companyMember']),
        ...mapGetters('document', ['document'])
    },

    methods: {
        ...mapActions('experta', [
            'clearHistory',
            'addUserMessage',
            'addBotMessage',
            'askQuestion',
            'getRelatedQuestions'
        ]),

        focusQuestionInput () {
            this.$refs.questionInput.focus()
        },

        async askOnEnterPress (e) {
            e.preventDefault()

            await this.askExperta(this.question)
        },

        async askExperta (question) {
            if (this.isThinking) {
                return
            }

            if (question) {
                // Clear input value
                this.question = ''

                // Add question to chat
                this.newUserMessage(question)

                // Send question to API
                const response = await this.askQuestion({
                    question: question,
                    email: this.companyMember.email
                })

                // Add Experta's awnser to chat
                if (response) {
                    this.newBotMessage(response.answer)
                }
            }
        },

        async suggestHelpTopics () {
            await this.getRelatedQuestions(this.helpTopicsContext)

            this.scrollToChatBottom()
        },

        // Stores new user message and scrolls to the bottom of the chat
        newUserMessage (content) {
            this.addUserMessage(content)

            this.scrollToChatBottom()
        },

        // Stoew new bot message and scrolls to the bottom of the chat
        newBotMessage (content) {
            this.addBotMessage(content)

            this.scrollToChatBottom()
        },

        scrollToChatBottom () {
            this.$nextTick(() => {
                const chatContainer = this.$refs.expertaChatContent

                chatContainer.scrollTo(0, chatContainer.scrollHeight)
            })
        },

        getInfoPanel () {
            const routeName = this.$route.name

            // Document Wizard exception
            if (
                this.isParentRouteEqualTo(
                    DOCUMENT_WIZARD_ROUTES.DOCUMENT_WIZARD.name
                ) &&
                this.document
            ) {
                return this.document.explanation
            }

            //
            if (routeName in EXPERTA_ROUTE_INFO_MAP) {
                return this.$t(EXPERTA_ROUTE_INFO_MAP[routeName])
            }

            // Go further
            const matchedRoute = this.isParentRouteIn(EXPERTA_ROUTE_INFO_MAP)

            if (matchedRoute) {
                return this.$t(EXPERTA_ROUTE_INFO_MAP[matchedRoute.name])
            }

            return this.$t('EXPERTA.INFO_PANEL.FALLBACK')
        },

        isParentRouteIn (mappedObject) {
            return this.$route.matched?.find(
                route => route.name in mappedObject
            )
        },

        isParentRouteEqualTo (routeName) {
            return this.$route.matched?.find(route => route.name === routeName)
        }
    },

    watch: {
        isThinking (value) {
            if (value) {
                this.scrollToChatBottom()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@style/sfc.scss';

.experta {
    position: fixed;
    width: rem($cf-side-panel-width);
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $cf-color-white;
    display: flex;
    flex-direction: column;

    &__heading {
        padding: $cf-gutter;
        background-color: $color-primary-beta;
        color: $cf-color-white;

        h4 {
            margin: 0;
            line-height: calc(26 / 20);
        }

        span {
            display: block;
            line-height: calc(22 / 16);
        }
    }

    &__info {
        padding: $cf-gutter;
        border-left: 1px solid $color-gray-alpha;
        font-size: rem(12);
        color: $color-primary-beta;

        .btn-link {
            margin-top: $cf-gutter;
        }
    }

    &__info-help {
        display: block;
    }

    &__chat {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border-left: 1px solid $color-gray-alpha;

        &-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            border-top: 1px solid $color-gray-alpha;
            border-bottom: 1px solid $color-gray-alpha;
            @include thin-scrollbar;
        }

        &-spinner {
            padding: 0 $cf-gutter;
            line-height: calc(24 / 12);
            font-size: rem(12);
            color: $color-primary-beta;
            font-weight: bold;
        }

        &-messages {
            flex: 1;
            padding: $cf-gutter;
        }

        &-form {
            display: flex;
            padding: $cf-gutter;

            textarea {
                margin: 0 $cf-gutter-sm 0 0;
                padding: 0;
                border: 0;
                font-size: rem(12);
                line-height: calc(18 / 12);
                color: $color-primary-beta;
                resize: none;
                box-shadow: none;

                &:focus {
                    outline: none;
                }
            }

            button {
                align-self: flex-start;
            }
        }
    }
}
</style>
