export default {
    bind (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // eslint-disable-next-line
            if (!(el == event.target || el.contains(event.target))) {
                // eslint-disable-
                vnode.context[binding.expression](event)
            }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
    },

    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    }
}
