import { ROUTES, MY_PERMISSIONS } from '@src/constants'
import { myPermissionsGuard } from './guards'

const { RESPONSIBILITY_MATRIX } = ROUTES

export default {
    name: RESPONSIBILITY_MATRIX.INDEX.name,
    path: 'responsibility-matrix',
    component: () =>
        import('@/containers/ResponsibilityMatrix/ResponsibilityMatrix'),
    meta: {
        pageTitle: 'TASKS.RESPONSIBILITY_MATRIX.TITLE',
        requiresAuth: true,
        guards: [myPermissionsGuard],
        permissions: [MY_PERMISSIONS.RESPONSIBILITY_MATRIX_ACCESS]
    }
}
