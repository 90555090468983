<template>
    <div :class="getWrapperClasses">
        <div class="cf-c-select-native__ctn">
            <select
                :tabindex="tabindex"
                :name="name"
                class="cf-c-select-native__field"
                :required="required"
                :disabled="disabled"
                @input="$emit('input', $event.target.value)"
            >
                <option
                    v-if="!value || !required"
                    value=""
                    :disabled="required || placeholderDisabled"
                    selected
                    hidden
                >
                    {{ $t(placeholder) }}
                </option>
                <option
                    v-for="option in options"
                    :key="option[trackBy]"
                    :value="option[trackBy]"
                    :selected="isSelected(option)"
                    :disabled="option.disabled"
                >
                    {{ getOptionLabel(option[label]) }}
                </option>
            </select>
            <span class="cf-c-select-native__right-icon"></span>
        </div>
        <div v-if="errorMessage" class="cf-c-select-native__error">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: [Array, Object],
            required: true
        },

        value: {
            type: [String, Number],
            required: true
        },

        label: {
            type: String,
            required: false,
            default: 'label'
        },

        required: {
            type: Boolean,
            required: false,
            default: false
        },

        ignoreLocalization: {
            type: Boolean,
            required: false,
            default: false
        },

        // This has to exists due to Safari not supporting `hidden` options
        placeholderDisabled: {
            type: Boolean,
            required: false,
            default: false
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        errorMessage: {
            type: String,
            required: false,
            default: ''
        },

        placeholder: {
            type: String,
            required: false,
            default: ''
        },

        hasAbsolutePositionError: {
            type: Boolean,
            required: false,
            default: false
        },

        sm: {
            type: Boolean,
            required: false,
            default: false
        },

        autoWidth: {
            type: Boolean,
            required: false,
            default: false
        },

        fullWidth: {
            type: Boolean,
            required: false,
            default: false
        },

        maxWidthDefault: {
            type: Boolean,
            required: false,
            default: false
        },

        noBorders: {
            type: Boolean,
            required: false,
            default: false
        },

        fontSize: {
            type: String,
            required: false,
            default: ''
        },

        name: {
            type: String,
            required: false,
            default: ''
        },

        tabindex: {
            type: Number,
            required: false,
            default: 0
        },

        primary: {
            type: Boolean,
            required: false,
            default: false
        },

        textBold: {
            type: Boolean,
            required: false,
            default: false
        },

        md: {
            type: Boolean,
            required: false,
            default: false
        },

        trackBy: {
            type: String,
            required: false,
            default: 'value'
        }
    },

    computed: {
        getWrapperClasses () {
            let classes = 'cf-c-select-native'

            if (!this.value) {
                classes += ' cf-c-select-native--has-placeholder'
            } else {
                classes += ' cf-c-select-native--has-text'
            }

            if (this.errorMessage) {
                classes += ' cf-c-select-native--has-error'
            }

            if (this.hasAbsolutePositionError) {
                classes += ' cf-c-select-native--has-absolute-errors'
            }

            if (this.sm) {
                classes += ' cf-c-select-native--sm'
            }

            if (this.autoWidth) {
                classes += ' cf-c-select-native--auto-width'
            }

            if (this.fullWidth) {
                classes += ' cf-c-select-native--full-width'
            }

            if (this.maxWidthDefault) {
                classes += ' cf-c-select-native--max-width-default'
            }

            if (this.noBorders) {
                classes += ' cf-c-select-native--no-borders'
            }

            if (this.fontSize === 'sm') {
                classes += ' cf-c-select-native--fsz-sm'
            }

            if (this.primary) {
                classes += ' cf-c-select-native--primary'
            }

            if (this.textBold) {
                classes += ' cf-c-select-native--text-bold'
            }

            if (this.md) {
                classes += ' cf-c-select-native--md'
            }

            return classes
        }
    },

    methods: {
        isSelected (option) {
            return option[this.trackBy] === this.value
        },

        getOptionLabel (label) {
            return !this.ignoreLocalization ? this.$t(label) : label
        }
    }
}
</script>
