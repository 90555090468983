import { first } from 'lodash-es'
import { TASKS } from '@/constants'

export function getAssignee (task) {
    return first(task.assignees)
}

export function isTaskLate (task) {
    return task.status === TASKS.STATUSES.LATE
}
