import { managementReviewService } from '@/services/api/management-review'

function getInitialState () {
    return {
        activities: [],
        metaActivities: []
    }
}

const state = getInitialState()

const actions = {
    async getActivities ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const activities = await managementReviewService.getActivities(
                companyId,
                regulationId
            )

            commit('SET_ACTIVITIES', activities)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getManagementReviews ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const activities = await managementReviewService.getManagementReviews(
                companyId,
                regulationId
            )

            commit('SET_ACTIVITIES', activities)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getMetaActivities ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const activities = await managementReviewService.getMetaActivities(
                companyId,
                regulationId
            )

            commit('SET_META_ACTIVITIES', activities)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateSettings ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            await managementReviewService.updateSettings(
                companyId,
                regulationId,
                {
                    management_reviews: data
                }
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}

const mutations = {
    SET_ACTIVITIES (state, activities) {
        state.activities = activities.map(activity => {
            const {
                company_regulation_meta_management_review: metaActivity
            } = activity

            return {
                ...metaActivity,
                id: activity.id,
                status: activity.status,
                reviewers: activity.reviewers.filter(reviewer => reviewer.user),
                due_date: activity.due_date,
                data_source: activity.data_source
            }
        })
    },

    SET_META_ACTIVITIES (state, activities) {
        state.metaActivities = activities.map(mapActivityWithReviewers)
    }
}

const getters = {
    activities: state => state.activities,
    metaActivities: state => state.metaActivities,
    activitiesAssignedToUser: state => state.activitiesAssignedToUser
}

function mapActivityWithReviewers (activity) {
    return {
        ...activity,
        reviewers: activity.reviewers
            .filter(reviewer => reviewer.user)
            .map(reviewer => ({
                ...reviewer,
                company_member_id: reviewer.id
            }))
    }
}

export const managementReview = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
