import { MY_PERMISSIONS, REGISTERS_PERMISSIONS } from '@src/constants'
import { myPermissionsGuard } from '@src/router/guards'
import { REGISTER_TYPE, ROUTES_TRAININGS } from './constants'

export default {
    name: ROUTES_TRAININGS.BASE.name,
    path: 'trainings',
    component: () => import('./TrainingsModule'),
    meta: {
        pageTitle: 'TRAININGS.PAGE_TITLE',
        requiresAuth: true,
        register: REGISTER_TYPE,
        guards: [myPermissionsGuard],
        permissions: [
            MY_PERMISSIONS.TRAINING_MODULE_ACCESS,
            REGISTERS_PERMISSIONS.TRAININGS
        ]
    },
    children: [
        // This redirection is created in order to support opening links in already created Security Objectives approval tasks
        // It can just be removed after 06.02.2024.
        {
            name: 'trainings-help-redirection',
            path: 'help',
            redirect: to => ({
                name: ROUTES_TRAININGS.BASE.name
            })
        }
    ]
}
