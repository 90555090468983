<template>
    <div class="cf-c-step-bar">
        <div
            v-for="(step, index) in steps"
            :key="index"
            :class="[
                'cf-c-step-bar__item',
                { 'cf-is-active': activeStep === index },
                { 'cf-is-passed': step.isPassed },
                { 'cf-c-step-bar__item--inactive': activeStep === -1 }
            ]"
        >
            <div class="cf-c-step-bar__progress">
                <div class="cf-c-step-bar__line"></div>
                <div class="cf-c-step-bar__dot"></div>
            </div>
            <div
                v-show="shouldShowLabel(index)"
                :class="[
                    'cf-c-step-bar__label',
                    'cf-u-uppercased',
                    { 'cf-c-step-bar__label--active': activeStep === index },
                    { 'cf-c-step-bar__label--relative': step.icon }
                ]"
            >
                <p class="cf-c-step-bar__label-text">
                    {{ $t(step.label) }}
                </p>
                <app-icon
                    v-if="step.icon"
                    :id="step.label"
                    :icon="showIcon(step)"
                    size="md"
                    class="cf-c-step-bar__icon"
                />
                <div v-if="step.subLabel" class="cf-c-step-bar__sub-label">
                    {{ step.subLabel }}
                </div>
                <b-tooltip v-if="shouldShowTooltip(step)" :target="step.label">
                    {{ $t(step.icon.tooltipText) }}
                </b-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        steps: {
            type: Array,
            required: false,
            default: () => []
        },

        activeStep: {
            type: Number,
            required: false,
            default: -1
        },

        onlyActiveLabel: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        shouldShowLabel (index) {
            return !this.onlyActiveLabel || this.activeStep === index
        },
        shouldShowTooltip (step) {
            return step.icon && step.icon.tooltipText
        },
        showIcon (step) {
            if (step.icon) {
                return step.icon.name
            }
        }
    }
}
</script>
