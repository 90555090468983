import axios from 'axios'

const ENDPOINTS = {
    PROJECT_STATUS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/project-status`,
    PROJECT_STATUS_STEPS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/project-status/steps`,
    PROJECT_STATUS_RESOURCES: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/project-status/resources`,
    COMPLIANCE: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/compliance`,
    DOCUMENTS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/compliance/documents`,
    PERFORMANCE: (companyId, regulationId, intervalType) =>
        `companies/${companyId}/regulations/${regulationId}/performance?intervalType=${intervalType}`,
    READINESS_FOR_CERTIFICATION: companyId =>
        `companies/${companyId}/readiness-for-certification`
}

export default class ReportingService {
    /**
     * Get data for compliance dashboard
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async fetchCompliance (companyId, regulationId) {
        try {
            const route = ENDPOINTS.COMPLIANCE(companyId, regulationId)

            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get data for project status dashboard
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async fetchProjectStatus (companyId, regulationId) {
        try {
            const route = ENDPOINTS.PROJECT_STATUS(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    async getComplianceSteps (companyId, regulationId) {
        try {
            const route = ENDPOINTS.PROJECT_STATUS_STEPS(
                companyId,
                regulationId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get data for performance dashboard
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String} intervalType
     *
     * @returns Promise
     */
    async fetchPerformance ({ companyId, regulationId, intervalType }) {
        try {
            const route = ENDPOINTS.PERFORMANCE(
                companyId,
                regulationId,
                intervalType
            )

            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get approved documents and reports
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async fetchApprovedDocumentsAndReports (companyId, regulationId) {
        try {
            const route = ENDPOINTS.DOCUMENTS(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get resources for dashboards
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getResources (companyId, regulationId) {
        try {
            const route = ENDPOINTS.PROJECT_STATUS_RESOURCES(
                companyId,
                regulationId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Gets readiness for certification statistic from api
     *
     * @param {Number} companyId
     * @returns {Promise<any>}
     */
    async fetchReadinessForCertification (companyId) {
        try {
            const route = ENDPOINTS.READINESS_FOR_CERTIFICATION(companyId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const reportingService = new ReportingService()
