import { isAfter } from '@/services/utils/date'
import { getFullNameOrEmail } from '@/services/utils/functions'
import {
    REGISTER_TYPE as REGISTER_TYPE_SECURITY_OBJECTIVES,
    ROUTES_SECURITY_OBJECTIVES
} from '@src/modules/registers/security-objectives/constants'
import {
    REGISTER_TYPE as REGISTER_TYPE_TRAININGS,
    ROUTES_TRAININGS
} from '@src/modules/registers/trainings/constants'
import {
    REGISTER_TYPE as REGISTER_TYPE_REQUIREMENTS,
    ROUTES_REGISTER_OF_REQUIREMENTS
} from '@src/modules/registers/requirements/constants'

const REGISTER_WIZARD_ROUTES = {
    BASE_REGISTER_WIZARD: {
        name: 'register-wizard'
    },
    REQUIREMENTS: {
        REGISTER_WIZARD: { name: 'register-wizard.requirements' },
        INDEX: { name: 'register-wizard.requirements.index' },
        WIZARD: { name: 'register-wizard.requirements.wizard' },
        PROPERTIES: { name: 'register-wizard.requirements.properties' },
        DISCUSSIONS: { name: 'register-wizard.requirements.discussions' }
    },
    RISKS: {
        REGISTER_WIZARD: { name: 'register-wizard.risks' },
        INDEX: { name: 'register-wizard.risks.index' },
        COMPLETED_REGISTER: { name: 'register-wizard.risks.completed' },
        UNCOMPLETED_REGISTER: { name: 'register-wizard.risks.uncompleted' },
        WIZARD: { name: 'register-wizard.risks.wizard' },
        PROPERTIES: { name: 'register-wizard.risks.properties' },
        DISCUSSIONS: { name: 'register-wizard.risks.discussions' },
        COMPLETED_WIZARD: { name: 'register-wizard.risks.completed-wizard' },
        COMPLETED_PROPERTIES: {
            name: 'register-wizard.risks.completed-properties'
        },
        COMPLETED_DISCUSSIONS: {
            name: 'register-wizard.risks.completed-discussions'
        }
    },
    SOA: {
        REGISTER_WIZARD: { name: 'register-wizard.soa' },
        INDEX: { name: 'register-wizard.soa.index' },
        WIZARD: { name: 'register-wizard.soa.wizard' },
        PROPERTIES: { name: 'register-wizard.soa.properties' },
        DISCUSSIONS: { name: 'register-wizard.soa.discussions' }
    },
    RISK_TREATMENT_PLAN: {
        INDEX: { name: 'register-wizard.soa.risk-treatment-plan' },
        WIZARD: { name: 'register-wizard.soa.risk-treatment-plan.wizard' },
        PROPERTIES: {
            name: 'register-wizard.soa.risk-treatment-plan.properties'
        },
        DISCUSSIONS: {
            name: 'register-wizard.soa.risk-treatment-plan.discussions'
        }
    },
    INCIDENTS: {
        REGISTER_WIZARD: { name: 'register-wizard.incidents' },
        INDEX: { name: 'register-wizard.incidents.index' },
        WIZARD: { name: 'register-wizard.incidents.wizard' },
        PROPERTIES: { name: 'register-wizard.incidents.properties' },
        DISCUSSIONS: { name: 'register-wizard.incidents.discussions' }
    },
    PERIODIC_REVIEW: {
        INDEX: { name: 'register-wizard.incidents.periodic-review' },
        WIZARD: { name: 'register-wizard.incidents.periodic-review.wizard' },
        PROPERTIES: {
            name: 'register-wizard.incidents.periodic-review.properties'
        },
        DISCUSSIONS: {
            name: 'register-wizard.incidents.periodic-review.discussions'
        }
    },
    NONCONFORMITIES: {
        REGISTER_WIZARD: { name: 'register-wizard.nonconformities' },
        INDEX: { name: 'register-wizard.nonconformities.index' },
        WIZARD: { name: 'register-wizard.nonconformities.wizard' },
        PROPERTIES: { name: 'register-wizard.nonconformities.properties' },
        DISCUSSIONS: { name: 'register-wizard.nonconformities.discussions' },
        CORRECTIVE_ACTIONS: {
            INDEX: {
                name: 'register-wizard.nonconformities.corrective-actions'
            },
            WIZARD: {
                name:
                    'register-wizard.nonconformities.corrective-actions.wizard'
            },
            PROPERTIES: {
                name:
                    'register-wizard.nonconformities.corrective-actions.properties'
            },
            DISCUSSIONS: {
                name:
                    'register-wizard.nonconformities.corrective-actions.discussions'
            }
        }
    },
    INTERNAL_AUDITS: {
        REGISTER_WIZARD: { name: 'register-wizard.internal-audits' },
        INDEX: { name: 'register-wizard.internal-audits.index' },
        WIZARD: { name: 'register-wizard.internal-audits.wizard' },
        PROPERTIES: { name: 'register-wizard.internal-audits.properties' },
        DISCUSSIONS: { name: 'register-wizard.internal-audits.discussions' }
    },
    DOCUMENTATION_REVIEW: {
        INDEX: { name: 'register-wizard.internal-audits.documentation-review' },
        WIZARD: {
            name: 'register-wizard.internal-audits.documentation-review.wizard'
        },
        PROPERTIES: {
            name:
                'register-wizard.internal-audits.documentation-review.properties'
        },
        DISCUSSIONS: {
            name:
                'register-wizard.internal-audits.documentation-review.discussions'
        }
    },
    CHECKLIST_ADAPTATION: {
        INDEX: { name: 'register-wizard.internal-audits.checklist-adaptation' },
        WIZARD: {
            name: 'register-wizard.internal-audits.checklist-adaptation.wizard'
        },
        PROPERTIES: {
            name:
                'register-wizard.internal-audits.checklist-adaptation.properties'
        },
        DISCUSSIONS: {
            name:
                'register-wizard.internal-audits.checklist-adaptation.discussions'
        }
    },
    INTERNAL_AUDIT_PERFORMING: {
        INDEX: { name: 'register-wizard.internal-audits.performing' },
        WIZARD: {
            name: 'register-wizard.internal-audits.performing.wizard'
        },
        PROPERTIES: {
            name: 'register-wizard.internal-audits.performing.properties'
        },
        DISCUSSIONS: {
            name: 'register-wizard.internal-audits.performing.discussions'
        }
    },
    AUDIT_REPORT: {
        INDEX: { name: 'register-wizard.internal-audits.audit-report' },
        WIZARD: {
            name: 'register-wizard.internal-audits.audit-report.wizard'
        },
        PROPERTIES: {
            name: 'register-wizard.internal-audits.audit-report.properties'
        },
        DISCUSSIONS: {
            name: 'register-wizard.internal-audits.audit-report.discussions'
        }
    },
    REPORTING: {
        INDEX: { name: 'reporting-overview' },
        PROJECT_STATUS_DASHBOARD: {
            name: 'reporting-project-status-dashboard'
        },
        COMPLIANCE_DASHBOARD: { name: 'reporting-compliance-dashboard' },
        PERFORMANCE_DASHBOARD: { name: 'reporting-performance-dashboard' },
        MANAGEMENT_REVIEW: {
            INDEX: { name: 'management-review' },
            ACTIVITIES: {
                INDEX: { name: 'management-review.activities' },
                WIZARD: { name: 'management-review.activities.wizard' },
                PROPERTIES: { name: 'management-review.activities.properties' },
                DISCUSSIONS: {
                    name: 'management-review.activities.discussions'
                }
            },
            SETTING_UP: {
                INDEX: { name: 'setting-up-management-review' },
                WIZARD: { name: 'setting-up-management-review.wizard' },
                PROPERTIES: { name: 'setting-up-management-review.properties' },
                DISCUSSIONS: {
                    name: 'setting-up-management-review.discussions'
                }
            },
            IN_PROGRESS: {
                INDEX: { name: 'management-review.in-progress' },
                WIZARD: { name: 'management-review.in-progress.wizard' },
                PROPERTIES: {
                    name: 'management-review.in-progress.properties'
                },
                DISCUSSIONS: {
                    name: 'management-review.in-progress.discussions'
                }
            }
        }
    },
    AUDIT_AND_EVIDENCE: {
        INDEX: { name: 'compliance.audit-and-evidence' }
    },
    MAINTENANCE: {
        INDEX: { name: 'compliance.maintenance' }
    }
}

const REGISTER_TYPE_RISKS = 'risks'
const REGISTER_TYPE_STATEMENT_OF_APPLICABILITY = 'statement_of_applicability'
const REGISTER_TYPE_INCIDENTS = 'incidents'
const REGISTER_TYPE_NONCONFORMITIES = 'nonconformities'
const REGISTER_TYPE_INTERNAL_AUDITS = 'audits'
const REGISTER_TYPE_MANAGEMENT_REVIEW = 'management_reviews'
const REGISTER_TYPE_SETTING_UP_MANAGEMENT_REVIEW = 'setup_management_review'
const REGISTER_TYPE_REPORTING = 'reporting_module'
const REGISTER_TYPE_AUDIT_AND_EVIDENCE = 'audit_and_evidence_module'
const REGISTER_TYPE_MAINTENANCE = 'maintenance_module'

const REGISTER_TYPES = {
    REQUIREMENTS: REGISTER_TYPE_REQUIREMENTS,
    RISKS: REGISTER_TYPE_RISKS,
    SOA: REGISTER_TYPE_STATEMENT_OF_APPLICABILITY,
    INCIDENTS: REGISTER_TYPE_INCIDENTS,
    NONCONFORMITIES: REGISTER_TYPE_NONCONFORMITIES,
    INTERNAL_AUDITS: REGISTER_TYPE_INTERNAL_AUDITS,
    SECURITY_OBJECTIVES: REGISTER_TYPE_SECURITY_OBJECTIVES,
    MANAGEMENT_REVIEW: REGISTER_TYPE_MANAGEMENT_REVIEW,
    SETTING_UP_MANAGEMENT_REVIEW: REGISTER_TYPE_SETTING_UP_MANAGEMENT_REVIEW,
    TRAININGS: REGISTER_TYPE_TRAININGS
}

const REGISTER_WIZARD_TYPES = [
    REGISTER_TYPE_REQUIREMENTS,
    REGISTER_TYPE_RISKS,
    REGISTER_TYPE_STATEMENT_OF_APPLICABILITY,
    REGISTER_TYPE_INCIDENTS,
    REGISTER_TYPE_NONCONFORMITIES,
    REGISTER_TYPE_INTERNAL_AUDITS
]

const REGISTER_WIZARD_ROUTE_MAP = {
    [REGISTER_TYPE_REQUIREMENTS]: ROUTES_REGISTER_OF_REQUIREMENTS.BASE,
    [REGISTER_TYPE_RISKS]: REGISTER_WIZARD_ROUTES.RISKS.INDEX,
    [REGISTER_TYPE_STATEMENT_OF_APPLICABILITY]:
        REGISTER_WIZARD_ROUTES.SOA.WIZARD,
    [REGISTER_TYPE_INCIDENTS]: REGISTER_WIZARD_ROUTES.INCIDENTS.WIZARD,
    [REGISTER_TYPE_NONCONFORMITIES]:
        REGISTER_WIZARD_ROUTES.NONCONFORMITIES.WIZARD,
    [REGISTER_TYPE_INTERNAL_AUDITS]:
        REGISTER_WIZARD_ROUTES.INTERNAL_AUDITS.WIZARD,
    [REGISTER_TYPE_SECURITY_OBJECTIVES]: ROUTES_SECURITY_OBJECTIVES.BASE,
    [REGISTER_TYPE_MANAGEMENT_REVIEW]:
        REGISTER_WIZARD_ROUTES.REPORTING.MANAGEMENT_REVIEW.ACTIVITIES.WIZARD,
    [REGISTER_TYPE_SETTING_UP_MANAGEMENT_REVIEW]:
        REGISTER_WIZARD_ROUTES.REPORTING.MANAGEMENT_REVIEW.SETTING_UP.WIZARD,
    [REGISTER_TYPE_TRAININGS]: ROUTES_TRAININGS.BASE,
    [REGISTER_TYPE_REPORTING]: REGISTER_WIZARD_ROUTES.REPORTING.INDEX,
    [REGISTER_TYPE_AUDIT_AND_EVIDENCE]:
        REGISTER_WIZARD_ROUTES.AUDIT_AND_EVIDENCE.INDEX,
    [REGISTER_TYPE_MAINTENANCE]: REGISTER_WIZARD_ROUTES.MAINTENANCE.INDEX
}

const REGISTER_DISCUSSION_ROUTE_MAP = {
    [REGISTER_TYPE_REQUIREMENTS]: ROUTES_REGISTER_OF_REQUIREMENTS.BASE,
    [REGISTER_TYPE_RISKS]: REGISTER_WIZARD_ROUTES.RISKS.DISCUSSIONS,
    [REGISTER_TYPE_STATEMENT_OF_APPLICABILITY]:
        REGISTER_WIZARD_ROUTES.SOA.DISCUSSIONS,
    [REGISTER_TYPE_INCIDENTS]: REGISTER_WIZARD_ROUTES.INCIDENTS.DISCUSSIONS,
    [REGISTER_TYPE_NONCONFORMITIES]:
        REGISTER_WIZARD_ROUTES.NONCONFORMITIES.DISCUSSIONS,
    [REGISTER_TYPE_INTERNAL_AUDITS]:
        REGISTER_WIZARD_ROUTES.INTERNAL_AUDITS.DISCUSSIONS,
    [REGISTER_TYPE_SECURITY_OBJECTIVES]: ROUTES_SECURITY_OBJECTIVES.BASE,
    [REGISTER_TYPE_MANAGEMENT_REVIEW]:
        REGISTER_WIZARD_ROUTES.REPORTING.MANAGEMENT_REVIEW.ACTIVITIES
            .DISCUSSIONS,
    [REGISTER_TYPE_SETTING_UP_MANAGEMENT_REVIEW]:
        REGISTER_WIZARD_ROUTES.REPORTING.MANAGEMENT_REVIEW.SETTING_UP
            .DISCUSSIONS,
    [REGISTER_TYPE_TRAININGS]: ROUTES_TRAININGS.BASE
}

const REGISTER_STATUSES = {
    CHANGED: 'changed',
    IN_REVIEW: 'in_review',
    COMPLETED: 'completed',
    INITIAL: 'initial'
}

const CONTRACTUAL_TYPE = 'contractual'
const LEGAL_REGULATORY_TYPE = 'legal-regulatory'

const FILTER_VALUES = {
    CONTRACTUAL_TYPE,
    LEGAL_REGULATORY_TYPE,
    COMPLIANT: 'compliant',
    NON_COMPLIANT: 'non-compliant',
    ALL: 'all'
}

const FILTER_OPTIONS = [
    {
        value: FILTER_VALUES.CONTRACTUAL_TYPE,
        label: 'REGISTERS.REQUIREMENTS.FILTER_VALUES.CONTRACTUAL'
    },
    {
        value: FILTER_VALUES.LEGAL_REGULATORY_TYPE,
        label: 'REGISTERS.REQUIREMENTS.FILTER_VALUES.LEGAL_REGULATORY'
    },
    {
        value: FILTER_VALUES.COMPLIANT,
        label: 'REGISTERS.REQUIREMENTS.FILTER_VALUES.COMPLIANT'
    },
    {
        value: FILTER_VALUES.NON_COMPLIANT,
        label: 'REGISTERS.REQUIREMENTS.FILTER_VALUES.NON_COMPLIANT'
    },
    {
        value: FILTER_VALUES.ALL,
        label: 'REGISTERS.REQUIREMENTS.FILTER_VALUES.ALL'
    }
]

const FILTER_ACTIONS = {
    [FILTER_VALUES.CONTRACTUAL_TYPE]: item =>
        item.type === FILTER_VALUES.CONTRACTUAL_TYPE,
    [FILTER_VALUES.LEGAL_REGULATORY_TYPE]: item =>
        item.type === FILTER_VALUES.LEGAL_REGULATORY_TYPE,
    [FILTER_VALUES.COMPLIANT]: item => item.is_compliant,
    [FILTER_VALUES.NON_COMPLIANT]: item => !item.is_compliant,
    [FILTER_VALUES.ALL]: item => item
}

const SORT_VALUES = {
    TYPE_ASC: 'type_asc',
    TYPE_DESC: 'type_desc',
    DATE_OF_ENTRY_ASC: 'created_at_asc',
    DATE_OF_ENTRY_DESC: 'created_at_desc',
    DEADLINE_ASC: 'deadline_asc',
    DEADLINE_DESC: 'deadline_desc',
    RESPONSIBLE_PERSON_ASC: 'responsible_person_asc',
    RESPONSIBLE_PERSON_DESC: 'responsible_person_desc'
}

const SORT_OPTIONS = [
    {
        value: SORT_VALUES.TYPE_ASC,
        label: 'REGISTERS.REQUIREMENTS.SORT_VALUES.TYPE_ASC'
    },
    {
        value: SORT_VALUES.TYPE_DESC,
        label: 'REGISTERS.REQUIREMENTS.SORT_VALUES.TYPE_DESC'
    },
    {
        value: SORT_VALUES.DATE_OF_ENTRY_ASC,
        label: 'REGISTERS.REQUIREMENTS.SORT_VALUES.DATE_OF_ENTRY_ASC'
    },
    {
        value: SORT_VALUES.DATE_OF_ENTRY_DESC,
        label: 'REGISTERS.REQUIREMENTS.SORT_VALUES.DATE_OF_ENTRY_DESC'
    },
    {
        value: SORT_VALUES.DEADLINE_ASC,
        label: 'REGISTERS.REQUIREMENTS.SORT_VALUES.DEADLINE_ASC'
    },
    {
        value: SORT_VALUES.DEADLINE_DESC,
        label: 'REGISTERS.REQUIREMENTS.SORT_VALUES.DEADLINE_DESC'
    },
    {
        value: SORT_VALUES.RESPONSIBLE_PERSON_ASC,
        label: 'REGISTERS.REQUIREMENTS.SORT_VALUES.RESPONSIBLE_PERSON_ASC'
    },
    {
        value: SORT_VALUES.RESPONSIBLE_PERSON_DESC,
        label: 'REGISTERS.REQUIREMENTS.SORT_VALUES.RESPONSIBLE_PERSON_DESC'
    }
]

const SORT_ACTIONS = {
    [SORT_VALUES.TYPE_ASC]: (a, b) => (a.type > b.type ? 1 : -1),
    [SORT_VALUES.TYPE_DESC]: (a, b) => (a.type < b.type ? 1 : -1),
    [SORT_VALUES.DATE_OF_ENTRY_ASC]: (a, b) => (a.id > b.id ? 1 : -1),
    [SORT_VALUES.DATE_OF_ENTRY_DESC]: (a, b) => (a.id < b.id ? 1 : -1),
    [SORT_VALUES.DEADLINE_ASC]: (a, b) =>
        isAfter(a.deadline, b.deadline) ? 1 : -1,
    [SORT_VALUES.DEADLINE_DESC]: (a, b) =>
        isAfter(a.deadline, b.deadline) ? -1 : 1,
    [SORT_VALUES.RESPONSIBLE_PERSON_ASC]: (a, b) =>
        getFullNameOrEmail(a.responsible_person) >
        getFullNameOrEmail(b.responsible_person)
            ? 1
            : -1,
    [SORT_VALUES.RESPONSIBLE_PERSON_DESC]: (a, b) =>
        getFullNameOrEmail(a.responsible_person) <
        getFullNameOrEmail(b.responsible_person)
            ? 1
            : -1
}

const TYPES = [
    {
        value: CONTRACTUAL_TYPE,
        label: 'REGISTERS.REQUIREMENTS.TYPES.CONTRACTUAL'
    },
    {
        value: LEGAL_REGULATORY_TYPE,
        label: 'REGISTERS.REQUIREMENTS.TYPES.LEGAL_REGULATORY'
    }
]

const MUTUAL_PARAMS = [
    'id',
    'type',
    'area_id',
    'responsible_person_id',
    'is_compliant',
    'interested_party',
    'description',
    'deadline'
]

const CONTRACTUAL_REQUIREMENT_PARAMS = [
    ...MUTUAL_PARAMS,
    'date_of_the_document',
    'prescribing_document'
]

const LEGAL_REQUIREMENT_PARAMS = [...MUTUAL_PARAMS, 'law']

const LEGAL_REQUIREMENT_INPUTS = {
    type: {
        label: 'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.TYPE.LABEL',
        description: 'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.TYPE.DESCRIPTION',
        index: 0
    },
    country_id: {
        label: 'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.COUNTRY.LABEL',
        description:
            'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.COUNTRY.DESCRIPTION',
        index: 1
    },
    state_id: {
        label: 'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.STATE.LABEL',
        description:
            'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.STATE.DESCRIPTION',
        index: 2
    },
    name: {
        label:
            'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.LAW_REGULATION_NAME.LABEL',
        description:
            'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.LAW_REGULATION_NAME.DESCRIPTION',
        index: 3
    },
    interested_party: {
        label: 'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.INTERESTED_PARTY.LABEL',
        description:
            'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.INTERESTED_PARTY.DESCRIPTION',
        index: 4
    },
    description: {
        label: 'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.DESCRIPTION.LABEL',
        description:
            'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.DESCRIPTION.DESCRIPTION',
        index: 5
    },
    valid_from: {
        label: 'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.VALID_FROM.LABEL',
        description:
            'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.VALID_FROM.DESCRIPTION',
        index: 6
    },
    deadline: {
        label: 'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.DEADLINE.LABEL',
        description:
            'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.DEADLINE.DESCRIPTION',
        index: 7
    },
    area_id: {
        label: 'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.RELATED_TO.LABEL',
        description:
            'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.RELATED_TO.DESCRIPTION',
        index: 8
    },
    responsible_person_id: {
        label:
            'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.RESPONSIBLE_PERSON.LABEL',
        description:
            'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.RESPONSIBLE_PERSON.DESCRIPTION',
        index: 9
    },
    link: {
        label: 'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.LINK.LABEL',
        description: 'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.LINK.DESCRIPTION',
        index: 10
    },
    is_compliant: {
        label: 'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.COMPLIANT.LABEL',
        description:
            'REGISTERS.REQUIREMENTS.LEGAL_REGULATORY.COMPLIANT.DESCRIPTION',
        index: 11
    }
}

const CONTRACTUAL_REQUIREMENT_INPUTS = {
    type: {
        label: 'REGISTERS.REQUIREMENTS.CONTRACTUAL.TYPE.LABEL',
        description: 'REGISTERS.REQUIREMENTS.CONTRACTUAL.TYPE.DESCRIPTION',
        index: 0
    },
    interested_party: {
        label: 'REGISTERS.REQUIREMENTS.CONTRACTUAL.INTERESTED_PARTY.LABEL',
        description:
            'REGISTERS.REQUIREMENTS.CONTRACTUAL.INTERESTED_PARTY.DESCRIPTION',
        index: 1
    },
    prescribing_document: {
        label: 'REGISTERS.REQUIREMENTS.CONTRACTUAL.PRESCRIBING_DOCUMENT.LABEL',
        description:
            'REGISTERS.REQUIREMENTS.CONTRACTUAL.PRESCRIBING_DOCUMENT.DESCRIPTION',
        index: 2
    },
    description: {
        label: 'REGISTERS.REQUIREMENTS.CONTRACTUAL.DESCRIPTION.LABEL',
        description:
            'REGISTERS.REQUIREMENTS.CONTRACTUAL.DESCRIPTION.DESCRIPTION',
        index: 3
    },
    date_of_the_document: {
        label: 'REGISTERS.REQUIREMENTS.CONTRACTUAL.DATE.LABEL',
        description: 'REGISTERS.REQUIREMENTS.CONTRACTUAL.DATE.DESCRIPTION',
        index: 4
    },
    deadline: {
        label: 'REGISTERS.REQUIREMENTS.CONTRACTUAL.DEADLINE.LABEL',
        description: 'REGISTERS.REQUIREMENTS.CONTRACTUAL.DEADLINE.DESCRIPTION',
        index: 5
    },
    area_id: {
        label: 'REGISTERS.REQUIREMENTS.CONTRACTUAL.RELATED_TO.LABEL',
        description:
            'REGISTERS.REQUIREMENTS.CONTRACTUAL.RELATED_TO.DESCRIPTION',
        index: 6
    },
    responsible_person_id: {
        label: 'REGISTERS.REQUIREMENTS.CONTRACTUAL.RESPONSIBLE_PERSON.LABEL',
        description:
            'REGISTERS.REQUIREMENTS.CONTRACTUAL.RESPONSIBLE_PERSON.DESCRIPTION',
        index: 7
    },
    is_compliant: {
        label: 'REGISTERS.REQUIREMENTS.CONTRACTUAL.COMPLIANT.LABEL',
        description: 'REGISTERS.REQUIREMENTS.CONTRACTUAL.COMPLIANT.DESCRIPTION',
        index: 8
    }
}

const REGISTER_OF_REQUIREMENTS = {
    CONTRACTUAL_TYPE,
    LEGAL_REGULATORY_TYPE,
    TYPES,
    FILTER_VALUES,
    FILTER_OPTIONS,
    FILTER_ACTIONS,
    SORT_VALUES,
    SORT_OPTIONS,
    SORT_ACTIONS,
    CONTRACTUAL_REQUIREMENT_PARAMS,
    LEGAL_REQUIREMENT_PARAMS
}

const REGISTERS_ICONS = {
    [REGISTER_TYPE_REQUIREMENTS]: 'register-req',
    [REGISTER_TYPE_RISKS]: 'risk-register',
    [REGISTER_TYPE_STATEMENT_OF_APPLICABILITY]: 'soa',
    [REGISTER_TYPE_INCIDENTS]: 'incidents',
    [REGISTER_TYPE_NONCONFORMITIES]: 'nonconformity',
    [REGISTER_TYPE_INTERNAL_AUDITS]: 'audit',
    [REGISTER_TYPE_SECURITY_OBJECTIVES]: 'security-objectives',
    [REGISTER_TYPE_MANAGEMENT_REVIEW]: 'management-review',
    [REGISTER_TYPE_TRAININGS]: 'training',
    [REGISTER_TYPE_REPORTING]: 'reporting',
    [REGISTER_TYPE_AUDIT_AND_EVIDENCE]: 'audit',
    [REGISTER_TYPE_MAINTENANCE]: 'audit'
}

const PROPERTY_UPDATE_DEBOUNCE_INTERVAL = 1000

const REGISTER_NAMES = {
    REGISTER_OF_REQUIREMENTS_NAME: 'Register of requirements',
    RISK_REGISTER_NAME: 'Risk register',
    STATEMENT_OF_APPLICABILITY_NAME: 'Statement of applicability',
    INCIDENT_REGISTER_NAME: 'Incident register',
    NONCONFORMITY_REGISTER_NAME: 'Nonconformity register',
    AUDIT_REGISTER_NAME: 'Audit register',
    SECURITY_OBJECTIVES_NAME: 'Security objectives',
    FIRST_OFFICIAL_MANAGEMENT_REVIEWS_NAME: 'Management reviews',
    TRAININGS_REGISTER_NAME: 'Trainings',
    SETTING_UP_MANAGEMENT_REVIEW_NAME: 'Setup management review'
}

const INTERNAL_AUDITS_EVIDENCE_CHAR_LIMIT = 450

export {
    REGISTER_WIZARD_ROUTES,
    REGISTER_OF_REQUIREMENTS,
    LEGAL_REQUIREMENT_INPUTS,
    CONTRACTUAL_REQUIREMENT_INPUTS,
    REGISTER_TYPES,
    REGISTER_WIZARD_TYPES,
    REGISTER_WIZARD_ROUTE_MAP,
    REGISTER_STATUSES,
    REGISTERS_ICONS,
    REGISTER_DISCUSSION_ROUTE_MAP,
    PROPERTY_UPDATE_DEBOUNCE_INTERVAL,
    REGISTER_NAMES,
    INTERNAL_AUDITS_EVIDENCE_CHAR_LIMIT
}
