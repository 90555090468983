<template>
    <div
        :class="[
            `experta__chat-message`,
            `experta__chat-message--${message.sender}`,
            `experta__chat-message--${message.type}`
        ]"
    >
        <div v-if="isMessageTypeText" v-html="message.content"></div>

        <div
            v-else-if="isMessageTypeQuestion"
            @click="askExperta(message.content)"
        >
            {{ message.content }}
        </div>
    </div>
</template>

<script>
import { EXPERTA_MESSAGE_TYPE } from '@/constants'

export default {
    props: {
        message: {
            type: Object,
            required: true
        }
    },

    computed: {
        isMessageTypeText () {
            return this.message.type === EXPERTA_MESSAGE_TYPE.TEXT
        },

        isMessageTypeQuestion () {
            return this.message.type === EXPERTA_MESSAGE_TYPE.QUESTION
        }
    },

    methods: {
        askExperta (question) {
            this.$emit('ask-experta', question)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@style/sfc.scss';

.experta__chat-message {
    // Default styling
    display: inline-block;
    clear: both;
    max-width: 85%;
    margin: 0 0 $cf-gutter;
    padding: rem(8);
    border-radius: 8px;
    font-size: rem(12);

    // Word break settings
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    &:last-child {
        margin: 0;
    }

    &::v-deep {
        // p tag in Experta anwser
        p {
            margin: 0;

            & + p {
                margin: $cf-gutter-sm 0 0 0;
            }
        }
    }

    &--bot {
        float: left;
        background-color: $color-primary-zeta;
        color: $color-primary-beta;
    }

    &--user {
        float: right;
        background-color: $color-primary-epsilon;
        color: $cf-color-white;
    }

    &--question {
        text-decoration: underline;
        cursor: pointer;
    }
}
</style>
