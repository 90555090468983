import { find } from 'lodash-es'
import { reportingService } from '@/services/api/reporting'
import { getPercentage } from '@/services/utils/numbers'
import { REPORTING } from '@/constants'
import { resourcesService } from '@/services/api/resources'

function getInitialState () {
    return {
        projectStatus: {
            project_start_date: new Date(),
            tasks: {
                number_of_tasks: 0,
                number_of_overdue_tasks: 0
            },
            percentOfResourcesObjective: {
                result: 0,
                var1_title: ''
            },
            finishProjectObjective: {
                title: '',
                var_1: null
            }
        },

        complianceSteps: [],

        compliance: {},

        performance: {
            objectives: [],
            activities: [],
            achievedObjectives: 0,
            lastActivities: 0,
            totalActivities: 0,
            maturity: null
        },

        documents: {
            approved: [],
            reports: []
        },

        resources: {}
    }
}

const state = getInitialState()

const actions = {
    async fetchReportingData ({ commit, dispatch, rootGetters }) {
        commit('SET_APP_LOADING', true, { root: true })

        if (!rootGetters['company/companyExists']) {
            return
        }

        try {
            await Promise.all([
                dispatch('getProjectStatusStats'),
                dispatch('getComplianceStats'),
                dispatch('getPerformanceStats', {})
            ])
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getReadinessForCertification ({ commit, dispatch, rootGetters }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id

            return await reportingService.fetchReadinessForCertification(
                companyId
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getProjectStatusStats ({ commit, dispatch, rootGetters }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const projectStatusFetched =
                rootGetters['loading/projectStatusFetched']
            const projectStatusLoading =
                rootGetters['loading/projectStatusLoading']
            const complianceStepsFetched =
                rootGetters['loading/complianceStepsFetched']
            const resourcesFetched = rootGetters['loading/resourcesFetched']
            !(projectStatusFetched || projectStatusLoading) &&
                (await dispatch('getProjectStatus'))
            !complianceStepsFetched && (await dispatch('getComplianceSteps'))
            !resourcesFetched && (await dispatch('getResources'))
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getProjectStatus ({ commit, rootGetters }) {
        try {
            const companyId = rootGetters['company/company']?.id
            const regulationId = rootGetters['regulation/currentRegulation']?.id

            if (!companyId || !regulationId) {
                return
            }

            const projectStatus = await reportingService.fetchProjectStatus(
                companyId,
                regulationId
            )

            commit('SET_PROJECT_STATUS', projectStatus)
        } catch (error) {
            throw error
        }
    },

    async getComplianceSteps ({ commit, rootGetters }) {
        try {
            const companyId = rootGetters['company/company']?.id
            const companyRegulationId =
                rootGetters['regulation/currentRegulation']?.pivot.id

            if (!companyId || !companyRegulationId) {
                return
            }

            const complianceSteps = await reportingService.getComplianceSteps(
                companyId,
                companyRegulationId
            )

            commit('SET_COMPLIANCE_STEPS', complianceSteps)
        } catch (error) {
            throw error
        }
    },

    async getComplianceStats ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const documentsFetched =
                rootGetters[
                    'loading/approvedDocumentsAndGeneratedReportsFetched'
                ]
            await dispatch('getCompliance')
            !documentsFetched &&
                (await dispatch('getApprovedDocumentsAndGeneratedReports'))
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getCompliance ({ commit, rootGetters }) {
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const compliance = await reportingService.fetchCompliance(
                companyId,
                regulationId
            )

            commit('SET_COMPLIANCE', compliance)
        } catch (error) {
            throw error
        }
    },

    async getPerformanceStats (
        { commit, rootGetters, dispatch },
        {
            intervalType = REPORTING.PERFORMANCE_ACTIVITIES_DEFAULT_VALUE,
            shouldLoad = false
        }
    ) {
        shouldLoad && commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const performance = await reportingService.fetchPerformance({
                companyId,
                regulationId,
                intervalType
            })

            commit('SET_PERFORMANCE', performance)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            shouldLoad && commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getApprovedDocumentsAndGeneratedReports ({ commit, rootGetters }) {
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const documents = await reportingService.fetchApprovedDocumentsAndReports(
                companyId,
                regulationId
            )

            commit('SET_APPROVED_DOCUMENTS_AND_GENERATED_REPORTS', documents)
        } catch (error) {
            throw error
        }
    },

    async getResources ({ commit, rootGetters }) {
        try {
            const companyId = rootGetters['company/company']?.id
            const regulationId = rootGetters['regulation/currentRegulation']?.id

            if (!companyId || !regulationId) {
                return
            }

            const resources = await reportingService.getResources(
                companyId,
                regulationId
            )

            commit('SET_RESOURCES', resources)
        } catch (error) {
            throw error
        }
    },

    async bulkApproveResources ({ dispatch, commit, rootGetters }, data) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await resourcesService.bulkApproval(companyId, regulationId, data)
            await dispatch('getResources')
        } catch (error) {
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}

const mutations = {
    SET_PROJECT_STATUS (state, projectStatus) {
        const { objectives } = projectStatus

        const percentOfResourcesObjective = find(objectives, {
            key:
                REPORTING.SECURITY_OBJECTIVE_TYPES
                    .SECURITY_OBJECTIVE_PROJECT_PLAN
        })
        const finishProjectObjective = find(objectives, {
            key: REPORTING.SECURITY_OBJECTIVE_TYPES.FINISH_ISO_27001
        })

        state.projectStatus = {
            ...state.projectStatus,
            ...projectStatus,
            percentOfResourcesObjective,
            finishProjectObjective
        }
    },

    SET_COMPLIANCE_STEPS (state, steps) {
        state.complianceSteps = steps
    },

    SET_COMPLIANCE (state, compliance) {
        state.compliance = { ...state.compliance, ...compliance }
    },

    SET_PERFORMANCE (state, performance) {
        const achievedObjectives = performance.objectives.filter(
            item => item.isAchieved
        )
        const lastActivities = performance.periodicalActivities.reduce(
            (sum, item) => sum + item.value,
            0
        )
        const totalActivities = performance.activities
            .filter(item => item.in_total)
            .reduce((sum, item) => sum + item.value, 0)

        const maturityValues = performance.objectiveMaturities.map(
            item => item.value
        )
        let maturity = null

        if (!maturityValues.includes(null)) {
            maturity =
                maturityValues.reduce((sum, item) => sum + item, 0) /
                maturityValues.length
        }

        const stats = {
            achievedObjectives: getPercentage(
                performance.objectives.length,
                achievedObjectives.length
            ),
            lastActivities,
            totalActivities,
            maturity
        }

        performance.activities = [
            ...performance.periodicalActivities,
            ...performance.activities
        ]
        state.performance = { ...state.performance, ...performance, ...stats }
    },

    SET_APPROVED_DOCUMENTS_AND_GENERATED_REPORTS (state, documents) {
        state.documents = { ...state.documents, ...documents }
    },

    SET_RESOURCES (state, resources) {
        state.resources = { ...state.resources, ...resources }
    },

    SET_RESOURCE (state, { resource, resourceCategory }) {
        const resourceType = resource.type.name

        state.resources = {
            ...state.resources,
            [resourceCategory]: {
                ...(state.resources[resourceCategory]
                    ? state.resources[resourceCategory]
                    : []),
                [resourceType]: [
                    ...(state.resources[resourceCategory] &&
                    state.resources[resourceCategory][resourceType]
                        ? state.resources[resourceCategory][resourceType]
                        : []),
                    resource
                ]
            }
        }
    }
}

const getters = {
    projectStatus: state => state.projectStatus,
    compliance: state => state.compliance,
    performance: state => state.performance,
    documents: state => state.documents,
    resources: state => state.resources,
    complianceSteps: state => state.complianceSteps,
    numberOfCompletedSteps: state =>
        state.complianceSteps.filter(
            step => step.status === REPORTING.STEP_STATUSES.COMPLETED
        ).length
}

export const reporting = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
