<template>
    <searchable-list shadowed>
        <template slot="input">
            <app-input v-model="searchTerm" :placeholder="placeholder" />
        </template>
        <div v-for="listItem in list" :key="listItem[trackBy]">
            <div class="cf-c-checkbox-list__header">
                {{ listItem.label }}
            </div>
            <div
                v-for="item in filteredList(listItem.list)"
                :key="item[trackBy]"
                class="cf-c-checkbox-list__item"
                @click="handleItemSelect(item)"
            >
                {{ item[label] }}
            </div>
        </div>
    </searchable-list>
</template>

<script>
import { isArray, isString } from 'lodash-es'
import { includesSearchTerm } from '@/services/utils/functions'

export default {
    props: {
        list: {
            type: Array,
            required: true,
            validator: list => {
                for (let listItem of list) {
                    if (!isArray(listItem.list)) {
                        return false
                    }
                    if (!isString(listItem.label)) {
                        return false
                    }
                }

                return true
            }
        },

        trackBy: {
            type: String,
            default: 'id'
        },

        value: {
            type: String,
            default: 'value'
        },

        label: {
            type: String,
            default: 'label'
        },

        placeholder: {
            type: String,
            required: false,
            default: 'COMMON.SEARCH'
        }
    },
    data: () => ({
        searchTerm: ''
    }),

    methods: {
        filteredList (list) {
            return list.filter(item =>
                includesSearchTerm(item[this.label], this.searchTerm)
            )
        },

        handleItemSelect (item) {
            this.$emit('select', item)
        }
    }
}
</script>
