import { includes, minBy, first } from 'lodash-es'
import { INPUT_IDENTIFIERS } from '@/constants'
import { scrollTo } from '@/services/utils/dom'

export default {
    inserted (element, { arg: container }) {
        const inputs = element.querySelectorAll(INPUT_IDENTIFIERS)

        if (!inputs.length) {
            return
        }

        let emptyInputs = [...inputs].filter(input => {
            if (includes(input.classList, 'v-select')) {
                const value = input.querySelector('.vs__selected')
                return !value && !includes(input.classList, 'vs--disabled')
            }

            return input.value === '' && !input.disabled
        })

        emptyInputs = emptyInputs.map(input => {
            return includes(input.classList, 'v-select')
                ? first(input.querySelectorAll('input'))
                : input
        })

        const firstEmptyInput = emptyInputs.length
            ? minBy(emptyInputs, input =>
                  input.tabIndex ? parseInt(input.tabIndex) : 0
              )
            : null

        if (!firstEmptyInput) {
            return
        }

        scrollTo(firstEmptyInput, `#${container}`, -30)

        setTimeout(() => {
            firstEmptyInput.focus()
        }, 500)
    }
}
