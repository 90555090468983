// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Toasted from 'vue-toasted'
import vSelect from 'vue-select'
import Multiselect from 'vue-multiselect'
import VueScrollTo from 'vue-scrollto'
import VModal from 'vue-js-modal'
import VueObserveVisibility from 'vue-observe-visibility'
import VueGtm from '@gtm-support/vue2-gtm'
import AppModalNew from '@src/components/AppModalNew'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App from './App'
import router from './router'
import store from './store'

import {
    configureHotJar,
    configureHttp,
    configureSentry,
    configureMixpanel
} from './services/configuration'
import vueGTMConfig from './config/gtm'

import '@/components/globals'
import Vuelidate from 'vuelidate'
import { VUE_APP_GTM_TRACKING_ID } from '@/constants'

import { i18n } from '@/i18n'

Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(Toasted, {
    duration: 3000,
    theme: 'outline'
})
Vue.use(VueScrollTo, {
    cancelable: false
})
Vue.use(VModal, { componentName: 'v-modal' })
Vue.use(VueObserveVisibility)
Vue.component('VSelect', vSelect)
Vue.component('VMultiselect', Multiselect)
Vue.component('AppModalNew', AppModalNew)
Vue.config.productionTip = true
configureHttp()

/* google tag manager */
if (VUE_APP_GTM_TRACKING_ID) {
    Vue.use(VueGtm, vueGTMConfig(router))
}

/* eslint-disable no-new */
new Vue({
    router,
    store,
    i18n,
    components: { App },
    created () {
        configureSentry(router)
        configureHotJar()
        configureMixpanel()
    },
    render: h => h(App)
}).$mount('#root')
