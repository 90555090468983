var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dw-editor__heading"},[_c('div',{staticClass:"dw-editor__heading-left"},[(_vm.isConformioDocument && _vm.documentIsEditable)?_c('app-form-switch',{attrs:{"value":_vm.isCustomModeEnabled,"size":"lg","icon":"paragraph-icon","inactive-color":"gray-beta","active-color":"primary-epsilon"},on:{"input":_vm.handleModeChange}},[_c('template',{slot:"first-label"},[_vm._v(" "+_vm._s(_vm.$t('DOCUMENT_WIZARD.CUSTOM_PARAGRAPH.TEXT_LABEL'))+" ")])],2):_vm._e(),(_vm.shouldSeePrintButton)?_c('document-print',{attrs:{"document":_vm.document}}):_vm._e()],1),_c('div',{staticClass:"dw-editor__heading-right"},[(_vm.documentReviewIsStoppable)?_c('div',{staticClass:"dw-editor__heading-btn"},[_c('button',{staticClass:"btn btn--gamma",attrs:{"type":"button","permission-control":""},on:{"click":_vm.handleStopReview}},[_vm._v(" "+_vm._s(_vm.$t('DOCUMENT_WIZARD.STOP_REVIEW'))+" ")])]):_vm._e(),(_vm.documentApprovalIsStoppable)?_c('div',{staticClass:"dw-editor__heading-btn"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top",value:(
                    _vm.$t('DOCUMENT_WIZARD.STOP_APPROVAL_TOOLTIP')
                ),expression:"\n                    $t('DOCUMENT_WIZARD.STOP_APPROVAL_TOOLTIP')\n                ",modifiers:{"top":true}}],staticClass:"btn btn--gamma",attrs:{"type":"button","disabled":_vm.isCompanyExpired},on:{"click":_vm.handleStopApproval}},[_vm._v(" "+_vm._s(_vm.$t('DOCUMENT_WIZARD.STOP_APPROVAL'))+" ")])]):_vm._e(),(_vm.shouldUpploadNewVersionBeVisible)?_c('div',{staticClass:"dw-editor__heading-btn"},[(
                    (_vm.document && _vm.documentStep) ||
                        _vm.isUserUploadedDocument(_vm.document)
                )?_c('upload-new-version',{attrs:{"document-id":_vm.document.id,"step-id":_vm.documentStep ? _vm.documentStep.id : null,"step-inputs-number":_vm.stepInputsNumber},on:{"update-document-url":function($event){return _vm.$emit('upload-new-version', $event)}}}):_vm._e()],1):_vm._e(),(_vm.documentIsEditable)?[_c('div',{staticClass:"dw-editor__heading-btn",attrs:{"id":"submit-for-review"}},[_c('button',{staticClass:"btn btn--alpha",attrs:{"type":"button","disabled":_vm.isCompanyExpired || !_vm.hasReviewersAssigned},on:{"click":_vm.handleOpenReviewDeadlineModal}},[_vm._v(" "+_vm._s(_vm.$t('DOCUMENT_WIZARD.SUBMIT_FOR_REVIEW'))+" ")])]),_c('b-tooltip',{attrs:{"disabled":!!_vm.hasReviewersAssigned,"target":"submit-for-review"}},[_vm._v(" "+_vm._s(_vm.$t('DOCUMENT_WIZARD.MUST_ASSIGN_REVIEWERS_FIRST'))+" ")]),(_vm.isSubmittable)?_c('div',{staticClass:"dw-editor__heading-btn",attrs:{"id":"submit-for-approval"}},[_c('button',{staticClass:"btn btn--alpha",attrs:{"type":"button","disabled":_vm.isCompanyExpired || !_vm.hasApproverAssigned},on:{"click":_vm.handleOpenSubmitForApprovalModal}},[_vm._v(" "+_vm._s(_vm.$t('DOCUMENT_WIZARD.SUBMIT_FOR_APPROVAL'))+" ")])]):_vm._e(),_c('b-tooltip',{attrs:{"disabled":!!_vm.hasApproverAssigned,"target":"submit-for-approval"}},[_vm._v(" "+_vm._s(_vm.$t('DOCUMENT_WIZARD.MUST_ASSIGN_APPROVER_FIRST'))+" ")])]:_vm._e(),(
                _vm.documentCanBeSentToDraftFromCurrentStatus &&
                    !_vm.taskActions.length
            )?_c('div',{staticClass:"dw-editor__heading-btn"},[_c('button',{staticClass:"btn btn--alpha",attrs:{"type":"button","disabled":_vm.isCompanyExpired},on:{"click":_vm.handleEditDocument}},[_vm._v(" "+_vm._s(_vm.$t('DOCUMENT_WIZARD.EDIT_DOCUMENT'))+" ")])]):_vm._e(),_vm._l((_vm.taskActions),function(action,index){return (_vm.taskActions.length)?_c('div',{key:`${action.name}-${index}`,staticClass:"dw-editor__heading-btn"},[_c('button',{staticClass:"btn btn--alpha",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleTaskActionButtonClick(action)}}},[_vm._v(" "+_vm._s(_vm.$t(`DOCUMENT_WIZARD.${action.name.toUpperCase()}`))+" ")])]):_vm._e()})],2),_c('review-deadline',{on:{"submit":_vm.handleSubmitForReview}}),_c('submit-for-approval',{on:{"submit":_vm.handleSubmitForApproval}}),_c('app-confirm-modal',{attrs:{"title":"DOCUMENT_WIZARD.EDIT_DOCUMENT_WARNING_MODAL.TITLE","text":"DOCUMENT_WIZARD.EDIT_DOCUMENT_WARNING_MODAL.DESCRIPTION","modal-name":"edit-document-warning","should-close-on-cancel":"","ok-title":"COMMON.CONTINUE"},on:{"confirm":_vm.sendDocumentToEditMode}}),_c('app-modal',{attrs:{"name":"suggested-responsiblity-tasks-modal","body":"DOCUMENT_WIZARD.SUGGESTED_RESPONSIBILITY_WARNING_MODAL.DESCRIPTION","title":"DOCUMENT_WIZARD.SUGGESTED_RESPONSIBILITY_WARNING_MODAL.TITLE","ok-title":"DOCUMENT_WIZARD.SUGGESTED_RESPONSIBILITY_WARNING_MODAL.OK_TITLE","cancel-title":"DOCUMENT_WIZARD.SUGGESTED_RESPONSIBILITY_WARNING_MODAL.CANCEL_TITLE","hide-footer":false},on:{"ok":_vm.handleSuggestedResponsiblityModalOkClick,"before-open":_vm.handleSuggestedResponsiblityModalBeforeOpen}}),_c('action-notification-modal',{on:{"action-notification-modal-closed":_vm.handleRedirectionAfterAction}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }