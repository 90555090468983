<template>
    <div>
        <textarea
            :id="id"
            ref="textarea"
            v-char-counter="{ charLimit: charLimit, emitInput: false }"
            :disabled="disabled"
            :tabindex="tabindex"
            :name="name"
            :class="[
                'cf-c-textarea',
                { 'cf-has-text': value },
                { 'cf-c-textarea--full-width': fullWidth },
                { 'cf-c-textarea--min-height-full': minHeightFull },
                { 'cf-c-textarea--min-height-sm-double': minHeightSmDouble },
                { 'cf-c-textarea--resize-arrow-disabled': resizeArrowDisabled },
                { 'cf-c-textarea--has-error': errorMessage },
                { 'cf-c-textarea--min-height-15': minHeight15 },
                { 'cf-c-textarea--background-custom': backgroundCustom }
            ]"
            rows="1"
            :value="value"
            spellcheck="false"
            :placeholder="$t(placeholder)"
            @input="handleInput"
            @blur="$emit('blur', $event.target.value)"
            @focus="$emit('focus')"
        ></textarea>
        <span v-if="!!errorMessage" class="cf-c-input__error">
            {{ errorMessage }}
        </span>
    </div>
</template>

<script>
import autosize from 'autosize'
import CharCounter from '@/directives/CharCounter'

export default {
    directives: {
        'char-counter': CharCounter
    },
    props: {
        value: {
            type: String,
            required: false,
            default: ''
        },

        placeholder: {
            type: String,
            required: false,
            default: 'FORM.INPUT_TEXT'
        },

        errorMessage: {
            type: String,
            required: false,
            default: ''
        },

        fullWidth: {
            type: Boolean,
            required: false,
            default: true
        },

        minHeightFull: {
            type: Boolean,
            required: false,
            default: false
        },

        minHeightSmDouble: {
            type: Boolean,
            required: false,
            default: false
        },

        name: {
            type: String,
            required: false,
            default: ''
        },

        tabindex: {
            type: Number,
            required: false,
            default: 0
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        resizeArrowDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        charLimit: {
            type: Number,
            required: false,
            default: 0
        },
        // TODO: this is a tmp solution
        autosize: {
            type: Boolean,
            required: false,
            default: true
        },

        id: {
            type: String,
            required: false,
            default: ''
        },

        minHeight15: {
            type: Boolean,
            required: false,
            default: false
        },

        backgroundCustom: {
            type: Boolean,
            required: false,
            default: false
        },

        autofocus: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    async mounted () {
        this.autosize && autosize(this.$refs.textarea)

        this.autofocus && this.focus()
    },

    methods: {
        handleInput (event) {
            const value = event.target.value

            this.$emit('input', value.trim())
        },

        focus () {
            this.$refs.textarea.focus()
        }
    }
}
</script>
