<template>
    <div class="cf-c-data-card" :class="classes">
        <h4 class="cf-c-data-card__heading">
            {{ title }}
        </h4>
        <div class="cf-c-data-card__body">
            <slot></slot>
        </div>
        <div v-if="$slots.footer" class="cf-c-data-card__footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },

        classes: {
            type: String,
            required: false,
            default: ''
        }
    }
}
</script>
