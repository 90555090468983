<template>
    <div>
        <div v-for="(item, index) in listWithLastEmptyItem" :key="index">
            <app-input
                type="text"
                :value="item"
                :placeholder="placeholder"
                @input="
                    value => {
                        handleInput(index, value)
                    }
                "
            />
        </div>
        <span v-if="!!errorMessage" class="cf-c-input__error">{{
            errorMessage
        }}</span>
    </div>
</template>

<script>
export default {
    props: {
        inputList: {
            type: Array,
            required: true
        },

        placeholder: {
            type: String,
            required: true
        },

        errorMessage: {
            type: String,
            required: false,
            default: ''
        }
    },

    data () {
        return {
            list: []
        }
    },

    computed: {
        listWithLastEmptyItem () {
            return this.list.concat([''])
        }
    },

    watch: {
        inputList () {
            this.list = this.inputList || []
        }
    },

    created () {
        this.list = this.inputList || []
    },

    methods: {
        handleInput (index, value) {
            if (value) {
                if (this.list[index]) {
                    this.list[index] = value
                } else {
                    this.list.push(value)
                }
            } else {
                this.list.splice(index, 1)
            }

            this.$emit('input', this.list)
        }
    }
}
</script>
