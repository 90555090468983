<template>
    <vue-tags-input
        v-model="tag"
        :disabled="disabled"
        :tags="resultingTags"
        @tags-changed="handleTagsChanged"
    />
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input'

export default {
    components: {
        VueTagsInput
    },

    props: {
        tags: {
            type: Array,
            required: true
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data () {
        return {
            tag: '',
            resultingTags: []
        }
    },

    created () {
        this.resultingTags = this.tags
    },

    methods: {
        handleTagsChanged (newTags) {
            this.resultingTags = newTags
            this.$emit('input', this.resultingTags)
        }
    }
}
</script>
