import { mapGetters } from 'vuex'
import { differenceBy, get, findIndex, trim } from 'lodash-es'
import { includesSearchTerm } from '@/services/utils/functions'
import { REGISTER_TYPES } from '@/constants'

export default {
    data () {
        return {
            checkedRisks: this.form ? [...this.form.risks] : []
        }
    },

    computed: {
        ...mapGetters('risks', ['risks']),
        ...mapGetters('register', ['companyRegulationRegisterByType']),
        riskStepId () {
            const riskRegister = this.companyRegulationRegisterByType(
                REGISTER_TYPES.RISKS
            )

            return get(riskRegister, 'register.step_id', 0)
        },

        filteredRisks () {
            // Prevent showing already added risks.
            const filteredRisks = differenceBy(
                this.risks,
                this.form.risks,
                'id'
            )

            if (!trim(this.searchTerm)) {
                return filteredRisks.length ? filteredRisks : []
            }

            return filteredRisks.filter(
                risk =>
                    includesSearchTerm(String(risk.id), this.searchTerm) ||
                    includesSearchTerm(risk.code, this.searchTerm) ||
                    includesSearchTerm(risk.asset.name, this.searchTerm) ||
                    includesSearchTerm(
                        risk.vulnerability.name,
                        this.searchTerm
                    ) ||
                    includesSearchTerm(risk.threat.name, this.searchTerm)
            )
        }
    },

    methods: {
        handleCheckedRisk (event) {
            const index = findIndex(this.checkedRisks, event.risk)

            if (event.isChecked) {
                if (index === -1) {
                    this.checkedRisks.push(event.risk)
                }
            } else {
                this.checkedRisks.splice(index, 1)
            }
        },

        addRisks () {
            this.form.risks = [...this.form.risks, ...this.checkedRisks]
            this.checkedRisks = []
        },

        handleRiskDelete (risk) {
            const index = findIndex(this.form.risks, risk)
            this.form.risks.splice(index, 1)
            this.handleCheckedRisk(risk)
        }
    }
}
