<template>
    <div>
        <h4 class="cf-u-color-gray-03">
            {{ title }}
        </h4>
        <div class="cf-u-display-flex cf-u-flex-direction-column cf-u-box">
            <div
                class="cf-u-display-flex cf-u-p-default cf-u-flex-justify-between"
                :class="contentParentClasses"
            >
                <slot></slot>
            </div>

            <div
                v-if="!!hasViewMoreButton"
                class="cf-u-align-self-end cf-u-mr-md cf-u-mb-md"
            >
                <app-button variation="quiet" @click="handleViewMoreStats">
                    {{ $t('COMMON.VIEW_MORE_STATS') }}
                </app-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false
        },

        contentParentClasses: {
            type: String,
            required: false
        },

        hasViewMoreButton: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    methods: {
        handleViewMoreStats () {
            this.$emit('view-more-stats')
        }
    }
}
</script>
