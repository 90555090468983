import { REGISTER_WIZARD_ROUTES } from '@/constants'
import { getFullNameOrEmail } from '@/services/utils/functions'
import { isAfter } from '@/services/utils/date'
import { REGISTER_OF_INCIDENTS } from '@/constants/incidents'
import { REGISTER_OF_NONCONFORMITIES } from '@/constants/nonconformities'
import { MANAGEMENT_REVIEW } from '@/constants/management-review'

const STATUSES = {
    COMPLETED: 'completed',
    NOT_COMPLETED: 'not_completed'
}

const STATUS_COLORS = {
    COMPLETED: 'cf-u-color-secondary-light',
    NOT_COMPLETED: 'cf-u-color-danger'
}

const MODES = {
    EDIT: 'edit',
    CREATE_ONLY: 'create-only'
}

const CORRECTIONABLE_TYPES = {
    NONCONFORMITY: 'nonconformity',
    INCIDENT: 'incident',
    COMPANY_REGULATION_MANAGEMENT_REVIEW:
        'company-regulation-management-review',
    CORRECTIVE_ACTION: 'corrective-action'
}

const STATUSES_BY_TYPE = {
    [CORRECTIONABLE_TYPES.INCIDENT]: REGISTER_OF_INCIDENTS.STATUS_COLORS,
    [CORRECTIONABLE_TYPES.NONCONFORMITY]:
        REGISTER_OF_NONCONFORMITIES.STATUS_COLORS,
    [CORRECTIONABLE_TYPES.COMPANY_REGULATION_MANAGEMENT_REVIEW]:
        MANAGEMENT_REVIEW.STATUS_COLORS,
    [CORRECTIONABLE_TYPES.CORRECTIVE_ACTION]: STATUS_COLORS
}

const CORRECTIVE_ACTIONS_SIDEBAR_ROUTES = [
    {
        route: REGISTER_WIZARD_ROUTES.NONCONFORMITIES.CORRECTIVE_ACTIONS.WIZARD,
        label: 'COMMON.WIZARD'
    },
    {
        route:
            REGISTER_WIZARD_ROUTES.NONCONFORMITIES.CORRECTIVE_ACTIONS
                .PROPERTIES,
        label: 'COMMON.PROPERTIES'
    },
    {
        route:
            REGISTER_WIZARD_ROUTES.NONCONFORMITIES.CORRECTIVE_ACTIONS
                .DISCUSSIONS,
        label: 'COMMON.DISCUSSIONS'
    }
]

const SORT_VALUES = {
    TITLE_ASC: 'title_asc',
    TITLE_DESC: 'title_desc',
    ASSIGNEE_ASC: 'assignee_asc',
    ASSIGNEE_DESC: 'assignee_desc',
    DEADLINE_ASC: 'deadline_asc',
    DEADLINE_DESC: 'deadline_desc'
}

const SORT_OPTIONS = [
    {
        value: SORT_VALUES.TITLE_ASC,
        label: 'REGISTERS.CORRECTIVE_ACTIONS.SORT_VALUES.TITLE_ASC'
    },
    {
        value: SORT_VALUES.TITLE_DESC,
        label: 'REGISTERS.CORRECTIVE_ACTIONS.SORT_VALUES.TITLE_DESC'
    },
    {
        value: SORT_VALUES.ASSIGNEE_ASC,
        label: 'REGISTERS.CORRECTIVE_ACTIONS.SORT_VALUES.ASSIGNEE_ASC'
    },
    {
        value: SORT_VALUES.ASSIGNEE_DESC,
        label: 'REGISTERS.CORRECTIVE_ACTIONS.SORT_VALUES.ASSIGNEE_DESC'
    },
    {
        value: SORT_VALUES.DEADLINE_ASC,
        label: 'REGISTERS.CORRECTIVE_ACTIONS.SORT_VALUES.DEADLINE_ASC'
    },
    {
        value: SORT_VALUES.DEADLINE_DESC,
        label: 'REGISTERS.CORRECTIVE_ACTIONS.SORT_VALUES.DEADLINE_DESC'
    }
]

const SORT_ACTIONS = {
    [SORT_VALUES.TITLE_ASC]: (a, b) => (a.title > b.title ? 1 : -1),
    [SORT_VALUES.TITLE_DESC]: (a, b) => (a.title < b.title ? 1 : -1),
    [SORT_VALUES.ASSIGNEE_ASC]: (a, b) =>
        getFullNameOrEmail(a.assignee) > getFullNameOrEmail(b.assignee)
            ? 1
            : -1,
    [SORT_VALUES.ASSIGNEE_DESC]: (a, b) =>
        getFullNameOrEmail(a.assignee) < getFullNameOrEmail(b.assignee)
            ? 1
            : -1,
    [SORT_VALUES.DEADLINE_ASC]: (a, b) =>
        isAfter(a.deadline, b.deadline) ? 1 : -1,
    [SORT_VALUES.DEADLINE_DESC]: (a, b) =>
        isAfter(a.deadline, b.deadline) ? -1 : 1
}

const ROUTE_MAP = {
    [CORRECTIONABLE_TYPES.INCIDENT]: REGISTER_WIZARD_ROUTES.INCIDENTS.INDEX,
    [CORRECTIONABLE_TYPES.NONCONFORMITY]:
        REGISTER_WIZARD_ROUTES.NONCONFORMITIES.INDEX,
    [CORRECTIONABLE_TYPES.COMPANY_REGULATION_MANAGEMENT_REVIEW]:
        REGISTER_WIZARD_ROUTES.REPORTING.MANAGEMENT_REVIEW.INDEX,
    [CORRECTIONABLE_TYPES.CORRECTIVE_ACTION]:
        REGISTER_WIZARD_ROUTES.NONCONFORMITIES.CORRECTIVE_ACTIONS.INDEX
}

const UNLINK_FORBIDDEN_TYPES_WHEN_CORRECTIVE_ACTION_COMPLETED = [
    CORRECTIONABLE_TYPES.NONCONFORMITY,
    CORRECTIONABLE_TYPES.INCIDENT,
    CORRECTIONABLE_TYPES.COMPANY_REGULATION_MANAGEMENT_REVIEW
]

export const CORRECTIVE_ACTIONS = {
    STATUSES,
    STATUS_COLORS,
    MODES,
    CORRECTIONABLE_TYPES,
    CORRECTIVE_ACTIONS_SIDEBAR_ROUTES,
    SORT_ACTIONS,
    SORT_OPTIONS,
    SORT_VALUES,
    STATUSES_BY_TYPE,
    ROUTE_MAP,
    UNLINK_FORBIDDEN_TYPES_WHEN_CORRECTIVE_ACTION_COMPLETED
}
