import { MY_PERMISSIONS, REGISTERS_PERMISSIONS } from '@src/constants'
import { myPermissionsGuard } from '@src/router/guards'
import { REGISTER_TYPE, ROUTES_SECURITY_OBJECTIVES } from './constants'

export default {
    name: ROUTES_SECURITY_OBJECTIVES.BASE.name,
    path: 'security-objectives',
    component: () => import('./SecurityObjectives'),
    meta: {
        pageTitle: 'SECURITY_OBJECTIVES.PAGE_TITLE',
        requiresAuth: true,
        register: REGISTER_TYPE,
        guards: [myPermissionsGuard],
        permissions: [
            MY_PERMISSIONS.SECURITY_OBJECTIVES_ACCESS,
            REGISTERS_PERMISSIONS.SECURITY_OBJECTIVES
        ]
    },
    children: [
        // This redirection is created in order to support opening links in already created Security Objectives approval tasks
        // It can just be removed after 06.02.2024.
        {
            name: 'security-objectives-help-redirection',
            path: 'help',
            redirect: to => ({
                name: ROUTES_SECURITY_OBJECTIVES.BASE.name
            })
        }
    ]
}
