<template>
    <div>
        <app-modal
            :name="modalName"
            :body="body"
            :title="title"
            :ok-title="okTitle"
            ok-only
            @ok="closeModal"
            @closed="onModalClose"
        />
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
    props: {
        text: {
            type: String,
            required: false
        },
        title: {
            type: String,
            required: false,
            default: 'COMMON.WARNING'
        },
        okTitle: {
            type: String,
            required: false,
            default: 'COMMON.OK'
        }
    },

    data: () => ({
        isModalShown: false,
        modalName: 'error-modal',
        body: ''
    }),

    computed: {
        ...mapGetters('errors', ['modalErrors'])
    },

    methods: {
        ...mapMutations('errors', {
            popModalError: 'POP_MODAL_ERROR'
        }),

        /**
         * Display error modal
         */
        displayModal (text) {
            this.body = text ?? this.body

            this.$modal.show(this.modalName)

            this.isModalShown = true
        },

        /**
         * Close modal
         */
        closeModal () {
            this.$modal.hide(this.modalName)
        },

        /**
         * Mark modal as closed and show next error
         */
        onModalClose () {
            this.isModalShown = false

            // Show next error (last error in errors array)
            this.$nextTick(() => {
                this.showLastError(this.modalErrors)
            })
        },

        /**
         * Show first next error (latest element of the stack)
         */
        showLastError (errors) {
            if (errors.length && !this.isModalShown) {
                const lastError = errors.pop()

                if (lastError) {
                    this.displayModal(lastError.message)
                }
            }
        }
    },

    watch: {
        modalErrors (errors) {
            this.showLastError(errors)
        }
    }
}
</script>
