import axios from 'axios'
import { keys, find } from 'lodash-es'

const ENDPOINTS = {
    COMPANY: '/company',
    COMPANIES: '/companies',
    COMPANY_ROLES: 'companies/:companyId/company-roles',
    COMPANY_REGULATION: '/companies/:companyId/regulations/:regulationId',
    SET_PROJECT_MANAGER: (companyId, regulationId) =>
        `/companies/${companyId}/regulations/${regulationId}/set-pm`,
    USERS: 'company/:companyId/users',
    UPDATE_HOW_TO_VIDEO_WATCHED: (companyId, memberId) =>
        `company/${companyId}/members-update/${memberId}`,
    UPDATE_STEP_BY_STEP_WIZARD_VIDEO_WATCHED: (companyId, memberId) =>
        `company/${companyId}/member/${memberId}/step_by_step_wizard_video_watched`,
    UPDATE_MY_WORK_VIDEO_WATCHED: (companyId, memberId) =>
        `company/${companyId}/member/${memberId}/my_work_video_watched`,
    UPDATE_INTRO_VIDEO_WATCHED: (companyId, memberId) =>
        `company/${companyId}/member/${memberId}/intro_video_watched`,
    UPDATE_MAINTENANCE_VIDEO_WATCHED: (companyId, memberId) =>
        `company/${companyId}/member/${memberId}/maintenance_video_watched`,
    GET_COMPANY_PERMISSION_PLAN_KEYS: companyId =>
        `companies/${companyId}/permissions`,
    CANCEL_SUBSCRIPTION: companyId => `company/${companyId}/plans/cancel`,
    COMPANY_PAYMENT_DETAILS: companyId => `companies/${companyId}/billing-info`,
    UPDATE_PROJECT_MANAGER: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/set-pm`,
    UPDATE_COMPANY_SOA_PROGRESS: companyId =>
        `company/${companyId}/soa_progress`,
    UPDATE_SCHEDULED_ONBOARDING_CALL: companyId =>
        `company/${companyId}/onboarding-call-scheduled`
}

export default class CompanyService {
    /**
     * Get all companies belonging to the currently logged in user
     *
     * @returns Promise
     */
    async getAll () {
        try {
            const response = await axios.get(ENDPOINTS.COMPANIES)
            return response.data
        } catch (error) {
            throw error
        }
    }

    /**
     * Create a new company
     * @param {Object} data
     *
     * @returns Promise
     */
    async create (data) {
        try {
            const response = await axios.post(ENDPOINTS.COMPANY, data)
            return response.data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update a company
     * @param {String|Number} id
     * @param {Object} data
     *
     * @returns Promise
     */
    async update (id, data) {
        try {
            const response = await axios.patch(
                `${ENDPOINTS.COMPANY}/${id}`,
                data
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update company member
     *
     * @param {String|Number} companyId
     * @param {String|Number} memberId
     *
     * @returns Promise
     */
    async updateMember (companyId, memberId) {
        try {
            const response = await axios.get(
                `${ENDPOINTS.UPDATE_HOW_TO_VIDEO_WATCHED(companyId, memberId)}`
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update a company member Step By Step Wizard Video Watched property
     *
     * @param {String|Number} companyId
     * @param {String|Number} memberId
     *
     * @returns Promise
     */
    async updateMemberStepByStepWizardVideoWatched (companyId, memberId) {
        try {
            const response = await axios.get(
                `${ENDPOINTS.UPDATE_STEP_BY_STEP_WIZARD_VIDEO_WATCHED(
                    companyId,
                    memberId
                )}`
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update a company member My Work Video Watched property
     *
     * @param {String|Number} companyId
     * @param {String|Number} memberId
     *
     * @returns Promise
     */
    async updateMemberMyWorkVideoWatched (companyId, memberId) {
        try {
            const response = await axios.get(
                `${ENDPOINTS.UPDATE_MY_WORK_VIDEO_WATCHED(companyId, memberId)}`
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update a company member IntroVideo Watched property
     *
     * @param {String|Number} companyId
     * @param {String|Number} memberId
     *
     * @returns Promise
     */
    async updateMemberIntroVideoWatched (companyId, memberId) {
        try {
            const response = await axios.get(
                `${ENDPOINTS.UPDATE_INTRO_VIDEO_WATCHED(companyId, memberId)}`
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update a company member IntroVideo Watched property
     *
     * @param {String|Number} companyId
     * @param {String|Number} memberId
     *
     * @returns Promise
     */
    async updateMemberMaintenanceVideoWatched (companyId, memberId) {
        try {
            const response = await axios.get(
                `${ENDPOINTS.UPDATE_MAINTENANCE_VIDEO_WATCHED(
                    companyId,
                    memberId
                )}`
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    /**
     * Submit logo image
     * @param {String|Number} companyId
     * @param {FormData} formData
     *
     * @returns {Promise}
     */
    async submitLogo (companyId, formData) {
        try {
            const response = await axios.post(
                `${ENDPOINTS.COMPANY}/${companyId}/logo`,
                formData
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    /**
     * Delete logo image
     * @param {String|Number} companyId
     *
     * @returns {Promise}
     */
    async deleteLogo (companyId) {
        try {
            await axios.delete(`${ENDPOINTS.COMPANY}/${companyId}/logo`)
        } catch (error) {
            throw error
        }
    }

    /**
     * Get a list of all available company roles
     *
     * @returns {Promise}
     */
    async getCompanyRoles (companyId) {
        try {
            const route = ENDPOINTS.COMPANY_ROLES.replace(
                ':companyId',
                companyId
            )

            const response = await axios.get(route)
            return response.data
        } catch (error) {
            throw error
        }
    }

    async updateCompanyRegulation (companyId, regulationId, payload) {
        try {
            const route = ENDPOINTS.COMPANY_REGULATION.replace(
                ':companyId',
                companyId
            ).replace(':regulationId', regulationId)
            const { data } = await axios.patch(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    async getUsers (companyId) {
        try {
            const route = ENDPOINTS.USERS.replace(':companyId', companyId)
            const { data } = await axios.get(route)

            return keys(data).map(key => {
                return data[key]
            })
        } catch (err) {
            throw err
        }
    }

    /**
     * Cancel subscription API call
     *
     * @param companyId
     * @returns {Promise<T>}
     */
    async cancelSubscription (companyId, payload) {
        try {
            const { data } = await axios.post(
                ENDPOINTS.CANCEL_SUBSCRIPTION(companyId),
                payload
            )

            return data
        } catch (err) {
            throw err
        }
    }

    async getCompanyPermissions (companyId) {
        try {
            const response = await axios.get(
                ENDPOINTS.GET_COMPANY_PERMISSION_PLAN_KEYS(companyId)
            )

            return response.data
        } catch (error) {
            throw error
        }
    }

    async getPaymentDetails (companyId) {
        try {
            const response = await axios.get(
                ENDPOINTS.COMPANY_PAYMENT_DETAILS(companyId)
            )

            return response.data
        } catch (error) {
            throw error
        }
    }

    async updateProjectManagerRequest (
        companyId,
        regulationId,
        projectManagerEmail
    ) {
        try {
            const response = await axios.post(
                ENDPOINTS.UPDATE_PROJECT_MANAGER(companyId, regulationId),
                { email: projectManagerEmail }
            )

            return response.data
        } catch (error) {
            throw error
        }
    }

    async updateCompanySoaProgress (companyId, value) {
        try {
            const response = await axios.patch(
                ENDPOINTS.UPDATE_COMPANY_SOA_PROGRESS(companyId),
                {
                    value: value
                }
            )

            return response.data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update scheduled onboarding call
     * @param companyId
     *
     * @returns Promise
     */
    async updateScheduledOnboardingCall (companyId) {
        try {
            const response = await axios.post(
                ENDPOINTS.UPDATE_SCHEDULED_ONBOARDING_CALL(companyId)
            )

            return response.data
        } catch (error) {
            throw error
        }
    }
}

export const companyService = new CompanyService()
