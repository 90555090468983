<template>
    <draggable
        class="list-group"
        :class="widget ? 'cf-u-p-sm' : 'cf-u-pv-sm'"
        ghost-class="ghost"
        handle=".cf-c-draggable-item__drag-area"
        group="adaptations"
        :value="list"
        @change="handleChange"
        @start="dragging = true"
        @end="dragging = false"
    >
        <draggable-item v-for="item in items" :key="item.id" class="cf-u-mb-sm">
            <div
                class="cf-c-adaptation-content"
                :class="{ 'cf-c-adaptation-content--lg': !widget }"
                :data-related-document="item.related_document || ''"
            >
                <div class="cf-c-adaptation-content__main">
                    <span class="cf-c-adaptation-content__ctn cf-u-pr-default">
                        {{ item.title }}
                    </span>
                    <div
                        class="cf-c-adaptation-content__ctn cf-u-flex-align-center cf-u-pl-default"
                    >
                        {{ item.reference_to }}
                    </div>
                    <span>{{ items.reference_to }}</span>
                </div>
                <div class="cf-c-adaptation-content__action">
                    <app-button
                        class="cf-u-color-primary"
                        variation="clear"
                        :disabled="disabled"
                        @click="$emit('item-edited', item)"
                    >
                        <app-icon icon="pencil-filled-icon" :size="iconSize" />
                    </app-button>
                    <app-button
                        class="cf-u-color-gray-02"
                        variation="clear"
                        :disabled="disabled"
                        @click="$emit('item-deleted', item)"
                    >
                        <app-icon icon="delete-icon" :size="iconSize" />
                    </app-button>
                </div>
            </div>
        </draggable-item>
    </draggable>
</template>

<script>
import Draggable from 'vuedraggable'
import { includesSearchTerm } from '@/services/utils/functions'
import { getItemTitle } from '@/services/utils/documentation-review'

export default {
    components: {
        Draggable
    },

    props: {
        section: {
            type: Object,
            required: true
        },
        titleQuery: {
            type: String,
            default: ''
        },
        widget: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        items () {
            if (this.titleQuery) {
                return this.section.items.filter(item =>
                    includesSearchTerm(item.title, this.titleQuery)
                )
            }

            return this.section.items
        },

        list: {
            get () {
                return this.section.items
            },
            set () {}
        },

        iconSize () {
            return this.widget ? '' : 'md'
        }
    },

    methods: {
        getItemTitle,

        handleChange (event) {
            if (event.moved) {
                this.$emit('item-reordered', {
                    item: event.moved.element,
                    oldIndex: event.moved.oldIndex,
                    newIndex: event.moved.newIndex
                })
                return
            }

            if (event.added) {
                this.$emit('item-added', {
                    item: event.added.element,
                    newIndex: event.added.newIndex,
                    sectionId: this.section.id
                })
            }
        }
    }
}
</script>
