<template>
    <div v-if="label" class="cf-c-pill">
        <span class="cf-c-pill__text">{{ label }}</span>
        <div v-if="removable" class="cf-c-pill__action">
            <app-button
                size="sm"
                class="cf-u-full-size cf-u-radius-pill"
                variation="clear"
                @click="$emit('remove')"
            >
                <app-icon icon="close-icon" size="xs" />
            </app-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },

        removable: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>
