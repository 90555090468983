import { mapGetters } from 'vuex'
import { find, keys, includes, get, isNil, isArray, isEmpty } from 'lodash-es'
import { EventBus } from '@/EventBus'

import {
    CONDITION_DICTIONARY,
    DISPLAY,
    STANDARD_TRANSLATION_KEYS,
    INPUTS
} from '@/constants'

import {
    convertArrayOfObjectsToString,
    fromJson
} from '@/services/utils/functions'

const INPUT_VARIABLE_IN_DESCRIPTION = /\*\*\*(.*?)\|(.*?)\*\*\*/gm

export default {
    components: {
        'number-input': () =>
            import('@/components/regulation/inputs/NumberInput'),
        'number-input-new': () =>
            import('@/components/regulation/inputs/NumberInputNew'),
        'dynamic-select-with-predefined-option': () =>
            import(
                '@/components/regulation/inputs/DynamicSelectWithPredefinedOption'
            ),
        'dynamic-select-with-predefined-option-new': () =>
            import('@/components/regulation/inputs/DynamicSelect'),
        'document-upload': () =>
            import('@/components/regulation/inputs/DocumentUpload'),
        'text-input': () => import('@/components/regulation/inputs/TextInput'),
        'multiline-input': () =>
            import('@/components/regulation/inputs/MultilineInput'),
        'dynamic-multiselect': () =>
            import('@/components/regulation/inputs/DynamicMultiselect'),
        'generic-text': () =>
            import('@/components/regulation/inputs/GenericText'),
        'video-text': () => import('@/components/regulation/inputs/VideoText'),
        'document-classification': () =>
            import('@/components/regulation/inputs/DocumentClassification'),
        'radio-input': () =>
            import('@/components/regulation/inputs/RadioInput'),
        'step-sync': () => import('@/components/regulation/inputs/StepSync'),
        'company-prior-documents-conditional-text': () =>
            import(
                '@/components/regulation/inputs/CompanyPriorDocumentsConditionalText'
            ),
        'step-awareness': () =>
            import('@/components/regulation/inputs/StepAwareness'),
        'template-document': () =>
            import('@/components/regulation/inputs/TemplateDocumentInput'),
        'step-training': () =>
            import('@/components/regulation/inputs/StepTraining'),
        'checkbox-group-input-list': () =>
            import('@/components/regulation/inputs/CheckboxGroupInputList'),
        'checkbox-group-input-list-new': () =>
            import('@/components/regulation/inputs/DynamicMultiselectNew'),
        'radio-group-input-list': () =>
            import('@/components/regulation/inputs/RadioGroupInputList'),
        'radio-group-input-list-new': () =>
            import('@/components/regulation/inputs/DynamicSelect'),
        'table-input': () =>
            import('@/components/regulation/inputs/InputDescription'),
        'one-per-line-input': () =>
            import('@/components/regulation/inputs/OnePerLineInput'),
        'company-member-radio-input': () =>
            import('@/components/regulation/inputs/CompanyMemberRadioInput'),
        'company-member-radio-input-picker': () =>
            import(
                '@/components/regulation/inputs/CompanyMemberRadioInputPicker'
            ),
        'checkbox-input': () =>
            import('@/components/regulation/inputs/CheckboxInput'),
        'radio-input-with-text-entry': () =>
            import('@/components/regulation/inputs/RadioInputWithTextEntry'),
        'user-defined-checkbox-input': () =>
            import('@/components/regulation/inputs/UserDefinedCheckboxInput'),
        'single-job-title-input': () =>
            import('@/components/regulation/inputs/SingleJobTitleInput')
    },

    data () {
        return {
            humanReadableInputsMap: {
                [INPUTS.DYNAMIC_SELECT_WITH_PREDEFINED_OPTION]: this
                    .getCompanyMemberInformation,
                [INPUTS.DYNAMIC_MULTISELECT]: convertArrayOfObjectsToString,
                [INPUTS.RADIO_GROUP_INPUT_LIST]: this
                    .getCompanyMemberInformation,
                [INPUTS.NUMBER_INPUT]: this.getIntervalInformation,
                [INPUTS.DOCUMENT_CLASSIFICATION]: this
                    .getClassificationLevelInformation,
                [INPUTS.CHECKBOX_GROUP_INPUT_LIST]: convertArrayOfObjectsToString
            }
        }
    },

    beforeDestroy () {
        this.watcher && this.watcher.unwatch && this.watcher.unwatch()
    },

    computed: {
        ...mapGetters('errors', ['hasErrors', 'firstError']),
        ...mapGetters('company', ['company']),

        standardName () {
            return this.$route.params.standard
        }
    },

    methods: {
        fromJson,

        setValue (field, value) {
            this.form[field] = value

            if (
                !!this.handleChangeOfDocument &&
                field === `step_own_document_${this.getStepNumber()}`
            ) {
                this.handleChangeOfDocument(value)
            }
        },

        translateInput (input) {
            let label = input.label ? input.label : ''
            if (this.$te(label)) {
                label = this.$t(input.label)
            }

            let helpText = input.help_text ? input.help_text : ''

            if (this.$te(helpText)) {
                helpText = this.$t(input.help_text)
            }

            return {
                ...input,
                label: this.injectFieldValues(label),
                help_text: this.injectFieldValues(helpText)
            }
        },

        injectFieldValues (text) {
            let fieldValueMatch = INPUT_VARIABLE_IN_DESCRIPTION.exec(text)
            let output = text
            while (fieldValueMatch != null) {
                let extractedValue = null
                if (
                    !isNil(this.form[fieldValueMatch[1]]) &&
                    this.form[fieldValueMatch[1]] !== ''
                ) {
                    extractedValue = this.parseInputValueByType(
                        fieldValueMatch[1],
                        this.form[fieldValueMatch[1]],
                        fieldValueMatch[2]
                    )
                } else {
                    extractedValue = this.translateValueNotFoundMessage(
                        fieldValueMatch[1]
                    )
                }

                output = output.replace(fieldValueMatch[0], extractedValue)
                fieldValueMatch = INPUT_VARIABLE_IN_DESCRIPTION.exec(output)
            }

            return output
        },

        translateValueNotFoundMessage (inputName) {
            return `[${this.$t(
                'DOCUMENT_WIZARD.VALUE_OF_FIELD_NOT_DEFINED'
            )} ${inputName}]`
        },

        parseInputValueByType (inputName, inputValue, inputType) {
            let parsedInputValue = ''
            try {
                parsedInputValue = JSON.parse(inputValue)
            } catch (e) {
                parsedInputValue = inputValue
            }
            if (isArray(parsedInputValue) && inputType === 'normal') {
                return this.parseArrayOfInputValues(parsedInputValue, inputName)
            }
            if (inputType === 'radio') {
                return this.parseRadioInput(inputName, inputValue)
            }
            if (inputType === 'member') {
                return this.parseMemberInput(inputValue)
            }

            return parsedInputValue
        },

        parseArrayOfInputValues (parsedInputValue, inputName) {
            if (isEmpty(parsedInputValue)) {
                return this.translateValueNotFoundMessage(inputName)
            }
            return parsedInputValue
                .map(inputValue => {
                    if (typeof inputValue !== 'object') {
                        return inputValue
                    }

                    if (!isNil(inputValue.label)) {
                        return inputValue.label
                    }

                    return inputValue.value
                })
                .join(', ')
        },

        parseRadioInput (inputName, inputValue) {
            const selectedInput = find(
                this.inputs,
                input => input.name === inputName
            )
            if (isNil(selectedInput)) {
                return ''
            }

            const selectedChoice = find(
                selectedInput.default_choices,
                choice => choice.value === inputValue
            )
            if (isNil(selectedChoice)) {
                return ''
            }

            if (this.$te(selectedChoice.key)) {
                return this.$t(selectedChoice.key)
            }

            return selectedChoice.key
        },

        parseMemberInput (inputValue) {
            const selectedMember = find(
                this.company.members,
                member => member.id === parseInt(inputValue)
            )
            if (isNil(selectedMember)) {
                return ''
            }
            return selectedMember.job_title
        },

        mapDefaultChoices (input) {
            return input.default_choices.map(choice => ({
                ...choice,
                label: get(
                    choice,
                    'key_value',
                    this.$te(choice.key) ? this.$t(choice.key) : choice.key
                )
            }))
        },

        extractConditions (inputs) {
            let dictionary = {}

            inputs.forEach(input => {
                const { descriptor = null } = input

                if (!descriptor) return

                const { post_conditions: postConditions = {} } = descriptor

                keys(postConditions).forEach(condition => {
                    switch (condition) {
                        case CONDITION_DICTIONARY.SHOW:
                            keys(postConditions[condition]).forEach(field => {
                                const formattedCondition = {
                                    name: input.name,
                                    value: postConditions[condition][field]
                                }

                                dictionary = {
                                    ...dictionary,
                                    [field]: dictionary[field]
                                        ? [
                                              ...dictionary[field],
                                              formattedCondition
                                          ]
                                        : [formattedCondition]
                                }
                            })
                            break
                        default:
                            break
                    }
                })
            })

            this.setUpWatchers(dictionary)
        },

        setUpWatchers (dictionary) {
            keys(dictionary).forEach(key => {
                dictionary[key].forEach(field => {
                    this.checkCurrentConditionsAndToggle(dictionary[key], key)
                    this.watcher = this.$watch(`form.${field.name}`, () => {
                        this.checkCurrentConditionsAndToggle(
                            dictionary[key],
                            key
                        )
                    })
                })
            })
        },

        getStepNumber () {
            const fields = keys(this.form)

            if (!fields || !fields.length) {
                return -1
            }

            const parts = fields[0].split('_')
            return parseInt(parts[parts.length - 1])
        },

        getInputByName (name) {
            return find(this.inputs, input => includes(input.name, name))
        },

        handleCheckboxGroupInputList (input) {
            if (input.value !== null) {
                let value = ''
                try {
                    value = JSON.parse(input.value)
                } catch (e) {
                    value = input.value
                }
                value = value.map(reviewer => reviewer.label).join(', ')

                return value
            }
        },

        getInputValueInHumanReadableFormat (input) {
            const value = this.getInputValueFromJson(input)
            const { descriptor } = input
            const formatFunction = this.humanReadableInputsMap[
                descriptor.field_type
            ]

            if (input.name === 'owner' && input.descriptor?.data.length > 1) {
                const member = input.descriptor?.data[1].find(
                    m => Number(m.value) === Number(input.value)
                )

                if (member) {
                    return member.label
                }
            }

            // The [data] object lists the functions that are called depending on the type.
            // A number of them have two parameters and one of them has a default value.
            // But the function type that handles the INPUTS.DOCUMENT_CLASSIFICATION type has a specific array
            // as the second parameter, so for this type the function call is made exactly like this.
            const stringifiedValue =
                formatFunction &&
                descriptor.field_type === INPUTS.DOCUMENT_CLASSIFICATION
                    ? formatFunction(value, input.descriptor.data)
                    : formatFunction(value)

            return stringifiedValue || value || this.$t('COMMON.NOT_AVAILABLE')
        },

        checkCurrentConditionsAndToggle (conditions, key) {
            const passesConditions = conditions.reduce(
                (accumulator, current) =>
                    current.value != this.form[current.name]
                        ? false
                        : accumulator, // eslint-disable-line
                true
            )

            this.toggleElementDisplayClass(
                key,
                passesConditions ? DISPLAY.BLOCK : DISPLAY.NONE,
                !passesConditions ? DISPLAY.BLOCK : DISPLAY.NONE
            )
        },

        toggleElementDisplayClass (field, classToAdd, classToRemove) {
            field = `${field}_${this.getStepNumber()}`

            if (!this.$refs[field]) return

            this.$refs[field].classList.add(classToAdd)
            this.$refs[field].classList.remove(classToRemove)
        },

        getInputValue (input) {
            if (this.form && this.form[input.name] !== undefined) {
                const value = this.injectFieldValues(this.form[input.name])

                EventBus.$emit('dynamic-table-input-translated', {
                    input,
                    value
                })

                return value
            }

            return get(input, 'value.value', get(input, 'value', null))
        },

        getInputValueFromJson (input) {
            const value = this.getInputValue(input)

            return value ? this.fromJson(value) : ''
        },

        renderInput (component, input, currentStep = null) {
            const Component = component

            return (
                <div ref={input.name} key={input.name}>
                    <Component
                        ref={`input-${input.name}`}
                        input={input}
                        disabled={this.inputsDisabled}
                        value={this.getInputValue(input)}
                        standard-name={
                            STANDARD_TRANSLATION_KEYS[this.standardName]
                        }
                        should-show-content={this.shouldShowInputsContent}
                        is-open-document-wizard-shown={
                            !!this.showOpenDocumentWizardButton
                        }
                        form-value={this.form[input.name]}
                        step-id={currentStep ? currentStep.id : null}
                        form={this.form}
                        step-number={this.getStepNumber()}
                        on-input={(field, value) => {
                            this.setValue(field, value)
                        }}
                        on-blur={() => {
                            this.submitsOnBlur && this.submit()
                        }}
                        on-start-wizard={() =>
                            this.redirectToDocumentWizard &&
                            this.redirectToDocumentWizard()
                        }
                    />
                </div>
            )
        },

        getCompanyMemberInformation (id) {
            const companyMember = find(this.company.members, { id })

            if (companyMember?.job_title) {
                return companyMember.job_title
            }

            const nameOrEmail =
                companyMember?.user?.full_name || companyMember?.email

            return nameOrEmail || this.$t('COMMON.NOT_AVAILABLE')
        },

        getClassificationLevelInformation (id, classificationLevels) {
            const classificationLevel = find(
                classificationLevels,
                classificationLevel => classificationLevel.value === id
            )

            return id && classificationLevel
                ? classificationLevel.label
                : this.$t('COMMON.NOT_AVAILABLE')
        },

        getIntervalInformation (value) {
            return value
                ? `${value} ${this.$t('COMMON.MONTHS')}`
                : this.$t('COMMON.NOT_AVAILABLE')
        }
    }
}
