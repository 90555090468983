<template>
    <div class="cf-c-labeled-checkboxes">
        <template v-for="(input, index) in inputs">
            <div
                v-if="!input.hidden"
                :key="index"
                class="cf-c-labeled-checkboxes__item"
            >
                <labeled-checkbox
                    :value="isChecked(input)"
                    :label="input.label"
                    :disabled="disabled"
                    @input="value => handleChange(value, input)"
                />

                <div
                    v-if="input.isEditable"
                    class="cf-c-multiselect cf-c-multiselect--editable cf-u-mt-sm"
                >
                    <v-multiselect
                        v-model="selectedOptions"
                        track-by="label"
                        label="label"
                        open-direction="bottom"
                        :disabled="isDisabledSelectInput || disabled"
                        :placeholder="placeholder"
                        :options="options"
                        :multiple="true"
                        taggable
                        :deselect-label="deselectLabel"
                        :select-label="selectLabel"
                        :tag-placeholder="tagPlaceholder"
                        @tag="addSelectOption"
                        @input="handleSelectInput"
                    >
                        <template slot="noOptions">
                            {{ noOptionsPlaceholder }}
                        </template>
                    </v-multiselect>
                </div>
            </div>
        </template>
        <span v-if="hasError" class="cf-c-error-msg">{{ errorMessage }}</span>
        <slot></slot>
    </div>
</template>

<script>
import { find, some } from 'lodash-es'

export default {
    props: {
        inputs: {
            type: Array,
            required: true
        },

        options: {
            type: Array,
            required: true
        },

        name: {
            type: String,
            required: true
        },

        selectedValues: {
            type: Array,
            required: true
        },

        placeholder: {
            type: String,
            required: true
        },

        noOptionsPlaceholder: {
            type: String,
            required: true
        },

        optionValidation: {
            type: RegExp,
            required: true
        },

        errorMessage: {
            type: String,
            required: true
        },

        disabled: {
            type: Boolean,
            required: true,
            default: false
        },
        deselectLabel: {
            type: String,
            required: false
        },
        selectLabel: {
            type: String,
            required: false
        },
        tagPlaceholder: {
            type: String,
            required: false
        }
    },

    data () {
        return {
            checkedInputs: [],
            selectedOptions: [],
            canEditSelectInput: null,
            hasError: false
        }
    },

    computed: {
        isDisabledSelectInput () {
            return !this.canEditSelectInput
        },

        outputValues () {
            const selectedOptions = this.canEditSelectInput
                ? this.selectedOptions
                : []

            return [...this.checkedInputs, ...selectedOptions].map(item => {
                return {
                    label: item.label,
                    value: item.value
                }
            })
        }
    },

    watch: {
        selectedValues () {
            this.checkedInputs = this.selectedValues.filter(
                value => some(this.inputs, value) && !value.isEditable
            )

            this.selectedOptions = this.selectedValues.filter(value =>
                some(this.options, value)
            )

            this.canEditSelectInput = !!this.selectedOptions.length
        }
    },

    created () {
        this.checkedInputs = this.selectedValues.filter(
            value => some(this.inputs, value) && !value.isEditable
        )

        this.selectedOptions = this.selectedValues.filter(value =>
            some(this.options, value)
        )

        this.canEditSelectInput = !!this.selectedOptions.length
    },

    methods: {
        handleChange (isChecked, input) {
            this.hasError = false

            if (input.isEditable) {
                this.canEditSelectInput = !this.canEditSelectInput
                if (!this.selectedOptions.length) return
            } else if (isChecked) {
                this.checkedInputs.push(input)
            } else {
                const item = find(
                    this.checkedInputs,
                    item => item.value === +input.value
                )
                this.checkedInputs.splice(this.checkedInputs.indexOf(item), 1)
            }

            this.$emit('change', this.outputValues)
        },

        isChecked (input) {
            return input.isEditable
                ? this.canEditSelectInput
                : some(this.checkedInputs, {
                      value: input.value,
                      label: input.label
                  })
        },

        addSelectOption (value) {
            this.hasError = false

            const option = {
                label: value,
                value: value
            }

            if (!this.isOptionValid(option)) {
                this.hasError = true

                return
            }

            this.options.push(option)
            this.selectedOptions.push(option)

            this.$emit('change', this.outputValues)
        },

        isOptionValid (option) {
            return this.optionValidation.test(option.value)
        },

        handleSelectInput () {
            this.hasError = false
            this.$emit('change', this.outputValues)
        }
    }
}
</script>
