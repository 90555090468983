import Vue from 'vue'
import { upperFirst, camelCase } from 'lodash-es'

function registerComponents (requireComponent) {
    requireComponent.keys().forEach(fileName => {
        const componentConfig = requireComponent(fileName)

        const componentName = upperFirst(
            camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, ''))
        )

        Vue.component(componentName, componentConfig.default || componentConfig)
    })
}

const shared = require.context('./shared', false, /.vue$/)

const svgs = require.context('../assets/icons/svg', false, /.svg/)

registerComponents(shared)
registerComponents(svgs)
