export default {
    data: () => ({
        upperLevelCollapsibleVisibility: []
    }),
    mounted () {
        const scrollMonitor = require('scrollmonitor')
        const interval = setInterval(() => {
            if (
                this.$refs.upperLevelCollapsible &&
                this.$parent.$el.parentElement
            ) {
                const containerMonitor = scrollMonitor.createContainer(
                    this.$parent.$el.parentElement
                )
                const watchers = this.$refs.upperLevelCollapsible.map(x =>
                    containerMonitor.create(x.$el || x)
                )
                const length =
                    this.assetsByCategory?.length ||
                    this.sortedRisks?.length ||
                    0

                for (let i = 0; i < length; i++) {
                    this.$set(this.upperLevelCollapsibleVisibility, i, false)

                    if (watchers[i]) {
                        watchers[i].enterViewport(() => {
                            this.$set(
                                this.upperLevelCollapsibleVisibility,
                                i,
                                true
                            )
                        })
                    }
                }
                clearInterval(interval)
            }
        }, 1000)
    }
}
