import {
    REGISTER_WIZARD_ROUTES,
    REGISTER_TYPES,
    MY_PERMISSIONS,
    REGISTERS_PERMISSIONS
} from '@/constants'
import { myPermissionsGuard } from '@src/router/guards'

export default {
    name: REGISTER_WIZARD_ROUTES.RISKS.INDEX.name,
    component: () =>
        import('@/containers/RegisterWizard/RegisterOfRisks/RegisterOfRisks'),
    props: true,
    path: 'risks',
    meta: {
        requiresAuth: true,
        register: REGISTER_TYPES.RISKS,
        shouldSkipRouteInHistory: true
    },
    children: [
        {
            name: REGISTER_WIZARD_ROUTES.RISKS.COMPLETED_REGISTER.name,
            component: () =>
                import(
                    '@/containers/RegisterWizard/RegisterOfRisks/RegisterOfRisksCompleted'
                ),
            path: 'completed',
            meta: {
                requiresAuth: true,
                register: REGISTER_TYPES.RISKS
            },
            props: true,
            redirect: to => ({
                name: REGISTER_WIZARD_ROUTES.RISKS.COMPLETED_WIZARD.name,
                params: { ...to.params }
            }),
            children: [
                {
                    path: 'wizard',
                    name: REGISTER_WIZARD_ROUTES.RISKS.COMPLETED_WIZARD.name,
                    component: () =>
                        import(
                            '@/components/registers/risks/completed-register-tabs/WizardTab'
                        ),
                    props: true,
                    meta: {
                        pageTitle: 'REGISTERS.RISKS.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.RISKS,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.RISK_REGISTER_ACCESS,
                            REGISTERS_PERMISSIONS.RISK_REGISTER
                        ]
                    }
                },
                {
                    path: 'properties',
                    name:
                        REGISTER_WIZARD_ROUTES.RISKS.COMPLETED_PROPERTIES.name,
                    component: () =>
                        import('@/components/registers/PropertiesTab'),
                    props: true,
                    meta: {
                        pageTitle: 'REGISTERS.RISKS.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.RISKS,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.RISK_REGISTER_ACCESS,
                            REGISTERS_PERMISSIONS.RISK_REGISTER
                        ]
                    }
                },
                {
                    path: 'discussions',
                    name:
                        REGISTER_WIZARD_ROUTES.RISKS.COMPLETED_DISCUSSIONS.name,
                    component: () =>
                        import('@/components/registers/Discussions'),
                    props: true,
                    meta: {
                        pageTitle: 'REGISTERS.RISKS.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.RISKS,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.RISK_REGISTER_ACCESS,
                            REGISTERS_PERMISSIONS.RISK_REGISTER
                        ]
                    }
                }
            ]
        },
        {
            name: REGISTER_WIZARD_ROUTES.RISKS.UNCOMPLETED_REGISTER.name,
            component: () =>
                import(
                    '@/containers/RegisterWizard/RegisterOfRisks/RegisterOfRisksUncompleted'
                ),
            path: 'uncompleted',
            props: true,
            redirect: to => ({
                name: REGISTER_WIZARD_ROUTES.RISKS.WIZARD.name,
                params: { ...to.params }
            }),
            meta: {
                requiresAuth: true,
                register: REGISTER_TYPES.RISKS
            },
            children: [
                {
                    path: 'wizard',
                    name: REGISTER_WIZARD_ROUTES.RISKS.WIZARD.name,
                    component: () =>
                        import(
                            '@/components/registers/risks/uncompleted-register-tabs/WizardTab'
                        ),
                    props: true,
                    meta: {
                        pageTitle: 'REGISTERS.RISKS.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.RISKS,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.RISK_REGISTER_ACCESS,
                            REGISTERS_PERMISSIONS.RISK_REGISTER
                        ]
                    }
                },
                {
                    path: 'properties',
                    name: REGISTER_WIZARD_ROUTES.RISKS.PROPERTIES.name,
                    component: () =>
                        import('@/components/registers/PropertiesTab'),
                    props: true,
                    meta: {
                        pageTitle: 'REGISTERS.RISKS.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.RISKS,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.RISK_REGISTER_ACCESS,
                            REGISTERS_PERMISSIONS.RISK_REGISTER
                        ]
                    }
                },
                {
                    path: 'discussions',
                    name: REGISTER_WIZARD_ROUTES.RISKS.DISCUSSIONS.name,
                    component: () =>
                        import('@/components/registers/Discussions'),
                    props: true,
                    meta: {
                        pageTitle: 'REGISTERS.RISKS.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.RISKS,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.RISK_REGISTER_ACCESS,
                            REGISTERS_PERMISSIONS.RISK_REGISTER
                        ]
                    }
                }
            ]
        }
    ]
}
