<template>
    <div class="cf-c-data-card-section">
        <h5 class="cf-c-data-card-section__title">
            {{ title }}
        </h5>
        <p class="cf-c-data-card-section__ctn">
            <span
                v-if="!multipleContentLines"
                class="cf-c-data-card-section__ctn-item"
                :class="textColorStyles"
            >
                {{ content }}
            </span>
            <span
                v-for="(item, index) in content"
                v-else
                :key="index"
                class="cf-c-data-card-section__ctn-item"
            >
                {{ item }}
            </span>
        </p>
    </div>
</template>

<script>
import { isArray } from 'lodash-es'

export default {
    props: {
        title: {
            type: String,
            required: true
        },

        content: {
            type: [String, Array, Number],
            required: true
        },

        textColor: {
            type: String,
            required: false,
            default: ''
        }
    },

    computed: {
        textColorStyles () {
            if (this.textColor.length) {
                return 'cf-u-color-' + this.textColor
            }

            return ''
        },

        multipleContentLines () {
            return isArray(this.content) && this.content.length
        }
    }
}
</script>
