const COMPANY_ROLES = {
    MAIN_EXECUTIVE: { key: 'main_executive' },
    HEAD_OF_IT: { key: 'head_of_it' },
    SECURITY_OFFICER: { key: 'security_officer' },
    LEGAL_ADVISOR: { key: 'legal_advisor' },
    COMPLIANCE_OFFICER: { key: 'compliance_officer' },
    HR_OFFICER: { key: 'hr_officer' },
    OFFICE_MANAGER: { key: 'office_manager' },
    PURCHASING_MANAGER: { key: 'purchasing_manager' },
    INTERNAL_AUDITOR: { key: 'internal_auditor' },
    MARKETING_OFFICER: { key: 'marketing_officer' },
    FINANCIAL_OFFICER: { key: 'financial_officer' },
    RESOURCE_APPROVER: { key: 'resource_approver' },
    PROJECT_MANAGER: { key: 'project_manager' }
}

const COMPANY_ROLE_NOT_DEFINED = {
    key: 'role_not_defined'
}

const STEP_ASSIGNEE = 'step_assignee'
const ROLE_ANY = '*'

export { COMPANY_ROLES, COMPANY_ROLE_NOT_DEFINED, STEP_ASSIGNEE, ROLE_ANY }
