<template>
    <section
        :class="[
            'cf-c-main-section ' + classes,
            { 'cf-c-main-section--subsection': subsection }
        ]"
    >
        <div>
            <div :class="'cf-c-main-section__top-wrapper ' + topWrapperClasses">
                <div class="cf-c-main-section__title-wrapper">
                    <h4
                        v-if="label && !subsection"
                        :class="'cf-c-main-section__title ' + headingClasses"
                    >
                        {{ label }}
                        <span
                            v-if="requiredLabelSymbol"
                            class="cf-u-color-danger"
                            >*</span
                        >
                    </h4>
                    <h5
                        v-if="label && subsection"
                        class="cf-c-main-section__title"
                    >
                        {{ label }}
                    </h5>
                </div>
                <div v-if="rightIcon">
                    <app-button
                        v-if="appBtnCondition"
                        :class="appBtnClasses"
                        @click="handleClick"
                    >
                        <img :src="rightIconSrc" :class="rightIconClasses" />
                    </app-button>
                </div>
            </div>
        </div>
        <p
            v-if="description"
            class="cf-c-main-section__desc"
            v-html="description"
        ></p>
        <div
            v-if="helpText"
            class="cf-c-main-section cf-u-bg-primary-light cf-u-color-primary cf-u-p-sm cf-u-radius"
            v-html="helpText"
        ></div>
        <div
            v-if="$slots.default"
            :class="'cf-c-main-section__ctn ' + ctnClasses"
        >
            <slot></slot>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },

        description: {
            type: String,
            required: false,
            default: ''
        },

        classes: {
            type: String,
            required: false,
            default: ''
        },

        subsection: {
            type: Boolean,
            required: false,
            default: false
        },

        headingClasses: {
            type: String,
            required: false,
            default: ''
        },

        topWrapperClasses: {
            type: String,
            required: false,
            default: ''
        },

        appBtnClasses: {
            type: String,
            required: false,
            default: ''
        },

        appBtnCondition: {
            type: Boolean,
            required: false,
            default: true
        },

        rightIcon: {
            type: Boolean,
            required: false,
            default: false
        },

        rightIconSrc: {
            type: String,
            required: false,
            default: ''
        },

        rightIconClasses: {
            type: String,
            required: false,
            default: ''
        },

        ctnClasses: {
            type: String,
            required: false,
            default: ''
        },

        requiredLabelSymbol: {
            type: Boolean,
            required: false,
            default: false
        },

        helpText: {
            type: String,
            required: false,
            default: ''
        }
    },
    methods: {
        handleClick () {
            return this.$emit('app-btn-clicked')
        }
    }
}
</script>
