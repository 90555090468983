import Vue from 'vue'
import { i18n } from '@/i18n'
import { first, each, keys, find, isUndefined } from 'lodash-es'
import { STATUS_CODES, TOAST_TYPES } from '@/constants'
import { config } from '@/config'
import { TOAST_DURATIONS } from '../../constants'

function getInitialState () {
    return {
        validationErrors: {},
        modalErrors: []
    }
}

const state = getInitialState()

const actions = {
    handleError ({ commit }, error) {
        handleError(commit, error, true)
    },

    handleErrorNoToast ({ commit }, error) {
        handleError(commit, error, false)
    },

    clearAllErrors ({ commit }) {
        commit('CLEAR_ALL_VALIDATION_ERRORS')
    },

    clearErrors ({ commit }, field) {
        commit('CLEAR_VALIDATION_ERRORS', field)
    },

    clearErrorsByIndex ({ commit }, payload) {
        commit('CLEAR_VALIDATION_ERRORS_BY_INDEX', payload)
    },

    addError ({ commit }, error) {
        commit('ADD_VALIDATION_ERROR', error)
    },

    addCustomModalError ({ commit }, message) {
        commit('ADD_MODAL_ERROR', { message })
    }
}

const mutations = {
    ADD_VALIDATION_ERROR (state, error) {
        state.validationErrors = {
            ...state.validationErrors,
            error
        }
    },

    CLEAR_ALL_VALIDATION_ERRORS (state) {
        state.validationErrors = {}
    },

    SET_VALIDATION_ERRORS (state, errors) {
        state.validationErrors = errors
    },

    CLEAR_VALIDATION_ERRORS (state, field) {
        if (state.validationErrors[field]) {
            delete state.validationErrors[field]
            // Refresh the object in order to be reactive
            state.validationErrors = { ...state.validationErrors }
        }
    },

    CLEAR_VALIDATION_ERRORS_BY_INDEX (state, { index, field, arr }) {
        if (index === -1) {
            return
        }

        let identifier = !isUndefined(arr)
            ? `${arr}.${index}.${field}`
            : `${index}.${field}`

        if (state.validationErrors[identifier]) {
            delete state.validationErrors[identifier]
            state.validationErrors = { ...state.validationErrors }
        }
    },

    ADD_MODAL_ERROR (state, error) {
        state.modalErrors.push({
            ...error
        })
    },

    RESET_ERRORS (state) {
        const s = getInitialState()
        each(keys(state), key => {
            state[key] = s[key]
        })
    }
}

const getters = {
    validationErrors: state => state.validationErrors,
    hasAnyError: state => !!keys(state.validationErrors).length,
    hasErrors: (state, getters) => field =>
        !!getters.validationErrors && !!getters.validationErrors[field],
    firstError: (state, getters) => field =>
        getters.hasErrors(field) ? first(getters.validationErrors[field]) : '',
    firstErrorByIndex: state => (index, field) => {
        if (index === -1) {
            return ''
        }

        const { validationErrors } = state

        const key = find(keys(validationErrors), key => {
            const [arr, i, param] = key.split('.')

            return !isUndefined(param)
                ? parseInt(i, 10) === index && param === field
                : parseInt(arr, 10) === index && i === field
        })

        return key && validationErrors[key] && validationErrors[key].length
            ? first(validationErrors[key])
            : ''
    },
    modalErrors: state => state.modalErrors
}

/**
 * Checks if is thrown message type of Internal Server Error
 *
 * @param {Object} error
 *
 * @returns {Boolean}
 */
const isInternalServerError = error => {
    return (
        !error.response ||
        error.response.status === STATUS_CODES.INTERNAL_SERVER_ERROR
    )
}

/**
 * Displays error depending on error display mode
 *
 * @param {Function} commit
 * @param {Object} errorData
 */
const showError = (commit, errorData) => {
    switch (errorData.displayMode) {
        default:
        case 'toast':
            // Show error in toast
            showErrorToast(errorData.error || errorData.message)
            break

        case 'modal':
            // Show error in modal
            commit('ADD_MODAL_ERROR', errorData)
            break
    }
}

/**
 * Displays error message in a toast
 *
 * @param {String} errorMessage
 */
const showErrorToast = errorMessage => {
    Vue.toasted.show(errorMessage, {
        type: TOAST_TYPES.ERROR,
        duration: TOAST_DURATIONS.ERROR
    })
}

const handleError = (commit, error, shouldShow) => {
    !config.isProductionEnv() && console.log(error)

    // Handle Internal Server Errors
    if (isInternalServerError(error)) {
        return shouldShow && showErrorToast(i18n.t('ERRORS.UNKNOWN'))
    }

    const { status, data } = error.response

    // Store validation errors
    if (status === STATUS_CODES.VALIDATION_FAILED && data.errors) {
        commit('SET_VALIDATION_ERRORS', data.errors)
    }

    // Finally, show error depending on output type
    if (shouldShow) {
        showError(commit, data)
    }
}

export const errors = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
