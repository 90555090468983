import { REGISTER_WIZARD_ROUTES } from '@/constants'
import { getFullNameOrEmail } from '@/services/utils/functions'

const NONCONFORMITIES_REGISTER_SIDEBAR_ROUTES = [
    {
        route: REGISTER_WIZARD_ROUTES.NONCONFORMITIES.WIZARD,
        label: 'COMMON.WIZARD'
    },
    {
        route: REGISTER_WIZARD_ROUTES.NONCONFORMITIES.PROPERTIES,
        label: 'COMMON.PROPERTIES'
    },
    {
        route: REGISTER_WIZARD_ROUTES.NONCONFORMITIES.DISCUSSIONS,
        label: 'COMMON.DISCUSSIONS'
    }
]

const SORT_VALUES = {
    DATE_OF_EVENT_ASC: 'created_at_asc',
    DATE_OF_EVENT_DESC: 'created_at_desc',
    RESPONSIBLE_PERSON_ASC: 'responsible_person_asc',
    RESPONSIBLE_PERSON_DESC: 'responsible_person_desc',
    STATUS_ASC: 'status_asc',
    STATUS_DESC: 'status_desc'
}

const SORT_OPTIONS = [
    {
        value: SORT_VALUES.DATE_OF_EVENT_ASC,
        label: 'REGISTERS.NONCONFORMITIES.SORT_VALUES.DATE_OF_EVENT_ASC'
    },
    {
        value: SORT_VALUES.DATE_OF_EVENT_DESC,
        label: 'REGISTERS.NONCONFORMITIES.SORT_VALUES.DATE_OF_EVENT_DESC'
    },
    {
        value: SORT_VALUES.RESPONSIBLE_PERSON_ASC,
        label: 'REGISTERS.NONCONFORMITIES.SORT_VALUES.RESPONSIBLE_PERSON_ASC'
    },
    {
        value: SORT_VALUES.RESPONSIBLE_PERSON_DESC,
        label: 'REGISTERS.NONCONFORMITIES.SORT_VALUES.RESPONSIBLE_PERSON_DESC'
    },
    {
        value: SORT_VALUES.STATUS_ASC,
        label: 'REGISTERS.NONCONFORMITIES.SORT_VALUES.STATUS_ASC'
    },
    {
        value: SORT_VALUES.STATUS_DESC,
        label: 'REGISTERS.NONCONFORMITIES.SORT_VALUES.STATUS_DESC'
    }
]

const SORT_ACTIONS = {
    [SORT_VALUES.DATE_OF_EVENT_ASC]: (a, b) => (a.date > b.date ? 1 : -1),
    [SORT_VALUES.DATE_OF_EVENT_DESC]: (a, b) => (a.date < b.date ? 1 : -1),
    [SORT_VALUES.RESPONSIBLE_PERSON_ASC]: (a, b) =>
        getFullNameOrEmail(a.assignee) > getFullNameOrEmail(b.assignee)
            ? 1
            : -1,
    [SORT_VALUES.RESPONSIBLE_PERSON_DESC]: (a, b) =>
        getFullNameOrEmail(a.assignee) < getFullNameOrEmail(b.assignee)
            ? 1
            : -1,
    [SORT_VALUES.STATUS_ASC]: (a, b) => (a.state.id > b.state.id ? 1 : -1),
    [SORT_VALUES.STATUS_DESC]: (a, b) => (a.state.id < b.state.id ? 1 : -1)
}

const STATUSES = {
    UNASSIGNED: 'unassigned',
    ASSIGNED: 'assigned',
    ACTIONS_DEFINED: 'actions_defined',
    RESOLVED: 'resolved',
    NOT_RELEVANT: 'not_relevant'
}

const STATUS_COLORS = {
    UNASSIGNED: 'cf-u-color-unassigned',
    ASSIGNED: 'cf-u-color-primary',
    ACTIONS_DEFINED: 'cf-u-color-amber',
    RESOLVED: 'cf-u-color-secondary-light',
    NOT_RELEVANT: 'cf-u-color-gray-canceled'
}

const STATUS_GUIDES = [
    {
        label: 'REGISTERS.NONCONFORMITIES.STATUSES.UNASSIGNED',
        color: STATUS_COLORS.UNASSIGNED
    },
    {
        label: 'REGISTERS.NONCONFORMITIES.STATUSES.ASSIGNED',
        color: STATUS_COLORS.ASSIGNED
    },
    {
        label: 'REGISTERS.NONCONFORMITIES.STATUSES.ACTIONS_DEFINED',
        color: STATUS_COLORS.ACTIONS_DEFINED
    },
    {
        label: 'REGISTERS.NONCONFORMITIES.STATUSES.RESOLVED',
        color: STATUS_COLORS.RESOLVED
    },
    {
        label: 'REGISTERS.NONCONFORMITIES.STATUSES.NOT_RELEVANT',
        color: STATUS_COLORS.NOT_RELEVANT
    }
]

const RESPONSIBILITIES_INPUT_GUIDES = {
    assignee_id: {
        label:
            'REGISTERS.NONCONFORMITIES.RESPONSIBILITIES.FIELDS.ASSIGN_TO.LABEL',
        description:
            'REGISTERS.NONCONFORMITIES.RESPONSIBILITIES.FIELDS.ASSIGN_TO.DESCRIPTION',
        index: 0
    },
    notificants: {
        label: 'REGISTERS.NONCONFORMITIES.RESPONSIBILITIES.FIELDS.NOTIFY.LABEL',
        description:
            'REGISTERS.NONCONFORMITIES.RESPONSIBILITIES.FIELDS.NOTIFY.DESCRIPTION',
        index: 1
    },
    requirement_description: {
        label:
            'REGISTERS.NONCONFORMITIES.RESPONSIBILITIES.FIELDS.RELATED_REQUIREMENTS.LABEL',
        description:
            'REGISTERS.NONCONFORMITIES.RESPONSIBILITIES.FIELDS.RELATED_REQUIREMENTS.DESCRIPTION',
        placeholder:
            'REGISTERS.NONCONFORMITIES.RESPONSIBILITIES.FIELDS.RELATED_REQUIREMENTS.PLACEHOLDER',
        index: 2
    }
}

const INPUT_GUIDES = {
    title: {
        label: 'REGISTERS.NONCONFORMITIES.FIELDS.TITLE.LABEL',
        description: 'REGISTERS.NONCONFORMITIES.FIELDS.TITLE.DESCRIPTION',
        index: 0
    },
    description: {
        label: 'REGISTERS.NONCONFORMITIES.FIELDS.DESCRIPTION.LABEL',
        description: 'REGISTERS.NONCONFORMITIES.FIELDS.DESCRIPTION.DESCRIPTION',
        index: 1
    },
    date: {
        label: 'REGISTERS.NONCONFORMITIES.FIELDS.DATE.LABEL',
        index: 2
    },
    reporter_id: {
        label: 'REGISTERS.NONCONFORMITIES.FIELDS.REPORTED_BY.LABEL',
        description: 'REGISTERS.NONCONFORMITIES.FIELDS.REPORTED_BY.DESCRIPTION',
        index: 3
    },
    assignee_id: RESPONSIBILITIES_INPUT_GUIDES.assignee_id,
    notificants: RESPONSIBILITIES_INPUT_GUIDES.notificants,
    files: {
        label: 'COMMON.ATTACH_A_FILE',
        description:
            'REGISTERS.NONCONFORMITIES.FIELDS.ATTACHED_FILES.DESCRIPTION',
        index: 5
    },
    requirement_description: {
        label:
            'REGISTERS.NONCONFORMITIES.RESPONSIBILITIES.FIELDS.RELATED_REQUIREMENTS.LABEL',
        description:
            'REGISTERS.NONCONFORMITIES.RESPONSIBILITIES.FIELDS.RELATED_REQUIREMENTS.DESCRIPTION',
        placeholder:
            'REGISTERS.NONCONFORMITIES.RESPONSIBILITIES.FIELDS.RELATED_REQUIREMENTS.PLACEHOLDER',
        index: 6
    }
}

const NONCONFORMITY_PARAMS = [
    'title',
    'description',
    'reporter_id',
    'date',
    'state',
    'requirements',
    'requirement_description',
    'notificants',
    'assignee_id',
    'ready_for_resolving'
]

const INTENTIONS = {
    CREATE: 'create',
    READ: 'read',
    EDIT: 'edit'
}

const REGISTER_OF_NONCONFORMITIES = {
    NONCONFORMITIES_REGISTER_SIDEBAR_ROUTES,
    SORT_VALUES,
    SORT_OPTIONS,
    SORT_ACTIONS,
    STATUSES,
    STATUS_COLORS,
    STATUS_GUIDES,
    INPUT_GUIDES,
    NONCONFORMITY_PARAMS,
    RESPONSIBILITIES_INPUT_GUIDES,
    INTENTIONS
}

export { REGISTER_OF_NONCONFORMITIES }
