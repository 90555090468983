import {
    REGISTER_WIZARD_ROUTES,
    REGISTER_TYPES,
    MY_PERMISSIONS,
    REGISTERS_PERMISSIONS
} from '@/constants'
import { myPermissionsGuard } from '@src/router/guards'

export default {
    name: REGISTER_WIZARD_ROUTES.NONCONFORMITIES.REGISTER_WIZARD.name,
    component: () =>
        import(
            '@/containers/RegisterWizard/Nonconformities/RegisterOfNonconformities'
        ),
    path: 'nonconformities',
    props: true,
    redirect: to => ({
        name: REGISTER_WIZARD_ROUTES.NONCONFORMITIES.INDEX.name,
        params: { ...to.params }
    }),
    meta: {
        requiresAuth: true,
        register: REGISTER_TYPES.NONCONFORMITIES
    },
    children: [
        {
            path: '',
            name: REGISTER_WIZARD_ROUTES.NONCONFORMITIES.INDEX.name,
            component: () =>
                import(
                    '@/containers/RegisterWizard/Nonconformities/NonconformitiesList'
                ),
            props: true,
            redirect: to => ({
                name: REGISTER_WIZARD_ROUTES.NONCONFORMITIES.WIZARD.name,
                params: { ...to.params }
            }),
            meta: {
                requiresAuth: true,
                register: REGISTER_TYPES.NONCONFORMITIES,
                shouldSkipRouteInHistory: true
            },
            children: [
                {
                    path: 'wizard',
                    name: REGISTER_WIZARD_ROUTES.NONCONFORMITIES.WIZARD.name,
                    component: () =>
                        import(
                            '@/components/registers/nonconformities/WizardTab'
                        ),
                    props: true,
                    meta: {
                        pageTitle: 'REGISTERS.NONCONFORMITIES.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.NONCONFORMITIES,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.NONCONFORMITY_REGISTER_ACCESS,
                            REGISTERS_PERMISSIONS.NONCONFORMITY_REGISTER
                        ]
                    }
                },
                {
                    path: 'properties',
                    name:
                        REGISTER_WIZARD_ROUTES.NONCONFORMITIES.PROPERTIES.name,
                    component: () =>
                        import('@/components/registers/PropertiesTab'),
                    props: true,
                    meta: {
                        pageTitle: 'REGISTERS.NONCONFORMITIES.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.NONCONFORMITIES,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.NONCONFORMITY_REGISTER_ACCESS,
                            REGISTERS_PERMISSIONS.NONCONFORMITY_REGISTER
                        ]
                    }
                },
                {
                    path: 'discussions',
                    name:
                        REGISTER_WIZARD_ROUTES.NONCONFORMITIES.DISCUSSIONS.name,
                    component: () =>
                        import('@/components/registers/Discussions'),
                    props: true,
                    meta: {
                        pageTitle: 'REGISTERS.NONCONFORMITIES.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.NONCONFORMITIES,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.NONCONFORMITY_REGISTER_ACCESS,
                            REGISTERS_PERMISSIONS.NONCONFORMITY_REGISTER
                        ]
                    }
                }
            ]
        },
        {
            path: 'corrective-actions',
            name:
                REGISTER_WIZARD_ROUTES.NONCONFORMITIES.CORRECTIVE_ACTIONS.INDEX
                    .name,
            component: () =>
                import(
                    '@/containers/RegisterWizard/Nonconformities/CorrectiveActionsList'
                ),
            props: true,
            redirect: to => ({
                name:
                    REGISTER_WIZARD_ROUTES.NONCONFORMITIES.CORRECTIVE_ACTIONS
                        .WIZARD.name,
                params: { ...to.params }
            }),
            meta: {
                requiresAuth: true,
                register: REGISTER_TYPES.NONCONFORMITIES,
                shouldSkipRouteInHistory: true
            },
            children: [
                {
                    path: 'wizard',
                    name:
                        REGISTER_WIZARD_ROUTES.NONCONFORMITIES
                            .CORRECTIVE_ACTIONS.WIZARD.name,
                    component: () =>
                        import(
                            '@/components/registers/corrective-actions/WizardTab'
                        ),
                    props: true,
                    meta: {
                        pageTitle: 'REGISTERS.NONCONFORMITIES.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.NONCONFORMITIES,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.NONCONFORMITY_REGISTER_ACCESS,
                            REGISTERS_PERMISSIONS.NONCONFORMITY_REGISTER
                        ]
                    }
                },
                {
                    path: 'properties',
                    name:
                        REGISTER_WIZARD_ROUTES.NONCONFORMITIES
                            .CORRECTIVE_ACTIONS.PROPERTIES.name,
                    component: () =>
                        import('@/components/registers/PropertiesTab'),
                    props: true,
                    meta: {
                        pageTitle: 'REGISTERS.NONCONFORMITIES.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.NONCONFORMITIES,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.NONCONFORMITY_REGISTER_ACCESS,
                            REGISTERS_PERMISSIONS.NONCONFORMITY_REGISTER
                        ]
                    }
                },
                {
                    path: 'discussions',
                    name:
                        REGISTER_WIZARD_ROUTES.NONCONFORMITIES
                            .CORRECTIVE_ACTIONS.DISCUSSIONS.name,
                    component: () =>
                        import('@/components/registers/Discussions'),
                    props: true,
                    meta: {
                        pageTitle: 'REGISTERS.NONCONFORMITIES.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.NONCONFORMITIES,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.NONCONFORMITY_REGISTER_ACCESS,
                            REGISTERS_PERMISSIONS.NONCONFORMITY_REGISTER
                        ]
                    }
                }
            ]
        }
    ]
}
