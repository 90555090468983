import { mapGetters } from 'vuex'
import { find, get } from 'lodash-es'
import { getFullNameOrEmail } from '@/services/utils/functions'
import { DOCUMENT_STATUSES, DOCUMENT_TYPES } from '@/constants'
import {
    commentIsResolvable,
    commentCreatorIsLoggedInUser
} from '@/services/utils/comment'

export default {
    computed: {
        ...mapGetters('account', ['companyMember', 'isLoggedIn']),
        ...mapGetters('document', ['document']),

        commentCreatorIsCurrentReviewer () {
            const { creator } = this.comment
            const {
                company_member_id: companyMemberId = -1
            } = this.$route.query

            return parseInt(companyMemberId, 10) === creator.id
        },

        commentIsDeletable () {
            if (
                this.commentableStatus !== DOCUMENT_STATUSES.IN_REVIEW ||
                !this.commentIsForCurrentVersion
            ) {
                return false
            }

            return this.isLoggedIn
                ? commentCreatorIsLoggedInUser(this.comment, this.companyMember)
                : this.commentCreatorIsCurrentReviewer
        },

        commentIsForCurrentVersion () {
            const { version } = this.document.version

            return this.commentableVersion === version
        },

        commentIsResolvable () {
            return this.isLoggedIn
                ? commentIsResolvable(this.comment, this.companyMember)
                : false
        },

        commentableStatus () {
            return get(this, 'commentableTargetableVersion.status', null)
        },

        commentableVersion () {
            return get(this, 'commentableTargetableVersion.version', null)
        },

        isPlainDocument () {
            const type = get(
                this,
                'commentableTargetableVersion.documentable_type',
                null
            )

            return type ? type === DOCUMENT_TYPES.PLAIN_DOCUMENT : true
        },

        commentableTargetableVersion () {
            const {
                commentable,
                commentable_version_id: commentableVersionId
            } = this.comment

            return find(commentable.targetable_versions, {
                id: commentableVersionId
            })
        },

        isRoot () {
            return !!this.comment.replies
        },

        creatorIdentifier () {
            const { creator } = this.comment

            if (!creator) {
                return this.comment.external_creator_email
            }

            if (creator.user) {
                return getFullNameOrEmail(creator.user)
            }

            return creator.email
        }
    }
}
