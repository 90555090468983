import axios from 'axios'

const ENDPOINTS = {
    SAVE_PROVIDERS: companyId =>
        `companies/${companyId}/notifications/providers`,
    NOTIFICATION_GROUPS: companyId =>
        `companies/${companyId}/notifications/groups`,
    NOTIFICATION_SETTINGS: companyId =>
        `companies/${companyId}/notifications/settings`
}

class NotificationSettingsService {
    async getNotificationSettings (companyId) {
        try {
            const response = await axios.get(
                ENDPOINTS.NOTIFICATION_SETTINGS(companyId)
            )

            return response.data
        } catch (error) {
            throw error
        }
    }

    async saveProvidersSettings (companyId, data) {
        await axios.post(ENDPOINTS.SAVE_PROVIDERS(companyId), data)
    }

    async saveNotificationGroups (companyId, data) {
        await axios.post(ENDPOINTS.NOTIFICATION_GROUPS(companyId), data)
    }

    async saveNotificationSettings (companyId, data) {
        await axios.post(ENDPOINTS.NOTIFICATION_SETTINGS(companyId), data)
    }
}

export const notificationSettingsService = new NotificationSettingsService()
