import {
    isEmpty,
    first,
    last,
    merge,
    each,
    keys,
    findIndex,
    find,
    pick
} from 'lodash-es'
import { regulationService } from '@/services/api/regulation'
import {
    SHORTCODE_REGEX,
    REGULATION_ROUTES,
    ISO_27001_ROUTE_PARAM
} from '@/constants'
import { RESOURCES } from '@/constants/resources'
import {
    mapStepForDisplay,
    replaceShowAttributeInTemplate
} from '@/services/utils/steps'
import { isRegulationStepAvailable } from '@/services/utils/regulation'
import { COMPANY_REGULATION_MAINTENANCE_DATES } from '../../constants/regulations'

function getInitialState () {
    return {
        currentRegulationName: '',
        currentStepId: '',
        dependencies: {},
        documentStep: null,
        documentWizardInputs: [],
        inputs: [],
        stepResources: {},
        steps: [],
        step: {},
        template: '',
        usefulLinks: [],
        classificationLevels: [],
        endDate: '',
        maintenanceDates: []
    }
}

const state = getInitialState()

const placeholderStep = {
    title: '',
    description: '',
    document: null
}

const actions = {
    setCurrentStepId ({ commit }, id) {
        commit('SET_CURRENT_STEP_ID', id)
    },

    async loadRegulationData ({ dispatch }, name) {
        dispatch('setCurrentRegulationName', name)

        await dispatch('register/getCompanyRegulationRegisters', null, {
            root: true
        })
    },

    setCurrentRegulationName ({ commit }, name) {
        commit('SET_CURRENT_REGULATION_NAME', name)
    },

    /**
     * @param commit
     * @param rootGetters
     * @param dispatch
     * @param regulationId
     * @param shouldLoad
     * @returns {Promise<void>}
     */
    async getSteps (
        { commit, rootGetters, dispatch },
        { regulationId, shouldLoad = true }
    ) {
        shouldLoad && commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const steps = await regulationService.getSteps(
                companyId,
                regulationId
            )
            commit('SET_STEPS', steps)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            shouldLoad && commit('SET_APP_LOADING', false, { root: true })
        }
    },

    /**
     * @param commit
     * @param getters
     * @param rootGetters
     * @param dispatch
     * @param regulationId
     * @param stepId
     * @param shouldLoad
     * @param forceFetch
     * @returns {Promise<void>}
     */
    async getStep (
        { commit, getters, rootGetters, dispatch },
        { regulationId, stepId, shouldLoad = true, forceFetch = false }
    ) {
        shouldLoad && commit('SET_APP_LOADING', true, { root: true })
        try {
            /** if we already have steps in vuex do not load them
             * unless forceFetch is true
             **/
            if (getters.steps.length && !forceFetch) {
                const step = find(getters.steps, { id: stepId })
                commit('SET_STEP', step)
                return
            }
            const companyId = rootGetters['company/company'].id
            const step = await regulationService.getStep(
                companyId,
                regulationId,
                stepId
            )
            commit('SET_STEP', step)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            shouldLoad && commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getStepInputs ({ commit, rootGetters, dispatch }, data) {
        try {
            const { regulationId, stepId } = data

            const companyId = rootGetters['company/company'].id
            const inputs = await regulationService.getStepInputs(
                companyId,
                regulationId,
                stepId
            )
            commit('SET_STEP_INPUTS', inputs)
            commit('SET_DEPENDENCIES', inputs)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async getStepResources ({ commit, rootGetters, dispatch }, data) {
        commit('EMPTY_STEP_RESOURCES')
        try {
            const { regulationId, stepId } = data
            const companyId = rootGetters['company/company'].id

            const resources = await regulationService.getStepResources(
                companyId,
                regulationId,
                stepId
            )

            commit('SET_STEP_RESOURCES', resources)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async getClassificationLevels (
        { commit, rootGetters, dispatch },
        regulationId
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const classificationLevels = await regulationService.getClassificationLevels(
                companyId,
                regulationId
            )
            commit('SET_CLASSIFICATION_LEVELS', classificationLevels)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async submitRegulationInputs ({ rootGetters, dispatch }, data) {
        try {
            const { regulationId, stepId, payload } = data

            const companyId = rootGetters['company/company'].id
            await regulationService.submitRegulationInputs({
                companyId,
                regulationId,
                stepId,
                payload
            })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async submitStepResources ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const { regulationId, payload } = data
            const companyId = rootGetters['company/company'].id

            const resource = await regulationService.submitStepResources({
                companyId,
                regulationId,
                payload
            })

            const {
                STATUSES: { IN_APPROVAL }
            } = RESOURCES
            commit(
                'reporting/SET_RESOURCE',
                { resource, resourceCategory: IN_APPROVAL },
                { root: true }
            )

            return resource
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async approveStepResource ({ commit, rootGetters, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const { regulationId, resourceId, stepId } = data
            const companyId = rootGetters['company/company'].id

            const resource = await regulationService.approveStepResource({
                companyId,
                regulationId,
                resourceId,
                stepId
            })

            commit('UPDATE_RESOURCE', { resource })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async setInputsToEmpty ({ commit }) {
        commit('SET_STEP_INPUTS', [])
    },

    async updateRegulationInputs ({ rootGetters, dispatch }, data) {
        try {
            const { regulationId, stepId, payload } = data

            const companyId = rootGetters['company/company'].id
            await regulationService.updateRegulationInputs({
                companyId,
                regulationId,
                stepId,
                payload
            })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async updateDocumentProperties ({ commit, rootGetters }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const { documentId, versionId, payload } = data
            if (!documentId || !versionId) {
                return
            }
            const companyId = rootGetters['company/company'].id

            const response = await regulationService.updateDocumentProperties({
                companyId,
                documentId,
                versionId,
                payload
            })

            return response
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async triggerAction ({ commit, rootGetters }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const { regulationId, url } = data

            const companyId = rootGetters['company/company'].id
            return await regulationService.triggerAction(
                companyId,
                regulationId,
                url
            )
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getTemplate ({ commit, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const {
                companyId,
                regulationId,
                stepId,
                templateId,
                accessToken
            } = data

            const [html, inputs] = await Promise.all([
                regulationService.getTemplateData(
                    'html',
                    companyId,
                    regulationId,
                    stepId,
                    templateId,
                    accessToken
                ),
                regulationService.getTemplateData(
                    'inputs',
                    companyId,
                    regulationId,
                    stepId,
                    templateId,
                    accessToken
                )
            ])

            const parsedHtmlTemplate = replaceShowAttributeInTemplate(html)

            commit('SET_TEMPLATE', parsedHtmlTemplate)

            commit('SET_DOCUMENT_WIZARD_INPUTS', { inputs: inputs, html: html })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async startStep (
        { commit, rootGetters },
        { companyRegulationStepId, shouldLoad = true }
    ) {
        shouldLoad && commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            return await regulationService.startStep(
                companyId,
                regulationId,
                companyRegulationStepId
            )
        } finally {
            shouldLoad && commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async disableStepPredefinedTrainings (
        { commit, rootGetters },
        companyRegulationStepId
    ) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const companyRegulationStep = await regulationService.disableStepPredefinedTrainings(
                companyId,
                regulationId,
                companyRegulationStepId
            )

            commit('SET_COMPANY_REGULATION_STEP', companyRegulationStep)

            return companyRegulationStep
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async finishStep ({ commit, rootGetters }, data) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const companyId = rootGetters['company/company'].id
            const { regulationId, stepId } = data

            return await regulationService.finishStep(
                companyId,
                regulationId,
                stepId
            )
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateCompanyRegulationStep (
        { commit, getters, rootGetters, dispatch },
        payload
    ) {
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const companyRegulationStep = await regulationService.updateCompanyRegulationStep(
                companyId,
                regulationId,
                payload
            )

            if (!isEmpty(getters.steps)) {
                // Action can be called when state steps isn't loaded
                commit('SET_COMPANY_REGULATION_STEP', companyRegulationStep)
            }

            commit(
                'soa/SET_RESPONSIBILITY_PERSON',
                { data: companyRegulationStep, key: 'step_assignee' },
                { root: true }
            )

            commit(
                'soa/SET_DUE_DATE',
                { data: companyRegulationStep, key: 'deadline' },
                { root: true }
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        }
    },

    addToCurrentStepDocuments ({ commit, getters }, document) {
        if (!getters.currentStep) {
            return
        }

        const step = {
            ...getters.currentStep,
            document: [...getters.currentStep.document, document]
        }

        commit('SET_STEP', step)
    },

    async getEndDate ({ commit, rootGetters }) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation']?.id

            if (!regulationId) {
                return
            }

            const date = await regulationService.getEndDate(
                companyId,
                regulationId
            )
            commit('SET_END_DATE', date.end_date)
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async setCertificationDate (
        { commit, getters, rootGetters, dispatch },
        data
    ) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = getters.currentRegulation.id

            const maintenanceDates = await regulationService.setCertificationDate(
                companyId,
                regulationId,
                data
            )

            commit(
                'company/UPDATE_REGULATION_MAINTENANCE_DATES',
                {
                    regulationId,
                    maintenanceDates
                },
                { root: true }
            )
        } catch (error) {
            dispatch('errors/handleErrorNoToast', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}

const mutations = {
    SET_CURRENT_STEP_ID (state, id) {
        state.currentStepId = parseInt(id, 10)
    },

    SET_DOCUMENT_STEP (state, documentStep) {
        state.documentStep = documentStep
    },

    SET_USEFUL_LINKS (state, links) {
        state.usefulLinks = links
    },

    SET_CURRENT_REGULATION_NAME (state, name) {
        state.currentRegulationName = name
    },

    RESET_REGULATION (state) {
        const s = getInitialState()
        each(keys(state), key => {
            state[key] = s[key]
        })
    },

    SET_STEPS (state, steps) {
        state.steps = steps
    },

    SET_STEP_INPUTS (state, inputs) {
        state.inputs = [
            ...inputs.map(input => {
                const { values, ...rest } = input

                return {
                    ...rest,
                    value: isEmpty(values) ? null : first(values)
                }
            })
        ]
    },

    EMPTY_STEP_RESOURCES (state) {
        state.stepResources = {}
    },

    SET_STEP_RESOURCES (state, resources) {
        resources.forEach(resource => {
            state.stepResources = {
                ...state.stepResources,
                [resource.type.name]: {
                    value: resource.value,
                    status: { ...resource.status }
                }
            }
        })
    },

    SET_CLASSIFICATION_LEVELS (state, classificationLevels) {
        state.classificationLevels = classificationLevels
    },

    SET_DOCUMENT_WIZARD_INPUTS (state, { inputs, html }) {
        let pair
        let inputShortcodePairs = []

        while ((pair = SHORTCODE_REGEX.exec(html)) !== null) {
            inputShortcodePairs = [...inputShortcodePairs, pair]
        }

        const copyInputs = [...inputs]

        inputShortcodePairs.forEach(pair => {
            const [, inputName, shortcode, parent = inputName] = pair
            const inputIndex = findIndex(copyInputs, { name: inputName })

            if (inputIndex !== -1) {
                inputs.splice(inputIndex, 1, {
                    ...copyInputs[inputIndex],
                    shortcode,
                    parent
                })
            } else {
                console.error(
                    'Some input is present in template but missing in inputs array'
                )
            }
        })

        state.documentWizardInputs = inputs
    },

    SET_TEMPLATE (state, template) {
        state.template = template
    },

    SET_DEPENDENCIES (state, inputs) {
        let dependencies = []

        inputs.forEach(input => {
            const { descriptor } = input
            if (descriptor && descriptor.dependencies) {
                dependencies = {
                    ...dependencies,
                    ...merge(dependencies, descriptor.dependencies)
                }
            }
        })

        state.dependencies = { ...dependencies }
    },

    SET_COMPANY_REGULATION_STEP (state, companyRegulationStep) {
        const stepIndex = findIndex(state.steps, {
            id: companyRegulationStep.step_id
        })

        const step = state.steps[stepIndex]

        const stepCompanyRegulationStepIndex = findIndex(
            step.company_regulation_step,
            { id: companyRegulationStep.id }
        )
        const stepCompanyRegulationStep =
            step.company_regulation_step[stepCompanyRegulationStepIndex]

        step.company_regulation_step.splice(stepCompanyRegulationStepIndex, 1, {
            ...stepCompanyRegulationStep,
            ...companyRegulationStep
        })
    },

    SET_STEP (state, step) {
        state.step = step
    },

    UPDATE_RESOURCE (state, { resource }) {
        state.stepResources = {
            ...state.stepResources,
            [resource.type.name]: {
                value: resource.value,
                status: { ...resource.status }
            }
        }
    },

    SET_END_DATE (state, date) {
        state.endDate = date
    }
}

const getters = {
    steps: state => state.steps.map(mapStepForDisplay),
    currentStep: state => mapStepForDisplay(state.step) || placeholderStep,
    currentDocument: (state, getters) => {
        const lastDocument = last(getters.currentStep.document)

        return getters.currentStep
            ? lastDocument
                ? { ...lastDocument, discussions: [] }
                : lastDocument
            : {}
    },
    documentStep: state => state.documentStep,
    currentRegulation: (state, getters, rootState, rootGetters) =>
        find(rootGetters['company/regulations'], {
            name:
                REGULATION_ROUTES[
                    state.currentRegulationName || ISO_27001_ROUTE_PARAM
                ]
        }),
    usefulLinks: state => state.usefulLinks,
    inputs: state => state.inputs,
    resources: state => state.stepResources,
    dependencies: state => state.dependencies,
    documentWizardInputs: state => state.documentWizardInputs,
    classificationLevels: state => state.classificationLevels,
    template: state => state.template,
    regulationById: (state, getters, rootState, rootGetters) => id =>
        find(rootGetters['company/regulations'], { id }),
    projectManagerByRegulation: (state, getters, rootState, rootGetters) => (
        regulationId = getters.currentRegulation.id
    ) => {
        const regulation = getters.regulationById(regulationId)

        if (regulation) {
            const { pivot } = regulation

            return rootGetters['company/companyMemberById'](pivot.manager_id)
        }

        return null
    },
    sponsorByRegulation: (state, getters, rootState, rootGetters) => (
        regulationId = getters.currentRegulation.id
    ) => {
        const regulation = getters.regulationById(regulationId)

        if (regulation) {
            const { pivot } = regulation

            return rootGetters['company/companyMemberById'](pivot.sponsor_id)
        }

        return null
    },
    documentsForSteps: state => steps => {
        const selectedSteps = state.steps.filter(step =>
            steps.includes(step.id)
        )

        return [
            ...selectedSteps.map(step => {
                const document = last(step.documents)

                return {
                    id: step.id,
                    name: step.name,
                    document: {
                        id: document ? document.id : null,
                        last_approved_version: document
                            ? document.last_approved_version
                            : null,
                        current_status: document ? document.current_status : ''
                    }
                }
            })
        ]
    },
    endDate: state => state.endDate,
    maintenanceDates: (state, getters) => {
        return pick(
            getters.currentRegulation.pivot,
            COMPANY_REGULATION_MAINTENANCE_DATES
        )
    }
}

export const regulation = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
