<template>
    <div class="cf-c-radio-group">
        <template v-for="(input, index) in inputs">
            <div
                v-if="!input.hidden"
                :key="index"
                class="cf-c-radio-group__ctn"
            >
                <div class="cf-c-radio-group__item">
                    <app-radio-input
                        :name="name"
                        :label="input.label"
                        :radio-value="input.value"
                        :value="selectedValue"
                        :disabled="disabled"
                        :checked="isChecked(input)"
                        @input="handleInput"
                    />
                    <div
                        v-if="input.isEditable"
                        class="cf-c-radio-group__addition"
                    >
                        <div
                            class="cf-c-multiselect cf-c-multiselect--editable"
                        >
                            <v-multiselect
                                v-model="selectedOption"
                                track-by="label"
                                label="label"
                                open-direction="bottom"
                                :disabled="isDisabledSelectInput || disabled"
                                :placeholder="placeholder"
                                :options="options"
                                :taggable="taggable"
                                @tag="addSelectOption"
                                @input="handleSelectInput"
                            >
                                <template slot="noOptions">
                                    {{ noOptionsPlaceholder }}
                                </template>
                            </v-multiselect>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <span v-if="hasError" class="cf-c-input__error">{{
            errorMessage
        }}</span>
        <slot></slot>
    </div>
</template>

<script>
import { find } from 'lodash-es'

export default {
    props: {
        inputs: {
            type: Array,
            required: true
        },

        options: {
            type: Array,
            required: true
        },

        name: {
            type: String,
            required: true
        },

        selectedValue: {
            type: [String, Boolean, Number],
            required: false,
            default: 1
        },

        placeholder: {
            type: String,
            required: true
        },

        noOptionsPlaceholder: {
            type: String,
            required: true
        },

        optionValidation: {
            type: RegExp,
            required: true
        },

        errorMessage: {
            type: String,
            required: true
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        taggable: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data () {
        return {
            selectedOption: null,
            canEditSelectInput: null,
            hasError: false
        }
    },

    computed: {
        isDisabledSelectInput () {
            return !this.canEditSelectInput
        }
    },

    watch: {
        selectedValue () {
            this.selectedOption = find(
                this.options,
                option => option.value === this.selectedValue
            )
        }
    },

    created () {
        this.selectedOption = find(
            this.options,
            option => option.value === this.selectedValue
        )

        this.canEditSelectInput = !!this.selectedOption
    },

    methods: {
        isChecked (input) {
            return input.isEditable
                ? this.canEditSelectInput
                : input.value === this.selectedValue
        },

        addSelectOption (value) {
            const option = {
                label: value,
                value: value
            }

            if (!this.isOptionValid(option)) {
                this.hasError = true
                this.selectedOption = null

                return
            }

            this.hasError = false
            this.options.push(option)

            this.selectedOption = option
            this.$emit('change', this.selectedOption.value)
        },

        isOptionValid (option) {
            return this.optionValidation.test(option.value)
        },

        handleInput (event) {
            let value = event.target ? event.target.value : event

            if (!this.findEditableInput(value)) {
                this.canEditSelectInput = false
                this.hasError = false
            } else {
                this.canEditSelectInput = true

                if (this.selectedOption) {
                    value = this.selectedOption.value
                }
            }

            this.$emit('change', value)
        },

        findEditableInput (value) {
            return find(
                this.inputs,
                input => input.isEditable && input.value === +value
            )
        },

        handleSelectInput () {
            this.hasError = false

            this.$emit('change', this.selectedOption.value)
        }
    }
}
</script>
