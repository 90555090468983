<template>
    <div :class="wrapperClasses">
        <v-select
            v-model="selected"
            :tabindex="tabindex"
            :options="options"
            :placeholder="$t(placeholder)"
            @input="handleInput"
        />
        <span v-if="!!errorMessage" class="cf-c-input__error">
            {{ errorMessage }}
        </span>
    </div>
</template>

<script>
import { isUndefined } from 'lodash-es'
import { RESOURCES } from '@/constants'

export default {
    props: {
        errorMessage: {
            type: String,
            required: false,
            default: ''
        },

        height: {
            type: String,
            required: false,
            default: ''
        },

        fontSize: {
            type: String,
            required: false,
            default: ''
        },

        placeholder: {
            type: String,
            required: false,
            default: ''
        },

        tabindex: {
            type: Number,
            required: false,
            default: 0
        },

        minWidthIcon: {
            type: Boolean,
            required: false,
            default: false
        },

        opacity6: {
            type: Boolean,
            required: false,
            default: false
        },

        fullWidth: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            selected: {},
            options: this.getOptions()
        }
    },

    computed: {
        wrapperClasses () {
            let classes = 'cf-c-typeahead'

            if (this.height === 'sm') {
                classes += ' cf-c-typeahead--height-sm'
            }

            if (this.fontSize === 'sm') {
                classes += ' cf-c-typeahead--fsz-sm'
            }

            if (this.value) {
                classes += ' cf-c-typeahead--has-text'
            }

            if (this.minWidthIcon) {
                classes += ' cf-c-typeahead--min-width-8'
            }

            if (this.opacity6) {
                classes += ' cf-c-typeahead--opacity-70'
            }

            if (this.fullWidth) {
                classes += ' cf-c-typeahead--full-width'
            }

            classes += ` cf-c-typeahead--border-${
                this.selected ? this.selected.value : 'default'
            }`

            return classes
        }
    },

    watch: {
        value (value) {
            this.selected = value
        },

        options () {
            this.selected = this.value
        }
    },

    created () {
        this.selected = this.value
    },

    methods: {
        handleInput (selected) {
            if (isUndefined(selected)) {
                return
            }

            this.$emit('input', selected)
        },

        getOptions () {
            return [
                {
                    label: this.$t('RESOURCE.OPTION_APPROVE'),
                    value: RESOURCES.APPROVAL_ACTIONS.ACTION_APPROVE
                },
                {
                    label: this.$t('RESOURCE.OPTION_REJECT'),
                    value: RESOURCES.APPROVAL_ACTIONS.ACTION_REJECT
                }
            ]
        }
    }
}
</script>
