<template>
    <v-modal
        ref="modal"
        :name="name"
        :classes="[
            `app-modal-new app-modal-new--${width} ${
                isOverflowVisible ? 'app-modal-new--with-overflow-visible' : ''
            }`
        ]"
        height="auto"
        :click-to-close="isCloseable"
        :adaptive="adaptive"
        scrollable
        focusTrap
        @opened="$emit('on-show')"
        @closed="$emit('on-close')"
    >
        <div class="app-modal-new__wrap">
            <button
                v-if="isCloseable"
                type="button"
                class="app-modal-new__cancel-action"
                @click="closeModal"
            >
                <app-icon icon="icon-cancel" />
            </button>

            <h2 v-if="title" class="app-modal-new__title">
                {{ $t(title) }}
            </h2>

            <div
                :class="[
                    'app-modal-new__content',
                    {
                        'app-modal-new__content--no-padding': hideActions
                    }
                ]"
            >
                <slot name="content"></slot>
            </div>

            <div v-if="!hideActions" class="app-modal-new__actions-wrap">
                <slot name="extra-actions"></slot>

                <button
                    v-if="isCloseable && !hideCancelAction"
                    type="button"
                    :class="`btn btn--${cancelActionVariation}`"
                    @click="closeModal"
                >
                    {{ $t(cancelActionLabel) }}
                </button>

                <button
                    type="button"
                    :class="`btn btn--${confirmActionVariation}`"
                    :disabled="confirmActionDisabled"
                    @click="$emit('handle-confirm')"
                >
                    {{ $t(confirmActionLabel) }}
                </button>
            </div>
        </div>
    </v-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    props: {
        name: {
            type: String,
            required: true
        },
        width: {
            type: String,
            required: false,
            default: 'md',
            validator (value) {
                // The value must match one of these strings
                return ['sm', 'md', 'lg'].includes(value)
            }
        },
        title: {
            type: String,
            required: false,
            default: null
        },
        cancelActionLabel: {
            type: String,
            required: false,
            default: 'COMMON.CANCEL'
        },
        cancelActionVariation: {
            type: String,
            required: false,
            default: 'gamma',
            validator (value) {
                // The value must match one of these strings
                return [
                    'alpha',
                    'beta',
                    'gamma',
                    'danger',
                    'danger-outline',
                    'success'
                ].includes(value)
            }
        },
        hideCancelAction: {
            type: Boolean,
            required: false,
            default: false
        },
        confirmActionLabel: {
            type: String,
            required: false,
            default: 'COMMON.CONFIRM'
        },
        confirmActionVariation: {
            type: String,
            required: false,
            default: 'alpha',
            validator (value) {
                // The value must match one of these strings
                return [
                    'alpha',
                    'beta',
                    'gamma',
                    'danger',
                    'danger-outline',
                    'success'
                ].includes(value)
            }
        },
        confirmActionDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        isCloseable: {
            type: Boolean,
            required: false,
            default: true
        },
        isOverflowVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        isShared: {
            type: Boolean,
            required: false,
            default: false
        },
        adaptive: {
            type: Boolean,
            required: false,
            default: true
        },
        hideActions: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        ...mapGetters('confirmModal', ['isConfirmModalVisible'])
    },

    methods: {
        ...mapActions('confirmModal', ['closeConfirmModal']),
        closeModal () {
            this.$modal.hide(this.name)
            if (this.isShared) {
                this.closeConfirmModal()
            }
        }
    },
    watch: {
        isConfirmModalVisible (value) {
            if (this.isShared) {
                if (value) {
                    this.$modal.show(this.name)
                } else {
                    this.$modal.hide(this.name)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@style/sfc.scss';

::v-deep .app-modal-new {
    position: relative;
    left: 0 !important;
    max-width: rem(600);
    width: calc(100% - #{rem(30)}) !important;
    margin: 0 auto;

    &--lg {
        max-width: rem(800);
    }

    &--with-overflow-visible {
        overflow: visible;
    }
}

.app-modal-new {
    &__wrap {
        position: relative;
        padding: rem(24);
    }

    &__cancel-action {
        position: absolute;
        top: rem(24);
        right: rem(24);
        display: flex;
        height: rem(24);
        width: rem(24);
        padding: 0;
        border: 0;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: $color-primary-beta;
        outline: none;
        transition: color 0.25s ease;

        &:hover {
            color: $color-primary-epsilon;
        }
    }

    &__title {
        margin: 0;
        padding-right: rem(40);
        padding-bottom: rem(22);
        font-size: rem(24);
        font-weight: 700;
        line-height: calc(32 / 24);
        color: $color-primary-beta;
    }

    &__content {
        padding-bottom: rem(40);
        font-size: rem(16);
        font-weight: 400;
        line-height: calc(22 / 16);
        color: $color-primary-beta;

        &--no-padding {
            padding-bottom: 0;
        }
    }

    &__actions-wrap {
        display: flex;
        gap: rem(24);
        justify-content: flex-end;
    }
}
</style>
