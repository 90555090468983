<template>
    <div
        class="cf-c-labeled-icon"
        :class="[
            { 'cf-c-labeled-icon--is-label-bolded': labelBolded },
            { 'cf-c-labeled-icon--has-no-truncated-text': hasNoTruncatedText },
            { 'cf-c-labeled-icon--is-color-inherit': titleColorInherit }
        ]"
    >
        <div class="cf-c-labeled-icon__icon-wrap">
            <app-icon :icon="icon" :size="iconSize" />
        </div>
        <div class="cf-c-labeled-icon__text-wrap">
            <span
                class="cf-c-labeled-icon__text"
                :class="{ 'cf-c-labeled-icon__text--disabled': disabled }"
            >
                {{ title }}
            </span>
        </div>
        <div
            v-if="hasRightCaretUp"
            class="cf-c-labeled-icon__right-caret is-up"
        ></div>
        <div
            v-if="hasRightCaretDown"
            class="cf-c-labeled-icon__right-caret"
        ></div>

        <div
            v-if="$slots.customRightElement"
            class="cf-c-labeled-icon__right-element"
        >
            <slot name="customRightElement"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },

        icon: {
            type: String,
            required: true
        },

        iconSize: {
            type: String,
            required: false,
            default: ''
        },

        labelBolded: {
            type: Boolean,
            required: false,
            default: false
        },

        hasRightCaretUp: {
            type: Boolean,
            required: false,
            default: false
        },

        hasRightCaretDown: {
            type: Boolean,
            required: false,
            default: false
        },

        hasNoTruncatedText: {
            type: Boolean,
            required: false,
            default: false
        },

        titleColorInherit: {
            type: Boolean,
            required: false,
            default: false
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>
