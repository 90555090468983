<template>
    <label :class="wrapperClasses" @mouseup="removeFocus">
        <slot name="first-label"></slot>
        <input
            ref="switch"
            :tabindex="tabindex"
            type="checkbox"
            class="cf-c-form-switch__input"
            :checked="value"
            :disabled="disabled"
            @change="handleChange($event.target.checked, value)"
        />
        <span class="cf-c-form-switch__toggler">
            <app-icon v-if="icon" :icon="icon" class="switch-circle" />
            <span v-else class="switch-circle"> </span>
        </span>
    </label>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        value: {
            type: Boolean,
            required: true
        },

        tabindex: {
            type: Number,
            required: false,
            default: 0
        },

        size: {
            type: String,
            required: false,
            default: ''
        },

        activeColor: {
            type: String,
            required: false,
            default: ''
        },

        inactiveColor: {
            type: String,
            required: false,
            default: ''
        },

        vertical: {
            type: Boolean,
            required: false,
            default: false
        },

        icon: {
            type: String,
            required: false,
            default: ''
        }
    },

    computed: {
        wrapperClasses () {
            let classes = 'cf-c-form-switch'

            if (this.disabled) {
                classes += ' cf-is-disabled'
            }

            if (this.vertical) {
                classes += ' cf-c-form-switch--vertical'
            }

            //
            switch (this.size) {
                case 'sm':
                    classes += ' cf-c-form-switch--sm'
                    break

                case 'lg':
                    classes += ' cf-c-form-switch--lg'
                    break
            }

            // Define additional class for active color
            switch (this.activeColor) {
                case 'primary':
                    classes += ' active-primary'
                    break

                case 'primary-epsilon':
                    classes += ' active-primary-epsilon'
                    break

                case 'gray':
                    classes += ' active-gray'
                    break
            }

            // Define additional class for inactive color
            switch (this.inactiveColor) {
                case 'primary':
                    classes += ' inactive-primary'
                    break

                case 'light':
                    classes += ' inactive-light'
                    break

                case 'gray-beta':
                    classes += ' inactive-gray-beta'
                    break

                case 'danger':
                    classes += ' inactive-danger'
                    break
            }

            // With icon
            switch (this.icon) {
                case 'paragraph':
                    classes += ' icon-paragraph'
                    break
            }

            return classes
        }
    },

    methods: {
        removeFocus () {
            this.$refs.switch.blur()
        },

        handleChange (checked, value) {
            this.$emit('input', checked)

            if (checked !== value) {
                this.$refs.switch.checked = value
            }
        }
    }
}
</script>
