<template>
    <div class="cf-u-flex-justify-center">
        <app-button
            v-if="!isEditing"
            variation="clear"
            class="cf-u-flex-align-center cf-u-flex-direction-column"
            :class="
                disabled ? 'cf-u-color-primary-light' : 'cf-u-color-primary'
            "
            :disabled="disabled"
            :uppercased="false"
            @click="handleAddSection"
        >
            <div
                class="cf-u-flex-center cf-c-icon cf-u-radius-pill cf-u-bg-current-color"
                :class="{ 'cf-c-icon--lg': lg }"
            >
                <app-icon
                    icon="plus-icon"
                    class="cf-u-color-white"
                    :size="iconSize"
                />
            </div>
            <span
                class="cf-u-mt-xs cf-u-text-bold"
                :class="[lg ? 'cf-u-font-size-sm' : 'cf-u-font-size-xs']"
            >
                {{
                    $t(
                        'REGISTERS.INTERNAL_AUDITS.CHECKLIST_ADAPTATION.ADD_SECTION'
                    )
                }}
            </span>
        </app-button>
        <div
            v-else
            class="cf-u-flex-align-center cf-u-flex-1 cf-u-flex-justify-between cf-u-bg-gray-01"
        >
            <app-input
                v-model="form.title"
                class="cf-u-ml-sm"
                width60
                height-xs
                border-radius
            />

            <div class="cf-u-flex-align-center cf-u-mr-sm">
                <app-button
                    v-if="form.title"
                    variation="clear"
                    class="cf-u-color-gray-02"
                    size="sm"
                    @click="addSection"
                >
                    <app-icon icon="checkmark-icon" />
                </app-button>

                <app-button
                    variation="clear"
                    class="cf-u-color-gray-02"
                    @click="cancel"
                >
                    <app-icon icon="delete-icon" />
                </app-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        lg: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data () {
        return {
            isEditing: false,
            form: this.getDefaults()
        }
    },

    computed: {
        iconSize () {
            return this.lg ? 'lg' : ''
        }
    },
    methods: {
        addSection () {
            this.$emit('add-section', this.form, () => {
                this.form = this.getDefaults()
                this.isEditing = false
            })
        },

        handleAddSection () {
            this.isEditing = true
        },

        cancel () {
            this.form = this.getDefaults()
            this.isEditing = false
        },

        getDefaults () {
            return {
                title: ''
            }
        }
    }
}
</script>
