import { REGISTER_WIZARD_ROUTES } from '@/constants'

const SOA_SIDEBAR_ROUTES = [
    {
        route: REGISTER_WIZARD_ROUTES.SOA.WIZARD,
        label: 'COMMON.WIZARD'
    },
    {
        route: REGISTER_WIZARD_ROUTES.SOA.PROPERTIES,
        label: 'COMMON.PROPERTIES'
    },
    {
        route: REGISTER_WIZARD_ROUTES.SOA.DISCUSSIONS,
        label: 'COMMON.DISCUSSIONS'
    }
]

const RISK_TREATMENT_PLAN_SIDEBAR_ROUTES = [
    {
        route: REGISTER_WIZARD_ROUTES.RISK_TREATMENT_PLAN.WIZARD,
        label: 'COMMON.WIZARD'
    },
    {
        route: REGISTER_WIZARD_ROUTES.RISK_TREATMENT_PLAN.PROPERTIES,
        label: 'COMMON.PROPERTIES'
    },
    {
        route: REGISTER_WIZARD_ROUTES.RISK_TREATMENT_PLAN.DISCUSSIONS,
        label: 'COMMON.DISCUSSIONS'
    }
]

const UNDER_WAY = 'under_way'
const PLANNED = 'planned'
const IMPLEMENTED = 'implemented'
const REVIEW_NEEDED = 'review_needed'

const CONTROL_STATUSES = {
    UNDER_WAY,
    PLANNED,
    IMPLEMENTED,
    REVIEW_NEEDED
}

const STATUSES = {
    CHANGED: 'changed',
    IN_REVIEW: 'in_review',
    RESOURCES_IN_REVIEW: 'resources_review',
    COMPLETED: 'completed',
    REVIEW_NEEDED: 'review_needed',
    INITIAL: 'initial'
}

const STATUSES_TRANSLATION_MAPPER = {
    [STATUSES.CHANGED]: 'REGISTERS.SOA.STATUSES.CHANGED',
    [STATUSES.IN_REVIEW]: 'REGISTERS.SOA.STATUSES.IN_REVIEW',
    [STATUSES.RESOURCES_IN_REVIEW]: 'REGISTERS.SOA.STATUSES.RESOURCES_REVIEW',
    [STATUSES.COMPLETED]: 'REGISTERS.SOA.STATUSES.COMPLETED',
    [STATUSES.REVIEW_NEEDED]: 'REGISTERS.SOA.STATUSES.REVIEW_NEEDED',
    [STATUSES.INITIAL]: 'REGISTERS.SOA.STATUSES.INITIAL'
}

const FILTER_VALUES = {
    UNDER_WAY,
    PLANNED,
    IMPLEMENTED,
    APPLICABLE: 'applicable',
    NON_APPLICABLE: 'non-applicable',
    ALL: 'all',
    REVIEW_NEEDED: 'review-needed'
}

const FILTER_OPTIONS = [
    {
        value: FILTER_VALUES.UNDER_WAY,
        label: 'REGISTERS.SOA.CONTROL_STATUSES.UNDER_WAY'
    },
    {
        value: FILTER_VALUES.PLANNED,
        label: 'REGISTERS.SOA.CONTROL_STATUSES.PLANNED'
    },
    {
        value: FILTER_VALUES.IMPLEMENTED,
        label: 'REGISTERS.SOA.CONTROL_STATUSES.IMPLEMENTED'
    },
    {
        value: FILTER_VALUES.REVIEW_NEEDED,
        label: 'REGISTERS.SOA.CONTROL_STATUSES.REVIEW_NEEDED'
    },
    {
        value: FILTER_VALUES.APPLICABLE,
        label: 'REGISTERS.SOA.FILTER_VALUES.APPLICABLE'
    },
    {
        value: FILTER_VALUES.NON_APPLICABLE,
        label: 'REGISTERS.SOA.FILTER_VALUES.NON_APPLICABLE'
    },
    {
        value: FILTER_VALUES.ALL,
        label: 'REGISTERS.SOA.FILTER_VALUES.ALL'
    }
]

const FILTER_ACTIONS = {
    [FILTER_VALUES.UNDER_WAY]: item =>
        item.status === CONTROL_STATUSES.UNDER_WAY &&
        !item.implementedPrior &&
        item.isApplicable,
    [FILTER_VALUES.PLANNED]: item =>
        item.status === CONTROL_STATUSES.PLANNED &&
        !item.implementedPrior &&
        item.isApplicable,
    [FILTER_VALUES.IMPLEMENTED]: item =>
        (item.status === CONTROL_STATUSES.IMPLEMENTED ||
            item.implementedPrior) &&
        item.isApplicable,
    [FILTER_VALUES.APPLICABLE]: item => item.isApplicable,
    [FILTER_VALUES.NON_APPLICABLE]: item => !item.isApplicable,
    [FILTER_VALUES.ALL]: item => item,
    [FILTER_VALUES.REVIEW_NEEDED]: (item, isInitial) =>
        ((isInitial && item.isApplicable && item.reviewNeeded) ||
            (!isInitial && item.reviewNeeded)) &&
        !item.implementedPrior
}

const TAG_TYPES = {
    RISK_REGISTER: 'risk-register',
    REQUIREMENT: 'requirement',
    DOCUMENT: 'document',
    TEXT: 'text'
}

const CONTROL_APPLICABLE = 'applicable'
const CONTROL_JUSTIFICATION = 'justification'
const CONTROL_IMPLEMENTATION_METHOD = 'implementationMethod'

const CONTROLS_INPUTS = {
    [CONTROL_APPLICABLE]: {
        label: 'REGISTERS.SOA.CONTROL_FIELDS.APPLICABLE.LABEL',
        description: 'REGISTERS.SOA.CONTROL_FIELDS.APPLICABLE.DESCRIPTION'
    },
    [CONTROL_JUSTIFICATION]: {
        label: 'REGISTERS.SOA.CONTROL_FIELDS.JUSTIFICATION.LABEL',
        description: 'REGISTERS.SOA.CONTROL_FIELDS.JUSTIFICATION.DESCRIPTION'
    },
    [CONTROL_IMPLEMENTATION_METHOD]: {
        label: 'REGISTERS.SOA.CONTROL_FIELDS.IMPLEMENTATION_METHOD.LABEL',
        description:
            'REGISTERS.SOA.CONTROL_FIELDS.IMPLEMENTATION_METHOD.DESCRIPTION'
    }
}

const RTP_INPUTS = {
    completion_deadline_document: {
        label:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.COMPLETION_DEADLINE_DOCUMENT.LABEL',
        description:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.COMPLETION_DEADLINE_DOCUMENT.DESCRIPTION',
        index: 0
    },
    responsible_person_document: {
        label:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.RESPONSIBLE_PERSON_DOCUMENT.LABEL',
        description:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.RESPONSIBLE_PERSON_DOCUMENT.DESCRIPTION',
        index: 1
    },
    human_resources_document: {
        label:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.HUMAN_RESOURCES_DOCUMENT.LABEL',
        description:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.HUMAN_RESOURCES_DOCUMENT.DESCRIPTION',
        index: 2
    },
    financial_resources_document: {
        label:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.FINANCIAL_RESOURCES_DOCUMENT.LABEL',
        description:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.FINANCIAL_RESOURCES_DOCUMENT.DESCRIPTION',
        index: 3
    },
    technology_resources_document: {
        label:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.TECHNOLOGY_RESOURCES_DOCUMENT.LABEL',
        description:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.TECHNOLOGY_RESOURCES_DOCUMENT.DESCRIPTION',
        index: 4
    },
    completion_deadline_task: {
        label:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.COMPLETION_DEADLINE_TASK.LABEL',
        description:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.COMPLETION_DEADLINE_TASK.DESCRIPTION',
        index: 5
    },
    responsible_person_task: {
        label:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.RESPONSIBLE_PERSON_TASK.LABEL',
        description:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.RESPONSIBLE_PERSON_TASK.DESCRIPTION',
        index: 6
    },
    human_resources_task: {
        label: 'REGISTERS.SOA.RISK_TREATMENT_PLAN.HUMAN_RESOURCES_TASK.LABEL',
        description:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.HUMAN_RESOURCES_TASK.DESCRIPTION',
        index: 7
    },
    financial_resources_task: {
        label:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.FINANCIAL_RESOURCES_TASK.LABEL',
        description:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.FINANCIAL_RESOURCES_TASK.DESCRIPTION',
        index: 8
    },
    technology_resources_task: {
        label:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.TECHNOLOGY_RESOURCES_TASK.LABEL',
        description:
            'REGISTERS.SOA.RISK_TREATMENT_PLAN.TECHNOLOGY_RESOURCES_TASK.DESCRIPTION',
        index: 9
    }
}

const RISK_TREATMENT_PLAN_STATUSES = {
    APPROVED: 'approved',
    IN_APPROVAL: 'in_approval',
    NOT_APPROVED: 'not_approved'
}

const RISK_TREATMENT_PLAN_STATUS_COLORS = {
    APPROVED: 'cf-u-color-secondary-light',
    IN_APPROVAL: 'cf-u-color-amber',
    NOT_APPROVED: 'cf-u-color-rose'
}

const RISK_TREATMENT_PLAN_METHOD_COLORS = {
    NEW: 'cf-u-color-primary'
}

const SOA_STATUS_COLORS = {
    IMPLEMENTED: 'cf-u-color-sky-blue',
    UNDER_WAY: 'cf-u-color-amber',
    PLANNED: 'cf-u-color-gold',
    REVIEW_NEEDED: 'cf-u-color-danger'
}

const STATUS_GUIDES = [
    {
        label: 'REGISTERS.SOA.CONTROL_STATUSES.IMPLEMENTED',
        color: SOA_STATUS_COLORS.IMPLEMENTED
    },
    {
        label: 'REGISTERS.SOA.CONTROL_STATUSES.UNDER_WAY',
        color: SOA_STATUS_COLORS.UNDER_WAY
    },
    {
        label: 'REGISTERS.SOA.CONTROL_STATUSES.PLANNED',
        color: SOA_STATUS_COLORS.PLANNED
    },
    {
        label: 'REGISTERS.SOA.CONTROL_STATUSES.REVIEW_NEEDED',
        color: SOA_STATUS_COLORS.REVIEW_NEEDED
    }
]

const RISK_TREATMENT_PLAN_STATUS_GUIDES = [
    {
        label: 'REGISTERS.SOA.RISK_TREATMENT_PLAN.STATUSES.APPROVED',
        color: RISK_TREATMENT_PLAN_STATUS_COLORS.APPROVED,
        value: RISK_TREATMENT_PLAN_STATUSES.APPROVED
    },
    {
        label: 'REGISTERS.SOA.RISK_TREATMENT_PLAN.STATUSES.IN_APPROVAL',
        color: RISK_TREATMENT_PLAN_STATUS_COLORS.IN_APPROVAL,
        value: RISK_TREATMENT_PLAN_STATUSES.IN_APPROVAL
    },
    {
        label: 'REGISTERS.SOA.RISK_TREATMENT_PLAN.STATUSES.NOT_APPROVED',
        color: RISK_TREATMENT_PLAN_STATUS_COLORS.NOT_APPROVED,
        value: RISK_TREATMENT_PLAN_STATUSES.NOT_APPROVED
    }
]

const RISK_TREATMENT_PLAN_METHOD_GUIDES = [
    {
        label: 'REGISTERS.SOA.RISK_TREATMENT_PLAN.METHOD_STATUSES.NEW',
        color: RISK_TREATMENT_PLAN_METHOD_COLORS.NEW
    }
]

const RISK_TREATMENT_PLAN = {
    INPUTS: RTP_INPUTS,
    STATUSES: RISK_TREATMENT_PLAN_STATUSES,
    STATUS_COLORS: RISK_TREATMENT_PLAN_STATUS_COLORS,
    STATUS_GUIDES: RISK_TREATMENT_PLAN_STATUS_GUIDES,
    METHOD_GUIDES: RISK_TREATMENT_PLAN_METHOD_GUIDES
}

const IMPLEMENTATION_METHOD_TYPES = {
    DOCUMENT: 'document',
    UPDATE_DOCUMENT: 'update-document',
    TASK: 'task',
    TEXT: 'text'
}

const COMPANY_CONTROL_TASK_PARAMS = [
    'title',
    'description',
    'assignee_id',
    'due_date',
    'company_control_id'
]

const WITHDRAWN_FORMAT = documentStepName => `[withdrawn] ${documentStepName}`

const SOA_STEPS_ORDER = {
    STATEMENT_OF_APPLICABILITY: {
        step: 1,
        value: 'statement of applicability'
    },
    RISK_TREATMENT_PLAN: {
        step: 2,
        value: 'risk treatment plan'
    },
    RESOURCE_APPROVAL: {
        step: 3,
        value: 'resource approval'
    },
    RISK_OWNER_APPROVAL: {
        step: 4,
        value: 'risk owner approval'
    }
}

const SOA_STEP_OPTIONS = {
    STATEMENT_OF_APPLICABILITY: {
        SOA_SETUP: 'soa setup',
        SOA_REVIEW_NEEDED: 'soa review needed'
    }
}

const SOA = {
    COMPANY_CONTROL_TASK_PARAMS,
    IMPLEMENTATION_METHOD_TYPES,
    CONTROL_APPLICABLE,
    CONTROL_JUSTIFICATION,
    CONTROL_IMPLEMENTATION_METHOD,
    TAG_TYPES,
    SOA_SIDEBAR_ROUTES,
    FILTER_VALUES,
    FILTER_OPTIONS,
    FILTER_ACTIONS,
    CONTROL_STATUSES,
    CONTROLS_INPUTS,
    RISK_TREATMENT_PLAN_SIDEBAR_ROUTES,
    RISK_TREATMENT_PLAN,
    STATUSES,
    STATUS_GUIDES,
    STATUSES_TRANSLATION_MAPPER,
    WITHDRAWN_FORMAT,
    SOA_STEPS_ORDER,
    SOA_STEP_OPTIONS
}

export { SOA }
