import { implementationStepsService } from './api'

const store = {
    namespaced: true,

    state: {
        steps: [],
        soaSteps: []
    },

    actions: {
        async getImplementationSteps ({ commit, rootGetters, dispatch }) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                const response = await implementationStepsService.getImplementationSteps(
                    companyId,
                    regulationId
                )

                commit('setSteps', response)
            } catch (err) {
                dispatch('errors/handleError', err, { root: true })
                throw err
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },

        async getImplementationStepsSoaStatus ({
            commit,
            rootGetters,
            dispatch
        }) {
            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                const response = await implementationStepsService.getImplementationStepsSoaStatus(
                    companyId,
                    regulationId
                )

                if (!response.is_implemented) {
                    commit('setSoaSteps', response.steps)
                }
            } catch (err) {
                dispatch('errors/handleError', err, { root: true })
                throw err
            }
        }
    },

    mutations: {
        setSteps (state, steps) {
            state.steps = steps
        },

        setSoaSteps (state, soaSteps) {
            state.soaSteps = soaSteps
        }
    },

    getters: {}
}

export { store }
