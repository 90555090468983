<template>
    <div :class="wrapperClasses">
        <v-select
            v-model="selected"
            :tabindex="tabindex"
            :name="name"
            :options="options"
            :taggable="taggable"
            :multiple="false"
            :reduce="reduceFunction"
            :label="label"
            :clearable="clearable"
            :disabled="disabled"
            :placeholder="$t(placeholder)"
            :searchable="searchable"
            :selectable="member => !member.disabled"
            @input="handleInput"
            @option:created="handleTag"
        >
            <template slot="option" slot-scope="option">
                <span v-if="!option.tooltip && !option.button">{{
                    option[label]
                }}</span>
                <span v-if="option.tooltip" :title="option.tooltip">{{
                    option[label]
                }}</span>
                <span
                    v-if="option.button"
                    class="bold"
                    @click="optionButtonClicked(option.key)"
                    >{{ option.button }}</span
                >
            </template>
            <span slot="no-options" @click="$refs.select.open = false">
                {{ $t('COMMON.NO_RESULTS') }}
            </span>
        </v-select>
        <span v-if="!!errorMessage" class="cf-c-input__error">
            {{ errorMessage }}
        </span>
    </div>
</template>

<script>
import { isUndefined, isObject } from 'lodash-es'
import { EventBus } from '@/EventBus'

export default {
    props: {
        searchable: {
            type: Boolean,
            required: false,
            default: true
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        options: {
            type: Array,
            required: true
        },

        value: {
            type: [String, Number, Object],
            required: false
        },

        taggable: {
            type: Boolean,
            required: false,
            default: false
        },

        reduce: {
            type: Function,
            required: false
        },

        label: {
            type: String,
            required: false,
            default: 'label'
        },

        trackBy: {
            type: String,
            required: false,
            default: 'value'
        },

        clearable: {
            type: Boolean,
            required: false,
            default: true
        },

        errorMessage: {
            type: String,
            required: false,
            default: ''
        },

        height: {
            type: String,
            required: false,
            default: ''
        },

        fontSize: {
            type: String,
            required: false,
            default: ''
        },

        placeholder: {
            type: String,
            required: false,
            default: ''
        },

        name: {
            type: String,
            required: false,
            default: ''
        },

        tabindex: {
            type: Number,
            required: false,
            default: 0
        },

        minWidthIcon: {
            type: Boolean,
            required: false,
            default: false
        },

        opacity6: {
            type: Boolean,
            required: false,
            default: false
        },

        border: {
            type: Boolean,
            required: false,
            default: false
        },

        isTagged: {
            type: Boolean,
            required: false,
            default: false
        },

        className: {
            type: String,
            required: false,
            default: ''
        },

        index: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data () {
        return {
            selected: {}
        }
    },

    computed: {
        reduceFunction () {
            if (!this.reduce) {
                return value => value[this.trackBy]
            }

            return this.reduce
        },

        wrapperClasses () {
            let classes = 'cf-c-typeahead'

            if (this.errorMessage) {
                classes += ' cf-c-typeahead--has-error'
            }

            if (this.height === 'sm') {
                classes += ' cf-c-typeahead--height-sm'
            }

            if (this.fontSize === 'sm') {
                classes += ' cf-c-typeahead--fsz-sm'
            }

            if (this.value) {
                classes += ' cf-c-typeahead--has-text'
            }

            if (this.minWidthIcon) {
                classes += ' cf-c-typeahead--min-width-8'
            }

            if (this.opacity6) {
                classes += ' cf-c-typeahead--opacity-70'
            }

            if (this.border) {
                classes += ' cf-c-typeahead--border'
            }

            if (this.isTagged) {
                classes += ' cf-c-typehead--is-tagged'
            }

            if (this.className) {
                classes += ` ${this.className}`
            }

            return classes
        }
    },

    watch: {
        value (value) {
            this.selected = value
        },

        options () {
            this.selected = this.value
        }
    },

    created () {
        this.selected = this.value
    },

    methods: {
        optionButtonClicked (selected) {
            this.handleInput(selected)
            EventBus.$emit('optionButtonClicked', this.index)
        },

        showInfo () {
            EventBus.$emit('show-info', this.index)
        },

        handleInput (selected) {
            if (isUndefined(selected)) {
                return
            }

            this.$emit('input', selected)
        },

        handleTag (tag) {
            const payload = isObject(tag) ? tag : { [this.label]: tag }
            this.$emit('tag', payload)
            this.$emit('input', payload)
        }
    }
}
</script>
