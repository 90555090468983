import { isNil, first } from 'lodash-es'
import FocusFirstEmptyInput from '@/directives/FocusFirstEmptyInput'
import { EventBus } from '@/EventBus'
import { FOCUSABLE_INPUTS } from '@/constants'

export default {
    directives: {
        'focus-first-empty-input': FocusFirstEmptyInput
    },

    created () {
        EventBus.$on('input-focused', this.handleInputFocus)
        EventBus.$on('input-changed', this.handleInputChange)
    },

    beforeDestroy () {
        EventBus.$off('input-focused', this.handleInputFocus)
        EventBus.$off('input-changed', this.handleInputChange)
    },

    methods: {
        getInputRef (index) {
            if (isNil(index) || index === -1) {
                return null
            }

            const ref = this.$refs[index]

            return ref || null
        },

        getNestedInput (index) {
            const ref = this.getInputRef(index)

            if (!ref || !ref.$el) {
                return null
            }

            const inputs = ref.$el.querySelectorAll(FOCUSABLE_INPUTS)

            return inputs && inputs.length ? first(inputs) : null
        },

        handleInputChange (index) {
            const input = this.getNestedInput(index)

            input && input.focus()
        },

        getFirstEnabledInputIndex (index, ascending = true) {
            const ref = this.getInputRef(index)
            const input = this.getNestedInput(index)

            if (!input) {
                return null
            }

            return input && input.disabled
                ? this.getFirstEnabledInputIndex(
                      ascending ? index + 1 : index - 1,
                      ascending
                  )
                : this.inputs[ref.name].index
        },

        handleInputFocus (input) {
            const index =
                this.inputs &&
                this.inputs[input.name] &&
                this.inputs[input.name].index

            EventBus.$emit('input-focused-and-prepared', {
                ...input,
                index,
                nextIndex: this.getFirstEnabledInputIndex(index + 1, true),
                previousIndex: this.getFirstEnabledInputIndex(index - 1, false)
            })
        }
    }
}
