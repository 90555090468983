<template>
    <div class="cf-c-checkbox-grid-list">
        <div
            v-for="(item, index) in items"
            :key="index"
            class="cf-c-checkbox-grid-list__item cf-u-mr-xxl"
        >
            {{ item }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        }
    }
}
</script>
