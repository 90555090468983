import { mapGetters } from 'vuex'
import { COMPANY_ROLES, REGISTER_STATUSES, SOA } from '@/constants'
import { includes } from 'lodash-es'

const { STATUSES_TRANSLATION_MAPPER, SOA_STEPS_ORDER, SOA_STEP_OPTIONS } = SOA

export default {
    computed: {
        ...mapGetters('register', ['currentCompanyRegulationRegister']),
        ...mapGetters('company', [
            'soaProgress',
            'companyMembers',
            'companyMemberByRole'
        ]),

        resourceApproverNameOrJobTitle () {
            const approver = this.companyMemberByRole(
                COMPANY_ROLES.RESOURCE_APPROVER.key
            )

            if (approver) {
                const companyMember = this.companyMembers.find(
                    member => member.id === approver.member_id
                )

                return companyMember &&
                    companyMember.user &&
                    companyMember.user.full_name
                    ? companyMember.user.full_name
                    : approver.job_title
            }

            return ''
        },

        soaStatus () {
            if (this.currentCompanyRegulationRegister) {
                const { status } = this.currentCompanyRegulationRegister
                if (
                    this.currentCompanyRegulationRegister.is_initial &&
                    status !== SOA.STATUSES.RESOURCES_IN_REVIEW
                ) {
                    return this.$t(
                        STATUSES_TRANSLATION_MAPPER[REGISTER_STATUSES.INITIAL]
                    )
                }

                return this.$t(STATUSES_TRANSLATION_MAPPER[status])
            }

            return ''
        }
    },

    methods: {
        soaSteps (
            option = SOA_STEP_OPTIONS.STATEMENT_OF_APPLICABILITY.SOA_SETUP
        ) {
            const SOALabel = option => {
                if (
                    option ===
                    SOA_STEP_OPTIONS.STATEMENT_OF_APPLICABILITY
                        .SOA_REVIEW_NEEDED
                ) {
                    return 'REGISTERS.SOA.STEPS.SOA.REVIEW_NEEDED.LABEL'
                }
                return 'REGISTERS.SOA.STEPS.SOA.DOCUMENTATION_REVIEW.LABEL'
            }

            return [
                {
                    label: SOALabel(option),
                    subLabel: this.soaStatus,
                    value: SOA_STEPS_ORDER.STATEMENT_OF_APPLICABILITY.value,
                    isPassed: includes(
                        this.soaProgress,
                        SOA_STEPS_ORDER.STATEMENT_OF_APPLICABILITY.step
                    )
                },
                {
                    label: 'REGISTERS.SOA.STEPS.ADAPTING_THE_CHECKLIST.LABEL',
                    value: SOA_STEPS_ORDER.RISK_TREATMENT_PLAN.value,
                    isPassed: includes(
                        this.soaProgress,
                        SOA_STEPS_ORDER.RISK_TREATMENT_PLAN.step
                    )
                },
                {
                    label: 'REGISTERS.SOA.STEPS.PERFORMING_THE_AUDIT.LABEL',
                    value: SOA_STEPS_ORDER.RESOURCE_APPROVAL.value,
                    icon: {
                        name: 'info-icon',
                        tooltipText: this.$t(
                            'REGISTERS.SOA.STEPS.PERFORMING_THE_AUDIT.TOOLTIP_TEXT'
                        ).replace(
                            ':resource_approver_name_or_job_title',
                            this.resourceApproverNameOrJobTitle
                        )
                    },
                    isPassed: includes(
                        this.soaProgress,
                        SOA_STEPS_ORDER.RESOURCE_APPROVAL.step
                    )
                },
                {
                    label: 'REGISTERS.SOA.STEPS.AUDIT_REPORT.LABEL',
                    value: SOA_STEPS_ORDER.RISK_OWNER_APPROVAL.value,
                    icon: {
                        name: 'info-icon',
                        tooltipText:
                            'REGISTERS.SOA.STEPS.AUDIT_REPORT.TOOLTIP_TEXT'
                    }
                }
            ]
        }
    }
}
