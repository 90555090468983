const SYSTEM_TYPES = {
    FILE_PDF: 'file-pdf',
    FILE_XLS: 'file-xls',
    FILE_ODC: 'file-odc',
    FILE_XLSM: 'file-xlsm',
    FILE_PPT: 'file-ppt',
    FILE_ODS: 'file-ods',
    FILE_PPTM: 'file-pptm',
    FILE_ODT: 'file-odt',
    FILE_ODP: 'file-odp',
    FILE_MPP: 'file-mpp',
    FILE_PPTX: 'file-pptx',
    FILE_DOCM: 'file-docm',
    FILE_PPSX: 'file-ppsx',
    FILE_JPEG: 'file-jpeg',
    FILE_XLSX: 'file-xlsx',
    FILE_PNG: 'file-png',
    FILE_DOCX: 'file-docx',
    FILE_DEFAULT: 'file-default',
    PUBLIC_FOLDER: 'folder-regular',
    PRIVATE_FOLDER: 'folder-locked',
    PLAIN_DOCUMENT: 'plain-document',
    FOLDER: 'folder'
}

const FILE_FORMAT = {
    PDF: 'pdf',
    XLS: 'xls',
    ODC: 'odc',
    XLSM: 'xlsm',
    PPT: 'ppt',
    ODS: 'ods',
    PPTM: 'pptm',
    ODT: 'odt',
    ODP: 'odp',
    MPP: 'mpp',
    PPTX: 'pptx',
    DOCM: 'docm',
    PPSX: 'ppsx',
    JPEG: 'jpeg',
    JPG: 'jpg',
    XLSX: 'xlsx',
    PNG: 'png',
    DOCX: 'docx'
}

const SYSTEM_FOLDERS = {
    POLICIES_AND_PROCEDURES: 'policies_and_procedures_folder',
    LRSP: 'lrsp_folder',
    TEMPLATES_FOR_MANUAL_EDIT: 'templates_for_manual_editing_folder',
    ISO_27001: 'ISO 27001',
    COMPLIANCE_EVIDENCES: 'compliance_evidences_folder',
    ARCHIVE: 'archive_folder'
}

const DMS_ROUTES = {
    INDEX: { name: 'dms.index' },
    FILE_EXPLORER: { name: 'dms.explorer' },
    FILE_PREVIEW: { name: 'dms.preview' },
    PROPERTIES: { name: 'dw.custom.properties' },
    DISCUSSIONS: { name: 'dw.custom.discussions' }
}

const PERMISSIONS = {
    READ: 'read',
    WRITE: 'write'
}

const DEFAULT_SHAREABLE_LINK_DURATION = 365

const STEP_DOCUMENTS = {
    ISO_27001_PROJECT_PLAN: 'ISO_27001_project_plan',
    ISO_27001_PROCEDURE_FOR_DOCUMENT_AND_RECORD_CONTROL:
        'ISO_27001_procedure_for_document_and_record_control'
}

export {
    SYSTEM_TYPES,
    FILE_FORMAT,
    DMS_ROUTES,
    PERMISSIONS,
    DEFAULT_SHAREABLE_LINK_DURATION,
    SYSTEM_FOLDERS,
    STEP_DOCUMENTS
}
