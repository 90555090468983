<template>
    <div
        :class="[
            'cf-c-date-picker',
            { 'cf-c-date-picker--sm': sm },
            { 'cf-has-text': value }
        ]"
    >
        <div class="cf-c-date-picker__content">
            <input
                :type="type"
                :value="value"
                class="form-control flatpickr-input"
                :disabled="disabled"
                :name="name"
                @change="handleChange($event, type)"
            />
            <span v-if="!!errorMessage" class="cf-c-date-picker__error-msg">
                {{ errorMessage }}
            </span>
        </div>
    </div>
</template>

<script>
import {
    formatDate,
    formatTime,
    DATE_FORMATS,
    TIME_FORMATS
} from '@/services/utils/date'
import { DATETIME_FORMATS, DATETIME_PICKER_TYPE } from '@/constants'

export default {
    props: {
        value: {
            type: [String, Number, Date],
            required: false,
            default: null
        },

        errorMessage: {
            type: String,
            required: false,
            default: ''
        },

        sm: {
            type: Boolean,
            required: false,
            default: false
        },

        name: {
            type: String,
            required: false,
            default: ''
        },

        format: {
            type: String,
            required: false,
            default: DATETIME_FORMATS.DATE_PICKER
        },

        defaultDate: {
            type: [String, Number],
            required: false,
            default: null
        },

        enableTime: {
            type: Boolean,
            required: false,
            default: false
        },

        noCalendar: {
            type: Boolean,
            required: false,
            default: false
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        type: {
            type: String,
            required: false,
            default: DATETIME_PICKER_TYPE.DATE
        }
    },

    computed: {
        apiFormat () {
            return this.noCalendar && this.enableTime
                ? TIME_FORMATS.API
                : DATE_FORMATS.API
        }
    },

    methods: {
        handleChange (event, type) {
            let value
            if (type === DATETIME_PICKER_TYPE.DATE) {
                value = formatDate(event.target.value, this.apiFormat)
            } else {
                value = formatTime(event.target.value, this.apiFormat)
            }

            this.$emit('change', value)
        }
    }
}
</script>
