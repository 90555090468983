import {
    REGISTER_WIZARD_ROUTES,
    REGISTER_TYPES,
    MY_PERMISSIONS,
    REGISTERS_PERMISSIONS
} from '@/constants'
import { myPermissionsGuard } from '@src/router/guards'

const { MANAGEMENT_REVIEW } = REGISTER_WIZARD_ROUTES.REPORTING

export default {
    name: MANAGEMENT_REVIEW.INDEX.name,
    path: 'management-review',
    component: () =>
        import(
            '@/containers/ReportingDashboard/ManagementReview/ManagementReview'
        ),
    meta: {
        requiresAuth: true,
        register: REGISTER_TYPES.MANAGEMENT_REVIEW
    },
    redirect: to => ({
        name: MANAGEMENT_REVIEW.ACTIVITIES.INDEX.name,
        params: { ...to.params }
    }),
    children: [
        {
            path: 'activities',
            name: MANAGEMENT_REVIEW.ACTIVITIES.INDEX.name,
            component: () =>
                import(
                    '@/containers/ReportingDashboard/ManagementReview/ManagementReviewActivities'
                ),
            redirect: to => ({
                name: MANAGEMENT_REVIEW.ACTIVITIES.WIZARD.name,
                params: { ...to.params }
            }),
            meta: {
                requiresAuth: true,
                register: REGISTER_TYPES.MANAGEMENT_REVIEW
            },
            children: [
                {
                    path: 'wizard',
                    name: MANAGEMENT_REVIEW.ACTIVITIES.WIZARD.name,
                    component: () =>
                        import(
                            '@/components/reporting/management-review/activities/WizardTab'
                        ),
                    props: true,
                    meta: {
                        pageTitle: 'REPORTING.MANAGEMENT_REVIEW.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.MANAGEMENT_REVIEW,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.MANAGEMENT_REVIEW_ACCESS,
                            REGISTERS_PERMISSIONS.MANAGEMENT_REVIEWS
                        ]
                    }
                },
                {
                    path: 'properties',
                    name: MANAGEMENT_REVIEW.ACTIVITIES.PROPERTIES.name,
                    component: () =>
                        import('@/components/registers/PropertiesTab'),
                    props: true,
                    meta: {
                        pageTitle: 'REPORTING.MANAGEMENT_REVIEW.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.MANAGEMENT_REVIEW,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.MANAGEMENT_REVIEW_ACCESS,
                            REGISTERS_PERMISSIONS.MANAGEMENT_REVIEWS
                        ]
                    }
                },
                {
                    path: 'discussions',
                    name: MANAGEMENT_REVIEW.ACTIVITIES.DISCUSSIONS.name,
                    component: () =>
                        import('@/components/registers/Discussions'),
                    props: true,
                    meta: {
                        pageTitle: 'REPORTING.MANAGEMENT_REVIEW.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.MANAGEMENT_REVIEW,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.MANAGEMENT_REVIEW_ACCESS,
                            REGISTERS_PERMISSIONS.MANAGEMENT_REVIEWS
                        ]
                    }
                }
            ]
        },
        {
            path: 'my-items',
            name: MANAGEMENT_REVIEW.IN_PROGRESS.INDEX.name,
            component: () =>
                import(
                    '@/containers/ReportingDashboard/ManagementReview/ManagementReviewInProgress'
                ),
            redirect: to => ({
                name: MANAGEMENT_REVIEW.IN_PROGRESS.WIZARD.name,
                params: { ...to.params }
            }),
            meta: {
                requiresAuth: true,
                register: REGISTER_TYPES.MANAGEMENT_REVIEW
            },
            children: [
                {
                    path: 'wizard',
                    name: MANAGEMENT_REVIEW.IN_PROGRESS.WIZARD.name,
                    component: () =>
                        import(
                            '@/components/reporting/management-review/in-progress/WizardTab'
                        ),
                    props: true,
                    meta: {
                        pageTitle: 'REPORTING.MANAGEMENT_REVIEW.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.MANAGEMENT_REVIEW,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.MANAGEMENT_REVIEW_ACCESS,
                            REGISTERS_PERMISSIONS.MANAGEMENT_REVIEWS
                        ]
                    }
                },
                {
                    path: 'properties',
                    name: MANAGEMENT_REVIEW.IN_PROGRESS.PROPERTIES.name,
                    component: () =>
                        import('@/components/registers/PropertiesTab'),
                    props: true,
                    meta: {
                        pageTitle: 'REPORTING.MANAGEMENT_REVIEW.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.MANAGEMENT_REVIEW,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.MANAGEMENT_REVIEW_ACCESS,
                            REGISTERS_PERMISSIONS.MANAGEMENT_REVIEWS
                        ]
                    }
                },
                {
                    path: 'discussions',
                    name: MANAGEMENT_REVIEW.IN_PROGRESS.DISCUSSIONS.name,
                    component: () =>
                        import('@/components/registers/Discussions'),
                    props: true,
                    meta: {
                        pageTitle: 'REPORTING.MANAGEMENT_REVIEW.TITLE',
                        withScaling: true,
                        requiresAuth: true,
                        register: REGISTER_TYPES.MANAGEMENT_REVIEW,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard],
                        permissions: [
                            MY_PERMISSIONS.MANAGEMENT_REVIEW_ACCESS,
                            REGISTERS_PERMISSIONS.MANAGEMENT_REVIEWS
                        ]
                    }
                }
            ]
        }
    ]
}
