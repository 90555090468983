import { riskOfRequirementsService } from './api'
import { STATUSES } from './constants'

const store = {
    namespaced: true,

    state: {
        isLoading: false,
        isRequirementsInitiallyLoaded: false,
        isAnyRequirementUpdated: false,
        requirements: [],
        generatedDocument: null
    },

    actions: {
        async getRequirementsData (
            { commit, rootGetters, dispatch },
            regulationId
        ) {
            commit('SET_APP_LOADING', true, { root: true })
            try {
                const companyId = rootGetters['company/company'].id

                await dispatch('getRequirements', regulationId)
                !rootGetters['loading/areasFetched'] &&
                    (await dispatch('general/getAreas', null, { root: true }))
                !rootGetters['loading/lawsFetched'] &&
                    (await dispatch(
                        'general/getLaws',
                        { companyId, regulationId },
                        { root: true }
                    ))
                !rootGetters['loading/countriesFetched'] &&
                    (await dispatch('general/getCountries', null, {
                        root: true
                    }))
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },

        async getRequirements ({ commit, rootGetters, dispatch }) {
            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                const response = await riskOfRequirementsService.getRequirements(
                    companyId,
                    regulationId
                )

                const requirements = response.map(requirement => {
                    return {
                        id: requirement.id,
                        type: requirement.type,
                        responsibleUser: requirement.responsible_person ?? null,
                        interestedParty: requirement.interested_party,
                        status: !!requirement.is_compliant
                            ? STATUSES.COMPLIANT
                            : STATUSES.NON_COMPLIANT
                    }
                })

                commit('setRequirements', requirements)
            } catch (err) {
                dispatch('errors/handleError', err, { root: true })
                throw err
            } finally {
                commit('setIsRequirementsInitiallyLoaded', true)
            }
        },

        async createRequirement ({ commit, rootGetters, dispatch }, payload) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                const response = await riskOfRequirementsService.createRequirement(
                    companyId,
                    regulationId,
                    payload
                )

                const requirement = {
                    id: response.id,
                    interestedParty: response.interested_party,
                    type: response.type,
                    responsibleUser: response.responsible_person ?? null,
                    status: response.is_compliant
                        ? STATUSES.COMPLIANT
                        : STATUSES.NON_COMPLIANT
                }

                commit('updateRequirements', requirement)
                commit('setIsAnyRequirementUpdated', true)
                commit('general/ADD_LAW', response.law, { root: true })
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
                throw error
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },

        async updateRequirement ({ commit, rootGetters, dispatch }, payload) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                const response = await riskOfRequirementsService.updateRequirement(
                    companyId,
                    regulationId,
                    payload.stepId,
                    payload.id,
                    payload
                )

                const requirement = {
                    id: response.id,
                    interestedParty: response.interested_party,
                    type: response.type,
                    responsibleUser: response.responsible_person ?? null,
                    status: response.is_compliant
                        ? STATUSES.COMPLIANT
                        : STATUSES.NON_COMPLIANT
                }

                commit('updateRequirements', requirement)
                commit('setIsAnyRequirementUpdated', true)
                commit('general/ADD_LAW', response.law, { root: true })
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
                throw error
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },

        async deleteRequirement (
            { commit, rootGetters, dispatch },
            requirementId
        ) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                await riskOfRequirementsService.deleteRequirement(
                    companyId,
                    regulationId,
                    requirementId
                )

                commit('removeRequirement', requirementId)
                commit('setIsAnyRequirementUpdated', true)
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
                throw error
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },

        async getRequirementDetails (
            { commit, rootGetters, dispatch },
            requirementId
        ) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulationId =
                    rootGetters['regulation/currentRegulation'].id

                const response = await riskOfRequirementsService.getRequirementDetails(
                    companyId,
                    regulationId,
                    requirementId
                )

                return {
                    ...response,
                    areas: response.areas.map(area => {
                        return {
                            value: area.id,
                            label: area.name
                        }
                    }),
                    status: response.is_compliant
                        ? STATUSES.COMPLIANT
                        : STATUSES.NON_COMPLIANT
                }
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },

        async confirmInitialRequirements (
            { commit, rootGetters, dispatch },
            stepId
        ) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulation = rootGetters['regulation/currentRegulation']

                const response = await riskOfRequirementsService.confirmInitialRequirements(
                    companyId,
                    regulation.id,
                    stepId
                )

                const payload = {
                    ...regulation,
                    pivot: { ...regulation.pivot, requirements_confirmed: true }
                }

                commit('setGeneratedDocument', response)
                commit(
                    'company/UPDATE_REGULATION',
                    { regulationId: regulation.id, regulation: payload },
                    { root: true }
                )
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        },

        async exportRequirementsAsPdf (
            { commit, rootGetters, dispatch },
            stepId
        ) {
            commit('SET_APP_LOADING', true, { root: true })

            try {
                const companyId = rootGetters['company/company'].id
                const regulation = rootGetters['regulation/currentRegulation']

                const response = await riskOfRequirementsService.exportPdf(
                    companyId,
                    regulation.id,
                    stepId
                )

                commit('setGeneratedDocument', response)
                commit('setIsAnyRequirementUpdated', false)
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        }
    },

    mutations: {
        setIsLoading (state, isLoading) {
            state.isLoading = isLoading
        },

        setRequirements (state, requirements) {
            state.requirements = requirements
        },

        setIsAnyRequirementUpdated (state, value) {
            state.isAnyRequirementUpdated = value
        },

        updateRequirements (state, requirement) {
            const exist = state.requirements.find(
                obj => obj.id === requirement.id
            )

            if (exist) {
                state.requirements = state.requirements.map(obj => {
                    if (obj.id === requirement.id) {
                        return requirement
                    }
                    return obj
                })
            } else {
                state.requirements = [requirement, ...state.requirements]
            }
        },

        removeRequirement (state, requirementId) {
            state.requirements = state.requirements.filter(
                requirement => requirement.id !== requirementId
            )
        },

        setIsRequirementsInitiallyLoaded (state, value) {
            state.isRequirementsInitiallyLoaded = value
        },

        setGeneratedDocument (state, doc) {
            state.generatedDocument = doc
        }
    }
}

export { store }
