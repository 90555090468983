<template>
    <vue-pdf-embed
        v-if="file"
        :source="file"
        :disable-text-layer="true"
        @rendered="handleRendered"
        @loading-failed="handleError"
        @rendering-failed="handleError"
    />
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
    components: {
        VuePdfEmbed
    },

    props: {
        file: {
            type: String,
            required: false,
            default: ''
        }
    },

    data () {
        return {
            totalNumberOfPages: 1,
            percentLoaded: 0,
            fullyLoaded: false
        }
    },

    methods: {
        handleRendered () {
            this.$emit('fully-loaded')
        },

        handleError (error) {
            this.$emit('error', error)
        }
    }
}
</script>
