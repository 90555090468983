<template>
    <div class="cf-c-file-item">
        <div class="cf-c-file-item__icon-wrap">
            <div
                v-if="path"
                class="cf-u-color-primary cf-u-link"
                @click="download"
            >
                <app-icon icon="file-icon" size="lg" />
            </div>
            <app-icon v-else icon="file-icon" size="lg" />
            <app-icon
                v-if="removable"
                class="cf-c-file-item__remove cf-u-display-flex"
                icon="close-icon"
                size="xs"
                @click="handleDelete"
            />
        </div>
        <div class="cf-c-file-item__name" :title="name">
            <div
                v-if="path"
                target="_blank"
                class="cf-u-color-primary cf-c-file-item__name-link cf-u-link"
                @click="download"
            >
                <span class="cf-c-file-item__file-name">{{ name }}</span>
            </div>
            <span v-else class="cf-c-file-item__file-name">{{ name }}</span>

            <!--      TODO: if we want to see always file extension uncomment code below-->
            <!--      <span class="cf-c-file-item__name-ext">.txt</span>-->
        </div>
    </div>
</template>

<script>
import { fileService } from '@/services/api/file'
import { mapGetters } from 'vuex'

export default {
    props: {
        name: {
            type: String,
            required: true
        },

        path: {
            type: String,
            required: false,
            default: ''
        },

        removable: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        ...mapGetters('company', ['company'])
    },

    methods: {
        handleDelete () {
            this.removable && this.$emit('file-deleted')
        },

        async download () {
            await fileService.downloadFile(this.company.id, this.path)
        }
    }
}
</script>
