import { mapActions } from 'vuex'
import { REGISTER_OF_RISKS, REGISTER_WIZARD_ROUTES } from '@/constants'
import { debounce } from 'lodash-es'

const { STEPS, MODES } = REGISTER_OF_RISKS

export default {
    computed: {
        cacheSubmitted () {
            return this.$route.params.cacheSubmitted ?? false
        }
    },

    methods: {
        ...mapActions('risks', ['submitRisksCache']),

        submitCache: debounce(async function () {
            await this.submitRisksCache()
        }, REGISTER_OF_RISKS.SUBMIT_RISKS_CACHE_INTERVAL),

        redirectToSimpleModeAssets () {
            this.$router
                .push({
                    name:
                        REGISTER_WIZARD_ROUTES.RISKS.UNCOMPLETED_REGISTER.name,
                    query: {
                        step: STEPS.ASSETS,
                        mode: MODES.SIMPLE_MODE
                    }
                })
                .catch(() => {})
        }
    }
}
