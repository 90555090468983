<template>
    <div class="cf-c-links-list">
        <div
            v-for="(item, index) in list"
            :key="index"
            class="cf-c-links-list__item"
        >
            <div class="cf-c-links-list-item">
                <strong class="cf-c-links-list-item__label">{{
                    item[label]
                }}</strong>
                <div class="cf-c-links-list-item__dot">
                    <app-icon icon="semaphore-icon" size="xs" />
                </div>
                <div class="cf-c-links-list-item__desc">
                    {{ item.description }}
                </div>
                <router-link
                    class="cf-c-links-list-item__link"
                    :to="item.to"
                    target="_blank"
                >
                    {{ $t('COMMON.LINK') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            required: true
        },

        label: {
            type: String,
            required: false,
            default: 'label'
        }
    }
}
</script>
