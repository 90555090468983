<template>
    <header class="app-header">
        <app-icon icon="icon-logo" class="app-header__logo" size="custom" />
    </header>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@style/sfc.scss';

.app-header {
    display: flex;
    height: rem($height-app-header);
    padding: rem(16) rem(24);
    z-index: $z-index-app-navigation;
    justify-content: center;
    align-items: center;
    background-color: $color-primary-beta;

    &__logo {
        height: 100%;
        width: auto;

        ::v-deep .cf-c-icon--custom {
            height: rem(50);
            width: rem(190);
        }
    }
}
</style>
