const REGISTER_TYPE = 'trainings'

const ROUTES_TRAININGS = {
    BASE: {
        name: 'trainings'
    }
}

const NOT_MANDATORY_FIELDS = ['note', 'training_date']

const STATUSES = {
    SUGGESTED: 'suggested',
    APPROVED: 'approved',
    CHANGED_REQUIREMENTS: 'changed_requirements',
    SCHEDULED: 'scheduled',
    PERFORMED: 'performed',
    CANCELED: 'canceled'
}

const STATUSES_MAPPED = {
    [STATUSES.SUGGESTED]: {
        colorClass: 'color-primary-epsilon',
        label: 'TRAININGS.STATUSES.SUGGESTED'
    },
    [STATUSES.APPROVED]: {
        colorClass: 'color-success-alpha',
        label: 'TRAININGS.STATUSES.APPROVED'
    },
    [STATUSES.CHANGED_REQUIREMENTS]: {
        colorClass: 'color-warning-alpha',
        label: 'TRAININGS.STATUSES.CHANGED_REQUIREMENTS'
    },
    [STATUSES.SCHEDULED]: {
        colorClass: 'color-warning-gamma',
        label: 'TRAININGS.STATUSES.SCHEDULED'
    },
    [STATUSES.PERFORMED]: {
        colorClass: 'color-success-alpha',
        label: 'TRAININGS.STATUSES.PERFORMED'
    },
    [STATUSES.CANCELED]: {
        colorClass: 'color-primary-beta',
        label: 'TRAININGS.STATUSES.CANCELED'
    }
}

const NOTE_MAX_CHARACTERS = 200

export {
    REGISTER_TYPE,
    ROUTES_TRAININGS,
    NOT_MANDATORY_FIELDS,
    STATUSES,
    STATUSES_MAPPED,
    NOTE_MAX_CHARACTERS
}
