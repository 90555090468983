import axios from 'axios'

const ENDPOINTS = {
    MY_STEPS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/my-steps`,
    STEPS: 'companies/:companyId/regulations/:regulationId/steps',
    STEP: 'companies/:companyId/regulations/:regulationId/steps/:stepId',
    START_STEP: (companyId, regulationId, stepId) =>
        `companies/${companyId}/regulations/${regulationId}/steps/${stepId}/start`,
    DISABLE_STEP_PREDEFINED_TRAININGS: (companyId, regulationId, stepId) =>
        `companies/${companyId}/regulations/${regulationId}/steps/${stepId}/hide-predefined-trainings`,
    INPUTS:
        'companies/:companyId/regulations/:regulationId/steps/:stepId/inputs',
    TEMPLATE:
        'companies/:companyId/regulations/:regulationId/steps/:stepId/templates/:templateId',
    DOCUMENT_PROPERTIES:
        'companies/:companyId/document/:documentId/version/:versionId',
    STEP_RESOURCES:
        'companies/:companyId/regulations/:regulationId/steps/:stepId/resources',
    CLASSIFICATION_LEVELS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/classification-levels`,
    RESOURCES: 'companies/:companyId/regulations/:regulationId/resources',
    RESOURCE_APPROVAL:
        'companies/:companyId/regulations/:regulationId/resources/:resourceId/start-approval',
    END_DATE: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/end-date`,
    SET_CERTIFICATION_DATE: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/certification-date`
}

export default class RegulationService {
    /**
     * Get all steps for a regulation
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getSteps (companyId, regulationId) {
        try {
            const route = ENDPOINTS.STEPS.replace(
                ':companyId',
                companyId
            ).replace(':regulationId', regulationId)
            const { data } = await axios.get(route)
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get single step data for step overview page
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} stepId
     *
     * @returns Promise
     */
    async getStep (companyId, regulationId, stepId) {
        try {
            const route = ENDPOINTS.STEP.replace(':companyId', companyId)
                .replace(':regulationId', regulationId)
                .replace(':stepId', stepId)
            const { data } = await axios.get(route)
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get steps belonging to user
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getUsersSteps (companyId, regulationId) {
        try {
            const route = ENDPOINTS.MY_STEPS(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get inputs for a specific step
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @pram {String|Number} stepId
     *
     * @return Promise
     */
    async getStepInputs (companyId, regulationId, stepId) {
        try {
            const { data } = await axios.get(
                this.getInputsRoute(companyId, regulationId, stepId)
            )
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get resources for a specific step
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} stepId
     *
     * @return Promise
     */
    async getStepResources (companyId, regulationId, stepId) {
        try {
            const route = ENDPOINTS.STEP_RESOURCES.replace(
                ':companyId',
                companyId
            )
                .replace(':regulationId', regulationId)
                .replace(':stepId', stepId)

            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get classification levels for company and regulation
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @return Promise
     */
    async getClassificationLevels (companyId, regulationId) {
        try {
            const route = ENDPOINTS.CLASSIFICATION_LEVELS(
                companyId,
                regulationId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Submit regulation form
     *
     * @param {Object} data
     *
     * @returns Promise
     */
    async submitRegulationInputs (data) {
        const { companyId, regulationId, stepId, payload } = data
        try {
            const { data } = await axios.post(
                this.getInputsRoute(companyId, regulationId, stepId),
                payload
            )
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Submit regulation form
     *
     * @param {Object} data
     *
     * @returns Promise
     */
    async submitStepResources (data) {
        const { companyId, regulationId, payload } = data
        try {
            const route = ENDPOINTS.RESOURCES.replace(
                ':companyId',
                companyId
            ).replace(':regulationId', regulationId)

            const { data } = await axios.post(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Submit regulation form
     *
     * @param {Object} data
     *
     * @returns Promise
     */
    async approveStepResource (data) {
        const { companyId, regulationId, resourceId, stepId } = data
        try {
            const route = ENDPOINTS.RESOURCE_APPROVAL.replace(
                ':companyId',
                companyId
            )
                .replace(':regulationId', regulationId)
                .replace(':resourceId', resourceId)

            const { data } = await axios.post(route, {
                step_id: stepId
            })

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update regulation form
     *
     * @param {Object} data
     *
     * @returns Promise
     */
    async updateRegulationInputs (data) {
        const { companyId, regulationId, stepId, payload } = data
        try {
            const { data } = await axios.put(
                this.getInputsRoute(companyId, regulationId, stepId),
                payload
            )
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update document wizard properties form
     *
     * @param {Object} data
     *
     * @returns Promise
     */
    async updateDocumentProperties (data) {
        const { companyId, documentId, versionId, payload } = data
        try {
            const { data } = await axios.put(
                this.getDocumentPropertiesRoute(
                    companyId,
                    documentId,
                    versionId
                ),
                payload
            )
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Perform a trigger defined action
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String} url
     *
     * @returns Promise
     */
    async triggerAction (companyId, regulationId, url) {
        try {
            const route = url
                .replace('{company}', companyId)
                .replace('{regulation}', regulationId)

            const { data } = await axios.get(route)
            return data
        } catch (error) {
            throw error
        }
    }

    async getTemplateData (
        dataType,
        companyId,
        regulationId,
        stepId,
        templateId,
        accessToken
    ) {
        try {
            const route = this.getTemplateRoute(
                companyId,
                regulationId,
                stepId,
                templateId,
                accessToken
            )

            const { data } = await axios.get(route, {
                params: { data: dataType }
            })

            return data
        } catch (error) {
            throw error
        }
    }

    getTemplateRoute (
        companyId,
        regulationId,
        stepId,
        templateId,
        accessToken
    ) {
        let route = ENDPOINTS.TEMPLATE.replace(':companyId', companyId)
            .replace(':regulationId', regulationId)
            .replace(':stepId', stepId)
            .replace(':templateId', templateId)

        if (accessToken) {
            route += `?access_token=${accessToken}`
        }

        return route
    }

    async finishStep (companyId, regulationId, stepId) {
        try {
            const route = ENDPOINTS.STEP.replace(':companyId', companyId)
                .replace(':regulationId', regulationId)
                .replace(':stepId', stepId)

            const { data } = await axios.put(route, { finish_step: 1 })
            return data
        } catch (error) {
            throw error
        }
    }

    async updateCompanyRegulationStep (companyId, regulationId, step) {
        try {
            const route = ENDPOINTS.STEP.replace(':companyId', companyId)
                .replace(':regulationId', regulationId)
                .replace(':stepId', step.id)
            const { data } = await axios.put(route, step)

            return data
        } catch (error) {
            throw error
        }
    }

    async startStep (companyId, regulationId, stepId) {
        try {
            const route = ENDPOINTS.START_STEP(companyId, regulationId, stepId)
            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }

    async disableStepPredefinedTrainings (companyId, regulationId, stepId) {
        try {
            const route = ENDPOINTS.DISABLE_STEP_PREDEFINED_TRAININGS(
                companyId,
                regulationId,
                stepId
            )
            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }

    getInputsRoute (companyId, regulationId, stepId) {
        return ENDPOINTS.INPUTS.replace(':companyId', companyId)
            .replace(':regulationId', regulationId)
            .replace(':stepId', stepId)
    }

    getDocumentPropertiesRoute (companyId, documentId, versionId) {
        let route = ENDPOINTS.DOCUMENT_PROPERTIES.replace(
            ':companyId',
            companyId
        )
            .replace(':documentId', documentId)
            .replace(':versionId', versionId)

        return route
    }

    async getEndDate (companyId, regulationId) {
        try {
            const route = ENDPOINTS.END_DATE(companyId, regulationId)
            const { data } = await axios.get(route)
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Sets date of certification
     *
     * @param {Number|String} companyId
     * @param {Number|String} regulationId
     * @param {Number|String} evidenceId
     * @param {Object} payload
     *
     * @returns Promise
     */
    async setCertificationDate (companyId, regulationId, payload) {
        try {
            const route = ENDPOINTS.SET_CERTIFICATION_DATE(
                companyId,
                regulationId
            )

            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const regulationService = new RegulationService()
