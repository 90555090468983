<template>
    <canvas ref="chart" role="img"></canvas>
</template>

<script>
import Chart from 'chart.js'
import { CHART_TYPES } from '@/constants'

export default {
    props: {
        dataset: {
            type: Array,
            required: true
        },

        cutoutPercentage: {
            type: Number,
            required: false,
            default: 60
        },

        backgroundColors: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    data () {
        return {
            chart: null
        }
    },

    watch: {
        dataset: {
            handler () {
                this.attachChart()
            },
            deep: true,
            immediate: true
        }
    },

    mounted () {
        this.dataset.length && this.attachChart()
    },

    beforeDestroy () {
        this.detachChart()
    },

    methods: {
        attachChart () {
            if (!this.$refs.chart) {
                return
            }

            this.chart = new Chart(this.$refs.chart, {
                type: CHART_TYPES.DOUGHNUT,
                data: {
                    datasets: [
                        {
                            data: this.dataset,
                            backgroundColor: this.backgroundColors,
                            borderWidth: 0
                        }
                    ]
                },
                options: {
                    cutoutPercentage: this.cutoutPercentage,
                    maintainAspectRatio: false,
                    tooltips: false,
                    hover: { mode: null }
                }
            })
        },

        detachChart () {
            this.chart.destroy()
        }
    }
}
</script>
