import { find, each, keys, findIndex } from 'lodash-es'
import { soaService } from '@/services/api/soa'
import { resourcesService } from '@/services/api/resources'
import { SOA, RESOURCES, DOCUMENT_STATUSES } from '@/constants'

function getInitialState () {
    return {
        controls: [],
        controlsSections: [],
        controlsPerSections: [],
        implementationMethods: [],
        unnecessarySteps: []
    }
}

const state = getInitialState()

const actions = {
    async sendToApproval (
        { commit, dispatch, rootGetters },
        { resourceIds, register: companyRegister }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            await soaService.sendToApproval(companyId, regulationId, {
                resource_ids: resourceIds
            })
            await dispatch('getImplementationMethods')
            commit(
                'register/UPDATE_COMPANY_REGULATION_REGISTER',
                {
                    registerId: companyRegister.register_id,
                    payload: { status: SOA.STATUSES.RESOURCES_IN_REVIEW }
                },
                { root: true }
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getImplementationMethods ({ commit, dispatch, rootGetters }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const implementationMethods = await soaService.getImplementationMethods(
                companyId,
                regulationId
            )
            commit('SET_IMPLEMENTATION_METHODS', implementationMethods)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async addControlTask (
        { commit, rootGetters, dispatch, getters },
        { regulationId, data }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const companyControl = await soaService.addControlTask(
                companyId,
                regulationId,
                data
            )

            await commit('UPDATE_COMPANY_CONTROL_IN_CONTROLS', {
                id: companyControl.id,
                data: companyControl
            })

            commit('SET_CONTROLS_PER_SECTIONS', getters.controlsSections)

            await dispatch(
                'register/getCurrentCompanyRegulationRegister',
                rootGetters['register/currentCompanyRegulationRegister']
                    .register_id,
                { root: true }
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateControlTask ({ commit, rootGetters, dispatch, getters }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const companyControl = await soaService.updateControlTask(
                companyId,
                regulationId,
                data.id,
                data
            )

            await commit('UPDATE_COMPANY_CONTROL_IN_CONTROLS', {
                id: companyControl.id,
                data: companyControl
            })

            commit('SET_CONTROLS_PER_SECTIONS', getters.controlsSections)

            await dispatch(
                'register/getCurrentCompanyRegulationRegister',
                rootGetters['register/currentCompanyRegulationRegister']
                    .register_id,
                { root: true }
            )

            commit(
                'soa/SET_DUE_DATE',
                {
                    data: { deadline: data.due_date, id: data.id },
                    key: 'due_date'
                },
                { root: true }
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async removeControlTask (
        { commit, rootGetters, dispatch, getters },
        { regulationId, id }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const companyControl = await soaService.removeControlTask(
                companyId,
                regulationId,
                id
            )

            await commit('UPDATE_COMPANY_CONTROL_IN_CONTROLS', {
                id: companyControl.id,
                data: companyControl
            })

            commit('SET_CONTROLS_PER_SECTIONS', getters.controlsSections)

            await dispatch(
                'register/getCurrentCompanyRegulationRegister',
                rootGetters['register/currentCompanyRegulationRegister']
                    .register_id,
                { root: true }
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async addControlDocument (
        { commit, rootGetters, dispatch, getters },
        { regulationId, companyControlId, ...rest }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id

            const companyControl = await soaService.addControlDocument(
                companyId,
                regulationId,
                companyControlId,
                rest
            )

            await commit('UPDATE_COMPANY_CONTROL_IN_CONTROLS', {
                id: companyControl.id,
                data: companyControl
            })

            commit('SET_CONTROLS_PER_SECTIONS', getters.controlsSections)

            // Steps should be reloaded
            await dispatch(
                'regulation/getSteps',
                { regulationId },
                { root: true }
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async removeControlDocument (
        { commit, rootGetters, dispatch, getters },
        { regulationId, companyControlId, stepId }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id

            const companyControl = await soaService.removeControlDocument(
                companyId,
                regulationId,
                companyControlId,
                stepId
            )

            await commit('UPDATE_COMPANY_CONTROL_IN_CONTROLS', {
                id: companyControl.id,
                data: companyControl
            })

            commit('SET_CONTROLS_PER_SECTIONS', getters.controlsSections)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getControlsPerSections (
        { commit, rootGetters, dispatch },
        regulationId
    ) {
        commit('SET_CONTROLS_PER_SECTIONS', [])
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id

            const controlsSections = await soaService.getControlsPerSections(
                companyId,
                regulationId
            )

            commit('SET_CONTROLS_SECTIONS', controlsSections)

            commit('SET_CONTROLS_PER_SECTIONS', controlsSections)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getControls ({ commit, dispatch, rootGetters }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            if (
                !(
                    rootGetters['loading/controlsLoading'] &&
                    rootGetters['loading/controlsFetched']
                )
            ) {
                const controls = await soaService.getControls(
                    companyId,
                    regulationId
                )

                commit('SET_CONTROLS', controls)
            }
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async update (
        { commit, dispatch, rootGetters, getters },
        { companyControlId, data }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const responseData = await soaService.update(
                companyId,
                regulationId,
                companyControlId,
                data
            )

            await commit('UPDATE_COMPANY_CONTROL_IN_CONTROLS', {
                id: companyControlId,
                data: responseData
            })

            commit('SET_CONTROLS_PER_SECTIONS', getters.controlsSections)

            await dispatch(
                'register/getCurrentCompanyRegulationRegister',
                rootGetters['register/currentCompanyRegulationRegister']
                    .register_id,
                { root: true }
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async bulkUpdate ({ commit, dispatch, rootGetters }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await soaService.bulkUpdate(companyId, regulationId, data)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async markControlsAsSeen ({ commit, dispatch, rootGetters }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await soaService.markControlsAsSeen(companyId, regulationId, data)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async saveResource ({ dispatch, rootGetters, commit }, resource) {
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const result = await resourcesService.save(
                companyId,
                regulationId,
                resource
            )
            commit('SAVE_RESOURCE', { resource: result, type: resource.type })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async getUnnecessarySteps ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const steps = await soaService.getUnnecessarySteps(
                companyId,
                regulationId
            )

            commit('SET_UNNECESSARY_STEPS', steps)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async syncSteps ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await soaService.syncSteps(companyId, regulationId)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateDocumentsForControl (
        { commit, rootGetters, dispatch, getters },
        { controlId, documentsIds }
    ) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const data = await soaService.updateDocumentsForControl(
                companyId,
                regulationId,
                controlId,
                { document_ids: documentsIds }
            )

            await commit('UPDATE_COMPANY_CONTROL_IN_CONTROLS', {
                id: data.id,
                data
            })

            commit('SET_CONTROLS_PER_SECTIONS', getters.controlsSections)

            await dispatch(
                'register/getCurrentCompanyRegulationRegister',
                rootGetters['register/currentCompanyRegulationRegister']
                    .register_id,
                { root: true }
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async deleteRtpResource ({ commit, rootGetters, dispatch }, resourceId) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await resourcesService.deleteRtpResource(
                companyId,
                regulationId,
                resourceId
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async approveStatementOfApplicability ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await soaService.approveStatementOfApplicability(
                companyId,
                regulationId
            )
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    }
}

const mutations = {
    SET_DUE_DATE (state, { data, key }) {
        const { deadline, id } = data
        const index = findIndex(state.implementationMethods, { id: id })

        if (index !== -1) {
            state.implementationMethods.splice(index, 1, {
                ...state.implementationMethods[index],
                [key]: deadline
            })
        }
    },

    SET_RESPONSIBILITY_PERSON (state, { data, key }) {
        const { step_assignee: stepAssignee, id } = data
        const index = findIndex(state.implementationMethods, { id: id })

        if (index !== -1) {
            state.implementationMethods.splice(index, 1, {
                ...state.implementationMethods[index],
                [key]: stepAssignee
            })
        }
    },

    SAVE_RESOURCE (state, { resource, type: resourceType }) {
        const {
            targetable_id: id,
            targetable_type: type,
            value,
            status
        } = resource

        const index = findIndex(state.implementationMethods, { id, type })

        if (index !== -1) {
            state.implementationMethods.splice(index, 1, {
                ...state.implementationMethods[index],
                [resourceType]: {
                    id: resource.id,
                    value,
                    status: status.name
                }
            })
        }
    },

    SET_IMPLEMENTATION_METHODS (state, { companyRegulationSteps, tasks }) {
        state.implementationMethods = [
            ...companyRegulationSteps.map(prepareCompanyRegulationStep),
            ...tasks.map(prepareTask)
        ]
    },

    SET_UNNECESSARY_STEPS (state, companyRegulationSteps) {
        const unnecessarySteps = []

        companyRegulationSteps.forEach(companyRegulationStep => {
            unnecessarySteps.push({
                name: companyRegulationStep.step.name
            })
        })

        state.unnecessarySteps = unnecessarySteps
    },

    RESET_IMPLEMENTATION_METHODS (state) {
        state.implementationMethods = []
    },

    SET_CONTROLS_PER_SECTIONS (state, controlsSections) {
        const prepareJustification = control => {
            const requirements = control.justification_requirements.map(
                item => ({
                    id: item.id,
                    text: item.label,
                    type: SOA.TAG_TYPES.REQUIREMENT
                })
            )

            const risks = control.justification_risks.map(item => ({
                id: item.id,
                text: item.label,
                type: SOA.TAG_TYPES.RISK_REGISTER,
                description: item.description
            }))

            const justification = [...risks, ...requirements]

            if (control.company_control.justification) {
                justification.push({
                    text: control.company_control.justification,
                    type: SOA.TAG_TYPES.TEXT
                })
            }

            return justification
        }

        const prepareImplementationMethod = control => {
            const implementationMethod = []

            control.company_control.company_control_steps.forEach(item => {
                let stepDocumentName = item.name
                const document = item.document

                if (
                    document &&
                    document.current_status === DOCUMENT_STATUSES.WITHDRAWN
                ) {
                    stepDocumentName = SOA.WITHDRAWN_FORMAT(stepDocumentName)
                }

                implementationMethod.push({
                    text: stepDocumentName,
                    type: SOA.IMPLEMENTATION_METHOD_TYPES.DOCUMENT,
                    id: item.id,
                    definedByRegulation: item.pivot.defined_by_regulation === 1,
                    can_access_step: item.can_access_step
                })
            })

            control.company_control.company_control_tasks.forEach(item => {
                implementationMethod.push({
                    text: item.title,
                    type: SOA.IMPLEMENTATION_METHOD_TYPES.TASK,
                    id: item.id,
                    task: { assigned_to: item.assignee_id, ...item }
                })
            })

            if (control.company_control.implementation_method) {
                implementationMethod.push({
                    text: control.company_control.implementation_method,
                    type: SOA.IMPLEMENTATION_METHOD_TYPES.TEXT
                })
            }

            return implementationMethod
        }

        const prepareControls = controlsSections => {
            return controlsSections.map(control => {
                let {
                    id: controlId,
                    name,
                    code,
                    company_control: companyControl,
                    document_mandatory: documentMandatory
                } = control
                let {
                    id,
                    is_applicable: isApplicable,
                    review_needed: reviewNeeded,
                    reason,
                    implemented_prior: implementedPrior
                } = companyControl

                let status = companyControl.state
                    ? companyControl.state.name
                    : null

                let justification = prepareJustification(control)
                let implementationMethod = prepareImplementationMethod(control)

                return {
                    id,
                    controlId,
                    name,
                    code,
                    isApplicable,
                    status,
                    justification,
                    implementationMethod,
                    documentMandatory,
                    reviewNeeded,
                    reason,
                    implementedPrior
                }
            })
        }

        const controlsPerSections = controlsSections.map(item => {
            let { code, name } = item

            return {
                code,
                name,
                controls: item.subsections.reduce(
                    (controls, current) => [
                        ...controls,
                        ...prepareControls(current.controls)
                    ],
                    []
                )
            }
        })

        state.controlsPerSections = controlsPerSections
    },

    SET_CONTROLS (state, controls) {
        state.controls = controls
    },

    SET_CONTROLS_SECTIONS (state, controlsSections) {
        state.controlsSections = controlsSections
    },

    RESET_SOA (state) {
        const s = getInitialState()
        each(keys(state), key => {
            state[key] = s[key]
        })
    },

    UPDATE_COMPANY_CONTROL_IN_CONTROLS (state, { id, data }) {
        for (const subSectionsIndex in state.controlsSections) {
            const subSection = state.controlsSections[subSectionsIndex]

            for (const sectionIndex in subSection.subsections) {
                const section = subSection.subsections[sectionIndex]

                for (const controlIndex in section.controls) {
                    const control = section.controls[controlIndex]

                    if (control.company_control.id === id) {
                        state.controlsSections[subSectionsIndex].subsections[
                            sectionIndex
                        ].controls[controlIndex] = {
                            ...control,
                            company_control: {
                                ...control.company_control,
                                ...data
                            }
                        }

                        return
                    }
                }
            }
        }
    }
}

const getters = {
    controls: state => state.controls,
    controlsSections: state => state.controlsSections,
    controlsPerSections: state => state.controlsPerSections,
    previouslyImplementedControls: state =>
        state.controlsPerSections
            .map(section => {
                return {
                    ...section,
                    controls: section.controls.filter(
                        control =>
                            control.isApplicable &&
                            control.status !== SOA.CONTROL_STATUSES.IMPLEMENTED
                    )
                }
            })
            .filter(section => section.controls.length),
    implementationMethods: state => state.implementationMethods,
    unnecessarySteps: state => state.unnecessarySteps,
    hasUnnecessarySteps: state => state.unnecessarySteps.length > 0
}

function findResourceByType (resources, type) {
    const resource = find(resources, resource => type === resource.type.name)

    if (resource) {
        // eslint-disable-next-line camelcase
        const { status, id, value, mark_for_deletion } = resource

        return {
            id,
            value,
            status: status.name,
            markedForDeletion: mark_for_deletion
        }
    }

    return getDefaultResource()
}

function getDefaultResource () {
    return {
        value: '',
        status: RESOURCES.STATUSES.NO_STATUS
    }
}

function mapResources (resources) {
    const { TYPES } = RESOURCES

    return {
        human_resources: findResourceByType(resources, TYPES.HUMAN_RESOURCES),
        technology_resources: findResourceByType(
            resources,
            TYPES.TECHNOLOGY_RESOURCES
        ),
        financial_resources: findResourceByType(
            resources,
            TYPES.FINANCIAL_RESOURCES
        )
    }
}

function mapDocumentImplementationMethod ({ name: title }) {
    return {
        title,
        type: SOA.IMPLEMENTATION_METHOD_TYPES.DOCUMENT
    }
}

function mapTaskImplementationMethod ({ title, description }) {
    return {
        type: SOA.IMPLEMENTATION_METHOD_TYPES.TASK,
        title: title,
        description: description
    }
}

function prepareTask (task) {
    const { TARGET_TYPES } = RESOURCES
    const { resources, due_date: dueDate } = task

    return {
        ...task,
        completion_deadline: dueDate || null,
        implementation_method: mapTaskImplementationMethod(task),
        ...mapResources(resources),
        type: TARGET_TYPES.COMPANY_CONTROL_TASK
    }
}

function prepareCompanyRegulationStep (companyRegulationStep) {
    const { TARGET_TYPES } = RESOURCES
    const { step, resources, deadline } = companyRegulationStep

    return {
        ...companyRegulationStep,
        completion_deadline: deadline || null,
        implementation_method: mapDocumentImplementationMethod(step),
        ...mapResources(resources),
        type: TARGET_TYPES.COMPANY_REGULATION_STEP
    }
}

export const soa = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
