<template>
    <div :class="checkboxOuterWrapClasses">
        <div :class="checkboxWrapperClasses">
            <div :class="checkboxTitleClasses">
                <slot name="title"></slot>
            </div>
            <input
                :checked="value"
                :disabled="disabled"
                type="checkbox"
                :class="'form-check-input ' + checkboxInputClasses"
                @change="$emit('input', $event.target.checked)"
            />
        </div>

        <label :class="'form-check-label ' + checkboxLabelClasses">
            <slot></slot>
        </label>
    </div>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        value: {
            type: Boolean,
            required: true
        },

        checkboxInputClasses: {
            type: String,
            required: false,
            default: ''
        },

        checkboxLabelClasses: {
            type: String,
            required: false,
            default: ''
        },

        checkboxWrapperClasses: {
            type: String,
            required: false,
            default: ''
        },

        checkboxTitleClasses: {
            type: String,
            required: false,
            default: ''
        },

        checkboxOuterWrapClasses: {
            type: String,
            required: false,
            default: ''
        }
    }
}
</script>
