import { find, findIndex } from 'lodash-es'
import { generalService } from '@/services/api/general'

function getInitialState () {
    return {
        countries: [],
        areas: [],
        laws: []
    }
}

const state = getInitialState()

const actions = {
    async getCountries ({ commit, dispatch }) {
        try {
            const countries = await generalService.getCountries()
            commit('SET_COUNTRIES', countries)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        }
    },

    async getAreas ({ commit }) {
        try {
            const areas = await generalService.getAreas()
            commit('SET_AREAS', areas)
        } catch (error) {
            throw error
        }
    },

    async getLaws ({ commit }, { companyId, regulationId }) {
        try {
            const laws = await generalService.getLaws(companyId, regulationId)
            commit('SET_LAWS', laws)
        } catch (error) {
            throw error
        }
    }
}

const mutations = {
    SET_COUNTRIES (state, countries) {
        state.countries = countries
    },

    SET_LAWS (state, laws) {
        state.laws = laws
    },

    SET_AREAS (state, areas) {
        state.areas = areas
    },

    ADD_LAW (state, law) {
        if (law && !find(state.laws, { id: law.id })) {
            state.laws = [...state.laws, law]
        } else if (law && find(state.laws, { id: law.id })) {
            state.laws[findIndex(state.laws, { id: law.id })] = law
        }
    }
}

const getters = {
    countries: state => state.countries,
    areas: state => state.areas,
    laws: state => state.laws
}

export const general = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
