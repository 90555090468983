<template>
    <div class="cf-c-pagination">
        <p class="cf-c-pagination__info">
            {{ $t('COMMON.CHANGES_SAVED_AUTOMATICALLY') }}
        </p>
        <div class="cf-c-pagination__actions-wrapper">
            <div class="cf-c-pagination__actions">
                <div class="cf-c-pagination__action-item">
                    <app-button
                        v-if="hasPrevious"
                        variation="primary-outline"
                        @click="$emit('go-to-previous')"
                    >
                        <template>{{ $t('COMMON.PREVIOUS') }}</template>
                    </app-button>
                </div>
                <div class="cf-c-pagination__action-item">
                    <app-button variation="primary" @click="$emit('submit')">
                        <template>{{ $t('COMMON.PROCEED') }}</template>
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        hasPrevious: {
            type: Boolean,
            required: false,
            default: true
        }
    }
}
</script>
