import { isEmpty, first } from 'lodash-es'

const TEMPLATE_SHOW_REGEX = /show="(.*?)"/g
const CONDITIONAL_TEMPLATE_INPUT_REGEX = /conditional\[(.*?)\]\|(.*)/

export function mapStepForDisplay (step) {
    const { company_regulation_step: regulationStep } = step

    // TODO: Statistics here are not right, when we remove them this should be corrected
    return {
        ...step,
        companyRegulationStepId: isEmpty(regulationStep)
            ? null
            : first(regulationStep).id,
        progress: isEmpty(regulationStep) ? 0 : first(regulationStep).progress,
        numberOfTasks: isEmpty(regulationStep)
            ? 0
            : first(regulationStep).number_of_tasks,
        numberOfActiveTasks: isEmpty(regulationStep)
            ? 0
            : first(regulationStep).number_of_active_tasks,
        assignee: isEmpty(regulationStep)
            ? null
            : first(regulationStep).assignee,
        isEnabled: isEmpty(regulationStep)
            ? null
            : first(regulationStep).is_enabled,
        documents: step.document || []
    }
}

export function replaceShowAttributeInTemplate (template) {
    const result = template.replace(TEMPLATE_SHOW_REGEX, (match, p1) => {
        return `v-if="${resolveCaseOfConditionalInput(
            p1
        )}" class="cf-is-highlighted dw-editor__document-highlight"`
    })

    return result
}

function resolveCaseOfConditionalInput (conditionalCase) {
    const isBoolean =
        conditionalCase.indexOf('true') >= 0 ||
        conditionalCase.indexOf('false') >= 0

    // not a boolean-like string
    if (!isBoolean) {
        const conditionalUserInputCase = conditionalCase.match(
            CONDITIONAL_TEMPLATE_INPUT_REGEX
        )

        return `${conditionalUserInputCase[1]} == ${conditionalUserInputCase[2]}`
    }

    // boolean like-string: check for composite condition
    const isAnd = conditionalCase.indexOf('&&') >= 0
    const isOr = conditionalCase.indexOf('||') >= 0
    // no composite condition: return the simple one
    if (!isAnd && !isOr) {
        return conditionalCase
    }

    // composite condition: split the components into an array
    const operator = isAnd ? '&&' : '||'
    const conditionalCases = conditionalCase.split(operator)

    // it's a Boolean AND
    if (isAnd) {
        return conditionalCases.indexOf('false') >= 0 ? 'false' : 'true'
    }

    // it's a Boolean OR
    return conditionalCases.indexOf('true') >= 0 ? 'true' : 'false'
}
