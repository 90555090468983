<template>
    <img v-if="avatar" :src="avatar" />
    <app-icon v-else icon="avatar-placeholder" size="full" />
</template>

<script>
export default {
    props: {
        avatar: {
            type: String,
            required: false,
            default: null
        }
    }
}
</script>
