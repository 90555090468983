import axios from 'axios'
import { EXPERTA_API } from '@/constants'

const ENDPOINTS = {
    RELATED_QUESTIONS: EXPERTA_API.URL + '/get-related-questions',
    QA: EXPERTA_API.URL + '/qa'
}

class ExpertaService {
    axiosConfig () {
        return {
            headers: {
                'X-Api-Key': EXPERTA_API.KEY,
                Authorization: 'Basic ' + btoa(EXPERTA_API.AUTH)
            }
        }
    }

    async getRelatedQuestions (context) {
        try {
            const body = {
                text: context,
                namespace: 'questions',
                max_questions: 3,
                max_reliability_level: 1
            }

            return await axios.post(
                ENDPOINTS.RELATED_QUESTIONS,
                body,
                this.axiosConfig()
            )
        } catch (error) {
            throw error
        }
    }

    async askQuestion (question, email) {
        try {
            const body = {
                channelId: EXPERTA_API.CHANNEL_ID,
                email,
                question,
                forceNewConversation: false
            }

            return await axios.post(ENDPOINTS.QA, body, this.axiosConfig())
        } catch (error) {
            throw error
        }
    }
}

export const expertaService = new ExpertaService()
