import axios from 'axios'
import { each, isEmpty, keys, snakeCase } from 'lodash-es'

const ENDPOINTS = {
    TASK_CONDITION:
        'companies/:companyId/tasks/conditions/action-token/:actionToken/access-token/:accessToken',
    TASKS: 'companies/:companyId/regulations/:regulationId/tasks',
    TASK: 'companies/:companyId/regulations/:regulationId/tasks/:taskId',
    NEW_TASK_ASSIGNEES: companyId => `companies/${companyId}/tasks/assignees`,
    MY_TASKS_ASSIGNED:
        'companies/:companyId/regulations/:regulationId/my-work/tasks/assigned',
    MY_TASKS_ASSIGNED_OVERVIEW:
        'companies/:companyId/regulations/:regulationId/my-work/tasks/assigned/overview',
    MY_TASKS_CREATED:
        'companies/:companyId/regulations/:regulationId/my-work/tasks/created',
    MY_TASKS_CREATED_OVERVIEW:
        'companies/:companyId/regulations/:regulationId/my-work/tasks/created/overview'
}

class TaskService {
    /**
     * Fetches tasks assigned to auth user
     *
     * @param {Object} data
     *
     * @returns Promise
     */
    async getAssignedTasks ({ companyId, regulationId, queryParams }) {
        try {
            const { data } = await axios.get(
                ENDPOINTS.MY_TASKS_ASSIGNED.replace(
                    ':companyId',
                    companyId
                ).replace(':regulationId', regulationId),
                { params: queryParams }
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Fetches tasks created by auth user
     * @param companyId
     * @param regulationId
     * @param queryParams
     * @returns {Promise<any>}
     */
    async getCreatedTasks ({ companyId, regulationId, queryParams }) {
        try {
            const { data } = await axios.get(
                ENDPOINTS.MY_TASKS_CREATED.replace(
                    ':companyId',
                    companyId
                ).replace(':regulationId', regulationId),
                { params: queryParams }
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Fetches statistic tasks created by auth user
     * @param companyId
     * @param regulationId
     * @param queryParams
     * @returns {Promise<any>}
     */
    async getCreatedTasksOverview ({ companyId, regulationId, queryParams }) {
        try {
            const { data } = await axios.get(
                ENDPOINTS.MY_TASKS_CREATED_OVERVIEW.replace(
                    ':companyId',
                    companyId
                ).replace(':regulationId', regulationId),
                { params: queryParams }
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Fetches statistic tasks assigned to auth user
     * @param companyId
     * @param regulationId
     * @param queryParams
     * @returns {Promise<any>}
     */
    async getAssignedTasksOverview ({ companyId, regulationId, queryParams }) {
        try {
            const { data } = await axios.get(
                ENDPOINTS.MY_TASKS_ASSIGNED_OVERVIEW.replace(
                    ':companyId',
                    companyId
                ).replace(':regulationId', regulationId),
                { params: queryParams }
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Fetches the tasks
     *
     * @param {Object} data
     *
     * @returns Promise
     */
    async getTasks ({ companyId, regulationId, queryParams }) {
        try {
            const { data } = await axios.get(
                this.getTasksRoute(companyId, regulationId, queryParams)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Fetches new task possible assignees
     *
     * @param companyId
     *
     * @returns Promise
     */
    async getNewTaskAssignees (companyId) {
        try {
            const route = ENDPOINTS.NEW_TASK_ASSIGNEES(companyId)

            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Deletes the task
     *
     * @param {Object} data
     *
     * @returns Promise
     */
    async deleteTask ({ companyId, regulationId, taskId }) {
        try {
            const { data } = await axios.delete(
                `${this.getTasksRoute(companyId, regulationId, [])}/${taskId}`
            )
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Updates the task
     *
     * @param {Object} data
     *
     * @returns Promise
     */
    async updateTask ({ companyId, regulationId, taskId, data }) {
        try {
            await axios.put(
                `${this.getTasksRoute(companyId, regulationId, [])}/${taskId}`,
                data
            )
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Submit task condition action
     *
     * @param {Object} data
     *
     * @returns Promise
     */
    async submitAction ({ companyId, actionToken, accessToken }) {
        try {
            const { data } = await axios.put(
                this.getSubmitActionRoute(companyId, actionToken, accessToken)
            )
            return data
        } catch (error) {
            throw error
        }
    }

    async tasksCreateAction ({ companyId, regulationId, formData }) {
        try {
            const { data } = await axios.post(
                this.getTasksRoute(companyId, regulationId),
                formData
            )

            return data
        } catch (err) {
            throw err
        }
    }

    async getTask ({ companyId, regulationId, taskId }) {
        try {
            const { data } = await axios.get(
                this.getTaskRoute(companyId, regulationId, taskId)
            )

            return data
        } catch (err) {
            throw err
        }
    }

    /**
     * Submit task condition action
     *
     * @param {Number} companyId
     * @param {String} actionToken
     * @param {String} accessToken
     *
     * @returns {String}
     */
    getSubmitActionRoute (companyId, actionToken, accessToken) {
        return ENDPOINTS.TASK_CONDITION.replace(':companyId', companyId)
            .replace(':actionToken', actionToken)
            .replace(':accessToken', accessToken)
    }

    /**
     * Get tasks route
     */
    getTasksRoute (companyId, regulationId, queryParams) {
        let route = ENDPOINTS.TASKS.replace(':companyId', companyId).replace(
            ':regulationId',
            regulationId
        )

        if (!isEmpty(queryParams)) {
            route += '?'
            each(keys(queryParams), key => {
                route += `${snakeCase(key)}=${queryParams[key]}&`
            })
            route = route.slice(0, -1)
        }

        return route
    }

    /**
     * Get single task route
     */
    getTaskRoute (companyId, regulationId, taskId) {
        return ENDPOINTS.TASK.replace(':companyId', companyId)
            .replace(':regulationId', regulationId)
            .replace(':taskId', taskId)
    }
}

export const taskService = new TaskService()
