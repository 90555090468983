import { DOCUMENT_WIZARD_ROUTES, MY_PERMISSIONS } from '@/constants'
import { myPermissionsGuard, documentWizardGuard } from '@src/router/guards'

export default {
    name: DOCUMENT_WIZARD_ROUTES.DOCUMENT_WIZARD.name,
    path: 'document-wizard',
    component: () => import('@/containers/DocumentWizard/DocumentWizard'),
    props: route => ({
        question: route.query.question,
        ...route.params
    }),
    redirect: to => ({
        name: DOCUMENT_WIZARD_ROUTES.DISCUSSIONS.name,
        params: { ...to.params }
    }),
    children: [
        {
            name: DOCUMENT_WIZARD_ROUTES.DOCUMENT.name,
            path: 'document/:documentId',
            component: () =>
                import('@/containers/DocumentWizard/DocumentWizardDocument'),
            props: true,
            redirect: to => ({
                name: DOCUMENT_WIZARD_ROUTES.DISCUSSIONS.name,
                params: { ...to.params }
            }),
            children: [
                {
                    path: 'wizard',
                    name: DOCUMENT_WIZARD_ROUTES.WIZARD.name,
                    component: () =>
                        import('@/components/document-wizard/WizardTab'),
                    props: route => ({
                        question: route.query.question,
                        ...route.params
                    }),
                    meta: {
                        pageTitle: 'DOCUMENT_WIZARD.TITLE',
                        withScaling: true,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard, documentWizardGuard],
                        permissions: [MY_PERMISSIONS.DOCUMENT_WIZARD_ACCESS]
                    }
                },
                {
                    path: 'properties',
                    name: DOCUMENT_WIZARD_ROUTES.PROPERTIES.name,
                    component: () =>
                        import('@/components/document-wizard/PropertiesTab'),
                    props: true,
                    meta: {
                        pageTitle: 'DOCUMENT_WIZARD.TITLE',
                        withScaling: true,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard, documentWizardGuard],
                        permissions: [MY_PERMISSIONS.DOCUMENT_WIZARD_ACCESS]
                    }
                },
                {
                    path: 'discussions',
                    name: DOCUMENT_WIZARD_ROUTES.DISCUSSIONS.name,
                    component: () =>
                        import('@/components/document-wizard/Discussions'),
                    props: true,
                    meta: {
                        pageTitle: 'DOCUMENT_WIZARD.TITLE',
                        withScaling: true,
                        shouldSkipRouteInHistory: true,
                        guards: [myPermissionsGuard, documentWizardGuard],
                        permissions: [MY_PERMISSIONS.DOCUMENT_WIZARD_ACCESS]
                    }
                }
            ]
        }
    ]
}
