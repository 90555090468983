<template>
    <div
        class="cf-c-searchable-list"
        :class="[
            { 'cf-c-searchable-list--shadowed': shadowed },
            { 'cf-c-searchable-list--flying-list': flyingList },
            { 'cf-c-searchable-list--p-sm': paddingSm }
        ]"
    >
        <div class="cf-c-searchable-list__search">
            <slot name="input"></slot>
        </div>
        <div
            class="cf-c-searchable-list__items"
            :class="[{ 'cf-c-searchable-list__items--5items': show5Items }]"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        shadowed: {
            type: Boolean,
            required: false,
            default: false
        },

        flyingList: {
            type: Boolean,
            required: false,
            default: false
        },

        paddingSm: {
            type: Boolean,
            required: false,
            default: false
        },

        show5Items: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>
