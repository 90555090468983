<template>
    <div v-if="!isNotAvailable" class="cf-c-status-card__footer">
        <span class="cf-u-font-size-xxl cf-u-font-weight-bold">
            {{ currentStep }}
        </span>
        <span class="cf-u-font-weight-bold cf-u-ph-sm">{{
            $t('COMMON.OF')
        }}</span>
        <span class="cf-u-font-size-xxl cf-u-font-weight-bold">
            {{ totalSteps }}
        </span>
    </div>
    <div v-else>
        <span class="cf-u-font-size-xxl cf-u-font-weight-bold">
            {{ $t('COMMON.NOT_AVAILABLE') }}
        </span>
    </div>
</template>

<script>
import { isString, isNull, isNumber } from 'lodash-es'

export default {
    props: {
        currentStep: {
            validator: val => isNumber(val) || isString(val),
            required: false
        },

        totalSteps: {
            validator: val => isNumber(val) || isString(val),
            required: false
        }
    },

    computed: {
        isNotAvailable () {
            return isNull(this.currentStep) || isNull(this.totalSteps)
        }
    }
}
</script>
