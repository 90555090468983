<template>
    <div :class="`cf-pricing-plan-limits-alert align-${align}`">
        <div :class="`flex-wrapper align-${align}`">
            <app-icon v-if="icon" class="icon" size="md" :icon="icon" />
            <span v-html="text"></span>
        </div>
        <div v-if="shouldShowButtons" :class="`buttons-block align-${align}`">
            <div class="wrapper">
                <app-button
                    id="learn-more"
                    size="sm"
                    padding-small
                    variation="link"
                    @click="handleLearnMore"
                >
                    {{ $t('REGULATION.MODALS.LEARN_MORE.TITLE') }}
                </app-button>
                <app-button size="sm" @click="handleUpgrade">
                    {{ $t('COMPANY_SETTINGS.SUBSCRIPTION.UPGRADE') }}
                </app-button>
            </div>
        </div>
    </div>
</template>

<script>
import AppButton from './AppButton'
import {
    COMPANY_SETTINGS_ROUTES,
    FREE_TRIAL_ROUTE_PARAM,
    HELP_CENTER_LINK
} from '@/constants'

export default {
    components: { AppButton },
    props: {
        align: {
            type: String,
            required: false,
            default: 'center'
        },
        text: {
            type: String,
            required: false,
            default: ''
        },
        icon: {
            type: String,
            required: false,
            default: 'premium'
        },
        shouldShowButtons: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    methods: {
        handleLearnMore () {
            window.open(HELP_CENTER_LINK, '_blank')
        },

        handleUpgrade () {
            window.location.href = this.$router.resolve({
                name: COMPANY_SETTINGS_ROUTES.SUBSCRIPTION.INDEX.name,
                query: { target: FREE_TRIAL_ROUTE_PARAM }
            }).href
        }
    }
}
</script>
