function getInitialState () {
    return {
        layeredModals: []
    }
}

const state = getInitialState()

const actions = {
    async addModalVideoToLayer ({ commit }, { modals, method }) {
        commit('SET_LAYERED_MODALS', { modals, method })
    }
}

const mutations = {
    SET_LAYERED_MODALS (state, { modals, method }) {
        if (method === 'unshift') {
            state.layeredModals.unshift(...modals)
        } else if (method === 'push') {
            state.layeredModals.push(...modals)
        }
    },

    RESET_LAYERED_MODALS (state) {
        state.layeredModals = []
    }
}

const getters = {
    getLayeredModals: state => state.layeredModals
}

export const layeredModals = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
