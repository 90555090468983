import axios from 'axios'
import { REGISTER_OF_INCIDENTS } from '@/constants'
import {
    objectKeyIntersection,
    objectToFormData,
    dropEmptyValues
} from '@/services/utils/functions'

const { INCIDENT_PARAMS } = REGISTER_OF_INCIDENTS

const ENDPOINTS = {
    INCIDENTS: 'companies/:companyId/regulations/:regulationId/incidents',
    INCIDENT:
        'companies/:companyId/regulations/:regulationId/incidents/:incidentId',
    DELETE_INCIDENT: (companyId, regulationId, incidentId) =>
        `companies/${companyId}/regulations/${regulationId}/incidents/${incidentId}`
}

export default class IncidentService {
    /**
     * Get incidents for a regulation
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getIncidents (companyId, regulationId) {
        try {
            const { data } = await axios.get(
                this.getIncidentsRoute(companyId, regulationId)
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Add incident attachments
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} incidentId
     * @param {Array} files
     *
     * @returns Promise
     */
    async addAttachments (
        companyId,
        regulationId,
        incidentId,
        files = [],
        filesIds = []
    ) {
        try {
            const formData = new FormData()
            const route = this.getIncidentRoute(
                companyId,
                regulationId,
                incidentId
            )

            files.forEach(file => {
                formData.append('files[]', file)
            })

            filesIds.forEach(id => {
                formData.append('files_ids[]', id)
            })

            const { data } = await axios.post(`${route}/attachments`, formData)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Create an incident
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Object} incident
     *
     * @returns Promise
     */
    async addIncident (companyId, regulationId, incident) {
        try {
            const files = incident.files
            const preparedObject = dropEmptyValues(
                this.prepareFormData(
                    objectKeyIntersection(incident, INCIDENT_PARAMS)
                )
            )
            const formData = objectToFormData(preparedObject)

            files.forEach(file => {
                formData.append('files[]', file)
            })

            const { data } = await axios.post(
                this.getIncidentsRoute(companyId, regulationId),
                formData
            )

            return data
        } catch (error) {
            throw error
        }
    }

    async createIncidentTask (companyId, regulationId, incidentId, taskData) {
        try {
            const { data } = await axios.post(
                `${this.getIncidentRoute(
                    companyId,
                    regulationId,
                    incidentId
                )}/tasks`,
                taskData
            )

            return data
        } catch (error) {
            throw error
        }
    }

    async updateIncidentTask (companyId, regulationId, incidentId, taskData) {
        try {
            const { data } = await axios.put(
                `${this.getIncidentRoute(
                    companyId,
                    regulationId,
                    incidentId
                )}/tasks/${taskData.id}`,
                taskData
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get incidents route
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     *
     * @returns {String}
     */
    getIncidentsRoute (companyId, regulationId) {
        return ENDPOINTS.INCIDENTS.replace(':companyId', companyId).replace(
            ':regulationId',
            regulationId
        )
    }

    /**
     * Get incident route
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} incidentId
     *
     * @returns {String}
     */
    getIncidentRoute (companyId, regulationId, incidentId) {
        return ENDPOINTS.INCIDENT.replace(':companyId', companyId)
            .replace(':regulationId', regulationId)
            .replace(':incidentId', incidentId)
    }

    /**
     * Update an incident
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} incidentId
     * @param {Object} incident
     *
     * @returns Promise
     */
    async updateIncident (companyId, regulationId, incidentId, incident) {
        try {
            const { data } = await axios.put(
                this.getIncidentRoute(companyId, regulationId, incidentId),
                this.prepareFormData(
                    objectKeyIntersection(incident, INCIDENT_PARAMS)
                )
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Wrap up the incident
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} incidentId
     * @param {Object}
     *
     * @returns Promise
     */
    async wrapUp (companyId, regulationId, incidentId, incident) {
        try {
            const { data } = await axios.put(
                `${this.getIncidentRoute(
                    companyId,
                    regulationId,
                    incidentId
                )}/wrap-up`,
                this.prepareFormData(
                    objectKeyIntersection(incident, INCIDENT_PARAMS)
                )
            )

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Update responsibilities of an incident
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} incidentId
     * @param {Object}
     *
     * @returns Promise
     */
    async updateResponsibilities (
        companyId,
        regulationId,
        incidentId,
        incident
    ) {
        try {
            const { data } = await axios.put(
                `${this.getIncidentRoute(
                    companyId,
                    regulationId,
                    incidentId
                )}/responsibilities`,
                this.prepareFormData(
                    objectKeyIntersection(incident, INCIDENT_PARAMS)
                )
            )

            return data
        } catch (error) {
            throw error
        }
    }

    prepareFormData (data) {
        if (data.state && data.state instanceof Object) {
            data.state = data.state.name
        }

        if (data.classification && data.classification instanceof Object) {
            data.classification = data.classification.name
        }

        return { ...data }
    }

    /**
     * Mark incident as reviewed
     *
     * @param {Number} companyId
     * @param {Number} regulationId
     * @param {Number} incidentId
     *
     * @returns Promise
     */
    async markAsReviewed (companyId, regulationId, incidentId) {
        try {
            const route = `${this.getIncidentRoute(
                companyId,
                regulationId,
                incidentId
            )}/review`
            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Delete incident
     *
     * @param {Number|String} companyId
     * @param {Number|String} regulationId
     * @param {Number|String} incidentId
     *
     * @returns Promise
     */
    async deleteIncident (companyId, regulationId, incidentId) {
        try {
            const route = ENDPOINTS.DELETE_INCIDENT(
                companyId,
                regulationId,
                incidentId
            )
            const { data } = await axios.delete(route)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const incidentService = new IncidentService()
