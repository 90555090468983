import axios from 'axios'
import { each, isEmpty, keys, snakeCase } from 'lodash-es'
import { DOCUMENT_TYPES } from '@/constants'

const ENDPOINTS = {
    MY_DOCUMENTS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/my-documents`,
    DOCUMENT_TYPE: (companyId, documentType) =>
        `companies/${companyId}/${documentType}`,
    DOCUMENT: (companyId, documentId) =>
        `companies/${companyId}/document/${documentId}`,
    STOP_REVIEW: (companyId, documentId, versionId) =>
        `companies/${companyId}/document/${documentId}/version/${versionId}/end-review`,
    STOP_APPROVAL: (companyId, documentId, versionId) =>
        `companies/${companyId}/document/${documentId}/version/${versionId}/end-approval`,
    WITHDRAW_DOCUMENT: (companyId, documentId, versionId) =>
        `companies/${companyId}/document/${documentId}/version/${versionId}/withdraw`,
    META: (companyId, regulationId, documentId) =>
        `companies/${companyId}/regulations/${regulationId}/document/${documentId}/meta`,
    TEMPLATE_INPUTS: (companyId, documentId, versionId) =>
        `companies/${companyId}/documents/${documentId}/versions/${versionId}`,
    TEMPLATE_CUSTOM_INPUTS: (companyId, documentId) =>
        `companies/${companyId}/documents/${documentId}/custom-inputs`,
    UPLOAD_DOCUMENT_ROUTE: companyId =>
        `companies/${companyId}/user-uploaded-document`,
    CUSTOM_DOCUMENT_ROUTE: (companyId, documentId) =>
        `companies/${companyId}/document/${documentId}`,
    CONFORMIO_DOCUMENTS_ROUTE: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/documents`,
    SUGGESTED_RESPONSIBILITY: (companyId, regulationId, stepId) =>
        `companies/${companyId}/regulations/${regulationId}/steps/${stepId}/suggested-responsibilities`,
    UPDATE_SUGGESTED_RESPONSIBILITY: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/suggested-responsibilities/bulk`,
    GET_DOCUMENT_TASKS: (companyId, regulationId, documentId) =>
        `companies/${companyId}/regulations/${regulationId}/documents/${documentId}/tasks`,
    GET_DOCUMENT_HISTORY: (companyId, regulationId, documentId) =>
        `companies/${companyId}/regulations/${regulationId}/documents/${documentId}/history`,
    HIDE_DOCUMENT_HISTORY: (companyId, regulationId, historyId) =>
        `companies/${companyId}/regulations/${regulationId}/documents/history/${historyId}/hide`
}

class DocumentService {
    /**
     * Get document for logged in user
     *
     * @param {Object} data
     *
     * @returns Promise
     */
    async getDocument ({ companyId, documentId, accessToken, actionToken }) {
        try {
            const { data } = await axios.get(
                this.getDocumentsRoute(
                    companyId,
                    DOCUMENT_TYPES.DOCUMENT,
                    documentId
                ),
                {
                    params: this.prepareAccessParams(accessToken, actionToken)
                }
            )
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     *
     * @param companyId
     * @param regulationId
     * @param documentId
     * @returns {Promise<any>}
     */
    async getDocumentHistory ({ companyId, regulationId, documentId }) {
        try {
            const { data } = await axios.get(
                ENDPOINTS.GET_DOCUMENT_HISTORY(
                    companyId,
                    regulationId,
                    documentId
                )
            )
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     *
     * @param companyId
     * @param regulationId
     * @param historyId
     * @returns {Promise<any>}
     */
    async hideDocumentHistory ({ companyId, regulationId, historyId }) {
        try {
            const { data } = await axios.put(
                ENDPOINTS.HIDE_DOCUMENT_HISTORY(
                    companyId,
                    regulationId,
                    historyId
                )
            )
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get document for logged in user
     *
     * @param {Object} data
     *
     * @returns Promise
     */
    async getConformioDocuments ({ companyId, regulationId }) {
        try {
            const route = ENDPOINTS.CONFORMIO_DOCUMENTS_ROUTE(
                companyId,
                regulationId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Upload document
     *
     * @param {Object} data
     *
     * @returns Promise
     */
    async uploadDocument ({ formData, companyId }) {
        try {
            const { data } = await axios.post(
                this.getDocumentsRoute(
                    companyId,
                    DOCUMENT_TYPES.USER_UPLOAD_DOCUMENT
                ),
                formData
            )
            return data
        } catch (error) {
            throw error
        }
    }

    async createConformioDocument ({ formData, companyId }) {
        try {
            const { data } = await axios.post(
                this.getDocumentsRoute(companyId, DOCUMENT_TYPES.DOCUMENT),
                formData
            )
            return data
        } catch (error) {
            throw error
        }
    }

    async uploadDmsDocument ({ formData, companyId }) {
        try {
            const { data } = await axios.post(
                ENDPOINTS.UPLOAD_DOCUMENT_ROUTE(companyId),
                formData
            )

            return data
        } catch (error) {
            throw error
        }
    }

    getDocumentsRoute (
        companyId,
        documentType = DOCUMENT_TYPES.DOCUMENT,
        documentId = null
    ) {
        let route = ENDPOINTS.DOCUMENT_TYPE(companyId, documentType)

        if (documentId) {
            route += `/${documentId}`
        }

        return route
    }

    async stopReview (companyId, documentId, versionId) {
        try {
            const route = ENDPOINTS.STOP_REVIEW(
                companyId,
                documentId,
                versionId
            )
            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }

    async stopApproval (companyId, documentId, versionId) {
        try {
            const route = ENDPOINTS.STOP_APPROVAL(
                companyId,
                documentId,
                versionId
            )
            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }

    async withdrawDocument (companyId, documentId, versionId) {
        try {
            const route = ENDPOINTS.WITHDRAW_DOCUMENT(
                companyId,
                documentId,
                versionId
            )
            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }

    async submitForm (companyId, documentId, versionId, templateId, form) {
        try {
            const route = ENDPOINTS.TEMPLATE_INPUTS(
                companyId,
                documentId,
                versionId
            )
            await axios.patch(route, {
                ...form,
                templateId
            })
        } catch (error) {
            throw error
        }
    }

    async saveCustomInput (companyId, documentId, form) {
        try {
            const route = ENDPOINTS.TEMPLATE_CUSTOM_INPUTS(
                companyId,
                documentId
            )
            const { data } = await axios.put(route, form)

            return data
        } catch (error) {
            throw error
        }
    }

    async getDocumentMeta (companyId, regulationId, documentId) {
        try {
            const route = ENDPOINTS.META(companyId, regulationId, documentId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    async getCustomDocumentMeta (companyId, regulationId, documentId) {
        try {
            const { data } = await axios.get(
                this.getCustomDocumentMetaRoute(
                    companyId,
                    regulationId,
                    documentId
                )
            )

            return data
        } catch (error) {
            throw error
        }
    }

    getCustomDocumentMetaRoute (companyId, regulationId, documentId) {
        return `${ENDPOINTS.META(companyId, regulationId, documentId)}`
    }

    async getDocumentById (companyId, documentId, token, custom = false) {
        try {
            const { data } = await axios.get(
                this.getCustomDocumentRoute(
                    companyId,
                    documentId,
                    token,
                    custom
                )
            )

            return data
        } catch (error) {
            throw error
        }
    }

    getCustomDocumentRoute (companyId, documentId, token, custom = false) {
        const route = ENDPOINTS.CUSTOM_DOCUMENT_ROUTE(companyId, documentId)

        if (token) {
            return `${route}?access_token=${token}&custom=${custom}`
        }

        return `${route}?custom=${custom}`
    }

    async updateDocument (document) {
        try {
            const route = ENDPOINTS.DOCUMENT(document.company_id, document.id)
            const { data } = await axios.put(route, document)
            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get all users documents
     *
     * @param {Number|String} companyId
     * @param {Number|String} regulationId
     */
    async getUsersDocuments (companyId, regulationId) {
        try {
            const route = ENDPOINTS.MY_DOCUMENTS(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    async getSuggestedResponsibilities (companyId, regulationId, stepId) {
        try {
            const route = ENDPOINTS.SUGGESTED_RESPONSIBILITY(
                companyId,
                regulationId,
                stepId
            )

            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Change suggested responsibility active status
     *
     * @param {Number|String} companyId
     * @param {Number|String} regulationId
     * @param {Array} changedSuggestedResponsibilities
     *
     * @returns Promise
     */
    async changeSuggestedResponsiblityActiveStatus (
        companyId,
        regulationId,
        changedSuggestedResponsibilities
    ) {
        try {
            const route = ENDPOINTS.UPDATE_SUGGESTED_RESPONSIBILITY(
                companyId,
                regulationId
            )

            await axios.put(route, changedSuggestedResponsibilities)
        } catch (error) {
            throw error
        }
    }

    /**
     *
     * @param {Number|String} companyId
     * @param {Number|String} regulationId
     * @param {Number|String} documentId
     *
     * @returns Promise
     */
    async getDocumentTasks (companyId, regulationId, documentId) {
        try {
            const route = ENDPOINTS.GET_DOCUMENT_TASKS(
                companyId,
                regulationId,
                documentId
            )

            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * @param {Number} documentId
     * @returns {Promise<any>}
     */
    async getCustomDocumentInputs ({
        companyId,
        documentId,
        accessToken,
        actionToken
    }) {
        const route = ENDPOINTS.TEMPLATE_CUSTOM_INPUTS(companyId, documentId)

        const { data } = await axios.get(route, {
            params: this.prepareAccessParams(accessToken, actionToken)
        })

        return data
    }

    prepareAccessParams (accesToken, actionToken) {
        return {
            access_token: accesToken,
            action_token: actionToken
        }
    }
}

export const documentService = new DocumentService()
