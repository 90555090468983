import { isString, toNumber } from 'lodash-es'

export function shortcode (value, shortcode) {
    try {
        // In case of table input, value will be valid json, and we cannot
        // show it instead of shortcode
        return !toNumber(value) && isString(value) ? value : `[${shortcode}]`
    } catch (error) {
        console.warn(error)
    }
}
