import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import axios from 'axios'
import { config } from '../config'
import {
    SENTRY_KEY,
    SENTRY_ENV,
    HOTJAR_SITE_ID,
    DEFAULT_LOCALE
} from '@/constants'
import Hotjar from 'vue-hotjar'
import VueMixpanel from 'vue-mixpanel'
import { getLocalStorageItem } from '@/services/localStorage'

/**
 * Configure axios defaults
 */
export function configureHttp () {
    axios.defaults.baseURL = config.getApiUrl()
    axios.defaults.headers.Accept = 'application/json'
    axios.defaults.headers['Accept-Language'] =
        getLocalStorageItem('locale') || DEFAULT_LOCALE
}

export function configureHttpHeaderLang (lang) {
    axios.defaults.headers['Accept-Language'] = lang
}

/**
 * Configure Sentry error reporting
 */
export function configureSentry (router) {
    Sentry.init({
        Vue,
        dsn: SENTRY_KEY,
        environment: SENTRY_ENV,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ['localhost', /^\//]
            })
        ],
        ignoreUrls: [
            /extensions\//i,
            /^chrome:\/\//i,
            /^chrome-extensions:\/\//i
        ],
        ignoreErrors: ['ReportingObserver [deprecation]', 'ResizeObserver'],
        tracesSampleRate: 1.0
    })
}

export function configureHotJar () {
    Vue.use(Hotjar, {
        id: HOTJAR_SITE_ID,
        isProduction: config.isProductionEnv()
    })
}

export function configureMixpanel () {
    Vue.use(VueMixpanel, {
        token: config.isProductionEnv()
            ? 'b99987c7418c60a37253a01755ab7bbf'
            : '894c760bc3249fa53ee555e06c539fa3',
        config: {
            debug: !config.isProductionEnv(),
            persistence: 'localStorage'
        }
    })
}
