import axios from 'axios'
import { DROPBOX, SHAREPOINT } from '@/constants'

const ENDPOINTS = {
    [DROPBOX]: '/integrations/dropbox/auth',
    [SHAREPOINT]: '/integrations/sharepoint/auth'
}

export default class IntegrationService {
    async linkWithThirdPartyStorage (provider, formData) {
        try {
            const response = await axios.post(ENDPOINTS[provider], formData)
            return response.data
        } catch (error) {
            throw error
        }
    }
}

export const integrationService = new IntegrationService()
