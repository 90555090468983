<template>
    <div :class="['cf-c-basic-dropdown', handleAlignment]">
        <v-multiselect
            :value="value"
            :options="visibleOptions"
            :option-height="104"
            :show-labels="false"
            :searchable="false"
            :placeholder="$t('COMMON.SELECT_OPTION')"
            @select="handleSelect"
        >
            <template slot="singleLabel">
                <div class="cf-c-basic-dropdown__toggler-wrapper">
                    <slot name="label"></slot>
                </div>
            </template>
            <template slot="option" slot-scope="props">
                <span :ref="props.option.disabled">{{
                    props.option.label
                }}</span>
            </template>
        </v-multiselect>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true
        },

        dropdownPositionAlignment: {
            type: String,
            required: false,
            default: ''
        }
    },

    data () {
        return {
            value: {}
        }
    },

    computed: {
        handleAlignment () {
            return this.dropdownPositionAlignment
                ? 'cf-c-basic-dropdown--alignment-' +
                      this.dropdownPositionAlignment
                : ''
        },

        visibleOptions () {
            return this.options.filter(option => option.visible)
        }
    },
    mounted () {
        if (Object.keys(this.$refs).length) {
            for (let ref in this.$refs) {
                if (ref.indexOf('disabled_') > -1) {
                    this.$refs[ref].parentElement.classList.add(
                        'multiselect__option--disabled'
                    )
                }
            }
        }
    },

    methods: {
        handleSelect (option) {
            this.$emit('selected', option)
        }
    }
}
</script>
