import store from '@src/store'
import { COMMON_PAGES } from '@src/constants'
import { ROUTES_AUTH } from '@src/modules/auth/constants'
import { ROUTES_DOCUMENT_WIZARD } from '@src/modules/document-wizard/constants'
import RoutesDocumentWizard from '@src/modules/document-wizard/router'

export async function myPermissionsGuard (to, from, next) {
    let hasPermission = false
    const myPermissions = store.state.account.myPermissions

    if (
        to.meta?.permissions &&
        (myPermissions.permissions?.length ||
            myPermissions.registers_owner?.length)
    ) {
        to.meta.permissions.forEach(permission => {
            if (
                myPermissions.permissions.some(per => per.name === permission)
            ) {
                hasPermission = true
            }

            if (
                myPermissions.registers_owner.some(
                    register => register === permission
                )
            ) {
                hasPermission = true
            }
        })
    }

    if (
        myPermissions.documents_owner?.documents?.some(
            documentId => Number(documentId) === Number(to.params?.documentId)
        ) ||
        myPermissions.documents_owner?.step_documents?.some(
            stepDocumentId =>
                Number(stepDocumentId) === Number(to.params?.documentId)
        )
    ) {
        hasPermission = true
    }

    if (
        myPermissions.steps_owner?.some(
            stepId => Number(stepId) === Number(to.params?.step)
        )
    ) {
        hasPermission = true
    }

    // External links
    if (to.query?.access_token && to.query?.action_token && !hasPermission) {
        hasPermission = true
    }

    if (hasPermission) {
        return next()
    }

    return next(COMMON_PAGES.FORBIDDEN)
}

export async function documentWizardGuard (to, from, next) {
    const isNewDocWizard = Boolean(store.state.company.company.new_dw)

    if (isNewDocWizard) {
        return next({
            ...RoutesDocumentWizard,
            params: { ...to.params },
            hash: to.hash
        })
    }

    return next()
}

export async function mfaGuard (to, from, next) {
    if (!to.params.mfaToken) {
        return next(ROUTES_AUTH.LOGIN)
    }

    next()
}

export function consultantsSubscriptionGuard (to, from, next) {
    if (store.getters['company/company'].isUnderConsultant) {
        return next(COMMON_PAGES.FORBIDDEN)
    }

    next()
}
