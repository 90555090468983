const ROUTES_CONSULTANT = {
    BASE: {
        name: 'consultant'
    },
    COMPANIES: {
        name: 'consultant.companies'
    }
}

export { ROUTES_CONSULTANT }
