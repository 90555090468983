export const BUTTON_VARIATIONS = [
    'primary',
    'primary-outline',
    'quiet',
    'quiet-danger',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    'link',
    'clear',
    'primary-light-bg',
    'danger-light',
    'quiet-danger-light'
]
export const BUTTON_TYPES = ['submit', 'reset', 'button']

export const INPUT_TYPES = ['email', 'password', 'text', 'tel', 'number']

export const OPTIONS_DISABLED_SEPARATOR = {
    label: '──────────────────',
    value: 'separator',
    disabled: true
}
