<template>
    <div class="cf-u-display-inline-block">
        {{ textBefore }}
        <app-input
            v-model="stepInterval"
            is-within-text
            height-sm
            width-xs
            :disabled="disabled"
            type="number"
            @input="handleInput"
            @blur="$emit('blur', $event)"
        />
        {{ textAfter }}
        <span v-if="!!firstError(inputName)" class="cf-c-input__error">
            {{ firstError(inputName) }}
        </span>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    props: {
        interval: {
            type: [String, Number],
            required: false,
            default: 1
        },

        frequency: {
            type: String,
            required: false,
            default: 'months'
        },

        inputName: {
            type: String,
            required: true
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        text: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },

    data () {
        return {
            stepInterval: ''
        }
    },

    watch: {
        interval (value) {
            this.stepInterval = value
        }
    },

    created () {
        this.stepInterval = this.interval
    },

    computed: {
        ...mapGetters('errors', ['firstError']),

        textBefore () {
            return this.text.before ?? this.$t('COMMON.EVERY')
        },

        textAfter () {
            return (
                this.text.after ??
                this.$t(`COMMON.${this.frequency.toUpperCase()}`)
            )
        }
    },

    methods: {
        ...mapActions('errors', ['clearErrors']),

        handleInput (value) {
            this.clearErrors(this.inputName)

            this.$emit('input', value)
        }
    }
}
</script>

<style scoped>
div {
    display: inline-block;
}
</style>
