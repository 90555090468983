import {
    REGISTER_WIZARD_ROUTES,
    DMS_ROUTES,
    COMPANY_SETTINGS_ROUTES,
    ACCOUNT_ROUTES
} from '@/constants'
import { ROUTES } from './global'
import { ROUTES_COMPANY_SETTINGS } from '@/modules/company-settings/constants'
import { ROUTES_COMPLIANCE } from '@/modules/compliance/constants'
import { ROUTES_TRAINING } from '@/modules/training/constants'
import { ROUTES_SECURITY_OBJECTIVES } from '@src/modules/registers/security-objectives/constants'
import { ROUTES_TRAININGS } from '@src/modules/registers/trainings/constants'
import { ROUTES_REGISTER_OF_REQUIREMENTS } from '@src/modules/registers/requirements/constants'

const EXPERTA_API = {
    URL: process.env.VUE_APP_EXPERTA_API_URL,
    KEY: process.env.VUE_APP_EXPERTA_API_KEY,
    AUTH: process.env.VUE_APP_EXPERTA_API_AUTH,
    CHANNEL_ID: 'Conformio'
}

const EXPERTA_MESSAGE_TYPE = {
    TEXT: 'text',
    QUESTION: 'question'
}

const EXPERTA_MESSAGE_SENDER = {
    USER: 'user',
    BOT: 'bot'
}

const EXPERTA_ROUTE_INFO_MAP = {
    [ROUTES.MY_WORK.INDEX.name]: 'EXPERTA.INFO_PANEL.MY_WORK',

    // Compliance
    [ROUTES_COMPLIANCE.STEPS.name]: 'EXPERTA.INFO_PANEL.COMPLIANCE.STEPS',
    [ROUTES_COMPLIANCE.STEP_OVERVIEW.name]:
        'EXPERTA.INFO_PANEL.COMPLIANCE.STEP_OVERVIEW',
    [ROUTES_COMPLIANCE.AUDIT_AND_EVIDENCE.name]:
        'EXPERTA.INFO_PANEL.COMPLIANCE.AUDIT_AND_EVIDENCE',

    // DMS
    [DMS_ROUTES.INDEX.name]: 'EXPERTA.INFO_PANEL.DMS',

    // Registers and modules

    // Security Objectives
    [ROUTES_SECURITY_OBJECTIVES.BASE.name]:
        'EXPERTA.INFO_PANEL.REGISTERS_AND_MODULES.SECURITY_OBJECTIVES',

    // Register of Requirements
    [ROUTES_REGISTER_OF_REQUIREMENTS.BASE.name]:
        'EXPERTA.INFO_PANEL.REGISTERS_AND_MODULES.REGISTER_OF_REQUIREMENTS',

    // Risk Register
    [REGISTER_WIZARD_ROUTES.RISKS.INDEX.name]:
        'EXPERTA.INFO_PANEL.REGISTERS_AND_MODULES.RISK_REGISTER',

    // SOA
    [REGISTER_WIZARD_ROUTES.SOA.INDEX.name]:
        'EXPERTA.INFO_PANEL.REGISTERS_AND_MODULES.STATEMENT_OF_APPLICABILITY',
    [REGISTER_WIZARD_ROUTES.RISK_TREATMENT_PLAN.INDEX.name]:
        'REGISTERS_AND_MODULES.STATEMENT_OF_APPLICABILITY',

    // Training module
    [ROUTES_TRAININGS.BASE.name]:
        'EXPERTA.INFO_PANEL.REGISTERS_AND_MODULES.TRAINING_MODULE',

    // Incident Register
    [REGISTER_WIZARD_ROUTES.INCIDENTS.INDEX.name]:
        'EXPERTA.INFO_PANEL.REGISTERS_AND_MODULES.INCIDENTS_REGISTER',
    [REGISTER_WIZARD_ROUTES.NONCONFORMITIES.REGISTER_WIZARD.name]:
        'EXPERTA.INFO_PANEL.REGISTERS_AND_MODULES.NONCONFORMITY_REGISTER',

    // Internal Audit
    [REGISTER_WIZARD_ROUTES.INTERNAL_AUDIT_PERFORMING.INDEX.name]:
        'EXPERTA.INFO_PANEL.REGISTERS_AND_MODULES.INTERNAL_AUDIT',
    [REGISTER_WIZARD_ROUTES.INTERNAL_AUDITS.INDEX.name]:
        'EXPERTA.INFO_PANEL.REGISTERS_AND_MODULES.INTERNAL_AUDIT',
    [REGISTER_WIZARD_ROUTES.AUDIT_REPORT.INDEX.name]:
        'EXPERTA.INFO_PANEL.REGISTERS_AND_MODULES.INTERNAL_AUDIT',
    [REGISTER_WIZARD_ROUTES.CHECKLIST_ADAPTATION.INDEX.name]:
        'EXPERTA.INFO_PANEL.REGISTERS_AND_MODULES.INTERNAL_AUDIT',
    [REGISTER_WIZARD_ROUTES.DOCUMENTATION_REVIEW.INDEX.name]:
        'EXPERTA.INFO_PANEL.REGISTERS_AND_MODULES.INTERNAL_AUDIT',

    // Management review
    [REGISTER_WIZARD_ROUTES.REPORTING.MANAGEMENT_REVIEW.INDEX.name]:
        'EXPERTA.INFO_PANEL.REGISTERS_AND_MODULES.MANAGEMENT_REVIEW',
    [REGISTER_WIZARD_ROUTES.REPORTING.MANAGEMENT_REVIEW.SETTING_UP.INDEX.name]:
        'EXPERTA.INFO_PANEL.REGISTERS_AND_MODULES.MANAGEMENT_REVIEW',

    // Reporting Dashboard
    [REGISTER_WIZARD_ROUTES.REPORTING.INDEX.name]:
        'EXPERTA.INFO_PANEL.REGISTERS_AND_MODULES.REPORTING_DASHBOARD',

    // Responsibility Matrix
    [ROUTES.RESPONSIBILITY_MATRIX.INDEX.name]:
        'EXPERTA.INFO_PANEL.RESPONSIBILITY_MATRIX',

    // Conformio Training
    [ROUTES_TRAINING.BASE.name]: 'EXPERTA.INFO_PANEL.CONFORMIO_TRAINING',

    // Company Settings
    [ROUTES_COMPANY_SETTINGS.USER_MANAGEMENT.name]:
        'EXPERTA.INFO_PANEL.COMPANY_SETTINGS.USER_MANAGEMENT',
    [COMPANY_SETTINGS_ROUTES.SUBSCRIPTION.INDEX.name]:
        'EXPERTA.INFO_PANEL.COMPANY_SETTINGS.SUBSCRIPTION',
    [COMPANY_SETTINGS_ROUTES.BASIC_INFORMATION.name]:
        'EXPERTA.INFO_PANEL.COMPANY_SETTINGS.BASIC_INFORMATION',
    [COMPANY_SETTINGS_ROUTES.SECURITY.INDEX.name]:
        'EXPERTA.INFO_PANEL.COMPANY_SETTINGS.SECURITY',
    [COMPANY_SETTINGS_ROUTES.PROJECT_SETTINGS.name]:
        'EXPERTA.INFO_PANEL.COMPANY_SETTINGS.PROJECT_SETTINGS',
    [COMPANY_SETTINGS_ROUTES.INTEGRATIONS.name]:
        'EXPERTA.INFO_PANEL.COMPANY_SETTINGS.INTEGRATIONS',

    // Account
    [ACCOUNT_ROUTES.SETTINGS.name]: 'EXPERTA.INFO_PANEL.MY_ACCOUNT.PREFERENCES',
    [ACCOUNT_ROUTES.MY_PROFILE.name]: 'EXPERTA.INFO_PANEL.MY_ACCOUNT.MY_PROFILE'
}

export {
    EXPERTA_API,
    EXPERTA_MESSAGE_TYPE,
    EXPERTA_MESSAGE_SENDER,
    EXPERTA_ROUTE_INFO_MAP
}
