import { REGISTER_WIZARD_ROUTES, MY_PERMISSIONS } from '@src/constants'
import { myPermissionsGuard } from './guards'

export default {
    name: REGISTER_WIZARD_ROUTES.REPORTING.INDEX.name,
    path: 'standard/:standard/reporting-dashboard',
    component: () =>
        import('@/containers/ReportingDashboard/ReportingDashboard'),
    props: true,
    children: [
        {
            name: REGISTER_WIZARD_ROUTES.REPORTING.INDEX.name,
            path: '',
            component: () => import('@/containers/ReportingDashboard/Overview'),
            props: false,
            meta: {
                pageTitle: 'REPORTING.OVERVIEW.REPORTING_MODULE',
                title: 'REPORTING.OVERVIEW.REPORTING_MODULE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.REPORTING_DASHBOARD_ACCESS]
            }
        },
        {
            name:
                REGISTER_WIZARD_ROUTES.REPORTING.PROJECT_STATUS_DASHBOARD.name,
            path: 'project-status',
            component: () =>
                import(
                    '@/containers/ReportingDashboard/ProjectStatusDashboard'
                ),
            props: false,
            meta: {
                pageTitle: 'REPORTING.OVERVIEW.PROJECT_STATUS.PAGE_TITLE',
                title: 'REPORTING.OVERVIEW.PROJECT_STATUS.TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.PROJECT_STATUS_ACCESS]
            }
        },
        {
            name: REGISTER_WIZARD_ROUTES.REPORTING.COMPLIANCE_DASHBOARD.name,
            path: 'compliance',
            component: () =>
                import('@/containers/ReportingDashboard/ComplianceDashboard'),
            props: false,
            meta: {
                pageTitle: 'REPORTING.OVERVIEW.COMPLIANCE.PAGE_TITLE',
                title: 'REPORTING.OVERVIEW.COMPLIANCE.TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.COMPLIANCE_DASHBOARD_ACCESS]
            }
        },
        {
            name: REGISTER_WIZARD_ROUTES.REPORTING.PERFORMANCE_DASHBOARD.name,
            path: 'performance',
            component: () =>
                import('@/containers/ReportingDashboard/PerformanceDashboard'),
            props: false,
            meta: {
                pageTitle: 'REPORTING.OVERVIEW.PERFORMANCE.PAGE_TITLE',
                title: 'REPORTING.OVERVIEW.PERFORMANCE.TITLE',
                requiresAuth: true,
                guards: [myPermissionsGuard],
                permissions: [MY_PERMISSIONS.PERFORMANCE_DASHBOARD_ACCESS]
            }
        }
    ]
}
