import { ROUTES_PREONBOARDING } from './constants'

export default {
    path: '/preonboarding',
    name: ROUTES_PREONBOARDING.BASE.name,
    props: true,
    redirect: ROUTES_PREONBOARDING.ACCOUNT_DETAILS,
    component: () => import('./ModulePreonboarding'),
    children: [
        {
            path: 'account-details',
            name: ROUTES_PREONBOARDING.ACCOUNT_DETAILS.name,
            component: () => import('./PreonboardingAccountDetails'),
            props: true,
            meta: {
                pageTitle: 'PREONBOARDING.ACCOUNT_DETAILS.PAGE_TITLE',
                requiresAuth: false
            }
        },
        {
            path: 'email-verfication',
            name: ROUTES_PREONBOARDING.EMAIL_VERIFICATION.name,
            component: () => import('./PreonboardingEmailVerification'),
            props: true,
            meta: {
                pageTitle: 'PREONBOARDING.EMAIL_VERIFICATION.PAGE_TITLE',
                requiresAuth: true
            }
        },
        {
            path: 'company-setup',
            name: ROUTES_PREONBOARDING.COMPANY_SETUP.name,
            component: () => import('./PreonboardingCompanySetup'),
            props: true,
            meta: {
                pageTitle: 'PREONBOARDING.COMPANY_SETUP.PAGE_TITLE',
                requiresAuth: true
            }
        }
    ]
}
