<template>
    <div :class="['cf-c-adv-collapsible', { 'cf-is-open': visible }]">
        <slot name="risk-header"></slot>
        <div class="cf-c-adv-collapsible__header">
            <div class="cf-c-adv-collapsible__extra-states">
                <div
                    class="cf-c-adv-collapsible__extra-state cf-c-adv-collapsible__trigger"
                    @click="toggleCollapse"
                ></div>
                <slot name="checkbox"></slot>
                <slot name="state"></slot>
            </div>
            <slot name="columns"></slot>
            <div class="cf-c-adv-collapsible__extra-states">
                <slot name="extra-states"></slot>
            </div>
        </div>
        <div v-show="visible" class="cf-c-adv-collapsible__ctn">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isVisible: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    data () {
        return {
            visible: this.isVisible
        }
    },

    computed: {
        wrapperClasses () {
            let classes = 'cf-c-collapsible'

            if (this.highlighted) {
                classes += ' cf-c-collapsible--highlighted'
            }

            if (this.horizontal) {
                classes += ' cf-c-collapsible--horizontal'
            }

            if (this.visible) {
                classes += ' cf-is-open'
            }

            return classes
        }
    },

    watch: {
        isVisible (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.visible = this.isVisible
            }
        }
    },

    methods: {
        toggleCollapse () {
            this.visible = !this.visible
            this.$emit('toggle-collapse', this.visible)
        }
    }
}
</script>
