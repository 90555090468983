import axios from 'axios'

const ENDPOINTS = {
    GET_IMPLEMENTATION_STEPS: (companyId, regulationId) =>
        `/companies/${companyId}/regulations/${regulationId}/compliance/steps/`,
    GET_IMPLEMENTATION_STEPS_SOA_STATUS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/compliance/steps/soa-status`
}

class ImplementationStepsService {
    /**
     * Get implementation steps
     *
     * @param {Number|String} companyId
     * @param {Number|String} regulationId
     *
     * @returns Promise
     */
    async getImplementationSteps (companyId, regulationId) {
        try {
            const route = ENDPOINTS.GET_IMPLEMENTATION_STEPS(
                companyId,
                regulationId
            )

            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get implementation steps soa status
     *
     * @param {Number|String} companyId
     * @param {Number|String} regulationId
     *
     * @returns Promise
     */
    async getImplementationStepsSoaStatus (companyId, regulationId) {
        try {
            const route = ENDPOINTS.GET_IMPLEMENTATION_STEPS_SOA_STATUS(
                companyId,
                regulationId
            )

            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const implementationStepsService = new ImplementationStepsService()
