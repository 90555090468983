import { config } from '@/config'
import axios from 'axios'
import { BASE_SLACK_OAUTH_URL, SLACK_SCOPE } from '@/constants'
import { getLocalStorageItem } from '@/services/localStorage'
import { getCookie } from '../localStorage'

const ENDPOINTS = {
    CONFIGURATIONS: {
        INDEX: companyId => `companies/${companyId}/slack/slack-configurations`,
        UPDATE: (companyId, configurationId) =>
            `companies/${companyId}/slack/slack-configurations/${configurationId}`
    },
    CHANNELS: companyId => `companies/${companyId}/slack/channels`,
    INTEGRATION: companyId => `companies/${companyId}/slack/integration`
}

class SlackService {
    async getSlackConfigurations (companyId) {
        try {
            const route = ENDPOINTS.CONFIGURATIONS.INDEX(companyId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    async getSlackChannels (companyId) {
        try {
            const route = ENDPOINTS.CHANNELS(companyId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    async updateSlackConfiguration (companyId, configurationData) {
        try {
            const route = ENDPOINTS.CONFIGURATIONS.UPDATE(
                companyId,
                configurationData.id
            )
            const { data } = await axios.put(route, configurationData)

            return data
        } catch (error) {
            throw error
        }
    }

    async getSlackIntegration (companyId) {
        try {
            const route = ENDPOINTS.INTEGRATION(companyId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    getSlackOAuthUrl (companyId) {
        const baseUrl = `${BASE_SLACK_OAUTH_URL}${config.getSlackClientId()}&scope=${SLACK_SCOPE}&redirect_uri=`
        const baseRedirectUrl = `companies/${companyId}/slack/oauth?`
        const token = getCookie('token')
        const query = `api_token=${token}&forward_url=${window.location.pathname}`

        return (
            baseUrl +
            config.getApiUrl() +
            baseRedirectUrl +
            encodeURIComponent(query)
        )
    }
}

export const slackService = new SlackService()
