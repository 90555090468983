var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-modal',{ref:"modal",attrs:{"name":_vm.name,"classes":[
        `app-modal-new app-modal-new--${_vm.width} ${
            _vm.isOverflowVisible ? 'app-modal-new--with-overflow-visible' : ''
        }`
    ],"height":"auto","click-to-close":_vm.isCloseable,"adaptive":_vm.adaptive,"scrollable":"","focusTrap":""},on:{"opened":function($event){return _vm.$emit('on-show')},"closed":function($event){return _vm.$emit('on-close')}}},[_c('div',{staticClass:"app-modal-new__wrap"},[(_vm.isCloseable)?_c('button',{staticClass:"app-modal-new__cancel-action",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_c('app-icon',{attrs:{"icon":"icon-cancel"}})],1):_vm._e(),(_vm.title)?_c('h2',{staticClass:"app-modal-new__title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]):_vm._e(),_c('div',{class:[
                'app-modal-new__content',
                {
                    'app-modal-new__content--no-padding': _vm.hideActions
                }
            ]},[_vm._t("content")],2),(!_vm.hideActions)?_c('div',{staticClass:"app-modal-new__actions-wrap"},[_vm._t("extra-actions"),(_vm.isCloseable && !_vm.hideCancelAction)?_c('button',{class:`btn btn--${_vm.cancelActionVariation}`,attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t(_vm.cancelActionLabel))+" ")]):_vm._e(),_c('button',{class:`btn btn--${_vm.confirmActionVariation}`,attrs:{"type":"button","disabled":_vm.confirmActionDisabled},on:{"click":function($event){return _vm.$emit('handle-confirm')}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.confirmActionLabel))+" ")])],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }