const ADD_TO_SLACK_IMAGE_SRC =
    'https://platform.slack-edge.com/img/add_to_slack.png'
const ADD_TO_SLACK_IMAGE_SRCSET =
    'https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x'
const BASE_SLACK_OAUTH_URL = 'https://slack.com/oauth/v2/authorize?client_id='
const SLACK_SCOPE =
    'users:read.email,channels:read,groups:read,im:read,mpim:read,chat:write,users:read,chat:write.public'

const SLACK_CLIENT_ID = process.env.VUE_APP_SLACK_CLIENT_ID

export {
    ADD_TO_SLACK_IMAGE_SRC,
    ADD_TO_SLACK_IMAGE_SRCSET,
    BASE_SLACK_OAUTH_URL,
    SLACK_SCOPE,
    SLACK_CLIENT_ID
}
