import axios from 'axios'
import { first } from 'lodash-es'
import { config } from '@/config'
import { BLUESNAP_DOMAIN_PATHS } from '@/constants'
import { isAfter } from '@/services/utils/date'

const ENDPOINTS = {
    TOKEN: '/payment/token',
    PRICING_PLANS: '/pricing/plans',
    AVAILABLE_PRICING_PLANS: '/company/:companyId/plans',
    SET_PLAN: '/company/:id/plans',
    GET_SUBSCRIPTION_LIST: '/subscriptions/list'
}

export default class PricingPlansService {
    async getToken () {
        try {
            const response = await axios.get(ENDPOINTS.TOKEN)
            return first(response.data)
        } catch (error) {
            throw error
        }
    }

    async getSubscriptionsList () {
        try {
            const response = await axios.get(ENDPOINTS.GET_SUBSCRIPTION_LIST)
            return response.data
        } catch (error) {
            throw error
        }
    }

    isCheckoutSuccessful (eCheckoutResult) {
        return eCheckoutResult.code === 1
    }

    getDomainPath () {
        return config.isProductionEnv()
            ? BLUESNAP_DOMAIN_PATHS.production
            : BLUESNAP_DOMAIN_PATHS.sandbox
    }

    async getPricingPlans () {
        try {
            const response = await axios.get(ENDPOINTS.PRICING_PLANS)
            return response.data
        } catch (error) {
            throw error
        }
    }

    async getAvailablePricingPlans (companyId) {
        try {
            const route = ENDPOINTS.AVAILABLE_PRICING_PLANS.replace(
                ':companyId',
                companyId
            )
            const response = await axios.get(route)
            return response.data
        } catch (error) {
            throw error
        }
    }

    hasValidPricingPlan (company) {
        return company && company.pricing_plan
            ? isAfter(company.pricing_plan.expires)
            : false
    }

    async setPlan (companyId, data) {
        try {
            const route = ENDPOINTS.SET_PLAN.replace(':id', companyId)
            return await axios.post(route, data)
        } catch (error) {
            throw error
        }
    }
}

export const pricingPlansService = new PricingPlansService()
