<template>
    <div @blur.capture="handleBlur">
        <v-multiselect
            ref="multiselect"
            v-model="selected"
            :tabindex="tabindex"
            :multiple="true"
            :class="wrapClasses"
            :options="options"
            :label="label"
            :track-by="trackBy"
            :disabled="disabled"
            :taggable="taggable"
            :placeholder="placeholder"
            open-direction="bottom"
            @input="handleInput"
            @tag="$emit('tag', $event)"
            @close="
                $emit(
                    'close',
                    $event && $event.target ? $event.target.value : ''
                )
            "
        >
            <span slot="noOptions">{{ $t('COMMON.LIST_IS_EMPTY') }}</span>
        </v-multiselect>

        <span v-if="!!errorMessage" class="cf-c-input__error">
            <span v-if="showErrorMessage">{{ errorMessage }}</span>
        </span>
    </div>
</template>

<script>
import { isUndefined, isNil } from 'lodash-es'

export default {
    props: {
        options: {
            type: Array,
            required: false,
            default: () => []
        },

        label: {
            type: String,
            required: false,
            default: 'label'
        },

        trackBy: {
            type: String,
            required: false,
            default: 'value'
        },

        value: {
            type: [Array, Number, String],
            required: false,
            default: () => []
        },

        initialValue: {
            type: [Array, Number, String],
            required: false,
            default: () => []
        },

        errorMessage: {
            type: String,
            required: false,
            default: ''
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        disabledCustom: {
            type: Boolean,
            required: false,
            default: false
        },

        tabindex: {
            type: Number,
            required: false,
            default: 0
        },

        noBorderRadius: {
            type: Boolean,
            required: false,
            default: false
        },

        placeholder: {
            type: String,
            required: false,
            default: ''
        },

        taggable: {
            type: Boolean,
            default: false
        },

        noMinWidth: {
            type: Boolean,
            required: false,
            default: false
        },

        showErrorMessage: {
            type: Boolean,
            required: false,
            default: true
        },

        className: {
            type: String,
            required: false,
            default: ''
        }
    },

    data () {
        return {
            selected: null
        }
    },

    computed: {
        hasValue () {
            return !isNil(this.value)
        },

        wrapClasses () {
            return `${this.errorMessage ? 'multiselect__tags--red-border' : ''}
        ${
            this.noBorderRadius
                ? 'cf-c-multiselect cf-c-multiselect--no-border-radius'
                : ''
        }
        ${
            this.value.length > 0
                ? 'cf-c-multiselect cf-c-multiselect--has-text'
                : ''
        }
        ${
            this.disabledCustom
                ? 'cf-c-multiselect cf-c-multiselect--disabled-custom'
                : ''
        }
        ${this.noMinWidth ? 'cf-c-multiselect--no-min-width' : ''}
        cf-c-multiselect--clear-dropdown
        ${this.className ? `${this.className}` : ''}`
        }
    },

    watch: {
        value (newValue) {
            if (!isNil(newValue)) {
                this.selected = [...newValue]
            }
        }
    },

    mounted () {
        if (!isUndefined(this.value)) {
            this.selected = [...this.value]
        }

        if (this.initialValue.length) {
            this.selected = [...this.initialValue]
        }
    },

    methods: {
        handleInput (selected) {
            this.$emit('input', selected)
        },

        handleBlur () {
            if (this.$refs.multiselect) {
                this.$refs.multiselect.isOpen = false
            }
        }
    }
}
</script>
