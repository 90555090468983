import axios from 'axios'

const ENDPOINTS = {
    ASSETS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/assets`,
    RISKS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/risks`,
    RISK_DATA: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/risks/data`,
    COMPLETE_STEP: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/risks/complete-step`,
    REVIEW_RISKS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/risks/review`,
    CONFIRM_RISK: (companyId, regulationId, riskId) =>
        `companies/${companyId}/regulations/${regulationId}/risks/${riskId}/confirm`,
    CONFIRM_ALL_RISKS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/risks/complete-all`,
    THREATS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/threats`,
    THREATS_CATEGORIES: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/threats/categories`,
    UPDATE_THREAT: (companyId, regulationId, threatId) =>
        `companies/${companyId}/regulations/${regulationId}/threats/${threatId}`,
    VULNERABILITIES: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/vulnerabilities`,
    UPDATE_VULNERABILITY: (companyId, regulationId, vulnerabilityId) =>
        `companies/${companyId}/regulations/${regulationId}/vulnerabilities/${vulnerabilityId}`,
    RISK_TREATMENT_OPTIONS: 'risk-treatment-options',
    REGISTER_CACHE: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/cache`,
    RISKS_FROM_CACHE: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/risks/cache`,
    REMOVE_RISK_FROM_CACHE: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/risks/cache/remove-risk`,
    STEP_RISKS: (companyId, regulationId, stepId) =>
        `companies/${companyId}/regulations/${regulationId}/steps/${stepId}/risks`,
    UPDATE_RISK: (companyId, regulationId, riskId) =>
        `companies/${companyId}/regulations/${regulationId}/risks/${riskId}`,
    RISK: (companyId, regulationId, riskId) =>
        `companies/${companyId}/regulations/${regulationId}/risks/${riskId}`,
    VALIDATE_DRAFT_RISK: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/risks/validate-draft`,
    DELETE_RISK: (companyId, regulationId, riskId) =>
        `companies/${companyId}/regulations/${regulationId}/risks/${riskId}`
}

export default class RiskService {
    /**
     * Get risk treatment options
     *
     * @returns Promise
     */
    async getRiskTreatmentOptions () {
        try {
            const { data } = await axios.get(ENDPOINTS.RISK_TREATMENT_OPTIONS)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get risks
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     */
    async getRisks (companyId, regulationId, params = undefined) {
        try {
            const route = ENDPOINTS.RISKS(companyId, regulationId)

            const { data } = await axios.get(route, { params })

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get step risks
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} stepId
     *
     * @returns Promise
     */
    async getStepRisks (companyId, regulationId, stepId) {
        try {
            const route = ENDPOINTS.STEP_RISKS(companyId, regulationId, stepId)

            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get risk data (assets, vulnerabilities and threats)
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getRiskData (companyId, regulationId) {
        try {
            const route = ENDPOINTS.RISK_DATA(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get assets
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getAssets (companyId, regulationId) {
        try {
            const route = ENDPOINTS.ASSETS(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get vulnerabilities
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getVulnerabilities (companyId, regulationId) {
        try {
            const route = ENDPOINTS.VULNERABILITIES(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get threats
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getThreats (companyId, regulationId) {
        try {
            const route = ENDPOINTS.THREATS(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get threats categories
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getThreatsCategories (companyId, regulationId) {
        try {
            const route = ENDPOINTS.THREATS_CATEGORIES(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Add an asset
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Object} payload
     *
     * @returns Promise
     */
    async addAnAsset (companyId, regulationId, payload) {
        try {
            const route = ENDPOINTS.ASSETS(companyId, regulationId)
            const { data } = await axios.post(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Confirm a risk
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} riskId
     *
     * @returns Promise
     */
    async confirmRisk (companyId, regulationId, riskId) {
        try {
            const route = ENDPOINTS.CONFIRM_RISK(
                companyId,
                regulationId,
                riskId
            )
            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Confirm all risks
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async confirmAllRisks (companyId, regulationId) {
        try {
            const route = ENDPOINTS.CONFIRM_ALL_RISKS(companyId, regulationId)
            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Create new vulnerability
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Object} payload
     *
     * @returns Promise
     */
    async createNewVulnerability (companyId, regulationId, payload) {
        try {
            const route = ENDPOINTS.VULNERABILITIES(companyId, regulationId)
            const { data } = await axios.post(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Add vulnerability
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} vulnerabilityId
     * @param {Object} payload
     *
     * @returns Promise
     */
    async addVulnerability (companyId, regulationId, vulnerabilityId, payload) {
        try {
            const route = ENDPOINTS.UPDATE_VULNERABILITY(
                companyId,
                regulationId,
                vulnerabilityId
            )
            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Add a threat
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} threatId
     * @param {Object} payload
     *
     * @returns Promise
     */
    async addThreat (companyId, regulationId, threatId, payload) {
        try {
            const route = ENDPOINTS.UPDATE_THREAT(
                companyId,
                regulationId,
                threatId
            )
            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Create new threat
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Object} payload
     *
     * @returns Promise
     */
    async createNewThreat (companyId, regulationId, payload) {
        try {
            const route = ENDPOINTS.THREATS(companyId, regulationId)
            const { data } = await axios.post(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Complete step
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Object} payload
     */
    async completeStep (companyId, regulationId, step) {
        try {
            const route = ENDPOINTS.COMPLETE_STEP(companyId, regulationId)

            const params = { step }

            const { data } = await axios.get(route, { params })

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Review risks
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Object} payload
     */
    async reviewRisks (companyId, regulationId, payload) {
        try {
            const route = ENDPOINTS.REVIEW_RISKS(companyId, regulationId)
            const { data } = await axios.post(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Create new risk
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Object} risk
     */
    async createRisk (companyId, regulationId, riskData) {
        try {
            const route = ENDPOINTS.RISKS(companyId, regulationId)
            const { data: risk } = await axios.post(route, riskData)

            return risk
        } catch (error) {
            throw error
        }
    }

    /**
     * Get risks cache
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getRisksCache (companyId, regulationId) {
        try {
            const route = ENDPOINTS.REGISTER_CACHE(companyId, regulationId)

            const { data } = await axios.get(route)

            return data && data.data ? JSON.parse(data.data) : []
        } catch (error) {
            throw error
        }
    }

    /**
     * Get all risks from cache
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getRisksFromCache (companyId, regulationId, params = undefined) {
        try {
            const route = ENDPOINTS.RISKS_FROM_CACHE(companyId, regulationId)

            const { data } = await axios.get(route, { params })

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Submit risks cache
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Array} cache
     *
     * @returns Promise
     */
    async submitRisksCache (companyId, regulationId, cache) {
        try {
            const route = ENDPOINTS.REGISTER_CACHE(companyId, regulationId)

            const { data } = await axios.post(route, {
                data: cache
            })

            // Return updated cache data
            return data && data.data ? JSON.parse(data.data) : []
        } catch (error) {
            throw error
        }
    }

    /**
     * Remove risk data from register cache
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Array} cache
     *
     * @returns Promise
     */
    async removeRiskFromCache (companyId, regulationId, data) {
        try {
            const route = ENDPOINTS.REMOVE_RISK_FROM_CACHE(
                companyId,
                regulationId
            )

            await axios.put(route, { data })
        } catch (error) {
            throw error
        }
    }

    /**
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} riskId
     * @param {Object} payload
     */
    async updateRisk (companyId, regulationId, riskId, payload) {
        try {
            const route = ENDPOINTS.UPDATE_RISK(companyId, regulationId, riskId)

            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} riskId
     */
    async deleteRisk (companyId, regulationId, riskId, data = undefined) {
        try {
            const route = ENDPOINTS.DELETE_RISK(companyId, regulationId, riskId)

            await axios.delete(route, { data })
        } catch (error) {
            throw error
        }
    }

    /**
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Object} payload
     */
    async validateDraftRisk (companyId, regulationId, payload) {
        try {
            const route = ENDPOINTS.VALIDATE_DRAFT_RISK(companyId, regulationId)
            const { data } = await axios.post(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get risk by id
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} riskId
     *
     * @returns Promise
     */
    async getRisk (companyId, regulationId, riskId) {
        try {
            const route = ENDPOINTS.RISK(companyId, regulationId, riskId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const riskService = new RiskService()
