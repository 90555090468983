import axios from 'axios'

const ENDPOINTS = {
    GET_TRAININGS: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/trainings`,
    GET_TRAINING_DETAILS: (companyId, regulationId, trainingId) =>
        `companies/${companyId}/regulations/${regulationId}/trainings/${trainingId}`,
    ADD_TRAINING: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/trainings`,
    EDIT_TRAINING: (companyId, regulationId, trainingId) =>
        `companies/${companyId}/regulations/${regulationId}/trainings/${trainingId}`,
    DELETE_TRAINING: (companyId, regulationId, trainingId) =>
        `companies/${companyId}/regulations/${regulationId}/trainings/${trainingId}`,
    GET_STEP_TRAININGS: (companyId, regulationId, stepId) =>
        `companies/${companyId}/regulations/${regulationId}/steps/${stepId}/trainings`,
    BULK_ADD_STEP_TRAININGS: (companyId, regulationId, stepId) =>
        `companies/${companyId}/regulations/${regulationId}/steps/${stepId}/trainings/bulk-save`,
    DELETE_STEP_TRAINING: (companyId, regulationId, stepId, trainingId) =>
        `companies/${companyId}/regulations/${regulationId}/steps/${stepId}/trainings/${trainingId}`,
    GENERATE_REPORT: (companyId, regulationId) =>
        `companies/${companyId}/regulations/${regulationId}/trainings/export-report`
}

export default class TrainingsService {
    /**
     * Get all trainings
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async getTrainings (companyId, regulationId) {
        try {
            const route = ENDPOINTS.GET_TRAININGS(companyId, regulationId)
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get training details
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} trainingId
     *
     * @returns Promise
     */
    async getTrainingDetails (companyId, regulationId, trainingId) {
        try {
            const route = ENDPOINTS.GET_TRAINING_DETAILS(
                companyId,
                regulationId,
                trainingId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Add new training
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Object} payload
     *
     * @returns Promise
     */
    async addTraining (companyId, regulationId, payload) {
        try {
            const route = ENDPOINTS.ADD_TRAINING(companyId, regulationId)
            const { data } = await axios.post(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Edit training
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} trainingId
     * @param {Object} payload
     *
     * @returns Promise
     */
    async editTraining (companyId, regulationId, trainingId, payload) {
        try {
            const route = ENDPOINTS.EDIT_TRAINING(
                companyId,
                regulationId,
                trainingId
            )
            const { data } = await axios.put(route, payload)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Delete training
     *
     * @param {Number|String} companyId
     * @param {Number|String} regulationId
     * @param {Number|String} trainingId
     *
     * @returns Promise
     */
    async deleteTraining (companyId, regulationId, trainingId) {
        try {
            const route = ENDPOINTS.DELETE_TRAINING(
                companyId,
                regulationId,
                trainingId
            )
            const { data } = await axios.delete(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Get step trainings
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} stepId
     */
    async getStepTrainings (companyId, regulationId, stepId) {
        try {
            const route = ENDPOINTS.GET_STEP_TRAININGS(
                companyId,
                regulationId,
                stepId
            )
            const { data } = await axios.get(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Bulk add step trainings
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {Array} trainings
     *
     * @returns Promise
     */
    async bulkSaveTrainings (companyId, regulationId, stepId, trainings) {
        try {
            const route = ENDPOINTS.BULK_ADD_STEP_TRAININGS(
                companyId,
                regulationId,
                stepId
            )

            const { data } = await axios.post(route, trainings)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Delete step training
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     * @param {String|Number} stepId
     */
    async deleteStepTraining (companyId, regulationId, stepId, trainingId) {
        try {
            const route = ENDPOINTS.DELETE_STEP_TRAINING(
                companyId,
                regulationId,
                stepId,
                trainingId
            )
            const { data } = await axios.delete(route)

            return data
        } catch (error) {
            throw error
        }
    }

    /**
     * Generate training plan & record report
     *
     * @param {String|Number} companyId
     * @param {String|Number} regulationId
     *
     * @returns Promise
     */
    async generateReport (companyId, regulationId) {
        try {
            const route = ENDPOINTS.GENERATE_REPORT(companyId, regulationId)

            const { data } = await axios.post(route)

            return data
        } catch (error) {
            throw error
        }
    }
}

export const trainingsService = new TrainingsService()
